import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import {
  clearSearchDevicesState as clearSearchDevicesStateAction,
  searchDevices as searchDevicesAction,
} from "../store/devices.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";
import { selectIsDevicesTableStatusPending } from "../store/devices.selector";

export const DevicesSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isDevicesPending = useSelector(selectIsDevicesTableStatusPending);

  useEffect(() => {
    return () => {
      dispatch(clearSearchDevicesStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchDevicesAction(value));

  return (
    <TableSearchInput
      label="Search Devices"
      onSearch={debounce(onSearch, 200)}
      isPending={isDevicesPending}
    />
  );
};
