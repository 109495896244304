import { AlertModel } from "../model/alerts.model";
import { AlertDto } from "./alerts.api.dto";

export const mapAlertDtoToAlertModel = (alertDto: AlertDto): AlertModel => ({
  asset: alertDto.equipment.name,
  assetId: alertDto.equipment.id,
  sensor_name: alertDto.sensor.friendly_name,
  alarm: alertDto.alert_type === "alert",
  sensor_health: alertDto.sensor.health_rating,
  asset_state: alertDto.equipment.cached_active_status === "on" ? "On" : "Off",
  time: alertDto.created_at,
});
