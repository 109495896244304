import { authApiClient } from "../../app/api/authApi.client";
import {
  CreateEditUserDto,
  EditUserDetailsDto,
  UserDto,
  UserNotificationsDto,
} from "./users.api.dto";
import { mapUserNotifications } from "./users.api.mapper";

export const fetchUsers = async (): Promise<UserDto[]> => {
  try {
    const response = await authApiClient.get("/users");
    return response.data.users;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (user: CreateEditUserDto): Promise<void> => {
  try {
    const response = await authApiClient.post("/users", user);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/users/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async (): Promise<UserDto> => {
  try {
    const response = await authApiClient.get(`/users/details`);
    return response.data.user;
  } catch (error) {
    throw error;
  }
};

export const editUser = async (
  id: number | null,
  editUserDto: CreateEditUserDto | EditUserDetailsDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(`/users/${id}`, editUserDto);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editUserAvatar = async (
  id: number,
  formData: FormData
): Promise<string> => {
  try {
    const { data } = await authApiClient.post(`users/${id}/avatar`, formData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUserAvatar = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`users/${id}/avatar`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserNotifications = async (
  id: number
): Promise<UserNotificationsDto> => {
  try {
    const { data } = await authApiClient.get(`users/${id}/notifications`);

    return mapUserNotifications(data);
  } catch (error) {
    throw error;
  }
};

export const editUserNotifications = async (
  id: number,
  notificationsDto: UserNotificationsDto
): Promise<string> => {
  try {
    const { data } = await authApiClient.post(
      `users/${id}/notifications`,
      notificationsDto
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const unsubscribeUserEmailNotifications = async (): Promise<void> => {
  try {
    const { data } = await authApiClient.post("users/email-unsubscribe");
    return data;
  } catch (error) {
    throw error;
  }
};
