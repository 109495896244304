import React, { FC } from "react";

import { Box } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import { AssetTabsContainer } from "../../container/AssetTabsContainer";
import {
  ASSET_HISTORY,
  ASSET_NOTES,
  ASSET_REALTIME,
  ASSET_SENSORS,
  ASSET_TRENDS,
} from "../../../app/const/routes";
import { AssetRedirectRouteContainer } from "../../container/AssetRedirectRouteContainer";
import { AssetRealtimeView } from "../AssetRealtimeView/AssetRealtimeView";
import { AssetTrendsView } from "../AssetTrendsView/AssetTrendsView";
import { AssetNotesView } from "../AssetNotes/AssetNotesView";
import { AssetSensorsView } from "../AssetSensorsView/AssetSensorsView";
import { AssetPageHeaderContainer } from "../../container/AssetPageHeaderContainer";
import { AssetAlertHistoryView } from "../AssetAlertHistoryView/AssetAlertHistoryView";

export const AssetView: FC = () => (
  <StyledPage title="Asset" minHeight>
    <FullHeightContainer maxWidth={false}>
      <AssetPageHeaderContainer />
      <ConnectedShowForPermissions
        feature="assets"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        />
        <AssetTabsContainer />
        <Switch>
          <Route path={ASSET_REALTIME} component={AssetRealtimeView} exact />
          <Route path={ASSET_TRENDS} component={AssetTrendsView} exact />
          <Route path={ASSET_SENSORS} component={AssetSensorsView} exact />
          <Route path={ASSET_HISTORY} component={AssetAlertHistoryView} exact />
          <Route path={ASSET_NOTES} component={AssetNotesView} exact />
          <AssetRedirectRouteContainer />
        </Switch>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
