import styled from "styled-components";
import { ListItemIcon } from "@material-ui/core";
import { NavLink as RouterLink } from "react-router-dom";
import typography from "../../../theme/typography";
import { DRAWER_TEXT, SETTINGS_LIST_ITEM_COLOR } from "../../../theme/colors";

export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${DRAWER_TEXT};
  min-width: 50px;
`;

export const StyledSettingsListItemIcon = styled(ListItemIcon)`
  color: ${SETTINGS_LIST_ITEM_COLOR};
`;

export const StyledRouterLink = styled(RouterLink)`
  color: ${DRAWER_TEXT};
  font-weight: ${typography.sideNavigationLink.fontWeight};
  font-size: ${typography.sideNavigationLink.fontSize};
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};
  border-radius: 4px;
`;
