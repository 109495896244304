import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import {
  clearSearchSensorTypesState as clearSearchSensorTypesStateAction,
  searchSensorTypes as searchSensorTypesAction,
} from "../store/sensorTypes.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";
import { selectIsSensorTypesTableStatusPending } from "../store/sensorTypes.selector";

export const SensorTypesSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isSensorTypesPending = useSelector(
    selectIsSensorTypesTableStatusPending
  );

  useEffect(() => {
    return () => {
      dispatch(clearSearchSensorTypesStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchSensorTypesAction(value));

  return (
    <TableSearchInput
      label="Search Sensors"
      onSearch={debounce(onSearch, 200)}
      isPending={isSensorTypesPending}
    />
  );
};
