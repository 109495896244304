import styled from "styled-components";

import { Typography, CardContent } from "@material-ui/core";

export const StyledCardContent = styled(CardContent)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`}
    ${({ theme }) => `${theme.spacing(3)}px`}
    ${({ theme }) => `${theme.spacing(4)}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  > div:first-child {
    flex: 1;
  }
  > div:last-child {
    padding: 0 ${({ theme }) => `${theme.spacing(4)}px`};
    text-align: center;
  }
`;

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
`;

export const Subtitle = styled(Typography)`
  font-size: 14px;
  margin-bottom: ${({ theme }) => `${theme.spacing(8)}px`};
`;

export const ButtonsWrapper = styled.div`
  border-top: 1px solid #e3e3e9;
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  margin-top: 5px;
`;
