import React, { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { DialogProps } from "@material-ui/core/Dialog";
import { IModal, Modal } from "../Modal/Modal";
import { selectConnectedModalByName } from "../../store/connectedModal/connectedModal.selector";
import { ConnectedModalParamsModel } from "../../model/ConnectedModal";
import { AsyncStatus } from "../../enum/AsyncStatus";

interface IConnectedModal extends Omit<IModal, "open" | "Content" | "Header"> {
  name: string;
  size?: DialogProps["maxWidth"];
  Content: (
    isPending?: boolean,
    modalParams?: ConnectedModalParamsModel
  ) => ReactElement;
  Header?: (
    isPending?: boolean,
    modalParams?: ConnectedModalParamsModel
  ) => ReactElement;
}

export const ConnectedModal: FC<IConnectedModal> = ({
  name,
  size,
  Content,
  Header,
  ...rest
}) => {
  const connectedModal = useSelector(selectConnectedModalByName(name));
  const isOpen = !!(connectedModal && connectedModal.name === name);
  const isPending = !!(
    connectedModal && connectedModal.status === AsyncStatus.Pending
  );

  return (
    <Modal
      {...rest}
      open={isOpen}
      size={size}
      Header={() => Header && Header(isPending, connectedModal?.params)}
      Content={() => Content(isPending, connectedModal?.params)}
    />
  );
};
