import styled from "styled-components";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";

export const OuterWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  flex-direction: column;
  top: 0;
  bottom: 0;
`;

export const InnerWrapper = styled.span`
  display: flex;
  height: 1px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const StyledArrowDropUp = styled(ArrowDropUp)`
  position: absolute;
  top: -14px;
`;

export const StyledArrowDropDown = styled(ArrowDropDown)`
  position: absolute;
  bottom: -15px;
`;
