import { Tab } from "@material-ui/core";
import React, { FC } from "react";
import { ASSETS } from "../../../app/const/routes";
import { StyledConnectedTabs } from "./AssetTabs.style";

interface IAssetTabs {
  assetId: string;
}

export const AssetTabs: FC<IAssetTabs> = ({ assetId }) => (
  <StyledConnectedTabs>
    <Tab label="Realtime" value={`${ASSETS}/${assetId}/realtime`} />
    <Tab label="Trends" value={`${ASSETS}/${assetId}/trends`} />
    <Tab label="Sensors" value={`${ASSETS}/${assetId}/sensors`} />
    <Tab label="History" value={`${ASSETS}/${assetId}/history`} />
    <Tab label="Notes" value={`${ASSETS}/${assetId}/notes`} />
  </StyledConnectedTabs>
);
