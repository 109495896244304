import React, { FC, useState } from "react";
import { Typography, Box, Chip, Grid } from "@material-ui/core";
import { AssetDto } from "../../api/asset.api.dto";
import { StyledButton, StyledCardText } from "./AssetRealtimeWorkOrder.style";
import {
  StyledCloseIcon,
  StyledDialogTitleContainer,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import { Modal } from "../../../app/component/Modal/Modal";

export interface IAssetRealtimeTagsProps {
  asset: AssetDto | null;
}

export const AssetRealtimeTags: FC<IAssetRealtimeTagsProps> = ({ asset }) => {
  const maxTags = 3;
  const [modalOpen, setModalOpen] = useState(false);

  const tagText = () => {
    return `Tags: ${asset?.tags
      .slice(0, maxTags)
      .map((tag) => `#${tag.name}`)
      .join(", ")}`;
  };

  const moreTags = () => {
    const remainingTags = asset?.tags.slice(maxTags);
    if (remainingTags !== undefined && remainingTags.length !== 0) {
      return (
        <StyledButton
          color="primary"
          disableRipple
          onClick={() => setModalOpen(true)}
        >
          {remainingTags.length} more
        </StyledButton>
      );
    }
    return null;
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>
        <Typography variant="h2">Tags</Typography>
      </Box>
      <Box>
        <StyledIconButton
          aria-label="close"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = () => {
    return (
      <Box p={2}>
        <Grid container spacing={2}>
          {asset?.tags.map((tag) => (
            <Grid item>
              <Chip key={tag.id} label={`#${tag.name}`} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Modal
        onClose={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
        Content={ModalContent}
        Header={ModalHeader}
      />
      <Box display="flex" flexDirection="row">
        <StyledCardText>
          {tagText()} {moreTags()}
        </StyledCardText>
      </Box>
    </>
  );
};
