import React, {
  FC,
  SetStateAction,
  Dispatch,
  useEffect,
  useCallback,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from "@material-ui/core";
import { ASSET_TRENDS_COLORS } from "../../../app/theme/colors";
import { ChannelGraphData } from "../../api/asset.api.dto";

interface IAssetTrendsSensorSelector {
  graphData: ChannelGraphData[];
  setSelectedSensors: Dispatch<SetStateAction<number[] | undefined>>;
  selectedSensors: number[] | undefined;
}

export const AssetTrendsSensorSelector: FC<IAssetTrendsSensorSelector> = ({
  graphData,
  setSelectedSensors,
  selectedSensors,
}) => {
  const [allSensorsSelected, setAllSensorsSelected] = useState(true);

  const setSensors = useCallback(() => {
    if (selectedSensors === undefined && graphData.length > 0) {
      setSelectedSensors(
        graphData.map((series: ChannelGraphData) => {
          return series.index;
        })
      );
    }
  }, [selectedSensors, setSelectedSensors, graphData]);

  useEffect(() => {
    setSensors();
  }, [setSensors]);

  const toggleSensor = (index: number) => {
    if (selectedSensors?.includes(index)) {
      setAllSensorsSelected(false);
      setSelectedSensors(
        selectedSensors.filter((value) => {
          return value !== index;
        })
      );
    } else {
      setSelectedSensors([...(selectedSensors ?? []), index]);
    }
  };

  const toggleAllSensors = () => {
    if (allSensorsSelected) {
      setSelectedSensors([]);
      setAllSensorsSelected(false);
    } else {
      setSelectedSensors(
        graphData.map((series: ChannelGraphData) => {
          return series.index;
        })
      );
      setAllSensorsSelected(true);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box alignSelf="center" flexGrow={1} m={1}>
          <Typography variant="h3">Select Sensors</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box m={1} mb={-1}>
            <Chip
              label="View All"
              onClick={() => {
                toggleAllSensors();
              }}
              variant={allSensorsSelected ? "default" : "outlined"}
            />
          </Box>
          <Box m={1} mb={-1}>
            <Divider
              orientation="vertical"
              flexItem
              style={{ height: "70px" }}
            />
          </Box>
          <Box display="flex" flexWrap="wrap">
            {graphData.map((series: ChannelGraphData) => (
              <Box key={series.index} m={1} mb={-1}>
                <Chip
                  avatar={
                    <Avatar
                      style={
                        selectedSensors?.includes(series.index)
                          ? {
                              color: "white",
                              backgroundColor:
                                ASSET_TRENDS_COLORS[series.index],
                            }
                          : {
                              color: ASSET_TRENDS_COLORS[series.index],
                              border: `0.1px solid ${
                                ASSET_TRENDS_COLORS[series.index]
                              }`,
                              backgroundColor: "unset",
                            }
                      }
                    >
                      {series.index}
                    </Avatar>
                  }
                  label={series.title}
                  variant={
                    selectedSensors?.includes(series.index)
                      ? "default"
                      : "outlined"
                  }
                  onClick={() => {
                    toggleSensor(series.index);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
