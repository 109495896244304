import * as yup from "yup";

export const NAME_CHAR_LIMIT = 32;
export const DESCRIPTION_CHAR_LIMIT = 500;

export const EditAssetDtoValidation = yup.object().shape({
  id: yup.number().required(),
  team_ids: yup.array().of(yup.number()).optional(),
  category_ids: yup.array().of(yup.number()).optional(),
  tags: yup.array().of(yup.string()).optional(),
  client_id: yup.number().optional(),
  location_id: yup.number().optional(),
  name: yup.string().max(NAME_CHAR_LIMIT).required(),
  manufacturer: yup.string().optional(),
  model: yup.string().optional(),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  process: yup.string().optional(),
  is_template: yup.boolean().optional(),
  serial_number: yup.string().optional(),
  is_enabled: yup.boolean().optional(),
  health_rating: yup.string().optional(),
  heatlh_rating_update_at: yup.string().optional(),
  is_todo_list: yup.boolean().optional(),
  snooze_todo: yup.string().optional(),
  deleted_at: yup.string().optional(),
  created_at: yup.string().optional(),
  updated_at: yup.string().optional(),
  live_graph_svg: yup.number().optional(),
  health_is_enabled: yup.boolean().optional(),
});

export const AddAssetDtoValidation = yup.object().shape({
  id: yup.number().required(),
  team_ids: yup.array().of(yup.number()).optional(),
  category_ids: yup.array().of(yup.number()).optional(),
  tags: yup.array().of(yup.string()).optional(),
  client_id: yup.number().optional(),
  location_id: yup.number().optional(),
  name: yup.string().max(NAME_CHAR_LIMIT).required(),
  manufacturer: yup.string().optional(),
  model: yup.string().optional(),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  process: yup.string().optional(),
  is_template: yup.boolean().optional(),
  serial_number: yup.string().optional(),
  is_enabled: yup.boolean().optional(),
  health_rating: yup.string().optional(),
  heatlh_rating_update_at: yup.string().optional(),
  is_todo_list: yup.boolean().optional(),
  snooze_todo: yup.string().optional(),
  deleted_at: yup.string().optional(),
  created_at: yup.string().optional(),
  updated_at: yup.string().optional(),
  live_graph_svg: yup.number().optional(),
  health_is_enabled: yup.boolean().optional(),
});
