import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppDispatch } from "../../app/store";
import { UsageGraph } from "../component/Analytics/UsageGraph";
import {
  selectAssetUsageData,
  selectAssetUsageDataStatusPending,
} from "../store/asset.selector";
import { AssetDto, AssetUsageDto } from "../api/asset.api.dto";
import { fetchAssetUsageData as fetchAssetUsageDataAction } from "../store/asset.action";
import { USAGE_GRAPH_DATE_FORMAT } from "../const/AssetGraphs.const";

interface IAnalyticsUsageContainer {
  selectedAssets: AssetDto[];
  startEpoch: number;
  endEpoch: number;
  usageData: AssetUsageDto[];
  setUsageData: (usage: AssetUsageDto[]) => void;
}

export const AnalyticsUsageContainer: FC<IAnalyticsUsageContainer> = ({
  selectedAssets,
  startEpoch,
  endEpoch,
  usageData,
  setUsageData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const assetUsageData = useSelector(selectAssetUsageData);
  const assetUsageDataIsPending = useSelector(
    selectAssetUsageDataStatusPending
  );
  const [multiplier, setMultiplier] = useState<number>(1);
  const [usageUnit, setUsageUnit] = useState<string>("kWh");

  useEffect(() => {
    dispatch(
      fetchAssetUsageDataAction({
        assetIds: [],
        startEpoch: 0,
        endEpoch: 0,
        usageUnit: "kWh",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setUsageData(assetUsageData);
  }, [setUsageData, assetUsageData]);

  useEffect(() => {
    const assetIds = selectedAssets.map((asset: AssetDto) => {
      return asset.id;
    });
    if (assetIds.length > 0) {
      dispatch(
        fetchAssetUsageDataAction({
          assetIds,
          startEpoch,
          endEpoch,
          usageUnit,
        })
      );
    }
    setUsageData([]);
  }, [dispatch, setUsageData, selectedAssets, startEpoch, endEpoch, usageUnit]);

  const formatDate = (tickItem: number | string) => {
    return moment(tickItem).format(USAGE_GRAPH_DATE_FORMAT);
  };

  return (
    <UsageGraph
      isGraphsPending={assetUsageDataIsPending}
      formatDate={formatDate}
      usageData={usageData}
      setMultiplier={setMultiplier}
      multiplier={multiplier}
      setUsageUnit={setUsageUnit}
      usageUnit={usageUnit}
    />
  );
};
