import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { MaintenanceState } from "./maintenance.store";
import { PageModel } from "../../app/model/Pagination";
import { MaintenanceDto } from "../api/maintenance.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectMaintenanceState = createSelector(
  selectSelf,
  (state: RootState): MaintenanceState => state.maintenance
);

export const selectMaintenancesState = createSelector(
  selectMaintenanceState,
  (maintenance: MaintenanceState): AsyncStateModel<MaintenanceDto[]> =>
    maintenance.maintenances
);

export const selectMaintenances = createSelector(
  selectMaintenancesState,
  (maintenancePage: AsyncStateModel<MaintenanceDto[]>): MaintenanceDto[] =>
    maintenancePage.data
);

export const selectMaintenancesStatus = createSelector(
  selectMaintenancesState,
  (maintenancePage): AsyncStatus => maintenancePage.status
);

export const selectIsMaintenancesStatusPending = createSelector(
  selectMaintenancesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
