import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { PartnerDto } from "../api/partners.api.dto";

export interface PartnersState {
  fetchPartners: AsyncStateModel<PartnerDto[]>;
  deletePartner: AsyncStateModel<null>;
  editPartner: AsyncStateModel<null>;
  createPartner: AsyncStateModel<null>;
  searchPartners: string;
}

export const partnersState: PartnersState = {
  fetchPartners: {
    status: AsyncStatus.Void,
    data: [],
  },
  deletePartner: {
    status: AsyncStatus.Void,
    data: null,
  },
  editPartner: {
    status: AsyncStatus.Void,
    data: null,
  },
  createPartner: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchPartners: "",
};
