export default {
  input: {
    paddingTop: "27px",
    paddingRight: "12px",
    paddingBottom: "10px",
    paddingLeft: "12px",
  },
  iconButton: {
    paddingTop: "8px",
    paddingRight: "8px",
    paddingBottom: "8px",
    paddingLeft: "8px",
  },
};
