import React, { FC, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ResponsiveContainer } from "recharts";
import { Export } from "../../../app/component/Icons/ExportIcon";
import { AssetUsageDto } from "../../api/asset.api.dto";
import {
  ExportIconButton,
  LoaderOverlay,
  StyledTooltipDiv,
} from "./AnalyticsGraphs.style";
import { ASSET_USAGE_COLORS } from "../../../app/theme/colors";

interface IUsageGraph {
  usageData: AssetUsageDto[];
  isGraphsPending: boolean;
  formatDate: (tickItem: number | string) => string;
  setMultiplier: React.Dispatch<React.SetStateAction<number>>;
  multiplier: number;
  setUsageUnit: React.Dispatch<React.SetStateAction<string>>;
  usageUnit: string;
}

export const UsageGraph: FC<IUsageGraph> = ({
  usageData,
  isGraphsPending,
  formatDate,
  setMultiplier,
  multiplier,
  setUsageUnit,
  usageUnit,
}) => {
  const [usageType, setUsageType] = useState<string>("power");

  const useStyles = makeStyles(() =>
    createStyles({
      endAdornment: {
        "& .MuiSvgIcon-root": {
          display: "none",
        },
      },
      selectClass: {
        paddingTop: "10px",
      },
    })
  );
  const classes = useStyles();

  const generateGraphExportPdf = () => {
    const element = document.getElementById("usage-graph");

    if (element) {
      html2canvas(element, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "px");

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("UsageGraph.pdf");
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const sensorData = payload[0].payload;
      return (
        <StyledTooltipDiv>
          <span
            style={{ color: `${payload[0].color}` }}
          >{`${payload[0].name}`}</span>
          <br />
          <span>{`${formatDate(payload[0].payload.xdata)}: `}</span>
          {`${payload[0].value}`}
        </StyledTooltipDiv>
      );
    }

    return null;
  };

  const barChart = (asset: AssetUsageDto) => {
    const usageTypeSensors = asset.sensors.filter(
      (sensor) => sensor.usageType === usageType
    );

    const option = {
      xAxis: {
        type: "category",
        data: usageTypeSensors.map((sensor) => sensor?.sensor_name),
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: 16,
        },
      },
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: 16,
          },
          axisTick: {
            show: true,
          },
        },
      ],
      tooltip: {
        triggerOn: "mousemove",
        trigger: "item",
      },
      series: [
        {
          data: usageTypeSensors.map((sensor, index) => {
            return sensor.usageType === usageType
              ? {
                  value: sensor.data,
                  itemStyle: { color: ASSET_USAGE_COLORS[index % 3] },
                }
              : null;
          }),
          type: "bar",
        },
      ],
    };

    return option;
  };

  return (
    <Card>
      {isGraphsPending && (
        <LoaderOverlay>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      <CardContent id="usage-graph">
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <Box alignSelf="center" flexGrow={1}>
            <Typography variant="h2">Usage</Typography>
          </Box>
          <Box alignSelf="center" mr={1}>
            <ExportIconButton
              data-html2canvas-ignore="true"
              startIcon={<Export />}
              pending={isGraphsPending}
              onClick={generateGraphExportPdf}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box m={2} overflow="auto">
            <ToggleButtonGroup
              value={usageType}
              color="primary"
              exclusive
              aria-label="outlined primary button group"
              onChange={(event: React.MouseEvent, value: string) => {
                setUsageType(value);
                return value === "power"
                  ? setUsageUnit("kWh")
                  : setUsageUnit("gal");
              }}
            >
              <ToggleButton value="power">power</ToggleButton>
              <ToggleButton value="flow">Flow</ToggleButton>
              <ToggleButton value="volume" disabled>
                Volume
              </ToggleButton>
              <ToggleButton value="pressure" disabled>
                Pressure
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box m={-0.5} mt={-0.5}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={usageUnit}
              disabled={usageType === "power"}
              classes={{ select: classes.selectClass }}
              className={usageType === "power" ? classes.endAdornment : ""}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setUsageUnit(String(event.target.value));
              }}
            >
              {usageType === "flow"
                ? [
                    <MenuItem value="gal">Gallons</MenuItem>,
                    <MenuItem value="L">Liters</MenuItem>,
                    <MenuItem value="ac-ft.">Acre-Foot</MenuItem>,
                  ]
                : null}
              {usageType === "power" ? (
                <MenuItem value="kWh">kWh</MenuItem>
              ) : null}
            </Select>
          </Box>
        </Box>
        <Grid container direction="row" spacing={2}>
          {usageData.length > 0
            ? usageData.map((assets) => {
                const usageAssets = assets.sensors.filter(
                  (sensor) => sensor.usageType === usageType
                );
                return usageAssets.length > 0 ? (
                  <ResponsiveContainer width="100%" height={300}>
                    <ReactECharts option={barChart(assets)} />
                  </ResponsiveContainer>
                ) : null;
              })
            : null}
        </Grid>
      </CardContent>
    </Card>
  );
};
