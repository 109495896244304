import React, { FC } from "react";
import { useFormik } from "formik";
import { Divider, Grid, TextField } from "@material-ui/core";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import { Form } from "./AddAssetNoteModal.style";

export interface IAddAssetNoteModalFormProps {
  onSubmit: (text: string) => void;
  isPending: boolean;
  displayActionButtons: (disabled: boolean) => void;
}

export const AddAssetNoteModalForm: FC<IAddAssetNoteModalFormProps> = ({
  onSubmit,
  displayActionButtons,
  isPending,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      text: "",
    },
    onSubmit: (values: { text: string }) => {
      onSubmit(values.text);
    },
  });

  return (
    <StyledModalContent>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              id="text"
              name="text"
              label="Note text"
              variant="filled"
              fullWidth
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
              disabled={isPending}
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons(!formik.dirty)}
        </Grid>
      </Form>
    </StyledModalContent>
  );
};
