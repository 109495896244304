import React, { FC } from "react";
import { useFormik } from "formik";
import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import { DropzoneContainer, Form } from "./AddAssetAttachmentModal.style";

export interface IAddAssetNoteModalFormProps {
  onSubmit: (name: string, file: File) => void;
  isPending: boolean;
  displayActionButtons: (disabled: boolean) => void;
}

export const AddAssetAttachmentModalForm: FC<IAddAssetNoteModalFormProps> = ({
  onSubmit,
  displayActionButtons,
  isPending,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ["image/png", "image/jpg", "image/jpeg", "application/pdf"],
    multiple: false,
  });
  const files = acceptedFiles.map((file) => (
    <Typography>{file.name}</Typography>
  ));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    onSubmit: (values) => {
      onSubmit(values.name, acceptedFiles[0]);
    },
  });

  return (
    <StyledModalContent>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled={isPending}
            />
          </Grid>
          <Grid item xs={12}>
            <DropzoneContainer>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {acceptedFiles.length > 0 ? (
                  files
                ) : (
                  <>
                    <Typography>
                      Drag and drop some files here, or click to select files
                    </Typography>
                    <Typography>File must be less than 200 MB</Typography>
                    <Typography>(PNG, JPG, PDF)</Typography>
                  </>
                )}
              </div>
            </DropzoneContainer>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons(!formik.dirty || acceptedFiles.length < 1)}
        </Grid>
      </Form>
    </StyledModalContent>
  );
};
