import React, { FC } from "react";
import { Column } from "react-table";
import { FormControlLabel, Typography } from "@material-ui/core";
import { Checkbox } from "../../../app/component/Checkbox/Checkbox";
import { Table } from "../../../app/component/Table/Table";
import {
  CenteredCell,
  UncheckedBoxIcon,
  CheckedBoxIcon,
  CheckIcon,
  BlankIcon,
} from "../../../app/component/Table/Table.style";
import {
  RolePermissions,
  RolePermissionValues,
  RoleDevicePermissionValues,
} from "../../api/roles.api.dto";
import {
  RolePermissionFeatures as RolePermissionFeatureLabels,
  RolePermissionValues as RolePermissionValueLabels,
  RoleDevicePermissionValues as RoleDevicePermissionOptionLabels,
} from "../../enum/roles.enum";

export interface IRoleModalFormPermissions {
  formValues: RolePermissions;
  onChange: (event: React.ChangeEvent<unknown>) => void;
  setValues: (permissions: RolePermissions) => void;
  editView: boolean;
}

export const RoleModalFormPermissions: FC<IRoleModalFormPermissions> = ({
  formValues,
  onChange,
  setValues,
  editView,
}) => {
  const values: {
    value: RolePermissionValues;
    label: string;
  }[] = [
    {
      value: "create",
      label: RolePermissionValueLabels.Create,
    },
    {
      value: "update",
      label: RolePermissionValueLabels.Update,
    },
    {
      value: "delete",
      label: RolePermissionValueLabels.Delete,
    },
    {
      value: "read",
      label: RolePermissionValueLabels.Read,
    },
  ];

  const tableRows: {
    feature: keyof RolePermissions;
    label: string;
    values: RolePermissionValues[];
  }[] = [
    {
      feature: "partners",
      label: RolePermissionFeatureLabels.Partners,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "clients",
      label: RolePermissionFeatureLabels.Clients,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "roles",
      label: RolePermissionFeatureLabels.Roles,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "teams",
      label: RolePermissionFeatureLabels.Teams,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "users",
      label: RolePermissionFeatureLabels.Users,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "locations",
      label: RolePermissionFeatureLabels.Locations,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "assets",
      label: RolePermissionFeatureLabels.Assets,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "devices",
      label: RolePermissionFeatureLabels.Devices,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "sensors",
      label: RolePermissionFeatureLabels.Sensors,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "channels",
      label: RolePermissionFeatureLabels.Channels,
      values: ["create", "update", "delete", "read"],
    },
    {
      feature: "alerts",
      label: RolePermissionFeatureLabels.Alerts,
      values: ["delete", "read"],
    },
  ];

  const tableColumns: Column[] = [
    {
      Header: "Features",
      accessor: "label",
      disableSortBy: true,
    },
    ...values.map(({ value, label }) => ({
      Header: () => <CenteredCell>{label}</CenteredCell>,
      id: `permissions-${value}`,
      disableSortBy: true,
      Cell: ({
        row: {
          original: { feature, values: featureValues },
        },
      }: {
        row: { original: typeof tableRows[0] };
      }) => (
        <CenteredCell>
          <Checkbox
            id={`permissions-${feature}-${value}`}
            name={`permissions.${feature}`}
            value={value}
            icon={
              editView ? (
                <UncheckedBoxIcon fontSize="small" />
              ) : (
                <BlankIcon fontSize="small" />
              )
            }
            checkedIcon={
              editView ? (
                <CheckedBoxIcon fontSize="small" />
              ) : (
                <CheckIcon fontSize="small" />
              )
            }
            checked={formValues[feature] && formValues[feature].includes(value)}
            onChange={onChange}
            disabled={!featureValues.includes(value) || !editView}
          />
        </CenteredCell>
      ),
    })),
    ...(editView
      ? [
          {
            Header: () => <CenteredCell>Select All</CenteredCell>,
            id: "permissions-all",
            disableSortBy: true,
            Cell: ({
              row: {
                original: { feature, values: featureValues },
              },
            }: {
              row: { original: typeof tableRows[0] };
            }) => (
              <CenteredCell>
                <Checkbox
                  id={`permissions-${feature}-all`}
                  icon={
                    editView ? (
                      <UncheckedBoxIcon fontSize="small" />
                    ) : (
                      <BlankIcon fontSize="small" />
                    )
                  }
                  checkedIcon={
                    editView ? (
                      <CheckedBoxIcon fontSize="small" />
                    ) : (
                      <CheckIcon fontSize="small" />
                    )
                  }
                  checked={
                    formValues[feature] &&
                    featureValues.every(
                      (value) =>
                        formValues[feature] &&
                        formValues[feature].includes(value)
                    )
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setValues({
                        ...(formValues || {}),
                        [feature]: [
                          ...(formValues[feature] || []),
                          ...featureValues.filter(
                            (value) =>
                              !formValues[feature] ||
                              !formValues[feature].includes(value)
                          ),
                        ],
                      });
                    } else {
                      setValues({
                        ...(formValues || {}),
                        [feature]: [
                          ...(formValues[feature]
                            ? formValues[feature].filter(
                                (formValue: string) =>
                                  !(featureValues as string[]).includes(
                                    formValue
                                  )
                              )
                            : []),
                        ],
                      });
                    }
                  }}
                  disabled={!editView}
                />
              </CenteredCell>
            ),
          },
        ]
      : []),
  ];

  return <Table columns={tableColumns} data={tableRows} hover formView />;
};

export const RoleModalFormDevicePermissions: FC<IRoleModalFormPermissions> = ({
  formValues,
  onChange,
  setValues,
  editView,
}) => {
  const deviceValues: {
    value: RoleDevicePermissionValues;
    label: string;
  }[] = [
    {
      value: "ota",
      label: RoleDevicePermissionOptionLabels.Ota,
    },
    {
      value: "reboot",
      label: RoleDevicePermissionOptionLabels.Reboot,
    },
    {
      value: "actuate",
      label: RoleDevicePermissionOptionLabels.Actuate,
    },
    {
      value: "save-as-template",
      label: RoleDevicePermissionOptionLabels.SaveAsTemplate,
    },
    {
      value: "update-device-id",
      label: RoleDevicePermissionOptionLabels.UpdateDeviceId,
    },
    {
      value: "clear-data",
      label: RoleDevicePermissionOptionLabels.ClearData,
    },
    {
      value: "reset-runtime",
      label: RoleDevicePermissionOptionLabels.ResetRuntime,
    },
  ];

  const deviceTableColumns: Column[] = [
    {
      Header: () => {
        const possibleValues = deviceValues.map(({ value }) => value);
        return (
          <FormControlLabel
            control={
              <Checkbox
                id="device-permissions-all"
                placement="table-cell"
                icon={
                  editView ? (
                    <UncheckedBoxIcon fontSize="small" />
                  ) : (
                    <BlankIcon fontSize="small" />
                  )
                }
                checkedIcon={
                  editView ? (
                    <CheckedBoxIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )
                }
                checked={
                  formValues.devices &&
                  possibleValues.every(
                    (value) =>
                      formValues.devices && formValues.devices.includes(value)
                  )
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    setValues({
                      ...(formValues || {}),
                      devices: [
                        ...(formValues.devices || []),
                        ...possibleValues.filter(
                          (value) =>
                            !formValues.devices ||
                            !formValues.devices.includes(value)
                        ),
                      ],
                    });
                  } else {
                    setValues({
                      ...(formValues || {}),
                      devices: [
                        ...(formValues.devices
                          ? formValues.devices.filter(
                              (formValue: string) =>
                                !(possibleValues as string[]).includes(
                                  formValue
                                )
                            )
                          : []),
                      ],
                    });
                  }
                }}
                disabled={!editView}
              />
            }
            label={
              <Typography component="span" variant="body2">
                <strong>Select All</strong>
              </Typography>
            }
          />
        );
      },
      id: "device-permissions-all",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { value, label },
        },
      }: {
        row: { original: typeof deviceValues[0] };
      }) => (
        <FormControlLabel
          control={
            <Checkbox
              id={`device-permissions-${value}`}
              placement="table-cell"
              name="permissions.devices"
              value={value}
              icon={
                editView ? (
                  <UncheckedBoxIcon fontSize="small" />
                ) : (
                  <BlankIcon fontSize="small" />
                )
              }
              checkedIcon={
                editView ? (
                  <CheckedBoxIcon fontSize="small" />
                ) : (
                  <CheckIcon fontSize="small" />
                )
              }
              checked={formValues.devices && formValues.devices.includes(value)}
              onChange={onChange}
              disabled={!editView}
            />
          }
          label={
            <Typography component="span" variant="body2">
              {label}
            </Typography>
          }
        />
      ),
    },
  ];

  return (
    <Table
      columns={deviceTableColumns}
      data={deviceValues}
      hover
      formView
      noExtraHeaderPadding
    />
  );
};
