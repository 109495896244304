import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { Button } from "../../app/component/Button/Button";
import { AppDispatch } from "../../app/store";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_TASK_MODAL } from "../../app/const/modals";
import { TaskModal } from "../component/TaskModel/TaskModal";
import {
  CreateTaskDto,
  TaskType,
  TaskCommentDto,
} from "../api/workorders.api.dto";
import {
  createComment,
  createTask,
  deleteComment,
} from "../store/workOrders.action";
import { fetchProfile } from "../../auth/store/auth.action";
import { TaskStatus } from "../enum/workorders.enum";
import { ConnectedHideForRoleTask } from "../../app/component/UserManagement/ConnectedHideForRoleTask";
import { ITaskForm } from "../component/TaskModel/TaskModalForm";
import { selectProfileData } from "../../auth/store/auth.selector";
import { fetchTeams } from "../../teams/store/teams.action";
import { fetchUsers } from "../../users/store/users.action";
import { fetchAssets } from "../../asset/store/asset.action";
import {
  selectIsTeamsStatusPending,
  selectTeamsData,
} from "../../teams/store/teams.selector";
import {
  selectIsUsersStatusPending,
  selectUsersData,
} from "../../users/store/users.selector";
import {
  selectAssetsData,
  selectIsAssetsStatusPending,
} from "../../asset/store/asset.selector";
import { selectSelectedClientId } from "../store/workOrders.selector";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { fetchClients } from "../../clients/store/clients.action";
import { UserRole } from "../../app/enum/UserRole";

export const mapTaskFormToCreateTaskDto = (
  taskForm: ITaskForm
): CreateTaskDto => {
  return {
    ...taskForm,
    start_date: taskForm.start_date?.format("yyyy-MM-DD"),
    finish_date: taskForm.finish_date?.format("yyyy-MM-DD"),
    assigned_user: taskForm.assigned_user
      ? { id: taskForm.assigned_user.id }
      : null,
    assigned_team: taskForm.assigned_team
      ? { id: taskForm.assigned_team.id }
      : null,
    asset: taskForm.asset ? { id: taskForm.asset.id } : null,
    watchers_users: taskForm.watchers
      .filter((watcher) => watcher.isUser)
      .map((filteredWatcher) => ({ id: filteredWatcher.id })),
    watchers_team: taskForm.watchers
      .filter((watcher) => !watcher.isUser)
      .map((filteredWatcher) => ({ id: filteredWatcher.id })),
    sub_tasks: taskForm.sub_tasks.map((subTask) =>
      JSON.stringify({
        ...subTask,
        formId: undefined,
        assigned_team: subTask.assigned_team
          ? { id: subTask.assigned_team.id }
          : null,
        assigned_user: subTask.assigned_user
          ? { id: subTask.assigned_user.id }
          : null,
      })
    ),
  };
};

export interface ITaskModalContainer {
  name: string;
  type?: TaskType;
}

export const AddTaskModalContainer: FC<ITaskModalContainer> = ({
  name,
  type,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const teams = useSelector(selectTeamsData);
  const users = useSelector(selectUsersData);
  const assets = useSelector(selectAssetsData);
  const clients = useSelector(selectClientsData);

  const isTeamsStatusPending = useSelector(selectIsTeamsStatusPending);
  const isUsersStatusPending = useSelector(selectIsUsersStatusPending);
  const isAssetsStatusPending = useSelector(selectIsAssetsStatusPending);
  const isClientsStatusPending = useSelector(selectIsClientsStatusPending);
  const selectedClientId = useSelector(selectSelectedClientId);
  const openModal = () => dispatch(openModalAction({ name: ADD_TASK_MODAL }));
  const closeModal = () => dispatch(closeModalAction({ name: ADD_TASK_MODAL }));
  const profileData = useSelector(selectProfileData);

  const onCreateTask = (values: ITaskForm) => {
    const taskToDispatch = mapTaskFormToCreateTaskDto(values);
    dispatch(createTask(taskToDispatch));
  };

  const createCommentFn = (taskCommentDto: TaskCommentDto) =>
    dispatch(createComment(taskCommentDto));

  const deleteCommentFN = (taskCommentDto: TaskCommentDto) =>
    dispatch(deleteComment(taskCommentDto));

  useEffect(() => {
    dispatch(fetchProfile);
    dispatch(fetchTeams());
    dispatch(fetchUsers());
    dispatch(fetchAssets());
    dispatch(fetchClients());
  }, [dispatch]);

  return (
    <>
      {/* <ConnectedShowForPermissions
        feature="tasks"
        permissions={{ allOf: ["create"] }}
      > */}
      <ConnectedHideForRoleTask roles={[UserRole.Member]}>
        <Button
          color="primary"
          variant="contained"
          pending={!profileData}
          startIcon={<Add />}
          onClick={openModal}
          disabled={!selectedClientId}
        >
          Create WO
        </Button>
      </ConnectedHideForRoleTask>
      {/* </ConnectedShowForPermissions> */}
      {profileData && (
        <TaskModal
          onCommentAdd={createCommentFn}
          onCommentDelete={deleteCommentFN}
          type={type}
          name={name}
          onClose={closeModal}
          onSubmit={onCreateTask}
          currentUser={profileData.user}
          isPending={
            isTeamsStatusPending ||
            isUsersStatusPending ||
            isAssetsStatusPending ||
            isClientsStatusPending
          }
          teams={teams.filter((team) => team.client_id === selectedClientId)}
          users={users.filter((user) => user.client_id === selectedClientId)}
          assets={assets.filter(
            (asset) => asset.client_id === selectedClientId
          )}
          client={clients.filter((client) => client.id === selectedClientId)}
        />
      )}
    </>
  );
};
