import styled from "styled-components";
import { Card, Avatar, Typography, Chip } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";
import { ReactComponent as ManualIcon } from "../../../../assets/icons/wo-manual.svg";
import { BACKGROUND_DEFAULT, TEXT_PRIMARY } from "../../../app/theme/colors";

export const ToggleButton = styled(Button)`
  border-radius: 20px;
`;

export const StyledAvatar = styled(Avatar)`
  height: ${({ theme }) => `${theme.spacing(4)}px`};
  width: ${({ theme }) => `${theme.spacing(4)}px`};
  margin-left: ${({ theme }) => `${theme.spacing(1)}px`};
  font-size: 12px;
  font-weight: bold;
  background-color: ${BACKGROUND_DEFAULT};
  color: ${TEXT_PRIMARY};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
`;

export const StyledInfo = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
`;

export const StyledManualIcon = styled(ManualIcon)`
  color: #1f1e4d;
`;

export const StyledChip = styled(Chip)`
  background-color: #e4e4ea;
  span {
    color: #1f1e4d;
  }
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
`;
