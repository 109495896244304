import { authApiClient } from "../../app/api/authApi.client";
import {
  NotificationDto,
  ApiNotificationDto,
} from "./apiNotifications.api.dto";

export const markNotificationRead = async (
  notification: NotificationDto
): Promise<NotificationDto[]> => {
  try {
    const {
      data: { notifications },
    } = await authApiClient.post(`/notifications/${notification.id}`);
    return notifications;
  } catch (error) {
    throw error;
  }
};

export const markNotificationsReadUntil = async (
  timestamp: Date
): Promise<NotificationDto[]> => {
  try {
    const {
      data: { notifications },
    } = await authApiClient.post("/notifications/until", {
      timestamp,
    });
    return notifications;
  } catch (error) {
    throw error;
  }
};

export const fetchApiNotifications = async (): Promise<NotificationDto[]> => {
  try {
    const {
      data: { notifications },
    } = await authApiClient.get<ApiNotificationDto>(`/notifications`);
    return notifications;
  } catch (error) {
    throw error;
  }
};
