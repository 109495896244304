import { createAsyncThunk } from "@reduxjs/toolkit";

import withError from "../../app/util/with-thunk-error";
import { IndustryDto } from "../api/industry.api.dto";
import { fetchIndustries as fetchIndustriesRequest } from "../api/industry.api";

export const fetchIndustries = createAsyncThunk(
  "industry/fetchIndustries",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<IndustryDto[]> => {
      return fetchIndustriesRequest();
    }
  )
);
