export enum AlertTypes {
  ALERT_TYPE_ABOVE_VALUE = "Above Value",
  ALERT_TYPE_BELOW_VALUE = "Below Value",
  ALERT_TYPE_IN_RANGE = "In Range",
  ALERT_TYPE_OUT_RANGE = "Out of Range",
}

export enum AlarmTriggeredStatus {
  Yes = "Yes",
  No = "No",
}
