import styled from "styled-components";
import { Card } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";

export const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.card.secondary};
`;

export const StyledCardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({ theme }) => theme.overrides.MuiAppBar.colorPrimary.color};
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  line-height: inherit;
  padding: 0;
  font-size: 11px;
`;

export const StyledCardText = styled.div`
  font-size: 11px;
  color: #707070;
`;
