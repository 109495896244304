import styled from "styled-components";
import { Chip, ChipProps } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";

export interface IChip extends ChipProps {
  selected: boolean;
}

export const StyledChip = styled(Chip)<IChip>`
  background-color: ${({ selected }) => (selected ? "#7678ed" : "#e2e4f3")};
  span {
    color: ${({ selected }) => (selected ? "#e2e4f3" : "#9092ee")};
  }
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  cursor: pointer;
`;
