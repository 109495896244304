import * as yup from "yup";

export const NAME_CHAR_LIMIT = 32;
export const SENSOR_TYPE_DESCRIPTION_CHAR_LIMIT = 32;
export const DESCRIPTION_CHAR_LIMIT = 500;

export const assetSensorModalFormValidation = yup.object().shape({
  friendly_name: yup
    .string()
    .max(NAME_CHAR_LIMIT)
    .required("Sensor Name is required"),
  sensor_type_id: yup.mixed().required("Sensor is required"),
  source_voltage_ac: yup.mixed().when("$sensor_type_name", {
    is: "Amperage AC",
    then: yup
      .number()
      .typeError("Source Voltage (AC) is required")
      .required("Source Voltage (AC) is required"),
  }),
  source_voltage_dc: yup.mixed().when("$sensor_type_name", {
    is: "Amperage DC",
    then: yup
      .number()
      .typeError("Source Voltage (DC) is required")
      .required("Source Voltage (DC) is required"),
  }),
  sensor_type_description: yup
    .string()
    .max(SENSOR_TYPE_DESCRIPTION_CHAR_LIMIT)
    .optional(),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  calibration_value: yup.number().optional(),
  warning_is_enabled: yup.boolean().optional(),
  alert_type: yup.mixed().when("warning_is_enabled", {
    is: true,
    then: yup.number().typeError("Alarm Type is required"),
  }),
  warning_point_max: yup.mixed().when("warning_is_enabled", {
    is: true,
    then: yup
      .number()
      .typeError("Value is required")
      .required("Value is required"),
  }),
  warning_point_min: yup.mixed().when("alert_type", {
    is: (alert_type: number) => alert_type === 3 || alert_type === 4, // Range types
    then: yup
      .number()
      .typeError("Value is required")
      .required("Value is required"),
  }),
  health_point_max: yup
    .mixed()
    .optional()
    .when("warning_is_enabled", {
      is: true,
      then: yup.number().typeError("Health Value is required").optional(),
    }),
  health_point_min: yup.mixed().when("alert_type", {
    is: (alert_type: number) => alert_type === 3 || alert_type === 4, // Range types
    then: yup.number().typeError("Health Value is required").optional(),
  }),
  status_is_enabled: yup.boolean().optional(),
  status_alert_type: yup.mixed().when("status_is_enabled", {
    is: true,
    then: yup.number().typeError("Alarm Type is required"),
  }),
  status_point_max: yup.mixed().when("status_is_enabled", {
    is: true,
    then: yup
      .number()
      .typeError("Value is required")
      .required("Value is required"),
  }),
  status_point_min: yup.mixed().when("status_alert_type", {
    is: (status_alert_type: number) =>
      status_alert_type === 3 || status_alert_type === 4, // Range types
    then: yup
      .number()
      .typeError("Value is required")
      .required("Value is required"),
  }),
  is_enabled: yup.boolean().optional(),
});
