import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { AppDispatch } from "../../app/store";
import { selectDashboardAssetsFilterNumbers } from "../../asset/store/asset.selector";
import { AssetsFilter } from "../../asset/component/AssetsFilter/AssetsFilter";
import { fetchAssets } from "../../asset/store/asset.action";
import { ASSETS } from "../../app/const/routes";
import { AssetFilterStatus } from "../../app/enum/AssetStatus";
import { useInterval } from "../../app/hooks/useInterval";

export const DashboardAssetsFilterContainer: FC<{ clientId: number }> = ({
  clientId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const assetsFilterNumbers = useSelector(selectDashboardAssetsFilterNumbers);
  const [activeTab, setActiveTab] = useState(AssetFilterStatus.total);
  useEffect(() => {
    dispatch(fetchAssets(clientId));
  }, [dispatch, clientId]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string
  ) => {
    setActiveTab(newValue);
    dispatch(push(`${ASSETS}?clientId=${clientId}&active=${newValue}`));
  };

  useInterval(() => {
    dispatch(fetchAssets(Number(clientId)));
  });

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between" mb={2}>
        <Typography variant="h3">Assets</Typography>
      </Box>
      <AssetsFilter
        handleChange={handleChange}
        activeTab={activeTab}
        assetsFilterNumbers={assetsFilterNumbers}
        wrapped
      />
    </>
  );
};
