import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { parse } from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import {
  selectFilteredTasksListData,
  selectIsTasksListStatusPending,
  selectTasksListData,
  selectSelectedClientId,
  selectTasksData,
} from "../store/workOrders.selector";
import { SwimlaneHeader } from "../component/SwimlaneHeader/SwimlaneHeader";
import { Swimlane } from "../component/Swimlane/Swimlane";
import {
  clearWorkOrderState,
  sortTasks as sortTasksAction,
} from "../store/workOrders.slice";
import { DropEvent } from "../model/workorders.model";
import {
  TaskDto,
  TaskStatus,
  TaskType,
  TaskCommentDto,
} from "../api/workorders.api.dto";
import { SortBy } from "../enum/workorders.enum";
import {
  createComment,
  deleteComment,
  editTask,
  deleteTask,
  updateTaskStatus,
} from "../store/workOrders.action";
import { TaskModal } from "../component/TaskModel/TaskModal";
import { EDIT_TASK_MODAL } from "../../app/const/modals";
import { ITaskForm } from "../component/TaskModel/TaskModalForm";
import { mapTaskFormToCreateTaskDto } from "./TasksModalContainer";
import { selectProfileData } from "../../auth/store/auth.selector";
import {
  selectIsTeamsStatusPending,
  selectTeamsData,
} from "../../teams/store/teams.selector";
import {
  selectIsUsersStatusPending,
  selectUsersData,
} from "../../users/store/users.selector";
import {
  selectAssetsData,
  selectIsAssetsStatusPending,
} from "../../asset/store/asset.selector";
import { fetchTeams } from "../../teams/store/teams.action";
import { fetchUsers } from "../../users/store/users.action";
import { fetchAssets } from "../../asset/store/asset.action";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { fetchClients } from "../../clients/store/clients.action";

export const TasksBoardContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tasks = useSelector(selectTasksData);
  const tasksLists = useSelector(selectTasksListData);
  const filteredTasksLists = useSelector(selectFilteredTasksListData);
  const profileData = useSelector(selectProfileData);

  const teams = useSelector(selectTeamsData);
  const users = useSelector(selectUsersData);
  const assets = useSelector(selectAssetsData);
  const clients = useSelector(selectClientsData);

  const isTasksListStatusPending = useSelector(selectIsTasksListStatusPending);
  const isTeamsStatusPending = useSelector(selectIsTeamsStatusPending);
  const isUsersStatusPending = useSelector(selectIsUsersStatusPending);
  const isAssetsStatusPending = useSelector(selectIsAssetsStatusPending);
  const isClientsStatusPending = useSelector(selectIsClientsStatusPending);

  const selectedClientId = useSelector(selectSelectedClientId);
  const { search } = useLocation();
  const history = useHistory();
  const { workorderId }: { workorderId?: string } = parse(search);
  const [selectedTask, setSelectedTask] = useState<TaskDto | undefined>(
    undefined
  );

  const reorderTasks = (dropEvent: DropEvent) => {
    const {
      droppableIdStart,
      droppableIdEnd,
      droppableIndexStart,
      droppableIndexEnd,
    } = dropEvent;

    const startListTitle: TaskStatus = droppableIdStart as TaskStatus;
    const endListTitle: TaskStatus = droppableIdEnd as TaskStatus;

    if (droppableIdStart === droppableIdEnd) {
      const list = Array.from(tasksLists[startListTitle]);
      const [task] = list.splice(droppableIndexStart, 1);
      list.splice(droppableIndexEnd, 0, task);
      dispatch(sortTasksAction({ newList: list, listTitle: startListTitle }));
    } else {
      const startList = Array.from(filteredTasksLists[startListTitle]);
      const endList = Array.from(filteredTasksLists[endListTitle]);
      const [task] = startList.splice(droppableIndexStart, 1);
      const [nextTask] = endList.splice(droppableIndexEnd, 1);
      const nextTaskIndex = tasksLists[endListTitle].findIndex(
        (endListTask) => endListTask.id === nextTask?.id
      );

      const newStatus = droppableIdEnd as TaskStatus;
      const newEndTaskList = Array.from(tasksLists[endListTitle]);

      if (nextTaskIndex !== -1) {
        newEndTaskList.splice(nextTaskIndex, 0, {
          ...task,
          status: newStatus,
        });
      } else {
        newEndTaskList.splice(newEndTaskList.length, 0, {
          ...task,
          status: newStatus,
        });
      }

      dispatch(
        sortTasksAction({
          newList: tasksLists[startListTitle].filter(
            (startListTask) => startListTask.id !== task.id
          ),
          listTitle: startListTitle,
        })
      );
      dispatch(
        sortTasksAction({
          newList: newEndTaskList,
          listTitle: endListTitle,
        })
      );
      dispatch(updateTaskStatus({ taskId: task.id, status: newStatus }));
    }
  };

  const sortTasks = (status: TaskStatus, sortBy: SortBy, desc: boolean) => {
    let newList = Array.from(tasksLists[status]);

    if (sortBy === SortBy.Priority) {
      newList = newList.sort((a: TaskDto, b: TaskDto) =>
        desc ? a.priority - b.priority : b.priority - a.priority
      );
    } else if (sortBy === SortBy.DueDate) {
      newList = newList.sort((a: TaskDto, b: TaskDto) => {
        if (desc)
          return moment(a.finish_date).isBefore(moment(b.finish_date)) ? -1 : 1;
        return moment(a.finish_date).isBefore(moment(b.finish_date)) ? 1 : -1;
      });
    }
    dispatch(sortTasksAction({ newList, listTitle: status }));
  };

  const closeModal = () =>
    dispatch(closeModalAction({ name: EDIT_TASK_MODAL }));

  const openEditModal = (task: TaskDto) => {
    setSelectedTask(task);
    dispatch(openModalAction({ name: EDIT_TASK_MODAL, params: { task } }));
  };

  const createCommentFn = (taskCommentDto: TaskCommentDto) =>
    dispatch(createComment(taskCommentDto));

  const deleteCommentFN = (taskCommentDto: TaskCommentDto) =>
    dispatch(deleteComment(taskCommentDto));

  const onEditTask = (
    values: ITaskForm,
    start?: TaskStatus,
    end?: TaskStatus
  ) => {
    const taskToDispatch = mapTaskFormToCreateTaskDto(values);
    dispatch(
      editTask({
        ...taskToDispatch,
        id: selectedTask?.id,
        created_by: undefined,
      })
    );
    if (selectedTask && start && end) {
      reorderTasks({
        draggableId: selectedTask.id,
        droppableIndexStart: 0,
        droppableIndexEnd: 0,
        droppableIdStart: start,
        droppableIdEnd: end,
      });
    }
  };

  const onDeleteTask = () => {
    if (selectedTask) {
      dispatch(
        deleteTask({
          taskId: Number(selectedTask.id),
          clientId: selectedClientId || undefined,
        })
      );
    }
  };

  useEffect(() => {
    if (workorderId) {
      const queryTask = tasks.find(
        (task) => Number(task.id) === Number(workorderId)
      );
      if (queryTask) {
        setSelectedTask(queryTask);
        dispatch(
          openModalAction({ name: EDIT_TASK_MODAL, params: { queryTask } })
        );
        const queryParams = new URLSearchParams(search);
        if (queryParams.has("workorderId")) {
          queryParams.delete("workorderId");
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [dispatch, workorderId, tasks, search, history]);

  useEffect(() => {
    dispatch(fetchTeams());
    dispatch(fetchUsers());
    dispatch(fetchAssets());
    dispatch(fetchClients());

    return () => {
      dispatch(clearWorkOrderState());
    };
  }, [dispatch]);

  return (
    <>
      <SwimlaneHeader />
      <Swimlane
        tasksLists={filteredTasksLists}
        onTaskReorder={reorderTasks}
        onSortTasks={sortTasks}
        pending={isTasksListStatusPending}
        onCardClick={openEditModal}
      />
      {profileData && (
        <TaskModal
          currentUser={profileData.user}
          onCommentAdd={createCommentFn}
          onCommentDelete={deleteCommentFN}
          type={TaskType.Manual}
          name={EDIT_TASK_MODAL}
          onClose={closeModal}
          onSubmit={onEditTask}
          onDelete={onDeleteTask}
          task={selectedTask}
          isPending={
            isTasksListStatusPending ||
            isTeamsStatusPending ||
            isUsersStatusPending ||
            isAssetsStatusPending ||
            isClientsStatusPending
          }
          teams={teams.filter((team) => team.client_id === selectedClientId)}
          users={users.filter((user) => user.client_id === selectedClientId)}
          assets={assets.filter(
            (asset) => asset.client_id === selectedClientId
          )}
          client={clients.filter((client) => client.id === selectedClientId)}
        />
      )}
    </>
  );
};
