import styled from "styled-components";
import { Button, Paper } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TimerRoundedIcon from "@material-ui/icons/TimerRounded";
import RestoreIcon from "@material-ui/icons/Restore";

export const StyledBlock = styled.div`
  font-weight: 700;
  padding: 20px 25px;
  h1 {
    font-size: 24px;
    display: inline-block;
    color: ${({ theme }) => `${theme.palette.block.dataColor}`};
  }
  h3 {
    color: ${({ theme }) => `${theme.palette.block.color}`};
    font-size: 14px;
    display: inline-block;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-grow: 1;
    border-right: 1px solid
      ${({ theme }) => `${theme.palette.tab.divider.color}`};
    :last-child {
      border-right: 0;
    }
  }
  ${({ theme }) => theme.breakpoints.only("sm")} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const TitleBar = styled.div`
  display: block;
  font-size: 16px;
  text-align: left;
  color: ${({ theme }) => `${theme.palette.block.dataColor}`};
`;

export const StyledWrapper = styled.span`
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-self: center;
`;

export const FlexBoxIcon = styled.span`
  flex: 0;
  align-self: center;
`;

export const FlexBoxText = styled.span`
  flex: 1;
  align-self: center;
  text-align: left;
  flex-direction: column;
  display: flex;
`;

export const DoubleBox = styled(Paper)`
  padding: 16px;
  text-align: center;
  font-weight: 700;
`;

export const GreenIconOutline = styled.span`
  vertical-align: middle;
  background-color: ${({ theme }) =>
    `${theme.palette.icon.green.outline.backgroundColor}`};
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-right: 10px;
`;

export const GreenArrowUpwardIcon = styled(ArrowUpwardIcon)`
  color: ${({ theme }) => `${theme.palette.icon.green.color}`};
`;

export const RedIconOutline = styled.span`
  vertical-align: middle;
  background-color: ${({ theme }) =>
    `${theme.palette.icon.error.outline.backgroundColor}`};
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-right: 10px;
`;

export const RedArrowDownwardIcon = styled(ArrowDownwardIcon)`
  color: ${({ theme }) => `${theme.palette.icon.error.color}`};
`;

export const BlueIconOutline = styled.span`
  vertical-align: middle;
  background-color: ${({ theme }) =>
    `${theme.palette.icon.blue.outline.backgroundColor}`};
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-right: 10px;
`;

export const BlueTimeRoundedIcon = styled(TimerRoundedIcon)`
  color: ${({ theme }) => `${theme.palette.icon.blue.color}`};
`;

export const StyledRestoreIcon = styled(RestoreIcon)`
  color: ${({ theme }) => `${theme.palette.block.color}`};
`;

export const TransparentButton = styled(Button)`
  float: right;
  background-color: transparent;
  border: 0;
  padding: 0;
  &:hover {
    background-color: transparent;
  }
`;

export const CountersContainer = styled.span`
  display: block;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: flex;
  }
`;
