import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchClients as fetchClientsRequest,
  createClient as createClientRequest,
  deleteClient as deleteClientRequest,
  editClient as editClientRequest,
  fetchAverageHealth as fetchAverageHealthRequest,
  fetchRecentActivity as fetchRecentActivityRequest,
  fetchClientTodos as fetchClientTodosRequest,
} from "../api/clients.api";
import withError from "../../app/util/with-thunk-error";
import { ClientAverageHealthDto, ClientDto } from "../api/clients.api.dto";
import { CreateEditClientModel } from "../model/clients.model";
import { mapCreateEditClientModelToDto } from "../api/clients.api.mapper";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_CLIENT_MODAL, EDIT_CLIENT_MODAL } from "../../app/const/modals";
import { AssetTodosDto } from "../../asset/api/asset.api.dto";

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<ClientDto[]> => fetchClientsRequest()
  )
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  withError(
    async (client: CreateEditClientModel, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_CLIENT_MODAL }));
      await createClientRequest(mapCreateEditClientModelToDto(client));
      dispatch(closeModal({ name: ADD_CLIENT_MODAL }));
      dispatch(openSuccessNotification("Client has been added"));
      dispatch(fetchClients());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_CLIENT_MODAL }))
  )
);

export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_CLIENT_MODAL }));
      await deleteClientRequest(id);
      dispatch(closeModal({ name: EDIT_CLIENT_MODAL }));
      dispatch(openSuccessNotification("Client has been deleted"));
      dispatch(fetchClients());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_CLIENT_MODAL }))
  )
);

export const editClient = createAsyncThunk(
  "clients/editClient",
  withError(
    async (
      {
        id,
        editClientModel,
      }: { id: number; editClientModel: CreateEditClientModel },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_CLIENT_MODAL }));
      await editClientRequest(
        id,
        mapCreateEditClientModelToDto(editClientModel)
      );
      dispatch(closeModal({ name: EDIT_CLIENT_MODAL }));
      dispatch(openSuccessNotification("Client has been updated"));
      dispatch(fetchClients());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_CLIENT_MODAL }))
  )
);

export const fetchAverageHealth = createAsyncThunk(
  "clients/fetchAverageHealth",
  withError(
    async (clientId: number, { dispatch }): Promise<ClientAverageHealthDto> => {
      return fetchAverageHealthRequest(clientId);
    }
  )
);

export const fetchRecentActivity = createAsyncThunk(
  "clients/fetchRecentActivity",
  withError(
    async (clientId: number, { dispatch }): Promise<any> => {
      return fetchRecentActivityRequest(clientId);
    }
  )
);

export const fetchClientTodos = createAsyncThunk(
  "clients/fetchClientTodos",
  withError(
    async (clientId: number, { dispatch }): Promise<any> => {
      return fetchClientTodosRequest(clientId);
    }
  )
);
