import styled from "styled-components";
import { TEXT_SECONDARY } from "../../../app/theme/colors";

export const StyledInput = styled.input`
  font: inherit;
  font-weight: 500;
  color: currentColor;
  width: 195px;
  float: right;
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  height: 1.1876em;
  display: block;
  padding: 10px 10px 10px 10px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  text-align: right;
`;

export const StyledDisabledInput = styled.div`
  font: inherit;
  font-weight: 500;
  color: ${TEXT_SECONDARY};
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 10px 0px 10px 10px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
`;
