import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import {
  clearSearchTeamsState as clearSearchTeamsStateAction,
  searchTeams as searchTeamsAction,
} from "../store/teams.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";
import { selectIsTeamsTableStatusPending } from "../store/teams.selector";

export const TeamsSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isTeamsPending = useSelector(selectIsTeamsTableStatusPending);

  useEffect(() => {
    return () => {
      dispatch(clearSearchTeamsStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchTeamsAction(value));

  return (
    <TableSearchInput
      label="Search Teams"
      onSearch={debounce(onSearch, 200)}
      isPending={isTeamsPending}
    />
  );
};
