import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { PageModel } from "../../app/model/Pagination";
import { MaintenanceDto } from "../api/maintenance.api.dto";

export interface MaintenanceState {
  maintenances: AsyncStateModel<MaintenanceDto[]>;
  editMaintenance: AsyncStateModel<null>;
  createMaintenance: AsyncStateModel<null>;
  deleteMaintenance: AsyncStateModel<null>;
}

export const maintenanceState: MaintenanceState = {
  maintenances: {
    status: AsyncStatus.Void,
    data: [],
  },
  editMaintenance: {
    status: AsyncStatus.Void,
    data: null,
  },
  createMaintenance: {
    status: AsyncStatus.Void,
    data: null,
  },
  deleteMaintenance: {
    status: AsyncStatus.Void,
    data: null,
  },
};
