import styled from "styled-components";
import { Card, Chip } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";
import { IconButton } from "../../../app/component/IconButton/IconButton";

export const ToggleButton = styled(Button)`
  border-radius: 20px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledCard = styled(Card)`
  height: 100;
  margin: ${({ theme }) => `${theme.spacing(1)}px`} 0;

  .MuiCardContent-root:last-child {
    padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const StyledChip = styled(Chip)`
  width: 42px;
  height: 22px;
  margin-top: 3px;
  background-color: #e8eaed;
`;
