import React, { FC } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  StyledDialog,
  GreyButton,
  RedButton,
} from "./AnalyticsSelectorsConfirmationModal.style";

export interface IAnalyticsSelectorsConfirmationModal {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCloseConfirmationModal: any;
  handleConfirmSelections: (event: React.MouseEvent<HTMLElement>) => void;
}
export const AnalyticsSelectorsConfirmationModal: FC<IAnalyticsSelectorsConfirmationModal> = ({
  open,
  handleCloseConfirmationModal,
  handleConfirmSelections,
}) => {
  return (
    <StyledDialog open={open}>
      <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action will reset your current filter.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GreyButton
          autoFocus
          onClick={handleCloseConfirmationModal}
          color="primary"
        >
          No
        </GreyButton>
        <RedButton onClick={handleConfirmSelections} color="primary">
          Yes
        </RedButton>
      </DialogActions>
    </StyledDialog>
  );
};
