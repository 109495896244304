import { Box } from "@material-ui/core";
import React, { FC } from "react";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { SelectClientContainer } from "../../container/SelectClientContainer";
import { TasksBoardContainer } from "../../container/TasksBoardContainer";

export const WorkOrdersView: FC = () => (
  <StyledPage title="Work Orders" minHeight>
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Work Orders" />
        <PageActionsWrapper>
          <SelectClientContainer />
        </PageActionsWrapper>
      </PageHeaderContainer>
      <Box
        flexDirection="column"
        height="100%"
        justifyContent="center"
        flex={1}
      >
        <TasksBoardContainer />
      </Box>
    </FullHeightContainer>
  </StyledPage>
);
