import { authApiClient } from "../../app/api/authApi.client";
import { IndustryDto } from "./industry.api.dto";

export const fetchIndustries = async (): Promise<IndustryDto[]> => {
  try {
    const response = await authApiClient.get("/industry/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};
