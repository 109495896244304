import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AlertModel } from "../model/alerts.model";

export interface AlertsState {
  alerts: AsyncStateModel<AlertModel[]>;
}

export const alertsState: AlertsState = {
  alerts: {
    status: AsyncStatus.Void,
    data: [],
  },
};
