import { authApiClient } from "../../app/api/authApi.client";
import { CreateEditRoleDto, RoleDto } from "./roles.api.dto";

export const fetchRoles = async (): Promise<RoleDto[]> => {
  try {
    const response = await authApiClient.get("/roles");
    return response.data.roles;
  } catch (error) {
    throw error;
  }
};

export const createRole = async (role: CreateEditRoleDto): Promise<void> => {
  try {
    const response = await authApiClient.post("/roles", role);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRole = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/roles/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editRole = async (
  id: number,
  editRoleDto: CreateEditRoleDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(`/roles/${id}`, editRoleDto);
    return response.data;
  } catch (error) {
    throw error;
  }
};
