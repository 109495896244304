import styled from "styled-components";
import { Checkbox } from "@material-ui/core";

export const StyledSelectCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

export const StyledTableCellCheckbox = styled(Checkbox)`
  margin-right: 6px;
  margin-left: 2px;
`;
