import React, { FC, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  selectAssetSensorsData,
  selectIsAssetSensorsStatusPending,
  selectShowAssetData,
  selectShowAssetStatus,
  selectRuntimeData,
  selectRuntimeDataStatusPending,
} from "../store/asset.selector";
import { AssetRealtime } from "../component/AssetRealtime/AssetRealtime";
import { clearSensors, clearRuntime } from "../store/asset.slice";
import {
  fetchSensors as fetchSensorsAction,
  showAsset as showAssetAction,
  fetchRuntimeData as fetchRuntimeDataAction,
  resetAssetRuntime,
} from "../store/asset.action";
import { fetchMaintenances } from "../../maintenance/store/maintenance.action";
import {
  selectMaintenances,
  selectIsMaintenancesStatusPending,
} from "../../maintenance/store/maintenance.selector";
import { MaintenanceDto } from "../../maintenance/api/maintenance.api.dto";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { useInterval } from "../../app/hooks/useInterval";
import { fetchTasks } from "../../workorders/store/workOrders.action";
import {
  selectTasksListData,
  selectIsTasksListStatusPending,
} from "../../workorders/store/workOrders.selector";
import { TaskStatus } from "../../workorders/api/workorders.api.dto";
import { WORKORDERS } from "../../app/const/routes";
import { openModal } from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_TASK_MODAL } from "../../app/const/modals";
import { setSelectedClientId } from "../../workorders/store/workOrders.slice";

interface RouteParams {
  assetId: string;
}

export const AssetRealtimeContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { assetId } = useParams<RouteParams>();
  const history = useHistory<any>();
  const asset = useSelector(selectShowAssetData);
  const sensors = useSelector(selectAssetSensorsData);
  const maintenances = useSelector(selectMaintenances);
  const runtime = useSelector(selectRuntimeData);
  const tasks = useSelector(selectTasksListData);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const [assetMaintenances, setAssetMaintenances] = useState<MaintenanceDto[]>(
    []
  );
  const clientId = asset?.client_id;

  const fetchAssetStatus = useSelector(selectShowAssetStatus);
  const isAssetSensorsStatusPending = useSelector(
    selectIsAssetSensorsStatusPending
  );
  const isMaintenancesStatusPending = useSelector(
    selectIsMaintenancesStatusPending
  );
  const isRuntimeDataStatusPending = useSelector(
    selectRuntimeDataStatusPending
  );
  const isTasksListStatusPending = useSelector(selectIsTasksListStatusPending);

  useEffect(() => {
    const filtered = maintenances.filter((maintenance) => {
      return maintenance.asset_id === Number(assetId);
    });
    setAssetMaintenances(filtered);
  }, [maintenances, assetId]);

  useEffect(() => {
    dispatch(showAssetAction(Number(assetId)));
    dispatch(fetchSensorsAction(Number(assetId)));
    dispatch(fetchMaintenances());
    dispatch(
      fetchRuntimeDataAction({
        assetIds: [Number(assetId)],
      })
    );
    dispatch(
      fetchTasks({
        clientId,
        assetId: Number(assetId),
      })
    );
    return () => {
      dispatch(clearRuntime());
      dispatch(clearSensors());
    };
  }, [dispatch, assetId, clientId]);

  useInterval(() => {
    dispatch(fetchSensorsAction(Number(assetId)));
  });

  const handleRuntimeReset = (event: React.MouseEvent<HTMLElement>) => {
    if (asset !== null) {
      dispatch(resetAssetRuntime(asset));
      setOpenResetModal(false);
    }
  };

  const handleOpenResetModal = () => {
    setOpenResetModal(true);
  };

  const handleCloseResetModal = () => {
    setOpenResetModal(false);
  };

  const handleOpenAddWorkorderModal = () => {
    dispatch(setSelectedClientId(Number(clientId)));
    history.push(WORKORDERS);
    dispatch(
      openModal({ name: ADD_TASK_MODAL, params: { assetId: Number(assetId) } })
    );
  };

  const filteredTask = [
    ...tasks[TaskStatus.Open].filter(
      (task) => task.asset[0]?.id === Number(assetId)
    ),
    ...tasks[TaskStatus.InProgress].filter(
      (task) => task.asset[0]?.id === Number(assetId)
    ),
  ];

  return (
    <AssetRealtime
      pending={
        fetchAssetStatus === AsyncStatus.Pending ||
        isAssetSensorsStatusPending ||
        isMaintenancesStatusPending ||
        isRuntimeDataStatusPending ||
        isTasksListStatusPending
      }
      asset={asset}
      maintenances={assetMaintenances}
      sensors={sensors}
      runtime={runtime}
      handleRuntimeReset={handleRuntimeReset}
      handleOpenResetModal={handleOpenResetModal}
      handleCloseResetModal={handleCloseResetModal}
      handleOpenAddWorkorderModal={handleOpenAddWorkorderModal}
      openResetModal={openResetModal}
      tasks={filteredTask}
    />
  );
};
