import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Divider,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Link,
  IconButton,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Schedule as ScheduleIcon,
  Clear as ClearIcon,
  OpenInNew as OpenInNewIcon,
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dropzone from "react-dropzone";
import { DropzoneContainer } from "./SensorTypeModalForm.style";
import { Button } from "../../../app/component/Button/Button";
import {
  sensorTypeModalFormValidation,
  DESCRIPTION_CHAR_LIMIT,
  SPEC_SHEET_MIME_TYPES,
} from "./sensorTypeModalForm.validation";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import {
  SensorTypeDto,
  CreateEditSensorTypeDto,
  HardcodedSensorType,
  HardcodedSensorUnit,
} from "../../api/sensorTypes.api.dto";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";

export interface ISensorTypeModalForm {
  sensorType?: SensorTypeDto;
  hardcodedSensorTypes: HardcodedSensorType[];
  hardcodedSensorUnits: HardcodedSensorUnit[];
  onSubmit: (values: CreateEditSensorTypeDto, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  editView: boolean;
}

export const SensorTypeModalForm: FC<ISensorTypeModalForm> = ({
  sensorType,
  hardcodedSensorTypes,
  hardcodedSensorUnits,
  onSubmit,
  displayActionButtons,
  isPending,
  editView,
}) => {
  const [
    selectedHardcodedSensorTypeId,
    setSelectedHardcodedSensorTypeId,
  ] = useState<number | null>(sensorType?.sensor_type_index || null);
  const [
    selectedHardcodedSensorUnitId,
    setSelectedHardcodedSensorUnitId,
  ] = useState<number | null>(null);
  const [
    filteredHardcodedSensorUnitIds,
    setFilteredHardcodedSensorUnitIds,
  ] = useState<number[]>([]);
  const [specSheetFileUrl, setSpecSheetFileUrl] = useState(
    sensorType?.spec_sheet
  );
  const [specSheetFileName, setSpecSheetFileName] = useState<
    string | undefined
  >(undefined);

  const isSuperUser = useShowForRole([UserRole.SuperUser]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: sensorType?.manufacturer || "",
      oem_part_number: sensorType?.oem_part_number || "",
      part_number: sensorType?.part_number || "",
      sensor_type_index: sensorType?.sensor_type_index || 0,
      range_min: sensorType?.range_min || 0,
      range_max: sensorType?.range_max || 0,
      unit_type: sensorType?.unit_type || 0,
      settling_time: sensorType?.settling_time || 0,
      description: sensorType?.description || "",
      spec_sheet: undefined as File | undefined,
    },
    validationSchema: sensorTypeModalFormValidation,
    onSubmit: (values: CreateEditSensorTypeDto) =>
      onSubmit(values, sensorType?.id),
  });
  const { setFieldValue: formikSetFieldValue } = formik;

  const getHardcodedSensorTypeOptions = () => {
    return hardcodedSensorTypes.map(
      (hardcodedSensorType) => hardcodedSensorType.id
    );
  };
  const getHardcodedSensorTypeOptionLabel = (option: number) => {
    const match = hardcodedSensorTypes.find((hardcodedSensorType) => {
      return hardcodedSensorType.id === option;
    });
    return match ? match.name : "";
  };

  const getHardcodedSensorUnitOptionLabel = (option: number) => {
    const match = hardcodedSensorUnits.find((hardcodedSensorUnit) => {
      return hardcodedSensorUnit.id === option;
    });
    return match ? match.name : "";
  };

  useEffect(() => {
    setFilteredHardcodedSensorUnitIds(
      hardcodedSensorUnits
        .filter(
          (hardcodedSensorUnit) =>
            hardcodedSensorUnit.sensor_type_id === selectedHardcodedSensorTypeId
        )
        .map((hardcodedSensorUnit) => hardcodedSensorUnit.id)
    );
  }, [selectedHardcodedSensorTypeId, hardcodedSensorUnits]);

  useEffect(() => {
    if (
      filteredHardcodedSensorUnitIds &&
      filteredHardcodedSensorUnitIds.length === 1
    ) {
      setSelectedHardcodedSensorUnitId(filteredHardcodedSensorUnitIds[0]);
    } else {
      setSelectedHardcodedSensorUnitId(null);
    }
  }, [filteredHardcodedSensorUnitIds]);

  useEffect(() => {
    formikSetFieldValue("unit_type", selectedHardcodedSensorUnitId);
  }, [selectedHardcodedSensorUnitId, formikSetFieldValue]);

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Sensor Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={getHardcodedSensorTypeOptions()}
              id="sensor_type_index"
              getOptionLabel={getHardcodedSensorTypeOptionLabel}
              value={formik.values.sensor_type_index}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValue: number | null
              ) => {
                formik.setFieldValue("sensor_type_index", newValue);
                setSelectedHardcodedSensorTypeId(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="sensor_type_index"
                  label="Sensor Type"
                  error={
                    formik.touched.sensor_type_index &&
                    Boolean(formik.errors.sensor_type_index)
                  }
                  helperText={
                    formik.touched.sensor_type_index &&
                    formik.errors.sensor_type_index
                  }
                  variant="filled"
                  fullWidth
                  required
                />
              )}
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="manufacturer"
              name="manufacturer"
              label="Manufacturer"
              variant="filled"
              fullWidth
              value={formik.values.manufacturer}
              onChange={formik.handleChange}
              error={
                formik.touched.manufacturer &&
                Boolean(formik.errors.manufacturer)
              }
              helperText={
                formik.touched.manufacturer && formik.errors.manufacturer
              }
              required
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="oem_part_number"
              name="oem_part_number"
              label="OEM Part Number"
              variant="filled"
              fullWidth
              value={formik.values.oem_part_number}
              onChange={formik.handleChange}
              error={
                formik.touched.oem_part_number &&
                Boolean(formik.errors.oem_part_number)
              }
              helperText={
                formik.touched.oem_part_number && formik.errors.oem_part_number
              }
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="part_number"
              name="part_number"
              label="Tako Part Number"
              variant="filled"
              fullWidth
              value={formik.values.part_number}
              onChange={formik.handleChange}
              error={
                formik.touched.part_number && Boolean(formik.errors.part_number)
              }
              helperText={
                formik.touched.part_number && formik.errors.part_number
              }
              required
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Technical Specs</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={filteredHardcodedSensorUnitIds}
              id="unit_type"
              getOptionLabel={getHardcodedSensorUnitOptionLabel}
              value={selectedHardcodedSensorUnitId}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValue: number | null
              ) => {
                formik.setFieldValue("unit_type", newValue);
                setSelectedHardcodedSensorUnitId(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="unit_type"
                  label="Units"
                  error={
                    formik.touched.unit_type && Boolean(formik.errors.unit_type)
                  }
                  helperText={
                    formik.touched.unit_type && formik.errors.unit_type
                  }
                  variant="filled"
                  fullWidth
                  required
                />
              )}
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="range_min"
              name="range_min"
              label="Minimum Reading"
              type="number"
              variant="filled"
              fullWidth
              value={formik.values.range_min}
              onChange={formik.handleChange}
              error={
                formik.touched.range_min && Boolean(formik.errors.range_min)
              }
              helperText={formik.touched.range_min && formik.errors.range_min}
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="range_max"
              name="range_max"
              label="Maximum Reading"
              type="number"
              variant="filled"
              fullWidth
              value={formik.values.range_max}
              onChange={formik.handleChange}
              error={
                formik.touched.range_max && Boolean(formik.errors.range_max)
              }
              helperText={formik.touched.range_max && formik.errors.range_max}
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="settling_time"
              name="settling_time"
              label="Settling Time"
              type="number"
              variant="filled"
              fullWidth
              value={formik.values.settling_time}
              onChange={formik.handleChange}
              error={
                formik.touched.settling_time &&
                Boolean(formik.errors.settling_time)
              }
              helperText={
                formik.touched.settling_time && formik.errors.settling_time
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ScheduleIcon />
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ min: 0 }}
              required
              disabled={
                isPending ||
                (!editView && sensorType !== undefined) ||
                (editView && !isSuperUser)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Additional Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Notes"
              multiline
              rows={1}
              rowsMax={10}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
              disabled={isPending || (!editView && sensorType !== undefined)}
            />
          </Grid>
          {!editView && sensorType?.spec_sheet && (
            <Grid item xs={12}>
              <Typography>
                <Link href={sensorType.spec_sheet} target="_blank">
                  {sensorType.spec_sheet.split("\\").pop()?.split("/").pop()}
                </Link>{" "}
                <OpenInNewIcon fontSize="small" color="disabled" />
              </Typography>
            </Grid>
          )}
          {(editView || sensorType === undefined) && (
            <Grid item xs={12}>
              <DropzoneContainer>
                <Dropzone
                  accept={SPEC_SHEET_MIME_TYPES}
                  multiple={false}
                  onDrop={(acceptedFiles: File[]) => {
                    if (acceptedFiles.length > 0) {
                      formik.setFieldValue("spec_sheet", acceptedFiles[0]);
                      setSpecSheetFileName(acceptedFiles[0].name);
                    } else {
                      formik.setFieldValue("spec_sheet", undefined);
                      setSpecSheetFileName(undefined);
                    }
                  }}
                  disabled={isPending}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} disabled={isPending} />
                      {isDragActive ? (
                        <Typography>Drop the file here...</Typography>
                      ) : specSheetFileName ? (
                        <Typography>
                          {specSheetFileName}
                          {(editView || sensorType === undefined) && (
                            <>
                              {" "}
                              <IconButton
                                size="small"
                                edge="end"
                                aria-label="delete"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  formik.setFieldValue("spec_sheet", null);
                                  setSpecSheetFileName(undefined);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </>
                          )}
                        </Typography>
                      ) : specSheetFileUrl ? (
                        <Typography>
                          <Link
                            href={specSheetFileUrl}
                            target="_blank"
                            onClick={(event: React.MouseEvent) => {
                              event.stopPropagation();
                            }}
                          >
                            {specSheetFileUrl
                              .split("\\")
                              .pop()
                              ?.split("/")
                              .pop()}
                          </Link>
                          {(editView || sensorType === undefined) && (
                            <>
                              {" "}
                              <IconButton
                                size="small"
                                edge="end"
                                aria-label="delete"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  formik.setFieldValue("spec_sheet", null);
                                  setSpecSheetFileUrl(null);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </>
                          )}
                        </Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={isPending}
                        >
                          <AddIcon /> Add Attachment
                        </Button>
                      )}
                    </div>
                  )}
                </Dropzone>
              </DropzoneContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
