import styled from "styled-components";
import { Tabs, Tab, Paper, Button, Dialog } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NotificationsIcon from "@material-ui/icons/Notifications";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export const StyledBar = styled.div`
  margin-bottom: 24px;
  background-color: ${({ theme }) => `${theme.palette.background.paper}`};
  border-radius: 5px;
  position: relative;
`;

export const StyledTabs = styled(Tabs)`
  box-shadow: 0 1px 4px 0 rgba(16, 16, 16, 0.16);
  border-radius: 2px;
`;

export const StyledTab = styled(Tab)`
  padding: ${({ theme }) => `${theme.spacing(2)}px`} 0;
  font-weight: 500 !important;
  text-align: center;
  min-width: 140px;
  border-bottom: ${({ wrapped, theme, selected }) => {
    switch (wrapped) {
      case false:
        return `2px solid 
        ${
          selected
            ? theme.palette.tab.primary.color
            : theme.palette.background.paper
        }`;
      default:
        return null;
    }
  }};
  background-color: ${({ theme }) => theme.palette.background.paper};
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      vertical-align: baseline;
    }
  }
  :hover {
    border-bottom: ${({ wrapped, theme }) => {
      switch (wrapped) {
        case false:
          return `2px solid ${theme.palette.tab.primary.color}`;

        default:
          return null;
      }
    }};
    background-color: ${({ theme }) =>
      theme.palette.tab.primary.backgroundColor};
    color: ${({ theme }) => `${theme.palette.tab.primary.color}`};
  }

  :first-of-type {
    margin-left: 4px;
  }

  .MuiTab-wrapper > *:first-child {
    /* span { */
    margin-bottom: 0;
    /* } */
  }
`;

export const BorderedTab = styled(StyledTab)`
  position: relative;
  overflow: visible;
  border-left: 1px solid #eaeaef;
`;

export const Divider = styled.span`
  display: inline-block;
  position: absolute;
  left: -5px;
  background-color: ${({ theme }) => `${theme.palette.tab.divider.color}`};
  width: 1px;
  top: -4px;
  bottom: -6px;
`;

export const Counter = styled.span`
  font-size: 24px;
`;

export const Label = styled.span`
  line-height: 20px;
  vertical-align: top;
`;

export const StyledFavoriteIcon = styled(FavoriteIcon)`
  font-size: 20px;
  margin-right: 10px;
`;

export const StyledNotificationsIcon = styled(NotificationsIcon)`
  font-size: 20px;
  margin-right: 10px;
  color: ${({ theme }) => theme.palette.icon.bell.color};
`;

export const FloaterInfoIcon = styled(InfoOutlinedIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 20px;
`;

export const DoubleBox = styled(Paper)`
  padding: 50px 20px;
  text-align: center;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RedIconOutline = styled.span`
  vertical-align: middle;
  background-color: ${({ theme }) =>
    `${theme.palette.icon.error.outline.backgroundColor}`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 10px;
`;

export const RedErrorOutlineIcon = styled(ErrorOutlineIcon)`
  color: ${({ theme }) => `${theme.palette.icon.error.color}`};
`;
