import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginForm } from "../../component/LoginForm/LoginForm";
import { AppDispatch } from "../../../app/store";
import { LoginCredentialsModel } from "../../model/Auth";
import { login } from "../../store/auth.action";
import { selectIsLoginStatusPending } from "../../store/auth.selector";

export const LoginFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoginPending = useSelector(selectIsLoginStatusPending);

  const onSubmit = (credentials: LoginCredentialsModel) =>
    dispatch(login(credentials));

  return <LoginForm onSubmit={onSubmit} pending={isLoginPending} />;
};
