import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { PartnersState } from "./partners.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { PartnerDto } from "../api/partners.api.dto";
import { PartnerModel } from "../model/partners.model";
import { PartnerStatus } from "../enum/partners.enum";

export const selectSelf = (state: RootState): RootState => state;

export const selectPartnersRootState = createSelector(
  selectSelf,
  (state): PartnersState => state.partners
);

export const selectPartnersState = createSelector(
  selectPartnersRootState,
  (partners): AsyncStateModel<PartnerDto[]> => partners.fetchPartners
);

export const selectPartnersData = createSelector(
  selectPartnersState,
  (partners): PartnerDto[] => partners.data
);

export const selectPartnersStatus = createSelector(
  selectPartnersState,
  (partners): AsyncStatus => partners.status
);

export const selectIsPartnersStatusPending = createSelector(
  selectPartnersStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchPartners = createSelector(
  selectPartnersRootState,
  (partners): string => partners.searchPartners
);

export const selectPartnersTableData = createSelector(
  selectSearchPartners,
  selectPartnersData,
  (searchValue, partners): PartnerModel[] => {
    const data = partners.map((partner) => ({
      ...partner,
      status: partner.is_enabled
        ? PartnerStatus.Active
        : PartnerStatus.Inactive,
    }));

    if (searchValue) {
      return data.filter(
        (partner) =>
          partner.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          JSON.stringify(
            partner.clients?.map((e) => e.name.toLowerCase())
          ).includes(searchValue.toLowerCase()) ||
          partner.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          partner.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
          partner.industry.toLowerCase().includes(searchValue.toLowerCase()) ||
          partner.status.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsPartnersTableStatusPending = createSelector(
  selectPartnersStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCreatePartnerState = createSelector(
  selectPartnersRootState,
  (partners): AsyncStateModel<null> => partners.createPartner
);

export const selectCreatePartnerStatus = createSelector(
  selectCreatePartnerState,
  (createPartner): AsyncStatus => createPartner.status
);

export const selectIsCreatePartnerStatusPending = createSelector(
  selectCreatePartnerStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditPartnerState = createSelector(
  selectPartnersRootState,
  (partners): AsyncStateModel<null> => partners.editPartner
);

export const selectEditPartnerStatus = createSelector(
  selectEditPartnerState,
  (editPartner): AsyncStatus => editPartner.status
);

export const selectIsEditPartnerStatusPending = createSelector(
  selectEditPartnerStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeletePartnerState = createSelector(
  selectPartnersRootState,
  (partners): AsyncStateModel<null> => partners.deletePartner
);

export const selectDeletePartnerStatus = createSelector(
  selectDeletePartnerState,
  (deletePartner): AsyncStatus => deletePartner.status
);

export const selectIsDeletePartnerStatusPending = createSelector(
  selectDeletePartnerStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
