import { IconButton } from "@material-ui/core";
import styled, { css } from "styled-components";

export const StyledIconButtonContainer = styled.div`
  margin: 15px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #a1a3f3;
`;

export const StyledButtonHighlight = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.button.highlight.primary.backgroundColor};
  border-radius: 100px;
`;

export const StyledIconButton = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.button.highlight.secondary.backgroundColor};
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  button {
    margin-left: 0;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const EditText = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #a1a3f3;
  cursor: pointer;
`;

export const AddText = styled.div`
  cursor: pointer;
`;
