import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { ProfileModel } from "../model/Auth";

export interface AuthState {
  register: AsyncStateModel<null>;
  login: AsyncStateModel<null>;
  logout: AsyncStateModel<null>;
  recoverPassword: AsyncStateModel<null>;
  resetPassword: AsyncStateModel<null>;
  changePassword: AsyncStateModel<null>;
  profile: AsyncStateModel<ProfileModel | null>;
  authorization: AsyncStateModel<boolean>;
  forgotPassword: AsyncStateModel<null>;
}

export const authState: AuthState = {
  register: {
    status: AsyncStatus.Void,
    data: null,
  },
  login: {
    status: AsyncStatus.Void,
    data: null,
  },
  logout: {
    status: AsyncStatus.Void,
    data: null,
  },
  recoverPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  resetPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  changePassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  profile: {
    status: AsyncStatus.Void,
    data: null,
  },
  authorization: {
    status: AsyncStatus.Void,
    data: false,
  },
  forgotPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
};
