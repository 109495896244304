import React, { FC, useEffect, useState, useRef } from "react";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import moment, { Moment } from "moment";
import { v4 as uuidv4 } from "uuid";

import { Event, Add, DeleteForever } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as EmailIcon } from "../../../../assets/email-icon.svg";
import { ReactComponent as SMSIcon } from "../../../../assets/sms-icon.svg";
import {
  ButtonsWrapper,
  DeleteIconWrapper,
  IconRow,
  IconWrapper,
  OutOfOfficeTitle,
  PickersWrapper,
  PickerWrapper,
  SelectsDivider,
  SelectsTypography,
  SelectsWrapper,
  StyledCardContent,
  Subtitle,
  SwitchWrapper,
  Title,
  WeekdaysWrapper,
  WeekdayWrapper,
  StyledLearnMoreText,
} from "./UserNotifications.style";
import { Button } from "../../../app/component/Button/Button";
import { DateTimePicker } from "../../../app/component/DateTimePicker/DateTimePicker";
import { hourOptions } from "./hourOptions";
import { UserNotificationsDto } from "../../api/users.api.dto";
import { BACKGROUND_DARK } from "../../../app/theme/colors";

const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const OPTIONS = [
  { label: "Weekdays", value: "weekdays" },
  { label: "Custom", value: "custom" },
];

interface OutOfOffice {
  id: string;
  startDate: Moment | null;
  endDate: Moment | null;
}

interface IUserNotifications {
  notifications: UserNotificationsDto | null;
  onSubmit: (dto: UserNotificationsDto) => void;
  isPending: boolean;
  showErrorMessage: (message: string) => void;
}

export const UserNotifications: FC<IUserNotifications> = ({
  isPending,
  notifications,
  onSubmit,
  showErrorMessage,
}) => {
  const [email, setEmail] = useState<boolean>(false);
  const [SMS, setSMS] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<boolean>(false);
  const [scheduleType, setScheduleType] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >(OPTIONS[0]);
  const [schedulePlan, setSchedulePlan] = useState<
    { day?: string; from: string; to: string }[] | null
  >([{ from: "08:00 AM", to: "08:00 AM" }]);
  const [outOfOffice, setOutOfOffice] = useState<OutOfOffice[]>([]);
  const isAddOutOfOfficeDisabled = Boolean(
    outOfOffice.find(
      (singleOutOfOffice) =>
        singleOutOfOffice.startDate === null ||
        singleOutOfOffice.endDate === null
    )
  );
  const [clicked, setClicked] = useState(false);
  const [disableActionButtons, setDisableActionButtons] = useState(true);

  const setControlledData = () => {
    if (notifications) {
      const updatedScheduleType =
        notifications.scheduleType === "weekdays" ||
        notifications.scheduleType === null
          ? OPTIONS[0]
          : OPTIONS[1];
      const mappedSchedulePlan =
        notifications.schedulePlan.length > 0
          ? notifications.schedulePlan.map((
              plan: any // eslint-disable-line
            ) =>
              plan.from === null
                ? { ...plan, from: "None", to: "08:00 AM" }
                : plan
            )
          : [{ from: "08:00 AM", to: "08:00 AM" }];
      const updatedSchedulePlan =
        notifications.schedulePlan.length > 0
          ? mappedSchedulePlan
          : [{ from: "08:00 AM", to: "08:00 AM" }];

      setSchedule(notifications.schedule);
      setEmail(notifications.email);
      setSMS(notifications.sms);
      setOutOfOffice(
        notifications.outOfOffice.map((OOO) => ({
          id: uuidv4(),
          startDate: moment(OOO.from) || null,
          endDate: moment(OOO.to) || null,
        }))
      );
      setScheduleType(updatedScheduleType);
      setSchedulePlan(updatedSchedulePlan);
    }
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!isFirstRun.current) setDisableActionButtons(false);
  }, [email, SMS, schedule, scheduleType, clicked]);

  useEffect(() => {
    if (notifications) {
      const updatedScheduleType =
        notifications.scheduleType === "weekdays" ||
        notifications.scheduleType === null
          ? OPTIONS[0]
          : OPTIONS[1];
      const mappedSchedulePlan =
        notifications.schedulePlan.length > 0
          ? notifications.schedulePlan.map((
              plan: any // eslint-disable-line
            ) =>
              plan.from === null
                ? { ...plan, from: "None", to: "08:00 AM" }
                : plan
            )
          : [{ from: "08:00 AM", to: "08:00 AM" }];
      const updatedSchedulePlan =
        notifications.schedulePlan.length > 0
          ? mappedSchedulePlan
          : [{ from: "08:00 AM", to: "08:00 AM" }];

      setSchedule(notifications.schedule);
      setEmail(notifications.email);
      setSMS(notifications.sms);
      setOutOfOffice(
        notifications.outOfOffice.map((OOO) => ({
          id: uuidv4(),
          startDate: moment(OOO.from) || null,
          endDate: moment(OOO.to) || null,
        }))
      );
      setScheduleType(updatedScheduleType);
      setSchedulePlan(updatedSchedulePlan);
    }
  }, [notifications]);

  const addNewOutOfOfficeRow = () => {
    setClicked(true);
    setOutOfOffice((value) => [
      ...value,
      { id: uuidv4(), startDate: null, endDate: null },
    ]);
  };

  const removeOutOfOfficeRow = (id: string) =>
    setOutOfOffice((value) =>
      value.filter((singleOutOfOffice) => singleOutOfOffice.id !== id)
    );

  const handleStartDateChange = (id: string, date: Moment) => {
    const updatedOutOfOffice = outOfOffice.map((OOO) => {
      if (OOO.id === id) {
        return {
          ...OOO,
          startDate: date,
        };
      }

      return OOO;
    });

    setOutOfOffice(updatedOutOfOffice);
  };

  const handleEndDateChange = (id: string, date: Moment) => {
    const updatedOutOfOffice = outOfOffice.map((OOO) => {
      if (OOO.id === id) {
        return {
          ...OOO,
          endDate: date,
        };
      }

      return OOO;
    });

    setOutOfOffice(updatedOutOfOffice);
  };

  const handleScheduleSwitch = (checked: boolean) => {
    setSchedule(checked);

    if (!checked) {
      setScheduleType(OPTIONS[0]);
      setSchedulePlan([{ from: "08:00 AM", to: "08:00 AM" }]);
    }
  };

  const handleSetWeekdaysSchedulePlanFrom = (
    option:
      | {
          label: string;
          value: string;
        }
      | undefined
  ) => {
    if (option && scheduleType && scheduleType.value === "weekdays") {
      setSchedulePlan((value) =>
        value ? [{ from: option.value, to: value[0].to }] : value
      );
    }
  };

  const handleSetWeekdaysSchedulePlanTo = (
    option:
      | {
          label: string;
          value: string;
        }
      | undefined
  ) => {
    if (option && scheduleType && scheduleType.value === "weekdays") {
      setSchedulePlan((value) =>
        value ? [{ from: value[0].from, to: option.value }] : value
      );
    }
  };

  const handleScheduleTypeChange = (
    option: { label: string; value: string } | undefined
  ) => {
    setScheduleType(option);

    if (option && option.value === "weekdays") {
      setSchedulePlan([{ from: "08:00 AM", to: "08:00 AM" }]);
    }

    if (option && option.value === "custom") {
      const customPlan = WEEKDAYS.map((weekday) => ({
        day: weekday,
        from: "08:00 AM",
        to: "08:00 AM",
      }));
      setSchedulePlan(customPlan);
    }
  };

  const handleSetCustomSchedulePlanFrom = (
    option:
      | {
          label: string;
          value: string;
        }
      | undefined,
    day: string | undefined
  ) => {
    if (
      option &&
      scheduleType &&
      scheduleType.value === "custom" &&
      schedulePlan
    ) {
      const updatedPlan = schedulePlan.map((plan) => {
        if (plan.day === day) {
          return { ...plan, from: option.value };
        }

        return plan;
      });
      setSchedulePlan(updatedPlan);
    }
  };

  const handleSetCustomSchedulePlanTo = (
    option:
      | {
          label: string;
          value: string;
        }
      | undefined,
    day: string | undefined
  ) => {
    if (
      option &&
      scheduleType &&
      scheduleType.value === "custom" &&
      schedulePlan
    ) {
      const updatedPlan = schedulePlan.map((plan) => {
        if (plan.day === day) {
          return { ...plan, to: option.value };
        }

        return plan;
      });
      setSchedulePlan(updatedPlan);
    }
  };

  const handleSubmit = () => {
    if (
      outOfOffice.find((OOO) => OOO.startDate === null || OOO.endDate === null)
    ) {
      return showErrorMessage("Out of Office dates shouldn't be empty");
    }

    const formattedOutOfOffice = outOfOffice.map((OOO) => ({
      from: OOO.startDate?.format("yyyy-MM-DD HH:mm:ss"),
      to: OOO.endDate?.format("yyyy-MM-DD HH:mm:ss"),
    }));

    onSubmit({
      email,
      sms: SMS,
      schedule,
      outOfOffice: formattedOutOfOffice,
      scheduleType: schedule && scheduleType ? scheduleType.value : null,
      schedulePlan: schedulePlan
        ? schedulePlan.map((plan) =>
            plan.from === "None"
              ? { day: plan.day?.toLowerCase(), from: null, to: null }
              : { ...plan, day: plan.day?.toLowerCase() }
          )
        : [],
    });
  };

  return (
    <Card>
      <StyledCardContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <IconRow>
              <IconWrapper>
                <EmailIcon />
              </IconWrapper>
              <div>
                <Title>Email</Title>
                <Subtitle color="textSecondary">
                  Email notification will be sent your inbox
                </Subtitle>
              </div>
            </IconRow>
          </Grid>
          <Grid item xs={4}>
            <SwitchWrapper>
              <Switch
                checked={email}
                onChange={() => setEmail((value) => !value)}
                disabled={isPending}
              />
            </SwitchWrapper>
          </Grid>
          <Grid item xs={8}>
            <IconRow>
              <IconWrapper>
                <SMSIcon />
              </IconWrapper>
              <div>
                <Title>SMS</Title>
                <Subtitle color="textSecondary">
                  Text notifications will be sent to your phone
                </Subtitle>
              </div>
            </IconRow>
          </Grid>
          <Grid item xs={4}>
            <SwitchWrapper>
              <Switch
                checked={SMS}
                onChange={() => setSMS((value) => !value)}
                disabled={isPending}
              />
            </SwitchWrapper>
          </Grid>
          <Grid item xs={8}>
            <Title>Notification Schedule</Title>
            {schedule && (
              <Subtitle>
                You’ll only recieve notifications in the hours you choose.
                Outside of those times, notifications will be paused.
              </Subtitle>
            )}
          </Grid>
          <Grid item xs={4}>
            <SwitchWrapper>
              <Switch
                checked={schedule}
                onChange={(event, checked) => handleScheduleSwitch(checked)}
                disabled={isPending}
              />
            </SwitchWrapper>
          </Grid>
          {schedule && (
            <Grid item xs={12}>
              <SelectsWrapper>
                <PickerWrapper>
                  <Autocomplete
                    disableClearable
                    options={OPTIONS.sort((a, b) =>
                      b.label.localeCompare(a.label)
                    )}
                    getOptionLabel={(option) => option.label}
                    disabled={isPending}
                    value={scheduleType}
                    onChange={(
                      event: React.ChangeEvent<unknown>,
                      option: { label: string; value: string } | undefined
                    ) => handleScheduleTypeChange(option)}
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" fullWidth />
                    )}
                  />
                </PickerWrapper>
                {scheduleType &&
                  scheduleType.value === "weekdays" &&
                  schedulePlan && (
                    <>
                      <SelectsDivider />
                      <PickerWrapper>
                        <Autocomplete
                          disableClearable
                          options={hourOptions}
                          getOptionLabel={(option) => option.label}
                          disabled={isPending}
                          getOptionSelected={(option, value) => {
                            const isEqual = option.value.localeCompare(
                              value.value
                            );

                            return isEqual === 0;
                          }}
                          value={{
                            label: schedulePlan[0].from,
                            value: schedulePlan[0].from,
                          }}
                          onChange={(
                            event: React.ChangeEvent<unknown>,
                            option: { label: string; value: string } | undefined
                          ) => handleSetWeekdaysSchedulePlanFrom(option)}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" fullWidth />
                          )}
                        />
                      </PickerWrapper>
                      <SelectsTypography>to</SelectsTypography>
                      <PickerWrapper>
                        <Autocomplete
                          disableClearable
                          options={hourOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionSelected={(option, value) => {
                            const isEqual = option.value.localeCompare(
                              value.value
                            );

                            return isEqual === 0;
                          }}
                          disabled={isPending}
                          value={{
                            label: schedulePlan[0].to,
                            value: schedulePlan[0].to,
                          }}
                          onChange={(
                            event: React.ChangeEvent<unknown>,
                            option: { label: string; value: string } | undefined
                          ) => handleSetWeekdaysSchedulePlanTo(option)}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" fullWidth />
                          )}
                        />
                      </PickerWrapper>
                    </>
                  )}
              </SelectsWrapper>
            </Grid>
          )}
          {schedule &&
            scheduleType &&
            scheduleType.value === "custom" &&
            schedulePlan &&
            schedulePlan.map((dayPlan, index) => (
              <Grid item xs={12} key={index}>
                <WeekdaysWrapper>
                  <SelectsWrapper>
                    <WeekdayWrapper>
                      <Typography>{dayPlan.day}</Typography>
                    </WeekdayWrapper>
                    <PickerWrapper>
                      <Autocomplete
                        disableClearable
                        options={[
                          { label: "None", value: "None" },
                          ...hourOptions,
                        ]}
                        getOptionLabel={(option) => option.label}
                        disabled={isPending}
                        getOptionSelected={(option, value) => {
                          const isEqual = option.value.localeCompare(
                            value.value
                          );

                          return isEqual === 0;
                        }}
                        value={{
                          label: dayPlan.from,
                          value: dayPlan.from,
                        }}
                        onChange={(
                          event: React.ChangeEvent<unknown>,
                          option: { label: string; value: string } | undefined
                        ) =>
                          handleSetCustomSchedulePlanFrom(option, dayPlan.day)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="From"
                            variant="filled"
                            fullWidth
                          />
                        )}
                      />
                    </PickerWrapper>
                    {dayPlan.from !== "None" && (
                      <>
                        <SelectsTypography>to</SelectsTypography>
                        <PickerWrapper>
                          <Autocomplete
                            disableClearable
                            options={[...hourOptions]}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => {
                              const isEqual = option.value.localeCompare(
                                value.value
                              );

                              return isEqual === 0;
                            }}
                            disabled={isPending}
                            value={{
                              label: dayPlan.to,
                              value: dayPlan.to,
                            }}
                            onChange={(
                              event: React.ChangeEvent<unknown>,
                              option:
                                | { label: string; value: string }
                                | undefined
                            ) =>
                              handleSetCustomSchedulePlanTo(option, dayPlan.day)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="To"
                                variant="filled"
                                fullWidth
                              />
                            )}
                          />
                        </PickerWrapper>
                      </>
                    )}
                  </SelectsWrapper>
                </WeekdaysWrapper>
              </Grid>
            ))}
          <Grid item xs={12}>
            <OutOfOfficeTitle>Out of Office</OutOfOfficeTitle>
          </Grid>
          {outOfOffice.map((singleOutOfOffice) => (
            <Grid key={singleOutOfOffice.id} item xs={12}>
              <PickersWrapper>
                <PickerWrapper>
                  <DateTimePicker
                    value={singleOutOfOffice.startDate}
                    onChange={(value) =>
                      handleStartDateChange(singleOutOfOffice.id, value)
                    }
                    label="Start Date"
                    variant="filled"
                    disabled={isPending}
                    format="MM/DD/yyyy hh:mm A"
                    maxDate={
                      singleOutOfOffice.endDate
                        ? singleOutOfOffice.endDate
                        : undefined
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Event style={{ color: BACKGROUND_DARK }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </PickerWrapper>
                <PickerWrapper>
                  <DateTimePicker
                    value={singleOutOfOffice.endDate}
                    onChange={(value) =>
                      handleEndDateChange(singleOutOfOffice.id, value)
                    }
                    label="End Date"
                    variant="filled"
                    disabled={isPending}
                    format="MM/DD/yyyy hh:mm A"
                    minDate={
                      singleOutOfOffice.startDate
                        ? singleOutOfOffice.startDate
                        : undefined
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Event style={{ color: BACKGROUND_DARK }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </PickerWrapper>
                <DeleteIconWrapper>
                  <IconButton
                    onClick={() => removeOutOfOfficeRow(singleOutOfOffice.id)}
                    disabled={isPending}
                  >
                    <DeleteForever color="error" />
                  </IconButton>
                </DeleteIconWrapper>
              </PickersWrapper>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={addNewOutOfOfficeRow}
              disabled={isAddOutOfOfficeDisabled || isPending}
            >
              <Add /> Add Out of Office
            </Button>
          </Grid>
        </Grid>
      </StyledCardContent>
      <ButtonsWrapper>
        <Button
          color="primary"
          disabled={isPending || disableActionButtons}
          onClick={setControlledData}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isPending || disableActionButtons}
          pending={isPending}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ButtonsWrapper>
    </Card>
  );
};
