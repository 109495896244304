import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { AppDispatch } from "../../../app/store";
import { RecoverPasswordDataModel } from "../../model/Auth";
import { recoverPassword } from "../../store/auth.action";
import { ForgotPasswordForm } from "../../component/ForgotPasswordForm/ForgotPasswordForm";
import { selectIsRecoveryPasswordStatusPending } from "../../store/auth.selector";
import { FORGOT_PASSWORD_SUCCESS } from "../../../app/const/routes";

export const ForgotPasswordFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const isRecoveryPasswordPending = useSelector(
    selectIsRecoveryPasswordStatusPending
  );

  const onSubmit = (recoveryPasswordData: RecoverPasswordDataModel) =>
    dispatch(recoverPassword(recoveryPasswordData));

  return (
    <ForgotPasswordForm
      onSubmit={onSubmit}
      pending={isRecoveryPasswordPending}
      showNotification={location.pathname.includes(FORGOT_PASSWORD_SUCCESS)}
    />
  );
};
