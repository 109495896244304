const { host } = window.location;
const { NODE_ENV, REACT_APP_API_BASE_URL } = process.env;

export const apiConfig = {
  env: NODE_ENV,
  host,
  appName: "TAKO",
  apiRootUrl: REACT_APP_API_BASE_URL,
  apiBaseUrl: "",
};

export const BASE_API_URL = apiConfig.apiRootUrl;
