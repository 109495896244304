import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { RolesState } from "./roles.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { RoleDto } from "../api/roles.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectRolesRootState = createSelector(
  selectSelf,
  (state): RolesState => state.roles
);

export const selectRolesState = createSelector(
  selectRolesRootState,
  (roles): AsyncStateModel<RoleDto[]> => roles.fetchRoles
);

export const selectRolesData = createSelector(
  selectRolesState,
  (roles): RoleDto[] => roles.data
);

export const selectRolesStatus = createSelector(
  selectRolesState,
  (roles): AsyncStatus => roles.status
);

export const selectIsRolesStatusPending = createSelector(
  selectRolesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchRoles = createSelector(
  selectRolesRootState,
  (roles): string => roles.searchRoles
);

export const selectRolesTableData = createSelector(
  selectSearchRoles,
  selectRolesData,
  (searchValue, roles): RoleDto[] => {
    const data = roles.map((role) => role);

    if (searchValue) {
      return data.filter(
        (role) =>
          role.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          JSON.stringify(role.partners.map((e) => e.name))
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          JSON.stringify(role.clients.map((e) => e.name))
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          role.user_count
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          role.description?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsRolesTableStatusPending = createSelector(
  selectRolesStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectParentRolesData = createSelector(
  selectRolesData,
  (roles): RoleDto[] =>
    roles.filter(
      (role) => role.parent_id === null || role.parent_id === role.id
    )
);

export const selectIsParentRolesStatusPending = createSelector(
  selectRolesStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCreateRoleState = createSelector(
  selectRolesRootState,
  (roles): AsyncStateModel<null> => roles.createRole
);

export const selectCreateRoleStatus = createSelector(
  selectCreateRoleState,
  (createRole): AsyncStatus => createRole.status
);

export const selectIsCreateRoleStatusPending = createSelector(
  selectCreateRoleStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditRoleState = createSelector(
  selectRolesRootState,
  (roles): AsyncStateModel<null> => roles.editRole
);

export const selectEditRoleStatus = createSelector(
  selectEditRoleState,
  (editRole): AsyncStatus => editRole.status
);

export const selectIsEditRoleStatusPending = createSelector(
  selectEditRoleStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteRoleState = createSelector(
  selectRolesRootState,
  (roles): AsyncStateModel<null> => roles.deleteRole
);

export const selectDeleteRoleStatus = createSelector(
  selectDeleteRoleState,
  (deleteRole): AsyncStatus => deleteRole.status
);

export const selectIsDeleteRoleStatusPending = createSelector(
  selectDeleteRoleStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
