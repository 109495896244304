import React, { FC, MouseEvent } from "react";
import { MenuItem } from "@material-ui/core";

import { PageSize, StyledButton, StyledMenu } from "./TablePageSizeMenu.style";

interface ITopBarMenu {
  setPageSize: (pageSize: number) => void;
  pageSize: number;
}

const allRows = 100000000;
const options: number[] = [10, 50, 100, allRows];

export const TablePageSizeMenu: FC<ITopBarMenu> = ({
  setPageSize,
  pageSize,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (option: number) => {
    setPageSize(option);
    handleClose();
  };

  return (
    <>
      <StyledButton color="inherit" onClick={handleClick}>
        Showing <PageSize>{pageSize === allRows ? "All" : pageSize}</PageSize>
      </StyledButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            onClick={() => handleOnChange(option)}
            key={index}
            selected={option === pageSize}
          >
            {option === allRows ? "All" : option}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};
