import { authApiClient } from "../../app/api/authApi.client";
import { CreateEditPartnerDto, PartnerDto } from "./partners.api.dto";

export const fetchPartners = async (): Promise<PartnerDto[]> => {
  try {
    const response = await authApiClient.get("/partners");
    return response.data.partners;
  } catch (error) {
    throw error;
  }
};

export const createPartner = async (
  partner: CreateEditPartnerDto
): Promise<void> => {
  try {
    const response = await authApiClient.post("/partners", partner);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePartner = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/partners/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const editPartner = async (
  id: number,
  editPartnerDto: CreateEditPartnerDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(`/partners/${id}`, editPartnerDto);

    return response.data;
  } catch (error) {
    throw error;
  }
};
