import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchUser as fetchUserRequest,
  fetchUsers as fetchUsersRequest,
  createUser as createUserRequest,
  deleteUser as deleteUserRequest,
  editUser as editUserRequest,
  editUserAvatar as editUserAvatarRequest,
  deleteUserAvatar as deleteUserAvatarRequest,
  fetchUserNotifications as fetchUserNotificationsRequest,
  editUserNotifications as editUserNotificationsRequest,
  unsubscribeUserEmailNotifications as unsubscribeUserEmailNotificationsRequest,
} from "../api/users.api";
import withError from "../../app/util/with-thunk-error";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_USER_MODAL, EDIT_USER_MODAL } from "../../app/const/modals";
import { mapCreateEditUserModelToDto } from "../api/users.api.mapper";
import {
  UserDto,
  EditUserDetailsDto,
  UserNotificationsDto,
} from "../api/users.api.dto";
import { CreateEditUserModel } from "../model/users.model";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<UserDto[]> => fetchUsersRequest()
  )
);

export const createUser = createAsyncThunk(
  "users/createUser",
  withError(
    async (user: CreateEditUserModel, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_USER_MODAL }));
      await createUserRequest(mapCreateEditUserModelToDto(user));
      dispatch(closeModal({ name: ADD_USER_MODAL }));
      dispatch(openSuccessNotification("User has been added"));
      dispatch(fetchUsers());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_USER_MODAL }))
  )
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_USER_MODAL }));
      await deleteUserRequest(id);
      dispatch(closeModal({ name: EDIT_USER_MODAL }));
      dispatch(openSuccessNotification("User has been deleted"));
      dispatch(fetchUsers());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_USER_MODAL }))
  )
);

export const editUser = createAsyncThunk(
  "users/editUser",
  withError(
    async (
      {
        id,
        editUserModel,
      }: { id: number | null; editUserModel: CreateEditUserModel },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_USER_MODAL }));
      await editUserRequest(id, mapCreateEditUserModelToDto(editUserModel));
      dispatch(closeModal({ name: EDIT_USER_MODAL }));
      dispatch(openSuccessNotification("User has been updated"));
      dispatch(fetchUsers());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_USER_MODAL }))
  )
);

export const fetchUserDetails = createAsyncThunk(
  "users/fetchUserDetails",
  withError(
    async (): Promise<UserDto> => {
      return fetchUserRequest();
    }
  )
);

export const editUserAvatar = createAsyncThunk(
  "users/editUserAvatar",
  withError(
    async ({
      userId,
      file,
    }: {
      userId: number;
      file: File;
    }): Promise<boolean> => {
      const formData = new FormData();
      formData.append("image", file);

      await editUserAvatarRequest(userId, formData);

      return true;
    }
  )
);

export const deleteUserAvatar = createAsyncThunk(
  "users/deleteUserAvatar",
  withError(
    // eslint-disable-next-line
    async ({ userId }: { userId: number }): Promise<boolean> => {
      await deleteUserAvatarRequest(userId);

      return true;
    }
  )
);

export const editUserDetails = createAsyncThunk(
  "users/editUserDetails",
  withError(
    async (
      {
        id,
        editUserDetailsModel,
      }: { id: number | null; editUserDetailsModel: EditUserDetailsDto },
      { dispatch }
    ): Promise<void> => {
      await editUserRequest(id, editUserDetailsModel);
      dispatch(openSuccessNotification("Profile has been updated"));
    }
  )
);

export const fetchUserNotifications = createAsyncThunk(
  "users/fetchUserNotifications",
  withError(
    async (id: number): Promise<UserNotificationsDto> => {
      return fetchUserNotificationsRequest(id);
    }
  )
);

export const editUserNotifications = createAsyncThunk(
  "users/editUserNotifications",
  withError(
    async (
      {
        id,
        notificationsDto,
      }: { id: number; notificationsDto: UserNotificationsDto },
      { dispatch }
    ): Promise<void> => {
      await editUserNotificationsRequest(id, notificationsDto);
      dispatch(openSuccessNotification("Notifications has been updated"));
    }
  )
);

export const unsubscribeUserEmailNotifications = createAsyncThunk(
  "users/unsubscribeUserEmailNotifications",
  withError(
    async (arg: undefined, { dispatch }): Promise<void> => {
      await unsubscribeUserEmailNotificationsRequest();
      dispatch(
        openSuccessNotification(
          "User has been unsubscribed from email notifications"
        )
      );
    }
  )
);
