import React, { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";

import {
  StyledCard,
  StyledCell,
  StyledAvatar,
} from "./RecentActivitiesTable.style";
import { RecentActivityDto } from "../../../clients/api/clients.api.dto";

interface IDashboardFavorites {
  activities: RecentActivityDto[];
  pending: boolean;
}

export const RecentActivitiesTable: FC<IDashboardFavorites> = ({
  activities,
  pending,
}) => {
  const columns: Column[] = [
    {
      id: "user",
      Header: "User",
      accessor: "user",
      Cell: ({
        row: { original },
      }: {
        row: { original: RecentActivityDto };
      }) => (
        <StyledCell style={{ display: "flex" }}>
          <StyledAvatar src={original.user_photo_url}>
            {original.user?.split(" ")[0]?.charAt(0)}
            {original.user?.split(" ")[1]?.charAt(0)}
          </StyledAvatar>
          {original.user}
        </StyledCell>
      ),
    },
    {
      id: "change",
      Header: "Action",
      accessor: "change",
      Cell: ({ row: { original } }: { row: { original: RecentActivityDto } }) =>
        original.change,
    },
    {
      id: "asset",
      Header: "Asset",
      accessor: "asset",
      Cell: ({ row: { original } }: { row: { original: RecentActivityDto } }) =>
        original.asset,
    },
    {
      id: "timestamp",
      Header: "Time",
      accessor: "timestamp",
      Cell: ({ row: { original } }: { row: { original: RecentActivityDto } }) =>
        original.timestamp,
    },
  ];

  return (
    <StyledCard>
      <Table
        loading={pending}
        columns={columns}
        data={activities}
        formView
        hover
        sorting={{ id: "timestamp", desc: true }}
      />
    </StyledCard>
  );
};
