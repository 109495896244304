import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { TeamsState } from "./teams.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TeamDto } from "../api/teams.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectTeamsRootState = createSelector(
  selectSelf,
  (state): TeamsState => state.teams
);

export const selectTeamsState = createSelector(
  selectTeamsRootState,
  (teams): AsyncStateModel<TeamDto[]> => teams.fetchTeams
);

export const selectTeamsData = createSelector(
  selectTeamsState,
  (teams): TeamDto[] => teams.data
);

export const selectTeamsStatus = createSelector(
  selectTeamsState,
  (teams): AsyncStatus => teams.status
);

export const selectIsTeamsStatusPending = createSelector(
  selectTeamsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchTeams = createSelector(
  selectTeamsRootState,
  (teams): string => teams.searchTeams
);

export const selectTeamsTableData = createSelector(
  selectSearchTeams,
  selectTeamsData,
  (searchValue, teams): TeamDto[] => {
    const data = teams.map((team) => team);

    if (searchValue) {
      return data.filter(
        (team) =>
          team.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          team.client?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          team.description.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsTeamsTableStatusPending = createSelector(
  selectTeamsStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCreateTeamState = createSelector(
  selectTeamsRootState,
  (teams): AsyncStateModel<null> => teams.createTeam
);

export const selectCreateTeamStatus = createSelector(
  selectCreateTeamState,
  (createTeam): AsyncStatus => createTeam.status
);

export const selectIsCreateTeamStatusPending = createSelector(
  selectCreateTeamStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditTeamState = createSelector(
  selectTeamsRootState,
  (teams): AsyncStateModel<null> => teams.editTeam
);

export const selectEditTeamStatus = createSelector(
  selectEditTeamState,
  (editTeam): AsyncStatus => editTeam.status
);

export const selectIsEditTeamStatusPending = createSelector(
  selectEditTeamStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteTeamState = createSelector(
  selectTeamsRootState,
  (teams): AsyncStateModel<null> => teams.deleteTeam
);

export const selectDeleteTeamStatus = createSelector(
  selectDeleteTeamState,
  (deleteTeam): AsyncStatus => deleteTeam.status
);

export const selectIsDeleteTeamStatusPending = createSelector(
  selectDeleteTeamStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
