import { createSlice } from "@reduxjs/toolkit";

import {
  ConnectedModalState,
  connectedModalState,
} from "./connectedModal.store";
import { AsyncStatus } from "../../enum/AsyncStatus";
import { ConnectedModalParamsModel } from "../../model/ConnectedModal";

const slice = createSlice({
  name: "connectedModal",
  initialState: connectedModalState,
  reducers: {
    openModal: (
      state: ConnectedModalState,
      {
        payload,
      }: { payload: { name: string; params?: ConnectedModalParamsModel } }
    ) => [
      ...state,
      {
        name: payload.name,
        params: payload.params,
        status: AsyncStatus.Void,
      },
    ],
    closeModal: (
      state: ConnectedModalState,
      { payload }: { payload: { name: string } }
    ) => [
      ...state.filter((connectedModal) => connectedModal.name !== payload.name),
    ],
    setModalStatusPending: (
      state: ConnectedModalState,
      {
        payload,
      }: { payload: { name: string; params?: ConnectedModalParamsModel } }
    ) => {
      const modal = state.find(
        (connectedModal) => connectedModal.name === payload.name
      );

      if (modal) {
        return [
          ...state.filter(
            (connectedModal) => connectedModal.name !== modal.name
          ),
          { ...modal, status: AsyncStatus.Pending, params: payload.params },
        ];
      }

      return [...state];
    },
    setModalStatusFulfilled: (
      state: ConnectedModalState,
      {
        payload,
      }: { payload: { name: string; params?: ConnectedModalParamsModel } }
    ) => {
      const modal = state.find(
        (connectedModal) => connectedModal.name === payload.name
      );

      if (modal) {
        return [
          ...state.filter(
            (connectedModal) => connectedModal.name !== modal.name
          ),
          { ...modal, status: AsyncStatus.Fulfilled, params: payload.params },
        ];
      }

      return [...state];
    },
    setModalStatusRejected: (
      state: ConnectedModalState,
      {
        payload,
      }: { payload: { name: string; params?: ConnectedModalParamsModel } }
    ) => {
      const modal = state.find(
        (connectedModal) => connectedModal.name === payload.name
      );

      if (modal) {
        return [
          ...state.filter(
            (connectedModal) => connectedModal.name !== modal.name
          ),
          { ...modal, status: AsyncStatus.Rejected, params: payload.params },
        ];
      }

      return [...state];
    },
  },
});

export const {
  openModal,
  closeModal,
  setModalStatusPending,
  setModalStatusFulfilled,
  setModalStatusRejected,
} = slice.actions;
export default slice.reducer;
