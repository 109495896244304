import { createSlice } from "@reduxjs/toolkit";
import { DevicesState, devicesState } from "./devices.store";
import { fetchDevices } from "./devices.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "devices",
  initialState: devicesState,
  reducers: {
    searchDevices: (state: DevicesState, { payload }: { payload: string }) => ({
      ...state,
      searchDevices: payload,
    }),
    clearFetchDevicesState: (state: DevicesState) => ({
      ...state,
      fetchDevices: devicesState.fetchDevices,
    }),
    clearSearchDevicesState: (state: DevicesState) => ({
      ...state,
      searchDevices: devicesState.searchDevices,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.pending, (state) => ({
      ...state,
      fetchDevices: {
        ...state.fetchDevices,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchDevices.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchDevices: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchDevices.rejected, (state) => ({
      ...state,
      fetchDevices: {
        ...state.fetchDevices,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchDevices,
  clearFetchDevicesState,
  clearSearchDevicesState,
} = slice.actions;
export default slice.reducer;
