import "typeface-roboto";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import { StylesProvider, MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from "notistack";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store, history } from "./module/app/store";
import theme from "./module/app/theme";
import { App } from "./module/app/App";
import { GlobalStyles } from "./module/app/component/DashboardLayout/GlobalStyles";
import { LoginView } from "./module/auth/view/LoginView/LoginView";
import { RegisterView } from "./module/auth/view/RegisterView/RegisterView";
import { ForgotPasswordView } from "./module/auth/view/ForgotPasswordView/ForgotPasswordView";
import { ConnectedPrivateRoute } from "./module/app/component/ConnectedPrivateRoute/ConnectedPrivateRoute";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  PANEL,
  PRIVACYPOLICY,
  REGISTER,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  UNVERIFIED,
  VERIFIED,
  DASHBOARD,
} from "./module/app/const/routes";
import { VerifiedView } from "./module/auth/view/VerifiedView/VerifiedView";
import { UnverifiedView } from "./module/auth/view/UnverifiedView/UnverifiedView";
import { ForgotPasswordSuccessView } from "./module/auth/view/ForgotPasswordSuccessView/ForgotPasswordSuccessView";
import { ResetPasswordSuccessView } from "./module/auth/view/ResetPasswordSuccessView/ResetPasswordSuccessView";
import { ResetPasswordErrorView } from "./module/auth/view/ResetPasswordErrorView/ResetPasswordErrorView";
import { ResetPasswordView } from "./module/auth/view/ResetPasswordView/ResetPasswordView";
import { ConnectedNotifications } from "./module/app/component/ConnectedNotifications/ConnectedNotifications";
import { PrivacyPolicy } from "./module/auth/component/PrivacyPolicy/PrivacyPolicy";
import { authApiClient } from "./module/app/api/authApi.client";
import {
  onFulfilled,
  onRejected,
} from "./module/app/api/interceptors/logout.interceptor";
import { ReactComponent as SuccessCheck } from "./assets/icons/successCheck.svg";

authApiClient.interceptors.response.use(onFulfilled, onRejected);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider
                style={{ paddingRight: "54px" }}
                autoHideDuration={3000}
                maxSnack={4}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                iconVariant={{
                  success: <SuccessCheck style={{ marginRight: "14px" }} />,
                }}
              >
                <GlobalStyles />
                <Switch>
                  <Route path={PRIVACYPOLICY} component={PrivacyPolicy} exact />
                  <Route path={LOGIN} component={LoginView} exact />
                  <Route path={REGISTER} component={RegisterView} exact />
                  <Route
                    path={FORGOT_PASSWORD}
                    component={ForgotPasswordView}
                    exact
                  />
                  <Route
                    path={FORGOT_PASSWORD_SUCCESS}
                    component={ForgotPasswordView}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD}
                    component={ResetPasswordView}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_SUCCESS}
                    component={ResetPasswordSuccessView}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_ERROR}
                    component={ResetPasswordErrorView}
                    exact
                  />
                  <Route path={VERIFIED} component={VerifiedView} exact />
                  <Route path={UNVERIFIED} component={UnverifiedView} exact />
                  <ConnectedPrivateRoute path={PANEL} component={App} />

                  <Route path="*">
                    <Redirect to={DASHBOARD} />
                  </Route>
                </Switch>
                <ConnectedNotifications />
              </SnackbarProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </ThemeProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
