import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { BookmarksState } from "./bookmarks.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { BookmarkModel } from "../model/bookmarks.model";

export const selectSelf = (state: RootState): RootState => state;

export const selectBookmarksState = createSelector(
  selectSelf,
  (state: RootState): BookmarksState => state.bookmarks
);

export const selectFetchBookmarksState = createSelector(
  selectBookmarksState,
  (bookmarks): AsyncStateModel<BookmarkModel[]> => bookmarks.fetchBookmarks
);

export const selectFetchBookmarksData = createSelector(
  selectFetchBookmarksState,
  (fetchbookmarks): BookmarkModel[] =>
    fetchbookmarks.data.slice().sort((a, b) => a.position - b.position)
);

export const selectIsFetchBookmarksStatusPending = createSelector(
  selectFetchBookmarksState,
  (bookmarks): boolean => bookmarks.status === AsyncStatus.Pending
);
