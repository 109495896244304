import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import {
  searchTasks as searchTasksAction,
  clearSearchTasksState as clearSearchTasksStateAction,
} from "../store/workOrders.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";

export const TasksSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    return () => {
      dispatch(clearSearchTasksStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchTasksAction(value));

  return (
    <TableSearchInput label="Search..." onSearch={debounce(onSearch, 200)} />
  );
};
