import styled from "styled-components";
import { CardContent, Typography } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";
import { TEXT_SECONDARY } from "../../../app/theme/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;

  .MuiTypography-body2 {
    display: inline-block;
    margin-left: 5px;
  }
`;

export const ColumnWrapper = styled(Wrapper)`
  flex-direction: column;
  align-items: normal;
`;

export const ImageWrapper = styled(Wrapper)`
  justify-content: center;

  svg {
    width: 125px;
    height: 125px;
  }
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  font-weight: 600;
  line-height: inherit;
  padding: 0;
  min-width: 42px;
  margin-left: 5px;
`;

export const TagsWrapper = styled(Wrapper)`
  height: 45px;
`;

export const Label = styled(Typography)`
  font-size: 12px;
`;

export const Value = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  white-space: nowrap;
`;
export const DateText = styled(Typography)`
  margin-left: 0;
`;
export const StyledTypography = styled(Typography)`
  margin-top: 1.8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen and (max-width: 1407px) {
    width: 125px;
  }
  @media only screen and (max-width: 1398px) {
    width: 112px;
  }
  @media only screen and (max-width: 1305px) {
    width: 100px;
  }
  @media only screen and (max-width: 1279px) {
    width: auto;
  }
`;

export const StatusWrapper = styled.span<{ activeStatus: string | null }>`
  position: absolute;
  right: 0;
  display: inline-flex;
  border-radius: 20px;
  background-color: ${({ activeStatus }) =>
    activeStatus === "On"
      ? "rgba(63, 198, 142, 0.1)"
      : activeStatus === "Off"
      ? "rgba(241, 88, 81, 0.1)"
      : "rgba(95, 99, 104, 0.1)"};
  padding: 4px ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme, activeStatus }) =>
    activeStatus === "On"
      ? theme.palette.icon.green.color
      : activeStatus === "Off"
      ? theme.palette.icon.error.color
      : TEXT_SECONDARY};
  align-items: center;

  svg {
    width: 18px;
    margin-right: 3px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const StyledCardContent = styled(CardContent)`
  && {
    padding-top: 0;
    padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;
