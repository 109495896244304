import { Button } from "@material-ui/core";
import styled from "styled-components";

export const OutlinedRedButton = styled(Button)`
  color: ${({ theme }) => `${theme.palette.button.error.color}`};
  border-color: ${({ theme }) => `${theme.palette.button.error.color}`};
  width: 184px;
  padding: 4.75px 15px;
  background-color: transparent;
  :hover {
    color: ${({ theme }) => `${theme.palette.button.error.color}`};
    background-color: ${({ theme }) =>
      `${theme.palette.button.error.hover.backgroundColor}`};
  }
`;

export const OutlinedPurpleButton = styled(Button)`
  color: ${({ theme }) =>
    `${theme.overrides.MuiButton.containedPrimary.backgroundColor}`};
  border-color: ${({ theme }) =>
    `${theme.overrides.MuiButton.containedPrimary.backgroundColor}`};
  width: 261px;
  background-color: transparent;
  padding: 4.75px 15px;
  :hover {
    background-color: ${({ theme }) =>
      `${theme.overrides.MuiButton.containedPrimary.hover.background}`};
  }
`;

export const ContainedRedButton = styled(Button)`
  color: white;
  font-weight: 700;
  background-color: ${({ theme }) => `${theme.palette.button.error.color}`};
  :hover {
    color: white;
    background-color: ${({ theme }) => `${theme.palette.button.error.color}`};
  }
`;

export const GreyButton = styled(Button)`
  color: ${({ theme }) => `${theme.palette.text.secondary}`};
  font-weight: 700;
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;
