import styled from "styled-components";
import { Box, Card, Typography } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";

import { ReactComponent as Logo } from "../../../../assets/logo.svg";

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  text-align: right;
  color: #1f1e4d;
`;

export const StyledBox = styled(Box)`
  position: relative;
`;

export const StyledLogo = styled(Logo)`
  width: 132px;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => `${theme.palette.button.primary}`};
  color: ${({ theme }) => `${theme.palette.button.text}`};
`;
