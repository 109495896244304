import React, { FC } from "react";
import { Box, Typography, CircularProgress, Tooltip } from "@material-ui/core";
import zingchart, { ZC } from "zingchart/es6";
import { RemoveCircleOutline, InfoOutlined } from "@material-ui/icons";
import {
  HEALTH_GAUGE_BAD,
  HEALTH_GAUGE_FAIR,
  HEALTH_GAUGE_GOOD,
  HEALTH_GAUGE_VALUE_BOX,
  HEALTH_GAUGE_LABELS,
  HEALTH_GAUGE_INNER_RING,
  HEALTH_GAUGE_INDICATOR,
  TEXT_SECONDARY,
} from "../../../app/theme/colors";
import {
  StyledCardContent,
  StyledGaugeCard,
  StyledHealthDisabledCard,
  StyledHealthDisabledCardContent,
  LoaderContainer,
  StyledCardFooter,
  StyledLabel,
  StyledTooltipWrapper,
} from "./AssetHealthGauge.style";
import { StyledCardHeader } from "../../../app/component/DashboardLayout/DashboardLayout.style";

const ZingChart = require("zingchart-react").default;

const { ZINGCHART_LICENSE } = process.env;

// ZC.LICENSE = [ZINGCHART_LICENSE || ""];
ZC.LICENSE = ["5062b0408d7e507a560088e593fba0b2"];

export interface IAssetHealthGaugeProps {
  assetHealth: number | string;
  healthEnabled: boolean;
  pending?: boolean;
  totalAssets?: number | string;
  isAverage?: boolean;
  showLoader?: boolean;
}

export const AssetHealthGauge: FC<IAssetHealthGaugeProps> = ({
  assetHealth,
  healthEnabled,
  pending,
  totalAssets,
  isAverage,
  showLoader,
}) => {
  const gaugeData = {
    type: "gauge",
    plot: {
      size: "100%",
      valueBox: {
        placement: "center",
        text: "%v",
        fontSize: 20,
        offsetY: -20,
        rules: [
          {
            rule: "%v <= 2.5",
            text: "Poor %v",
            backgroundColor: HEALTH_GAUGE_BAD,
            color: HEALTH_GAUGE_VALUE_BOX,
            borderRadius: "2px",
            padding: "5px 10px",
            boxShadow: "none",
          },
          {
            rule: "%v > 2.5 && %v < 5.0",
            text: "Fair %v",
            backgroundColor: HEALTH_GAUGE_FAIR,
            color: HEALTH_GAUGE_VALUE_BOX,
            borderRadius: "2px",
          },
          {
            rule: "%v >= 5.0",
            text: "Good %v",
            backgroundColor: HEALTH_GAUGE_GOOD,
            color: HEALTH_GAUGE_VALUE_BOX,
            borderRadius: "2px",
          },
        ],
      },
    },
    plotarea: {
      x: 0,
      y: 80,
      marginLeft: 5,
      marginRight: 5,
    },
    tooltip: {
      borderRadius: 5,
    },
    scale: {
      sizeFactor: 1.4,
    },
    scaleR: {
      aperture: 180,
      minValue: 0,
      maxValue: 10,
      step: 2.5,
      center: {
        visible: false,
      },
      tick: {
        visible: false,
      },
      item: {
        offsetR: 0,
        fontColor: HEALTH_GAUGE_LABELS,
        fontWeight: "800",
        fontSize: "14px",
      },
      labels: ["", "2.5", "5.0", "", ""],
      ring: {
        size: 20,
        rules: [
          {
            rule: "%v <= 2.5",
            backgroundColor: HEALTH_GAUGE_BAD,
          },
          {
            rule: "%v > 2.5 && %v < 5.0",
            backgroundColor: HEALTH_GAUGE_FAIR,
          },
          {
            rule: "%v >= 5.0",
            backgroundColor: HEALTH_GAUGE_GOOD,
          },
        ],
      },
    },
    "scale-2": {
      sizeFactor: 1.1,
    },
    "scale-r-2": {
      aperture: 180,
      minValue: 0,
      maxValue: 10,
      step: 2.5,
      labels: ["0", "", "", "", "10"],
      center: {
        visible: false,
      },
      tick: {
        visible: false,
      },
      guide: {
        visible: false,
      },
      item: {
        offsetR: 5,
        offsetY: 20,
        fontColor: HEALTH_GAUGE_LABELS,
        fontWeight: "800",
        fontSize: "14px",
      },
      ring: {
        size: 1.5,
        backgroundColor: HEALTH_GAUGE_INNER_RING,
      },
    },
    refresh: {
      type: "feed",
      transport: "js",
      url: "getHealth()",
      interval: 20,
      resetTimeout: 1000,
    },
    series: [
      {
        values: [assetHealth],
        backgroundColor: HEALTH_GAUGE_INDICATOR,
        indicator: [5, 5, 8, 8, 0.55],
        animation: {
          effect: showLoader ? 2 : null,
          method: 1,
          sequence: 4,
          speed: 900,
        },
      },
    ],
  };

  return (
    <>
      {pending ? (
        <LoaderContainer>
          <CircularProgress size={50} />
        </LoaderContainer>
      ) : healthEnabled &&
        assetHealth !== null &&
        !Number.isNaN(assetHealth) &&
        assetHealth !== "\n" ? (
        <StyledGaugeCard>
          <StyledCardHeader
            title={isAverage ? "Operation Health" : "Asset Health"}
          />
          <StyledCardContent>
            <ZingChart data={gaugeData} width="100%" height={200} />
          </StyledCardContent>
          {isAverage ? (
            <StyledCardFooter>
              <Box />

              <StyledLabel variant="h4">
                {totalAssets} <span>Contributing Assets</span>
              </StyledLabel>
              <StyledTooltipWrapper>
                <Tooltip
                  title="View a count of how many assets are feeding into your overall Operation Health."
                  placement="top-end"
                >
                  <InfoOutlined />
                </Tooltip>
              </StyledTooltipWrapper>
            </StyledCardFooter>
          ) : null}
        </StyledGaugeCard>
      ) : (
        <StyledHealthDisabledCard>
          <StyledHealthDisabledCardContent>
            <Typography variant="h3" align="center">
              <Box mb={2}>
                <RemoveCircleOutline
                  fontSize="large"
                  style={{ color: TEXT_SECONDARY }}
                />
              </Box>
              <Box>Health rating is disabled</Box>
            </Typography>
          </StyledHealthDisabledCardContent>
        </StyledHealthDisabledCard>
      )}
    </>
  );
};
