import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { DevicesTableContainer } from "../../container/DevicesTableContainer";
import { DevicesSearchContainer } from "../../container/DevicesSearchContainer";
import { AddDeviceModalContainer } from "../../container/AddDeviceModalContainer";

export const DevicesView: FC = () => (
  <StyledPage title="Devices">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Devices" />
        <ConnectedShowForPermissions
          feature="devices"
          permissions={{ allOf: ["read"] }}
        >
          <PageActionsWrapper>
            <DevicesSearchContainer />
            <ConnectedShowForPermissions
              feature="devices"
              permissions={{ allOf: ["create"] }}
            >
              <AddDeviceModalContainer />
            </ConnectedShowForPermissions>
          </PageActionsWrapper>
        </ConnectedShowForPermissions>
      </PageHeaderContainer>
      <ConnectedShowForPermissions
        feature="devices"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <DevicesTableContainer />
        </Box>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
