import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { Button } from "../../app/component/Button/Button";
import {
  createAsset,
  fetchAssets,
  fetchCategories,
  fetchTags,
} from "../store/asset.action";
import { fetchLocations } from "../../location/store/locations.action";
import { fetchTeams } from "../../teams/store/teams.action";
import {
  selectCategories,
  selectTags,
  selectIsAssetsTableStatusPending,
  selectIsTagsStatusPending,
  selectIsCategoriesStatusPending,
  selectAssetsData,
  selectIsAssetsStatusPending,
} from "../store/asset.selector";
import {
  selectIsLocationsStatusPending,
  selectLocationsData,
} from "../../location/store/locations.selector";
import {
  selectIsTeamsTableStatusPending,
  selectTeamsTableData,
} from "../../teams/store/teams.selector";
import { AssetModal } from "../component/AssetModal/AssetModal";
import { AppDispatch } from "../../app/store";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_ASSET_MODAL } from "../../app/const/modals";
import { CreateEditAssetModel } from "../model/asset.model";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { fetchClients } from "../../clients/store/clients.action";
import { fetchDevices } from "../../devices/store/devices.action";
import {
  selectFetchDevicesData,
  selectIsFetchDevicesStatusPending,
} from "../../devices/store/devices.selector";

export const AddAssetModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tags = useSelector(selectTags);
  const isFetchTagsPending = useSelector(selectIsTagsStatusPending);
  const locations = useSelector(selectLocationsData);
  const isFetchLocationsPending = useSelector(selectIsLocationsStatusPending);
  const teams = useSelector(selectTeamsTableData);
  const isFetchTeamsPending = useSelector(selectIsTeamsTableStatusPending);
  const categories = useSelector(selectCategories);
  const isFetchCategoriesPending = useSelector(selectIsCategoriesStatusPending);
  const clients = useSelector(selectClientsData);
  const devices = useSelector(selectFetchDevicesData);
  const assets = useSelector(selectAssetsData);
  const isFetchDevicesPending = useSelector(selectIsFetchDevicesStatusPending);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const isFetchAssetsPending = useSelector(selectIsAssetsStatusPending);
  const isAssetsPending = useSelector(selectIsAssetsTableStatusPending);
  const { search } = useLocation();
  const { clientId }: { clientId?: string } = parse(search);

  const openModal = () => dispatch(openModalAction({ name: ADD_ASSET_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_ASSET_MODAL }));

  const onCreateAsset = (values: CreateEditAssetModel) =>
    dispatch(createAsset({ asset: values, clientId }));

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchLocations());
    dispatch(fetchTeams());
    dispatch(fetchTags());
    dispatch(fetchClients());
    dispatch(fetchDevices());
  }, [dispatch]);

  return (
    <>
      <ConnectedShowForPermissions
        feature="assets"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isAssetsPending}
        >
          Add Asset
        </Button>
      </ConnectedShowForPermissions>
      <AssetModal
        name={ADD_ASSET_MODAL}
        onClose={closeModal}
        onSubmit={onCreateAsset}
        isPending={
          isFetchTagsPending ||
          isFetchLocationsPending ||
          isFetchTeamsPending ||
          isFetchCategoriesPending ||
          isFetchClientsPending ||
          isFetchDevicesPending ||
          isFetchAssetsPending
        }
        tags={tags}
        locations={locations}
        teams={teams}
        categories={categories}
        clients={clients}
        devices={devices}
        assets={assets}
      />
    </>
  );
};
