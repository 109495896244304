import React, { FC, useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getAssetImage } from "../../../asset/util/get-asset-image";
import {
  AssetName,
  ImageWrapper,
  StyledPaper,
  TabName,
} from "./DashboardFavorites.style";
import { BookmarkModel } from "../../../bookmarks/model/bookmarks.model";

interface IDashboardFavorites {
  favorites: BookmarkModel[];
  onFavoriteClick: (favorites: BookmarkModel) => void;
  pending: boolean;
  onReposition: (bookmarksIds: number[]) => void;
}

export const DashboardFavorites: FC<IDashboardFavorites> = ({
  favorites,
  onFavoriteClick,
  pending,
  onReposition,
}) => {
  const [favoritesToDisplay, setFavoritesToDisplay] = useState(favorites);

  useEffect(() => {
    setFavoritesToDisplay(favorites);
  }, [favorites]);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      favoritesToDisplay,
      result.source.index,
      result.destination.index
    ) as BookmarkModel[];

    onReposition(items.map((item) => parseInt(item.id, 10)));
    setFavoritesToDisplay(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginBottom={favorites.length === 0 ? 5 : 0}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="h3">Favorite Assets</Typography>
            <Box mr={1} />
          </Box>
        </Box>
        {pending ? (
          <CircularProgress size={50} />
        ) : (
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Grid container spacing={3}>
                  {favoritesToDisplay.map((favorite, index) => (
                    <Draggable
                      draggableId={favorite.id}
                      index={index}
                      key={favorite.id}
                    >
                      {(draggableProvided) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={3}
                          key={favorite.id}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <StyledPaper
                            onClick={() => onFavoriteClick(favorite)}
                          >
                            <ImageWrapper>
                              {getAssetImage(
                                favorite.asset_live_graph_svg || 0
                              )}
                            </ImageWrapper>
                            <Box
                              display="flex"
                              flex="1"
                              justifyContent="center"
                              padding={2}
                            >
                              <Typography>
                                <AssetName>
                                  {favorite.asset_name
                                    ? `${favorite.asset_name}: `
                                    : ""}
                                </AssetName>
                                <TabName>{favorite.tab_name}</TabName>
                              </Typography>
                            </Box>
                          </StyledPaper>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {favoritesToDisplay.length > 4 ? null : provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>
        )}
      </Box>
    </DragDropContext>
  );
};
