import styled from "styled-components";

export const IconButtonWrapper = styled.div`
  margin-top: -3px;
  margin-right: auto;

  button {
    margin-right: 5px;
  }

  svg {
    color: #5f6368;
  }
`;
