import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";

import { ADD_ASSET_ATTACHMENT_MODAL } from "../../app/const/modals";
import {
  addAssetAttachment,
  fetchAssetAttachments,
} from "../store/asset.action";
import { AddAssetAttachmentModal } from "../component/AddAssetAttachmentModal/AddAssetAttachmentModal";

export const AddAssetAttachmentModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ assetId: string }>();

  const openModal = () =>
    dispatch(openModalAction({ name: ADD_ASSET_ATTACHMENT_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_ASSET_ATTACHMENT_MODAL }));

  const handleOnSubmit = async (name: string, file: File): Promise<void> => {
    await dispatch(
      addAssetAttachment({ assetId: Number(params.assetId), name, file })
    );
    await dispatch(fetchAssetAttachments(Number(params.assetId)));
  };

  return (
    <AddAssetAttachmentModal
      name={ADD_ASSET_ATTACHMENT_MODAL}
      onClose={closeModal}
      onOpen={openModal}
      onSubmit={handleOnSubmit}
    />
  );
};
