import React, { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Export } from "../../../app/component/Icons/ExportIcon";
import { AssetRuntimeDto } from "../../api/asset.api.dto";
import { ExportIconButton, LoaderOverlay } from "./AnalyticsGraphs.style";
import { ASSET_TRENDS_COLORS } from "../../../app/theme/colors";

interface IRuntimeGraph {
  runtimeData: AssetRuntimeDto[];
  isGraphsPending: boolean;
}

export const RuntimeGraph: FC<IRuntimeGraph> = ({
  runtimeData,
  isGraphsPending,
}) => {
  const generateGraphExportPdf = () => {
    const element = document.getElementById("runtime-graph");

    if (element) {
      html2canvas(element, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "px");

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("RuntimeGraph.pdf");
      });
    }
  };

  return (
    <Card>
      {isGraphsPending && (
        <LoaderOverlay>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      <CardContent id="runtime-graph">
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <Box alignSelf="center" flexGrow={1}>
            <Typography variant="h2">Uptime/Downtime Comparison</Typography>
          </Box>
          <Box alignSelf="center" mr={1}>
            <ExportIconButton
              data-html2canvas-ignore="true"
              startIcon={<Export />}
              pending={isGraphsPending}
              onClick={generateGraphExportPdf}
            />
          </Box>
        </Box>
        <Box mt={2}>
          {runtimeData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={runtimeData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="asset_name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="uptimeMins"
                  name="Uptime"
                  fill={ASSET_TRENDS_COLORS[0]}
                />
                <Bar
                  dataKey="downtimeMins"
                  name="Downtime"
                  fill={ASSET_TRENDS_COLORS[1]}
                />
                <Bar
                  dataKey="runtimeMins"
                  name="Runtime"
                  fill={ASSET_TRENDS_COLORS[2]}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};
