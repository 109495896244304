import { Box, Card, Grid } from "@material-ui/core";
import React, { FC, useState } from "react";
import { AssetDto, SensorDto } from "../../../api/asset.api.dto";
import { StyledCardTitle } from "./AssetRealtimeSensors.style";
import { ChannelDetails } from "./ChannelDetails";
import { Channels } from "./Channels";

export interface IAssetRealtimeCardsProps {
  asset: AssetDto | null;
  sensors: SensorDto[];
}

export const AssetRealtimeCards: FC<IAssetRealtimeCardsProps> = ({
  asset,
  sensors,
}) => {
  const [selectedSensor, setSelectedSensor] = useState(0);
  const sortedSensors = sensors
    .slice()
    .sort((a, b) => a.channel.channel_index - b.channel.channel_index);

  return (
    <Card>
      <Box p={2}>
        <Box mb={1}>
          <StyledCardTitle>Sensor Info</StyledCardTitle>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Channels
              sensors={sortedSensors}
              selectedSensor={selectedSensor}
              onClick={setSelectedSensor}
              asset={asset}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            {sensors.length ? (
              <ChannelDetails
                sensors={sortedSensors}
                selectedSensor={selectedSensor}
                asset={asset}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
