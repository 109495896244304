import { Tooltip } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { AssetFilterStatus } from "../../../app/enum/AssetStatus";
import {
  StyledTabs,
  Counter,
  Label,
  FloaterInfoIcon,
  StyledBar,
  StyledTab,
  BorderedTab,
  Divider,
} from "./AssetsFilter.style";
import { ReactComponent as LowHealthIcon } from "../../../../assets/low-health-icon.svg";
import { ReactComponent as GrayAlarmIcon } from "../../../../assets/grayAlarm.svg";

export interface IAssetsFilter {
  handleChange: (event: React.ChangeEvent<unknown>, newValue: string) => void;
  activeTab: string;
  assetsFilterNumbers: any;
  wrapped: boolean;
}

export const AssetsFilter: FC<IAssetsFilter> = ({
  handleChange,
  activeTab,
  assetsFilterNumbers,
  wrapped,
}) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledBar>
      <StyledTabs
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="force tabs"
        value={activeTab}
        variant={isSmallDevice ? "scrollable" : "fullWidth"}
        scrollButtons="on"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        <StyledTab
          wrapped={wrapped}
          label="State: ON"
          icon={<Counter>{assetsFilterNumbers.numberOfOnStatus}</Counter>}
          value={AssetFilterStatus.on}
          selected={activeTab === AssetFilterStatus.on}
        />
        <StyledTab
          wrapped={wrapped}
          label="STATE: OFF"
          icon={<Counter>{assetsFilterNumbers.numberOfOffStatus}</Counter>}
          value={AssetFilterStatus.off}
          selected={activeTab === AssetFilterStatus.off}
        />
        <StyledTab
          wrapped={wrapped}
          label="STATE: N/A"
          icon={<Counter>{assetsFilterNumbers.numberOfNAStatus}</Counter>}
          value={AssetFilterStatus.na}
          selected={activeTab === AssetFilterStatus.na}
        />
        <StyledTab
          wrapped={wrapped}
          label="TOTAL"
          icon={<Counter>{assetsFilterNumbers.numberOfTotal}</Counter>}
          value={AssetFilterStatus.total}
          selected={activeTab === AssetFilterStatus.total}
        />
        <BorderedTab
          label={
            <div>
              <LowHealthIcon style={{ marginRight: "10px" }} />
              <Label>Low Health</Label>
              <Tooltip
                title="View a count of the total number of assets in your organization that are currently in a state of Low Health."
                placement="top-end"
              >
                <FloaterInfoIcon />
              </Tooltip>
            </div>
          }
          icon={<Counter>{assetsFilterNumbers.numberOfLowHealth}</Counter>}
          value={AssetFilterStatus.health}
          selected={activeTab === AssetFilterStatus.health}
        />
        <StyledTab
          wrapped={wrapped}
          label={
            <div>
              <GrayAlarmIcon style={{ marginRight: "10px" }} />
              <Label>Alarms</Label>
              <Tooltip
                title="View a count of the total number of assets in your organization that are currently in a state of Alarm."
                placement="top-end"
              >
                <FloaterInfoIcon />
              </Tooltip>
            </div>
          }
          icon={<Counter>{assetsFilterNumbers.numberOfAlarms}</Counter>}
          value={AssetFilterStatus.alarm}
          selected={activeTab === AssetFilterStatus.alarm}
        />
      </StyledTabs>
    </StyledBar>
  );
};
