import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createRole as createRoleAction } from "../store/roles.action";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { RoleModal } from "../component/RoleModal/RoleModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditRoleDto } from "../api/roles.api.dto";
import { ADD_ROLE_MODAL } from "../../app/const/modals";
import {
  selectParentRolesData,
  selectIsParentRolesStatusPending,
  selectIsRolesTableStatusPending,
} from "../store/roles.selector";
import {
  selectPartnersData,
  selectIsPartnersStatusPending,
} from "../../partners/store/partners.selector";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";

export const AddRoleModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const parentRolesData = useSelector(selectParentRolesData);
  const partnersData = useSelector(selectPartnersData);
  const clientsData = useSelector(selectClientsData);
  const isParentRolesPending = useSelector(selectIsParentRolesStatusPending);
  const isRolesPending = useSelector(selectIsRolesTableStatusPending);
  const isPartnersPending = useSelector(selectIsPartnersStatusPending);
  const isClientsPending = useSelector(selectIsClientsStatusPending);

  const openModal = () => dispatch(openModalAction({ name: ADD_ROLE_MODAL }));

  const closeModal = () => dispatch(closeModalAction({ name: ADD_ROLE_MODAL }));

  const onCreateRole = (values: CreateEditRoleDto) =>
    dispatch(createRoleAction(values));

  return (
    <>
      <ConnectedShowForPermissions
        feature="roles"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isRolesPending}
        >
          Add Role
        </Button>
      </ConnectedShowForPermissions>
      <RoleModal
        name={ADD_ROLE_MODAL}
        onClose={closeModal}
        onSubmit={onCreateRole}
        parentRoles={parentRolesData}
        partners={partnersData}
        clients={clientsData}
        isPending={
          isParentRolesPending || isPartnersPending || isClientsPending
        }
      />
    </>
  );
};
