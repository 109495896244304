import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "../../app/hooks/useInterval";
import { AppDispatch } from "../../app/store";
import { fetchClientTodos } from "../../clients/store/clients.action";
import {
  selectTodosStateData,
  selectIsTodosStatusPending,
} from "../../clients/store/clients.selector";
import { AlertsTable } from "../component/AlertsTable/AlertsTable";

export const AlertsTableContainer: FC<{ clientId: number }> = ({
  clientId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const alerts = useSelector(selectTodosStateData);
  const pending = useSelector(selectIsTodosStatusPending);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchClientTodos(clientId));
  }, [dispatch, clientId]);

  useInterval(() => {
    dispatch(fetchClientTodos(clientId));
    if (showLoader) {
      setShowLoader(false);
    }
  });

  const filteredAlerts = alerts.filter(
    (sensor) =>
      sensor.is_enabled === 1 &&
      sensor.state !== "errored" &&
      (sensor.alarm || sensor.equipment.health_is_enabled)
  );

  return (
    <AlertsTable alerts={filteredAlerts} pending={pending && showLoader} />
  );
};
