import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  min-width: 250px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 5px;
  }
`;
