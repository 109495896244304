import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppDispatch } from "../../../app/store";
import { CategoryDto } from "../../../category/api/category.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { LocationDto } from "../../../location/api/locations.api.dto";
import { AssetDto, AssetTagDto } from "../../api/asset.api.dto";
import { fetchClients as fetchClientsAction } from "../../../clients/store/clients.action";
import { fetchLocations as fetchLocationsAction } from "../../../location/store/locations.action";
import { fetchAssets as fetchAssetsAction } from "../../store/asset.action";
import { selectAssetsData } from "../../store/asset.selector";
import { selectClientsData } from "../../../clients/store/clients.selector";
import { AnalyticsSelectorsConfirmationModal } from "./AnalyticsSelectorsConfirmationModal";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";

interface IAnalyticsSelectorsProps {
  selectedAssets: AssetDto[];
  setSelectedAssets: (assets: AssetDto[]) => void;
}

export const AnalyticsSelectors: FC<IAnalyticsSelectorsProps> = ({
  selectedAssets,
  setSelectedAssets,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      endAdornment: {
        "& .MuiAutocomplete-endAdornment": {
          top: "30px",
        },
      },
    })
  );
  const classes = useStyles();

  const dispatch = useDispatch<AppDispatch>();

  const [filteredClients, setFilteredClients] = useState<ClientDto[]>([]);
  const [filteredClientsAssets, setFilteredClientsAssets] = useState<
    AssetDto[]
  >([]);
  const [selectedClient, setSelectedClient] = useState<ClientDto | null>(null);

  const [filteredCategories, setFilteredCategories] = useState<CategoryDto[]>(
    []
  );
  const [filteredCategoriesAssets, setFilteredCategoriesAssets] = useState<
    AssetDto[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<CategoryDto[]>(
    []
  );

  const [filteredManufacturers, setFilteredManufacturers] = useState<string[]>(
    []
  );
  const [
    filteredManufacturersAssets,
    setFilteredManufacturersAssets,
  ] = useState<AssetDto[]>([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>(
    []
  );

  const [filteredModels, setFilteredModels] = useState<string[]>([]);
  const [filteredModelsAssets, setFilteredModelsAssets] = useState<AssetDto[]>(
    []
  );
  const [selectedModels, setSelectedModels] = useState<string[]>([]);

  const [filteredLocations, setFilteredLocations] = useState<LocationDto[]>([]);
  const [filteredLocationsAssets, setFilteredLocationsAssets] = useState<
    AssetDto[]
  >([]);
  const [selectedLocations, setSelectedLocations] = useState<LocationDto[]>([]);

  const [filteredTags, setFilteredTags] = useState<AssetTagDto[]>([]);
  const [filteredTagsAssets, setFilteredTagsAssets] = useState<AssetDto[]>([]);
  const [selectedTags, setSelectedTags] = useState<AssetTagDto[]>([]);

  const [filteredAssets, setFilteredAssets] = useState<AssetDto[]>([]);
  const [assetOptions, setAssetOptions] = useState<AssetDto[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [updateFilterMethod, setUpdateFilterMethod] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    method: Dispatch<SetStateAction<any | null>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any | null;
  }>({ method: () => {}, value: null });

  const assets = useSelector(selectAssetsData);
  const clients = useSelector(selectClientsData);

  useEffect(() => {
    dispatch(fetchAssetsAction());
    dispatch(fetchClientsAction());
    dispatch(fetchLocationsAction());
  }, [dispatch]);

  useEffect(() => {
    setFilteredClients(clients);
    const filtered = selectedClient
      ? assets.filter((asset) => asset.client_id === selectedClient.id)
      : assets;
    setFilteredClientsAssets(filtered);
    setSelectedCategories([]);
    setSelectedManufacturers([]);
    setSelectedModels([]);
    setSelectedLocations([]);
    setSelectedTags([]);
    setSelectedAssets([]);
  }, [clients, assets, selectedClient, setSelectedAssets]);

  useEffect(() => {
    const categoryMap: { [key: number]: CategoryDto } = {};
    filteredClientsAssets.forEach((asset) => {
      asset.categories?.forEach((category) => {
        categoryMap[category.id] = category;
      });
    });
    setFilteredCategories(Object.values(categoryMap));
    const filtered =
      selectedCategories.length > 0
        ? filteredClientsAssets.filter((asset) => {
            return (
              asset.categories &&
              asset.categories.filter((category) =>
                selectedCategories.includes(category)
              ).length > 0
            );
          })
        : filteredClientsAssets;
    setFilteredCategoriesAssets(filtered);
    setSelectedManufacturers([]);
    setSelectedModels([]);
    setSelectedLocations([]);
    setSelectedTags([]);
    setSelectedAssets([]);
  }, [filteredClientsAssets, selectedCategories, setSelectedAssets]);

  useEffect(() => {
    const manufacturerMap: { [key: string]: boolean } = {};
    filteredCategoriesAssets.forEach((asset) => {
      if (asset.manufacturer) manufacturerMap[asset.manufacturer] = true;
    });
    setFilteredManufacturers(Object.keys(manufacturerMap));
    const filtered =
      selectedManufacturers.length > 0
        ? filteredCategoriesAssets.filter((asset) =>
            selectedManufacturers.includes(asset.manufacturer as string)
          )
        : filteredCategoriesAssets;
    setFilteredManufacturersAssets(filtered);
    setSelectedModels([]);
    setSelectedLocations([]);
    setSelectedTags([]);
    setSelectedAssets([]);
  }, [filteredCategoriesAssets, selectedManufacturers, setSelectedAssets]);

  useEffect(() => {
    const modelMap: { [key: string]: boolean } = {};
    filteredManufacturersAssets.forEach((asset) => {
      if (asset.model) modelMap[asset.model] = true;
    });
    setFilteredModels(Object.keys(modelMap));
    const filtered =
      selectedModels.length > 0
        ? filteredManufacturersAssets.filter((asset) =>
            selectedModels.includes(asset.model as string)
          )
        : filteredManufacturersAssets;
    setFilteredModelsAssets(filtered);
    setSelectedLocations([]);
    setSelectedTags([]);
    setSelectedAssets([]);
  }, [filteredManufacturersAssets, selectedModels, setSelectedAssets]);

  useEffect(() => {
    const locationsMap: { [key: number]: LocationDto } = {};
    filteredModelsAssets.forEach((asset) => {
      if (asset.location) locationsMap[asset.location?.id] = asset.location;
    });
    setFilteredLocations(Object.values(locationsMap));
    const filtered =
      selectedLocations.length > 0
        ? filteredModelsAssets.filter((asset) =>
            Object.values(locationsMap)
              .map((location) => location.id)
              .includes(asset.location?.id as number)
          )
        : filteredModelsAssets;
    setFilteredLocationsAssets(filtered);
    setSelectedTags([]);
    setSelectedAssets([]);
  }, [filteredModelsAssets, selectedLocations, setSelectedAssets]);

  useEffect(() => {
    const tagsMap: { [key: string]: AssetTagDto } = {};
    filteredLocationsAssets.forEach((asset) =>
      asset.tags.forEach((tag) => {
        tagsMap[tag.name] = tag;
      })
    );
    setFilteredTags(Object.values(tagsMap));
    const filtered =
      selectedTags.length > 0
        ? filteredLocationsAssets.filter(
            (asset) =>
              asset.tags.filter((tag) =>
                selectedTags.map((el) => el.name).includes(tag.name)
              ).length > 0
          )
        : filteredLocationsAssets;
    setFilteredTagsAssets(filtered);
    setSelectedAssets([]);
  }, [filteredLocationsAssets, selectedTags, setSelectedAssets]);

  useEffect(() => {
    setFilteredAssets(filteredTagsAssets);
  }, [filteredTagsAssets]);

  const handleOpenConfirmationModal = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    method: Dispatch<SetStateAction<any | null>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any | null
  ) => {
    setModalIsOpen(true);
    setUpdateFilterMethod({
      method,
      value,
    });
  };

  useEffect(() => {
    setSelectedClient(filteredClients.length === 1 ? filteredClients[0] : null);
  }, [filteredClients]);

  const handleCloseConfirmationModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirmSelections = () => {
    const { method, value } = updateFilterMethod;
    method(value);
    setModalIsOpen(false);
  };

  const hasMultipleClients = useShowForRole([
    UserRole.SuperUser,
    UserRole.SuperAdmin,
  ]);

  return (
    <Card>
      <CardContent>
        <AnalyticsSelectorsConfirmationModal
          open={modalIsOpen}
          handleCloseConfirmationModal={handleCloseConfirmationModal}
          handleConfirmSelections={handleConfirmSelections}
        />
        {hasMultipleClients ? (
          <Box mb={1}>
            <Autocomplete
              id="clients"
              options={[...filteredClients].sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              getOptionLabel={(client: ClientDto) => client.name}
              renderInput={(params) => (
                <TextField {...params} label="Clients" variant="filled" />
              )}
              onChange={(
                event: React.ChangeEvent<unknown>,
                client: ClientDto | null
              ) => {
                if (selectedAssets.length > 0) {
                  handleOpenConfirmationModal(setSelectedClient, client);
                } else {
                  setSelectedClient(client);
                }
              }}
              value={selectedClient}
            />
          </Box>
        ) : null}
        <Box mb={1}>
          <Autocomplete
            className={
              selectedCategories.length > 0 ? classes.endAdornment : ""
            }
            multiple
            id="categories"
            options={[...filteredCategories].sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            getOptionLabel={(category: CategoryDto) => category.name}
            renderInput={(params) => (
              <TextField {...params} label="Category" variant="filled" />
            )}
            onChange={(
              event: React.ChangeEvent<unknown>,
              categories: CategoryDto[]
            ) => {
              if (selectedAssets.length > 0) {
                setModalIsOpen(true);
                handleOpenConfirmationModal(setSelectedCategories, categories);
              } else {
                setSelectedCategories(categories);
              }
            }}
            value={selectedCategories}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            className={
              selectedManufacturers.length > 0 ? classes.endAdornment : ""
            }
            multiple
            id="manufacturers"
            options={[...filteredManufacturers].sort()}
            renderInput={(params) => (
              <TextField {...params} label="Manufacturer" variant="filled" />
            )}
            onChange={(
              event: React.ChangeEvent<unknown>,
              manufacturers: string[]
            ) => {
              if (selectedAssets.length > 0) {
                handleOpenConfirmationModal(
                  setSelectedManufacturers,
                  manufacturers
                );
              } else {
                setSelectedManufacturers(manufacturers);
              }
            }}
            value={selectedManufacturers}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            className={selectedModels.length > 0 ? classes.endAdornment : ""}
            multiple
            id="models"
            options={[...filteredModels].sort()}
            renderInput={(params) => (
              <TextField {...params} label="Model" variant="filled" />
            )}
            onChange={(event: React.ChangeEvent<unknown>, models: string[]) => {
              if (selectedAssets.length > 0) {
                handleOpenConfirmationModal(setSelectedModels, models);
              } else {
                setSelectedModels(models);
              }
            }}
            value={selectedModels}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            className={selectedLocations.length > 0 ? classes.endAdornment : ""}
            multiple
            id="locations"
            options={[...filteredLocations].sort((a, b) =>
              a.title.localeCompare(b.title)
            )}
            getOptionLabel={(location: LocationDto) => location.title}
            renderInput={(params) => (
              <TextField {...params} label="Location" variant="filled" />
            )}
            onChange={(
              event: React.ChangeEvent<unknown>,
              locations: LocationDto[]
            ) => {
              if (selectedAssets.length > 0) {
                handleOpenConfirmationModal(setSelectedLocations, locations);
              } else {
                setSelectedLocations(locations);
              }
            }}
            value={selectedLocations}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            className={selectedTags.length > 0 ? classes.endAdornment : ""}
            multiple
            id="tags"
            options={[...filteredTags].sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            getOptionLabel={(tag: AssetTagDto) => tag.name}
            renderInput={(params) => (
              <TextField {...params} label="#tags" variant="filled" />
            )}
            onChange={(
              event: React.ChangeEvent<unknown>,
              tags: AssetTagDto[]
            ) => {
              if (selectedAssets.length > 0) {
                handleOpenConfirmationModal(setSelectedTags, tags);
              } else {
                setSelectedTags(tags);
              }
            }}
            value={selectedTags}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            className={selectedAssets.length > 0 ? classes.endAdornment : ""}
            multiple
            id="assets"
            options={[...filteredAssets].sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            getOptionLabel={(asset: AssetDto) => asset.name}
            renderInput={(params) => (
              <TextField {...params} label="Assets" variant="filled" />
            )}
            onChange={(
              event: React.ChangeEvent<unknown>,
              newValue: AssetDto[]
            ) => {
              setSelectedAssets(newValue);
            }}
            value={selectedAssets}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
