import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { DashboardLayout } from "./component/DashboardLayout/DashboardLayout";
import {
  DASHBOARD,
  DASHBOARD_PARAM,
  TEAMS,
  ASSETS,
  ASSET,
  PARTNERS,
  LOCATIONS,
  CLIENTS,
  USERS,
  PERMISSIONS,
  PROFILE,
  DEVICES,
  ANALYTICS,
  SENSOR_TYPES,
  PASSWORD,
  NOTIFICATIONS,
  DASHBOARD_CLIENTS,
  WORKORDERS,
} from "./const/routes";
import { DashboardView } from "./view/DashboardView";
import { TeamsView } from "../teams/view/TeamsView/TeamsView";
import { AssetsView } from "../asset/view/AssetsView/AssetsView";
import { PartnersView } from "../partners/view/PartnersView/PartnersView";
import { LocationsView } from "../location/view/LocationsView/LocationsView";
import { ClientsView } from "../clients/view/ClientsView/ClientsView";
import { UsersView } from "../users/view/UsersView/UsersView";
import { RolesView } from "../roles/view/RolesView/RolesView";
import { DevicesView } from "../devices/view/DevicesView/DevicesView";
import { AssetView } from "../asset/view/AssetView/AssetView";
import { AssetAnalyticsView } from "../asset/view/AssetAnalyticsView/AssetAnalyticsView";
import { SensorTypesView } from "../sensorTypes/view/SensorTypesView/SensorTypesView";
import { PasswordView } from "../auth/view/PasswordView/PasswordView";
import { ProfileView } from "../users/view/ProfileView/ProfileView";
import { UserNotificationsView } from "../users/view/UserNotificationsView/UserNotificationsView";
import { WorkOrdersView } from "../workorders/view/WorkOrdersView/WorkOrdersView";

export const App: FC = () => (
  <DashboardLayout>
    <Switch>
      <Route path={DASHBOARD} component={DashboardView} exact />
      <Route path={DASHBOARD_PARAM} component={DashboardView} exact />
      <Route path={TEAMS} component={TeamsView} exact />
      <Route path={ASSETS} component={AssetsView} exact />
      <Route path={ASSET} component={AssetView} />
      <Route path={PARTNERS} component={PartnersView} exact />
      <Route path={LOCATIONS} component={LocationsView} exact />
      <Route path={CLIENTS} component={ClientsView} exact />
      <Route path={DASHBOARD_CLIENTS} component={ClientsView} exact />
      <Route path={USERS} component={UsersView} exact />
      <Route path={PERMISSIONS} component={RolesView} exact />
      <Route path={DEVICES} component={DevicesView} exact />
      <Route path={ANALYTICS} component={AssetAnalyticsView} exact />
      <Route path={PROFILE} component={ProfileView} exact />
      <Route path={PASSWORD} component={PasswordView} exact />
      <Route path={SENSOR_TYPES} component={SensorTypesView} exact />
      <Route path={NOTIFICATIONS} component={UserNotificationsView} exact />
      <Route path={WORKORDERS} component={WorkOrdersView} exact />
    </Switch>
  </DashboardLayout>
);
