import React, { FC, useState } from "react";
import { CssBaseline } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { NavBar } from "./NavBar/NavBar";
import {
  StyledRoot,
  StyledWrapper,
  StyledContentContainer,
  StyledContent,
} from "./DashboardLayout.style";
import { ConnectedTopBar } from "./ConnectedTopBar/ConnectedTopBar";
import { selectRouterPathname } from "../../store/router/router.selector";
import { getPathnameFromTo } from "../../util/router-helpers";
import { SETTINGS } from "../../const/routes";

export const DashboardLayout: FC = ({ children }) => {
  const theme = useTheme();
  const minWidthForNavOpen = theme.breakpoints.values.md;
  const [isNavOpen, setNavOpen] = useState(
    window.innerWidth >= minWidthForNavOpen
  );
  const pathname = useSelector(selectRouterPathname);
  const isSettingsNav = getPathnameFromTo(0, 2)(pathname) === SETTINGS;

  return (
    <StyledRoot>
      <CssBaseline />
      <ConnectedTopBar
        isNavOpen={isNavOpen}
        onNavOpen={() => setNavOpen(true)}
        onNavClose={() => setNavOpen(false)}
      />
      <NavBar
        isNavOpen={isNavOpen}
        onNavClose={() => setNavOpen(false)}
        isSettingsNav={isSettingsNav}
      />
      <StyledWrapper>
        <StyledContentContainer>
          <StyledContent>{children}</StyledContent>
        </StyledContentContainer>
      </StyledWrapper>
    </StyledRoot>
  );
};
