import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  TaskDto,
  TasksList,
  TaskStatus,
  Comment,
} from "../api/workorders.api.dto";

export interface WorkOrdersState {
  tasks: AsyncStateModel<TaskDto[]>;
  tasksLists: AsyncStateModel<TasksList>;
  deleteTask: AsyncStateModel<null>;
  createComments: AsyncStateModel<Comment[]>;
  deleteComments: AsyncStateModel<Comment[]>;
  searchTasks: string;
  userIdFilter: number | null;
  selectedClientId: number | null;
}

export const workOrdersState: WorkOrdersState = {
  tasks: {
    status: AsyncStatus.Void,
    data: [],
  },
  tasksLists: {
    status: AsyncStatus.Void,
    data: {
      [TaskStatus.Open]: [],
      [TaskStatus.InProgress]: [],
      [TaskStatus.Done]: [],
    },
  },
  deleteTask: {
    status: AsyncStatus.Void,
    data: null,
  },
  createComments: { status: AsyncStatus.Void, data: [] },
  deleteComments: { status: AsyncStatus.Void, data: [] },
  searchTasks: "",
  userIdFilter: null,
  selectedClientId: null,
};
