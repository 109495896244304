export const ADD_CLIENT_MODAL = "ADD_CLIENT_MODAL";
export const EDIT_CLIENT_MODAL = "EDIT_CLIENT_MODAL";
export const ADD_LOCATION_MODAL = "ADD_LOCATION_MODAL";
export const EDIT_LOCATION_MODAL = "EDIT_LOCATION_MODAL";
export const ADD_TEAM_MODAL = "ADD_TEAM_MODAL";
export const EDIT_TEAM_MODAL = "EDIT_TEAM_MODAL";

export const ADD_PARTNER_MODAL = "ADD_PARTNER_MODAL";
export const EDIT_PARTNER_MODAL = "EDIT_PARTNER_MODAL";

export const ADD_USER_MODAL = "ADD_USER_MODAL";
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";

export const ADD_ROLE_MODAL = "ADD_ROLE_MODAL";
export const EDIT_ROLE_MODAL = "EDIT_ROLE_MODAL";

export const ADD_DEVICE_MODAL = "ADD_DEVICE_MODAL";
export const EDIT_DEVICE_MODAL = "EDIT_DEVICE_MODAL";
export const VIEW_DEVICE_MODAL = "VIEW_DEVICE_MODAL";
export const ADD_SENSOR_TYPE_MODAL = "ADD_SENSOR_TYPE_MODAL";
export const EDIT_SENSOR_TYPE_MODAL = "EDIT_SENSOR_TYPE_MODAL";

export const ADD_ASSET_MODAL = "ADD_ASSET_MODAL";
export const EDIT_ASSET_MODAL = "EDIT_ASSET_MODAL";
export const ADD_ASSET_SENSOR_MODAL = "ADD_ASSET_SENSOR_MODAL";
export const EDIT_ASSET_SENSOR_MODAL = "EDIT_ASSET_SENSOR_MODAL";
export const ADD_ASSET_NOTE_MODAL = "ADD_ASSET_NOTE_MODAL";
export const ADD_ASSET_ATTACHMENT_MODAL = "ADD_ASSET_ATTACHMENT_MODAL";

export const ADD_TASK_MODAL = "ADD_TASK_MODAL";
export const EDIT_TASK_MODAL = "EDIT_TASK_MODAL";
