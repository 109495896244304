import React, { FC } from "react";
import { Box, Link, Tooltip, Typography } from "@material-ui/core";
import { WarningRounded, InfoOutlined } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";

import {
  StyledCard,
  StyledBadHealth,
  StyledFairHealth,
  StyledGoodHealth,
  StyledCenteredCell,
  StyledTooltipWrapper,
} from "./AlertsTable.style";
import {
  CenteredCell,
  StyledNAHealth,
} from "../../../app/component/Table/Table.style";
import { ASSETS } from "../../../app/const/routes";
import { AssetHealthThresholds } from "../../../asset/enum/assets.enum";
import { AssetTodosDto } from "../../../asset/api/asset.api.dto";

interface IAlertsTable {
  alerts: AssetTodosDto[];
  pending: boolean;
}

const getTime = (time: string | null) => {
  if (!time) {
    return "";
  }
  const yesterday = moment().subtract(1, "days");
  const timeMoment = moment(time);

  return timeMoment.isBefore(yesterday)
    ? timeMoment.format("MM-DD-YYYY h:mm:ss")
    : timeMoment.fromNow();
};

export const AlertsTable: FC<IAlertsTable> = ({ alerts, pending }) => {
  const renderHealthRating = (health: number | null, healthEnabled: any) => {
    if (health === null || health === undefined || healthEnabled === 0) {
      return <StyledNAHealth>N/A</StyledNAHealth>;
    }
    if (
      (health === null && healthEnabled === 1) ||
      (health === undefined && healthEnabled === 1)
    ) {
      return "-";
    }
    if (health <= AssetHealthThresholds.bad) {
      if (health === 10 || health === 0) {
        return <StyledBadHealth>{health}</StyledBadHealth>;
      }
      return <StyledBadHealth>{Number(health).toFixed(1)}</StyledBadHealth>;
    }
    if (health <= AssetHealthThresholds.fair) {
      if (health === 10 || health === 0) {
        return <StyledFairHealth>{health}</StyledFairHealth>;
      }
      return <StyledFairHealth>{Number(health).toFixed(1)}</StyledFairHealth>;
    }

    if (health === 10 || health === 0) {
      return <StyledGoodHealth>{health}</StyledGoodHealth>;
    }
    return <StyledGoodHealth>{Number(health).toFixed(1)}</StyledGoodHealth>;
  };

  const columns: Column[] = [
    {
      id: "asset",
      Header: "Asset",
      accessor: "asset",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: AssetTodosDto } }) => (
        <Link component={RouterLink} to={`${ASSETS}/${original.equipment?.id}`}>
          {original.equipment?.name}
        </Link>
      ),
    },
    {
      id: "sensor_name",
      Header: "Sensor Name",
      accessor: "sensor_name",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: AssetTodosDto } }) =>
        original.sensorName !== "" ? original.sensorName : original.sensorType,
    },
    {
      id: "alarm",
      Header: "Alarm",
      accessor: "alarm",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: AssetTodosDto } }) =>
        original.equipment?.cached_is_alarmed ? (
          <CenteredCell>
            <WarningRounded color="error" />
          </CenteredCell>
        ) : (
          ""
        ),
    },
    {
      id: "sensor_health",
      Header: "Sensor Health",
      accessor: "sensor_health",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: AssetTodosDto } }) => (
        <CenteredCell>
          {renderHealthRating(
            original.health,
            original.equipment?.health_is_enabled
          )}
        </CenteredCell>
      ),
    },
    {
      id: "asset_state",
      Header: "Asset State",
      accessor: "cached_active_status",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: AssetTodosDto } }) =>
        original.equipment?.cached_active_status === "Off" ? (
          <StyledCenteredCell>
            {original.equipment?.cached_active_status}
          </StyledCenteredCell>
        ) : (
          <CenteredCell>
            {original.equipment?.cached_active_status}
          </CenteredCell>
        ),
    },
    {
      id: "time",
      Header: "Time",
      accessor: "time",
      Cell: ({ row: { original } }: { row: { original: AssetTodosDto } }) =>
        getTime(original.lastReadingTime),
    },
  ];

  return (
    <StyledCard>
      <Box
        mt={2}
        ml={2}
        mr={2}
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Typography>
          Low Health and Alarms
          <StyledTooltipWrapper>
            <Tooltip
              title="Use this table to view all assets in your organization that are currently in a state of low health and/or alarm and quickly access more information for each asset."
              placement="top-end"
            >
              <InfoOutlined className="ms-2" />
            </Tooltip>
          </StyledTooltipWrapper>
        </Typography>
      </Box>
      <Table
        hideNoData
        loading={pending}
        columns={columns}
        data={alerts}
        formView
        hover
        sorting={{ id: "time", desc: true }}
      />
    </StyledCard>
  );
};
