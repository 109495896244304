import React, { FC } from "react";
import { useFormik } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, Grid, TextField } from "@material-ui/core";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import {
  DESCRIPTION_CHAR_LIMIT,
  locationModalFormValidation,
} from "./LocationModalForm.validation";
import { ConnectedShowForRole } from "../../../app/component/UserManagement/ConnectedShowForRole";
import { LocationDto } from "../../api/locations.api.dto";
import { UserRole } from "../../../app/enum/UserRole";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { CreateEditLocationModel } from "../../model/locations.model";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { useClientId } from "../../../app/hooks/useClientId";

export interface ILocationModalForm {
  location?: LocationDto;
  clients: ClientDto[];
  displayActionButtons: () => void;
  onSubmit: (location: CreateEditLocationModel, id?: number) => void;
  isPending: boolean;
  editView: boolean;
  clientId?: number | null;
}

export const LocationModalContent: FC<ILocationModalForm> = ({
  location,
  clients,
  displayActionButtons,
  onSubmit,
  isPending,
  editView,
  clientId,
}) => {
  const adminClientId = useClientId();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      client_id: location?.client_id || clientId || 0,
      title: location?.title || "",
      description: location?.description || "",
    },
    onSubmit: (values: CreateEditLocationModel) => {
      onSubmit(values, location?.id);
    },
    validationSchema: locationModalFormValidation,
  });

  const getClientIdOptions = () =>
    [...clients]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client) => client.id) as number[];

  const getClientIdOptionLabel = (option: number) => {
    const match = clients.find((client) => client.id === option);
    return match?.name || "";
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <ConnectedShowForRole
            roles={[UserRole.SuperUser, UserRole.SuperAdmin]}
          >
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth required>
                <Autocomplete
                  options={getClientIdOptions()}
                  id="client_id"
                  getOptionLabel={getClientIdOptionLabel}
                  value={formik.values.client_id}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: number | null
                  ) => {
                    formik.setFieldValue("client_id", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="client_id"
                      label="Client"
                      error={
                        formik.touched.client_id &&
                        Boolean(formik.errors.client_id)
                      }
                      helperText={
                        formik.touched.client_id && formik.errors.client_id
                      }
                      variant="filled"
                      fullWidth
                      required
                    />
                  )}
                  disabled={isPending || (!editView && location !== undefined)}
                />
              </FormControl>
            </Grid>
          </ConnectedShowForRole>
          <Grid item xs={12}>
            <TextField
              id="title"
              name="title"
              label="Location Name"
              variant="filled"
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              required
              disabled={isPending || (!editView && location !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Location Description"
              multiline
              rows={3}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{
                maxLength: DESCRIPTION_CHAR_LIMIT,
              }}
              disabled={isPending || (!editView && location !== undefined)}
            />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
