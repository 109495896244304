import { Box, Chip, Button, Link, CircularProgress } from "@material-ui/core";
import React, { FC } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Link as RouterLink } from "react-router-dom";
import { TaskDto } from "../../../workorders/api/workorders.api.dto";
import {
  StyledCardTitle,
  StyledCard,
  StyledCardActions,
  StyledTaskCard,
} from "./AssetRealtimeWorkOrders.style";
import { TaskCardContent } from "../../../workorders/component/TaskCardContent/TaskCardContent";
import { WORKORDERS } from "../../../app/const/routes";

export interface IAssetRealtimeWorkOrdersProps {
  tasks: TaskDto[];
  pending?: boolean;
  handleOpenAddWorkorderModal: () => void;
}

export const AssetRealtimeWorkOrders: FC<IAssetRealtimeWorkOrdersProps> = ({
  tasks,
  pending,
  handleOpenAddWorkorderModal,
}) => {
  return (
    <Box height="100%">
      <StyledCard>
        <Box maxHeight={640} overflow="auto">
          <Box p={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <StyledCardTitle>Work Orders</StyledCardTitle>
              {!pending && (
                <Box ml={1}>
                  <Chip label={tasks.length} size="small" />
                </Box>
              )}
            </Box>
          </Box>
          {!pending &&
            tasks.map((task) => (
              <StyledTaskCard>
                <TaskCardContent task={task} />
              </StyledTaskCard>
            ))}
        </Box>
        {pending ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={50} />
          </Box>
        ) : (
          <StyledCardActions>
            <Box display="flex" justifyContent="space-between">
              <Link component={RouterLink} to={WORKORDERS}>
                <Button color="primary">View All</Button>
              </Link>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenAddWorkorderModal();
                }}
              >
                New Work Order
              </Button>
            </Box>
          </StyledCardActions>
        )}
      </StyledCard>
    </Box>
  );
};
