import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import {
  clearSearchAssetsState as clearSearchAssetsStateAction,
  searchAssets as searchAssetsAction,
} from "../store/asset.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";
import { selectIsAssetsTableStatusPending } from "../store/asset.selector";

export const AssetsSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAssetsPending = useSelector(selectIsAssetsTableStatusPending);

  useEffect(() => {
    return () => {
      dispatch(clearSearchAssetsStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchAssetsAction(value));

  return (
    <TableSearchInput
      label="Search Assets"
      onSearch={debounce(onSearch, 200)}
      isPending={isAssetsPending}
    />
  );
};
