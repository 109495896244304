import styled from "styled-components";
import { List } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { SETTINGS_LIST_ITEM_COLOR } from "../../../theme/colors";

export const StyledList = styled(List)`
  padding-top: 0;
  padding: 8px;
`;

export const StyledWhiteChevron = styled(ChevronLeft)`
  color: white;
`;

export const StyledBarTitle = styled.div`
  font-size: 32px;
  color: ${SETTINGS_LIST_ITEM_COLOR};
  font-weight: 600;
`;

export const StyledBarSectionHeader = styled.div`
  font-size: 20px;
  padding-left: 1rem;
  color: ${SETTINGS_LIST_ITEM_COLOR};
  font-weight: 600;
`;
