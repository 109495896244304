import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import { LocationsSearch } from "../component/LocationsSearch/LocationsSearch";
import {
  clearSearchLocationsState,
  searchLocations,
} from "../store/locations.slice";

export const LocationsSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    return () => {
      dispatch(clearSearchLocationsState());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchLocations(value));

  return (
    <LocationsSearch onSearch={debounce(onSearch, 200)} isPending={false} />
  );
};
