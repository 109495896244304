import React from "react";
import styled, { css } from "styled-components";
import {
  Checkbox,
  IconButton,
  TextField,
  Avatar,
  TextFieldProps,
} from "@material-ui/core";
import { TEXT_PRIMARY } from "../../../app/theme/colors";

export const TaskContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  border: 1px solid ${({ theme }) => theme.palette.border.light};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.text.disabled};
  margin-top: ${({ theme }) => `-${theme.spacing(1)}px`};
  margin-left: ${({ theme }) => `-${theme.spacing(1)}px`};
`;

export const StyledIconButton = styled(IconButton)<{
  corner?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  fadeOnHover?: boolean;
}>`
  ${({ theme, corner }) => {
    switch (corner) {
      case "top-right":
        return css`
          margin-top: -${theme.spacing(0.8)}px;
          margin-right: -${theme.spacing(0.8)}px;
        `;
      case "bottom-left":
        return css`
          margin-bottom: -${theme.spacing(0.8)}px;
          margin-left: -${theme.spacing(0.8)}px;
        `;
      case "bottom-right":
        return css`
          margin-bottom: -${theme.spacing(0.8)}px;
          margin-right: -${theme.spacing(0.8)}px;
        `;
      default:
        return css`
          margin-top: -${theme.spacing(0.8)}px;
          margin-left: -${theme.spacing(0.8)}px;
        `;
    }
  }};

  ${({ theme, fadeOnHover }) =>
    fadeOnHover &&
    css`
      .MuiAvatar-circle:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0;
        transition: ${theme.transitions.create("opacity", {
          duration: theme.transitions.duration.shortest,
        })};
      }

      &:hover,
      &.faded {
        background-color: transparent; // Disable ripple effect

        .MuiAvatar-circle:after {
          opacity: 1;
        }
      }
    `};
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};

  .MuiOutlinedInput-input {
    height: 4px;
    padding: 16px 14px 20px 14px;
  }
`;

export const NakedTextField = styled(
  (
    props: Omit<TextFieldProps, "size"> & {
      size?: TextFieldProps["size"] | "tiny";
      bolder?: boolean;
    }
  ) => (
    <TextField
      {...props}
      size={props.size === "tiny" ? "small" : props.size}
      variant="outlined"
      hiddenLabel
    />
  )
)`
  .MuiOutlinedInput-input {
    font-size: ${({ theme, size }) =>
      size === "tiny"
        ? `${theme.typography.bodyTiny.fontSize}`
        : `${theme.typography.h5.fontSize}`};
    font-weight: ${({ theme, bolder }) =>
      bolder ? `${theme.typography.h5.fontWeight}` : "inherit"};
  }

  .MuiOutlinedInput-multiline {
    padding: 0px;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding: 5px 7px;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0px;
    border-width: 1px !important;
    border-color: transparent;
  }

  .MuiOutlinedInput-root:not(.Mui-focused):hover
    .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.border.light};
  }
`;

export const StyledAvatar = styled(Avatar)<{
  size?: "normal" | "small";
  noMargin?: boolean;
}>`
  ${({ size }) =>
    size === "small"
      ? css`
          width: 25px;
          height: 25px;
          font-size: 12px;
        `
      : css`
          width: 28px;
          height: 28px;
          font-size: 14px;
        `}

  ${({ theme, noMargin }) =>
    !noMargin &&
    css`
      margin-right: ${theme.spacing(1)}px;
    `}

  color: ${TEXT_PRIMARY};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;

  .MuiSvgIcon-root {
    color: white;
  }
`;
