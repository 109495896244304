import React, { FC } from "react";
import { Typography, IconButton } from "@material-ui/core";

import { DeleteOutline } from "@material-ui/icons";
import moment from "moment";
import { ReactComponent as Pin } from "../../../../assets/pin.svg";
import { ReactComponent as PinActive } from "../../../../assets/pin-active.svg";
import {
  Wrapper,
  Note,
  ActionButtons,
  StyledTypography,
  Circle,
} from "./AssetNote.style";
import { AssetNoteModel } from "../../model/asset.model";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";

interface IAssetNote {
  note: AssetNoteModel;
  onDelete: (noteId: number) => void;
  onPin: (noteId: number, isPinned: boolean) => void;
}

export const AssetNote: FC<IAssetNote> = ({ note, onDelete, onPin }) => {
  const isMember = useShowForRole([UserRole.Member]);
  return (
    <Wrapper>
      <Note>
        <Typography variant="body2" color="textPrimary">
          {note.text}
        </Typography>
        <StyledTypography>
          {note.creator && (
            <>
              Created by: {note.creator.full_name} <Circle />
            </>
          )}
          {moment(note.created_at).format("MM-DD-YYYY h:mm:ss A")}
        </StyledTypography>
      </Note>
      <ActionButtons>
        {note.pinned ? (
          <IconButton onClick={() => onPin(note.id, false)}>
            <PinActive />
          </IconButton>
        ) : (
          <IconButton onClick={() => onPin(note.id, true)}>
            <Pin />
          </IconButton>
        )}
        {!isMember ? (
          <IconButton onClick={() => onDelete(note.id)}>
            <DeleteOutline />
          </IconButton>
        ) : null}
      </ActionButtons>
    </Wrapper>
  );
};
