import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { MaintenanceDto } from "../../../maintenance/api/maintenance.api.dto";
import { AssetRealtimeCards } from "./AssetRealtimeSensors/AssetRealtimeSensors";
import { AssetDto, AssetRuntimeDto, SensorDto } from "../../api/asset.api.dto";
import { AssetRealtimeWorkOrders } from "./AssetRealtimeWorkOrders";
import { AssetRuntime } from "./AssetRuntime";
import { AssetRuntimeModal } from "./AssetRuntimeModal";
import { TaskDto } from "../../../workorders/api/workorders.api.dto";

export interface IAssetRealtimeProps {
  pending: boolean;
  asset: AssetDto | null;
  maintenances: MaintenanceDto[];
  sensors: SensorDto[];
  runtime: AssetRuntimeDto[];
  handleRuntimeReset: (event: React.MouseEvent<HTMLElement>) => void;
  handleOpenResetModal: () => void;
  handleCloseResetModal: () => void;
  handleOpenAddWorkorderModal: () => void;
  openResetModal: boolean;
  tasks: TaskDto[];
}

export const AssetRealtime: FC<IAssetRealtimeProps> = ({
  pending,
  asset,
  sensors,
  runtime,
  handleRuntimeReset,
  handleOpenResetModal,
  handleCloseResetModal,
  handleOpenAddWorkorderModal,
  openResetModal,
  tasks,
}) => {
  return (
    <>
      <Grid item xs={12} md={8} xl={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AssetRealtimeCards sensors={sensors} asset={asset} />
          </Grid>
          <Grid item xs={12}>
            <AssetRuntimeModal
              open={openResetModal}
              handleCloseResetModal={handleCloseResetModal}
              handleRuntimeReset={handleRuntimeReset}
            />
            <AssetRuntime
              runtime={runtime[0]}
              handleOpenResetModal={handleOpenResetModal}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <AssetRealtimeWorkOrders
          tasks={tasks}
          pending={pending}
          handleOpenAddWorkorderModal={handleOpenAddWorkorderModal}
        />
      </Grid>
    </>
  );
};
