import { CreateEditSensorModel } from "../model/asset.model";
import { CreateEditSensorDto } from "./asset.api.dto";

export const mapCreateEditSensorModelToDto = (
  sensorModel: CreateEditSensorModel
): CreateEditSensorDto => ({
  ...sensorModel,
  is_enabled: sensorModel.is_enabled ? 1 : 0,
  warning_is_enabled: sensorModel.warning_is_enabled ? 1 : 0,
  status_is_enabled: sensorModel.status_is_enabled ? 1 : 0,
});
