import * as yup from "yup";

export const ID_CHAR_LIMIT = 32;
export const SN_CHAR_LIMIT = 8;
export const DESCRIPTION_CHAR_LIMIT = 500;

export const deviceModalFormValidation = yup.object().shape({
  name: yup.string().required("Device name is required"),
  type: yup.number().required("Device type is required"),
  id: yup
    .string()
    .min(ID_CHAR_LIMIT)
    .max(ID_CHAR_LIMIT)
    .required("Device ID is required"),
  serialNumber: yup
    .string()
    .max(SN_CHAR_LIMIT)
    .required("Device S/N is required"),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  measurementPlanMinutes: yup
    .number()
    .min(0, "The value cannot be less than 0 ")
    .optional(),
  measurementPlanSeconds: yup
    .number()
    .min(0, "The value cannot be less than 0 ")
    .max(59, "The value cannot be higher than 59 ")
    .optional(),
  measurementScheduleMinutes: yup
    .number()
    .min(0, "The value cannot be less than 0 ")
    .optional(),
  measurementScheduleSeconds: yup
    .number()
    .min(0, "The value cannot be less than 0 ")
    .max(59, "The value cannot be higher than 59 ")
    .optional(),
  isEnabled: yup.boolean().optional(),
});
