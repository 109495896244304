export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_SUCCESS = "/forgot-password/success";
export const RESET_PASSWORD = "/reset-password";
export const RESET_PASSWORD_SUCCESS = "/reset-password/success";
export const RESET_PASSWORD_ERROR = "/reset-password/error";
export const VERIFIED = "/verified";
export const UNVERIFIED = "/unverified";
export const PRIVACYPOLICY = "/privacy-policy";

export const PANEL = "/panel";
export const DASHBOARD = `${PANEL}/dashboard`;
export const DASHBOARD_PARAM = `${PANEL}/dashboard/:clientId?`;
export const ASSETS = `${PANEL}/assets`;
export const ASSET = `${ASSETS}/:assetId`;
export const ASSET_REALTIME = `${ASSET}/realtime`;
export const ANALYTICS = `${PANEL}/analytics`;
export const ASSET_TRENDS = `${ASSET}/trends`;
export const ASSET_SENSORS = `${ASSET}/sensors`;
export const ASSET_HISTORY = `${ASSET}/history`;
export const ASSET_NOTES = `${ASSET}/notes`;
export const SETTINGS = `${PANEL}/settings`;
export const PROFILE = `${SETTINGS}/profile`;
export const PASSWORD = `${SETTINGS}/password`;
export const TEAMS = `${SETTINGS}/teams`;
export const PARTNERS = `${SETTINGS}/partners`;
export const LOCATIONS = `${SETTINGS}/locations`;
export const USERS = `${SETTINGS}/users`;
export const PERMISSIONS = `${SETTINGS}/permissions`;
export const SENSOR_TYPES = `${SETTINGS}/sensors`;
export const CLIENTS = `${SETTINGS}/clients`;
export const NOTIFICATIONS = `${SETTINGS}/notifications`;
export const DEVICES = `${SETTINGS}/devices`;
export const DASHBOARD_CLIENTS = `${PANEL}/clients/dashboard`;
export const WORKORDERS = `${PANEL}/workorders`;

export const TAKO_CONTACT = `https://www.tako.com/tako-app-contact-us`;
