import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";

import { StyledPage } from "../../../app/component/DashboardLayout/DashboardLayout.style";
import { ResetPasswordFormContainer } from "../../container/ResetPasswordFormContainer/ResetPasswordFormContainer";

export const ResetPasswordView: FC = () => {
  return (
    <StyledPage title="Reset Password">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <ResetPasswordFormContainer />
        </Container>
      </Box>
    </StyledPage>
  );
};
