import { createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import withError from "../../app/util/with-thunk-error";
import {
  login as loginRequest,
  register as registerRequest,
  recoverPassword as recoverPasswordRequest,
  fetchProfile as fetchProfileRequest,
  resetPassword as resetPasswordRequest,
  changePassword as changePasswordRequest,
  forgotPassword as forgotPasswordRequest,
} from "../api/auth.api";
import {
  RecoverPasswordDataModel,
  LoginCredentialsModel,
  RegisterDataModel,
  ProfileModel,
} from "../model/Auth";
import {
  DASHBOARD,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  RESET_PASSWORD_SUCCESS,
} from "../../app/const/routes";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";

export const login = createAsyncThunk(
  "auth/login",
  withError(
    async (
      credentials: LoginCredentialsModel,
      { dispatch }
    ): Promise<string> => {
      const { token } = await loginRequest(credentials);
      localStorage.setItem("token", token);
      return dispatch(push(DASHBOARD));
    }
  )
);

export const register = createAsyncThunk(
  "auth/register",
  withError(
    async (data: RegisterDataModel, { dispatch }): Promise<void> => {
      const response = await registerRequest(data);
      dispatch(push(LOGIN));
      return response;
    }
  )
);

export const recoverPassword = createAsyncThunk(
  "auth/recoverPassword",
  withError(
    async (
      recoverPasswordData: RecoverPasswordDataModel,
      { dispatch }
    ): Promise<void> => {
      await recoverPasswordRequest(recoverPasswordData);
      return dispatch(push(FORGOT_PASSWORD_SUCCESS));
    }
  )
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  withError(
    // eslint-disable-next-line
    async (resetPasswordData: any, { dispatch }): Promise<void> => {
      await resetPasswordRequest(resetPasswordData);
      return dispatch(push(RESET_PASSWORD_SUCCESS));
    }
  )
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  withError(
    // eslint-disable-next-line
    async (data: any, { dispatch }): Promise<void> => {
      await changePasswordRequest(data);
      dispatch(openSuccessNotification("Password has been changed"));
    }
  )
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  withError(
    // eslint-disable-next-line
    async (payload: undefined, { dispatch, getState }): Promise<void> => {
      const email = getState().auth.profile.data?.email;

      if (email) {
        await forgotPasswordRequest(email);
        dispatch(openSuccessNotification("Reset password email has been sent"));
      }
    }
  )
);

export const fetchProfile = createAsyncThunk(
  "auth/fetchProfile",
  withError(
    async (): Promise<ProfileModel> => {
      return fetchProfileRequest();
    }
  )
);

export const authorize = createAsyncThunk(
  "auth/authorize",
  withError(
    async (
      payload: undefined,
      { dispatch, rejectWithValue }
    ): Promise<ProfileModel> => {
      try {
        return await fetchProfileRequest();
      } catch (e) {
        dispatch(push(LOGIN));
        return rejectWithValue(e.response.data);
      }
    }
  )
);

export const logout = createAsyncThunk(
  "auth/logout",
  withError(
    async (payload: undefined, { dispatch }): Promise<boolean> => {
      localStorage.clear();
      return true;
    }
  )
);
