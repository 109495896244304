import { Card, CardContent, Typography } from "@material-ui/core";

import styled from "styled-components";

export const StyledGaugeCard = styled(Card)``;

export const StyledHealthDisabledCard = styled(Card)`
  display: flex;
  min-height: 250px;
  height: 100%;
`;

export const StyledCardContent = styled(CardContent)`
  && {
    padding-top: 0;
    padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const StyledCardFooter = styled(CardContent)`
  border-top: 0.5px solid #e3e3e9;
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledHealthDisabledCardContent = styled(CardContent)`
  margin: auto;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLabel = styled(Typography)`
  font-weight: 700;
  span {
    font-weight: 400;
  }
`;

export const StyledTooltipWrapper = styled.span`
  vertical-align: bottom;
`;
