import React, { FC, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";
import {
  StyledEditIcon,
  StyledDialogTitleContainer,
  StyledCloseIcon,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { AssetDto, AssetTagDto } from "../../api/asset.api.dto";
import { LocationDto } from "../../../location/api/locations.api.dto";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { CategoryDto } from "../../../category/api/category.api.dto";
import { AssetModalForm } from "../AssetModalForm/AssetModalForm";
import { CreateEditAssetModel } from "../../model/asset.model";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { DeviceListDto } from "../../../devices/api/devices.api.dto";

export interface IAssetModal {
  name: string;
  categories: CategoryDto[];
  tags: AssetTagDto[];
  locations: LocationDto[];
  teams: TeamDto[];
  clients: ClientDto[];
  devices: DeviceListDto[];
  onSubmit: (asset: CreateEditAssetModel, id?: number) => void;
  onClose: () => void;
  asset?: AssetDto;
  assets?: AssetDto[];
  onDelete?: (id: number) => void;
  isPending: boolean;
}

export const AssetModal: FC<IAssetModal> = ({
  name,
  categories,
  tags,
  locations,
  teams,
  clients,
  devices,
  onSubmit,
  onClose,
  onDelete,
  asset,
  assets,
  isPending,
}) => {
  const [editView, setEditView] = useState(false);

  const handleOnDelete = () => {
    if (onDelete && asset) {
      onDelete(asset.id);
    }
  };

  const getModalHeader = () => {
    if (asset && editView) {
      return <Typography variant="h2">Edit Asset</Typography>;
    }
    if (asset && !editView) {
      return <Typography variant="h2">View Asset</Typography>;
    }
    return <Typography variant="h2">Add Asset</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid container item xs={4} justify="flex-start">
        {editView && (
          <ConnectedShowForPermissions
            feature="assets"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView && (
          <Box display="inline" mr={1}>
            <Button
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
              disabled={pending}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box display="inline">
          {editView ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
            >
              Close
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        {!editView && asset && (
          <ConnectedShowForPermissions
            feature="assets"
            permissions={{ allOf: ["update"] }}
          >
            <StyledIconButton
              aria-label="edit"
              onClick={() => setEditView(true)}
            >
              <StyledEditIcon />
            </StyledIconButton>
          </ConnectedShowForPermissions>
        )}
        <StyledIconButton
          aria-label="close"
          onClick={() => {
            onClose();
            setEditView(false);
          }}
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button onClick={onClose} disabled={pending} color="primary">
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add Asset
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (asset) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalContent = (pending: boolean) => {
    return (
      <AssetModalForm
        onSubmit={onSubmit}
        locations={locations}
        teams={teams}
        tags={tags}
        categories={categories}
        clients={clients}
        devices={devices}
        asset={asset}
        assets={assets}
        isPending={pending}
        displayActionButtons={() => displayActionButtons(pending)}
        editView={editView}
      />
    );
  };

  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
    />
  );
};
