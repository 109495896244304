import { createMuiTheme } from "@material-ui/core";
import theme from "../../theme";
import { INPUT_BACKGROUND, TEXT_SECONDARY } from "../../theme/colors";
import forms from "../../theme/forms";

export const datePickerTheme = createMuiTheme({
  ...theme,
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiFilledInput: {
      root: {
        color: TEXT_SECONDARY,
        backgroundColor: INPUT_BACKGROUND,
        fontSize: "14px",
        "&:hover": {
          backgroundColor: INPUT_BACKGROUND,
        },
        "&$disabled": {
          backgroundColor: INPUT_BACKGROUND,
        },
      },
      input: {
        paddingTop: forms.input.paddingTop,
        paddingRight: forms.input.paddingRight,
        paddingBottom: forms.input.paddingBottom,
        paddingLeft: forms.input.paddingLeft,
        color: TEXT_SECONDARY,
        fontSize: "14px",
      },
    },
  },
});
