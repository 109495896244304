import React, { FC, useState } from "react";
import { useFormik } from "formik";
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { userModalFormValidation } from "./userModalForm.validation";
import {
  StyledModalContent,
  StyledSwitch,
} from "../../../app/component/Modal/Modal.style";
import { UserModel, CreateEditUserModel } from "../../model/users.model";
import { PartnerDto } from "../../../partners/api/partners.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { RoleDto } from "../../../roles/api/roles.api.dto";
import { UserRole } from "../../../app/enum/UserRole";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { useShowForPermissions } from "../../../app/hooks/useShowForPermissions";

export interface IUserModalForm {
  user?: UserModel;
  partners: PartnerDto[];
  clients: ClientDto[];
  teams: TeamDto[];
  roles: RoleDto[];
  onSubmit: (values: CreateEditUserModel, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  editView: boolean;
  clientId?: number | null;
}

export const UserModalForm: FC<IUserModalForm> = ({
  user,
  partners,
  clients,
  teams,
  roles,
  onSubmit,
  displayActionButtons,
  isPending,
  editView,
  clientId,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      endAdornment: {
        "& .MuiAutocomplete-endAdornment": {
          top: "30px",
        },
      },
    })
  );
  const classes = useStyles();

  const [selectedClientId, setSelectedClientId] = useState(
    user ? user.client_id : clientId
  );
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(
    user?.sms_subscribed !== 0 || user?.email_subscribed !== 0
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      display_name: user?.display_name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      job_title: user?.job_title || "",
      partner_id: user?.partner_id || null,
      client_id: user?.client_id || clientId || null,
      team_ids: user?.teams ? user.teams.map((team) => team.id) : [],
      role: user?.role_id || null,
      sms_subscribed: user?.sms_subscribed !== 0,
      email_subscribed: user?.email_subscribed !== 0,
    },
    validationSchema: userModalFormValidation,
    onSubmit: (values: CreateEditUserModel) => onSubmit(values, user?.id),
  });

  const getPartnerIdOptions = () => {
    return [...partners]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((partner) => partner.id) as number[];
  };
  const getPartnerIdOptionLabel = (option: number) => {
    const match = partners.find((partner) => partner.id === option);
    return match?.name || "";
  };

  const getClientIdOptions = () => {
    if (!formik.values.partner_id) {
      return [...clients]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((client) => client.id) as number[];
    }
    return clients
      .filter(
        (client) => client.partner_id === formik.values.partner_id?.toString()
      )
      .map((client) => client.id);
  };
  const getClientIdOptionLabel = (option: number) => {
    const match = clients.find((client) => client.id === option);
    return match?.name || "";
  };

  const canCreateRole = useShowForPermissions("roles", {
    allOf: ["create", "update"],
  });

  const getRoleOptions = () => {
    if (canCreateRole) {
      return [...roles]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((role) => role.id) as number[];
    }
    return roles
      .filter((role) => role.name === "Admin" || role.name === "Member")
      .map((role) => role.id);
  };
  const getRoleOptionLabel = (option: number) => {
    if (!roles.find((role) => role.id === option) && user?.role_name) {
      return user.role_name;
    }

    if (canCreateRole) {
      const match = roles.find((role) => role.id === option);
      return match ? match.name : "";
    }

    const match = roles.find(
      (role) =>
        (role.id === option && role.name === "Admin") ||
        (role.id === option && role.name === "Member")
    );

    return match ? match.name : "";
  };

  const getTeamOptions = () => {
    return [...teams]
      .filter((team) => team.client_id === selectedClientId)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((team) => team.id);
  };

  const getTeamOptionLabel = (option: number) => {
    const match = teams.find((team) => team.id === option);
    return match ? match.name : "";
  };

  const handlePhoneChange = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      formik.setFieldValue("phone", onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      formik.setFieldValue("phone", number);
    }
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              id="first_name"
              name="first_name"
              label="First Name"
              variant="filled"
              fullWidth
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              required
              disabled={isPending || (!editView && user !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="last_name"
              name="last_name"
              label="Last Name"
              variant="filled"
              fullWidth
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              disabled={isPending || (!editView && user !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              variant="filled"
              fullWidth
              value={formik.values.phone}
              onChange={handlePhoneChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              disabled={isPending || (!editView && user !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="filled"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
              disabled={isPending || (!editView && user !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="job_title"
              name="job_title"
              label="Job Title"
              variant="filled"
              fullWidth
              value={formik.values.job_title}
              onChange={formik.handleChange}
              error={
                formik.touched.job_title && Boolean(formik.errors.job_title)
              }
              helperText={formik.touched.job_title && formik.errors.job_title}
              disabled={isPending || (!editView && user !== undefined)}
            />
          </Grid>
          {useShowForRole([UserRole.SuperUser]) ? (
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth disabled={isPending}>
                <Autocomplete
                  options={getPartnerIdOptions()}
                  id="partner_id"
                  getOptionLabel={getPartnerIdOptionLabel}
                  disabled={isPending || (!editView && user !== undefined)}
                  value={formik.values.partner_id}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: number | null
                  ) => {
                    formik.setFieldValue("partner_id", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="partner_id"
                      label="Partner"
                      error={
                        formik.touched.partner_id &&
                        Boolean(formik.errors.partner_id)
                      }
                      helperText={
                        formik.touched.partner_id && formik.errors.partner_id
                      }
                      variant="filled"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : null}
          {useShowForRole([UserRole.SuperUser, UserRole.SuperAdmin]) ? (
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth disabled={isPending}>
                <Autocomplete
                  options={getClientIdOptions()}
                  id="client_id"
                  getOptionLabel={getClientIdOptionLabel}
                  disabled={isPending || (!editView && user !== undefined)}
                  value={formik.values.client_id}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: number | null
                  ) => {
                    formik.setFieldValue("client_id", newValue);
                    formik.setFieldValue("team_ids", []);
                    setSelectedClientId(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="client_id"
                      label="Client"
                      error={
                        formik.touched.client_id &&
                        Boolean(formik.errors.client_id)
                      }
                      helperText={
                        formik.touched.client_id && formik.errors.client_id
                      }
                      variant="filled"
                      fullWidth
                      required
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth disabled={isPending}>
              <Autocomplete
                className={
                  formik.values.team_ids.length > 0 ? classes.endAdornment : ""
                }
                multiple
                options={getTeamOptions()}
                id="team_ids"
                getOptionLabel={getTeamOptionLabel}
                disabled={isPending || (!editView && user !== undefined)}
                value={formik.values.team_ids}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: number[]
                ) => {
                  formik.setFieldValue("team_ids", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="team_ids"
                    label="Teams"
                    error={
                      formik.touched.team_ids && Boolean(formik.errors.team_ids)
                    }
                    helperText={
                      (formik.touched.team_ids && formik.errors.team_ids) ||
                      "Leave blank to assign the user to all teams"
                    }
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={isPending}
            >
              <Autocomplete
                options={getRoleOptions()}
                id="role"
                getOptionLabel={getRoleOptionLabel}
                disabled={isPending || (!editView && user !== undefined)}
                value={formik.values.role}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: number | null
                ) => {
                  formik.setFieldValue("role", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="role"
                    label="Role"
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                    variant="filled"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={9} sm={4}>
            <Typography variant="h4">Notifications</Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            <StyledSwitch
              checked={isNotificationsEnabled}
              onChange={(event, checked) => {
                formik.setFieldValue("sms_subscribed", false);
                formik.setFieldValue("email_subscribed", false);
                setIsNotificationsEnabled(checked);
              }}
              disabled={isPending || (!editView && user !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="sms_subscribed"
                  checked={
                    isNotificationsEnabled && formik.values.sms_subscribed
                  }
                  onChange={formik.handleChange}
                  color="default"
                />
              }
              label="SMS"
              className="bodySmall"
              disabled={
                !isNotificationsEnabled ||
                isPending ||
                (!editView && user !== undefined)
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="email_subscribed"
                  checked={
                    isNotificationsEnabled && formik.values.email_subscribed
                  }
                  onChange={formik.handleChange}
                  color="default"
                />
              }
              label="Email"
              className="bodySmall"
              disabled={
                !isNotificationsEnabled ||
                isPending ||
                (!editView && user !== undefined)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
