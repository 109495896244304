import { createSlice } from "@reduxjs/toolkit";
import { PartnersState, partnersState } from "./partners.store";
import {
  fetchPartners,
  deletePartner,
  editPartner,
  createPartner,
} from "./partners.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "partners",
  initialState: partnersState,
  reducers: {
    searchPartners: (
      state: PartnersState,
      { payload }: { payload: string }
    ) => ({
      ...state,
      searchPartners: payload,
    }),
    clearFetchPartnersState: (state: PartnersState) => ({
      ...state,
      fetchPartners: partnersState.fetchPartners,
    }),
    clearSearchPartnersState: (state: PartnersState) => ({
      ...state,
      searchPartners: partnersState.searchPartners,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartners.pending, (state) => ({
      ...state,
      fetchPartners: {
        ...state.fetchPartners,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchPartners.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchPartners: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchPartners.rejected, (state) => ({
      ...state,
      fetchPartners: {
        ...state.fetchPartners,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deletePartner.pending, (state) => ({
      ...state,
      deletePartner: {
        ...state.deletePartner,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deletePartner.fulfilled, (state) => ({
      ...state,
      deletePartner: {
        ...state.deletePartner,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deletePartner.rejected, (state) => ({
      ...state,
      deletePartner: {
        ...state.deletePartner,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editPartner.pending, (state) => ({
      ...state,
      editPartner: {
        ...state.editPartner,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editPartner.fulfilled, (state) => ({
      ...state,
      editPartner: {
        ...state.editPartner,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editPartner.rejected, (state) => ({
      ...state,
      editPartner: {
        ...state.editPartner,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createPartner.pending, (state) => ({
      ...state,
      createPartner: {
        ...state.createPartner,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createPartner.fulfilled, (state) => ({
      ...state,
      createPartner: {
        ...state.createPartner,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createPartner.rejected, (state) => ({
      ...state,
      createPartner: {
        ...state.createPartner,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchPartners,
  clearFetchPartnersState,
  clearSearchPartnersState,
} = slice.actions;
export default slice.reducer;
