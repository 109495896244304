import * as yup from "yup";

const phoneRegExp = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export const NAME_CHAR_LIMIT = 32;
export const DESCRIPTION_CHAR_LIMIT = 500;

export const clientModalFormValidation = yup.object().shape({
  name: yup.string().max(NAME_CHAR_LIMIT).required("Client name is required"),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Email is not valid").required("Email is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .optional(),
  address: yup.string().optional(),
  industry: yup.string().optional(),
  is_enabled: yup.boolean().optional(),
});
