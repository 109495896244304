import React, { FC } from "react";

import { useParams } from "react-router-dom";
import { AssetRedirectRoute } from "../component/AssetRedirectRoute/AssetRedirectRoute";

export const AssetRedirectRouteContainer: FC = () => {
  const { assetId }: { assetId: string } = useParams();

  return <AssetRedirectRoute assetId={assetId} />;
};
