import React, { FC } from "react";

import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { AssetAttachment } from "../AssetAttachment/AssetAttachment";
import {
  AttachmentsNumber,
  NoAttachments,
  NoAttachmentsWrapper,
  Wrapper,
} from "./AssetAttachments.style";
import { AssetAttachmentDto } from "../../api/asset.api.dto";
import { LoaderOverlay } from "../../../app/component/DashboardLayout/DashboardLayout.style";

interface IAssetAttachments {
  attachments: AssetAttachmentDto[];
  isPending: boolean;
  isFulfilled: boolean;
  onDownload: (attachmentId: number, name: string, extension: string) => void;
  onDelete: (attachmentId: number) => void;
}

export const AssetAttachments: FC<IAssetAttachments> = ({
  attachments,
  isPending,
  isFulfilled,
  onDownload,
  onDelete,
}) => (
  <Wrapper>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Attachments{" "}
          <AttachmentsNumber>{attachments.length}</AttachmentsNumber>
        </Typography>
      </Grid>
      {isPending && attachments.length === 0 && (
        <NoAttachmentsWrapper>
          {isPending && (
            <LoaderOverlay>
              <CircularProgress size={50} />
            </LoaderOverlay>
          )}
        </NoAttachmentsWrapper>
      )}
      {!isPending && isFulfilled && attachments.length === 0 && (
        <NoAttachmentsWrapper>
          {attachments.length === 0 && isFulfilled && (
            <NoAttachments>
              <Typography>No attachments found</Typography>
            </NoAttachments>
          )}
        </NoAttachmentsWrapper>
      )}
      {isPending && attachments.length > 0 && (
        <LoaderOverlay style={{ top: 55, bottom: 8 }}>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      {attachments.map((attachment) => (
        <AssetAttachment
          attachment={attachment}
          onDownload={onDownload}
          onDelete={onDelete}
        />
      ))}
    </Grid>
  </Wrapper>
);
