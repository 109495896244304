import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";

import { useLocation } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { ResetPasswordDataModel } from "../../model/Auth";
import { resetPassword } from "../../store/auth.action";
import { selectIsResetPasswordStatusPending } from "../../store/auth.selector";
import { ResetPasswordForm } from "../../component/ResetPasswordForm/ResetPasswordForm";

export const ResetPasswordFormContainer: FC = () => {
  const { search } = useLocation();
  const { token }: { token?: string } = parse(search);
  const dispatch = useDispatch<AppDispatch>();
  const isResetPasswordPending = useSelector(
    selectIsResetPasswordStatusPending
  );

  const onSubmit = (resetPasswordData: ResetPasswordDataModel) =>
    dispatch(resetPassword({ ...resetPasswordData, token }));

  return (
    <ResetPasswordForm onSubmit={onSubmit} pending={isResetPasswordPending} />
  );
};
