import {
  combineReducers,
  configureStore as configureToolkitStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createBrowserHistory, History } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

import auth from "../../auth/store/auth.slice";
import locations from "../../location/store/locations.slice";
import partners from "../../partners/store/partners.slice";
import teams from "../../teams/store/teams.slice";
import asset from "../../asset/store/asset.slice";
import clients from "../../clients/store/clients.slice";
import users from "../../users/store/users.slice";
import roles from "../../roles/store/roles.slice";
import notifications from "./notifications/notifications.slice";
import industry from "../../industry/store/industry.slice";
import connectedModal from "./connectedModal/connectedModal.slice";
import devices from "../../devices/store/devices.slice";
import maintenance from "../../maintenance/store/maintenance.slice";
import bookmarks from "../../bookmarks/store/bookmarks.slice";
import alerts from "../../alerts/store/alerts.slice";
import sensorTypes from "../../sensorTypes/store/sensorTypes.slice";
import workOrders from "../../workorders/store/workOrders.slice";
import apiNotifications from "../../apiNotifications/store/apiNotifications.slice";

const configureRootReducer = (newHistory: History) => {
  return combineReducers({
    router: connectRouter(newHistory),
    notifications,
    auth,
    locations,
    partners,
    teams,
    asset,
    clients,
    users,
    roles,
    industry,
    connectedModal,
    devices,
    maintenance,
    bookmarks,
    alerts,
    sensorTypes,
    workOrders,
    apiNotifications,
  });
};

const configureMiddleware = (newHistory: History) => {
  return [
    ...getDefaultMiddleware({ immutableCheck: false }),
    routerMiddleware(newHistory),
  ] as const;
};

export const configureStore = (newHistory?: History) => {
  const history = newHistory || createBrowserHistory();

  const store = configureToolkitStore({
    reducer: configureRootReducer(history),
    middleware: configureMiddleware(history),
  });

  return { store, history };
};

export const { store, history } = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
