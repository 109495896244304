import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { DevicesTable } from "../component/DevicesTable/DevicesTable";
import {
  deleteDevice,
  editDevice,
  fetchDevices as fetchDevicesAction,
  fetchDeviceTypes,
} from "../store/devices.action";
import { clearSearchDevicesState as clearSearchDevicesStateAction } from "../store/devices.slice";
import {
  selectDevicesTableData,
  selectIsDevicesTableStatusPending,
} from "../store/devices.selector";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { EDIT_DEVICE_MODAL } from "../../app/const/modals";
import {
  CreateEditDeviceDto,
  DeviceListDto,
  DeviceTypeDto,
} from "../api/devices.api.dto";
import { unwrap } from "../../app/util/unwrap-dispatch";
import { PartnerDto } from "../../partners/api/partners.api.dto";
import { fetchPartners } from "../../partners/store/partners.action";
import { openErrorNotification } from "../../app/store/notifications/notifications.slice";
import { AssetDto } from "../../asset/api/asset.api.dto";
import { fetchAssetsByClientId } from "../../asset/store/asset.action";
import { DeviceListModel } from "../model/devices.model";
import { fetchClients } from "../../clients/store/clients.action";
import { ClientDto } from "../../clients/api/clients.api.dto";

export const DevicesTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const devicesData = useSelector(selectDevicesTableData);
  const isDevicesPending = useSelector(selectIsDevicesTableStatusPending);
  const [isPending, setIsPending] = useState(false);
  const [isOnMountPending, setIsOnMountPending] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [partnerOptions, setPartnerOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [clientOptions, setClientOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [assetOptions, setAssetOptions] = useState<
    { label: string; value: number; channels: number[] }[]
  >([]);

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_DEVICE_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_DEVICE_MODAL }));

  const handleOnEdit = (editDeviceDto: CreateEditDeviceDto, id?: number) =>
    id && dispatch(editDevice({ id, editDeviceDto }));

  const handleOnDelete = (id: number) => dispatch(deleteDevice(id));

  const handleOnEnter = async () => {
    try {
      setIsPending(true);
      const deviceTypes: DeviceTypeDto[] = await unwrap(
        dispatch(fetchDeviceTypes())
      );
      setDeviceTypeOptions(
        deviceTypes.map((device) => ({ label: device.name, value: device.id }))
      );
      const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
      setPartnerOptions(
        partners.map((partner) => ({ label: partner.name, value: partner.id }))
      );

      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  const handleOnPartnerChange = async (partnerId: number | null) => {
    try {
      setIsPending(true);
      const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
      const partnerById = partners.find((partner) => partner.id === partnerId);

      if (partnerById) {
        setClientOptions(
          partnerById.clients.map((client) => ({
            label: client.name,
            value: client.id,
          }))
        );
      } else {
        const clients: ClientDto[] = await unwrap(dispatch(fetchClients()));
        const allClients = clients.map((client) => ({
          label: client.name,
          value: client.id,
        }));
        setClientOptions(allClients);
      }

      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  const onModalMount = useCallback(
    async (device: DeviceListModel | DeviceListDto) => {
      try {
        setIsOnMountPending(true);
        const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
        const partnerById = partners.find(
          (partner) => partner.id === device.partner_id
        );

        if (partnerById) {
          setClientOptions(
            partnerById.clients.map((client) => ({
              label: client.name,
              value: client.id,
            }))
          );
        } else {
          const clients: ClientDto[] = await unwrap(dispatch(fetchClients()));
          const allClients = clients.map((client) => ({
            label: client.name,
            value: client.id,
          }));
          setClientOptions(allClients);
        }

        if (device.client_id) {
          const assets: AssetDto[] = await unwrap(
            dispatch(fetchAssetsByClientId(device.client_id))
          );
          setAssetOptions(
            assets.map((asset) => ({
              label: asset.name,
              value: asset.id,
              channels: asset.channels.map((channel) => channel.channel_index),
            }))
          );
        }

        setIsOnMountPending(false);
      } catch (e) {
        setIsOnMountPending(false);
      }
    },
    [dispatch]
  );

  const handleOnError = (message: string) =>
    dispatch(openErrorNotification(message));

  const handleOnClientChange = async (clientId: number) => {
    try {
      setIsPending(true);
      const assets: AssetDto[] = await unwrap(
        dispatch(fetchAssetsByClientId(clientId))
      );
      setAssetOptions(
        assets.map((asset) => ({
          label: asset.name,
          value: asset.id,
          channels: asset.channels.map((channel) => channel.channel_index),
        }))
      );
      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  useEffect(() => {
    dispatch(fetchDevicesAction());

    return () => {
      dispatch(clearSearchDevicesStateAction());
    };
  }, [dispatch]);

  return (
    <DevicesTable
      // TODO: quick fix, update model to handle new type
      devices={devicesData.map((device: any) => ({
        ...device,
        measurement: Number(device.measurement),
        equipment_names: device.equipments
          .map((equipment: any) => equipment.name)
          .join(", "),
        partner: device.partner ? device.partner.name : "",
        client: device.client ? device.client.name : "",
      }))}
      isTablePending={isDevicesPending}
      onEdit={handleOnEdit}
      onDelete={handleOnDelete}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
      deviceTypeOptions={deviceTypeOptions}
      partnerOptions={partnerOptions}
      clientOptions={clientOptions}
      isModalPending={isPending || isOnMountPending}
      onEnter={handleOnEnter}
      onPartnerChange={handleOnPartnerChange}
      assetOptions={assetOptions}
      onClientChange={handleOnClientChange}
      onError={handleOnError}
      onModalMount={onModalMount}
    />
  );
};
