import React, { FC } from "react";
import Divider from "@material-ui/core/Divider";
import { Box, Grid, Typography } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { StyledNAHealth } from "../../../../app/component/Table/Table.style";
import { AssetDto, SensorDto } from "../../../api/asset.api.dto";
import {
  StyledDetailsCard,
  TitleChannel,
  TitleContainer,
  TitleName,
  StyledSecondaryCard,
  CardTextKey,
  LastReadingText,
  RedHealthBar,
  YellowHealthBar,
  GreenHealthBar,
  ImageWrapper,
} from "./ChannelDetails.style";
import { getSensorTypeIcon } from "../../../../sensorTypes/util/get-sensor-type-icon";

export interface IChannelDetailsProps {
  sensors: SensorDto[];
  selectedSensor: number;
  asset: AssetDto | null;
}

export const ChannelDetails: FC<IChannelDetailsProps> = ({
  sensors,
  selectedSensor,
  asset,
}) => {
  const healthBar = (healthRating: number) => {
    if (
      !asset?.health_is_enabled ||
      sensors[selectedSensor].state === "errored" ||
      !sensors[selectedSensor].alarm.enabled
    ) {
      return "";
    }
    if (healthRating > 5) {
      return <GreenHealthBar>Sensor Health: {healthRating}</GreenHealthBar>;
    }

    if (healthRating > 2.5) {
      return <YellowHealthBar>Sensor Health: {healthRating}</YellowHealthBar>;
    }
    if (healthRating >= 0) {
      return (
        <RedHealthBar>
          <Box mr={1} display="flex">
            <ReportProblemIcon fontSize="large" />
          </Box>
          Sensor Health: {healthRating}
        </RedHealthBar>
      );
    }
    return null;
  };

  return (
    <StyledDetailsCard>
      <TitleContainer>
        <TitleChannel>
          Ch. {sensors[selectedSensor]?.channel.channel_index + 1}
        </TitleChannel>
        <TitleName>{sensors[selectedSensor]?.friendly_name}</TitleName>
      </TitleContainer>
      <Box mb={2}>
        <Divider />
      </Box>
      <StyledSecondaryCard>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="center">
              {sensors[selectedSensor] ? (
                <ImageWrapper>
                  {getSensorTypeIcon(sensors[selectedSensor].sensor_type)}
                </ImageWrapper>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box>
              <Box display="flex" justifyContent="flex-start">
                <CardTextKey variant="body2">Sensor Type:&nbsp;</CardTextKey>
                <Typography variant="body2">
                  {sensors[selectedSensor]?.sensor_type?.name ?? "N/A"}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-start">
                <CardTextKey variant="body2">Alarm Type:&nbsp;</CardTextKey>
                <Typography variant="body2">
                  {sensors[selectedSensor]?.alarm?.alert_name ?? "N/A"}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-start">
                <CardTextKey variant="body2">
                  Alarm Set-point:&nbsp;
                </CardTextKey>
                <Typography variant="body2">
                  {String(
                    Number(sensors[selectedSensor]?.alarm?.max).toFixed(2)
                  ) ?? "N/A"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <LastReadingText>
                Last Reading:&nbsp;
                <span>{sensors[selectedSensor]?.reading}</span>
              </LastReadingText>
            </Box>
          </Grid>
        </Grid>
      </StyledSecondaryCard>
      <Box display="flex" justifyContent="center">
        {healthBar(sensors[selectedSensor]?.health_rating)}
      </Box>
    </StyledDetailsCard>
  );
};
