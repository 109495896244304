import { createSlice } from "@reduxjs/toolkit";
import { UsersState, usersState } from "./users.store";
import {
  fetchUsers,
  deleteUser,
  editUser,
  createUser,
  fetchUserDetails,
  editUserDetails,
  editUserAvatar,
  fetchUserNotifications,
  editUserNotifications,
  unsubscribeUserEmailNotifications,
} from "./users.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "users",
  initialState: usersState,
  reducers: {
    searchUsers: (state: UsersState, { payload }: { payload: string }) => ({
      ...state,
      searchUsers: payload,
    }),
    clearFetchUsersState: (state: UsersState) => ({
      ...state,
      fetchUsers: usersState.fetchUsers,
    }),
    clearSearchUsersState: (state: UsersState) => ({
      ...state,
      searchUsers: usersState.searchUsers,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => ({
      ...state,
      fetchUserDetails: {
        ...state.fetchUserDetails,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchUserDetails: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }
      return state;
    });
    builder.addCase(fetchUserDetails.rejected, (state) => ({
      ...state,
      fetchUserDetails: {
        ...state.fetchUserDetails,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editUserDetails.pending, (state) => ({
      ...state,
      editUserDetails: {
        ...state.editUserDetails,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editUserDetails.fulfilled, (state) => ({
      ...state,
      editUserDetails: {
        ...state.editUserDetails,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editUserDetails.rejected, (state) => ({
      ...state,
      editUserDetails: {
        ...state.editUserDetails,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editUserAvatar.pending, (state) => ({
      ...state,
      editUserAvatar: {
        ...state.editUserAvatar,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editUserAvatar.fulfilled, (state) => ({
      ...state,
      editUserAvatar: {
        ...state.editUserAvatar,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editUserAvatar.rejected, (state) => ({
      ...state,
      editUserAvatar: {
        ...state.editUserAvatar,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(fetchUsers.pending, (state) => ({
      ...state,
      fetchUsers: {
        ...state.fetchUsers,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchUsers: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchUsers.rejected, (state) => ({
      ...state,
      fetchUsers: {
        ...state.fetchUsers,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteUser.pending, (state) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteUser.fulfilled, (state) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteUser.rejected, (state) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editUser.pending, (state) => ({
      ...state,
      editUser: {
        ...state.editUser,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editUser.fulfilled, (state) => ({
      ...state,
      editUser: {
        ...state.editUser,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editUser.rejected, (state) => ({
      ...state,
      editUser: {
        ...state.editUser,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createUser.pending, (state) => ({
      ...state,
      createUser: {
        ...state.createUser,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createUser.fulfilled, (state) => ({
      ...state,
      createUser: {
        ...state.createUser,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createUser.rejected, (state) => ({
      ...state,
      createUser: {
        ...state.createUser,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(fetchUserNotifications.pending, (state) => ({
      ...state,
      fetchUserNotifications: {
        ...state.fetchUserNotifications,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchUserNotifications.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchUserNotifications: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchUserNotifications.rejected, (state) => ({
      ...state,
      fetchUserNotifications: {
        ...state.fetchUserNotifications,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editUserNotifications.pending, (state) => ({
      ...state,
      editUserNotifications: {
        ...state.editUserNotifications,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editUserNotifications.fulfilled, (state) => ({
      ...state,
      editUserNotifications: {
        ...state.editUserNotifications,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editUserNotifications.rejected, (state) => ({
      ...state,
      editUserNotifications: {
        ...state.editUserNotifications,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(unsubscribeUserEmailNotifications.pending, (state) => ({
      ...state,
      fetchUserDetails: {
        ...state.fetchUserDetails,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(
      unsubscribeUserEmailNotifications.fulfilled,
      (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchUserDetails: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      }
    );
    builder.addCase(unsubscribeUserEmailNotifications.rejected, (state) => ({
      ...state,
      fetchUserDetails: {
        ...state.fetchUserDetails,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchUsers,
  clearFetchUsersState,
  clearSearchUsersState,
} = slice.actions;
export default slice.reducer;
