import { Box, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { fetchRecentActivity } from "../../clients/store/clients.action";
import {
  selectIsRecentActivityStatusPending,
  selectRecentActivityStateData,
} from "../../clients/store/clients.selector";

import { RecentActivitiesTable } from "../component/RecentActivitiesTable/RecentActivitiesTable";

export const RecentActivitiesTableContainer: FC<{ clientId: number }> = ({
  clientId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const activities = useSelector(selectRecentActivityStateData);
  const pending = useSelector(selectIsRecentActivityStatusPending);

  useEffect(() => {
    dispatch(fetchRecentActivity(clientId));
  }, [dispatch, clientId]);

  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        mb={2}
        mt={2}
      >
        <Typography variant="h3">Recent Activity</Typography>
      </Box>
      <RecentActivitiesTable activities={activities} pending={pending} />
    </>
  );
};
