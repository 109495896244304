export enum TaskStatus {
  Open = "open",
  InProgress = "in progress",
  Done = "closed",
}

export enum SortBy {
  DueDate = "duedate",
  Priority = "priority",
}
