import React, { FC } from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import moment from "moment";
import { AssetDto } from "../../api/asset.api.dto";
import {
  FullHeightCard,
  LoaderOverlay,
  StyledCardHeader,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  ColumnWrapper,
  Header,
  ImageWrapper,
  Label,
  StatusWrapper,
  StyledButton,
  StyledCardContent,
  TagsWrapper,
  Value,
  Wrapper,
  DateText,
  StyledTypography,
} from "./AssetInfo.style";
import { DATE_AND_HOURS } from "../../../app/const/date-format";
import { CategoryDto } from "../../../category/api/category.api.dto";
import { AssetRealtimeTags } from "../AssetRealtime/AssetRealtimeTags";
import { getAssetImage } from "../../util/get-asset-image";
import CalendarIcon from "../../../../assets/icons/calendar.svg";
import LocationIcon from "../../../../assets/icons/location.svg";
import TeamsIcon from "../../../../assets/icons/teams.svg";

interface IAssetInfo {
  data: AssetDto | null;
  pending: boolean;
  onOpenModal: () => void;
}

export const AssetInfo: FC<IAssetInfo> = ({ pending, data, onOpenModal }) => {
  return (
    <FullHeightCard>
      <StyledCardHeader
        title={
          <Header>
            <Typography component="span" variant="h5">
              Asset Info
            </Typography>
            {data && (
              <StatusWrapper activeStatus={data.cached_active_status}>
                <PowerSettingsNewIcon />
                {data.cached_active_status}
              </StatusWrapper>
            )}
          </Header>
        }
      />
      {pending && (
        <LoaderOverlay>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      <StyledCardContent>
        {data && (
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Wrapper>
                    <div>
                      <img src={LocationIcon} alt="Location" />
                      <Typography variant="body2">Location</Typography>
                    </div>
                    <Typography variant="subtitle1">
                      {data.location?.title || "-"}
                    </Typography>
                  </Wrapper>
                </Grid>
                <Grid item xs={12}>
                  <Wrapper>
                    <div>
                      <img src={TeamsIcon} alt="Location" />
                      <Typography variant="body2">Teams</Typography>
                    </div>
                    <Typography variant="subtitle1">
                      {data.teams?.map((team) => team.name).join(", ") || "-"}
                    </Typography>
                  </Wrapper>
                </Grid>
                <Grid item xs={12}>
                  <Wrapper>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={CalendarIcon} alt="Calendar" />
                      <StyledTypography variant="body2" title="Last Recording">
                        Last Recording
                      </StyledTypography>
                    </div>
                    <Box marginTop="2px" display="flex" flexDirection="row">
                      <DateText
                        variant="body2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {data.last_received
                          ? moment(data.last_received).format(DATE_AND_HOURS)
                          : "N/A"}
                      </DateText>
                      {data.devices.length > 0 && (
                        <StyledButton
                          color="primary"
                          disableRipple
                          onClick={onOpenModal}
                        >
                          Info
                        </StyledButton>
                      )}
                    </Box>
                  </Wrapper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ImageWrapper>{getAssetImage(data.live_graph_svg)}</ImageWrapper>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ColumnWrapper>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      maxHeight="18px"
                    >
                      <Label>Category </Label>
                      <Value>
                        {data.categories
                          ?.map((category: CategoryDto) => category.name)
                          .join(", ") || "-"}
                      </Value>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Label>Manufacturer</Label>
                      <Value>{data.manufacturer}</Value>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Label>Model</Label>
                      <Value>{data.model || "-"}</Value>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Label>Asset SN</Label>
                      <Value>{data.serial_number || "-"}</Value>
                    </Box>
                  </ColumnWrapper>
                </Grid>
                <Grid item xs={12}>
                  <TagsWrapper>
                    <AssetRealtimeTags asset={data} />
                  </TagsWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </StyledCardContent>
    </FullHeightCard>
  );
};
