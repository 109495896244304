import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredAssetsState, setTagsFilters } from "../store/asset.slice";
import { AppDispatch } from "../../app/store";
import { selectFilteredAssets } from "../store/asset.selector";
import { AssetDto, AssetTagDto } from "../api/asset.api.dto";
import { AssetTagsFilter } from "../component/AssetTagsFilter/AssetTagsFilter";
import { filterByTags } from "../util/filter-by-tags";

export const AssetTagsFilterContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const assetsData = useSelector(selectFilteredAssets);
  const assetTags = assetsData.map((asset: AssetDto) => {
    return asset.tags;
  });
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [originalData, setOriginalData] = useState<AssetDto[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getAssetTagsArray = (): string[] =>
    assetTags.flatMap((tagsArray: AssetTagDto[]) =>
      tagsArray.map((tag: AssetTagDto) => tag.name)
    );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string[]
  ) => {
    if (!originalData.length) {
      setOriginalData(assetsData);
    }
    let data = originalData;
    if (newValue.length) {
      data = filterByTags(assetsData, newValue);
    }
    setSelectedFilters(newValue);
    dispatch(setTagsFilters(newValue));
    dispatch(setFilteredAssetsState(data));
  };

  return (
    <AssetTagsFilter
      handleChange={handleChange}
      handleClose={handleClose}
      handleClick={handleClick}
      tags={getAssetTagsArray()}
      anchorEl={anchorEl}
      selectedFilters={selectedFilters}
    />
  );
};
