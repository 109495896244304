import React, { FC } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { StyledDialog, GreyButton, RedButton } from "./AssetRuntimeModal.style";

export interface IAssetRuntimeModalProps {
  open: boolean;
  handleCloseResetModal: any;
  handleRuntimeReset: (event: React.MouseEvent<HTMLElement>) => void;
}
export const AssetRuntimeModal: FC<IAssetRuntimeModalProps> = ({
  open,
  handleCloseResetModal,
  handleRuntimeReset,
}) => {
  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleCloseResetModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reset Runtime Counter</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Are you sure you want to reset Runtime Counter?</p>
            <p>This will erase all prior data and cannot be undone.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton onClick={handleCloseResetModal}>Cancel</GreyButton>
          <RedButton onClick={handleRuntimeReset} autoFocus>
            Reset
          </RedButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};
