import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchMaintenances as fetchMaintenancesRequest,
  createMaintenance as createMaintenanceRequest,
  deleteMaintenance as deleteMaintenanceRequest,
  editMaintenance as editMaintenanceRequest,
} from "../api/maintenance.api";
import withError from "../../app/util/with-thunk-error";
import { MaintenanceDto } from "../api/maintenance.api.dto";

export const fetchMaintenances = createAsyncThunk(
  "maintenances/fetchMaintenances",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<MaintenanceDto[]> => {
      return fetchMaintenancesRequest();
    }
  )
);

export const createMaintenance = createAsyncThunk(
  "maintenances/create",
  withError(
    async (
      maintenance: MaintenanceDto,
      { dispatch }
    ): Promise<MaintenanceDto> => {
      const response = await createMaintenanceRequest(maintenance);
      dispatch(openSuccessNotification("Maintenance has been added"));
      dispatch(fetchMaintenances());
      return response;
    }
  )
);

export const deleteMaintenance = createAsyncThunk(
  "maintenances/delete",
  withError(
    async (id: number, { dispatch }): Promise<string> => {
      const response = await deleteMaintenanceRequest(id);
      dispatch(openSuccessNotification("Maintenance has been deleted"));
      dispatch(fetchMaintenances());
      return response;
    }
  )
);

export const editMaintenance = createAsyncThunk(
  "maintenances/edit",
  withError(
    async (
      maintenance: MaintenanceDto,
      { dispatch }
    ): Promise<MaintenanceDto> => {
      const response = await editMaintenanceRequest(maintenance);
      dispatch(openSuccessNotification("Maintenance has been updated"));
      dispatch(fetchMaintenances());
      return response;
    }
  )
);
