import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { IndustryDto } from "../api/industry.api.dto";

export interface IndustryState {
  fetchIndustries: AsyncStateModel<IndustryDto[]>;
}

export const industryState: IndustryState = {
  fetchIndustries: {
    status: AsyncStatus.Void,
    data: [],
  },
};
