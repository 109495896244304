import * as yup from "yup";

const phoneRegExp = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const userModalFormValidation = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().optional(),
  email: yup.string().email("Email is not valid").required("Email is required"),
  phone: yup.string().when("sms_subscribed", {
    is: true,
    then: yup
      .string()
      .required("Phone number is required when SMS notifications are enabled")
      .matches(phoneRegExp, "Phone number is not valid"),
  }),
  job_title: yup.string().optional(),
  partner_id: yup.number().nullable(true),
  client_id: yup.number().required(),
  team_ids: yup.array().of(yup.number().optional()),
  role: yup.number().required(),
  sms_subscribed: yup.boolean().optional(),
  email_subscribed: yup.boolean().optional(),
});
