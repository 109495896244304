import React from "react";
import { useSelector } from "react-redux";
import { UserRole } from "../../enum/UserRole";
import { useShowForRole } from "../../hooks/useShowForRole";
import {
  SubTaskFormModel,
  TaskDto,
} from "../../../workorders/api/workorders.api.dto";
import { selectProfileData } from "../../../auth/store/auth.selector";

export interface IConnectedHideForRoleTask {
  roles: Array<UserRole>;
  task?: TaskDto | undefined;
  subtask?: SubTaskFormModel | undefined;
  owner?: any;
  children: React.ReactNode;
}

export const ConnectedHideForRoleTask = (props: IConnectedHideForRoleTask) => {
  const { roles, children, task, subtask, owner } = props;
  const profileData = useSelector(selectProfileData);
  let show = true;
  const isNotRestricted = useShowForRole(roles);
  if (task?.assigned_user?.id !== profileData?.user?.id) {
    show = !isNotRestricted;
  }
  if (subtask?.assigned_user?.id === profileData?.user?.id) {
    show = isNotRestricted;
  }
  if (owner?.created_by.id === profileData?.user?.id) {
    show = true;
  }

  return show ? <>{children}</> : null;
};
