import { AssetDto, SensorDto } from "../../asset/api/asset.api.dto";
import { DeviceListDto } from "../../devices/api/devices.api.dto";
import { UserDto } from "../../users/api/users.api.dto";
import { TaskDto } from "../../workorders/api/workorders.api.dto";

export enum ApiNotificationType {
  Workorder = "workorder", // should take to workorder
  WorkorderDone = "workorder_done", // should take to workorder
  Info = "info", // should take to device
  Alarm = "alarm", // should take to asset
  LowHealth = "low_health", // should take to asset
  LostConnection = "lost_connection", // should take to device
}

export interface ApiNotificationDto {
  success: boolean;
  notifications: NotificationDto[];
}

export interface NotificationDto {
  id: number;
  asset_id: number | null;
  device_id: number | null;
  sensor_id: number | null;
  workorder_id: number | null;
  type: ApiNotificationType;
  title: string;
  sub_title: string;
  created_by: number;
  time: string;
  timestamp: Date;
  is_read: boolean;
}
