import React, { FC, useState } from "react";

import {
  StyledClearIcon,
  StyledIconButton,
  StyledSearchContainer,
  StyledSearchIcon,
  TableToolbarSearchInput,
} from "./TableSearchInput.style";

interface ITableSearchInput {
  onSearch: (searchTerm: string) => void;
  label: string;
  isPending?: boolean;
  filter?: string;
}

const TableSearchInput: FC<ITableSearchInput> = ({
  onSearch,
  label,
  isPending,
  filter,
}) => {
  const [value, setValue] = useState<string>("");

  const handleClear = () => {
    onSearch("");
  };

  return (
    <StyledSearchContainer>
      <TableToolbarSearchInput
        disabled={isPending}
        fullWidth
        value={filter || value}
        onChange={(e) => {
          onSearch(e.target.value);
          setValue(e.target.value);
        }}
        label={label}
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: filter ? (
            <StyledIconButton aria-label="clear search" onClick={handleClear}>
              <StyledClearIcon />
            </StyledIconButton>
          ) : (
            <StyledSearchIcon />
          ),
        }}
      />
    </StyledSearchContainer>
  );
};

export default TableSearchInput;
