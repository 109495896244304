import React, { FC } from "react";
import { Checkbox as MuiCheckbox, CheckboxProps } from "@material-ui/core";
import {
  StyledSelectCheckbox,
  StyledTableCellCheckbox,
} from "./Checkbox.style";

export interface ICheckbox extends CheckboxProps {
  placement?: "select" | "table-cell" | "table-header";
}

export const Checkbox: FC<ICheckbox> = ({ placement, ...rest }) => {
  if (placement === "select") {
    return <StyledSelectCheckbox {...rest} />;
  }
  if (placement === "table-cell") {
    return <StyledTableCellCheckbox {...rest} />;
  }
  return <MuiCheckbox {...rest} />;
};
