import React, { FC } from "react";
import { useFormik } from "formik";
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Box,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckBoxOutlineBlank as UncheckedBoxIcon,
  CheckBox as CheckedBoxIcon,
} from "@material-ui/icons";
import { Checkbox } from "../../../app/component/Checkbox/Checkbox";
import {
  roleModalFormValidation,
  DESCRIPTION_CHAR_LIMIT,
} from "./roleModalForm.validation";
import { StyledCard } from "../../../app/component/Table/Table.style";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import {
  RoleDto,
  CreateEditRoleDto,
  RolePermissions,
} from "../../api/roles.api.dto";
import { PartnerDto } from "../../../partners/api/partners.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import {
  RoleModalFormPermissions,
  RoleModalFormDevicePermissions,
} from "./RoleModalFormPermissions";

export interface IRoleModalForm {
  role?: RoleDto;
  parentRoles: RoleDto[];
  partners: PartnerDto[];
  clients: ClientDto[];
  onSubmit: (values: CreateEditRoleDto, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  editView: boolean;
}

export const RoleModalForm: FC<IRoleModalForm> = ({
  role,
  parentRoles,
  partners,
  clients,
  onSubmit,
  displayActionButtons,
  isPending,
  editView,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      endAdornment: {
        "& .MuiAutocomplete-endAdornment": {
          top: "30px",
        },
      },
    })
  );
  const classes = useStyles();

  const getParentIdOptions = () => {
    return [...parentRoles]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((parentRole) => parentRole.id) as number[];
  };
  const getParentIdOptionLabel = (option: number) => {
    const match = parentRoles.find((parentRole) => {
      return parentRole.id === option;
    });
    return match ? match.name : "";
  };

  const getPartnerIdOptions = () => {
    return [...partners]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((partner) => partner.id) as number[];
  };
  const getPartnerIdOptionLabel = (option: number) => {
    const match = partners.find((partner) => {
      return partner.id === option;
    });
    return match ? match.name : "";
  };

  const getClientIdOptions = () => {
    return [...clients]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client) => client.id) as number[];
  };
  const getClientIdOptionLabel = (option: number) => {
    const match = clients.find((client) => {
      return client.id === option;
    });
    return match ? match.name : "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      parent_id: role?.parent_id || null,
      name: role?.name || "",
      description: role?.description || "",
      partner_ids: (role?.partners || []).map((partner) => partner.id),
      client_ids: (role?.clients || []).map((client) => client.id),
      permissions: role?.formatted_permissions || ({} as RolePermissions),
    },
    validationSchema: roleModalFormValidation,
    onSubmit: (values: CreateEditRoleDto) => onSubmit(values, role?.id),
  });

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required
              disabled={isPending || (!editView && role !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth disabled={isPending}>
              <Autocomplete
                className={
                  formik.values.partner_ids.length > 0
                    ? classes.endAdornment
                    : ""
                }
                multiple
                disableCloseOnSelect
                options={getPartnerIdOptions()}
                id="partner_ids"
                getOptionLabel={getPartnerIdOptionLabel}
                disabled={isPending || (!editView && role !== undefined)}
                value={formik.values.partner_ids}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: number[]
                ) => {
                  formik.setFieldValue("partner_ids", newValue);
                }}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      placement="select"
                      icon={<UncheckedBoxIcon fontSize="small" />}
                      checkedIcon={<CheckedBoxIcon fontSize="small" />}
                      checked={selected}
                    />
                    {getPartnerIdOptionLabel(option)}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partner_ids"
                    label="Partners"
                    error={
                      formik.touched.partner_ids &&
                      Boolean(formik.errors.partner_ids)
                    }
                    helperText={
                      (formik.touched.partner_ids &&
                        formik.errors.partner_ids) ||
                      "Leave blank to assign the role to all partners"
                    }
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth disabled={isPending}>
              <Autocomplete
                className={
                  formik.values.client_ids.length > 0
                    ? classes.endAdornment
                    : ""
                }
                multiple
                disableCloseOnSelect
                options={getClientIdOptions()}
                id="client_ids"
                getOptionLabel={getClientIdOptionLabel}
                disabled={isPending || (!editView && role !== undefined)}
                value={formik.values.client_ids}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: number[]
                ) => {
                  formik.setFieldValue("client_ids", newValue);
                }}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      placement="select"
                      icon={<UncheckedBoxIcon fontSize="small" />}
                      checkedIcon={<CheckedBoxIcon fontSize="small" />}
                      checked={selected}
                    />
                    {getClientIdOptionLabel(option)}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="client_ids"
                    label="Clients"
                    error={
                      formik.touched.client_ids &&
                      Boolean(formik.errors.client_ids)
                    }
                    helperText={
                      (formik.touched.client_ids && formik.errors.client_ids) ||
                      "Leave blank to assign the role to all clients"
                    }
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Description"
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{
                maxLength: DESCRIPTION_CHAR_LIMIT,
              }}
              disabled={isPending || (!editView && role !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth disabled={isPending}>
              <Autocomplete
                options={getParentIdOptions()}
                id="parent_id"
                getOptionLabel={getParentIdOptionLabel}
                disabled={isPending || (!editView && role !== undefined)}
                value={formik.values.parent_id}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: number | null
                ) => {
                  formik.setFieldValue("parent_id", newValue);
                  const selectedParentRole = parentRoles.find(
                    (parentRole) => parentRole.id === newValue
                  );
                  if (selectedParentRole) {
                    formik.setFieldValue(
                      "permissions",
                      selectedParentRole.formatted_permissions
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="parent_id"
                    label="Template"
                    error={
                      formik.touched.parent_id &&
                      Boolean(formik.errors.parent_id)
                    }
                    helperText={
                      formik.touched.parent_id && formik.errors.parent_id
                    }
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledCard>
              <Box px="12px" pt={2} pb={1}>
                <Typography>Select Permissions</Typography>
              </Box>
              <RoleModalFormPermissions
                formValues={formik.values.permissions}
                onChange={formik.handleChange}
                setValues={(permissions) =>
                  formik.setFieldValue("permissions", permissions)
                }
                editView={editView || role === undefined}
              />
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard>
              <Box px="12px" pt={2} pb={1}>
                <Typography>Additional Permissions</Typography>
              </Box>
              <RoleModalFormDevicePermissions
                formValues={formik.values.permissions}
                onChange={formik.handleChange}
                setValues={(permissions) =>
                  formik.setFieldValue("permissions", permissions)
                }
                editView={editView || role === undefined}
              />
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
