import React, { FC } from "react";
import {
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { AssetDynamoRollupDto } from "../../api/asset.api.dto";
import { ASSET_TRENDS_COLORS } from "../../../app/theme/colors";
import { Export } from "../../../app/component/Icons/ExportIcon";
import {
  ExportIconButton,
  LoaderOverlay,
  StyledCard,
  StyledTooltipDiv,
} from "./AnalyticsGraphs.style";

interface IAssetHealthComparisonGraph {
  healthComparisonData: AssetDynamoRollupDto[];
  isGraphsPending: boolean;
  formatDate: (tickItem: number | string) => string;
}

export const AssetHealthComparisonGraph: FC<IAssetHealthComparisonGraph> = ({
  healthComparisonData,
  isGraphsPending,
  formatDate,
}) => {
  const healthComparisonDataCopy = [...healthComparisonData];
  healthComparisonDataCopy.sort(
    (a, b) =>
      a?.chartData[a?.chartData.length - 1]?.xdata -
      b?.chartData[b?.chartData.length - 1]?.xdata
  );

  const generateGraphExportPdf = () => {
    const element = document.getElementById("health-comparison-graph");

    if (element) {
      html2canvas(element, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "px");

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("AssetHealthComparisonGraph.pdf");
      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltipDiv>
          {payload.map(
            (
              series: { stroke: string; name: string; value: number },
              index: number
            ) => (
              <p key={index}>
                <span
                  style={{ color: `${series.stroke}` }}
                >{`• ${series.name}: `}</span>
                {`${series.value.toFixed(2)}`}
              </p>
            )
          )}
        </StyledTooltipDiv>
      );
    }

    return null;
  };
  const graphDataExists = (): boolean => {
    let exists = false;
    if (healthComparisonData.length > 0) {
      Object.values(healthComparisonData).forEach((healthData) => {
        if (healthData.chartData.length > 0) {
          exists = true;
        }
      });
    }
    return exists;
  };
  return (
    <StyledCard>
      {isGraphsPending && (
        <LoaderOverlay>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      <CardContent id="health-comparison-graph">
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <Box alignSelf="center" flexGrow={1}>
            <Typography variant="h2">Asset Health Comparison</Typography>
          </Box>
          <Box alignSelf="center" mr={1}>
            <ExportIconButton
              data-html2canvas-ignore="true"
              startIcon={<Export />}
              pending={isGraphsPending}
              onClick={generateGraphExportPdf}
            />
          </Box>
        </Box>
        <Box mt={2}>
          {graphDataExists() ? (
            <ResponsiveContainer width="100%" height={350}>
              <LineChart>
                <XAxis
                  dataKey="xdata"
                  tickFormatter={formatDate}
                  type="number"
                  domain={["dataMin", "dataMax"]}
                />
                <YAxis
                  domain={[0, 10]}
                  ticks={[0, 2.5, 5, 7.5, 10]}
                  tickMargin={10}
                  tickLine={false}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                {healthComparisonDataCopy.map(
                  (series: AssetDynamoRollupDto, index: number) => {
                    return series.chartData.length > 0 ? (
                      <Line
                        dataKey="data"
                        data={series.chartData}
                        name={series.name}
                        key={index}
                        stroke={ASSET_TRENDS_COLORS[index % 10]}
                        strokeWidth={4}
                        type="monotone"
                        dot={false}
                      />
                    ) : null;
                  }
                )}
              </LineChart>
            </ResponsiveContainer>
          ) : null}
        </Box>
      </CardContent>
    </StyledCard>
  );
};
