import React, { FC } from "react";
import { Grid } from "@material-ui/core";

import {
  StyledBlock,
  DoubleBox,
  GreenArrowUpwardIcon,
  GreenIconOutline,
  RedArrowDownwardIcon,
  RedIconOutline,
  BlueTimeRoundedIcon,
  BlueIconOutline,
  StyledWrapper,
  FlexBoxIcon,
  FlexBoxText,
  TitleBar,
  StyledRestoreIcon,
  TransparentButton,
  CountersContainer,
} from "./AssetRuntime.style";
import { AssetRuntimeDto } from "../../api/asset.api.dto";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";

export interface IAssetRuntimeProps {
  runtime: AssetRuntimeDto | null;
  handleOpenResetModal: () => void;
}

export const AssetRuntime: FC<IAssetRuntimeProps> = ({
  runtime,
  handleOpenResetModal,
}) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DoubleBox>
            <TitleBar>
              <ConnectedShowForPermissions
                feature="devices"
                permissions={{ allOf: ["reset-runtime"] }}
              >
                <TransparentButton type="button" onClick={handleOpenResetModal}>
                  <StyledRestoreIcon />
                </TransparentButton>
              </ConnectedShowForPermissions>
              <>Runtime Counter</>
            </TitleBar>
            <CountersContainer>
              <StyledBlock>
                <StyledWrapper>
                  <FlexBoxIcon>
                    <GreenIconOutline>
                      <GreenArrowUpwardIcon />
                    </GreenIconOutline>
                  </FlexBoxIcon>
                  <FlexBoxText>
                    <h3>Asset Uptime</h3>
                    <h1>{runtime?.uptimeMins} Hours</h1>
                  </FlexBoxText>
                </StyledWrapper>
              </StyledBlock>
              <StyledBlock>
                <StyledWrapper>
                  <FlexBoxIcon>
                    <RedIconOutline>
                      <RedArrowDownwardIcon />
                    </RedIconOutline>
                  </FlexBoxIcon>
                  <FlexBoxText>
                    <h3>Asset Downtime</h3>
                    <h1>{runtime?.downtimeMins} Hours</h1>
                  </FlexBoxText>
                </StyledWrapper>
              </StyledBlock>
              <StyledBlock>
                <StyledWrapper>
                  <FlexBoxIcon>
                    <BlueIconOutline>
                      <BlueTimeRoundedIcon />
                    </BlueIconOutline>
                  </FlexBoxIcon>
                  <FlexBoxText>
                    <h3>Asset Runtime</h3>
                    <h1>{runtime?.runtimeMins} Hours</h1>
                  </FlexBoxText>
                </StyledWrapper>
              </StyledBlock>
            </CountersContainer>
          </DoubleBox>
        </Grid>
      </Grid>
    </div>
  );
};
