import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchLocations as fetchLocationsRequest,
  createLocation as createLocationRequest,
  deleteLocation as deleteLocationRequest,
  editLocation as editLocationRequest,
  fetchAlarmsAmount as fetchAlarmsAmountRequest,
  fetchWarningsAmount as fetchWarningsAmountRequest,
} from "../api/location.api";
import withError from "../../app/util/with-thunk-error";
import { LocationDto } from "../api/locations.api.dto";
import { CreateEditLocationModel } from "../model/locations.model";
import {
  ADD_LOCATION_MODAL,
  EDIT_LOCATION_MODAL,
} from "../../app/const/modals";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { mapCreateEditLocationModelToDto } from "../api/locations.api.mapper";

export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<LocationDto[]> => {
      return fetchLocationsRequest();
    }
  )
);

export const createLocation = createAsyncThunk(
  "locations/createLocation",
  withError(
    async (location: CreateEditLocationModel, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_LOCATION_MODAL }));
      await createLocationRequest(mapCreateEditLocationModelToDto(location));
      dispatch(closeModal({ name: ADD_LOCATION_MODAL }));
      dispatch(openSuccessNotification("Location has been added"));
      dispatch(fetchLocations());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_LOCATION_MODAL }))
  )
);

export const deleteLocation = createAsyncThunk(
  "locations/deleteLocation",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_LOCATION_MODAL }));
      await deleteLocationRequest(id);
      dispatch(closeModal({ name: EDIT_LOCATION_MODAL }));
      dispatch(openSuccessNotification("Location has been deleted"));
      dispatch(fetchLocations());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_LOCATION_MODAL }))
  )
);

export const editLocation = createAsyncThunk(
  "locations/editLocation",
  withError(
    async (
      {
        id,
        editLocationModel,
      }: { id: number; editLocationModel: CreateEditLocationModel },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_LOCATION_MODAL }));
      await editLocationRequest(
        id,
        mapCreateEditLocationModelToDto(editLocationModel)
      );
      dispatch(closeModal({ name: EDIT_LOCATION_MODAL }));
      dispatch(openSuccessNotification("Location has been updated"));
      dispatch(fetchLocations());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_LOCATION_MODAL }))
  )
);

export const fetchAlarmsAmount = createAsyncThunk(
  "locations/fetchAlarmsAmount",
  withError(
    async (id: number): Promise<number> => {
      return fetchAlarmsAmountRequest(id);
    }
  )
);

export const fetchWarningsAmount = createAsyncThunk(
  "locations/fetchWarningsAmount",
  withError(
    async (id: number): Promise<number> => {
      return fetchWarningsAmountRequest(id);
    }
  )
);
