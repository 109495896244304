import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { parse } from "query-string";
import { PageHeaderContainer } from "../../app/component/DashboardLayout/PageHeader/PageHeader";
import { AssetPageHeader } from "../component/AssetPageHeader/AssetPageHeader";
import { AppDispatch } from "../../app/store";
import {
  editAsset as editAssetAction,
  deleteAsset as deleteAssetAction,
  showAsset,
  fetchCategories,
  fetchTags,
  addAssetToBookmark,
  removeAssetFromBookmark,
  fetchAssets,
} from "../store/asset.action";
import { fetchLocations } from "../../location/store/locations.action";
import { fetchTeams } from "../../teams/store/teams.action";
import {
  selectIsShowAssetStatusFulfilled,
  selectIsShowAssetStatusPending,
  selectShowAssetData,
  selectCategories,
  selectTags,
  selectIsTagsStatusPending,
  selectIsCategoriesStatusPending,
  selectIsAddAssetToBookmarkStatusPending,
  selectIsRemoveAssetFromBookmarkStatusPending,
  selectAssetsData,
} from "../store/asset.selector";
import {
  selectIsLocationsStatusPending,
  selectLocationsData,
} from "../../location/store/locations.selector";
import {
  selectIsTeamsTableStatusPending,
  selectTeamsTableData,
} from "../../teams/store/teams.selector";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { EDIT_ASSET_MODAL } from "../../app/const/modals";
import { CreateEditAssetModel } from "../model/asset.model";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import {
  selectFetchDevicesData,
  selectIsFetchDevicesStatusPending,
} from "../../devices/store/devices.selector";
import { getPathnameNthParam } from "../../app/util/router-helpers";

export const AssetPageHeaderContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { assetId } = useParams<{ assetId: string }>();
  const assetData = useSelector(selectShowAssetData);
  const isAssetDataPending = useSelector(selectIsShowAssetStatusPending);
  const isAssetDataFulfilled = useSelector(selectIsShowAssetStatusFulfilled);
  const tags = useSelector(selectTags);
  const isFetchTagsPending = useSelector(selectIsTagsStatusPending);
  const locations = useSelector(selectLocationsData);
  const isFetchLocationsPending = useSelector(selectIsLocationsStatusPending);
  const teams = useSelector(selectTeamsTableData);
  const isFetchTeamsPending = useSelector(selectIsTeamsTableStatusPending);
  const categories = useSelector(selectCategories);
  const isFetchCategoriesPending = useSelector(selectIsCategoriesStatusPending);
  const clients = useSelector(selectClientsData);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const devices = useSelector(selectFetchDevicesData);
  const isFetchDevicesPending = useSelector(selectIsFetchDevicesStatusPending);
  const isAddToBookmarkPending = useSelector(
    selectIsAddAssetToBookmarkStatusPending
  );
  const isRemoveFromBookmarkPending = useSelector(
    selectIsRemoveAssetFromBookmarkStatusPending
  );
  const [isFavorite, setIsFavorite] = useState(false);
  const tabName = getPathnameNthParam(3)(location.pathname);
  const assets = useSelector(selectAssetsData);
  const assetClient = assetData?.client_id;
  const { search } = useLocation();
  const { clientId }: { clientId?: string } = parse(search);

  useEffect(() => {
    if (assetId) {
      dispatch(showAsset(Number(assetId)));
    }
    dispatch(fetchCategories(assetClient));
    dispatch(fetchLocations());
    dispatch(fetchTeams());
    dispatch(fetchTags(assetClient));
  }, [dispatch, assetId, assetClient]);

  useEffect(() => {
    if (assetId && assetData) {
      const bookmark = assetData.user_bookmarks.find(
        (assetBookmark) => assetBookmark.tab_name === tabName
      );

      if (bookmark) {
        return setIsFavorite(true);
      }

      setIsFavorite(false);
    }
  }, [assetId, assetData, tabName]);

  const handleOnFavorite = async () => {
    if (assetId) {
      if (!isFavorite) {
        await dispatch(
          addAssetToBookmark({ assetId: Number(assetId), tabName })
        );
        await dispatch(showAsset(Number(assetId)));
      }

      if (isFavorite) {
        await dispatch(
          removeAssetFromBookmark({ assetId: Number(assetId), tabName })
        );
        await dispatch(showAsset(Number(assetId)));
      }
    }
  };

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_ASSET_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_ASSET_MODAL }));

  const onEditAsset = (editAssetModel: CreateEditAssetModel, id?: number) =>
    id && dispatch(editAssetAction({ id, editAssetModel }));

  const onDeleteAsset = (id: number) => dispatch(deleteAssetAction(id));

  return (
    <PageHeaderContainer>
      <AssetPageHeader
        asset={assetData ?? undefined}
        assetStatus={assetData?.cached_active_status || "Off"}
        isPending={
          isAssetDataPending ||
          isAddToBookmarkPending ||
          isRemoveFromBookmarkPending
        }
        isFulfilled={isAssetDataFulfilled}
        isFavorite={isFavorite}
        onFavorite={handleOnFavorite}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        isModalPending={
          isFetchTagsPending ||
          isFetchLocationsPending ||
          isFetchTeamsPending ||
          isFetchCategoriesPending ||
          isFetchClientsPending ||
          isFetchDevicesPending
        }
        tags={tags}
        locations={locations}
        teams={teams}
        categories={categories}
        clients={clients}
        devices={devices}
        onEditAsset={onEditAsset}
        onDeleteAsset={onDeleteAsset}
        assets={assets}
      />
    </PageHeaderContainer>
  );
};
