import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { Button } from "../../app/component/Button/Button";
import { selectIsDevicesTableStatusPending } from "../store/devices.selector";
import { DeviceModal } from "../component/DeviceModal/DeviceModal";
import { AppDispatch } from "../../app/store";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_DEVICE_MODAL } from "../../app/const/modals";
import { addDevice, fetchDeviceTypes } from "../store/devices.action";
import { unwrap } from "../../app/util/unwrap-dispatch";
import { CreateEditDeviceDto, DeviceTypeDto } from "../api/devices.api.dto";
import { PartnerDto } from "../../partners/api/partners.api.dto";
import { fetchPartners } from "../../partners/store/partners.action";
import { AssetDto } from "../../asset/api/asset.api.dto";
import { fetchAssetsByClientId } from "../../asset/store/asset.action";
import { openErrorNotification } from "../../app/store/notifications/notifications.slice";

export const AddDeviceModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isDevicesPending = useSelector(selectIsDevicesTableStatusPending);
  const [isPending, setIsPending] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [partnerOptions, setPartnerOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [clientOptions, setClientOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [allClientOptions, setAllClientOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [assetOptions, setAssetOptions] = useState<
    { label: string; value: number; channels: number[] }[]
  >([]);

  const openModal = () => dispatch(openModalAction({ name: ADD_DEVICE_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_DEVICE_MODAL }));

  const handleOnSubmit = async (deviceDto: CreateEditDeviceDto) => {
    await dispatch(addDevice(deviceDto));
  };

  const handleOnEnter = async () => {
    try {
      setIsPending(true);
      const deviceTypes: DeviceTypeDto[] = await unwrap(
        dispatch(fetchDeviceTypes())
      );
      setDeviceTypeOptions(
        deviceTypes.map((device) => ({ label: device.name, value: device.id }))
      );
      const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
      setPartnerOptions(
        partners.map((partner) => ({ label: partner.name, value: partner.id }))
      );

      const allClients = partners.flatMap((partner) =>
        partner.clients.map((client) => ({
          label: client.name,
          value: client.id,
        }))
      );

      setAllClientOptions(allClients);
      setClientOptions(allClients);
      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  const handleOnPartnerChange = async (partnerId: number | null) => {
    try {
      setIsPending(true);
      const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
      const partnerById = partners.find((partner) => partner.id === partnerId);

      if (partnerById) {
        setClientOptions(
          partnerById.clients.map((client) => ({
            label: client.name,
            value: client.id,
          }))
        );
      } else {
        setClientOptions(allClientOptions);
      }

      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  const handleOnError = (message: string) =>
    dispatch(openErrorNotification(message));

  const handleOnClientChange = async (clientId: number) => {
    try {
      setIsPending(true);
      const assets: AssetDto[] = await unwrap(
        dispatch(fetchAssetsByClientId(clientId))
      );
      setAssetOptions(
        assets.map((asset) => ({
          label: asset.name,
          value: asset.id,
          channels: asset.channels.map((channel) => channel.channel_index),
        }))
      );
      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  return (
    <>
      <ConnectedShowForPermissions
        feature="devices"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isDevicesPending}
        >
          Add Device
        </Button>
      </ConnectedShowForPermissions>
      <DeviceModal
        name={ADD_DEVICE_MODAL}
        onClose={closeModal}
        onSubmit={handleOnSubmit}
        deviceTypeOptions={deviceTypeOptions}
        partnerOptions={partnerOptions}
        clientOptions={clientOptions}
        isPending={isPending}
        onEnter={handleOnEnter}
        onPartnerChange={handleOnPartnerChange}
        assetOptions={assetOptions}
        onClientChange={handleOnClientChange}
        onError={handleOnError}
      />
    </>
  );
};
