import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import { Box } from "@material-ui/core";

import { forgotPasswordFormValidationSchema } from "./ForgotPasswordForm.validation";
import { RecoverPasswordDataModel } from "../../model/Auth";
import Logo from "../../../../assets/logo.png";
import {
  StyledCard,
  StyledLink,
  StyledButton,
  StyledNotification,
  StyledNotificationText,
  StyledNotificationIcon,
} from "./ForgotPasswordForm.style";
import { LOGIN } from "../../../app/const/routes";
import { StyledLogo, StyledTextField } from "../LoginForm/LoginForm.style";

interface IForgotPasswordForm {
  onSubmit: (formValues: RecoverPasswordDataModel) => void;
  pending: boolean;
  showNotification: boolean;
}

export const ForgotPasswordForm: FC<IForgotPasswordForm> = ({
  onSubmit,
  pending,
  showNotification,
}) => {
  return (
    <StyledCard>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={forgotPasswordFormValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <StyledLogo alt="logo" src={Logo} />
            {showNotification ? (
              <StyledNotification>
                <StyledNotificationIcon />
                <StyledNotificationText>
                  We have e-mailed your password reset link.
                </StyledNotificationText>
              </StyledNotification>
            ) : null}
            <StyledTextField
              disabled={pending}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email address"
              margin="dense"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              autoComplete="off"
            />
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledLink component={RouterLink} to={LOGIN}>
                BACK TO LOGIN
              </StyledLink>
              <StyledButton
                pending={pending}
                disabled={pending}
                color="primary"
                type="submit"
                variant="contained"
              >
                Send Password Reset Link
              </StyledButton>
            </Box>
          </form>
        )}
      </Formik>
    </StyledCard>
  );
};
