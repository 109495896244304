import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createLocation } from "../store/locations.action";
import { LocationModal } from "../component/LocationModal/LocationModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditLocationModel } from "../model/locations.model";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { ADD_LOCATION_MODAL } from "../../app/const/modals";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { selectIsLocationsTablePending } from "../store/locations.selector";
import { selectProfileClientId } from "../../auth/store/auth.selector";

export const AddLocationModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const clientsData = useSelector(selectClientsData);
  const isLocatonsPending = useSelector(selectIsLocationsTablePending);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const clientId = useSelector(selectProfileClientId);

  const openModal = () =>
    dispatch(openModalAction({ name: ADD_LOCATION_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_LOCATION_MODAL }));

  const onCreateLocation = (values: CreateEditLocationModel) =>
    dispatch(createLocation(values));

  return (
    <>
      <ConnectedShowForPermissions
        feature="locations"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isLocatonsPending}
        >
          Add Location
        </Button>
      </ConnectedShowForPermissions>
      <LocationModal
        name={ADD_LOCATION_MODAL}
        onClose={closeModal}
        onSubmit={onCreateLocation}
        clients={clientsData}
        isPending={isFetchClientsPending}
        clientId={clientId}
      />
    </>
  );
};
