import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectIsShowAssetStatusPending,
  selectShowAssetData,
} from "../store/asset.selector";
import { AssetInfo } from "../component/AssetInfo/AssetInfo";
import { AppDispatch } from "../../app/store";
import {
  fetchAssetsByClientId,
  fetchAssetSensors,
  fetchSensors,
  showAsset,
} from "../store/asset.action";
import { useInterval } from "../../app/hooks/useInterval";
import { EDIT_DEVICE_MODAL } from "../../app/const/modals";
import { DeviceModal } from "../../devices/component/DeviceModal/DeviceModal";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import {
  CreateEditDeviceDto,
  DeviceListDto,
  DeviceTypeDto,
} from "../../devices/api/devices.api.dto";
import {
  deleteDevice,
  editDevice,
  fetchDeviceTypes,
} from "../../devices/store/devices.action";
import { unwrap } from "../../app/util/unwrap-dispatch";
import { PartnerDto } from "../../partners/api/partners.api.dto";
import { fetchPartners } from "../../partners/store/partners.action";
import { DeviceListModel } from "../../devices/model/devices.model";
import { AssetDto } from "../api/asset.api.dto";
import { openErrorNotification } from "../../app/store/notifications/notifications.slice";
import { ClientDto } from "../../clients/api/clients.api.dto";
import { fetchClients } from "../../clients/store/clients.action";

export const AssetInfoContainer: FC = () => {
  const assetData = useSelector(selectShowAssetData);
  const isAssetDataPending = useSelector(selectIsShowAssetStatusPending);
  const [showLoader, setShowLoader] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isOnMountPending, setIsOnMountPending] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [partnerOptions, setPartnerOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [clientOptions, setClientOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [assetOptions, setAssetOptions] = useState<
    { label: string; value: number; channels: number[] }[]
  >([]);

  const dispatch = useDispatch<AppDispatch>();
  const { assetId } = useParams<{ assetId: string }>();

  useInterval(() => {
    if (assetId) {
      dispatch(showAsset(Number(assetId)));
    }
    if (showLoader) {
      setShowLoader(false);
    }
  });

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_DEVICE_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_DEVICE_MODAL }));

  const handleOnEdit = async (
    editDeviceDto: CreateEditDeviceDto,
    id?: number
  ) => {
    if (assetId && id) {
      await dispatch(editDevice({ id, editDeviceDto }));
      await dispatch(showAsset(Number(assetId)));
      await dispatch(fetchAssetSensors(Number(assetId)));
      await dispatch(fetchSensors(Number(assetId)));
    }
  };

  const handleOnDelete = (id: number) => dispatch(deleteDevice(id));

  const handleOnEnter = async () => {
    try {
      setIsPending(true);
      const deviceTypes: DeviceTypeDto[] = await unwrap(
        dispatch(fetchDeviceTypes())
      );
      setDeviceTypeOptions(
        deviceTypes.map((device) => ({ label: device.name, value: device.id }))
      );
      const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
      setPartnerOptions(
        partners.map((partner) => ({ label: partner.name, value: partner.id }))
      );

      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  const handleOnPartnerChange = async (partnerId: number | null) => {
    try {
      setIsPending(true);
      const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
      const partnerById = partners.find((partner) => partner.id === partnerId);

      if (partnerById) {
        setClientOptions(
          partnerById.clients.map((client) => ({
            label: client.name,
            value: client.id,
          }))
        );
      }

      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  const onModalMount = useCallback(
    async (device: DeviceListModel | DeviceListDto) => {
      try {
        setIsOnMountPending(true);
        const partners: PartnerDto[] = await unwrap(dispatch(fetchPartners()));
        const partnerById = partners.find(
          (partner) => partner.id === device.partner_id
        );

        if (partnerById) {
          setClientOptions(
            partnerById.clients.map((client) => ({
              label: client.name,
              value: client.id,
            }))
          );
        } else {
          const clients: ClientDto[] = await unwrap(dispatch(fetchClients()));
          const allClients = clients.map((client) => ({
            label: client.name,
            value: client.id,
          }));
          setClientOptions(allClients);
        }

        if (device.client_id) {
          const assets: AssetDto[] = await unwrap(
            dispatch(fetchAssetsByClientId(device.client_id))
          );
          setAssetOptions(
            assets.map((asset) => ({
              label: asset.name,
              value: asset.id,
              channels: asset.channels.map((channel) => channel.channel_index),
            }))
          );
        }

        setIsOnMountPending(false);
      } catch (e) {
        setIsOnMountPending(false);
      }
    },
    [dispatch]
  );

  const handleOnError = (message: string) =>
    dispatch(openErrorNotification(message));

  const handleOnClientChange = async (clientId: number) => {
    try {
      setIsPending(true);
      const assets: AssetDto[] = await unwrap(
        dispatch(fetchAssetsByClientId(clientId))
      );
      setAssetOptions(
        assets.map((asset) => ({
          label: asset.name,
          value: asset.id,
          channels: asset.channels.map((channel) => channel.channel_index),
        }))
      );
      setIsPending(false);
    } catch (e) {
      setIsPending(false);
    }
  };

  return (
    <>
      <AssetInfo
        data={assetData}
        pending={isAssetDataPending && showLoader}
        onOpenModal={onOpenModal}
      />
      <DeviceModal
        name={EDIT_DEVICE_MODAL}
        onClose={onCloseModal}
        onSubmit={handleOnEdit}
        device={assetData?.last_received_device}
        deviceTypeOptions={deviceTypeOptions}
        partnerOptions={partnerOptions}
        clientOptions={clientOptions}
        isPending={isPending || isOnMountPending}
        onEnter={handleOnEnter}
        onPartnerChange={handleOnPartnerChange}
        assetOptions={assetOptions}
        onClientChange={handleOnClientChange}
        onError={handleOnError}
        onModalMount={onModalMount}
        onDelete={handleOnDelete}
      />
    </>
  );
};
