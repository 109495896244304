import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchApiNotifications as fetchApiNotificationsRequest,
  markNotificationRead as makeNotificationAsReadRequest,
  markNotificationsReadUntil as markNotificationsReadUntilRequest,
} from "../api/apiNotifications.api";
import withError from "../../app/util/with-thunk-error";
import { NotificationDto } from "../api/apiNotifications.api.dto";

export const fetchApiNotifications = createAsyncThunk(
  "apiNotifications/fetchNotifications",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (arg: undefined): Promise<NotificationDto[]> =>
      fetchApiNotificationsRequest()
  )
);

export const makeNotificationRead = createAsyncThunk(
  "apiNotifications/makeNotificationRead",
  withError(
    async (
      notification: NotificationDto,
      { dispatch }
    ): Promise<NotificationDto[]> => {
      return makeNotificationAsReadRequest(notification);
    }
  )
);

export const makeNotificationsReadUntil = createAsyncThunk(
  "apiNotifications/makeNotificationsReadUntil",
  withError(
    async (timestamp: Date): Promise<NotificationDto[]> => {
      return markNotificationsReadUntilRequest(timestamp);
    }
  )
);
