import React, { FC, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Avatar,
  Box,
  createStyles,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import styled from "styled-components";
import { selectProfileData } from "../../../../auth/store/auth.selector";
import { logout } from "../../../../auth/store/auth.action";
import { Logo } from "../Logo/Logo";
import { DASHBOARD, PROFILE, TAKO_CONTACT } from "../../../const/routes";
import { DRAWER_WIDTH } from "../../../const/drawer";
import {
  TEXT_PRIMARY,
  AVATAR_BACKGROUND,
  AVATAR_TEXT,
  BACKGROUND_DEFAULT,
} from "../../../theme/colors";
import { ApiNotificationsContainer } from "../../../../apiNotifications/container/ApiNotificationsContainer";

interface IConnectedTopBar {
  isNavOpen: boolean;
  onNavOpen: () => void;
  onNavClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 15,
    },
    hide: {
      display: "none",
    },
    small: {
      width: "32px",
      height: "32px",
      fontSize: "12px",
      color: AVATAR_TEXT,
      backgroundColor: AVATAR_BACKGROUND,
      fontWeight: "bold",
    },
    inline: {
      display: "inline",
      fontSize: "14px",
    },
    icon: {
      color: "#1f1e4d",
    },
    listItemAvatar: {
      minWidth: "45px",
    },
    divider: {
      height: "45px !important",
      alignSelf: "center",
      marginLeft: "10px",
      marginRight: "-10px",
      backgroundColor: "#d7d7d7",
      width: "1.6px",
    },
  })
);

const StyledAvatar = styled(Avatar)`
  background-color: ${BACKGROUND_DEFAULT};
  color: ${TEXT_PRIMARY};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
`;

export const ConnectedTopBar: FC<IConnectedTopBar> = ({
  isNavOpen,
  onNavClose,
  onNavOpen,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const classes = useStyles();
  const profileData = useSelector(selectProfileData);
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      elevation={2}
      {...rest}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isNavOpen,
      })}
    >
      <Toolbar>
        <div>
          <IconButton
            color="inherit"
            onClick={isNavOpen ? onNavClose : onNavOpen}
            edge="start"
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </div>
        <RouterLink to={DASHBOARD}>
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Box ml={2}>
          <ApiNotificationsContainer />
        </Box>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <Box ml={2}>
          <IconButton
            onClick={() => {
              history.push(PROFILE);
            }}
          >
            <SettingsOutlinedIcon className={classes.icon} />
          </IconButton>
        </Box>
        <Box ml={2}>
          <RouterLink to={{ pathname: TAKO_CONTACT }} target="_blank">
            <HelpOutlineOutlinedIcon className={classes.icon} />
          </RouterLink>
        </Box>
        <List disablePadding>
          <ListItem>
            <RouterLink to={DASHBOARD}>
              <ListItemAvatar className={classes.listItemAvatar}>
                {profileData &&
                profileData.user.photo_url &&
                !profileData.user.photo_url.includes("gravatar") ? (
                  <Avatar
                    className={classes.small}
                    src={profileData.user.photo_url}
                  />
                ) : (
                  <StyledAvatar className={classes.small}>
                    {profileData?.user.first_name.charAt(0)}
                    {profileData?.user.last_name.charAt(0)}
                  </StyledAvatar>
                )}
              </ListItemAvatar>
            </RouterLink>
            <ListItemText
              primary={profileData?.name}
              primaryTypographyProps={{ variant: "subtitle1" }}
              onClick={handleClick}
              style={{ cursor: "pointer" }}
              secondary={
                <Typography
                  component="span"
                  variant="subtitle2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {profileData?.client?.name
                    ? `${profileData?.client?.name}`
                    : null}
                </Typography>
              }
            />
            <Menu
              id="context-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openContextMenu}
              onClose={handleClose}
            >
              <MenuItem key="logout" onClick={handleLogout}>
                <Typography>Logout</Typography>
              </MenuItem>
            </Menu>
          </ListItem>
        </List>
      </Toolbar>
    </AppBar>
  );
};
