import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { fetchClients as fetchClientsAction } from "../../clients/store/clients.action";
import {
  editLocation as editLocationAction,
  deleteLocation as deleteLocationAction,
  fetchLocations as fetchLocationsAction,
} from "../store/locations.action";
import {
  selectIsLocationsTablePending,
  selectLocationsTableData,
} from "../store/locations.selector";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { clearLocationsState as clearLocationsStateAction } from "../store/locations.slice";
import { LocationsTable } from "../component/LocationsTable/LocationsTable";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { CreateEditLocationModel } from "../model/locations.model";
import { EDIT_LOCATION_MODAL } from "../../app/const/modals";

export const LocationsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const locationsTableData = useSelector(selectLocationsTableData);
  const isLocationsPending = useSelector(selectIsLocationsTablePending);
  const clientsTableData = useSelector(selectClientsData);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_LOCATION_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_LOCATION_MODAL }));

  const onEditLocation = (
    editLocationModel: CreateEditLocationModel,
    id?: number
  ) => id && dispatch(editLocationAction({ id, editLocationModel }));

  const onDeleteLocation = (id: number) => dispatch(deleteLocationAction(id));

  useEffect(() => {
    dispatch(fetchLocationsAction());
    dispatch(fetchClientsAction());

    return () => {
      dispatch(clearLocationsStateAction());
    };
  }, [dispatch]);

  return (
    <LocationsTable
      locations={locationsTableData}
      clients={clientsTableData}
      isTablePending={isLocationsPending}
      isModalPending={isFetchClientsPending}
      onEditLocation={onEditLocation}
      onDeleteLocation={onDeleteLocation}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
