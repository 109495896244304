import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { RoleDto } from "../api/roles.api.dto";

export interface RolesState {
  fetchRoles: AsyncStateModel<RoleDto[]>;
  deleteRole: AsyncStateModel<null>;
  editRole: AsyncStateModel<null>;
  createRole: AsyncStateModel<null>;
  searchRoles: string;
}

export const rolesState: RolesState = {
  fetchRoles: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteRole: {
    status: AsyncStatus.Void,
    data: null,
  },
  editRole: {
    status: AsyncStatus.Void,
    data: null,
  },
  createRole: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchRoles: "",
};
