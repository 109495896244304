import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { SensorTypesState } from "./sensorTypes.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  SensorTypeDto,
  HardcodedSensorType,
  HardcodedSensorUnit,
} from "../api/sensorTypes.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectSensorTypesRootState = createSelector(
  selectSelf,
  (state): SensorTypesState => state.sensorTypes
);

export const selectSensorTypesState = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): AsyncStateModel<SensorTypeDto[]> =>
    sensorTypes.fetchSensorTypes
);

export const selectSensorTypesData = createSelector(
  selectSensorTypesState,
  (sensorTypes): SensorTypeDto[] => sensorTypes.data
);

export const selectSensorTypesStatus = createSelector(
  selectSensorTypesState,
  (sensorTypes): AsyncStatus => sensorTypes.status
);

export const selectIsSensorTypesStatusPending = createSelector(
  selectSensorTypesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchSensorTypes = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): string => sensorTypes.searchSensorTypes
);

export const selectSensorTypesTableData = createSelector(
  selectSearchSensorTypes,
  selectSensorTypesData,
  (searchValue, sensorTypes): SensorTypeDto[] => {
    const data = sensorTypes.map((sensorType) => sensorType);

    if (searchValue) {
      return data.filter(
        (sensorType) =>
          sensorType.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          sensorType.range_min
            .toString()
            .toLowerCase()
            .includes(searchValue.toString().toLowerCase()) ||
          sensorType.range_max
            .toString()
            .toLowerCase()
            .includes(searchValue.toString().toLowerCase()) ||
          sensorType.unit_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          sensorType.in_use_count.toString().includes(searchValue.toString()) ||
          sensorType.manufacturer
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          sensorType.oem_part_number
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          sensorType.part_number
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsSensorTypesTableStatusPending = createSelector(
  selectSensorTypesStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCreateSensorTypeState = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): AsyncStateModel<null> => sensorTypes.createSensorType
);

export const selectCreateSensorTypeStatus = createSelector(
  selectCreateSensorTypeState,
  (createSensorType): AsyncStatus => createSensorType.status
);

export const selectIsCreateSensorTypeStatusPending = createSelector(
  selectCreateSensorTypeStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditSensorTypeState = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): AsyncStateModel<null> => sensorTypes.editSensorType
);

export const selectEditSensorTypeStatus = createSelector(
  selectEditSensorTypeState,
  (editSensorType): AsyncStatus => editSensorType.status
);

export const selectIsEditSensorTypeStatusPending = createSelector(
  selectEditSensorTypeStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteSensorTypeState = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): AsyncStateModel<null> => sensorTypes.deleteSensorType
);

export const selectDeleteSensorTypeStatus = createSelector(
  selectDeleteSensorTypeState,
  (deleteSensorType): AsyncStatus => deleteSensorType.status
);

export const selectIsDeleteSensorTypeStatusPending = createSelector(
  selectDeleteSensorTypeStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectHardcodedSensorTypesState = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): AsyncStateModel<HardcodedSensorType[]> =>
    sensorTypes.fetchHardcodedSensorTypes
);

export const selectHardcodedSensorTypesData = createSelector(
  selectHardcodedSensorTypesState,
  (sensorTypes): HardcodedSensorType[] => sensorTypes.data
);

export const selectHardcodedSensorTypesStatus = createSelector(
  selectHardcodedSensorTypesState,
  (sensorTypes): AsyncStatus => sensorTypes.status
);

export const selectIsHardcodedSensorTypesStatusPending = createSelector(
  selectHardcodedSensorTypesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectHardcodedSensorUnitsState = createSelector(
  selectSensorTypesRootState,
  (sensorTypes): AsyncStateModel<HardcodedSensorUnit[]> =>
    sensorTypes.fetchHardcodedSensorUnits
);

export const selectHardcodedSensorUnitsData = createSelector(
  selectHardcodedSensorUnitsState,
  (sensorUnits): HardcodedSensorUnit[] => sensorUnits.data
);

export const selectHardcodedSensorUnitsStatus = createSelector(
  selectHardcodedSensorUnitsState,
  (sensorUnits): AsyncStatus => sensorUnits.status
);

export const selectIsHardcodedSensorUnitsStatusPending = createSelector(
  selectHardcodedSensorUnitsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
