import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";

import { ADD_ASSET_NOTE_MODAL } from "../../app/const/modals";
import { AddAssetNoteModal } from "../component/AddAssetNoteModal/AddAssetNoteModal";
import { addAssetNote, fetchAssetNotes } from "../store/asset.action";

export const AddAssetNoteModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ assetId: string }>();

  const openModal = () =>
    dispatch(openModalAction({ name: ADD_ASSET_NOTE_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_ASSET_NOTE_MODAL }));

  const handleOnSubmit = async (text: string): Promise<void> => {
    await dispatch(addAssetNote({ assetId: Number(params.assetId), text }));
    await dispatch(fetchAssetNotes(Number(params.assetId)));
  };

  return (
    <AddAssetNoteModal
      name={ADD_ASSET_NOTE_MODAL}
      onClose={closeModal}
      onOpen={openModal}
      onSubmit={handleOnSubmit}
    />
  );
};
