import { Tabs } from "@material-ui/core";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  .MuiTab-root {
    flex-grow: 1;
  }
`;
