import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";

import { StyledPage } from "../../../app/component/DashboardLayout/DashboardLayout.style";
import { ResetPasswordSuccess } from "../../component/ResetPasswordSuccess/ResetPasswordSuccess";

export const ResetPasswordSuccessView: FC = () => (
  <StyledPage title="Reset Password Success">
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <ResetPasswordSuccess />
      </Container>
    </Box>
  </StyledPage>
);
