import * as yup from "yup";

export const DESCRIPTION_CHAR_LIMIT = 500;
export const SPEC_SHEET_MIME_TYPES = [
  "application/pdf",
  "image/png",
  "image/jpg",
  "image/jpeg",
];

export const sensorTypeModalFormValidation = yup.object().shape({
  manufacturer: yup.string().required("Manufacturer is required"),
  oem_part_number: yup.string().optional(),
  part_number: yup.string().required("Part Number is required"),
  sensor_type_index: yup.number().required("Sensor Type is required"),
  range_min: yup.number().optional(),
  range_max: yup.number().optional(),
  unit_type: yup.number().typeError("Unit is required").optional(),
  settling_time: yup.number().min(0).required("Settling Time is required"),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  spec_sheet: yup
    .mixed()
    .test(
      "spec_sheet-type",
      "Spec Sheet can only be a PDF or an image",
      (value: File) =>
        value === undefined ||
        value === null ||
        SPEC_SHEET_MIME_TYPES.includes(value.type)
    )
    .optional(),
});
