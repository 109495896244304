import styled from "styled-components";
import {
  IconButton,
  IconButtonProps,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";

import { Button, IButton } from "../../Button/Button";

export const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
  fieldset {
    max-height: 40px;
    top: 0px;
  }
`;

export const TableToolbarSearchButton = styled(Button)<IButton>`
  padding: 8px 18px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const TableToolbarSearchInput = styled(TextField)<TextFieldProps>`
  max-width: 250px;
  min-width: 250px;

  .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 50px; // Don't overlap icon
    background-color: #f1f3f4;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    min-width: 100px;
  }

  .Mui-focused {
    svg {
      color: ${({ theme }) => `${theme.palette.primary.main}`};
    }
  }
`;

export const StyledIconButton = styled(IconButton)<IconButtonProps>`
  padding: 6px;
  margin-right: -12px;
`;

export const StyledClearIcon = styled(Clear)`
  font-size: 24px;
  color: #606060;
`;

export const StyledSearchIcon = styled(Search)`
  font-size: 24px;
  margin-right: -6px;
  color: #606060;
`;
