import { createSlice } from "@reduxjs/toolkit";

import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { authState } from "./auth.store";
import {
  authorize,
  changePassword,
  fetchProfile,
  forgotPassword,
  login,
  logout,
  recoverPassword,
  register,
  resetPassword,
} from "./auth.action";

const slice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => ({
      ...state,
      register: {
        ...state.register,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(register.fulfilled, (state) => ({
      ...state,
      register: {
        ...state.register,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(register.rejected, (state) => ({
      ...state,
      register: {
        ...state.register,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(recoverPassword.pending, (state) => ({
      ...state,
      recoverPassword: {
        ...state.recoverPassword,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(recoverPassword.fulfilled, (state) => ({
      ...state,
      recoverPassword: {
        ...state.recoverPassword,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(recoverPassword.rejected, (state) => ({
      ...state,
      recoverPassword: {
        ...state.recoverPassword,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(resetPassword.pending, (state) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(resetPassword.fulfilled, (state) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(resetPassword.rejected, (state) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(changePassword.pending, (state) => ({
      ...state,
      changePassword: {
        ...state.changePassword,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(changePassword.fulfilled, (state) => ({
      ...state,
      changePassword: {
        ...state.changePassword,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(changePassword.rejected, (state) => ({
      ...state,
      changePassword: {
        ...state.changePassword,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(login.pending, (state) => ({
      ...state,
      login: {
        ...state.login,
        status: AsyncStatus.Pending,
      },
      logout: {
        ...state.logout,
        status: AsyncStatus.Void,
      },
    }));
    builder.addCase(login.fulfilled, (state) => ({
      ...state,
      login: {
        ...state.login,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(login.rejected, (state) => ({
      ...state,
      login: {
        ...state.login,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchProfile.pending, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          profile: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchProfile.rejected, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(authorize.pending, (state) => ({
      ...state,
      authorization: {
        ...state.authorization,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(authorize.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          profile: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
          authorization: {
            status: AsyncStatus.Fulfilled,
            data: true,
          },
        };
      }

      return state;
    });
    builder.addCase(authorize.rejected, (state) => ({
      ...state,
      authorization: {
        status: AsyncStatus.Rejected,
        data: false,
      },
    }));

    builder.addCase(forgotPassword.pending, (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(forgotPassword.fulfilled, (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(forgotPassword.rejected, (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(logout.pending, (state) => ({
      ...state,
      logout: { ...state.logout, status: AsyncStatus.Pending },
    }));
    builder.addCase(logout.fulfilled, (state) => ({
      ...authState,
      logout: { ...authState.logout, status: AsyncStatus.Fulfilled },
    }));
  },
});

export default slice.reducer;
