import React, { FC, useEffect, useState } from "react";
import { Channel, ChannelsWrapper } from "./ChannelsSelector.style";

interface IChannelsSelector {
  value: number[] | undefined;
  channels: number[];
  disabledChannels: number[];
  onChange: (channels: number[]) => void;
  disabled: boolean;
}

export const ChannelsSelector: FC<IChannelsSelector> = ({
  channels,
  value,
  disabledChannels,
  onChange,
  disabled,
}) => {
  const [selectedChannels, setSelectedChannels] = useState<number[]>([]);

  useEffect(() => {
    if (value) {
      setSelectedChannels(value);
    }
  }, [value]);

  const handleOnChange = (channel: number) => {
    const selected = selectedChannels.find(
      (selectedChannel) => selectedChannel === channel
    );

    if (selected !== undefined && String(selected)) {
      setSelectedChannels([
        ...selectedChannels.filter(
          (selectedChannel) => selectedChannel !== channel
        ),
      ]);

      return onChange([
        ...selectedChannels.filter(
          (selectedChannel) => selectedChannel !== channel
        ),
      ]);
    }

    setSelectedChannels([...selectedChannels, channel]);
    onChange([...selectedChannels, channel]);
  };

  const checkIfIsSelected = (channelNumber: number) => {
    if (value) {
      const isSelected = value.find(
        (singleValue) => singleValue === channelNumber
      );

      return isSelected === 0 ? true : Boolean(isSelected);
    }

    return false;
  };

  const checkIfIsDisabled = (channelNumber: number) => {
    const isDisabled = disabledChannels.find(
      (disabledChannel) => disabledChannel === channelNumber
    );

    return isDisabled === 0 ? true : Boolean(isDisabled);
  };

  return (
    <ChannelsWrapper>
      {channels.map((channelNumber, index) => (
        <Channel
          key={index}
          onClick={() =>
            !disabled &&
            !checkIfIsDisabled(channelNumber) &&
            handleOnChange(channelNumber)
          }
          selected={checkIfIsSelected(channelNumber)}
          disabled={disabled || checkIfIsDisabled(channelNumber)}
        >
          {channelNumber + 1}
        </Channel>
      ))}
    </ChannelsWrapper>
  );
};
