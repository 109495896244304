import React, { FC } from "react";
import {
  FullHeightContainer,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { AssetAnalyticsContainer } from "../../container/AssetAnalyticsContainer";

export const AssetAnalyticsView: FC = () => (
  <StyledPage minHeight title="Analytics">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Analytics" />
      </PageHeaderContainer>
      <AssetAnalyticsContainer />
    </FullHeightContainer>
  </StyledPage>
);
