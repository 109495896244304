import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { TeamsTable } from "../component/TeamsTable/TeamsTable";
import {
  editTeam as editTeamAction,
  deleteTeam as deleteTeamAction,
  fetchTeams as fetchTeamsAction,
} from "../store/teams.action";
import {
  selectTeamsTableData,
  selectIsTeamsTableStatusPending,
} from "../store/teams.selector";
import { clearFetchTeamsState as clearFetchTeamsStateAction } from "../store/teams.slice";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditTeamDto } from "../api/teams.api.dto";
import { fetchClients as fetchClientsAction } from "../../clients/store/clients.action";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { EDIT_TEAM_MODAL } from "../../app/const/modals";

export const TeamsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const teamsTableData = useSelector(selectTeamsTableData);
  const isTeamsPending = useSelector(selectIsTeamsTableStatusPending);
  const clientsData = useSelector(selectClientsData);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_TEAM_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_TEAM_MODAL }));

  const onEditTeam = (editTeamDto: CreateEditTeamDto, id?: number) =>
    id && dispatch(editTeamAction({ id, editTeamDto }));

  const onDeleteTeam = (id: number) => dispatch(deleteTeamAction(id));

  useEffect(() => {
    dispatch(fetchClientsAction());
    dispatch(fetchTeamsAction());

    return () => {
      dispatch(clearFetchTeamsStateAction());
    };
  }, [dispatch]);

  return (
    <TeamsTable
      teams={teamsTableData.map((team: any) => ({
        ...team,
        clientName: team.client ? team.client.name : "",
      }))}
      clients={clientsData}
      isTablePending={isTeamsPending}
      isModalPending={isFetchClientsPending}
      onEditTeam={onEditTeam}
      onDeleteTeam={onDeleteTeam}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
