import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

import { Breadcrumbs, IBreadcrumb } from "../../Breadcrumbs/Breadcrumbs";

interface IPageHeader {
  title: string;
  breadcrumbs?: IBreadcrumb[];
}

export const PageHeaderContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const PageHeading = styled(Typography)`
  margin: ${({ theme }) => `${theme.spacing(0, 2, 1, 0)}`};
`;

export const PageHeader: FC<IPageHeader> = ({ title, breadcrumbs }) => (
  <div>
    <Breadcrumbs breadcrumbs={breadcrumbs || []} />
    <PageHeading variant="h1">{title}</PageHeading>
  </div>
);
