import styled from "styled-components";
import { ConnectedTabs } from "../../../app/component/ConnectedTabs/ConnectedTabs";

export const StyledConnectedTabs = styled(ConnectedTabs)`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 0 ${({ theme }) => theme.spacing(3)}px;

  .MuiTab-root {
    padding: ${({ theme }) => theme.spacing(2)}px 12px;
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
`;
