import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: center;
  background-color: #ffffff;
  border-right: 0.5px solid #e3e3e9;

  svg {
    width: 70px;
    height: 70px;
  }
`;

export const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AssetName = styled.span`
  font-weight: 500;
`;

export const TabName = styled.span`
  text-transform: capitalize;
`;
