import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { FC, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GetAppRounded as GetAppRoundedIcon,
  MoreHoriz as MoreHorizIcon,
  PrintRounded as PrintRoundedIcon,
} from "@material-ui/icons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import {
  StyledCloseIcon,
  StyledDialog,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import {
  SubTaskFormModel,
  TaskCommentDto,
  TaskDto,
  TaskPriority,
  TaskStatus,
  TaskType,
} from "../../api/workorders.api.dto";
import {
  Content,
  DropzoneContainer,
  Event,
  ManualIcon,
  PickerWrapper,
  Sidebar,
  StyledAutocompleteField,
  StyledAvatar,
  StyledContentContainer,
  StyledMenuIconsContainer,
  StyledRow,
  StyledSelectField,
  StyledSidebarContainer,
  StyledTextField,
  StyledTitleContainer,
  StyledTypography,
  StyledWatchersAvatar,
  TypeSelect,
} from "./TaskModalForm.style";
import { AssetDto } from "../../../asset/api/asset.api.dto";
import { UserDto } from "../../../users/api/users.api.dto";
import { DatePicker } from "../../../app/component/DatePicker/DatePicker";
import { TaskModalCommentsForm } from "./TaskModalCommentsForm";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { AssetBox } from "../AssetBox/AssetBox";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { SubTaskForm } from "../SubTaskForm/SubTaskForm";
import { IUserTeamOption } from "../../model/workorders.model";
import { ConnectedHideForRoleTask } from "../../../app/component/UserManagement/ConnectedHideForRoleTask";
import { ConnectedHideForRoleSubTask } from "../../../app/component/UserManagement/ConnectedHideForRoleSubTask";
import { createTaskValidation, DOC_MIME_TYPES } from "./createTask.validation";
import { ReactComponent as HighestIcon } from "../../../../assets/icons/wo-highest.svg";
import { ReactComponent as LowIcon } from "../../../../assets/icons/wo-low.svg";
import { ReactComponent as LowestIcon } from "../../../../assets/icons/wo-lowest.svg";
import { ReactComponent as MediumIcon } from "../../../../assets/icons/wo-medium.svg";
import { ReactComponent as HighIcon } from "../../../../assets/icons/wo-high.svg";
import { ReactComponent as TaskIcon } from "../../../../assets/icons/wo-task.svg";
import { setMaxTextLength } from "../../../app/util/max-text-length";
import { DATE_AND_HOURS } from "../../../app/const/date-format";
import { selectProfileData } from "../../../auth/store/auth.selector";
import {
  GreyButton,
  RedButton,
} from "../../../asset/component/Analytics/AnalyticsSelectorsConfirmationModal.style";
import { UserRole } from "../../../app/enum/UserRole";
import { useShowForRole } from "../../../app/hooks/useShowForRole";

const taskStatus: {
  name: string;
  value: TaskStatus;
  icon: React.ReactElement;
}[] = [
  {
    name: "Closed",
    value: TaskStatus.Done,
    icon: <TaskIcon />,
  },
  {
    name: "In Progress",
    value: TaskStatus.InProgress,
    icon: <TaskIcon />,
  },
  {
    name: "Open",
    value: TaskStatus.Open,
    icon: <TaskIcon />,
  },
];

const taskPriority: {
  name: string;
  value: TaskPriority;
  icon: React.ReactElement;
}[] = [
  {
    name: "Highest",
    value: TaskPriority.Highest,
    icon: <HighestIcon />,
  },
  {
    name: "High",
    value: TaskPriority.High,
    icon: <HighIcon />,
  },
  {
    name: "Medium",
    value: TaskPriority.Medium,
    icon: <MediumIcon />,
  },
  {
    name: "Low",
    value: TaskPriority.Low,
    icon: <LowIcon />,
  },
  {
    name: "Lowest",
    value: TaskPriority.Lowest,
    icon: <LowestIcon />,
  },
];

export interface ITaskForm {
  id?: TaskDto["id"];
  name: TaskDto["name"];
  description: TaskDto["description"];
  type?: TaskDto["type"];
  start_date: any;
  finish_date: any;
  startingStatus: TaskStatus;
  status: TaskDto["status"];
  priority: TaskDto["priority"];
  asset?: AssetDto;
  assigned_user: IUserTeamOption | null;
  assigned_team: IUserTeamOption | null;
  watchers: IUserTeamOption[];
  comments: TaskDto["comments"];
  created_by?: TaskDto["created_by"];
  po_reference: TaskDto["po_reference"];
  sub_tasks: SubTaskFormModel[];
  work_order_number?: TaskDto["work_order_number"];
  file?: File;
}

interface ITaskModelForm {
  type?: TaskType;
  task?: TaskDto;
  isPending?: boolean;
  onClose: () => void;
  onSubmit: (task: ITaskForm, start?: TaskStatus, end?: TaskStatus) => void;
  onDelete?: () => void;
  onCommentAdd: (taskCommentDto: TaskCommentDto) => Promise<any>;
  onCommentDelete: (taskCommentDto: TaskCommentDto) => Promise<any>;
  currentUser: UserDto;
  teams: TeamDto[];
  users: UserDto[];
  assets: AssetDto[];
  selectedAssetId?: number;
  client: ClientDto[];
}

export const TaskModelForm: FC<ITaskModelForm> = ({
  type,
  task,
  onClose,
  isPending,
  onSubmit,
  onDelete,
  currentUser,
  onCommentAdd,
  onCommentDelete,
  teams,
  users,
  assets,
  selectedAssetId,
  client,
}) => {
  const [isNewSubTaskFormShown, setIsNewSubTaskFormShown] = useState(false);
  const [addedNewTask, setAddedNewTask] = useState(false);
  const profileData = useSelector(selectProfileData);

  const [uploadedFileUrl, setUploadedFileUrl] = useState<
    string | null | undefined
  >(task?.file?.url);
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    undefined
  );
  const formRef: any = useRef(null);

  const isMember = useShowForRole([UserRole.Member]);
  const [isRestricted, setIsRestricted] = useState(false);
  const [clickedOut, setClickedOut] = useState(false);
  const [
    deleteConfirmationDialogOpen,
    setDeleteConfirmationDialogOpen,
  ] = useState(false);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (task?.assigned_user?.id !== profileData?.user?.id) {
      setIsRestricted(isMember);
    }
  }, [isMember, profileData, task]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        formRef.current &&
        !formRef.current?.contains(e.target) &&
        e.target.outerHTML.length > 10000
      ) {
        setClickedOut(true);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);
  const usersOptions: IUserTeamOption[] = users.map((user) => ({
    name: user.full_name,
    id: user.id,
    photo: !user.photo_url.includes("gravatar") ? user.photo_url : "",
    isUser: true,
  }));

  const teamsOptions: IUserTeamOption[] = teams.map((team) => ({
    name: team.name,
    id: team.id,
    photo: "",
    isUser: false,
  }));

  const usersTeamsOptions = [...usersOptions, ...teamsOptions];

  const getInitialAssignedUser = () =>
    task?.assigned_user
      ? {
          name: task.assigned_user.full_name,
          id: task.assigned_user.id,
          photo: !task.assigned_user.photo_url.includes("gravatar")
            ? task.assigned_user.photo_url
            : "",
          isUser: true,
        }
      : null;

  const getInitialAssignedTeam = () =>
    task?.assigned_team
      ? {
          name: task.assigned_team.name,
          id: task.assigned_team.id,
          photo: "",
          isUser: false,
        }
      : null;

  const getInitialWatchers = () => {
    const teamsWatchers =
      task?.watchers_teams.map((teamWatcher) => ({
        name: teamWatcher.name,
        id: teamWatcher.id,
        photo: "",
        isUser: false,
      })) || [];

    const usersWatchers =
      task?.watchers_users.map((userWatcher) => ({
        name: userWatcher.first_name,
        id: userWatcher.id,
        photo: !userWatcher.photo_url.includes("gravatar")
          ? userWatcher.photo_url
          : "",
        isUser: true,
      })) || [];

    return [...usersWatchers, ...teamsWatchers];
  };

  const getInitialSubTasks = () =>
    task?.sub_tasks.map((subtask) => ({
      ...subtask,
      assigned_team: subtask?.assigned_team
        ? {
            name: subtask.assigned_team.name,
            id: subtask.assigned_team.id,
            photo: "",
            isUser: false,
          }
        : null,
      assigned_user: subtask?.assigned_user
        ? {
            name: subtask.assigned_user.full_name,
            id: subtask.assigned_user.id,
            photo: "",
            isUser: true,
          }
        : null,
    })) || [];

  const getInitialAsset = () => {
    if (selectedAssetId !== undefined) {
      return assets.find((asset) => asset.id === selectedAssetId);
    }
    if (task?.asset.length) {
      return task.asset[0];
    }
    return undefined;
  };

  const formik = useFormik<ITaskForm>({
    enableReinitialize: true,
    initialValues: {
      name: task?.name || "",
      description: task?.description || "",
      type: TaskType.Manual,
      startingStatus: task?.status ?? TaskStatus.Open,
      status: task?.status ?? TaskStatus.Open,
      start_date: task?.start_date ? moment(task.start_date) : null,
      finish_date:
        task?.finish_date && moment(task.finish_date).isValid()
          ? moment(task.finish_date)
          : null,
      priority: task?.priority || TaskPriority.Medium,
      asset: getInitialAsset(),
      assigned_user: getInitialAssignedUser(),
      assigned_team: getInitialAssignedTeam(),
      watchers: getInitialWatchers(),
      comments: task?.comments ?? [],
      created_by: task?.created_by || currentUser,
      po_reference: task?.po_reference || "",
      sub_tasks: getInitialSubTasks(),
      work_order_number: task?.work_order_number || "",
      file: undefined as File | undefined,
    },
    validationSchema: createTaskValidation,
    onSubmit: (values) => {
      setAddedNewTask(false);
      onSubmit(values, formik.values.startingStatus, formik.values.status);
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openContextMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = () => {
    window.print();
    setAnchorEl(null);
  };

  const handleExport = () => {
    const pdfTable = [
      { name: "Client Name", value: client[0] ? client[0].name : "" },
      { name: "Client Address", value: client[0] ? client[0].address : "" },
      { name: "WO #", value: formik.values.work_order_number },
      { name: "WO Name", value: formik.values.name },
      {
        name: "WO Type",
        value: formik.values.type ? formik.values.type : "",
      },
      { name: "PO Ref. #", value: formik.values.po_reference },
      { name: "Description", value: formik.values.description },
      { name: "Asset Name", value: formik.values.asset?.name },
      { name: "Category", value: formik.values.asset?.category?.name },
      { name: "Manufacturer", value: formik.values.asset?.manufacturer },
      { name: "Model", value: formik.values.asset?.model },
      { name: "Ser. #", value: formik.values.asset?.serial_number },
      { name: "Status", value: formik.values.status },
      { name: "Start Date", value: formik.values.start_date?.format("LL") },
      { name: "Due Date", value: formik.values.finish_date?.format("LL") },
      { name: "Priority", value: formik.values.priority },
      { name: "Assignee", value: formik.values.assigned_user?.name },
      {
        name: "Watchers",
        value: formik.values.watchers.map((watcher) => watcher.name),
      },
      {
        name: "Created By",
        value: formik.values.created_by
          ? `${formik.values.created_by.first_name} ${formik.values.created_by.last_name}`
          : "",
      },
    ];

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [["WO SUMMARY", ""]];

    const data = pdfTable.map((elt) => [elt.name, elt.value]);

    const content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text("", marginLeft, 40);
    autoTable(doc, content);
    doc.save("report.pdf");

    setAnchorEl(null);
  };

  const handleConfirmDeletion = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  const handleCancelDeletion = () => {
    setDeleteConfirmationDialogOpen(false);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
    setDeleteConfirmationDialogOpen(false);
    setAnchorEl(null);
    onClose();
  };

  const getDeleteConfirmationModal = () => {
    return (
      <Dialog
        open={deleteConfirmationDialogOpen}
        onClose={handleCancelDeletion}
      >
        <DialogTitle>Delete Work Order?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this work order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton onClick={handleCancelDeletion} autoFocus>
            Cancel
          </GreyButton>
          <RedButton onClick={handleDelete}>Continue</RedButton>
        </DialogActions>
      </Dialog>
    );
  };

  const addNewSubTask = (newSubTask: SubTaskFormModel) => {
    const formId = uuidv4();
    const newSubTasks = [...formik.values.sub_tasks, { ...newSubTask, formId }];
    formik.setFieldValue("sub_tasks", newSubTasks);
    setIsNewSubTaskFormShown(false);
    setAddedNewTask(true);
  };

  const editSubTask = (newSubTask: SubTaskFormModel) => {
    const newSubTasks = formik.values.sub_tasks.map((subTask) => {
      return (newSubTask.id && newSubTask.id === subTask.id) ||
        (newSubTask.formId && newSubTask.formId === subTask.formId)
        ? newSubTask
        : subTask;
    });
    formik.setFieldValue("sub_tasks", newSubTasks);
  };

  const deleteSubTask = (id: number | string) => {
    const newSubTasks = formik.values.sub_tasks.filter(
      (subTask) => subTask.id !== id && subTask.formId !== id
    );
    formik.setFieldValue("sub_tasks", newSubTasks);
  };

  const onAddTaskClick = () => {
    if (!isNewSubTaskFormShown) {
      setIsNewSubTaskFormShown(true);
    }
  };

  function isFormChanged(initialValues: ITaskForm, values: ITaskForm): boolean {
    return (
      JSON.stringify({
        ...initialValues,
        created_by: initialValues.created_by?.id || 0,
      }) !==
        JSON.stringify({
          ...values,
          created_by: values.created_by?.id || 0,
        }) || addedNewTask
    );
  }

  const getActionButtons = () => {
    return (
      <DialogActions>
        <Button
          autoFocus
          onClick={() =>
            isFormChanged(formik.initialValues, formik.values)
              ? setClickedOut(true)
              : onClose()
          }
          color="primary"
        >
          Cancel
        </Button>
        <ConnectedHideForRoleSubTask roles={[UserRole.Member]} task={task}>
          <Button
            onClick={() => setClickedOut(false)}
            disabled={
              isPending || !isFormChanged(formik.initialValues, formik.values)
            }
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </ConnectedHideForRoleSubTask>
      </DialogActions>
    );
  };

  const getConfirmationModal = () => {
    return (
      <StyledDialog
        open={clickedOut && isFormChanged(formik.initialValues, formik.values)}
      >
        <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will remove all changes on the Work Order.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton
            autoFocus
            onClick={() => setClickedOut(false)}
            color="primary"
          >
            No
          </GreyButton>
          <RedButton
            onClick={() => {
              setClickedOut(false);
              onClose();
            }}
            color="primary"
          >
            Yes
          </RedButton>
        </DialogActions>
      </StyledDialog>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {getConfirmationModal()}
      <form className="w-100" onSubmit={formik.handleSubmit} ref={formRef}>
        <Grid container direction="row" wrap="wrap">
          <StyledMenuIconsContainer textAlign="right" mb={1}>
            <StyledIconButton
              transparent
              nospacing
              aria-label="close"
              onClick={handleClick}
            >
              <MoreHorizIcon />
            </StyledIconButton>
            <Menu
              id="context-menu"
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              anchorEl={anchorEl}
              keepMounted
              open={openContextMenu}
              onClose={handleClose}
            >
              <MenuItem key="print" onClick={handlePrint}>
                <StyledRow>
                  <PrintRoundedIcon color="action" />
                  <StyledTypography>Print</StyledTypography>
                </StyledRow>
              </MenuItem>
              <MenuItem key="export" onClick={handleExport}>
                <StyledRow>
                  <GetAppRoundedIcon color="action" />
                  <StyledTypography>Export PDF</StyledTypography>
                </StyledRow>
              </MenuItem>
              <ConnectedHideForRoleTask roles={[UserRole.Member]} task={task}>
                <Divider />
                <MenuItem key="delete" onClick={handleConfirmDeletion}>
                  <StyledRow>
                    <DeleteIcon style={{ color: "#F15851" }} />
                    <StyledTypography style={{ color: "#F15851" }}>
                      Delete
                    </StyledTypography>
                  </StyledRow>
                </MenuItem>
              </ConnectedHideForRoleTask>
            </Menu>
            {getDeleteConfirmationModal()}
            <StyledIconButton
              transparent
              nospacing
              aria-label="close"
              onClick={() =>
                isFormChanged(formik.initialValues, formik.values)
                  ? setClickedOut(true)
                  : onClose()
              }
            >
              <StyledCloseIcon />
            </StyledIconButton>
          </StyledMenuIconsContainer>
          <Grid item md={8} sm={12}>
            <Content>
              <StyledContentContainer>
                <Grid container className="mb-4" direction="row" wrap="wrap">
                  <Grid item md={6} sm={12}>
                    <Box display="flex" alignItems="center" mb={1} mr={1}>
                      <Box fontWeight="600" mr={1}>
                        WO Type :
                      </Box>
                      <Chip icon={<ManualIcon />} label="Manual" />
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <StyledTitleContainer>
                      {task?.work_order_number && (
                        <Box mb={1}>
                          <b>WO #: </b> {task.work_order_number}
                        </Box>
                      )}
                      <StyledTextField
                        name="po_reference"
                        variant="outlined"
                        label="PO Ref. Number"
                        size="small"
                        disabled={isRestricted}
                        value={formik.values.po_reference}
                        onChange={formik.handleChange}
                        type="number"
                        error={
                          formik.touched.po_reference &&
                          Boolean(formik.errors.po_reference)
                        }
                        helperText={
                          formik.touched.po_reference &&
                          formik.errors.po_reference
                        }
                      />
                    </StyledTitleContainer>
                  </Grid>
                </Grid>
                <Box paddingY="8px">
                  <StyledTextField
                    name="name"
                    fullWidth
                    required
                    label="WO Title"
                    variant="outlined"
                    size="small"
                    disabled={isRestricted}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Box>
                <Box paddingY="8px">
                  <StyledTextField
                    name="description"
                    fullWidth
                    label="WO Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    size="small"
                    disabled={isRestricted}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Box>
                {formik.values.type && (
                  <Box paddingY="8px">
                    <div>
                      <Box component="small" fontWeight="600">
                        Threshhold Event
                      </Box>
                    </div>
                    <Event>
                      {formik.values.type === TaskType.Manual && (
                        <>
                          <ManualIcon className="me-2" /> No threshhold event -
                          work order created manually
                        </>
                      )}
                    </Event>
                  </Box>
                )}
                <Grid container spacing={1} alignItems="center" direction="row">
                  <Grid item md={8}>
                    <Box component="small" fontWeight="600">
                      Tasks
                    </Box>
                  </Grid>
                  <ConnectedHideForRoleTask
                    roles={[UserRole.Member]}
                    task={task}
                  >
                    <Grid className="text-end" item md={4}>
                      <Button
                        startIcon={<AddIcon />}
                        color="primary"
                        onClick={onAddTaskClick}
                      >
                        Add Task
                      </Button>
                    </Grid>
                  </ConnectedHideForRoleTask>
                  <Grid item xs={12}>
                    {isNewSubTaskFormShown ? (
                      <SubTaskForm
                        key="new"
                        subtask={{
                          name: "",
                          description: "",
                          is_done: false,
                          assigned_user: null,
                          assigned_team: null,
                        }}
                        isRestricted={isRestricted}
                        usersTeamsOptions={usersTeamsOptions}
                        onChange={(newSubTask) => addNewSubTask(newSubTask)}
                        onDelete={() => setIsNewSubTaskFormShown(false)}
                        isEdit={false}
                      />
                    ) : null}
                    {[...formik.values.sub_tasks].reverse().map((subTask) => (
                      <SubTaskForm
                        key={subTask.id || subTask.formId}
                        subtask={subTask}
                        isRestricted={isRestricted}
                        usersTeamsOptions={usersTeamsOptions}
                        onChange={(newSubTask) => editSubTask(newSubTask)}
                        onDelete={() =>
                          deleteSubTask(
                            (subTask.id as number) || (subTask.formId as string)
                          )
                        }
                        isEdit
                      />
                    ))}
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" direction="row">
                  <Grid item md={8}>
                    <Box component="small" fontWeight="600">
                      Declaration of Contamination
                    </Box>
                  </Grid>
                  {!uploadedFileUrl && (
                    <ConnectedHideForRoleTask
                      roles={[UserRole.Member]}
                      task={task}
                    >
                      <Grid className="text-end" item md={4}>
                        <Button
                          startIcon={<EditIcon style={{ fontSize: 16 }} />}
                          color="primary"
                          href={`https://fs29.formsite.com/fR32I1/zxd811aqzp/fill?id0=${task?.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Sign D.O.C
                        </Button>
                      </Grid>
                    </ConnectedHideForRoleTask>
                  )}
                </Grid>
                <ConnectedHideForRoleTask roles={[UserRole.Member]} task={task}>
                  <Grid item xs={12}>
                    <DropzoneContainer>
                      <Dropzone
                        accept={DOC_MIME_TYPES}
                        multiple={false}
                        onDrop={(acceptedFiles: File[]) => {
                          if (acceptedFiles.length > 0) {
                            formik.setFieldValue("file", acceptedFiles[0]);
                            setUploadedFileName(acceptedFiles[0].name);
                          } else {
                            formik.setFieldValue("file", undefined);
                            setUploadedFileName(undefined);
                          }
                        }}
                        disabled={isPending}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} disabled={isPending} />
                            {isDragActive ? (
                              <Typography>Drop the file here...</Typography>
                            ) : uploadedFileName ? (
                              <Typography>
                                {uploadedFileName}{" "}
                                <IconButton
                                  size="small"
                                  edge="end"
                                  aria-label="delete"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    formik.setFieldValue("file", null);
                                    setUploadedFileName(undefined);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Typography>
                            ) : uploadedFileUrl ? (
                              <Typography>
                                <Link
                                  href={uploadedFileUrl}
                                  target="_blank"
                                  onClick={(event: React.MouseEvent) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  {uploadedFileUrl
                                    .split("\\")
                                    .pop()
                                    ?.split("/")
                                    .pop()}
                                </Link>{" "}
                                <IconButton
                                  size="small"
                                  edge="end"
                                  aria-label="delete"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    formik.setFieldValue("file", null);
                                    setUploadedFileUrl(null);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Typography>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={isPending}
                              >
                                <AddIcon /> Add D.O.C
                              </Button>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </DropzoneContainer>
                    {uploadedFileUrl &&
                      task?.file?.created_by &&
                      task?.file?.created_at && (
                        <Typography variant="body2" align="right">
                          {task.file.created_by?.full_name} uploaded at{" "}
                          {moment(task.file.created_at).format(DATE_AND_HOURS)}
                        </Typography>
                      )}
                  </Grid>
                </ConnectedHideForRoleTask>
              </StyledContentContainer>
              {task?.id && (
                <TaskModalCommentsForm
                  user={currentUser}
                  onDelete={(value) =>
                    onCommentDelete({
                      task,
                      newComment: value,
                      newSubTasks: formik.values.sub_tasks,
                    })
                  }
                  onAdd={(value) =>
                    onCommentAdd({
                      task,
                      newComment: value,
                      newSubTasks: formik.values.sub_tasks,
                    })
                  }
                  comments={formik.values.comments}
                />
              )}
            </Content>
          </Grid>
          <Grid item md={4} sm={12}>
            <Sidebar>
              <StyledSidebarContainer>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600" mt={3}>
                      Status
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <TypeSelect
                      className="p-2"
                      fullWidth
                      variant="outlined"
                      name="status"
                      disabled={isRestricted}
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      renderValue={(value: any) => {
                        const cstatus = taskStatus.find(
                          (e) => e.value === value
                        );
                        return (
                          <StyledSelectField
                            margin="dense"
                            value={cstatus?.name}
                            error={
                              formik.touched.status &&
                              Boolean(formik.errors.status)
                            }
                            helperText={
                              formik.touched.status && formik.errors.status
                            }
                            InputProps={{
                              startAdornment: cstatus?.icon,
                              disableUnderline: true,
                            }}
                          />
                        );
                      }}
                    >
                      {taskStatus
                        .filter(
                          (status) => status.value !== formik.values.status
                        )
                        .map(({ name, value, icon }) => (
                          <MenuItem key={value} value={value}>
                            <Box mr={1}>{icon}</Box>
                            <span>{name}</span>
                          </MenuItem>
                        ))}
                    </TypeSelect>
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Start Date
                    </Box>
                  </Grid>
                  <Grid className="text-end" item md={8}>
                    <PickerWrapper className="p-2">
                      <DatePicker
                        placeholder="None"
                        value={formik.values.start_date}
                        onChange={(date) => {
                          formik.setFieldValue("start_date", date);
                        }}
                        label=""
                        variant="outlined"
                        disabled={isRestricted}
                        format="MM/DD/yyyy"
                      />
                    </PickerWrapper>
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Due Date
                    </Box>
                  </Grid>
                  <Grid className="text-end" item md={8}>
                    <PickerWrapper className="p-2">
                      <DatePicker
                        placeholder="None"
                        value={formik.values.finish_date}
                        onChange={(date) => {
                          formik.setFieldValue("finish_date", date);
                        }}
                        label=""
                        variant="outlined"
                        disabled={isRestricted}
                        format="MM/DD/yyyy"
                      />
                    </PickerWrapper>
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Priority
                    </Box>
                  </Grid>
                  <Grid className="text-end" item md={8}>
                    <TypeSelect
                      className="p-2"
                      fullWidth
                      disabled={isRestricted}
                      variant="outlined"
                      name="priority"
                      value={formik.values.priority}
                      onChange={formik.handleChange}
                      renderValue={(value: any) => {
                        const cpre = taskPriority.find(
                          (e) => e.value === value
                        );
                        return (
                          <StyledSelectField
                            margin="dense"
                            value={cpre?.name}
                            error={
                              formik.touched.priority &&
                              Boolean(formik.errors.priority)
                            }
                            helperText={
                              formik.touched.priority && formik.errors.priority
                            }
                            InputProps={{
                              startAdornment: cpre?.icon,
                              disableUnderline: true,
                            }}
                          />
                        );
                      }}
                    >
                      {taskPriority
                        .filter(
                          (priority) =>
                            priority.value !== formik.values.priority
                        )
                        .map(({ name, value, icon }) => (
                          <MenuItem key={value} value={value}>
                            <Box mr={1}>{icon}</Box>
                            <span>{name}</span>
                          </MenuItem>
                        ))}
                    </TypeSelect>
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Created By
                    </Box>
                  </Grid>
                  <Grid className="text-end" item md={8}>
                    <Box display="flex" className="p-2" alignItems="center">
                      <Box mr={1}>
                        {formik.values.created_by &&
                        formik.values.created_by.photo_url &&
                        !formik.values.created_by.photo_url.includes(
                          "gravatar"
                        ) ? (
                          <StyledAvatar
                            src={formik.values.created_by.photo_url}
                          />
                        ) : (
                          <StyledAvatar>
                            {formik.values.created_by?.first_name?.charAt(0)}
                            {formik.values.created_by?.last_name?.charAt(0)}
                          </StyledAvatar>
                        )}
                      </Box>
                      <Typography>
                        {formik.values.created_by?.full_name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} sm={12} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Assignee
                    </Box>
                  </Grid>
                  <Grid
                    className="text-end"
                    item
                    md={8}
                    sm={12}
                    style={{ width: "100%" }}
                  >
                    <Autocomplete
                      className="p-2"
                      disableClearable
                      placeholder="None"
                      options={usersTeamsOptions.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )}
                      value={
                        formik.values.assigned_user ||
                        formik.values.assigned_team ||
                        undefined
                      }
                      onChange={(
                        event: React.ChangeEvent<unknown>,
                        newValue: IUserTeamOption
                      ) => {
                        if (newValue.isUser) {
                          formik.setFieldValue("assigned_user", newValue);
                          formik.setFieldValue("assigned_team", null);
                        } else {
                          formik.setFieldValue("assigned_team", newValue);
                          formik.setFieldValue("assigned_user", null);
                        }
                      }}
                      getOptionSelected={(option, value) => {
                        const isEqual = option.name.localeCompare(value?.name);

                        return isEqual === 0;
                      }}
                      getOptionLabel={(option) =>
                        setMaxTextLength(option.name, 12)
                      }
                      renderInput={(params) => (
                        <Box display="flex" alignItems="center">
                          <StyledAutocompleteField
                            {...params}
                            placeholder="None"
                            error={
                              (formik.touched.assigned_user &&
                                Boolean(formik.errors.assigned_user)) ||
                              (formik.touched.assigned_team &&
                                Boolean(formik.errors.assigned_team))
                            }
                            helperText={
                              (formik.touched.assigned_user &&
                                formik.errors.assigned_user) ||
                              (formik.touched.assigned_team &&
                                formik.errors.assigned_team)
                            }
                            InputProps={{
                              ...params.InputProps,
                              inputProps: {
                                ...params.inputProps,
                                className: `${
                                  (params.inputProps as Record<string, any>)
                                    .className
                                } px-0 py-1`,
                              },
                              disableUnderline: true,
                              ...((formik.values.assigned_user ||
                                formik.values.assigned_team) && {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <StyledAvatar
                                      src={
                                        !formik.values.assigned_user?.photo.includes(
                                          "gravatar"
                                        )
                                          ? formik.values.assigned_user?.photo
                                          : ""
                                      }
                                    >
                                      {formik.values.assigned_user?.name
                                        .split(" ")[0]
                                        ?.charAt(0) ||
                                        formik.values.assigned_team?.name
                                          .split(" ")[0]
                                          ?.charAt(0)}
                                      {formik.values.assigned_user?.name
                                        .split(" ")[1]
                                        ?.charAt(0) ||
                                        formik.values.assigned_team?.name
                                          .split(" ")[1]
                                          ?.charAt(0)}
                                    </StyledAvatar>
                                  </InputAdornment>
                                ),
                              }),
                            }}
                          />
                        </Box>
                      )}
                      renderOption={(option) => (
                        <>
                          <Box mr={1}>
                            <StyledAvatar src={option.photo}>
                              {option.name.split(" ")[0]?.charAt(0)}
                              {option.name.split(" ")[1]?.charAt(0)}
                            </StyledAvatar>
                          </Box>
                          {option.name}
                        </>
                      )}
                      disabled={isRestricted || isPending}
                    />
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Watchers
                    </Box>
                  </Grid>
                  <Grid item md={8} style={{ width: "100%" }}>
                    <Autocomplete
                      className="p-2"
                      disableClearable
                      multiple
                      disableCloseOnSelect
                      placeholder="None"
                      options={usersTeamsOptions}
                      value={formik.values.watchers || undefined}
                      onChange={(
                        event: React.ChangeEvent<unknown>,
                        newValue: IUserTeamOption[]
                      ) => {
                        formik.setFieldValue("watchers", newValue);
                      }}
                      getOptionSelected={(option, value) => {
                        const isEqual = option.name.localeCompare(value?.name);
                        return isEqual === 0;
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <StyledAutocompleteField
                          {...params}
                          placeholder={
                            formik.values.watchers.length ? "" : "None"
                          }
                          error={
                            formik.touched.watchers &&
                            Boolean(formik.errors.watchers)
                          }
                          helperText={
                            formik.touched.watchers && formik.errors.watchers
                          }
                          InputProps={{
                            ...params.InputProps,
                            inputProps: {
                              ...params.inputProps,
                              className: `${
                                (params.inputProps as Record<string, any>)
                                  .className
                              } px-0 py-1`,
                            },
                            disableUnderline: true,
                          }}
                        />
                      )}
                      renderTags={(watchers) =>
                        watchers.map((watcher) => (
                          <Tooltip title={watcher.name} placement="top">
                            <StyledWatchersAvatar src={watcher.photo}>
                              {watcher.name.split(" ")[0]?.charAt(0)}
                              {watcher.name.split(" ")[1]?.charAt(0)}
                            </StyledWatchersAvatar>
                          </Tooltip>
                        ))
                      }
                      renderOption={(option, { selected }) => (
                        <>
                          <>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginLeft: -8 }}
                              checked={selected}
                            />
                            <StyledWatchersAvatar src={option.photo}>
                              {option.name.split(" ")[0]?.charAt(0)}
                              {option.name.split(" ")[1]?.charAt(0)}
                            </StyledWatchersAvatar>
                          </>
                          {option.name}
                        </>
                      )}
                      disabled={isRestricted || isPending}
                    />
                  </Grid>
                </Grid>
                <hr />
                <Grid
                  alignItems="center"
                  className="my-1"
                  container
                  direction="row"
                >
                  <Grid item md={4} className="pe-2">
                    <Box component="small" fontWeight="600">
                      Asset *
                    </Box>
                  </Grid>
                  <Grid
                    className="text-end"
                    item
                    md={8}
                    style={{ width: "100%" }}
                  >
                    {isPending ? (
                      <Box display="flex" justifyContent="left">
                        <CircularProgress size={20} />
                      </Box>
                    ) : (
                      <Autocomplete
                        options={assets}
                        value={formik.values.asset || null}
                        getOptionLabel={(option) => option.name}
                        onChange={(
                          event: React.ChangeEvent<unknown>,
                          newValue: AssetDto | null
                        ) => {
                          formik.setFieldValue("asset", newValue);
                        }}
                        renderInput={(params) => (
                          <StyledAutocompleteField
                            {...params}
                            placeholder="None"
                            error={
                              formik.touched.asset &&
                              Boolean(formik.errors.asset)
                            }
                            helperText={
                              formik.touched.asset && formik.errors.asset
                            }
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            required
                          />
                        )}
                        disabled={isRestricted || isPending}
                        getOptionSelected={(option, value) => {
                          const isEqual = option.name.localeCompare(
                            value?.name
                          );
                          return isEqual === 0;
                        }}
                      />
                    )}
                  </Grid>
                  {formik.values.asset ? (
                    <AssetBox asset={formik.values.asset} />
                  ) : null}
                </Grid>
              </StyledSidebarContainer>
              <div
                style={{
                  position: "sticky",
                  right: "15px",
                  bottom: "-17px",
                  backgroundColor: "rgb(241, 243, 244)",
                }}
              >
                {getActionButtons()}
              </div>
            </Sidebar>
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};
