import React, { FC } from "react";
import moment from "moment";
import { Box } from "@material-ui/core/";

import { StyledDot, StyledLabel } from "./ApiNotificationRow.style";
import {
  ApiNotificationType,
  NotificationDto,
} from "../../api/apiNotifications.api.dto";
import { ApiNotificationIcon } from "../ApiNotificiationIcon/ApiNotificationIcon";

interface IApiNotificationRow {
  onClick: (notification: NotificationDto) => void;
  apiNotification: NotificationDto;
}

export const ApiNotificationRow: FC<IApiNotificationRow> = ({
  apiNotification,
  onClick,
}) => {
  return (
    <Box
      display="flex"
      onClick={() => onClick(apiNotification)}
      alignItems="center"
      width="100%"
      p={1}
    >
      <Box mr={2}>
        <StyledDot show={!apiNotification.is_read} />
      </Box>
      <Box mr={2}>
        <ApiNotificationIcon type={apiNotification.type} />
      </Box>
      <Box>
        <StyledLabel variant="h5">{apiNotification.title}</StyledLabel>
        <StyledLabel variant="h5" color="secondary">
          {apiNotification.sub_title}
        </StyledLabel>
        <StyledLabel variant="h5" color="textSecondary">
          {moment(apiNotification.time).fromNow()}
        </StyledLabel>
      </Box>
    </Box>
  );
};
