import styled from "styled-components";
import { Tabs, Tab, Paper, Button, Dialog } from "@material-ui/core";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 280px;
  }
  .MuiDialogTitle-root {
    padding: 25px 25px 5px;
  }
  .MuiDialogContent-root {
    padding: 5px 25px 10px;
  }
  h2 {
    font-size: 17px;
  }
  p {
    font-size: 13px;
    margin-bottom: 15px;
  }
  p:last-child {
    margin-bottom: 15px;
  }
`;

export const RedButton = styled(Button)`
  color: ${({ theme }) => `${theme.palette.button.error.color}`};
  font-weight: 700;
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;

export const GreyButton = styled(Button)`
  color: ${({ theme }) => `${theme.palette.text.disabled}`};
  font-weight: 700;
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;
