import { AxiosResponse } from "axios";
import { openErrorNotification } from "../store/notifications/notifications.slice";
import { ApiErrorModel } from "../model/ApiError";

export default <Args, Returned>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloadCreator: (args: Args, options?: any) => Promise<Returned>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected?: (args: Args, options?: any) => void
) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,consistent-return,@typescript-eslint/no-explicit-any
  return async (args: Args, options?: any) => {
    try {
      return await payloadCreator(args, options);
    } catch (exception) {
      if (onRejected) {
        onRejected(args, options);
      }

      if (exception && exception.response) {
        const { data }: AxiosResponse = exception.response;
        const { response }: ApiErrorModel = data;
        const { error } = data;

        if (response) {
          const errorsKeys = Object.keys(response);

          if (errorsKeys.length > 0) {
            errorsKeys.forEach((key) =>
              response[key].forEach((message: string) =>
                options.dispatch(openErrorNotification(message))
              )
            );
          }
        } else if (error) {
          options.dispatch(openErrorNotification(error));
        } else {
          options.dispatch(openErrorNotification("Something went wrong"));
        }
      }

      if (exception && exception.response) {
        throw options.rejectWithValue(exception.response.data);
      }

      if (exception) {
        throw exception;
      }
    }
  };
};
