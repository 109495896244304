import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createSensorType as createSensorTypeAction } from "../store/sensorTypes.action";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { SensorTypeModal } from "../component/SensorTypeModal/SensorTypeModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditSensorTypeDto } from "../api/sensorTypes.api.dto";
import { ADD_SENSOR_TYPE_MODAL } from "../../app/const/modals";
import {
  selectIsSensorTypesTableStatusPending,
  selectHardcodedSensorTypesData,
  selectIsHardcodedSensorTypesStatusPending,
  selectHardcodedSensorUnitsData,
  selectIsHardcodedSensorUnitsStatusPending,
} from "../store/sensorTypes.selector";

export const AddSensorTypeModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const hardcodedSensorTypesData = useSelector(selectHardcodedSensorTypesData);
  const hardcodedSensorUnitsData = useSelector(selectHardcodedSensorUnitsData);
  const isSensorTypesPending = useSelector(
    selectIsSensorTypesTableStatusPending
  );
  const isHardcodedSensorTypesPending = useSelector(
    selectIsHardcodedSensorTypesStatusPending
  );
  const isHardcodedSensorUnitsPending = useSelector(
    selectIsHardcodedSensorUnitsStatusPending
  );

  const openModal = () =>
    dispatch(openModalAction({ name: ADD_SENSOR_TYPE_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_SENSOR_TYPE_MODAL }));

  const onCreateSensorType = (values: CreateEditSensorTypeDto) =>
    dispatch(createSensorTypeAction(values));

  return (
    <>
      <ConnectedShowForPermissions
        feature="sensors"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isSensorTypesPending}
        >
          Add Sensor
        </Button>
      </ConnectedShowForPermissions>
      <SensorTypeModal
        name={ADD_SENSOR_TYPE_MODAL}
        hardcodedSensorTypes={hardcodedSensorTypesData}
        hardcodedSensorUnits={hardcodedSensorUnitsData}
        onClose={closeModal}
        onSubmit={onCreateSensorType}
        isPending={
          isHardcodedSensorTypesPending || isHardcodedSensorUnitsPending
        }
      />
    </>
  );
};
