import {
  ClientAverageHealthDto,
  ClientDto,
  CreateEditClientDto,
} from "./clients.api.dto";
import { authApiClient } from "../../app/api/authApi.client";
import { AssetTodosDto } from "../../asset/api/asset.api.dto";

export const fetchClients = async (): Promise<ClientDto[]> => {
  try {
    const response = await authApiClient.get("/clients");
    return response.data.clients;
  } catch (error) {
    throw error;
  }
};

export const createClient = async (
  client: CreateEditClientDto
): Promise<ClientDto> => {
  try {
    const response = await authApiClient.post("/clients", client);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteClient = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/clients/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const editClient = async (
  id: number,
  editClientDto: CreateEditClientDto
): Promise<ClientDto> => {
  try {
    const response = await authApiClient.put(`/clients/${id}`, editClientDto);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAverageHealth = async (
  clientId: number
): Promise<ClientAverageHealthDto> => {
  try {
    const response = await authApiClient.get(
      `clients/${clientId}/average-health`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRecentActivity = async (clientId: number): Promise<any> => {
  try {
    const response = await authApiClient.post(
      `clients/${clientId}/recent-activity`
    );

    return response.data.audits;
  } catch (error) {
    throw error;
  }
};

export const fetchClientTodos = async (
  clientId: number
): Promise<AssetTodosDto> => {
  try {
    const response = await authApiClient.get(`clients/${clientId}/todos`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
