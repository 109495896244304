import { createAsyncThunk } from "@reduxjs/toolkit";

import withError from "../../app/util/with-thunk-error";
import {
  fetchBookmarks as fetchBookmarksRequest,
  repositionBookmarks as repositionBookmarksRequest,
} from "../api/bookmarks.api";
import { BookmarkModel } from "../model/bookmarks.model";

export const fetchBookmarks = createAsyncThunk(
  "bookmarks/fetchBookmarks",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<BookmarkModel[]> => {
      return fetchBookmarksRequest();
    }
  )
);

export const repositionBookmarks = createAsyncThunk(
  "bookmarks/repositionBookmarks",
  withError(
    async (bookmarksIds: number[]): Promise<void> => {
      return repositionBookmarksRequest(bookmarksIds);
    }
  )
);
