import { createSlice } from "@reduxjs/toolkit";
import { bookmarksState } from "./bookmarks.store";
import { fetchBookmarks } from "./bookmarks.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "bookmarks",
  initialState: bookmarksState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookmarks.pending, (state) => ({
      ...state,
      fetchBookmarks: {
        ...state.fetchBookmarks,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchBookmarks.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchBookmarks: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchBookmarks.rejected, (state) => ({
      ...state,
      fetchIndustries: {
        ...state.fetchBookmarks,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
