import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";

import { StyledPage } from "../../../app/component/DashboardLayout/DashboardLayout.style";
import { ForgotPasswordFormContainer } from "../../container/ForgotPasswordFormContainer/ForgotPasswordFormContainer";

export const ForgotPasswordView: FC = () => {
  return (
    <StyledPage title="Forgot Password">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <ForgotPasswordFormContainer />
        </Container>
      </Box>
    </StyledPage>
  );
};
