import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchTeams as fetchTeamsRequest,
  createTeam as createTeamRequest,
  deleteTeam as deleteTeamRequest,
  editTeam as editTeamRequest,
} from "../api/teams.api";
import withError from "../../app/util/with-thunk-error";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_TEAM_MODAL, EDIT_TEAM_MODAL } from "../../app/const/modals";
import { TeamDto, CreateEditTeamDto } from "../api/teams.api.dto";

export const fetchTeams = createAsyncThunk(
  "teams/fetchTeams",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<TeamDto[]> => fetchTeamsRequest()
  )
);

export const createTeam = createAsyncThunk(
  "teams/createTeam",
  withError(
    async (team: CreateEditTeamDto, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_TEAM_MODAL }));
      await createTeamRequest(team);
      dispatch(closeModal({ name: ADD_TEAM_MODAL }));
      dispatch(openSuccessNotification("Team has been added"));
      dispatch(fetchTeams());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_TEAM_MODAL }))
  )
);

export const deleteTeam = createAsyncThunk(
  "teams/deleteTeam",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_TEAM_MODAL }));
      await deleteTeamRequest(id);
      dispatch(closeModal({ name: EDIT_TEAM_MODAL }));
      dispatch(openSuccessNotification("Team has been deleted"));
      dispatch(fetchTeams());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_TEAM_MODAL }))
  )
);

export const editTeam = createAsyncThunk(
  "teams/editTeam",
  withError(
    async (
      { id, editTeamDto }: { id: number; editTeamDto: CreateEditTeamDto },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_TEAM_MODAL }));
      await editTeamRequest(id, editTeamDto);
      dispatch(closeModal({ name: EDIT_TEAM_MODAL }));
      dispatch(openSuccessNotification("Team has been updated"));
      dispatch(fetchTeams());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_TEAM_MODAL }))
  )
);
