import styled from "styled-components";

export const NotesNumber = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 22px;
  border-radius: 12px;
  background-color: rgba(31, 30, 77, 0.1);
  margin-left: 3px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  font-weight: 500;
`;

export const NotesWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  margin-bottom: 57px;
  position: relative;
  min-height: 95px;
`;

export const NoNotesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing(3)}px`} 0;

  p {
    font-size: 24px;
    color: rgba(31, 30, 77, 0.5);
  }
`;
