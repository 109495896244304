import React, { FC } from "react";
import { Divider as MuiDivider, DividerProps } from "@material-ui/core";
import { StyledTransparentDivider } from "./Divider.style";

export interface IDivider extends DividerProps {
  transparent?: boolean;
}

export const Divider: FC<IDivider> = ({ transparent, ...rest }) => {
  if (transparent) {
    return <StyledTransparentDivider {...rest} />;
  }
  return <MuiDivider {...rest} />;
};
