import { colors } from "@material-ui/core";

export const MAIN = "#7678ed";

export const BACKGROUND_DARK = "#1f1e4d";
export const BACKGROUND_DEFAULT = "#f1f3f4";
export const BACKGROUND_PAPER = colors.common.white;

export const BORDER_LIGHT = "#E5E5E5";
export const BORDER_DARK = "#acacac";

export const TEXT_PRIMARY = "#1f1e4d";
export const TEXT_SECONDARY = "#5f6368";
export const TEXT_DISABLED = "#BABABA";
export const TEXT_LINK = "#7678ed";
export const TEXT_LINK_HOVER = "#1f1e4d";
export const TEXT_WHITE = "#ffffff";

export const BUTTON_PRIMARY = "#7678ed";
export const BUTTON_PRIMARY_HOVER = "rgba(118, 120, 237, 0.85)";
export const BUTTON_PRIMARY_HOVER_TEXT = "#ffffff";
export const BUTTON_PRIMARY_TEXT = "#ffffff";

export const BUTTON_PRIMARY_INVERSE_HOVER = "rgba(118, 120, 237, 0.05)";

export const BUTTON_SECONDARY = "#ffffff";
export const BUTTON_SECONDARY_HOVER = "#ffffff";
export const BUTTON_SECONDARY_HOVER_TEXT = "#3a3c40";
export const BUTTON_SECONDARY_TEXT = "#5f6368";

export const BUTTON_TERTIARY = "#e3e3e9";
export const BUTTON_TERTIARY_HOVER = "#e3e3e9";
export const BUTTON_TERTIARY_HOVER_TEXT = "#1f1e4d";
export const BUTTON_TERTIARY_TEXT = "#1f1e4d";

export const BUTTON_ERROR = "rgba(241, 88, 81, 0.1)";
export const BUTTON_ERROR_HOVER = "rgba(241, 88, 81, 0.05)";
export const BUTTON_ERROR_HOVER_TEXT = "#f15851";
export const BUTTON_ERROR_TEXT = "#f15851";

export const BUTTON_CANCEL = "rgba(0, 0, 0, 0)";
export const BUTTON_CANCEL_HOVER = "#e3e3e9";
export const BUTTON_CANCEL_HOVER_TEXT = BUTTON_PRIMARY;
export const BUTTON_CANCEL_TEXT = BUTTON_PRIMARY;

export const APP_BAR_BACKGROUND = "#ffffff";
export const APP_BAR_TEXT = "#1f1e4d";
export const APP_BAR_HAMBURGER_MENU = "#1f1e4d";

export const DRAWER_BACKGROUND = "#1f1e4d";
export const DRAWER_TEXT = "#dddddd";
export const DRAWER_TEXT_SETTINGS = "red";
export const DRAWER_TEXT_SELECTED = "#ffffff";
export const DRAWER_TEXT_SELECTED_SETTINGS = "black";
export const DRAWER_BACKGROUND_SELECTED = "#444381";
export const DRAWER_BACKGROUND_SELECTED_SETTINGS = "grey";

export const AVATAR_BACKGROUND = "#dddddd";
export const AVATAR_TEXT = "#1f1e4d";

export const TAB_PRIMARY_BACKGROUND = "rgba(118, 120, 237, 0.1)";
export const TAB_PRIMARY_COLOR = "#7678ed";
export const TAB_DIVIDER_COLOR = "#eaeaef";

export const CARD_SECONDARY_BACKGROUND = "#f8f8f8";

export const HEALTH_GAUGE_BAD = "#f15851";
export const HEALTH_GAUGE_FAIR = "#eaca19";
export const HEALTH_GAUGE_GOOD = "#3fc68e";
export const HEALTH_GAUGE_VALUE_BOX = "#ffffff";
export const HEALTH_GAUGE_LABELS = "#bfbfc4";
export const HEALTH_GAUGE_INNER_RING = "#e3e3e9";
export const HEALTH_GAUGE_INDICATOR = "#1f1e4d";
export const SETTINGS_LIST_ITEM_COLOR = "black";
export const SETTINGS_LIST_ITEM_ICON_COLOR = "#565656";
export const ASSET_TRENDS_COLORS = [
  "#00B9AE",
  "#3066BE",
  "#F25F5C",
  "#00B9AE",
  "#F79636",
  "#048BA8",
  "#B252F1",
  "#50514F",
];
export const ASSET_USAGE_COLORS = ["#CE01BC", "#DE86B1", "#023B8C"];
export const WARNING_REFERENCE_AREA = "#FCFFC5";
export const ICON_ERROR_OUTLINE = "rgb(241, 88, 81, .2)";
export const ICON_ERROR_COLOR = "#f15851";
export const ICON_GREEN_OUTLINE = "rgba(63, 198, 142, .2)";
export const ICON_GREEN_COLOR = "#3fc68e";
export const ICON_BLUE_OUTLINE = "rgba(118, 155, 237, .2)";
export const ICON_BLUE_COLOR = "#769bed";

export const ASSET_RUNTIME_LABEL = "#707070";
export const ASSET_RUNTIME_DATA = "#1f1e4d";

export const BELL_COLOR = "#43427c";

export const HIGHLIGHT_BACKGROUND = "rgba(161, 163, 243, 0.3)";
export const PROFILE_BACKGROUND = "#F1F3F4";

export const INPUT_BACKGROUND = "rgba(221,221,221,0.2)";
