import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { IndustryState } from "./industry.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { IndustryDto } from "../api/industry.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectIndustryState = createSelector(
  selectSelf,
  (state: RootState): IndustryState => state.industry
);

export const selectFetchIndustriesState = createSelector(
  selectIndustryState,
  (industry): AsyncStateModel<IndustryDto[]> => industry.fetchIndustries
);

export const selectFetchIndustriesData = createSelector(
  selectFetchIndustriesState,
  (fetchIndustries): IndustryDto[] =>
    fetchIndustries.data.filter((industry) => industry)
);

export const selectFetchIndustriesStatus = createSelector(
  selectFetchIndustriesState,
  (fetchIndustries): AsyncStatus => fetchIndustries.status
);

export const selectIsFetchIndustriesStatusPending = createSelector(
  selectFetchIndustriesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
