import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { UsersState } from "./users.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { UserDto, UserNotificationsDto } from "../api/users.api.dto";
import { UserModel } from "../model/users.model";

export const selectSelf = (state: RootState): RootState => state;

export const selectUsersRootState = createSelector(
  selectSelf,
  (state): UsersState => state.users
);

export const selectUserState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<UserDto[]> => users.fetchUsers
);

export const selectUsersState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<UserDto[]> => users.fetchUsers
);

export const selectUsersData = createSelector(
  selectUsersState,
  (users): UserDto[] => users.data
);

export const selectFetchUserDetails = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<UserDto | null> => users.fetchUserDetails
);

export const selectFetchUserDetailsData = createSelector(
  selectFetchUserDetails,
  (fetchUserDetails): UserDto | null => fetchUserDetails.data
);

export const selectFetchUserDetailsStatus = createSelector(
  selectFetchUserDetails,
  (fetchUserDetails): AsyncStatus => fetchUserDetails.status
);

export const selectIsFetchUserDetailsStatusPending = createSelector(
  selectFetchUserDetailsStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectEditUserDetails = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<null> => users.editUserDetails
);

export const selectEditUserDetailsStatus = createSelector(
  selectEditUserDetails,
  (editUserDetails): AsyncStatus => editUserDetails.status
);

export const selectIsEditUserDetailsStatusPending = createSelector(
  selectEditUserDetailsStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectEditUserAvatar = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<null> => users.editUserAvatar
);

export const selectEditUserAvatarStatus = createSelector(
  selectEditUserAvatar,
  (editUserAvatar): AsyncStatus => editUserAvatar.status
);

export const selectIsEditUserAvatarStatusPending = createSelector(
  selectEditUserAvatarStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectUsersStatus = createSelector(
  selectUsersState,
  (users): AsyncStatus => users.status
);

export const selectIsUsersStatusPending = createSelector(
  selectUsersStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchUsers = createSelector(
  selectUsersRootState,
  (users): string => users.searchUsers
);

export const selectUsersTableData = createSelector(
  selectSearchUsers,
  selectUsersData,
  (searchValue, users): UserModel[] => {
    const data = users.map((user) => user);

    if (searchValue) {
      return data.filter(
        (user) =>
          user.full_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.partner_name
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          user.client_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          JSON.stringify(user.teams?.map((e) => e.name.toLowerCase())).includes(
            searchValue.toLowerCase()
          ) ||
          user.role_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.phone?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsUsersTableStatusPending = createSelector(
  selectUsersStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCreateUserState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<null> => users.createUser
);

export const selectCreateUserStatus = createSelector(
  selectCreateUserState,
  (createUser): AsyncStatus => createUser.status
);

export const selectIsCreateUserStatusPending = createSelector(
  selectCreateUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditUserState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<null> => users.editUser
);

export const selectEditUserStatus = createSelector(
  selectEditUserState,
  (editUser): AsyncStatus => editUser.status
);

export const selectIsEditUserStatusPending = createSelector(
  selectEditUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteUserState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<null> => users.deleteUser
);

export const selectDeleteUserStatus = createSelector(
  selectDeleteUserState,
  (deleteUser): AsyncStatus => deleteUser.status
);

export const selectIsDeleteUserStatusPending = createSelector(
  selectDeleteUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchUserNotificationsState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<UserNotificationsDto | null> =>
    users.fetchUserNotifications
);

export const selectFetchUserNotificationsData = createSelector(
  selectFetchUserNotificationsState,
  (fetchUserNotifications): UserNotificationsDto | null =>
    fetchUserNotifications.data
);

export const selectFetchUserNotificationsStatus = createSelector(
  selectFetchUserNotificationsState,
  (fetchUserNotifications): AsyncStatus => fetchUserNotifications.status
);

export const selectIsFetchUserNotificationsStatusPending = createSelector(
  selectFetchUserNotificationsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditUserNotificationsState = createSelector(
  selectUsersRootState,
  (users): AsyncStateModel<null> => users.editUserNotifications
);

export const selectEditUserNotificationsStatus = createSelector(
  selectEditUserNotificationsState,
  (editUserNotifications): AsyncStatus => editUserNotifications.status
);

export const selectIsEditUserNotificationsStatusPending = createSelector(
  selectEditUserNotificationsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
