export const getPathnameFromTo = (from: number, to: number) => (
  pathname: string
): string => {
  const splittedPathname = pathname.split("/");
  const slicedPathname = splittedPathname.slice(1, splittedPathname.length);
  const pathnameFromTo = slicedPathname.slice(from, to);

  return `/${pathnameFromTo.join("/")}`;
};

export const getPathnameNthParam = (nth: number) => (
  pathname: string
): string => {
  const splittedPathname = pathname.split("/");
  const slicedPathname = splittedPathname.slice(1, splittedPathname.length);

  return slicedPathname[nth];
};
