import styled from "styled-components";
import { Menu } from "@material-ui/core";
import { Button } from "../../Button/Button";
import { MAIN } from "../../../theme/colors";

export const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: none;
  background-color: transparent;

  :hover {
    background-color: transparent;
  }
`;

export const PageSize = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: ${MAIN};
`;
