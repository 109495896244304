import React, { FC, useState } from "react";
import {
  Box,
  MenuItem,
  Popover,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  DeleteForever as DeleteForeverIcon,
  Person as PersonIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  TaskContainer,
  StyledCheckbox,
  StyledIconButton,
  NakedTextField,
  StyledAvatar,
} from "./SubTaskForm.style";
import {
  GreyButton,
  RedButton,
} from "../../../asset/component/Analytics/AnalyticsSelectorsConfirmationModal.style";
import { IUserTeamOption } from "../../model/workorders.model";
import { SubTaskFormModel } from "../../api/workorders.api.dto";
import { ConnectedHideForRoleTask } from "../../../app/component/UserManagement/ConnectedHideForRoleTask";
import { BORDER_LIGHT } from "../../../app/theme/colors";
import { UserRole } from "../../../app/enum/UserRole";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { selectProfileData } from "../../../auth/store/auth.selector";

interface ISubTaskForm {
  subtask: SubTaskFormModel;
  onChange: (newSubTask: SubTaskFormModel) => void;
  onDelete?: () => void;
  usersTeamsOptions: IUserTeamOption[];
  isRestricted: boolean;
  isEdit: boolean;
}

export const SubTaskForm: FC<ISubTaskForm> = ({
  onChange,
  onDelete,
  subtask,
  usersTeamsOptions,
  isRestricted,
  isEdit,
}) => {
  const [name, setName] = useState(subtask.name);
  const [editingName, setEditingName] = useState(subtask.name);
  const [description, setDescription] = useState(subtask.description);
  const [editingDescription, setEditingDescription] = useState(
    subtask.description
  );
  const [isDone, setIsDone] = useState(subtask.is_done);
  const [assignedUser, setAssignedUser] = useState(subtask.assigned_user);
  const [assignedTeam, setAssignedTeam] = useState(subtask.assigned_team);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [
    deleteConfirmationDialogOpen,
    setDeleteConfirmationDialogOpen,
  ] = useState(false);
  const profileData = useSelector(selectProfileData);
  const handleChecked = (checked: boolean) => {
    onChange({
      ...subtask,
      is_done: checked,
    });
    setIsDone(checked);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditingName(event.target.value);
  };
  const handleNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let newName;
    if (event.target.value.trim() === "") {
      newName = name;
    } else {
      newName = event.target.value;
    }
    onChange({
      ...subtask,
      name: newName,
    });
    setName(newName);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEditingDescription(event.target.value);
  };

  const handleDescriptionBlur = (
    event: React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const newDescription = event.target.value;
    onChange({
      ...subtask,
      description: newDescription,
    });
    setDescription(newDescription);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === "Escape") {
      (event.target as HTMLInputElement).blur();
    }
  };

  const handleAvatarChange = (option: IUserTeamOption) => {
    let newAssignedUser;
    let newAssignedTeam;
    if (option.isUser) {
      newAssignedUser = option;
      newAssignedTeam = null;
    } else {
      newAssignedUser = null;
      newAssignedTeam = option;
    }
    onChange({
      ...subtask,
      assigned_user: newAssignedUser,
      assigned_team: newAssignedTeam,
    });
    setAssignedUser(newAssignedUser);
    setAssignedTeam(newAssignedTeam);
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarAnchorEl(null);
  };

  const getAvatarPlaceholder = (option?: IUserTeamOption) => {
    if (!option) {
      return <PersonIcon className="MuiAvatar-fallback" />;
    }
    if (!option.isUser && !option.photo) {
      return <PeopleIcon className="MuiAvatar-fallback" />;
    }
    return `${option.name.split(" ")[0]?.charAt(0) || ""}${
      option.name.split(" ")[1]?.charAt(0) || ""
    }`;
  };

  const handleConfirmDeletion = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  const handleCancelDeletion = () => {
    setDeleteConfirmationDialogOpen(false);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
    setDeleteConfirmationDialogOpen(false);
  };

  const getDeleteConfirmationModal = () => {
    return (
      <Dialog
        open={deleteConfirmationDialogOpen}
        onClose={handleCancelDeletion}
      >
        <DialogTitle>Delete Task?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this task?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton onClick={handleCancelDeletion} autoFocus>
            Cancel
          </GreyButton>
          <RedButton onClick={handleDelete}>Continue</RedButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <TaskContainer>
      <Box display="flex">
        <Box>
          <StyledCheckbox
            checked={!!isDone}
            disabled={
              isRestricted &&
              subtask.assigned_user?.id !== profileData?.user?.id
            }
            onChange={(event: React.ChangeEvent<unknown>, checked: boolean) =>
              handleChecked(checked)
            }
            style={{
              paddingBottom:
                editingDescription === "" && isRestricted ? `0px` : `9px`,
            }}
          />
        </Box>
        <Box flexGrow={1}>
          <Box
            display="flex"
            marginBottom="5px"
            style={{
              borderBottom:
                editingDescription === "" && isRestricted
                  ? ``
                  : `1px solid ${BORDER_LIGHT}`,
            }}
          >
            {!(
              isRestricted &&
              subtask.assigned_user?.id !== profileData?.user?.id
            ) ? (
              <Box flexGrow={1}>
                <NakedTextField
                  fullWidth
                  size="small"
                  bolder
                  value={editingName}
                  disabled={
                    isRestricted &&
                    subtask.assigned_user?.id !== profileData?.user?.id
                  }
                  placeholder="Add title"
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  onKeyDown={handleKeyDown}
                />
              </Box>
            ) : (
              <Box
                flexGrow={1}
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#000",
                  fontFamily: "Roboto, Helvetica, Arial",
                  marginTop:
                    isRestricted && editingDescription === "" ? "5px" : "0",
                }}
              >
                {editingName}
              </Box>
            )}
            <ConnectedHideForRoleTask
              roles={[UserRole.Member]}
              subtask={subtask}
            >
              <Box>
                <StyledIconButton
                  corner="top-right"
                  onClick={handleConfirmDeletion}
                >
                  <DeleteForeverIcon />
                </StyledIconButton>
                {getDeleteConfirmationModal()}
              </Box>
            </ConnectedHideForRoleTask>
          </Box>
          {!(
            isRestricted && subtask.assigned_user?.id !== profileData?.user?.id
          ) ? (
            <Box>
              <NakedTextField
                multiline
                fullWidth
                size="tiny"
                value={editingDescription}
                disabled={
                  isRestricted &&
                  subtask.assigned_user?.id !== profileData?.user?.id
                }
                placeholder="Add details..."
                onChange={handleDescriptionChange}
                onBlur={handleDescriptionBlur}
                onKeyDown={handleKeyDown}
              />
            </Box>
          ) : editingDescription !== "" ? (
            <Box
              flexGrow={1}
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#000",
                fontFamily: "Roboto, Helvetica, Arial",
              }}
            >
              {editingDescription}
            </Box>
          ) : (
            <></>
          )}
          {isRestricted && !assignedUser && !assignedTeam ? (
            ``
          ) : (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <StyledIconButton
                corner="bottom-right"
                fadeOnHover
                disabled={
                  isRestricted &&
                  subtask.assigned_user?.id !== profileData?.user?.id
                }
                className={avatarAnchorEl ? "faded" : ""}
                onClick={handleAvatarClick}
                title={assignedUser?.name || assignedTeam?.name}
              >
                <StyledAvatar
                  size="small"
                  noMargin
                  src={assignedUser?.photo || assignedTeam?.photo}
                >
                  {getAvatarPlaceholder(
                    assignedUser || assignedTeam || undefined
                  )}
                </StyledAvatar>
              </StyledIconButton>
              <Popover
                open={Boolean(avatarAnchorEl)}
                anchorEl={avatarAnchorEl}
                onClose={handleAvatarClose}
                anchorOrigin={{
                  vertical: 8,
                  horizontal: 4,
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {[...usersTeamsOptions]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={
                        option.name.length > 0 &&
                        option.name.localeCompare(
                          assignedUser?.name || assignedTeam?.name || ""
                        ) === 0
                      }
                      onClick={() => {
                        handleAvatarChange(option);
                        handleAvatarClose();
                      }}
                    >
                      <Box mr={1}>
                        <StyledAvatar
                          size="small"
                          src={
                            !option.photo.includes("gravatar")
                              ? option.photo
                              : ""
                          }
                        >
                          {getAvatarPlaceholder(option)}
                        </StyledAvatar>
                      </Box>
                      <Typography variant="inherit">{option.name}</Typography>
                    </MenuItem>
                  ))}
              </Popover>
            </Box>
          )}
        </Box>
      </Box>
    </TaskContainer>
  );
};
