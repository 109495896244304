import { CreateEditUserModel } from "../model/users.model";
import { CreateEditUserDto, UserNotificationsDto } from "./users.api.dto";

export const mapCreateEditUserModelToDto = (
  userModel: CreateEditUserModel
): CreateEditUserDto => ({
  ...userModel,
  sms_subscribed: userModel.sms_subscribed ? 1 : null,
  email_subscribed: userModel.email_subscribed ? 1 : null,
});

export const mapUserNotifications = (data: any): UserNotificationsDto =>
  Array.isArray(data.schedulePlan)
    ? data
    : { ...data, schedulePlan: [data.schedulePlan] };
