export enum RolePermissionFeatures {
  Partners = "Partners",
  Clients = "Clients",
  Roles = "Roles",
  Teams = "Teams",
  Users = "Users",
  Locations = "Locations",
  Assets = "Assets",
  Devices = "Devices",
  Sensors = "Sensors",
  Channels = "Channels",
  Alerts = "Alerts",
}

export enum RolePermissionValues {
  Create = "Create",
  Update = "Edit",
  Delete = "Delete",
  Read = "Read",
}

export enum RoleDevicePermissionValues {
  Ota = "OTA",
  Reboot = "Reboot",
  Actuate = "Actuate",
  SaveAsTemplate = "Save as Template",
  UpdateDeviceId = "Update Device ID",
  ClearData = "Clear Device Data",
  ResetRuntime = "Reset Asset Runtime",
}
