import { DateTimePicker } from "@material-ui/pickers";
import styled from "styled-components";

export const StyledDatePicker = styled(DateTimePicker)`
  width: 100%;

  label {
    color: #9e9e9e;
  }
`;
