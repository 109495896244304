import styled from "styled-components";
import { Card, Typography } from "@material-ui/core";

export const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.card.secondary};
`;

export const StyledCardTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const StyledCardText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
`;
