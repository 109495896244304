import React, { FC } from "react";

import { TaskPriority } from "../../api/workorders.api.dto";
import { ReactComponent as HighestIcon } from "../../../../assets/icons/wo-highest.svg";
import { ReactComponent as LowIcon } from "../../../../assets/icons/wo-low.svg";
import { ReactComponent as LowestIcon } from "../../../../assets/icons/wo-lowest.svg";
import { ReactComponent as MediumIcon } from "../../../../assets/icons/wo-medium.svg";
import { ReactComponent as HighIcon } from "../../../../assets/icons/wo-high.svg";

interface IPriorityIcon {
  priority: TaskPriority;
}

export const PriorityIcon: FC<IPriorityIcon> = ({ priority }) => {
  switch (priority) {
    case TaskPriority.Low:
      return <LowIcon />;
    case TaskPriority.Lowest:
      return <LowestIcon />;
    case TaskPriority.Medium:
      return <MediumIcon />;
    case TaskPriority.Highest:
      return <HighestIcon />;
    case TaskPriority.High:
      return <HighIcon />;
    default:
      return <LowestIcon />;
  }
};
