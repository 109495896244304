import styled from "styled-components";
import { Card, Typography } from "@material-ui/core";

export const StyledDetailsCard = styled.div`
  height: 100%;
  border: 1px solid #e3e3e9;
  border-radius: 4px;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const StyledSecondaryCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.card.secondary};
  padding: 1em;
`;

export const TitleContainer = styled.div`
  text-align: center;
`;

export const TitleChannel = styled.span`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #1f1e4d;
  font-weight: 500;
  margin-right: 0.5em;
`;

export const TitleName = styled.span`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #1f1e4d;
`;

export const CardTextKey = styled(Typography)`
  margin-bottom: 6px;
  font-weight: 600;
`;

export const LastReadingText = styled.div`
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1f1e4d;
  white-space: nowrap;

  span {
    font-weight: 600;
  }
`;

export const RedHealthBar = styled.div`
  min-width: 246px;
  height: 60px;
  margin: 32px 0;
  padding: 0 12px;
  justify-content: center;
  border-radius: 30px;
  background-color: #f15851;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
`;

export const YellowHealthBar = styled.div`
  min-width: 246px;
  height: 60px;
  margin: 32px 0;
  justify-content: center;
  padding: 0 12px;
  border-radius: 30px;
  background-color: #eaca19;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
`;

export const GreenHealthBar = styled.div`
  min-width: 246px;
  height: 60px;
  margin: 32px 0;
  justify-content: center;
  padding: 0 12px;
  border-radius: 30px;
  background-color: #3fc68e;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  svg {
    width: auto;
    height: 70px;
  }
`;
