import React, { FC, useState, MouseEvent } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import { Box, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { loginFormValidationSchema } from "./LoginForm.validation";
import { LoginCredentialsModel } from "../../model/Auth";
import Logo from "../../../../assets/logo.png";
import { FORGOT_PASSWORD } from "../../../app/const/routes";

import {
  StyledCard,
  StyledLink,
  StyledLogo,
  StyledIconButton,
  StyledCheckboxLabel,
  StyledCheckbox,
  StyledTextField,
} from "./LoginForm.style";
import { Button } from "../../../app/component/Button/Button";

interface ILoginForm {
  onSubmit: (formValues: LoginCredentialsModel) => void;
  pending: boolean;
}

export const LoginForm: FC<ILoginForm> = ({ onSubmit, pending }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleMouseDownPassword = (event: MouseEvent) => event.preventDefault();

  return (
    <>
      <StyledCard>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginFormValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <StyledLogo alt="logo" src={Logo} />
              <StyledTextField
                disabled={pending}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email address"
                margin="dense"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                autoComplete="off"
              />
              <StyledTextField
                disabled={pending}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="dense"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={isPasswordVisible ? "text" : "password"}
                value={values.password}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <StyledIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disabled={pending}
                    >
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </StyledIconButton>
                  ),
                }}
              />
              <Grid
                container
                spacing={1}
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        // checked={state.checkedB}
                        // onChange={handleChange}
                        name="rememberMe"
                      />
                    }
                    label={
                      <StyledCheckboxLabel>Remember me</StyledCheckboxLabel>
                    }
                    className="bodySmall"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Box textAlign="right">
                    <StyledLink component={RouterLink} to={FORGOT_PASSWORD}>
                      FORGOT PASSWORD
                    </StyledLink>
                  </Box>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <Button
                    pending={pending}
                    disabled={pending}
                    size="large"
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};
