import React, { FC } from "react";
import { Box, Grid, CircularProgress } from "@material-ui/core";
import {
  DragDropContext,
  Droppable,
  DropResult,
  DroppableProvided,
} from "react-beautiful-dnd";
import { TaskDto, TasksList, TaskStatus } from "../../api/workorders.api.dto";
import { SortBy } from "../../enum/workorders.enum";
import { DropEvent } from "../../model/workorders.model";
import { TaskCard } from "../TaskCard/TaskCard";
import { TaskCardHeader } from "../TaskCardHeader/TaskCardHeader";
import { ListContainer } from "./Swimlane.style";

interface ISwimlane {
  tasksLists: TasksList;
  onTaskReorder: (dropEvent: DropEvent) => void;
  onSortTasks: (type: TaskStatus, sortBy: SortBy, desc: boolean) => void;
  pending: boolean;
  onCardClick: (task: TaskDto) => void;
}

export const Swimlane: FC<ISwimlane> = ({
  tasksLists,
  onTaskReorder,
  onSortTasks,
  pending,
  onCardClick,
}) => {
  const onDragEnd = (result: DropResult): void => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    const dropEvent: DropEvent = {
      droppableIdStart: source.droppableId,
      droppableIdEnd: destination.droppableId,
      droppableIndexStart: source.index,
      droppableIndexEnd: destination.index,
      draggableId,
    };

    onTaskReorder(dropEvent);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        mt={2}
        mb={8}
      >
        <Grid container justify="space-between" spacing={2} wrap="wrap">
          {Object.keys(tasksLists).map((key) => {
            const taskStatusKey = key as TaskStatus;
            return (
              <Grid item xs={12} md={4} key={key}>
                <TaskCardHeader
                  type={taskStatusKey}
                  amount={tasksLists[taskStatusKey].length}
                  onSortTasks={onSortTasks}
                />

                <Droppable droppableId={taskStatusKey}>
                  {(provided: DroppableProvided) => (
                    <ListContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {pending ? (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        tasksLists[taskStatusKey].map((task, index) => (
                          <TaskCard
                            task={task}
                            index={index}
                            key={task.id}
                            onCardClick={onCardClick}
                          />
                        ))
                      )}
                      {provided.placeholder}
                    </ListContainer>
                  )}
                </Droppable>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </DragDropContext>
  );
};
