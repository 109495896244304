import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { ADD_TASK_MODAL } from "../../../app/const/modals";
import { AddTaskModalContainer } from "../../container/TasksModalContainer";
import { TasksSearchContainer } from "../../container/TasksSearchContainer";
import { MyStuffChipContainer } from "../../container/MyStuffChipContainer";
import { TaskType } from "../../api/workorders.api.dto";

export const SwimlaneHeader: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Box mt={1} mb={1}>
        <MyStuffChipContainer />
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box mr={1} mt={1} mb={1}>
          <TasksSearchContainer />
        </Box>
        <Box mt={1} mb={1}>
          <AddTaskModalContainer name={ADD_TASK_MODAL} type={TaskType.Manual} />
        </Box>
      </Box>
    </Box>
  );
};
