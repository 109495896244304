import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  deleteUserAvatar,
  editUserAvatar,
  editUserDetails,
  fetchUserDetails as fetchUserDetailsAction,
} from "../store/users.action";
import { ProfileForm } from "../component/ProfileForm/ProfileForm";
import {
  selectFetchUserDetailsData,
  selectIsEditUserAvatarStatusPending,
  selectIsEditUserDetailsStatusPending,
  selectIsFetchUserDetailsStatusPending,
} from "../store/users.selector";
import { EditUserDetailsModel } from "../model/users.model";
import { fetchProfile } from "../../auth/store/auth.action";

export const UserProfileContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userDetailsData = useSelector(selectFetchUserDetailsData);
  const isUserDetailsPending = useSelector(
    selectIsFetchUserDetailsStatusPending
  );
  const isEditUserDetailsPending = useSelector(
    selectIsEditUserDetailsStatusPending
  );
  const isEditUserAvatarPending = useSelector(
    selectIsEditUserAvatarStatusPending
  );

  useEffect(() => {
    dispatch(fetchUserDetailsAction({}));
  }, [dispatch]);

  const handleSubmit = async (values: EditUserDetailsModel) => {
    if (userDetailsData) {
      await dispatch(
        editUserDetails({
          id: userDetailsData.id,
          editUserDetailsModel: {
            ...values,
            role: userDetailsData.role_id || null,
          },
        })
      );
      await dispatch(fetchUserDetailsAction({}));
      await dispatch(fetchProfile({}));
    }
  };

  const handleOnImageChange = async (file?: File) => {
    if (file && userDetailsData) {
      await dispatch(editUserAvatar({ file, userId: userDetailsData.id }));
      await dispatch(fetchUserDetailsAction({}));
      await dispatch(fetchProfile({}));
    } else if (userDetailsData) {
      await dispatch(deleteUserAvatar({ userId: userDetailsData.id }));
      await dispatch(fetchUserDetailsAction({}));
      await dispatch(fetchProfile({}));
    }
  };

  return (
    <ProfileForm
      profile={userDetailsData}
      onImageChange={handleOnImageChange}
      onSubmit={handleSubmit}
      isPending={
        isUserDetailsPending ||
        isEditUserDetailsPending ||
        isEditUserAvatarPending
      }
    />
  );
};
