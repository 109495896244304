import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC, useState } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  StyledCloseIcon,
  StyledEditIcon,
  StyledDialogTitleContainer,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { ClientModalForm } from "../ClientModalForm/ClientModalForm";
import { ClientModel, CreateEditClientModel } from "../../model/clients.model";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";
import { PartnerDto } from "../../../partners/api/partners.api.dto";

export interface IClientModal {
  name: string;
  onClose: () => void;
  onSubmit: (client: CreateEditClientModel, id?: number) => void;
  client?: ClientModel;
  industries: string[];
  partners: PartnerDto[];
  onDelete?: (id: number) => void;
  isPending: boolean;
}

export const ClientModal: FC<IClientModal> = ({
  name,
  isPending,
  onClose,
  onSubmit,
  client,
  industries,
  partners,
  onDelete,
}) => {
  const [editView, setEditView] = useState(false);

  const handleOnDelete = () => {
    if (onDelete && client) {
      onDelete(client.id);
    }
  };

  const getModalHeader = () => {
    if (client && editView) {
      return <Typography variant="h2">Edit Client</Typography>;
    }
    if (client && !editView) {
      return <Typography variant="h2">View Client</Typography>;
    }
    return <Typography variant="h2">Add Client</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid container item xs={4} justify="flex-start">
        {editView && (
          <ConnectedShowForPermissions
            feature="clients"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView && (
          <Box display="inline" mr={1}>
            <Button
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
              disabled={pending}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box display="inline">
          {editView ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
            >
              Close
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button onClick={onClose} disabled={pending} color="primary">
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add Client
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (client) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        {!editView && client && (
          <ConnectedShowForPermissions
            feature="clients"
            permissions={{ allOf: ["update"] }}
          >
            <StyledIconButton
              aria-label="edit"
              onClick={() => setEditView(true)}
            >
              <StyledEditIcon />
            </StyledIconButton>
          </ConnectedShowForPermissions>
        )}
        <StyledIconButton
          aria-label="close"
          onClick={() => {
            onClose();
            setEditView(false);
          }}
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = (pending: boolean) => {
    return (
      <ClientModalForm
        client={client}
        industries={industries}
        partners={partners}
        onSubmit={onSubmit}
        displayActionButtons={() => displayActionButtons(pending)}
        isPending={pending}
        editView={editView}
      />
    );
  };

  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
    />
  );
};
