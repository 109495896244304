import { createAsyncThunk } from "@reduxjs/toolkit";

import withError from "../../app/util/with-thunk-error";
import { fetchAlerts as fetchAlertsRequest } from "../api/alerts";
import { AlertModel } from "../model/alerts.model";

export const fetchAlerts = createAsyncThunk(
  "alerts/fetchAlerts",
  withError(
    async (clientId: number): Promise<AlertModel[]> => {
      return fetchAlertsRequest(clientId);
    }
  )
);
