import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";

import { ApiNotifications } from "../component/ApiNotifications/ApiNotifications";
import { fetchApiNotifications } from "../store/apiNotifications.action";
import { selectApiNotificiationData } from "../store/apiNotifications.selector";
import { useInterval } from "../../app/hooks/useInterval";

export const ApiNotificationsContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const apiNotifications = useSelector(selectApiNotificiationData);

  useEffect(() => {
    dispatch(fetchApiNotifications());
  }, [dispatch]);

  useInterval(() => {
    dispatch(fetchApiNotifications());
  });

  return <ApiNotifications apiNotifications={apiNotifications} />;
};
