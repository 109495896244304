import React, { FC } from "react";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { StyledIconContainer } from "./ApiNotificationIcon.style";
import { ApiNotificationType } from "../../api/apiNotifications.api.dto";
import { ReactComponent as ManualIcon } from "../../../../assets/icons/wo-manual.svg";
import { ReactComponent as LowHealthIcon } from "../../../../assets/low-health-icon.svg";
import { ReactComponent as GrayAlarmIcon } from "../../../../assets/grayAlarm.svg";

const WORKORDER_COLOR = "#6997FC";
const WORKORDER_COLOR_BG = "#6997FC20";
const ALARM_COLOR = "#F15851";
const ALARM_COLOR_BG = "#F1585120";
const LOST_CONNECTION_COLOR = "#AAAAAA";
const LOST_CONNECTION_COLOR_BG = "#AAAAAA20";
const WORKORDER_DONE_COLOR = "#7CE2B8";
const WORKORDER_DONE_COLOR_BG = "#7CE2B820";

interface IApiNotificationIcon {
  type: ApiNotificationType;
}

export const ApiNotificationIcon: FC<IApiNotificationIcon> = ({ type }) => {
  switch (type) {
    case ApiNotificationType.Alarm:
      return (
        <StyledIconContainer bgColor={ALARM_COLOR_BG}>
          <GrayAlarmIcon color={ALARM_COLOR} />
        </StyledIconContainer>
      );
    case ApiNotificationType.Info:
      return (
        <StyledIconContainer bgColor={WORKORDER_COLOR_BG}>
          <InfoIcon style={{ color: WORKORDER_COLOR }} />
        </StyledIconContainer>
      );
    case ApiNotificationType.LostConnection:
      return (
        <StyledIconContainer bgColor={LOST_CONNECTION_COLOR_BG}>
          <InfoIcon style={{ color: LOST_CONNECTION_COLOR }} />
        </StyledIconContainer>
      );
    case ApiNotificationType.LowHealth:
      return (
        <StyledIconContainer bgColor={ALARM_COLOR_BG}>
          <LowHealthIcon color={ALARM_COLOR} />
        </StyledIconContainer>
      );
    case ApiNotificationType.Workorder:
      return (
        <StyledIconContainer bgColor={WORKORDER_COLOR_BG}>
          <ManualIcon color={WORKORDER_COLOR} />
        </StyledIconContainer>
      );
    case ApiNotificationType.WorkorderDone:
      return (
        <StyledIconContainer bgColor={WORKORDER_DONE_COLOR_BG}>
          <CheckCircleIcon style={{ color: WORKORDER_DONE_COLOR }} />
        </StyledIconContainer>
      );
    default:
      return (
        <StyledIconContainer bgColor={WORKORDER_COLOR_BG}>
          <InfoIcon style={{ color: WORKORDER_COLOR }} />
        </StyledIconContainer>
      );
  }
};
