import React, { FC, useState } from "react";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  CenteredCell,
  StyledNAHealth,
  RightAlignedCell,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import { SensorTableDto, SensorDto, AssetDto } from "../../api/asset.api.dto";
import { CreateEditSensorModel } from "../../model/asset.model";
import { SensorTypeDto } from "../../../sensorTypes/api/sensorTypes.api.dto";
import { AssetHealthThresholds } from "../../enum/assets.enum";
import {
  StyledCard,
  StyledWarningIcon,
  StyledBadHealth,
  StyledFairHealth,
  StyledGoodHealth,
} from "./AssetSensorsTable.style";
import { AssetSensorModal } from "../AssetSensorModal/AssetSensorModal";
import {
  ADD_ASSET_SENSOR_MODAL,
  EDIT_ASSET_SENSOR_MODAL,
} from "../../../app/const/modals";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";

interface IAssetSensorsTable {
  onSubmit: (values: CreateEditSensorModel, id?: number) => void;
  onDeleteSensor: (id: number) => void;
  onOpenModal: (name: string) => void;
  onCloseModal: (name: string) => void;
  rows: SensorTableDto[];
  assetId: number;
  sensors: SensorDto[];
  sensorTypes: SensorTypeDto[];
  isTablePending: boolean;
  isModalPending: boolean;
  asset: AssetDto | null;
}

export const AssetSensorsTable: FC<IAssetSensorsTable> = ({
  rows,
  assetId,
  sensors,
  sensorTypes,
  isTablePending,
  isModalPending,
  onSubmit,
  onDeleteSensor,
  onOpenModal,
  onCloseModal,
  asset,
}) => {
  const [modalName, setModalName] = useState<string>(ADD_ASSET_SENSOR_MODAL);
  const [selectedSensor, setSelectedSensor] = useState<SensorDto | undefined>(
    undefined
  );
  const [selectedChannel, setSelectedChannel] = useState<number | undefined>(
    undefined
  );
  const [selectedChannelDevice, setSelectedChannelDevice] = useState<
    number | undefined
  >(undefined);

  const openModal = (
    tableSensor: SensorTableDto,
    deviceId: number | undefined
  ) => {
    const sensor = sensors.find(
      (dataSensor) => dataSensor.id === tableSensor.sensorId
    );
    setSelectedSensor(sensor || undefined);
    setSelectedChannel(tableSensor.channel);
    setSelectedChannelDevice(deviceId);
    onOpenModal(EDIT_ASSET_SENSOR_MODAL);
  };

  const openAddModal = (channelIndex: number, deviceId: number | undefined) => {
    setSelectedSensor(undefined);
    setSelectedChannel(channelIndex);
    setSelectedChannelDevice(deviceId);
    onOpenModal(ADD_ASSET_SENSOR_MODAL);
  };

  const closeModal = () => {
    setSelectedSensor(undefined);
    onCloseModal(modalName);
  };

  const renderHealthRating = (
    health: number | null,
    alarmType: string | null
  ) => {
    const numHealth = health ? Number(health) : health;
    if (
      !asset?.health_is_enabled ||
      alarmType === "N/A" ||
      alarmType === null ||
      health === null
    ) {
      return <StyledNAHealth>N/A</StyledNAHealth>;
    }
    if (health && health <= AssetHealthThresholds.bad) {
      return (
        <StyledBadHealth>
          {numHealth === 0 ? numHealth.toFixed() : numHealth?.toFixed(1)}
        </StyledBadHealth>
      );
    }
    if (health && health <= AssetHealthThresholds.fair) {
      return <StyledFairHealth>{numHealth?.toFixed(1)}</StyledFairHealth>;
    }
    return (
      <StyledGoodHealth>
        {numHealth === 10 ? numHealth.toFixed() : numHealth?.toFixed(1)}
      </StyledGoodHealth>
    );
  };

  const columns: Column[] = [
    {
      id: "alarm",
      Header: () => <CenteredCell>Alarm</CenteredCell>,
      accessor: "alarm",
      width: 50,
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) =>
        original.alarm ? (
          <CenteredCell>
            <StyledWarningIcon color="error" />
          </CenteredCell>
        ) : (
          ""
        ),
    },
    {
      id: "channel",
      Header: () => <CenteredCell>Channel</CenteredCell>,
      accessor: "channel",
      width: 60,
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return <CenteredCell>{original.channel}</CenteredCell>;
      },
    },
    {
      id: "health",
      Header: () => <CenteredCell>Health</CenteredCell>,
      accessor: "health",
      width: 60,
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return (
          <CenteredCell>
            {renderHealthRating(original?.health, original.alarmType)}
          </CenteredCell>
        );
      },
    },
    {
      id: "deviceName",
      Header: "Device Name",
      accessor: "deviceName",
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return original.deviceName;
      },
    },
    {
      id: "sensorName",
      Header: "Sensor Name",
      accessor: "sensorName",
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return original.sensorName;
      },
    },
    {
      id: "sensorType",
      Header: "Sensor Type",
      accessor: "sensorType",
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return original.sensorType;
      },
    },
    {
      id: "lastReading",
      Header: "Last Reading",
      accessor: "lastReading",
      width: 140,
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return original.lastReading;
      },
    },
    {
      id: "alarmSetPoint",
      Header: "Alarm Set-Point",
      accessor: "alarmSetPoint",
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return <CenteredCell>{original.alarmSetPoint}</CenteredCell>;
      },
    },
    {
      id: "alarmType",
      Header: "Alarm Type",
      accessor: "alarmType",
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return <CenteredCell>{original.alarmType}</CenteredCell>;
      },
    },
    {
      id: "recording",
      Header: () => <CenteredCell>Recording</CenteredCell>,
      accessor: "recording",
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => {
        return <CenteredCell>{original.recording}</CenteredCell>;
      },
    },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: SensorTableDto } }) => (
        <ActionCellWrapper>
          {original.sensorId !== null && original.sensorId !== undefined ? (
            <Button
              variant="table-action"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setModalName(EDIT_ASSET_SENSOR_MODAL);
                openModal(original, original?.device_id);
              }}
              disabled={isModalPending}
            >
              View
            </Button>
          ) : (
            <ConnectedShowForPermissions
              feature="channels"
              permissions={{ allOf: ["create"] }}
            >
              <Button
                variant="table-action"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalName(ADD_ASSET_SENSOR_MODAL);
                  openAddModal(original.channel, original?.device_id);
                }}
                disabled={isModalPending}
              >
                Add
              </Button>
            </ConnectedShowForPermissions>
          )}
        </ActionCellWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={rows}
          hover
          sorting={{ id: "channel", desc: false }}
          formView
        />
      </StyledCard>
      <AssetSensorModal
        name={modalName}
        assetId={assetId}
        sensor={selectedSensor}
        sensorTypes={sensorTypes}
        onClose={closeModal}
        onSubmit={onSubmit}
        onDelete={onDeleteSensor}
        isPending={isModalPending}
        selectedChannel={selectedChannel}
        selectedChannelDevice={selectedChannelDevice}
      />
    </>
  );
};
