import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { fetchLocations } from "../../location/store/locations.action";
import { fetchTeams } from "../../teams/store/teams.action";
import {
  selectAssetsFilteredData,
  selectCategories,
  selectTags,
  selectIsAssetsTableStatusPending,
  selectIsTagsStatusPending,
  selectIsCategoriesStatusPending,
} from "../store/asset.selector";
import {
  selectIsLocationsStatusPending,
  selectLocationsData,
} from "../../location/store/locations.selector";
import {
  selectIsTeamsTableStatusPending,
  selectTeamsTableData,
} from "../../teams/store/teams.selector";
import { AppDispatch } from "../../app/store";
import { AssetsTable } from "../component/AssetsTable/AssetsTable";
import {
  editAsset as editAssetAction,
  deleteAsset as deleteAssetAction,
  fetchCategories,
  fetchTags,
} from "../store/asset.action";
import { clearAssetState } from "../store/asset.slice";
import { EDIT_ASSET_MODAL } from "../../app/const/modals";
import { CreateEditAssetModel } from "../model/asset.model";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import {
  selectFetchDevicesData,
  selectIsFetchDevicesStatusPending,
} from "../../devices/store/devices.selector";
import { TeamDto } from "../../teams/api/teams.api.dto";

export interface IAssetsTableContainerProps {
  setIsInitialDataFetched: React.Dispatch<React.SetStateAction<boolean>>;
  isInitialDataFetched: boolean;
}
export const AssetsTableContainer: FC<IAssetsTableContainerProps> = ({
  setIsInitialDataFetched,
  isInitialDataFetched,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const tags = useSelector(selectTags);
  const isFetchTagsPending = useSelector(selectIsTagsStatusPending);
  const locations = useSelector(selectLocationsData);
  const isFetchLocationsPending = useSelector(selectIsLocationsStatusPending);
  const teams = useSelector(selectTeamsTableData);
  const isFetchTeamsPending = useSelector(selectIsTeamsTableStatusPending);
  const categories = useSelector(selectCategories);
  const isFetchCategoriesPending = useSelector(selectIsCategoriesStatusPending);
  const clients = useSelector(selectClientsData);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const devices = useSelector(selectFetchDevicesData);
  const isFetchDevicesPending = useSelector(selectIsFetchDevicesStatusPending);
  const isAssetsPending = useSelector(selectIsAssetsTableStatusPending);
  const filteredAssets = useSelector(selectAssetsFilteredData);
  const { search } = useLocation();
  const { clientId }: { clientId?: string } = parse(search);

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_ASSET_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_ASSET_MODAL }));

  const onEditAsset = (editAssetModel: CreateEditAssetModel, id?: number) =>
    id && dispatch(editAssetAction({ id, editAssetModel }));

  const onDeleteAsset = (id: number) => dispatch(deleteAssetAction(id));

  useEffect(() => {
    return () => {
      dispatch(clearAssetState());
    };
  }, [dispatch, clientId]);
  return (
    <AssetsTable
      // TODO: quick fix, update model to handle new type
      assets={filteredAssets.map((asset: any) => ({
        ...asset,
        categories: asset.categories
          .map((row: { name: string }) => row.name)
          .join(", ") as any,
        location: !asset.location ? "" : asset.location.title,
        teams: asset.teams.map((row: TeamDto) => row.name).join(", "),
      }))}
      isTablePending={isAssetsPending}
      isInitialDataFetched={isInitialDataFetched}
      tags={tags}
      locations={locations}
      teams={teams}
      categories={categories}
      isModalPending={
        isFetchTagsPending ||
        isFetchLocationsPending ||
        isFetchTeamsPending ||
        isFetchCategoriesPending ||
        isFetchClientsPending ||
        isFetchDevicesPending
      }
      clients={clients}
      devices={devices}
      onEditAsset={onEditAsset}
      onDeleteAsset={onDeleteAsset}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
