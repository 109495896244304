import styled from "styled-components";
import { Build } from "@material-ui/icons";
import { TextField, Select, Avatar, Typography, Box } from "@material-ui/core";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import { BACKGROUND_DEFAULT, TEXT_PRIMARY } from "../../../app/theme/colors";

export const ManualIcon = styled(Build)`
  font-size: 1.75em;
  border-radius: 50%;
  color: white;
  padding: 4px;
  background-color: ${(props) => props.theme.palette.grey[800]};
`;

export const Event = styled.small`
  padding: 7px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-radius: 4px;
  background: #e5e5e5;
`;

export const Sidebar = styled(StyledModalContent)`
  flex-direction: column;
  background: #f1f3f4;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 90vh;
  }
`;

export const StyledSidebarContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  }
`;

export const StyledContentContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  }
`;

export const StyledTitleContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: end;
  }
`;

export const Content = styled(StyledModalContent)`
  background: white;
  flex-direction: column;
  justify-content: between;
  padding: 24px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 90vh;
  }
`;

export const TypeSelect = styled(Select)`
  fieldset {
    border: 0;
  }

  &:hover {
    box-shadow: #e5e5e5 0 0 0 2px;
    border-radius: 4px;
  }

  .MuiSelect-icon {
    display: none;
  }

  .MuiSelect-selectMenu {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  &:before {
    content: none !important;
  }
`;

export const StyledTextField = styled(TextField)<{
  labelBackground?: string;
}>`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }

  .MuiFormLabel-root {
    background-color: ${(props) => props.labelBackground ?? "white"};
    padding: 0 5px;
  }

  .Mui-focused {
    svg {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export const StyledScrollBox = styled.div`
  max-height: 90vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const PickerWrapper = styled.div`
  fieldset {
    border: 0;
  }

  input {
    padding: 4px 0;
    color: #000;
  }

  &:hover {
    box-shadow: #e5e5e5 0 0 0 2px;
    border-radius: 4px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  background-color: ${BACKGROUND_DEFAULT};
  color: ${TEXT_PRIMARY};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  border: 1px solid #fff;
`;

export const StyledWatchersAvatar = styled(Avatar)`
  width: 1.9em;
  height: 1.9em;
  margin-right: 0.3em;
  margin-bottom: 0.3em;
  background-color: ${BACKGROUND_DEFAULT};
  color: ${TEXT_PRIMARY};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  border: 1px solid #fff;
`;

export const DropzoneContainer = styled.div`
  text-align: center;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  border: 2px dashed ${({ theme }) => theme.palette.border.dark};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
`;

export const StyledSelectField = styled(StyledTextField)`
  .MuiInputBase-input {
    color: black;
    padding-bottom: 0;
  }

  &.MuiFormControl-marginDense {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledAutocompleteField = styled(StyledTextField)`
  .MuiInputBase-input {
    color: black;
    padding-bottom: 0;
  }

  .MuiAutocomplete-inputRoot {
    padding: 0 !important;
  }

  .MuiSvgIcon-root {
    display: none;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 50px 8px 10px;
`;

export const StyledTypography = styled(Typography)`
  margin-left: 20px;
`;

export const StyledMenuIconsContainer = styled(Box)`
  position: absolute;
  top: ${({ theme }) => `${theme.spacing(2)}px`};
  right: ${({ theme }) => `${theme.spacing(2)}px`};
`;
