import { useSelector } from "react-redux";
import { selectProfileData } from "../../auth/store/auth.selector";
import {
  RolePermissions,
  RolePermissionValues,
  RoleDevicePermissionValues,
} from "../../roles/api/roles.api.dto";

export const useShowForPermissions = (
  feature: keyof RolePermissions,
  permissions: {
    allOf?: (RolePermissionValues | RoleDevicePermissionValues)[];
    oneOf?: (RolePermissionValues | RoleDevicePermissionValues)[];
  }
) => {
  const profileData = useSelector(selectProfileData);
  const userPermissions = profileData?.role_permissions;
  if (!userPermissions || !userPermissions[feature]) {
    return false;
  }
  if (permissions.allOf) {
    return permissions.allOf.every((value) =>
      (userPermissions[feature] as string[]).includes(value as string)
    );
  }
  if (permissions.oneOf) {
    return permissions.oneOf.some((value) =>
      (userPermissions[feature] as string[]).includes(value as string)
    );
  }
  return false;
};
