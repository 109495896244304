import React, { FC, useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { TextFieldProps } from "@material-ui/core/TextField";
import { DatePickerView } from "@material-ui/pickers/DatePicker/DatePicker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { datePickerTheme } from "./datePicker.theme";
import { StyledDatePicker } from "./DatePicker.style";

interface IDatePicker {
  placeholder?: string;
  className?: string;
  value: Moment | null;
  onChange: (date: Moment) => void;
  label: string;
  variant: TextFieldProps["variant"];
  disabled: boolean;
  maxDate?: Moment;
  maxDateMessage?: string;
  minDate?: Moment;
  minDateMessage?: string;
  disableFuture?: boolean;
  views?: DatePickerView[];
  format?: string;
  size?: "small" | "medium";
  name?: string;
  InputProps?: TextFieldProps["InputProps"];
}

export const DatePicker: FC<IDatePicker> = ({
  placeholder,
  className,
  value,
  onChange,
  label,
  variant,
  disabled,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  disableFuture,
  views,
  format,
  size,
  name,
  InputProps,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleOnChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);

    if (onChange) {
      onChange(date as Moment);
    }
  };

  return (
    <MuiThemeProvider theme={datePickerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <StyledDatePicker
          placeholder={placeholder}
          className={className}
          inputVariant={variant}
          label={label}
          disabled={disabled}
          disableFuture={disableFuture}
          maxDate={maxDate}
          maxDateMessage={maxDateMessage}
          minDate={minDate}
          minDateMessage={minDateMessage}
          format={format || "MM/DD/yyyy"}
          value={selectedDate}
          onChange={handleOnChange}
          animateYearScrolling
          views={views}
          size={size}
          name={name}
          InputProps={InputProps}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};
