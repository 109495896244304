import styled from "styled-components";
import {
  Box,
  Card,
  Link,
  IconButton,
  Typography,
  Checkbox,
  TextField,
} from "@material-ui/core";

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  min-height: 275px;
  display: flex;
`;

// eslint-disable-next-line
export const StyledLink = styled<any>(Link)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  text-align: right;
  color: #1f1e4d;
  display: inline-block;
`;

export const StyledBox = styled(Box)`
  position: relative;
`;

export const StyledLogo = styled.img`
  height: 32px;
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`}; ;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledCheckboxLabel = styled(Typography)`
  color: #5f6368;
  font-size: 13px;
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(0.9);
  color: #5f6368;
`;

export const StyledTextField = styled(TextField)`
  .MuiInputLabel-marginDense {
    background-color: white;
    padding-left: 14px;
  }
`;
