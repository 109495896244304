import React, { FC, useState } from "react";
import { Column } from "react-table";
import { UserRole } from "../../../app/enum/UserRole";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  StyledCard,
} from "../../../app/component/Table/Table.style";
import {
  CreateEditLocationModel,
  LocationModel,
} from "../../model/locations.model";
import { Button } from "../../../app/component/Button/Button";
import { StyledRouterLink } from "./LocationsTable.style";
import { LocationModal } from "../LocationModal/LocationModal";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { EDIT_LOCATION_MODAL } from "../../../app/const/modals";
import { useShowForRole } from "../../../app/hooks/useShowForRole";

interface ILocationsTable {
  onEditLocation: (location: CreateEditLocationModel) => void;
  onDeleteLocation: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  locations: LocationModel[];
  clients: ClientDto[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const LocationsTable: FC<ILocationsTable> = ({
  clients,
  locations,
  isTablePending,
  isModalPending,
  onEditLocation,
  onDeleteLocation,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<
    LocationModel | undefined
  >(undefined);

  const openModal = (location: LocationModel) => {
    setSelectedLocation(location);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedLocation(undefined);
    onCloseModal();
  };

  // TODO: Location Name linkes to filtered assets view where only assets from location
  const columns: Column[] = [
    {
      Header: "Location Name",
      id: "title",
      accessor: "title",
    },
    ...(useShowForRole([UserRole.SuperUser, UserRole.SuperAdmin])
      ? [
          {
            Header: "Client",
            accessor: "clientName",
          },
        ]
      : []),
    { Header: "Description", accessor: "description" },
    {
      id: "actions-column",
      disableSortBy: true,
      width: "200px",
      Cell: ({ row: { original } }: { row: { original: LocationModel } }) => (
        <ActionCellWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            View
          </Button>
        </ActionCellWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={locations}
          hover
          sorting={{ id: "title", desc: false }}
        />
      </StyledCard>
      <LocationModal
        name={EDIT_LOCATION_MODAL}
        location={selectedLocation}
        onClose={closeModal}
        onSubmit={onEditLocation}
        onDelete={onDeleteLocation}
        clients={clients}
        isPending={isModalPending}
      />
    </>
  );
};
