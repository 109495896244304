import { authApiClient } from "../../app/api/authApi.client";
import {
  SensorTypeDto,
  CreateEditSensorTypeDto,
  HardcodedSensorType,
  HardcodedSensorUnit,
} from "./sensorTypes.api.dto";

export const fetchSensorTypes = async (): Promise<SensorTypeDto[]> => {
  try {
    const response = await authApiClient.get("/sensor_type");
    return response.data.sensorTypes;
  } catch (error) {
    throw error;
  }
};

export const fetchHardcodedSensorTypes = async (): Promise<
  HardcodedSensorType[]
> => {
  try {
    const response = await authApiClient.get("/sensor_type/types");
    return response.data.types;
  } catch (error) {
    throw error;
  }
};

export const fetchHardcodedSensorUnits = async (): Promise<
  HardcodedSensorUnit[]
> => {
  try {
    const response = await authApiClient.get("/sensor_type/units");
    return response.data.units;
  } catch (error) {
    throw error;
  }
};

export const createSensorType = async (
  sensorType: CreateEditSensorTypeDto
): Promise<void> => {
  try {
    const response = await authApiClient.post("/sensor_type", sensorType);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSensorType = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/sensor_type/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editSensorType = async (
  id: number,
  formData: FormData
): Promise<void> => {
  try {
    // Workaround to make the Laravel API handle multipart-formdata with the PUT method
    formData.append("_method", "put");
    const response = await authApiClient.post(`/sensor_type/${id}`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
