import React, { FC, useState } from "react";
import { Column } from "react-table";
import { ConnectedShowForRole } from "../../../app/component/UserManagement/ConnectedShowForRole";
import { UserRole } from "../../../app/enum/UserRole";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  StyledCard,
  StyledPartnerTypography,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import {
  PartnerModel,
  CreateEditPartnerModel,
} from "../../model/partners.model";
import { PartnerModal } from "../PartnerModal/PartnerModal";
import { IndustryDto } from "../../../industry/api/industry.api.dto";
import { EDIT_PARTNER_MODAL } from "../../../app/const/modals";

interface IPartnersTable {
  onEditPartner: (
    editPartnerModel: CreateEditPartnerModel,
    id?: number
  ) => void;
  onDeletePartner: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  partners: PartnerModel[];
  industries: IndustryDto[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const PartnersTable: FC<IPartnersTable> = ({
  partners,
  industries,
  isTablePending,
  isModalPending,
  onEditPartner,
  onDeletePartner,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedPartner, setSelectedPartner] = useState<
    PartnerModel | undefined
  >(undefined);

  const openModal = (client: PartnerModel) => {
    setSelectedPartner(client);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedPartner(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    {
      Header: "Partner Name",
      id: "name",
      accessor: "name",
    },
    {
      Header: "Clients",
      accessor: "clients_number",
      Cell: ({ row: { original } }: { row: { original: PartnerModel } }) => {
        let clients: string[] = [];
        if (original.clients.length > 0) {
          clients = original.clients.map((client) => {
            return client.name;
          });
        }

        return (
          <StyledPartnerTypography>
            {clients.toString().replaceAll(",", ", ")}
          </StyledPartnerTypography>
        );
      },
    },
    { Header: "Contact Email", accessor: "email" },
    { Header: "Contact Phone", accessor: "phone" },
    { Header: "Industry", accessor: "industry" },
    { Header: "Status", accessor: "status" },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: PartnerModel } }) => (
        <ActionCellWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            Account Info
          </Button>
        </ActionCellWrapper>
      ),
    },
  ];

  return (
    <ConnectedShowForRole roles={[UserRole.SuperUser, UserRole.SuperAdmin]}>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={partners}
          hover
          sorting={{ id: "name", desc: false }}
        />
      </StyledCard>
      <PartnerModal
        name={EDIT_PARTNER_MODAL}
        partner={selectedPartner}
        onClose={closeModal}
        onSubmit={onEditPartner}
        onDelete={onDeletePartner}
        industries={industries}
        isPending={isModalPending}
      />
    </ConnectedShowForRole>
  );
};
