import React from "react";
import styled, { css } from "styled-components";
import { Card, CardHeader, Container, ContainerProps } from "@material-ui/core";
import { Page } from "./Page/Page";

export const StyledRoot = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.background.dark};
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 64px;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  align-content: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(0, 1)},
    ${({ theme }) => theme.mixins.toolbar};
`;

export const StyledContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

export const StyledFormContainer = styled(Card)`
  padding: 20px;
`;

export const StyledPage = styled(({ minHeight, ...props }) => (
  <Page {...props} />
))<{ minHeight?: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-top: ${({ theme }) => `${theme.spacing(3)}px`};
  ${({ minHeight }) =>
    minHeight
      ? css`
          min-height: 100%;
        `
      : css`
          height: 100%;
        `}
`;

export const FullHeightContainer = styled(Container)<ContainerProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > button,
  > div {
    margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
    margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
    :last-child {
      margin-right: 0;
    }
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  span {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const FullHeightCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
`;
