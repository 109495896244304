import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import { fetchAssetAlertHistory } from "../store/asset.action";
import { AssetAlertHistoryTable } from "../component/AssetAlertHistoryTable/AssetAlertHistoryTable";
import {
  selectFetchAssetAlertsData,
  selectIsFetchAssetAlertsStatusPending,
} from "../store/asset.selector";

export const AssetAlertHistoryTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ assetId: string }>();
  const assetAlertsData = useSelector(selectFetchAssetAlertsData);
  const isAssetAlertsDataPending = useSelector(
    selectIsFetchAssetAlertsStatusPending
  );

  useEffect(() => {
    dispatch(fetchAssetAlertHistory(Number(params.assetId)));
  }, [dispatch, params]);

  return (
    <AssetAlertHistoryTable
      data={assetAlertsData}
      isPending={isAssetAlertsDataPending}
    />
  );
};
