import React, { FC } from "react";
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { StyledDeleteButton, StyledTableButton } from "./Button.style";

export interface IButton extends Omit<ButtonProps, "variant"> {
  pending?: boolean;
  variant?: "text" | "outlined" | "contained" | "delete" | "table-action";
  startIcon?: React.ReactNode;
}

export const Button: FC<IButton> = ({
  pending,
  startIcon,
  variant,
  ...rest
}) => {
  if (variant === "delete") {
    return <StyledDeleteButton {...rest} startIcon={<CloseIcon />} />;
  }
  if (variant === "table-action") {
    return <StyledTableButton variant="outlined" {...rest} />;
  }
  return (
    <MuiButton
      {...rest}
      variant={variant}
      startIcon={
        pending ? <CircularProgress size={16} color="inherit" /> : startIcon
      }
    />
  );
};
