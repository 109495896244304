import { TEXT_PRIMARY, TEXT_LINK, TEXT_LINK_HOVER } from "./colors";

export default {
  h1: {
    fontWeight: 700,
    fontSize: "32px",
  },
  h2: {
    fontWeight: 700,
    fontSize: "24px",
  },
  h3: {
    fontWeight: 700,
    fontSize: "20px",
  },
  h4: {
    fontWeight: 500,
    fontSize: "16px",
  },
  h5: {
    fontWeight: 500,
    fontSize: "14px",
  },
  bodyStandard: {
    fontWeight: 400,
    fontSize: "16px",
  },
  bodySmall: {
    fontWeight: 400,
    fontSize: "14px",
  },
  bodyTiny: {
    fontWeight: 400,
    fontSize: "12px",
  },
  columnHeader: {
    fontWeight: 500,
    fontSize: "14px",
  },
  tableContent: {
    fontWeight: 400,
    fontSize: "14px",
  },
  tabName: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "16px",
  },
  sideNavigationLink: {
    fontWeight: 500,
    fontSize: "14px",
  },
  breadcrumbLink: {
    fontWeight: 400,
    fontSize: "12px",
  },
  link: {
    fontWeight: 500,
    fontSize: "14px",
    color: TEXT_LINK,
    hover: {
      color: TEXT_LINK_HOVER,
    },
  },
  subtitle1: {
    fontSize: "14px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.18px",
    color: TEXT_PRIMARY,
  },
  subtitle2: {
    fontSize: "10px",
    fontWeight: 500,
    letterSpacing: "0.13px",
    color: TEXT_PRIMARY,
  },
  fontWeightBold: 700,
};
