import { TaskDto, TasksList, TaskStatus } from "./workorders.api.dto";

export const mapTasksDtoToTasksList = (tasks: TaskDto[]): TasksList => {
  const openList: TaskDto[] = [];
  const inProgressList: TaskDto[] = [];
  const doneList: TaskDto[] = [];

  tasks.forEach((task) => {
    switch (task.status) {
      case TaskStatus.Open:
        openList.push({ ...task, id: task.id.toString() });
        break;
      case TaskStatus.InProgress:
        inProgressList.push({ ...task, id: task.id.toString() });
        break;
      case TaskStatus.Done:
        doneList.push({ ...task, id: task.id.toString() });
        break;
      default:
    }
  });

  return {
    [TaskStatus.Open]: openList,
    [TaskStatus.InProgress]: inProgressList,
    [TaskStatus.Done]: doneList,
  };
};
