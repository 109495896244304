import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { PartnersTable } from "../component/PartnersTable/PartnersTable";
import {
  editPartner as editPartnerAction,
  deletePartner as deletePartnerAction,
  fetchPartners as fetchPartnersAction,
} from "../store/partners.action";
import {
  selectPartnersTableData,
  selectIsPartnersTableStatusPending,
} from "../store/partners.selector";
import { clearFetchPartnersState as clearFetchPartnersStateAction } from "../store/partners.slice";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditPartnerModel } from "../model/partners.model";
import { fetchIndustries as fetchIndustriesAction } from "../../industry/store/industry.action";
import {
  selectFetchIndustriesData,
  selectIsFetchIndustriesStatusPending,
} from "../../industry/store/industry.selector";
import { EDIT_PARTNER_MODAL } from "../../app/const/modals";

export const PartnersTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const partnersTableData = useSelector(selectPartnersTableData);
  const isPartnersPending = useSelector(selectIsPartnersTableStatusPending);
  const industriesData = useSelector(selectFetchIndustriesData);
  const isFetchIndustriesPending = useSelector(
    selectIsFetchIndustriesStatusPending
  );

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_PARTNER_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_PARTNER_MODAL }));

  const onEditPartner = (
    editPartnerModel: CreateEditPartnerModel,
    id?: number
  ) => id && dispatch(editPartnerAction({ id, editPartnerModel }));

  const onDeletePartner = (id: number) => dispatch(deletePartnerAction(id));

  useEffect(() => {
    dispatch(fetchIndustriesAction());
    dispatch(fetchPartnersAction());

    return () => {
      dispatch(clearFetchPartnersStateAction());
    };
  }, [dispatch]);

  return (
    <PartnersTable
      partners={partnersTableData}
      industries={industriesData}
      isTablePending={isPartnersPending}
      isModalPending={isFetchIndustriesPending}
      onEditPartner={onEditPartner}
      onDeletePartner={onDeletePartner}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
