import React, { FC } from "react";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import { AssetModal } from "../AssetModal/AssetModal";
import { PageHeader } from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { IconButtonWrapper } from "./AssetPageHeader.style";
import { CreateEditAssetModel } from "../../model/asset.model";
import { AssetDto, AssetTagDto } from "../../api/asset.api.dto";
import { EDIT_ASSET_MODAL } from "../../../app/const/modals";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { CategoryDto } from "../../../category/api/category.api.dto";
import { LocationDto } from "../../../location/api/locations.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { DeviceListDto } from "../../../devices/api/devices.api.dto";

interface IAssetPageHeader {
  asset?: AssetDto;
  isPending: boolean;
  assetStatus: string;
  isFavorite?: boolean;
  isFulfilled: boolean;
  teams: TeamDto[];
  tags: AssetTagDto[];
  categories: CategoryDto[];
  locations: LocationDto[];
  clients: ClientDto[];
  devices: DeviceListDto[];
  onFavorite: () => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onEditAsset: (editClientModel: CreateEditAssetModel, id?: number) => void;
  onDeleteAsset: (id: number) => void;
  isModalPending: boolean;
  assets?: AssetDto[];
}

export const AssetPageHeader: FC<IAssetPageHeader> = ({
  asset,
  isPending,
  isFavorite,
  teams,
  tags,
  categories,
  locations,
  clients,
  devices,
  onFavorite,
  onOpenModal,
  onCloseModal,
  onEditAsset,
  onDeleteAsset,
  isModalPending,
  assets,
}) => {
  return (
    <>
      <PageHeader title={asset?.name || ""} />
      {asset?.name && (
        <>
          <IconButtonWrapper>
            <IconButton onClick={onOpenModal} disabled={isPending}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onFavorite} disabled={isPending}>
              {isFavorite ? <StarIcon /> : <StarOutlineIcon />}
            </IconButton>
          </IconButtonWrapper>
          <AssetModal
            name={EDIT_ASSET_MODAL}
            asset={asset}
            onClose={onCloseModal}
            onSubmit={onEditAsset}
            onDelete={onDeleteAsset}
            tags={tags}
            locations={locations}
            teams={teams}
            categories={categories}
            clients={clients}
            devices={devices}
            isPending={isModalPending}
            assets={assets}
          />
        </>
      )}
    </>
  );
};
