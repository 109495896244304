import React, { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AssetNotes } from "../component/AssetNotes/AssetNotes";
import { AppDispatch } from "../../app/store";
import {
  deleteAssetNote,
  fetchAssetNotes,
  pinAssetNote,
} from "../store/asset.action";
import {
  selectAssetNotesData,
  selectIsAssetNotesStatusFulfilled,
  selectIsAssetNotesStatusPending,
  selectIsDeleteAssetNoteStatusPending,
  selectIsPinAssetNoteStatusPending,
} from "../store/asset.selector";

export const AssetNotesContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ assetId: string }>();
  const assetNotesData = useSelector(selectAssetNotesData);
  const isAssetNotesPending = useSelector(selectIsAssetNotesStatusPending);
  const isAssetNotesFulfilled = useSelector(selectIsAssetNotesStatusFulfilled);
  const isDeleteAssetNotePending = useSelector(
    selectIsDeleteAssetNoteStatusPending
  );
  const isPinAssetNotePending = useSelector(selectIsPinAssetNoteStatusPending);

  useEffect(() => {
    dispatch(fetchAssetNotes(Number(params.assetId)));
  }, [dispatch, params.assetId]);

  const handleOnDelete = async (noteId: number): Promise<void> => {
    await dispatch(deleteAssetNote(noteId));
    await dispatch(fetchAssetNotes(Number(params.assetId)));
  };

  const handleOnPin = async (
    noteId: number,
    isPinned: boolean
  ): Promise<void> => {
    await dispatch(pinAssetNote({ noteId, isPinned }));
    await dispatch(fetchAssetNotes(Number(params.assetId)));
  };

  return (
    <AssetNotes
      notes={assetNotesData}
      isPending={
        isAssetNotesPending || isDeleteAssetNotePending || isPinAssetNotePending
      }
      isFulfilled={isAssetNotesFulfilled}
      onDelete={handleOnDelete}
      onPin={handleOnPin}
    />
  );
};
