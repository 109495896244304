import * as yup from "yup";

export const DESCRIPTION_CHAR_LIMIT = 500;

export const roleModalFormValidation = yup.object().shape({
  parent_id: yup.number().nullable(true),
  name: yup.string().required("Role Name is required"),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  partner_ids: yup.array().of(yup.number().optional()),
  client_ids: yup.array().of(yup.number().optional()),
});
