export enum LiveGraphSvg {
  Chiller = 0,
  DryPump1800,
  DryPump1800Box,
  DryVacuumPump,
  DryVacuumPumpBlower,
  GasBottle,
  OttoBox,
  ScrollPump,
  WineCentrifuge,
}
