import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { StyledBreadcrumbs } from "./Breadcrumbs.style";

export interface IBreadcrumb {
  label: string;
  path?: string;
}

interface IBreadcrumbs {
  breadcrumbs: IBreadcrumb[];
}

export const Breadcrumbs: FC<IBreadcrumbs> = ({ breadcrumbs }) => {
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  if (breadcrumbs.length === 0) return <></>;

  return (
    <StyledBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs.slice(0, breadcrumbs.length - 1).map((breadcrumb, index) =>
        breadcrumb.path ? (
          <Link
            key={`${breadcrumb}-${index}`}
            color="inherit"
            component={RouterLink}
            to={breadcrumb.path}
          >
            {breadcrumb.label}
          </Link>
        ) : (
          <Typography color="inherit">{breadcrumb.label}</Typography>
        )
      )}
      {lastBreadcrumb &&
        (lastBreadcrumb?.path ? (
          <Link color="inherit" component={RouterLink} to={lastBreadcrumb.path}>
            {lastBreadcrumb.label}
          </Link>
        ) : (
          <Typography color="textPrimary">{lastBreadcrumb.label}</Typography>
        ))}
    </StyledBreadcrumbs>
  );
};
