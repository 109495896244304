import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { NotificationsState, notificationsState } from "./notifications.store";
import { Notification } from "../../enum/Notification";

const slice = createSlice({
  name: "notifications",
  initialState: notificationsState,
  reducers: {
    openSuccessNotification: (state: NotificationsState, { payload }) => [
      ...state,
      {
        key: uuidv4(),
        message: payload,
        options: {
          variant: Notification.Success,
        },
      },
    ],
    openErrorNotification: (state: NotificationsState, { payload }) =>
      payload === "Unauthorised" ||
      payload ===
        "Measurement Schedule cannot be smaller than Measurement Plan" ||
      payload === "At least one channel should be assigned to the Asset"
        ? [
            ...state,
            {
              key: uuidv4(),
              message: payload,
              options: {
                variant: Notification.Error,
              },
            },
          ]
        : [...state],
    openInfoNotification: (state: NotificationsState, { payload }) => [
      ...state,
      {
        key: uuidv4(),
        message: payload,
        options: {
          variant: Notification.Info,
        },
      },
    ],
    openWarningNotification: (state: NotificationsState, { payload }) => [
      ...state,
      {
        key: uuidv4(),
        message: payload,
        options: {
          variant: Notification.Warning,
        },
      },
    ],
    removeNotification: (state: NotificationsState, { payload }) =>
      state.filter((notification) => notification.key !== payload),
  },
});

export const {
  openSuccessNotification,
  openErrorNotification,
  openInfoNotification,
  openWarningNotification,
  removeNotification,
} = slice.actions;
export default slice.reducer;
