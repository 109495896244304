import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { UserDto, UserNotificationsDto } from "../api/users.api.dto";

export interface UsersState {
  fetchUsers: AsyncStateModel<UserDto[]>;
  fetchUserDetails: AsyncStateModel<UserDto | null>;
  deleteUser: AsyncStateModel<null>;
  editUser: AsyncStateModel<null>;
  createUser: AsyncStateModel<null>;
  editUserDetails: AsyncStateModel<null>;
  editUserAvatar: AsyncStateModel<null>;
  editUserNotifications: AsyncStateModel<null>;
  fetchUserNotifications: AsyncStateModel<UserNotificationsDto | null>;
  searchUsers: string;
}

export const usersState: UsersState = {
  fetchUserDetails: {
    status: AsyncStatus.Void,
    data: null,
  },
  editUserDetails: {
    status: AsyncStatus.Void,
    data: null,
  },
  editUserAvatar: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchUsers: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  editUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  createUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchUserNotifications: {
    status: AsyncStatus.Void,
    data: null,
  },
  editUserNotifications: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchUsers: "",
};
