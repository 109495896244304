import React, { FC } from "react";
import { Box, Grid } from "@material-ui/core";

import { AssetAttachmentsContainer } from "../../container/AssetAttachmentsContainer";
import { AssetNotesContainer } from "../../container/AssetNotesContainer";
import { Wrapper } from "./AssetNotesView.style";
import { AddAssetNoteModalContainer } from "../../container/AddAssetNoteModalContainer";
import { AddAssetAttachmentModalContainer } from "../../container/AddAssetAttachmentModalContainer";

export const AssetNotesView: FC = () => (
  <Wrapper>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box position="relative">
          <AssetAttachmentsContainer />
          <AddAssetAttachmentModalContainer />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box position="relative">
          <AssetNotesContainer />
          <AddAssetNoteModalContainer />
        </Box>
      </Grid>
    </Grid>
  </Wrapper>
);
