import React, { FC, useState } from "react";
import { Column } from "react-table";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  StyledCard,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import { TeamDto, CreateEditTeamDto } from "../../api/teams.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { TeamModal } from "../TeamModal/TeamModal";
import { EDIT_TEAM_MODAL } from "../../../app/const/modals";

interface ITeamsTable {
  onEditTeam: (editTeamDto: CreateEditTeamDto, id?: number) => void;
  onDeleteTeam: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  teams: TeamDto[];
  clients: ClientDto[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const TeamsTable: FC<ITeamsTable> = ({
  teams,
  clients,
  isTablePending,
  isModalPending,
  onEditTeam,
  onDeleteTeam,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedTeam, setSelectedTeam] = useState<TeamDto | undefined>(
    undefined
  );

  const openModal = (team: TeamDto) => {
    setSelectedTeam(team);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedTeam(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    {
      Header: "Team Name",
      accessor: "name",
      Cell: ({ row: { original } }: { row: { original: TeamDto } }) =>
        original.name,
    },
    ...(useShowForRole([UserRole.SuperUser, UserRole.SuperAdmin])
      ? [
          {
            Header: "Client Name",
            accessor: "clientName",
          },
        ]
      : []),
    { Header: "Description", accessor: "description" },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: TeamDto } }) => (
        <ActionCellWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            View
          </Button>
        </ActionCellWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={teams}
          hover
          sorting={{ id: "name", desc: false }}
        />
      </StyledCard>
      <TeamModal
        name={EDIT_TEAM_MODAL}
        team={selectedTeam}
        onClose={closeModal}
        onSubmit={onEditTeam}
        onDelete={onDeleteTeam}
        clients={clients}
        isPending={isModalPending}
      />
    </>
  );
};
