import React, { FC } from "react";
import {
  Box,
  createStyles,
  Divider,
  Drawer,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import {
  Bookmark as BookmarkIcon,
  Home as DashboardIcon,
  Category as AssetsIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { NavItem } from "../NavItem/NavItem";
import {
  DASHBOARD,
  ASSETS,
  PROFILE,
  ANALYTICS,
  PASSWORD,
  USERS,
  TEAMS,
  LOCATIONS,
  PERMISSIONS,
  CLIENTS,
  PARTNERS,
  SENSOR_TYPES,
  DEVICES,
  NOTIFICATIONS,
  WORKORDERS,
} from "../../../const/routes";
import { DRAWER_WIDTH, DRAWER_FULL_WIDTH } from "../../../const/drawer";
import {
  StyledBarSectionHeader,
  StyledBarTitle,
  StyledList,
} from "./NavBar.style";
import { Button } from "../../Button/Button";
import { useShowForRole } from "../../../hooks/useShowForRole";
import { UserRole } from "../../../enum/UserRole";
import { useShowForPermissions } from "../../../hooks/useShowForPermissions";
import { ReactComponent as AnalyticsIcon } from "../../../../../assets/analytics.svg";
import { ReactComponent as WorkOrdersIcon } from "../../../../../assets/work-orders.svg";

interface INavBar {
  isNavOpen: boolean;
  onNavClose: () => void;
  isSettingsNav: boolean;
}

export const NavBar: FC<INavBar> = ({
  isNavOpen,
  isSettingsNav,
  onNavClose,
}) => {
  const items = [
    {
      href: DASHBOARD,
      icon: DashboardIcon,
      title: "Dashboard",
    },
    {
      href: ASSETS,
      icon: AssetsIcon,
      title: "Assets",
    },
    {
      href: ANALYTICS,
      icon: AnalyticsIcon,
      title: "Analytics",
    },
    {
      href: WORKORDERS,
      icon: WorkOrdersIcon,
      title: "Work Orders",
    },
  ];

  const preferencesItems = [
    {
      href: PROFILE,
      icon: BookmarkIcon,
      title: "Profile",
    },
    {
      href: NOTIFICATIONS,
      icon: BookmarkIcon,
      title: "Notifications",
    },
    {
      href: PASSWORD,
      icon: BookmarkIcon,
      title: "Security",
    },
  ];

  const settingsItems = [
    {
      href: USERS,
      icon: BookmarkIcon,
      title: "Users",
    },
    {
      href: TEAMS,
      icon: BookmarkIcon,
      title: "Teams",
    },
    {
      href: LOCATIONS,
      icon: BookmarkIcon,
      title: "Locations",
    },
    ...(useShowForPermissions("roles", { allOf: ["read"] })
      ? [
          {
            href: PERMISSIONS,
            icon: BookmarkIcon,
            title: "Permissions",
          },
        ]
      : []),
    ...(useShowForPermissions("clients", {
      allOf: ["read", "create", "update", "delete"],
    })
      ? [
          {
            href: CLIENTS,
            icon: BookmarkIcon,
            title: "Clients",
          },
        ]
      : []),
    ...(useShowForRole([UserRole.SuperUser])
      ? [
          {
            href: PARTNERS,
            icon: BookmarkIcon,
            title: "Partners",
          },
        ]
      : []),
    {
      href: DEVICES,
      icon: BookmarkIcon,
      title: "Devices",
    },
    {
      href: SENSOR_TYPES,
      icon: BookmarkIcon,
      title: "Sensors",
    },
  ];

  const isSmallDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: "nowrap",
      },
      drawerOpen: {
        width: DRAWER_FULL_WIDTH,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        background: isSettingsNav ? "lightgrey" : "",
        [theme.breakpoints.up("sm")]: {
          width: DRAWER_WIDTH,
        },
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9) + 1,
        },
        background: isSettingsNav ? "lightgrey" : "",
      },
      list: {
        padding: theme.spacing(1),
      },
      toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      drawerTop: {
        marginTop: "80px",
      },
    })
  );
  const classes = useStyles();

  const content = () => {
    return (
      <StyledList className={classes.drawerTop}>
        {items.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}
      </StyledList>
    );
  };

  const settingsContent = () => {
    return (
      <Box className={classes.drawerTop}>
        {isNavOpen ? (
          <>
            <Box ml={1} mr={1} mb={1}>
              <Button onClick={() => history.push(DASHBOARD)}>
                Back to Dashboard
              </Button>
              <StyledBarTitle>Settings</StyledBarTitle>
            </Box>
            <StyledBarSectionHeader>Your Preferences</StyledBarSectionHeader>
          </>
        ) : null}
        <StyledList>
          {preferencesItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              settingsItem
            />
          ))}
        </StyledList>
        {isNavOpen ? (
          <StyledBarSectionHeader>Account Settings</StyledBarSectionHeader>
        ) : null}
        <StyledList>
          {settingsItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              settingsItem
            />
          ))}
        </StyledList>
      </Box>
    );
  };

  return (
    <Drawer
      variant="permanent"
      elevation={2}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isNavOpen,
        [classes.drawerClose]: !isNavOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isNavOpen,
          [classes.drawerClose]: !isNavOpen,
        }),
      }}
      onClick={() => {
        if (isSmallDevice) {
          onNavClose();
        }
      }}
    >
      <Divider />
      {isSettingsNav ? settingsContent() : content()}
    </Drawer>
  );
};
