import React, { FC, useState } from "react";
import { Column } from "react-table";
import { Typography } from "@material-ui/core";
import { UserRole } from "../../../app/enum/UserRole";
import { Table } from "../../../app/component/Table/Table";
import {
  TrimActionCellWrapper,
  StyledCard,
  StyledUserIcon,
  StyledUserNameWrapper,
  StyledUserNameColumn,
  StyledUserTypography,
  StyledUserEmailTypography,
  StyledUserIconTypography,
  StyledInviteDot,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import { UserModel, CreateEditUserModel } from "../../model/users.model";
import { UserModal } from "../UserModal/UserModal";
import { PartnerDto } from "../../../partners/api/partners.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { RoleDto } from "../../../roles/api/roles.api.dto";
import { EDIT_USER_MODAL } from "../../../app/const/modals";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserDto } from "../../api/users.api.dto";

interface IUsersTable {
  onEditUser: (editUserModel: CreateEditUserModel, id?: number) => void;
  onDeleteUser: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  users: UserModel[];
  partners: PartnerDto[];
  clients: ClientDto[];
  teams: TeamDto[];
  roles: RoleDto[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const UsersTable: FC<IUsersTable> = ({
  users,
  partners,
  clients,
  teams,
  roles,
  isTablePending,
  isModalPending,
  onEditUser,
  onDeleteUser,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedUser, setSelectedUser] = useState<UserModel | undefined>(
    undefined
  );

  const openModal = (user: UserModel) => {
    setSelectedUser(user);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedUser(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    {
      Header: "Name",
      id: "name",
      accessor: (original: any) =>
        original.registration_completed_at
          ? original.full_name
          : original.email,
      Cell: ({ row: { original } }: { row: { original: UserDto } }) => (
        <StyledUserNameWrapper>
          <StyledUserIcon>
            {original.registration_completed_at ? (
              <StyledUserIconTypography>
                {original.first_name?.charAt(0)}
                {original.last_name?.charAt(0)}
              </StyledUserIconTypography>
            ) : (
              <StyledUserIconTypography>?</StyledUserIconTypography>
            )}
          </StyledUserIcon>
          <StyledUserNameColumn>
            <StyledUserTypography>
              {original.registration_completed_at
                ? original.full_name
                : original.email}
            </StyledUserTypography>
            {original.registration_completed_at ? (
              <StyledUserEmailTypography>
                {original.email}
              </StyledUserEmailTypography>
            ) : (
              <StyledUserNameWrapper>
                <StyledInviteDot />
                <StyledUserEmailTypography>
                  Invite Pending | {original.email}
                </StyledUserEmailTypography>
              </StyledUserNameWrapper>
            )}
          </StyledUserNameColumn>
        </StyledUserNameWrapper>
      ),
      width: 300,
    },
    ...(useShowForRole([UserRole.SuperUser])
      ? [
          {
            Header: "Partner",
            accessor: "partner_name",
          },
        ]
      : []),
    ...(useShowForRole([UserRole.SuperUser, UserRole.SuperAdmin])
      ? [
          {
            id: "client_name",
            Header: "Client",
            accessor: "client_name",
          },
        ]
      : []),
    {
      Header: "Team(s)",
      accessor: "team_names",
    },
    {
      id: "role_name",
      Header: "Role",
      accessor: "role_name",
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      width: 100,
    },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: UserModel } }) => (
        <TrimActionCellWrapper>
          <Button
            variant="table-action"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            View
          </Button>
        </TrimActionCellWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={users}
          hover
          sorting={{ id: "name", desc: false }}
        />
      </StyledCard>
      <UserModal
        name={EDIT_USER_MODAL}
        user={selectedUser}
        onClose={closeModal}
        onSubmit={onEditUser}
        onDelete={onDeleteUser}
        partners={partners}
        clients={clients}
        teams={teams}
        roles={roles}
        isPending={isModalPending}
      />
    </>
  );
};
