import styled, { css } from "styled-components";

import { Button } from "@material-ui/core";

export const StyledDeleteButton = styled(Button)`
  ${({ disabled }) =>
    !disabled &&
    css`
      background-color: ${({ theme }) =>
        `${theme.palette.button.error.backgroundColor}`};
      color: ${({ theme }) => `${theme.palette.button.error.color}`};
    `}

  :hover {
    background-color: ${({ theme }) =>
      `${theme.palette.button.error.hover.backgroundColor}`};
    color: ${({ theme }) => `${theme.palette.button.error.hover.color}`};
  }
`;

export const StyledTableButton = styled(Button)`
  max-height: 40px;
  align-self: center;
`;
