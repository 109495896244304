import React, { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";
import { AssetAlertsDto } from "../../api/asset.api.dto";
import { AlarmTriggeredStatus } from "../../enum/alert_types.enum";
import { StyledCard } from "../../../app/component/Table/Table.style";

interface IAssetAlertHistoryTable {
  data: AssetAlertsDto[];
  isPending: boolean;
}

export const AssetAlertHistoryTable: FC<IAssetAlertHistoryTable> = ({
  data,
  isPending,
}) => {
  const columns: Column[] = [
    {
      id: "sensor_name",
      Header: "Sensor Name",
      accessor: "sensor_name",
    },
    { Header: "Sensor Type", accessor: "sensor_type" },
    {
      Header: "Sensor Reading",
      accessor: "reading",
      Cell: ({ row: { original } }: { row: { original: AssetAlertsDto } }) => {
        return `${original.reading} ${original.sensor.sensor_type.unit_name}`;
      },
    },
    {
      Header: "Alarm Set-Point",
      accessor: "alarm_point",
      Cell: ({ row: { original } }: { row: { original: AssetAlertsDto } }) => {
        return `${original.alarm_point} ${original.sensor.sensor_type.unit_name}`;
      },
    },
    {
      Header: "Alarm Type",
      accessor: "alert_type",
    },
    {
      Header: "Triggered",
      accessor: "triggered",
      Cell: ({ row: { original } }: { row: { original: AssetAlertsDto } }) => {
        return original.triggered ? "Yes" : "No";
      },
    },
  ];

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={columns}
        data={data}
        formView
        hover
        sorting={{ id: "sensor_name", desc: false }}
      />
    </StyledCard>
  );
};
