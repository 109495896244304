import React, { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import { ASSETS } from "../../../app/const/routes";

interface IAssetRedirectRoute {
  assetId: string;
}

export const AssetRedirectRoute: FC<IAssetRedirectRoute> = ({ assetId }) => (
  <Route path="*">
    <Redirect to={`${ASSETS}/${assetId}/realtime`} />
  </Route>
);
