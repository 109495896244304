import styled from "styled-components";
import { IconButton, Typography } from "@material-ui/core";

export const Wrapper = styled.div`
  height: 250px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #e3e3e9;
  display: flex;
  flex-direction: column;
`;

export const Preview = styled.div`
  margin: ${({ theme }) => `${theme.spacing(3)}px`};
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const Title = styled.div`
  border-radius: 0 0 4px 4px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const StyledIFrame = styled.iframe`
  max-width: 100%;
  max-height: 100%;
`;

export const StyledIconButton = styled(IconButton)<any>`
  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
`;
