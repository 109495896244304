import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { LocationDto } from "../api/locations.api.dto";

export interface LocationState {
  locations: AsyncStateModel<LocationDto[]>;
  deleteLocation: AsyncStateModel<null>;
  editLocation: AsyncStateModel<null>;
  createLocation: AsyncStateModel<null>;
  searchLocations: string;
}

export const locationState: LocationState = {
  locations: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteLocation: {
    status: AsyncStatus.Void,
    data: null,
  },
  editLocation: {
    status: AsyncStatus.Void,
    data: null,
  },
  createLocation: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchLocations: "",
};
