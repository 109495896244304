import React, { FC, useState } from "react";
import { Box } from "@material-ui/core";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import { AssetsTableContainer } from "../../container/AssetsTableContainer";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { AssetsSearchContainer } from "../../container/AssetsSearchContainer";
import { AssetsFilterContainer } from "../../container/AssetsFilterContainer";
import { AssetTagsFilterContainer } from "../../container/AssetTagsFilterContainer";
import { AddAssetModalContainer } from "../../container/AddAssetModalContainer";

export const openModal = () => {};

export const AssetsView: FC = () => {
  const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);
  return (
    <StyledPage title="Assets">
      <FullHeightContainer maxWidth={false}>
        <PageHeaderContainer>
          <PageHeader title="Assets" />
          <ConnectedShowForPermissions
            feature="assets"
            permissions={{ allOf: ["read"] }}
          >
            <PageActionsWrapper>
              <AssetsSearchContainer />
              <AssetTagsFilterContainer />
              <AddAssetModalContainer />
            </PageActionsWrapper>
          </ConnectedShowForPermissions>
        </PageHeaderContainer>
        <ConnectedShowForPermissions
          feature="assets"
          permissions={{ allOf: ["read"] }}
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            flex={1}
          >
            <AssetsFilterContainer
              isInitialDataFetched={isInitialDataFetched}
              setIsInitialDataFetched={setIsInitialDataFetched}
            />
            <AssetsTableContainer
              isInitialDataFetched={isInitialDataFetched}
              setIsInitialDataFetched={setIsInitialDataFetched}
            />
          </Box>
        </ConnectedShowForPermissions>
      </FullHeightContainer>
    </StyledPage>
  );
};
