import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { RuntimeGraph } from "../component/Analytics/RuntimeGraph";
import {
  selectRuntimeData,
  selectRuntimeDataStatusPending,
} from "../store/asset.selector";
import { AssetDto, AssetRuntimeDto } from "../api/asset.api.dto";
import { fetchRuntimeData as fetchRuntimeDataAction } from "../store/asset.action";

interface IRuntimeGraphContainer {
  selectedAssets: AssetDto[];
  startEpoch: number;
  endEpoch: number;
  runtimeData: AssetRuntimeDto[];
  setRuntimeData: (runtime: AssetRuntimeDto[]) => void;
}

export const AssetAnalyticsRuntimeContainer: FC<IRuntimeGraphContainer> = ({
  selectedAssets,
  startEpoch,
  endEpoch,
  runtimeData,
  setRuntimeData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const assetRuntimeData = useSelector(selectRuntimeData);
  const assetRuntimeDataIsPending = useSelector(selectRuntimeDataStatusPending);

  useEffect(() => {
    dispatch(
      fetchRuntimeDataAction({ assetIds: [], startEpoch: 0, endEpoch: 0 })
    );
  }, [dispatch]);

  useEffect(() => {
    setRuntimeData(assetRuntimeData);
  }, [setRuntimeData, assetRuntimeData]);

  useEffect(() => {
    const assetIds = selectedAssets.map((asset: AssetDto) => {
      return asset.id;
    });
    if (assetIds.length > 0) {
      dispatch(fetchRuntimeDataAction({ assetIds, startEpoch, endEpoch }));
    }
    setRuntimeData([]);
  }, [dispatch, setRuntimeData, selectedAssets, startEpoch, endEpoch]);

  return (
    <RuntimeGraph
      isGraphsPending={assetRuntimeDataIsPending}
      runtimeData={runtimeData}
    />
  );
};
