import { createSlice } from "@reduxjs/toolkit";
import { ClientState, clientState } from "./clients.store";
import {
  fetchClients,
  deleteClient,
  editClient,
  createClient,
  fetchAverageHealth,
  fetchRecentActivity,
  fetchClientTodos,
} from "./clients.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "clients",
  initialState: clientState,
  reducers: {
    searchClients: (state: ClientState, { payload }: { payload: string }) => ({
      ...state,
      searchClients: payload,
    }),
    clearClientsState: (state: ClientState) => ({
      ...state,
      clients: clientState.clients,
    }),
    clearSearchClientsState: (state: ClientState) => ({
      ...state,
      searchClients: clientState.searchClients,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => ({
      ...state,
      clients: {
        ...state.clients,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchClients.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          clients: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchClients.rejected, (state) => ({
      ...state,
      clients: {
        ...state.clients,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteClient.pending, (state) => ({
      ...state,
      deleteClient: {
        ...state.deleteClient,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteClient.fulfilled, (state) => ({
      ...state,
      deleteClient: {
        ...state.deleteClient,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteClient.rejected, (state) => ({
      ...state,
      deleteClient: {
        ...state.deleteClient,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editClient.pending, (state) => ({
      ...state,
      editClient: {
        ...state.editClient,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editClient.fulfilled, (state) => ({
      ...state,
      editClient: {
        ...state.editClient,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editClient.rejected, (state) => ({
      ...state,
      editClient: {
        ...state.editClient,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createClient.pending, (state) => ({
      ...state,
      createClient: {
        ...state.createClient,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createClient.fulfilled, (state) => ({
      ...state,
      createClient: {
        ...state.createClient,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createClient.rejected, (state) => ({
      ...state,
      createClient: {
        ...state.createClient,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAverageHealth.pending, (state) => ({
      ...state,
      averageHealth: {
        ...state.averageHealth,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAverageHealth.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          averageHealth: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }
      return state;
    });
    builder.addCase(fetchAverageHealth.rejected, (state) => ({
      ...state,
      averageHealth: {
        ...state.averageHealth,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchRecentActivity.pending, (state) => ({
      ...state,
      recentActivities: {
        ...state.recentActivities,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchRecentActivity.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          recentActivities: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }
      return state;
    });
    builder.addCase(fetchRecentActivity.rejected, (state) => ({
      ...state,
      recentActivities: {
        ...state.recentActivities,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchClientTodos.pending, (state) => ({
      ...state,
      todos: {
        ...state.todos,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchClientTodos.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          todos: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }
      return state;
    });
    builder.addCase(fetchClientTodos.rejected, (state) => ({
      ...state,
      todos: {
        ...state.todos,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchClients,
  clearClientsState,
  clearSearchClientsState,
} = slice.actions;
export default slice.reducer;
