import React, { FC, useState } from "react";
import {
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  CardContent,
  Typography,
  CircularProgress,
  Divider,
  Switch,
} from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ChannelGraphData } from "../../api/asset.api.dto";
import { ASSET_TRENDS_COLORS } from "../../../app/theme/colors";
import {
  ExportIconButton,
  LoaderOverlay,
  StyledTooltipDiv,
  StyledCard,
  StyledFormControlLabel,
} from "./AssetTrends.style";
import { Export } from "../../../app/component/Icons/ExportIcon";

interface IAssetHealthGraph {
  isGraphsPending: boolean;
  graphData: ChannelGraphData[];
  formatDate: (tickItem: number | string) => string;
  assetName: string;
  assetHealthEnabled: boolean;
  dateTimeRangePicker: React.ReactNode;
  selectedSensors: number[] | undefined;
}

export const AssetHealthGraph: FC<IAssetHealthGraph> = ({
  isGraphsPending,
  graphData,
  formatDate,
  assetName,
  assetHealthEnabled,
  dateTimeRangePicker,
  selectedSensors,
}) => {
  const [showAssetHealth, setShowAssetHealth] = useState(true);

  const graphDataCopy = [...graphData];
  graphDataCopy.sort(
    (a, b) =>
      a?.chartData[a?.chartData.length - 1]?.xdata -
      b?.chartData[b?.chartData.length - 1]?.xdata
  );

  const getNumChannels = () => {
    let channels = 0;
    graphDataCopy.forEach((channelData: ChannelGraphData) => {
      if (channelData?.data.length > 0) {
        channels += 1;
      }
    });
    return channels;
  };

  const assetHealthData = (): { xdata: number; data: number }[] => {
    const numChannels = getNumChannels();
    const assetHealth: { xdata: number; data: number }[] = [];
    let assetHealthSingle = 0;
    let xDataArrays: number[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in graphDataCopy) {
      if (graphDataCopy[key]?.xdata.length > 0) {
        xDataArrays = xDataArrays.concat(graphDataCopy[key].xdata);
      }
    }
    const uniqueSet = [...new Set(xDataArrays)];
    const uniqueArray = Array.from(uniqueSet).sort();
    let healthIndex;
    uniqueArray.forEach((xdata: number) => {
      assetHealthSingle = 0;
      healthIndex = 0;
      Object.keys(graphDataCopy).forEach((key: string, index: number) => {
        if (graphDataCopy[index].xdata) {
          healthIndex = graphDataCopy[index].xdata.indexOf(xdata);
          if (healthIndex >= 0) {
            assetHealthSingle += graphDataCopy[index].data[healthIndex];
          }
        }
      });
      assetHealth.push({ xdata, data: assetHealthSingle / numChannels });
    });

    return assetHealth;
  };

  const generateGraphExportPdf = () => {
    const element = document.getElementById("asset-health-graph");

    if (element) {
      html2canvas(element, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "px");

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${assetName}HealthScores.pdf`);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltipDiv>
          {payload.map(
            (
              series: { stroke: string; name: string; value: number },
              index: number
            ) => (
              <p key={index}>
                <span
                  style={{ color: `${series.stroke}` }}
                >{`• ${series.name}: `}</span>
                {`${series.value.toFixed(2)}`}
              </p>
            )
          )}
          <p key="timestamp">{formatDate(payload[0].payload.xdata)}</p>
        </StyledTooltipDiv>
      );
    }

    return null;
  };

  return (
    <StyledCard>
      {isGraphsPending && (
        <LoaderOverlay>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      <CardContent id="asset-health-graph">
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <Box alignSelf="center" flexGrow={1}>
            <Typography variant="h3">Sensor Health Scores</Typography>
          </Box>
          <Box alignSelf="center" mr={1} data-html2canvas-ignore="true">
            <ExportIconButton
              startIcon={<Export />}
              pending={isGraphsPending}
              onClick={generateGraphExportPdf}
            />
          </Box>
          <Box alignSelf="center" width={220} data-html2canvas-ignore="true">
            {dateTimeRangePicker}
          </Box>
        </Box>
        {assetHealthEnabled &&
        !isGraphsPending &&
        assetHealthData().length > 0 ? (
          <Box mt={2}>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart>
                <XAxis
                  dataKey="xdata"
                  allowDuplicatedCategory={false}
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={formatDate}
                />
                <YAxis
                  domain={[0, 10]}
                  ticks={[0, 2.5, 5, 7.5, 10]}
                  tickMargin={10}
                  tickLine={false}
                  axisLine={false}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <CartesianGrid strokeWidth={1} />
                {graphDataCopy.map((series: ChannelGraphData) => {
                  if (
                    series.data.length > 0 &&
                    selectedSensors?.includes(series.index)
                  ) {
                    return (
                      <Line
                        dataKey="data"
                        data={series.chartData}
                        name={series.title}
                        key={series.seriesId}
                        stroke={ASSET_TRENDS_COLORS[series.index]}
                        strokeWidth={4}
                        type="monotone"
                        dot={false}
                      />
                    );
                  }
                  return null;
                })}
                {showAssetHealth ? (
                  <Line
                    key="asset-health"
                    dataKey="data"
                    data={assetHealthData()}
                    name="Asset Health"
                    stroke="#832780"
                    strokeWidth={4}
                    type="monotone"
                    dot={false}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
            <Box data-html2canvas-ignore="true">
              <Divider />
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={showAssetHealth}
                    onChange={() => setShowAssetHealth(!showAssetHealth)}
                    name="checkedA"
                  />
                }
                label="Show Asset Health"
                labelPlacement="start"
              />
            </Box>
          </Box>
        ) : null}
      </CardContent>
    </StyledCard>
  );
};
