import React, { FC, Fragment, useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import {
  Box,
  Divider,
  Grid,
  CardMedia,
  TextField,
  Typography,
  FormControl,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { CategoryDto } from "../../../category/api/category.api.dto";
import { LocationDto } from "../../../location/api/locations.api.dto";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { LiveGraphSvg } from "../../enum/LiveGraphSvg/LiveGraphSvg";
import { AssetDto, AssetTagDto } from "../../api/asset.api.dto";
import {
  DESCRIPTION_CHAR_LIMIT,
  NAME_CHAR_LIMIT,
} from "./AssetModalForm.validation";
import {
  StyledModalContent,
  StyledSwitch,
} from "../../../app/component/Modal/Modal.style";
import { CreateEditAssetModel } from "../../model/asset.model";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { ConnectedShowForRole } from "../../../app/component/UserManagement/ConnectedShowForRole";
import { UserRole } from "../../../app/enum/UserRole";
import { ChannelsSelector } from "../../../app/component/ChannelsSelector/ChannelsSelector";
import { DeviceListDto } from "../../../devices/api/devices.api.dto";
import { TooltipIcon } from "../../../app/component/TooltipIcon/TooltipIcon";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { fetchCategories } from "../../store/asset.action";
import {
  selectCategories,
  selectIsCategoriesStatusPending,
} from "../../store/asset.selector";
import { AppDispatch } from "../../../app/store";

export interface IAssetModalFormProps {
  locations: LocationDto[];
  teams: TeamDto[];
  tags: AssetTagDto[];
  clients: ClientDto[];
  devices: DeviceListDto[];
  categories: CategoryDto[];
  asset?: AssetDto;
  assets?: AssetDto[];
  onSubmit: (values: CreateEditAssetModel, id?: number) => void;
  isPending: boolean;
  displayActionButtons: () => void;
  editView: boolean;
}

export const AssetModalForm: FC<IAssetModalFormProps> = ({
  onSubmit,
  displayActionButtons,
  locations,
  teams,
  clients,
  devices,
  categories,
  asset,
  assets,
  editView,
  isPending,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const useStyles = makeStyles(() =>
    createStyles({
      endAdornment: {
        "& .MuiAutocomplete-endAdornment": {
          top: "30px",
        },
      },
    })
  );
  const classes = useStyles();

  const [tagsArr, setTagsArr] = useState<AssetTagDto[]>([]);

  const isSuperAdminUser = useShowForRole([
    UserRole.SuperAdmin,
    UserRole.SuperUser,
  ]);

  const getTagsForClientId = useCallback(
    (clientId: number | null) => {
      const filteredArray = clientId
        ? assets?.filter(
            (currAsset) =>
              currAsset.client_id === clientId && currAsset.tags.length > 0
          )
        : assets;

      const tagsArray = filteredArray?.flatMap((filteredAsset) =>
        filteredAsset.tags.map((tag) => tag)
      );

      return [...new Map(tagsArray?.map((item) => [item.id, item])).values()];
    },
    [assets]
  );

  useEffect(() => {
    if (editView || !isSuperAdminUser) {
      const tagsArray = getTagsForClientId(
        asset?.client_id ? asset?.client_id : null
      );

      setTagsArr(tagsArray || []);
    }
  }, [editView, asset, getTagsForClientId, isSuperAdminUser]);

  const getLocationIdOptionLabel = (option: number) => {
    const match = locations.find((location) => {
      return location.id === option;
    });
    return match ? match.title : "";
  };

  const getClientIdOptions = () => {
    return [...clients]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client) => client.id) as number[];
  };

  const getClientIdOptionLabel = (option: number) => {
    const match = clients.find((client) => {
      return client.id === option;
    });
    return match ? match.name : "";
  };

  const getTeamIdsOptionLabel = (option: number) => {
    const match = teams?.find((team) => {
      return team.id === option;
    });
    return match ? match.name : "";
  };

  const getLiveGraphSvgOptions = () => {
    return Object.values(LiveGraphSvg).filter(
      (x) => typeof x === "number"
    ) as number[];
  };
  const getLiveGraphSvgPreviewPath = (liveGraphSvg: LiveGraphSvg) => {
    const paths = new Map<number, string>([
      [LiveGraphSvg.Chiller, "/img/Chiller.svg"],
      [LiveGraphSvg.DryPump1800, "/img/DryPump1800.svg"],
      [LiveGraphSvg.DryPump1800Box, "/img/DryPump1800Box.svg"],
      [LiveGraphSvg.DryVacuumPump, "/img/DryVacuumPump.svg"],
      [LiveGraphSvg.DryVacuumPumpBlower, "/img/DryVacuumPumpBlower.svg"],
      [LiveGraphSvg.GasBottle, "/img/GasBottle.svg"],
      [LiveGraphSvg.OttoBox, "/img/OttoBox.svg"],
      [LiveGraphSvg.ScrollPump, "/img/ScrollPump.svg"],
      [LiveGraphSvg.WineCentrifuge, "/img/WineCentrifuge.svg"],
    ]);
    return paths.get(liveGraphSvg) ?? "";
  };

  const getLiveGraphSvgOptionLabel = (option: number) => {
    const images = new Map<number, string>([
      [LiveGraphSvg.OttoBox, "Otto Box"],
      [LiveGraphSvg.ScrollPump, "Scroll Pump"],
      [LiveGraphSvg.WineCentrifuge, "Wine Centrifuge"],
      [LiveGraphSvg.Chiller, "Chiller"],
      [LiveGraphSvg.GasBottle, "Gas Bottle"],
      [LiveGraphSvg.DryVacuumPump, "Dry Vacuum Pump"],
      [LiveGraphSvg.DryVacuumPumpBlower, "Dry Vacuum Pump Blower"],
      [LiveGraphSvg.DryPump1800, "Dry Pump 1800"],
      [LiveGraphSvg.DryPump1800Box, "Dry Pump 1800 Box"],
    ]);
    return images.get(option) ?? "";
  };

  const getDeviceChannels = () => {
    const deviceChannels: {
      id: number;
      name: string;
      channel_count: number;
      channels: number[];
    }[] = [];
    const deviceIds: number[] = [];
    asset?.channels.forEach((channel) => {
      if (deviceIds.find((ch) => ch === channel.device_id) === undefined) {
        deviceIds.push(channel.device_id);
      }
    });
    if (asset) {
      const assetDevices = asset.devices;
      const mappedDevices = assetDevices
        .map((device) => {
          return {
            id: device.id,
            name: device.name,
            channel_count: device.channel_count,
          };
        })
        .filter((device) => deviceIds.includes(device.id));

      if (mappedDevices.length > 0) {
        mappedDevices.forEach((device) => {
          const channels = asset?.channels.filter((channel) => {
            return channel.device_id === device.id;
          });
          if (channels && channels.length > 0) {
            deviceChannels.push({
              id: device.id,
              name: device.name,
              channel_count: device.channel_count,
              channels: channels.map((ch) => {
                return ch.channel_index;
              }),
            });
          }
        });
      }
    }
    return deviceChannels;
  };

  const getDeviceChannelsArray = (channels: number) => {
    const response = [];
    for (let i = 0; i < channels; i += 1) {
      response.push(i);
    }
    return response;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: asset?.categories[0]?.name || null,
      categories: asset?.categories || [],
      health_is_enabled: Boolean(asset?.health_is_enabled) || false,
      id: asset?.id || 0,
      is_enabled: Boolean(asset?.is_enabled) || false,
      snoozed: Boolean(asset?.snoozed) || false,
      location: asset?.location?.id || null,
      team_ids: asset?.teams?.map((team) => team.id) || [],
      tags: asset?.tags.map((tag) => tag.name) || [],
      manufacturer: asset?.manufacturer || "",
      model: asset?.model || "",
      name: asset?.name || "",
      client_id: asset?.client_id || undefined,
      serial_number: asset?.serial_number || "",
      live_graph_svg: asset?.live_graph_svg || 0,
      process: asset?.process || "",
      description: asset?.description || "",
    },
    onSubmit: (values: CreateEditAssetModel) => onSubmit(values, asset?.id),
  });

  const getLocationIdOptions = () => {
    if (formik.values.client_id) {
      return [...locations]
        .filter((location) => location.client_id === formik.values.client_id)
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((filteredLocation) => filteredLocation.id) as number[];
    }
    return locations.map((location) => location.id) as number[];
  };

  const getTeamIdsOptions = () => {
    if (formik.values.client_id) {
      return teams
        .filter((team) => team.client_id === formik.values.client_id)
        .map((filteredTeam) => filteredTeam.id) as number[];
    }
    return teams.map((team) => team.id) as number[];
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Asset Info</Typography>
          </Grid>
          {!editView ? (
            <ConnectedShowForRole
              roles={[UserRole.SuperUser, UserRole.SuperAdmin]}
            >
              <Grid item xs={12}>
                <Autocomplete
                  options={getClientIdOptions()}
                  id="client_id"
                  getOptionLabel={getClientIdOptionLabel}
                  disabled={isPending || (!editView && asset !== undefined)}
                  value={formik.values.client_id || null}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: number | null
                  ) => {
                    formik.setFieldValue("client_id", newValue);
                    formik.setFieldValue("location", null);
                    formik.setFieldValue("team_ids", []);
                    if (assets) {
                      setTagsArr(getTagsForClientId(newValue));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="client_id"
                      label="Client"
                      error={
                        formik.touched.client_id &&
                        Boolean(formik.errors.client_id)
                      }
                      helperText={
                        formik.touched.client_id && formik.errors.client_id
                      }
                      variant="filled"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </ConnectedShowForRole>
          ) : null}
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Asset Name"
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && asset !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={getLocationIdOptions()}
              id="location"
              getOptionLabel={getLocationIdOptionLabel}
              disabled={isPending || (!editView && asset !== undefined)}
              value={formik.values.location}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValue: number | null
              ) => {
                formik.setFieldValue("location", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="location"
                  label="Location"
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={
                formik.values.team_ids.length > 0 ? classes.endAdornment : ""
              }
              multiple
              options={getTeamIdsOptions()}
              id="teams"
              getOptionLabel={getTeamIdsOptionLabel}
              disabled={isPending || (!editView && asset !== undefined)}
              value={formik.values.team_ids}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValues: number[] | null
              ) => {
                formik.setFieldValue("team_ids", newValues);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="team_ids"
                  label="Teams"
                  error={
                    formik.touched.team_ids && Boolean(formik.errors.team_ids)
                  }
                  helperText={formik.touched.team_ids && formik.errors.team_ids}
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={
                formik.values.tags.length > 0 ? classes.endAdornment : ""
              }
              multiple
              freeSolo
              options={tagsArr
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tag) => tag?.name)}
              id="tags"
              disabled={isPending || (!editView && asset !== undefined)}
              value={formik.values.tags || null}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValues: string[] | null
              ) => {
                formik.setFieldValue("tags", newValues);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tags"
                  label="Tags"
                  error={formik.touched.tags && Boolean(formik.errors.tags)}
                  helperText={formik.touched.tags && formik.errors.tags}
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Manufacturer Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              autoSelect
              options={categories
                .slice()
                .sort((a, b) => a?.name.localeCompare(b?.name))
                .map((category) => category?.name)}
              id="category"
              disabled={isPending || (!editView && asset !== undefined)}
              value={formik.values.category || null}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValues: string | null
              ) => {
                formik.setFieldValue("category", newValues);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="category"
                  label="Category"
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  helperText={formik.touched.category && formik.errors.category}
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="manufacturer"
              name="manufacturer"
              label="Manufacturer"
              variant="filled"
              fullWidth
              value={formik.values.manufacturer}
              onChange={formik.handleChange}
              error={
                formik.touched.manufacturer &&
                Boolean(formik.errors.manufacturer)
              }
              helperText={
                formik.touched.manufacturer && formik.errors.manufacturer
              }
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && asset !== undefined)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="model"
              name="model"
              label="Model"
              variant="filled"
              fullWidth
              value={formik.values.model}
              onChange={formik.handleChange}
              error={formik.touched.model && Boolean(formik.errors.model)}
              helperText={formik.touched.model && formik.errors.model}
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && asset !== undefined)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="serial_number"
              name="serial_number"
              label="S/N"
              variant="filled"
              fullWidth
              value={formik.values.serial_number}
              onChange={formik.handleChange}
              error={
                formik.touched.serial_number &&
                Boolean(formik.errors.serial_number)
              }
              helperText={
                formik.touched.serial_number && formik.errors.serial_number
              }
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && asset !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={getLiveGraphSvgOptions()}
              id="live_graph_svg"
              getOptionLabel={getLiveGraphSvgOptionLabel}
              disabled={isPending || (!editView && asset !== undefined)}
              value={formik.values.live_graph_svg}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValue: number | null
              ) => {
                formik.setFieldValue("live_graph_svg", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="live_graph_svg"
                  label="Asset Image"
                  error={
                    formik.touched.live_graph_svg &&
                    Boolean(formik.errors.live_graph_svg)
                  }
                  helperText={
                    formik.touched.live_graph_svg &&
                    formik.errors.live_graph_svg
                  }
                  variant="filled"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {typeof formik.values.live_graph_svg === "number" && (
              <>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <Typography variant="h4">Preview</Typography>
                  </Box>
                </Grid>
                <CardMedia
                  image={getLiveGraphSvgPreviewPath(
                    formik.values.live_graph_svg as LiveGraphSvg
                  )}
                  style={{
                    height: "100px",
                    width: "100px",
                    backgroundSize: "auto",
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Additional Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="process"
              name="process"
              label="Process"
              multiline
              rows={3}
              variant="filled"
              fullWidth
              value={formik.values.process}
              onChange={formik.handleChange}
              error={formik.touched.process && Boolean(formik.errors.process)}
              helperText={formik.touched.process && formik.errors.process}
              inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
              disabled={isPending || (!editView && asset !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Asset Description"
              multiline
              rows={3}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
              disabled={isPending || (!editView && asset !== undefined)}
            />
          </Grid>
          {asset ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h4">Condition Monitoring</Typography>
              </Grid>
              {getDeviceChannels().map((deviceChannels, index) => (
                <Fragment key={index}>
                  <Grid item xs={12}>
                    <FormControl variant="filled" fullWidth required disabled>
                      <TextField
                        label="Device"
                        variant="filled"
                        fullWidth
                        value={deviceChannels.name}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Assign Channel</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ChannelsSelector
                      onChange={() => {}}
                      value={deviceChannels.channels}
                      channels={getDeviceChannelsArray(
                        deviceChannels.channel_count
                      )}
                      disabled
                      disabledChannels={[]}
                    />
                  </Grid>
                </Fragment>
              ))}
            </>
          ) : null}
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={9} sm={4}>
              <Typography variant="h4">
                Asset Health Score
                <TooltipIcon text="Activate to use health score for the asset and have health score included in cumulative operation health. Deactivate when health score is not pertinent (Ex. When using sensors to record binary states, such as empty or full)." />
              </Typography>
            </Grid>
            <Grid item xs={3} sm={8}>
              <StyledSwitch
                id="health_is_enabled"
                name="health_is_enabled"
                size="small"
                value={formik.values.health_is_enabled}
                checked={formik.values.health_is_enabled}
                onChange={formik.handleChange}
                disabled={isPending || (!editView && asset !== undefined)}
              />
            </Grid>
            <Grid item xs={9} sm={4}>
              <Typography variant="h4">
                Notifications
                <TooltipIcon text="Activate to trigger notifications to users when conditions are met. Deactivate to pause this asset from triggering notifications." />
              </Typography>
            </Grid>
            <Grid item xs={3} sm={8}>
              <StyledSwitch
                id="snoozed"
                name="snoozed"
                size="small"
                value={formik.values.snoozed}
                checked={formik.values.snoozed}
                onChange={formik.handleChange}
                disabled={isPending || (!editView && asset !== undefined)}
              />
            </Grid>
            <Grid item xs={9} sm={4}>
              <Typography variant="h4">
                Sensor Data
                <TooltipIcon text="Activate this feature when you want to receive sensor data from this asset. Deactivate when you do not wish to receive data (Ex. Asset removed from operation)." />
              </Typography>
            </Grid>
            <Grid item xs={3} sm={8}>
              <StyledSwitch
                id="is_enabled"
                name="is_enabled"
                size="small"
                value={formik.values.is_enabled}
                checked={!!formik.values.is_enabled}
                onChange={formik.handleChange}
                disabled={isPending || (!editView && asset !== undefined)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
