import { createSlice } from "@reduxjs/toolkit";
import { industryState } from "./industry.store";
import { fetchIndustries } from "./industry.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "industry",
  initialState: industryState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndustries.pending, (state) => ({
      ...state,
      fetchIndustries: {
        ...state.fetchIndustries,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchIndustries.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchIndustries: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchIndustries.rejected, (state) => ({
      ...state,
      fetchIndustries: {
        ...state.fetchIndustries,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
