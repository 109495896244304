import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AssetHealthGauge } from "../../asset/component/AssetHealthGauge/AssetHealthGauge";
import { AppDispatch } from "../../app/store";

import { fetchAverageHealth } from "../../clients/store/clients.action";
import {
  selectAverageHealthStateData,
  selectIsAverageHealthStatusPending,
} from "../../clients/store/clients.selector";
import { useInterval } from "../../app/hooks/useInterval";

export const DashboardAssetHealthGaugeContainer: FC<{
  clientId: number;
}> = ({ clientId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const averageHealth = useSelector(selectAverageHealthStateData);
  const pending = useSelector(selectIsAverageHealthStatusPending);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchAverageHealth(clientId));
  }, [dispatch, clientId]);

  const assetHealth = averageHealth
    ? parseFloat(averageHealth.average_health)
    : 0;

  useInterval(() => {
    dispatch(fetchAverageHealth(clientId));
    if (showLoader) {
      setShowLoader(false);
    }
  });

  return (
    <AssetHealthGauge
      assetHealth={assetHealth}
      healthEnabled
      pending={pending && showLoader}
      totalAssets={averageHealth?.asset_count}
      isAverage
    />
  );
};
