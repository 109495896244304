import styled from "styled-components";
import { Card, Typography } from "@material-ui/core";

export const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.card.secondary};
  border-bottom: 1px solid #dddddd;
  border-radius: 0px;
  padding-left: 12px;
  cursor: pointer;
`;
export const StyledTopCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.card.secondary};
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #dddddd;
  padding-left: 12px;
  cursor: pointer;
`;
export const StyledBottomCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.card.secondary};
  border-radius: 0px 0px 4px 4px;
  padding-left: 12px;
  cursor: pointer;
`;

export const StyledSelectedCard = styled(Card)`
  background-color: white;
  border-radius: 0px;
  border-left: 5px solid ${({ theme }) => theme.palette.primary.main};
  padding-left: 6px;
`;

export const StyledCardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({ theme }) => theme.overrides.MuiAppBar.colorPrimary.color};
`;

export const StyledCardText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
`;

export const GreenBadge = styled.div`
  text-align: center;
  color: white;
  width: 28px;
  height: 24px;
  margin-left: 16px;
  border-radius: 2px;
  background-color: #3fc68e;
`;

export const YellowBadge = styled.div`
  text-align: center;
  color: white;
  width: 28px;
  height: 24px;
  margin-left: 16px;
  border-radius: 2px;
  background-color: #eaca19;
`;

export const RedBadge = styled.div`
  text-align: center;
  color: white;
  width: 28px;
  height: 24px;
  margin-left: 16px;
  border-radius: 2px;
  background-color: #f15851;
`;

export const TranparentBadge = styled.div`
  width: 28px;
  height: 24px;
  margin-left: 16px;
`;

export const ChannelText = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.18px;
  color: #1f1e4d;
  margin-bottom: 1em;
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  font-size: 14px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  svg {
    width: 32px;
    height: 32px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 8px;
    height: 1px;
    background-color: black;
    margin-left: -29px; // -(8px this width + (32px icon width / 2) + 5px gap)
  }

  &:after {
    margin-left: 21px; // (32px icon width / 2) + 5px gap
  }
`;
