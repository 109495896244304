import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { LocationState } from "./locations.store";
import { LocationDto } from "../api/locations.api.dto";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  selectClientsData,
  selectClientsStatus,
} from "../../clients/store/clients.selector";
import { LocationModel } from "../model/locations.model";

export const selectSelf = (state: RootState): RootState => state;

export const selectLocationsRootState = createSelector(
  selectSelf,
  (state: RootState): LocationState => state.locations
);

export const selectLocationsState = createSelector(
  selectLocationsRootState,
  (locations: LocationState): AsyncStateModel<LocationDto[]> =>
    locations.locations
);

export const selectLocationsData = createSelector(
  selectLocationsState,
  (locationPage: AsyncStateModel<LocationDto[]>): LocationDto[] =>
    locationPage.data
);

export const selectLocationsStatus = createSelector(
  selectLocationsState,
  (locations: AsyncStateModel<LocationDto[]>): AsyncStatus => locations.status
);

export const selectIsLocationsStatusPending = createSelector(
  selectLocationsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectIsLocationsTablePending = createSelector(
  selectLocationsStatus,
  selectClientsStatus,
  (locationsStatus, clientsStatus) =>
    (locationsStatus === AsyncStatus.Void ||
      locationsStatus === AsyncStatus.Pending) &&
    (clientsStatus === AsyncStatus.Void ||
      clientsStatus === AsyncStatus.Pending)
);

export const selectSearchLocations = createSelector(
  selectLocationsRootState,
  (location): string => location.searchLocations
);

export const selectLocationsTableData = createSelector(
  selectSearchLocations,
  selectLocationsData,
  selectClientsData,
  (searchValue, locations, clients): LocationModel[] => {
    const data = locations.map((location) => {
      const clientForLocation = clients.find(
        (client) => client.id === Number(location.client_id)
      );

      return {
        ...location,
        clientName: clientForLocation?.name || "",
        partnerName: clientForLocation?.partner?.name || "",
      };
    });

    if (searchValue) {
      return data.filter(
        (location) =>
          location.title.toLowerCase().includes(searchValue.toLowerCase()) ||
          location.clientName
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          location.description.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectCreateLocationState = createSelector(
  selectLocationsRootState,
  (locations): AsyncStateModel<null> => locations.createLocation
);

export const selectCreateLocationStatus = createSelector(
  selectCreateLocationState,
  (createLocation): AsyncStatus => createLocation.status
);

export const selectIsCreateLocationStatusPending = createSelector(
  selectCreateLocationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditLocationState = createSelector(
  selectLocationsRootState,
  (locations): AsyncStateModel<null> => locations.editLocation
);

export const selectEditLocationStatus = createSelector(
  selectEditLocationState,
  (editLocation): AsyncStatus => editLocation.status
);

export const selectIsEditLocationStatusPending = createSelector(
  selectEditLocationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteLocationState = createSelector(
  selectLocationsRootState,
  (locations): AsyncStateModel<null> => locations.deleteLocation
);

export const selectDeleteLocationStatus = createSelector(
  selectDeleteLocationState,
  (deleteLocation): AsyncStatus => deleteLocation.status
);

export const selectIsDeleteLocationStatusPending = createSelector(
  selectDeleteLocationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
