import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC, useState } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  StyledCloseIcon,
  StyledDialogTitleContainer,
  StyledIconButton,
  StyledEditIcon,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { PartnerModalForm } from "../PartnerModalForm/PartnerModalForm";
import {
  CreateEditPartnerModel,
  PartnerModel,
} from "../../model/partners.model";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";

export interface IPartnerModal {
  name: string;
  onClose: () => void;
  onSubmit: (client: CreateEditPartnerModel, id?: number) => void;
  partner?: PartnerModel;
  industries: string[];
  onDelete?: (id: number) => void;
  isPending: boolean;
}

export const PartnerModal: FC<IPartnerModal> = ({
  name,
  isPending,
  onClose,
  onSubmit,
  partner,
  industries,
  onDelete,
}) => {
  const [editView, setEditView] = useState(false);

  const handleOnDelete = () => {
    if (onDelete && partner) {
      onDelete(partner.id);
    }
  };

  const getModalHeader = () => {
    if (partner && editView) {
      return <Typography variant="h2">Edit Partner</Typography>;
    }
    if (partner && !editView) {
      return <Typography variant="h2">View Partner</Typography>;
    }
    return <Typography variant="h2">Add Partner</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid container item xs={4} justify="flex-start">
        {editView && (
          <ConnectedShowForPermissions
            feature="partners"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView ? (
          <>
            <Box display="inline" mr={1}>
              <Button
                color="primary"
                onClick={() => {
                  onClose();
                  setEditView(false);
                }}
                disabled={pending}
              >
                Cancel
              </Button>
            </Box>
            <Box display="inline">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={pending}
                pending={pending}
              >
                Save
              </Button>
            </Box>
          </>
        ) : (
          <Box display="inline">
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button onClick={onClose} disabled={pending} color="primary">
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add Partner
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (partner) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        {!editView && partner && (
          <ConnectedShowForPermissions
            feature="partners"
            permissions={{ allOf: ["update"] }}
          >
            <StyledIconButton
              aria-label="close"
              onClick={() => setEditView(true)}
            >
              <StyledEditIcon />
            </StyledIconButton>
          </ConnectedShowForPermissions>
        )}
        <StyledIconButton
          aria-label="close"
          onClick={() => {
            onClose();
            setEditView(false);
          }}
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = (pending: boolean) => {
    return (
      <PartnerModalForm
        partner={partner}
        industries={industries}
        onSubmit={onSubmit}
        displayActionButtons={() => displayActionButtons(pending)}
        isPending={pending}
        editView={editView}
      />
    );
  };

  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
    />
  );
};
