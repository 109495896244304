import { Box, Divider, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC, useState } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";
import {
  StyledCloseIcon,
  StyledDialogTitleContainer,
  StyledIconButton,
  StyledEditIcon,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { LocationModalContent } from "../LocationModalForm/LocationModalForm";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import {
  CreateEditLocationModel,
  LocationModel,
} from "../../model/locations.model";

export interface ILocationModal {
  name: string;
  onClose: () => void;
  onSubmit: (location: CreateEditLocationModel) => void;
  location?: LocationModel;
  clients: ClientDto[];
  onDelete?: (id: number) => void;
  isPending: boolean;
  clientId?: number | null;
}

export const LocationModal: FC<ILocationModal> = ({
  name,
  onClose,
  onSubmit,
  location,
  clients,
  onDelete,
  isPending,
  clientId,
}) => {
  const [editView, setEditView] = useState(false);

  const handleOnDelete = () => {
    if (onDelete && location) {
      onDelete(location.id);
    }
  };

  const getModalHeader = () => {
    if (location && editView) {
      return <Typography variant="h2">Edit Location</Typography>;
    }
    if (location && !editView) {
      return <Typography variant="h2">View Location</Typography>;
    }
    return <Typography variant="h2">Add Location</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item xs={4} justify="flex-start">
        {editView && (
          <ConnectedShowForPermissions
            feature="locations"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView ? (
          <>
            <Box display="inline" mr={1}>
              <Button
                color="primary"
                onClick={() => {
                  onClose();
                  setEditView(false);
                }}
                disabled={pending}
              >
                Cancel
              </Button>
            </Box>

            <Box display="inline">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={pending}
                pending={pending}
              >
                Save
              </Button>
            </Box>
          </>
        ) : (
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
            >
              Close
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button
              onClick={() => {
                onClose();
                setEditView(false);
              }}
              disabled={pending}
              color="primary"
            >
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add Location
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (location) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        {!editView && location && (
          <ConnectedShowForPermissions
            feature="locations"
            permissions={{ allOf: ["update"] }}
          >
            <StyledIconButton
              aria-label="edit"
              onClick={() => setEditView(true)}
            >
              <StyledEditIcon />
            </StyledIconButton>
          </ConnectedShowForPermissions>
        )}
        <StyledIconButton
          aria-label="close"
          onClick={() => {
            onClose();
            setEditView(false);
          }}
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = (pending: boolean) => {
    return (
      <LocationModalContent
        location={location}
        clients={clients}
        onSubmit={onSubmit}
        displayActionButtons={() => displayActionButtons(pending)}
        isPending={pending}
        editView={editView}
        clientId={clientId}
      />
    );
  };

  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
    />
  );
};
