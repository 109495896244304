import { Card, FormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import { IconButton } from "../../../app/component/IconButton/IconButton";

export const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const ExportIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledTooltipDiv = styled.div`
  width: 110%;
  border: #000000 solid 1px;
  padding: 10px;
  background-color: white;
  border-radius: 2.5px;

  p {
    margin-bottom: ${({ theme }) => theme.spacing(0.25)}px;

    &:last-child {
      font-size: ${({ theme }) => `${theme.typography.bodyTiny.fontSize}`};
      margin-top: ${({ theme }) => theme.spacing(1.25)}px;
      margin-bottom: 0px;
    }
  }
`;

export const StyledCard = styled(Card)`
  position: relative;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  > span {
    font-weight: 600 !important;
  }
  margin: 10px 0 0 20px;
`;
