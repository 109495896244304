import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createClient } from "../store/clients.action";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ClientModal } from "../component/ClientModal/ClientModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditClientModel } from "../model/clients.model";
import {
  selectFetchIndustriesData,
  selectIsFetchIndustriesStatusPending,
} from "../../industry/store/industry.selector";
import { ADD_CLIENT_MODAL } from "../../app/const/modals";
import { selectIsClientsTableStatusPending } from "../store/clients.selector";
import { selectPartnersData } from "../../partners/store/partners.selector";

export const AddClientModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const industriesData = useSelector(selectFetchIndustriesData);
  const partnersData = useSelector(selectPartnersData);
  const isClientsPending = useSelector(selectIsClientsTableStatusPending);
  const isFetchIndustriesPending = useSelector(
    selectIsFetchIndustriesStatusPending
  );

  const openModal = () => dispatch(openModalAction({ name: ADD_CLIENT_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_CLIENT_MODAL }));

  const onCreateClient = (values: CreateEditClientModel) =>
    dispatch(createClient(values));

  return (
    <>
      <ConnectedShowForPermissions
        feature="clients"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isClientsPending}
        >
          Add Client
        </Button>
      </ConnectedShowForPermissions>
      <ClientModal
        name={ADD_CLIENT_MODAL}
        onClose={closeModal}
        onSubmit={onCreateClient}
        industries={industriesData}
        partners={partnersData}
        isPending={isFetchIndustriesPending}
      />
    </>
  );
};
