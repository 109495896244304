import { Box, Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { StyledNAHealth } from "../../../../app/component/Table/Table.style";
import { AssetDto, SensorDto } from "../../../api/asset.api.dto";
import {
  GreenBadge,
  YellowBadge,
  RedBadge,
  StyledCard,
  StyledTopCard,
  StyledBottomCard,
  StyledSelectedCard,
  StyledTypography,
  ImageWrapper,
} from "./Channels.style";
import { getSensorTypeIcon } from "../../../../sensorTypes/util/get-sensor-type-icon";
import { StyledWarningIcon } from "../../AssetSensorsTable/AssetSensorsTable.style";

export interface IChannelsProps {
  sensors: SensorDto[];
  selectedSensor: number;
  onClick: (index: number) => void;
  asset: AssetDto | null;
}

export const Channels: FC<IChannelsProps> = ({
  sensors,
  selectedSensor,
  onClick,
  asset,
}) => {
  const healthRatingBadge = (
    healthRating: number,
    alertType: number | null,
    sensorState: string,
    alarmed: number
  ) => {
    if (alarmed === 1) {
      return <StyledWarningIcon color="error" style={{ marginLeft: 3 }} />;
    }
    if (
      !asset?.health_is_enabled ||
      !alertType ||
      alertType === null ||
      sensorState === "errored"
    ) {
      return <StyledNAHealth>N/A</StyledNAHealth>;
    }
    if (healthRating > 5) {
      return <GreenBadge>{healthRating}</GreenBadge>;
    }
    if (healthRating > 2.5) {
      return <YellowBadge>{healthRating}</YellowBadge>;
    }
    if (healthRating === null) {
      return (
        <StyledNAHealth style={{ marginLeft: "16px" }}>N/A</StyledNAHealth>
      );
    }
    return <RedBadge>{healthRating}</RedBadge>;
  };
  const sensorCard = (sensor: SensorDto, index: number) => {
    return (
      <Box pt={1} pb={1}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6} md={2}>
            <Box mb={2}>
              <Typography variant="body2">
                Ch. {sensor.channel.channel_index + 1}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageWrapper>
              {getSensorTypeIcon(sensors[index].sensor_type)}
            </ImageWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box ml={1}>
              <StyledTypography variant="body2">
                {sensor?.reading}
              </StyledTypography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {healthRatingBadge(
              sensor.health_rating,
              sensor.alarm.alert_type,
              sensor.state,
              sensor.is_alarmed
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const sensorCardContainer = (sensor: SensorDto, index: number) => {
    if (index === selectedSensor) {
      return (
        <StyledSelectedCard onClick={() => onClick(index)}>
          {sensorCard(sensor, index)}
        </StyledSelectedCard>
      );
    }
    if (index === 0) {
      return (
        <StyledTopCard onClick={() => onClick(index)}>
          {sensorCard(sensor, index)}
        </StyledTopCard>
      );
    }
    if (index === sensors.length - 1) {
      return (
        <StyledBottomCard onClick={() => onClick(index)}>
          {sensorCard(sensor, index)}
        </StyledBottomCard>
      );
    }
    return (
      <StyledCard onClick={() => onClick(index)}>
        {sensorCard(sensor, index)}
      </StyledCard>
    );
  };

  return (
    <div>
      {sensors.map((sensor, i) => (
        <div key={i}>{sensorCardContainer(sensor, i)}</div>
      ))}
    </div>
  );
};
