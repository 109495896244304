import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createUser as createUserAction } from "../store/users.action";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { UserModal } from "../component/UserModal/UserModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditUserModel } from "../model/users.model";
import { ADD_USER_MODAL } from "../../app/const/modals";
import { selectIsUsersTableStatusPending } from "../store/users.selector";
import {
  selectPartnersData,
  selectIsPartnersStatusPending,
} from "../../partners/store/partners.selector";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import {
  selectTeamsData,
  selectIsTeamsStatusPending,
} from "../../teams/store/teams.selector";
import {
  selectRolesData,
  selectIsRolesStatusPending,
} from "../../roles/store/roles.selector";
import { selectProfileClientId } from "../../auth/store/auth.selector";

export const AddUserModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const partnersData = useSelector(selectPartnersData);
  const clientsData = useSelector(selectClientsData);
  const teamsData = useSelector(selectTeamsData);
  const rolesData = useSelector(selectRolesData);
  const isUsersPending = useSelector(selectIsUsersTableStatusPending);
  const isFetchPartnersPending = useSelector(selectIsPartnersStatusPending);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const isFetchTeamsPending = useSelector(selectIsTeamsStatusPending);
  const isFetchRolesPending = useSelector(selectIsRolesStatusPending);
  const clientId = useSelector(selectProfileClientId);

  const openModal = () => dispatch(openModalAction({ name: ADD_USER_MODAL }));

  const closeModal = () => dispatch(closeModalAction({ name: ADD_USER_MODAL }));

  const onCreateUser = (values: CreateEditUserModel) =>
    dispatch(createUserAction(values));

  return (
    <>
      <ConnectedShowForPermissions
        feature="users"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isUsersPending}
        >
          Add User
        </Button>
      </ConnectedShowForPermissions>
      <UserModal
        clientId={clientId}
        name={ADD_USER_MODAL}
        onClose={closeModal}
        onSubmit={onCreateUser}
        partners={partnersData}
        clients={clientsData}
        teams={teamsData}
        roles={rolesData}
        isPending={
          isFetchPartnersPending ||
          isFetchClientsPending ||
          isFetchTeamsPending ||
          isFetchRolesPending
        }
      />
    </>
  );
};
