import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { BookmarkModel } from "../model/bookmarks.model";

export interface BookmarksState {
  fetchBookmarks: AsyncStateModel<BookmarkModel[]>;
}

export const bookmarksState: BookmarksState = {
  fetchBookmarks: {
    status: AsyncStatus.Void,
    data: [],
  },
};
