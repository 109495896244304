import styled from "styled-components";
import { Card } from "@material-ui/core";
import { Button } from "../../../app/component/Button/Button";

export const ToggleButton = styled(Button)`
  border-radius: 20px;
`;

export const StyledCard = styled(Card)`
  min-height: 275;
  margin: ${({ theme }) => `${theme.spacing(1)}px`} 0;
  .MuiCardContent-root {
    padding: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;
