import React, { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";
import {
  TrimActionCellWrapper,
  StyledCard,
  TrimmedTableCell,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import { ClientModel, CreateEditClientModel } from "../../model/clients.model";
import { ClientModal } from "../ClientModal/ClientModal";
import { IndustryDto } from "../../../industry/api/industry.api.dto";
import { EDIT_CLIENT_MODAL } from "../../../app/const/modals";
import { DASHBOARD } from "../../../app/const/routes";
import { PartnerDto } from "../../../partners/api/partners.api.dto";

interface IClientsTable {
  onEditClient: (editClientModel: CreateEditClientModel, id?: number) => void;
  onDeleteClient: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  clients: ClientModel[];
  industries: IndustryDto[];
  partners: PartnerDto[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const ClientsTable: FC<IClientsTable> = ({
  clients,
  industries,
  partners,
  isTablePending,
  isModalPending,
  onEditClient,
  onDeleteClient,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(
    undefined
  );

  const openModal = (client: ClientModel) => {
    setSelectedClient(client);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedClient(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    {
      id: "name",
      Header: "Client Name",
      accessor: "name",
      Cell: ({ row: { original } }: { row: { original: ClientModel } }) => (
        <RouterLink to={`${DASHBOARD}/${original.id}`}>
          <TrimmedTableCell color="primary" variant="body2">
            {original.name}
          </TrimmedTableCell>
        </RouterLink>
      ),
    },
    {
      id: "partner",
      Header: "Partner",
      accessor: "partner.name",
    },
    {
      id: "email",
      Header: "Contact Email",
      accessor: "email",
    },
    {
      id: "phone",
      Header: "Contact Phone",
      accessor: "phone",
      Cell: ({ row: { original } }: { row: { original: ClientModel } }) => (
        <TrimmedTableCell variant="body2">{original.phone}</TrimmedTableCell>
      ),
    },
    { id: "status", Header: "Status", accessor: "status" },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: ClientModel } }) => (
        <TrimActionCellWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            Account Info
          </Button>
        </TrimActionCellWrapper>
      ),
    },
    { accessor: "id" },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={clients}
          hover
          sorting={{ id: "name", desc: false }}
          // trimRows
        />
      </StyledCard>
      <ClientModal
        name={EDIT_CLIENT_MODAL}
        client={selectedClient}
        onClose={closeModal}
        onSubmit={onEditClient}
        onDelete={onDeleteClient}
        industries={industries}
        partners={partners}
        isPending={isModalPending}
      />
    </>
  );
};
