import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDevices as fetchDevicesRequest,
  fetchDeviceTypes as fetchDeviceTypesRequest,
  addDevice as addDeviceRequest,
  editDevice as editDeviceRequest,
  deleteDevice as deleteDeviceRequest,
  clearDevice as clearDeviceRequest,
} from "../api/devices.api";
import withError from "../../app/util/with-thunk-error";
import {
  CreateEditDeviceDto,
  DeviceListDto,
  DeviceTypeDto,
} from "../api/devices.api.dto";
import {
  closeModal,
  setModalStatusPending,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_DEVICE_MODAL, EDIT_DEVICE_MODAL } from "../../app/const/modals";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";

export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<DeviceListDto[]> => fetchDevicesRequest()
  )
);

export const fetchDeviceTypes = createAsyncThunk(
  "devices/fetchDeviceTypes",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<DeviceTypeDto[]> =>
      fetchDeviceTypesRequest()
  )
);

export const addDevice = createAsyncThunk(
  "devices/fetchDeviceTypes",
  withError(
    // eslint-disable-next-line
    async (deviceDto: CreateEditDeviceDto, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_DEVICE_MODAL }));
      await addDeviceRequest(deviceDto);
      dispatch(closeModal({ name: ADD_DEVICE_MODAL }));
      dispatch(openSuccessNotification("Device has been created"));
      dispatch(fetchDevices());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_DEVICE_MODAL }))
  )
);

export const editDevice = createAsyncThunk(
  "devices/editDevice",
  withError(
    // eslint-disable-next-line
    async (
      {
        id,
        editDeviceDto,
      }: { editDeviceDto: CreateEditDeviceDto; id?: number },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_DEVICE_MODAL }));
      await editDeviceRequest(editDeviceDto, id);
      dispatch(closeModal({ name: EDIT_DEVICE_MODAL }));
      dispatch(openSuccessNotification("Device has been updated"));
      dispatch(fetchDevices());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_DEVICE_MODAL }))
  )
);

export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  withError(
    // eslint-disable-next-line
    async (deviceId: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_DEVICE_MODAL }));
      await deleteDeviceRequest(deviceId);
      dispatch(closeModal({ name: EDIT_DEVICE_MODAL }));
      dispatch(openSuccessNotification("Device has been deleted"));
      dispatch(fetchDevices());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_DEVICE_MODAL }))
  )
);

export const clearDevice = createAsyncThunk(
  "devices/clearDevice",
  withError(
    // eslint-disable-next-line
    async (deviceId: number | undefined, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_DEVICE_MODAL }));
      await clearDeviceRequest(deviceId);
      dispatch(closeModal({ name: EDIT_DEVICE_MODAL }));
      dispatch(openSuccessNotification("Device Data Cleared Successfully"));
      dispatch(fetchDevices());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_DEVICE_MODAL }))
  )
);
