import styled from "styled-components";
import { Card, Avatar } from "@material-ui/core";
import { BACKGROUND_DEFAULT, TEXT_PRIMARY } from "../../../app/theme/colors";

export const StyledCard = styled(Card)`
  height: 420px;
  margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  height: ${({ theme }) => `${theme.spacing(4)}px`};
  width: ${({ theme }) => `${theme.spacing(4)}px`};
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => `${theme.palette.avatar.text}`};
  background-color: ${BACKGROUND_DEFAULT};
  color: ${TEXT_PRIMARY};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
`;
