import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchAssets as fetchAssetsRequest,
  fetchAssetById as fetchAssetByIdRequest,
  createAsset as createAssetRequest,
  deleteAsset as deleteAssetRequest,
  editAsset as editAssetRequest,
  resetRuntime as assetResetRuntime,
  fetchCategories as fetchCategoriesRequest,
  fetchTags as fetchTagsRequest,
  fetchHealth as fetchHealthRequest,
  fetchAssetHealthData as fetchAssetHealthDataRequest,
  fetchAssetSensorReadingsData as fetchAssetSensorReadingsDataRequest,
  fetchAssetRuntime as fetchAssetRuntimeRequest,
  fetchSensors as fetchSensorsRequest,
  createSensor as createSensorRequest,
  editSensor as editSensorRequest,
  deleteSensor as deleteSensorRequest,
  fetchAssetUsageData as fetchAssetUsageDataRequest,
  fetchDyanmoData as fetchDyanmoDataRequest,
  fetchAssetNotes as fetchAssetNotesRequest,
  deleteAssetNote as deleteAssetNoteRequest,
  addAssetNote as addAssetNoteRequest,
  pinAssetNote as pinAssetNoteRequest,
  addAssetAttachment as addAssetAttachmentRequest,
  fetchAssetAttachments as fetchAssetAttachmentsRequest,
  downloadAssetAttachment as downloadAssetAttachmentRequest,
  deleteAssetAttachment as deleteAssetAttachmentRequest,
  fetchAssetSensors as fetchAssetSensorsRequest,
  fetchAssetsByClientId as fetchAssetsByClientIdRequest,
  addAssetToBookmark as addAssetToBookmarkRequest,
  removeAssetFromBookmark as removeAssetFromBookmarkRequest,
  fetchAssetAlertHistory as fetchAssetAlertHistoryRequest,
} from "../api/asset.api";
import withError from "../../app/util/with-thunk-error";
import {
  AssetDto,
  ChannelGraphData,
  AssetTagDto,
  AssetTrendsDataRequest,
  AssetRuntimeDto,
  SensorDto,
  AssetUsageDto,
  AssetDynamoRollupDto,
  AnalyticsDataRequest,
  AssetRuntimeDataRequest,
  AssetUsageDataRequest,
  AssetNoteDto,
  AssetAttachmentDto,
  SensorTableDto,
  AssetAlertsDto,
} from "../api/asset.api.dto";
import { CategoryDto } from "../../category/api/category.api.dto";
import {
  CreateEditSensorModel,
  CreateEditAssetModel,
} from "../model/asset.model";
import { mapCreateEditSensorModelToDto } from "../api/asset.api.mapper";
import {
  closeModal,
  setModalStatusPending,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import {
  ADD_ASSET_ATTACHMENT_MODAL,
  ADD_ASSET_MODAL,
  ADD_ASSET_NOTE_MODAL,
  EDIT_ASSET_MODAL,
  ADD_ASSET_SENSOR_MODAL,
  EDIT_ASSET_SENSOR_MODAL,
} from "../../app/const/modals";
import { mapCreateEditAssetModelToDto } from "../api/assets.api.mapper";

export const fetchAssets = createAsyncThunk(
  "assets/fetchAssets",
  withError(
    async (clientId?: number): Promise<AssetDto[]> => {
      return fetchAssetsRequest(clientId);
    }
  )
);

export const fetchAssetsByClientId = createAsyncThunk(
  "assets/fetchAssetsByClientId",
  withError(
    async (clientId: number): Promise<AssetDto[]> => {
      return fetchAssetsByClientIdRequest(clientId);
    }
  )
);

export const showAsset = createAsyncThunk(
  "assets/showAsset",
  withError(
    async (assetId: number): Promise<AssetDto> => {
      return fetchAssetByIdRequest(assetId);
    }
  )
);

export const createAsset = createAsyncThunk(
  "assets/create",
  withError(
    async (
      {
        asset,
        clientId,
      }: {
        asset: CreateEditAssetModel;
        clientId?: string;
      },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_ASSET_MODAL }));
      await createAssetRequest(mapCreateEditAssetModelToDto(asset));
      dispatch(closeModal({ name: ADD_ASSET_MODAL }));
      dispatch(openSuccessNotification("Asset has been added"));
      dispatch(fetchAssets(Number(clientId)));
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_ASSET_MODAL }))
  )
);

export const deleteAsset = createAsyncThunk(
  "assets/delete",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_ASSET_MODAL }));
      await deleteAssetRequest(id);
      dispatch(closeModal({ name: EDIT_ASSET_MODAL }));
      dispatch(openSuccessNotification("Asset has been deleted"));
      dispatch(fetchAssets());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_ASSET_MODAL }))
  )
);

export const editAsset = createAsyncThunk(
  "assets/edit",
  withError(
    async (
      {
        id,
        editAssetModel,
      }: { id: number; editAssetModel: CreateEditAssetModel },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_ASSET_MODAL }));
      await editAssetRequest(id, mapCreateEditAssetModelToDto(editAssetModel));
      await dispatch(showAsset(id));
      dispatch(closeModal({ name: EDIT_ASSET_MODAL }));
      dispatch(openSuccessNotification("Asset has been updated"));
      dispatch(fetchAssets());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_ASSET_MODAL }))
  )
);

export const fetchRuntimeData = createAsyncThunk(
  "analytics/asset-runtime-data",
  withError(
    async (request: AssetRuntimeDataRequest): Promise<AssetRuntimeDto[]> => {
      const response = await fetchAssetRuntimeRequest(request);

      return response;
    }
  )
);

export const fetchCategories = createAsyncThunk(
  "assets/fetchCategories",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (clientId?: number): Promise<CategoryDto[]> => {
      return fetchCategoriesRequest(clientId);
    }
  )
);

export const fetchTags = createAsyncThunk(
  "assets/fetchTags",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (clientId?: number): Promise<AssetTagDto[]> => {
      return fetchTagsRequest(clientId);
    }
  )
);

export const fetchHealth = createAsyncThunk(
  "assets/fetchHealth",
  withError(
    async (assetId: number): Promise<{ data: number }> => {
      return fetchHealthRequest(assetId);
    }
  )
);

export const fetchSensors = createAsyncThunk(
  "assets/fetchSensors",
  withError(
    async (assetId: number): Promise<SensorDto[]> => {
      return fetchSensorsRequest(assetId);
    }
  )
);

export const fetchAssetHealthData = createAsyncThunk(
  "assets/fetchAssetTrendsData",
  withError(
    async (request: AssetTrendsDataRequest): Promise<ChannelGraphData[]> => {
      return fetchAssetHealthDataRequest(request);
    }
  )
);

export const fetchAssetSensorReadingsData = createAsyncThunk(
  "assets/fetchAssetSensorReadingsData",
  withError(
    async (request: AssetTrendsDataRequest): Promise<ChannelGraphData[]> => {
      return fetchAssetSensorReadingsDataRequest(request);
    }
  )
);

export const resetAssetRuntime = createAsyncThunk(
  "assets/reset/runtime",
  withError(
    async (asset: AssetDto, { dispatch }): Promise<AssetDto> => {
      const response = await assetResetRuntime(asset);
      dispatch(openSuccessNotification("Runtime successfully reset"));
      dispatch(fetchRuntimeData({ assetIds: [asset.id] }));
      return response;
    }
  )
);

export const fetchAssetUsageData = createAsyncThunk(
  "analytics/fetchAssetUsageData",
  withError(
    async (request: AssetUsageDataRequest): Promise<AssetUsageDto[]> => {
      return fetchAssetUsageDataRequest(request);
    }
  )
);

export const fetchAssetNotes = createAsyncThunk(
  "assets/fetchAssetNotes",
  withError(
    async (assetId: number): Promise<AssetNoteDto[]> => {
      return fetchAssetNotesRequest(assetId);
    }
  )
);

export const fetchDyanmoData = createAsyncThunk(
  "analytics/fetchDyanmoData",
  withError(
    async (request: AnalyticsDataRequest): Promise<AssetDynamoRollupDto[]> => {
      return fetchDyanmoDataRequest(request);
    }
  )
);

export const deleteAssetNote = createAsyncThunk(
  "assets/deleteAssetNote",
  withError(
    async (noteId: number): Promise<boolean> => {
      return deleteAssetNoteRequest(noteId);
    }
  )
);

export const pinAssetNote = createAsyncThunk(
  "assets/pinAssetNote",
  withError(
    async ({
      noteId,
      isPinned,
    }: {
      noteId: number;
      isPinned: boolean;
    }): Promise<boolean> => {
      return pinAssetNoteRequest(noteId, isPinned);
    }
  )
);

export const addAssetNote = createAsyncThunk(
  "assets/addAssetNote",
  withError(
    async (
      {
        assetId,
        text,
      }: {
        assetId: number;
        text: string;
      },
      { dispatch }
    ): Promise<boolean> => {
      dispatch(setModalStatusPending({ name: ADD_ASSET_NOTE_MODAL }));
      const data = await addAssetNoteRequest(assetId, text);
      dispatch(closeModal({ name: ADD_ASSET_NOTE_MODAL }));

      return data;
    },

    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_ASSET_NOTE_MODAL }))
  )
);

export const addAssetAttachment = createAsyncThunk(
  "assets/addAssetAttachment",
  withError(
    async (
      {
        assetId,
        name,
        file,
      }: {
        assetId: number;
        name: string;
        file: File;
      },
      { dispatch }
    ): Promise<boolean> => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);

      dispatch(setModalStatusPending({ name: ADD_ASSET_ATTACHMENT_MODAL }));
      const data = await addAssetAttachmentRequest(assetId, formData);
      dispatch(closeModal({ name: ADD_ASSET_ATTACHMENT_MODAL }));

      return data;
    },

    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_ASSET_ATTACHMENT_MODAL }))
  )
);

export const fetchAssetAttachments = createAsyncThunk(
  "assets/fetchAssetAttachments",
  withError(
    async (assetId: number): Promise<AssetAttachmentDto[]> => {
      return fetchAssetAttachmentsRequest(assetId);
    }
  )
);

export const downloadAssetAttachment = createAsyncThunk(
  "assets/downloadAssetAttachment",
  withError(
    async ({
      attachmentId,
      name,
      extension,
    }: {
      attachmentId: number;
      name: string;
      extension: string;
    }): Promise<boolean> => {
      return downloadAssetAttachmentRequest(attachmentId, name, extension);
    }
  )
);

export const deleteAssetAttachment = createAsyncThunk(
  "assets/deleteAssetAttachment",
  withError(
    async (noteId: number): Promise<boolean> => {
      return deleteAssetAttachmentRequest(noteId);
    }
  )
);

export const fetchAssetSensors = createAsyncThunk(
  "assets/fetchAssetSensors",
  withError(
    async (assetId: number): Promise<SensorTableDto[]> => {
      return fetchAssetSensorsRequest(assetId);
    }
  )
);

export const deleteSensor = createAsyncThunk(
  "assets/deleteSensor",
  withError(
    async (
      {
        id,
        assetId,
      }: {
        id: number;
        assetId: number;
      },
      { dispatch }
    ): Promise<boolean> => {
      dispatch(setModalStatusPending({ name: EDIT_ASSET_SENSOR_MODAL }));
      const response = await deleteSensorRequest(id);
      dispatch(closeModal({ name: EDIT_ASSET_SENSOR_MODAL }));
      dispatch(openSuccessNotification("Sensor has been deleted"));
      dispatch(fetchAssetSensors(assetId));
      dispatch(fetchSensors(assetId));

      return response;
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_ASSET_SENSOR_MODAL }))
  )
);

export const editSensor = createAsyncThunk(
  "assets/editSensor",
  withError(
    async (
      {
        id,
        values,
        assetId,
      }: {
        id: number;
        values: CreateEditSensorModel;
        assetId: number;
      },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_ASSET_SENSOR_MODAL }));
      await editSensorRequest(id, mapCreateEditSensorModelToDto(values));
      dispatch(closeModal({ name: EDIT_ASSET_SENSOR_MODAL }));
      dispatch(openSuccessNotification("Sensor has been updated"));
      dispatch(fetchAssetSensors(assetId));
      dispatch(fetchSensors(assetId));
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_ASSET_SENSOR_MODAL }))
  )
);

export const createSensor = createAsyncThunk(
  "assets/createSensor",
  withError(
    async (
      {
        values,
        assetId,
      }: {
        values: CreateEditSensorModel;
        assetId: number;
      },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_ASSET_SENSOR_MODAL }));
      await createSensorRequest(mapCreateEditSensorModelToDto(values));
      dispatch(closeModal({ name: ADD_ASSET_SENSOR_MODAL }));
      dispatch(openSuccessNotification("Sensor has been added"));
      dispatch(fetchAssetSensors(assetId));
      dispatch(fetchSensors(assetId));
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_ASSET_SENSOR_MODAL }))
  )
);

export const addAssetToBookmark = createAsyncThunk(
  "assets/addAssetToBookmark",
  withError(
    async ({
      assetId,
      tabName,
    }: {
      assetId: number;
      tabName: string;
    }): Promise<void> => {
      return addAssetToBookmarkRequest(assetId, tabName);
    }
  )
);

export const removeAssetFromBookmark = createAsyncThunk(
  "assets/removeAssetFromBookmark",
  withError(
    async ({
      assetId,
      tabName,
    }: {
      assetId: number;
      tabName: string;
    }): Promise<void> => {
      return removeAssetFromBookmarkRequest(assetId, tabName);
    }
  )
);

export const fetchAssetAlertHistory = createAsyncThunk(
  "assets/fetchAssetAlertHistory",
  withError(
    async (assetId: number): Promise<AssetAlertsDto[]> => {
      return fetchAssetAlertHistoryRequest(assetId);
    }
  )
);
