import React, { FC } from "react";
import { useFormik } from "formik";
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  clientModalFormValidation,
  DESCRIPTION_CHAR_LIMIT,
  NAME_CHAR_LIMIT,
} from "./clientModalForm.validation";
import {
  StyledModalContent,
  StyledSwitch,
} from "../../../app/component/Modal/Modal.style";
import { ClientModel, CreateEditClientModel } from "../../model/clients.model";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";
import { PartnerDto } from "../../../partners/api/partners.api.dto";

export interface IClientModalForm {
  client?: ClientModel;
  industries: string[];
  partners: PartnerDto[];
  onSubmit: (values: CreateEditClientModel, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  editView: boolean;
}

export const ClientModalForm: FC<IClientModalForm> = ({
  client,
  industries,
  partners,
  onSubmit,
  displayActionButtons,
  isPending,
  editView,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: client?.name || "",
      email: client?.email || "",
      description: client?.description || "",
      first_name: client?.first_name || "",
      last_name: client?.last_name || "",
      phone: client?.phone || "",
      address: client?.address || "",
      industry: client?.industry || "",
      is_enabled: client?.is_enabled !== 0,
      partner_id: Number(client?.partner_id) || 0,
    },
    validationSchema: clientModalFormValidation,
    onSubmit: (values: CreateEditClientModel) => onSubmit(values, client?.id),
  });

  const isSuperUser = useShowForRole([UserRole.SuperUser]);
  const getPartnersOptionLabel = (option: number) => {
    const match = partners.find((partner) => {
      return partner.id === option;
    });
    return match ? match.name : "";
  };

  const getPartnersOptions = () => {
    return [...partners]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((partner) => partner.id);
  };

  const handlePhoneChange = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      formik.setFieldValue("phone", onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      formik.setFieldValue("phone", number);
    }
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Client Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Client Name"
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          {isSuperUser ? (
            <Grid item xs={12}>
              <FormControl
                variant="filled"
                fullWidth
                required
                disabled={isPending}
              >
                <Autocomplete
                  options={getPartnersOptions()}
                  getOptionLabel={getPartnersOptionLabel}
                  id="partner_id"
                  disabled={isPending || (!editView && client !== undefined)}
                  value={formik.values.partner_id}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: number | null
                  ) => {
                    formik.setFieldValue("partner_id", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="partner_id"
                      label="Partner"
                      error={
                        formik.touched.partner_id &&
                        Boolean(formik.errors.partner_id)
                      }
                      helperText={
                        formik.touched.partner_id && formik.errors.partner_id
                      }
                      variant="filled"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={isPending}
            >
              <Autocomplete
                options={industries.sort()}
                id="industry"
                disabled={isPending || (!editView && client !== undefined)}
                value={formik.values.industry}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: string | null
                ) => {
                  formik.setFieldValue("industry", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="industry"
                    label="Client Industry"
                    error={
                      formik.touched.industry && Boolean(formik.errors.industry)
                    }
                    helperText={
                      formik.touched.industry && formik.errors.industry
                    }
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Client Description"
              multiline
              rows={3}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{
                maxLength: DESCRIPTION_CHAR_LIMIT,
              }}
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Client Contact</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="first_name"
              name="first_name"
              label="First Name"
              variant="filled"
              fullWidth
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              required
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="last_name"
              name="last_name"
              label="Last Name"
              variant="filled"
              fullWidth
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              required
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              variant="filled"
              fullWidth
              value={formik.values.phone}
              onChange={handlePhoneChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="filled"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              name="address"
              label="Address"
              variant="filled"
              fullWidth
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={9} sm={4}>
            <Typography variant="h4">Activate Client</Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            <StyledSwitch
              id="is_enabled"
              name="is_enabled"
              value={formik.values.is_enabled}
              checked={!!formik.values.is_enabled}
              onChange={formik.handleChange}
              disabled={isPending || (!editView && client !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
