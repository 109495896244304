import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchRoles as fetchRolesRequest,
  createRole as createRoleRequest,
  deleteRole as deleteRoleRequest,
  editRole as editRoleRequest,
} from "../api/roles.api";
import withError from "../../app/util/with-thunk-error";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_ROLE_MODAL, EDIT_ROLE_MODAL } from "../../app/const/modals";
import { RoleDto, CreateEditRoleDto } from "../api/roles.api.dto";

export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<RoleDto[]> => fetchRolesRequest()
  )
);

export const createRole = createAsyncThunk(
  "roles/createRole",
  withError(
    async (role: CreateEditRoleDto, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_ROLE_MODAL }));
      await createRoleRequest(role);
      dispatch(closeModal({ name: ADD_ROLE_MODAL }));
      dispatch(openSuccessNotification("Role has been added"));
      dispatch(fetchRoles());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_ROLE_MODAL }))
  )
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_ROLE_MODAL }));
      await deleteRoleRequest(id);
      dispatch(closeModal({ name: EDIT_ROLE_MODAL }));
      dispatch(openSuccessNotification("Role has been deleted"));
      dispatch(fetchRoles());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_ROLE_MODAL }))
  )
);

export const editRole = createAsyncThunk(
  "roles/editRole",
  withError(
    async (
      { id, editRoleDto }: { id: number; editRoleDto: CreateEditRoleDto },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_ROLE_MODAL }));
      await editRoleRequest(id, editRoleDto);
      dispatch(closeModal({ name: EDIT_ROLE_MODAL }));
      dispatch(openSuccessNotification("Role has been updated"));
      dispatch(fetchRoles());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_ROLE_MODAL }))
  )
);
