import { createSlice } from "@reduxjs/toolkit";
import { SensorTypesState, sensorTypesState } from "./sensorTypes.store";
import {
  fetchSensorTypes,
  fetchHardcodedSensorTypes,
  fetchHardcodedSensorUnits,
  deleteSensorType,
  editSensorType,
  createSensorType,
} from "./sensorTypes.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "sensorTypes",
  initialState: sensorTypesState,
  reducers: {
    searchSensorTypes: (
      state: SensorTypesState,
      { payload }: { payload: string }
    ) => ({
      ...state,
      searchSensorTypes: payload,
    }),
    clearFetchSensorTypesState: (state: SensorTypesState) => ({
      ...state,
      fetchSensorTypes: sensorTypesState.fetchSensorTypes,
    }),
    clearSearchSensorTypesState: (state: SensorTypesState) => ({
      ...state,
      searchSensorTypes: sensorTypesState.searchSensorTypes,
    }),
    clearFetchHardcodedSensorTypesState: (state: SensorTypesState) => ({
      ...state,
      fetchHardcodedSensorTypes: sensorTypesState.fetchHardcodedSensorTypes,
    }),
    clearFetchHardcodedSensorUnitsState: (state: SensorTypesState) => ({
      ...state,
      fetchHardcodedSensorUnits: sensorTypesState.fetchHardcodedSensorUnits,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSensorTypes.pending, (state) => ({
      ...state,
      fetchSensorTypes: {
        ...state.fetchSensorTypes,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchSensorTypes.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchSensorTypes: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchSensorTypes.rejected, (state) => ({
      ...state,
      fetchSensorTypes: {
        ...state.fetchSensorTypes,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteSensorType.pending, (state) => ({
      ...state,
      deleteSensorType: {
        ...state.deleteSensorType,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteSensorType.fulfilled, (state) => ({
      ...state,
      deleteSensorType: {
        ...state.deleteSensorType,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteSensorType.rejected, (state) => ({
      ...state,
      deleteSensorType: {
        ...state.deleteSensorType,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editSensorType.pending, (state) => ({
      ...state,
      editSensorType: {
        ...state.editSensorType,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editSensorType.fulfilled, (state) => ({
      ...state,
      editSensorType: {
        ...state.editSensorType,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editSensorType.rejected, (state) => ({
      ...state,
      editSensorType: {
        ...state.editSensorType,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createSensorType.pending, (state) => ({
      ...state,
      createSensorType: {
        ...state.createSensorType,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createSensorType.fulfilled, (state) => ({
      ...state,
      createSensorType: {
        ...state.createSensorType,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createSensorType.rejected, (state) => ({
      ...state,
      createSensorType: {
        ...state.createSensorType,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchHardcodedSensorTypes.pending, (state) => ({
      ...state,
      fetchHardcodedSensorTypes: {
        ...state.fetchHardcodedSensorTypes,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(
      fetchHardcodedSensorTypes.fulfilled,
      (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchHardcodedSensorTypes: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      }
    );
    builder.addCase(fetchHardcodedSensorTypes.rejected, (state) => ({
      ...state,
      fetchHardcodedSensorTypes: {
        ...state.fetchHardcodedSensorTypes,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchHardcodedSensorUnits.pending, (state) => ({
      ...state,
      fetchHardcodedSensorUnits: {
        ...state.fetchHardcodedSensorUnits,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(
      fetchHardcodedSensorUnits.fulfilled,
      (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchHardcodedSensorUnits: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      }
    );
    builder.addCase(fetchHardcodedSensorUnits.rejected, (state) => ({
      ...state,
      fetchHardcodedSensorUnits: {
        ...state.fetchHardcodedSensorUnits,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchSensorTypes,
  clearFetchSensorTypesState,
  clearSearchSensorTypesState,
  clearFetchHardcodedSensorTypesState,
  clearFetchHardcodedSensorUnitsState,
} = slice.actions;
export default slice.reducer;
