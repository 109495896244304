import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { DevicesState } from "./devices.store";
import { DeviceListDto } from "../api/devices.api.dto";
import { DeviceRecordingStatus } from "../enum/devices.enum";
import { DeviceListModel } from "../model/devices.model";
import { isToday } from "../../app/util/is-today";
import { DATE_AND_HOURS, HOURS } from "../../app/const/date-format";

const setMaxTextLength = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }

  const substrString = text.trim().substr(text.length - maxLength, text.length);

  return `...${substrString.trim()}`;
};

export const selectSelf = (state: RootState): RootState => state;

export const selectDevicesRootState = createSelector(
  selectSelf,
  (state: RootState): DevicesState => state.devices
);

export const selectFetchDevicesState = createSelector(
  selectDevicesRootState,
  (devices): AsyncStateModel<DeviceListDto[]> => devices.fetchDevices
);

export const selectFetchDevicesData = createSelector(
  selectFetchDevicesState,
  (fetchDevices): DeviceListDto[] => fetchDevices.data
);

export const selectFetchDevicesStatus = createSelector(
  selectFetchDevicesState,
  (fetchDevices): AsyncStatus => fetchDevices.status
);

export const selectIsFetchDevicesStatusPending = createSelector(
  selectFetchDevicesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchDevices = createSelector(
  selectDevicesRootState,
  (devices): string => devices.searchDevices
);

export const selectDevicesTableData = createSelector(
  selectSearchDevices,
  selectFetchDevicesData,
  (searchValue, devices): DeviceListModel[] => {
    const data = devices.map((device) => ({
      ...device,
      serial_number: setMaxTextLength(device.serial_number, 8),
      status: device.is_enabled
        ? DeviceRecordingStatus.Yes
        : DeviceRecordingStatus.No,
      last_reading_date: device.last_reading_date
        ? isToday(device.last_reading_date)
          ? moment(device.last_reading_date).format(HOURS)
          : moment(device.last_reading_date).format(DATE_AND_HOURS)
        : "N/A",
    }));

    if (searchValue) {
      return data.filter(
        (location) =>
          location.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          location.client?.name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          location.measurement.toString().includes(searchValue.toString()) ||
          location.device_type_id
            ?.toString()
            .includes(searchValue.toString()) ||
          location.serial_number
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          location.partner?.name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          location.last_reading_date
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          JSON.stringify(location.equipments)
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsDevicesTableStatusPending = createSelector(
  selectFetchDevicesStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);
