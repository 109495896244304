import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import { AuthState } from "./auth.store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { ProfileModel } from "../model/Auth";

export const selectSelf = (state: RootState): RootState => state;

export const selectAuthState = createSelector(
  selectSelf,
  (state: RootState): AuthState => state.auth
);

export const selectLoginState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<null> => auth.login
);

export const selectLoginStatus = createSelector(
  selectLoginState,
  (login: AsyncStateModel<null>): AsyncStatus => login.status
);

export const selectIsLoginStatusPending = createSelector(
  selectLoginStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectRegisterState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<null> => auth.register
);

export const selectRegisterStatus = createSelector(
  selectRegisterState,
  (register: AsyncStateModel<null>): AsyncStatus => register.status
);

export const selectIsRegisterStatusPending = createSelector(
  selectRegisterStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectResetPasswordState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<null> => auth.resetPassword
);

export const selectResetPasswordStatus = createSelector(
  selectResetPasswordState,
  (resetPassword: AsyncStateModel<null>): AsyncStatus => resetPassword.status
);

export const selectIsResetPasswordStatusPending = createSelector(
  selectResetPasswordStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectRecoveryPasswordState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<null> => auth.recoverPassword
);

export const selectRecoveryPasswordStatus = createSelector(
  selectRecoveryPasswordState,
  (recoveryPassword: AsyncStateModel<null>): AsyncStatus =>
    recoveryPassword.status
);

export const selectIsRecoveryPasswordStatusPending = createSelector(
  selectRecoveryPasswordStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectAuthorizationState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<boolean> => auth.authorization
);

export const selectAuthorizationData = createSelector(
  selectAuthorizationState,
  (authorization: AsyncStateModel<boolean>): boolean =>
    authorization.data || false
);

export const selectAuthorizationStatus = createSelector(
  selectAuthorizationState,
  (data: AsyncStateModel<boolean>): AsyncStatus => data.status
);

export const selectIsAuthorizationVoid = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Void
);

export const selectIsAuthorizationPending = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectIsAuthorizationFulfilled = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Fulfilled
);

export const selectIsAuthorizationRejected = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Rejected
);

export const selectLogoutState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<null> => auth.logout
);

export const selectLogoutStatus = createSelector(
  selectLogoutState,
  (logout: AsyncStateModel<null>): AsyncStatus => logout.status
);

export const selectIsLogoutStatusFulfilled = createSelector(
  selectLogoutStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Fulfilled
);

export const selectProfileState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<ProfileModel | null> => auth.profile
);

export const selectProfileData = createSelector(
  selectProfileState,
  (profile: AsyncStateModel<ProfileModel | null>): ProfileModel | null =>
    profile.data || null
);

export const selectProfileClientId = createSelector(
  selectProfileData,
  (profile: ProfileModel | null): number | null => profile?.client?.id || null
);

export const selectProfileUserId = createSelector(
  selectProfileData,
  (profile: ProfileModel | null): number | null => profile?.user?.id || null
);

export const selectProfilePhotoUrl = createSelector(
  selectProfileData,
  (profile: ProfileModel | null): string | null =>
    profile?.user?.photo_url || null
);

export const selectChangePasswordState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncStateModel<null> => auth.changePassword
);

export const selectChangePasswordStatus = createSelector(
  selectChangePasswordState,
  (changePassword: AsyncStateModel<null>): AsyncStatus => changePassword.status
);
export const selectIsChangePasswordStatusPending = createSelector(
  selectChangePasswordStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectForgotPasswordState = createSelector(
  selectAuthState,
  (auth): AsyncStateModel<null> => auth.forgotPassword
);

export const selectForgotPasswordStatus = createSelector(
  selectForgotPasswordState,
  (forgotPassword): AsyncStatus => forgotPassword.status
);

export const selectIsForgotPasswordStatusPending = createSelector(
  selectForgotPasswordStatus,
  (status): boolean => status === AsyncStatus.Pending
);
