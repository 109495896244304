import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../index";
import { ConnectedModalState } from "./connectedModal.store";
import {
  ConnectedModalModel,
  ConnectedModalParamsModel,
} from "../../model/ConnectedModal";
import { AsyncStatus } from "../../enum/AsyncStatus";

export const selectSelf = (state: RootState): RootState => state;

export const selectConnectedModalState = createSelector(
  selectSelf,
  (state): ConnectedModalState => state.connectedModal
);

export const selectConnectedModalByName = (name: string) =>
  createSelector(selectConnectedModalState, (connectedModal):
    | ConnectedModalModel
    | undefined => connectedModal.find((modal) => modal.name === name));

export const selectConnectedModalByNameParams = (name: string) =>
  createSelector(selectConnectedModalByName(name), (connectedModal):
    | ConnectedModalParamsModel
    | undefined => (connectedModal ? connectedModal.params : undefined));

export const selectConnectedModalByNameStatus = (name: string) =>
  createSelector(selectConnectedModalByName(name), (connectedModal):
    | AsyncStatus
    | undefined => (connectedModal ? connectedModal.status : undefined));

export const selectIsConnectedModalByNameStatusPending = (name: string) =>
  createSelector(
    selectConnectedModalByNameStatus(name),
    (status): boolean => !!(status && status === AsyncStatus.Pending)
  );
