import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { PartnersTableContainer } from "../../container/PartnersTableContainer";
import { PartnersSearchContainer } from "../../container/PartnersSearchContainer";
import { AddPartnerModalContainer } from "../../container/AddPartnerModalContainer";

export const PartnersView: FC = () => (
  <StyledPage title="Partners">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Partners" />
        <ConnectedShowForPermissions
          feature="partners"
          permissions={{ allOf: ["read"] }}
        >
          <PageActionsWrapper>
            <PartnersSearchContainer />
            <AddPartnerModalContainer />
          </PageActionsWrapper>
        </ConnectedShowForPermissions>
      </PageHeaderContainer>
      <ConnectedShowForPermissions
        feature="partners"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <PartnersTableContainer />
        </Box>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
