import React, { FC } from "react";
import TableSearchInput from "../../../app/component/Table/TableSearchInput/TableSearchInput";

interface ILocationsSearch {
  onSearch: (searchTerm: string) => void;
  isPending: boolean;
  filter?: string;
}

export const LocationsSearch: FC<ILocationsSearch> = ({
  onSearch,
  isPending,
  filter,
}) => (
  <TableSearchInput
    onSearch={onSearch}
    label="Search Locations"
    isPending={isPending}
    filter={filter}
  />
);
