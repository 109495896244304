import React, { FC, useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import {
  Box,
  CardContent,
  Typography,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { ChannelGraphData } from "../../api/asset.api.dto";
import {
  ASSET_TRENDS_COLORS,
  WARNING_REFERENCE_AREA,
} from "../../../app/theme/colors";
import { AlertTypes } from "../../enum/alert_types.enum";
import {
  ExportIconButton,
  LoaderOverlay,
  StyledTooltipDiv,
  StyledCard,
} from "./AssetTrends.style";
import { Export } from "../../../app/component/Icons/ExportIcon";
import { StyledDisabledInput } from "../DateTimeRangePicker/DateTimeRangePicker.style";
import { DATE_TIME_RANGE_PICKER_FORMAT } from "../../const/AssetGraphs.const";

interface IAssetSensorReadingsGraphs {
  isGraphsPending: boolean;
  graphData: ChannelGraphData[];
  formatDate: (tickItem: number | string) => string;
  formatYAxis: (tickItem: number) => string;
  assetName: string;
  startEpoch: number;
  endEpoch: number;
  selectedSensors: number[] | undefined;
}

export const AssetSensorReadingsGraphs: FC<IAssetSensorReadingsGraphs> = ({
  isGraphsPending,
  graphData,
  formatDate,
  formatYAxis,
  assetName,
  startEpoch,
  endEpoch,
  selectedSensors,
}) => {
  const numChannels = graphData.length;
  const graphsRef = useRef<Array<React.ReactNode>>([]);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number>(-1);
  let timeoutOnMouseOver: ReturnType<typeof setTimeout> | false;

  useEffect(() => {
    graphsRef.current = graphsRef.current.slice(0, numChannels);
  }, [numChannels]);

  const generateGraphExportPdf = () => {
    const element = document.getElementById("sensor-readings");

    if (element) {
      html2canvas(element, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "px");

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${assetName}SensorReadings.pdf`);
      });
    }
  };

  const generateReferenceAreas = (series: ChannelGraphData) => {
    let referenceAreas;
    if (series.alertType) {
      switch (series.alertType) {
        case AlertTypes.ALERT_TYPE_ABOVE_VALUE:
          referenceAreas = (
            <ReferenceArea
              fill={WARNING_REFERENCE_AREA}
              y1={series.warningPointMax}
              y2={series.alarmPointMax}
              fillOpacity={1}
              ifOverflow="extendDomain"
            />
          );
          break;
        case AlertTypes.ALERT_TYPE_BELOW_VALUE:
          referenceAreas = (
            <ReferenceArea
              fill={WARNING_REFERENCE_AREA}
              y1={series.alarmPointMax}
              y2={series.warningPointMax}
              fillOpacity={1}
              ifOverflow="extendDomain"
            />
          );
          break;
        case AlertTypes.ALERT_TYPE_IN_RANGE:
          referenceAreas = (
            <>
              <ReferenceArea
                fill={WARNING_REFERENCE_AREA}
                y1={series.alarmPointMax}
                y2={series.warningPointMax}
                fillOpacity={1}
                ifOverflow="extendDomain"
              />
              <ReferenceArea
                fill={WARNING_REFERENCE_AREA}
                y1={series.alarmPointMin}
                y2={series.warningPointMin}
                fillOpacity={1}
                ifOverflow="extendDomain"
              />
            </>
          );
          break;
        case AlertTypes.ALERT_TYPE_OUT_RANGE:
          referenceAreas = (
            <>
              <ReferenceArea
                fill={WARNING_REFERENCE_AREA}
                y1={series.warningPointMax}
                y2={series.alarmPointMax}
                fillOpacity={1}
                ifOverflow="extendDomain"
              />
              <ReferenceArea
                fill={WARNING_REFERENCE_AREA}
                y1={series.warningPointMin}
                y2={series.alarmPointMin}
                fillOpacity={1}
                ifOverflow="extendDomain"
              />
            </>
          );
          break;
        default:
      }
    }
    return referenceAreas;
  };

  const generateReferenceLines = (series: ChannelGraphData) => {
    let referenceLines;
    if (series.alertType) {
      switch (series.alertType) {
        case AlertTypes.ALERT_TYPE_ABOVE_VALUE:
        case AlertTypes.ALERT_TYPE_BELOW_VALUE:
          referenceLines = (
            <ReferenceLine
              stroke="black"
              strokeWidth={1}
              label={{
                position: "insideBottomRight",
                value: `Alarm: ${series.alarmPointMax}`,
                fill: "black",
                fontSize: 14,
              }}
              isFront
              y={series.alarmPointMax}
            />
          );
          break;
        case AlertTypes.ALERT_TYPE_IN_RANGE:
        case AlertTypes.ALERT_TYPE_OUT_RANGE:
          referenceLines = (
            <>
              <ReferenceLine
                stroke="black"
                strokeWidth={1}
                label={{
                  position: "insideBottomRight",
                  value: `Alarm: ${series.alarmPointMin}`,
                  fill: "black",
                  fontSize: 14,
                }}
                isFront
                y={series.alarmPointMin}
              />
              <ReferenceLine
                stroke="black"
                strokeWidth={1}
                label={{
                  position: "insideTopRight",
                  value: `Alarm: ${series.alarmPointMax}`,
                  fill: "black",
                  fontSize: 14,
                }}
                isFront
                y={series.alarmPointMax}
              />
            </>
          );
          break;
        default:
      }
    }
    return referenceLines;
  };

  const CustomTooltip = ({
    active,
    payload: tooltipPayload,
    fallback,
  }: any) => {
    if (active) {
      let payload;

      if (!tooltipPayload || tooltipPayload.length <= 0) {
        if (fallback && fallback.x && fallback.y) {
          payload = [
            {
              name: fallback.name,
              stroke: fallback.stroke,
              value: fallback.y,
              payload: {
                alertType: fallback.alertType,
                warningPointMin: fallback.warningPointMin,
                warningPointMax: fallback.warningPointMax,
                units: fallback.units,
                xdata: fallback.x,
              },
            },
          ];
        }
      } else {
        payload = [...tooltipPayload];
      }

      if (payload && payload.length) {
        const sensorData = payload[0].payload;
        let alertText;

        switch (sensorData.alertType) {
          case AlertTypes.ALERT_TYPE_ABOVE_VALUE:
            alertText = (
              <>
                {"Alarm: >"}
                <b> {sensorData.warningPointMax}</b>
              </>
            );
            break;
          case AlertTypes.ALERT_TYPE_BELOW_VALUE:
            alertText = (
              <>
                {"Alarm: <"}
                <b> {sensorData.warningPointMax}</b>
              </>
            );
            break;
          case AlertTypes.ALERT_TYPE_IN_RANGE:
            alertText = (
              <>
                {"Range: "}
                <b> {sensorData.warningPointMin} </b>
                {" < Alarm < "}
                <b> {sensorData.warningPointMax} </b>
              </>
            );
            break;
          case AlertTypes.ALERT_TYPE_OUT_RANGE:
            alertText = (
              <>
                {"Alarm: > "}
                <b> {sensorData.warningPointMax} </b> <br />
                {"Alarm: < "}
                <b> {sensorData.warningPointMin} </b>
              </>
            );
            break;
          default:
        }

        return (
          <StyledTooltipDiv
            style={{
              border: `${payload[0].stroke} solid 1px`,
            }}
          >
            <p className="label">
              <span
                style={{ color: `${payload[0].stroke}` }}
              >{`${payload[0].name}: `}</span>
              {`${payload[0].value.toFixed(2)} ${sensorData.units}`}
              <br />
              {alertText}
            </p>
            <p style={{ color: `${payload[0].stroke}` }}>
              {formatDate(sensorData.xdata)}
            </p>
          </StyledTooltipDiv>
        );
      }
    }

    return null;
  };

  const handleOnMouseEnter = ({
    activeTooltipIndex: index,
  }: {
    activeTooltipIndex: number;
  }) => {
    setActiveTooltipIndex(index);
  };

  const handleOnMouseMove = ({
    activeTooltipIndex: index,
  }: {
    activeTooltipIndex: number;
  }) => {
    if (timeoutOnMouseOver) {
      clearTimeout(timeoutOnMouseOver);
    }
    timeoutOnMouseOver = setTimeout(() => {
      setActiveTooltipIndex(index);
      timeoutOnMouseOver = false;
    }, 100);
  };

  const handleOnMouseLeave = () => {
    setActiveTooltipIndex(-1);
  };

  return (
    <StyledCard>
      {isGraphsPending && (
        <LoaderOverlay>
          <CircularProgress size={50} />
        </LoaderOverlay>
      )}
      <CardContent id="sensor-readings">
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <Box alignSelf="center" flexGrow={1}>
            <Typography variant="h3">Sensor Readings</Typography>
          </Box>
          <Box alignSelf="center" mr={1}>
            <ExportIconButton
              data-html2canvas-ignore="true"
              startIcon={<Export />}
              pending={isGraphsPending}
              onClick={generateGraphExportPdf}
            />
          </Box>
          <Box alignSelf="center">
            <StyledDisabledInput>
              {moment(startEpoch * 1000).format(DATE_TIME_RANGE_PICKER_FORMAT)}{" "}
              - {moment(endEpoch * 1000).format(DATE_TIME_RANGE_PICKER_FORMAT)}
            </StyledDisabledInput>
          </Box>
        </Box>
        {graphData.map((series: ChannelGraphData) => {
          if (
            series.data.length > 0 &&
            selectedSensors?.includes(series.index)
          ) {
            return (
              <Box mt={2} key={series.seriesId}>
                {series.index !== 1 ? (
                  <Box mt={2}>
                    <Divider />
                  </Box>
                ) : null}
                <Box m={3}>
                  <Typography variant="h3" display="inline">
                    Ch.{series.index}:
                    {series.title === "" ? "No Name" : series.title}
                  </Typography>
                  <Typography variant="h6" display="inline">
                    {` • ${series.type}, ${series.units}`}
                  </Typography>
                </Box>
                <ResponsiveContainer
                  key={series.seriesId}
                  width="100%"
                  height={150}
                >
                  <LineChart
                    syncId="assetSensorReadingsGraphs"
                    data={series.chartData}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseMove={handleOnMouseMove}
                    onMouseLeave={handleOnMouseLeave}
                  >
                    {generateReferenceAreas(series)}
                    {generateReferenceLines(series)}
                    <XAxis
                      dataKey="xdata"
                      allowDuplicatedCategory={false}
                      tickFormatter={formatDate}
                    />
                    <YAxis
                      style={{ fontSize: "0.9rem" }}
                      tickMargin={10}
                      tickLine={false}
                      axisLine={false}
                      tickFormatter={formatYAxis}
                      domain={[
                        (dataMin: number) =>
                          dataMin < 0 ? dataMin * 1.2 : dataMin - dataMin * 0.2,
                        (dataMax: number) =>
                          dataMax < 0 ? dataMax - dataMax * 0.2 : dataMax * 1.2,
                      ]}
                    />
                    <Tooltip
                      active
                      wrapperStyle={{ visibility: "visible" }}
                      content={
                        <CustomTooltip
                          fallback={{
                            name: series.title,
                            stroke: ASSET_TRENDS_COLORS[series.index],
                            alertType: series.alertType,
                            warningPointMin: series.warningPointMin,
                            warningPointMax: series.warningPointMax,
                            units: series.units,
                            x:
                              activeTooltipIndex >= 0
                                ? series.xdata[activeTooltipIndex]
                                : null,
                            y:
                              activeTooltipIndex >= 0
                                ? series.data[activeTooltipIndex]
                                : null,
                          }}
                        />
                      }
                    />
                    <Line
                      dataKey="data"
                      data={series.chartData}
                      name={series.title}
                      key={series.seriesId}
                      stroke={ASSET_TRENDS_COLORS[series.index]}
                      strokeWidth={4}
                      type="monotone"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            );
          }
          return null;
        })}
      </CardContent>
    </StyledCard>
  );
};
