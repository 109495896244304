import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../app/store";
import { RegisterDataModel } from "../../model/Auth";
import { RegisterForm } from "../../component/RegisterForm/RegisterForm";
import { register } from "../../store/auth.action";
import { selectIsRegisterStatusPending } from "../../store/auth.selector";

export const RegisterFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isRegisterPending = useSelector(selectIsRegisterStatusPending);

  const onSubmit = (registerData: RegisterDataModel) =>
    dispatch(register(registerData));

  return <RegisterForm onSubmit={onSubmit} pending={isRegisterPending} />;
};
