import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #e3e3e9;
  background-color: #ffffff;
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const Note = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #707070;
  margin-top: 5px;
`;

export const Circle = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e3e3e9;
  margin: 0 ${({ theme }) => `${theme.spacing(1)}px`};
`;
