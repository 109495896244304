import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TeamDto } from "../api/teams.api.dto";

export interface TeamsState {
  fetchTeams: AsyncStateModel<TeamDto[]>;
  deleteTeam: AsyncStateModel<null>;
  editTeam: AsyncStateModel<null>;
  createTeam: AsyncStateModel<null>;
  searchTeams: string;
}

export const teamsState: TeamsState = {
  fetchTeams: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteTeam: {
    status: AsyncStatus.Void,
    data: null,
  },
  editTeam: {
    status: AsyncStatus.Void,
    data: null,
  },
  createTeam: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchTeams: "",
};
