import React, { FC, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useFormik } from "formik";
import { MoreVert, Send } from "@material-ui/icons";
import moment from "moment";
import { StyledTextField } from "./TaskModalForm.style";
import { Comment } from "../../api/workorders.api.dto";
import { Button } from "../../../app/component/Button/Button";
import { UserDto } from "../../../users/api/users.api.dto";
import { StyledAvatar } from "../SubTaskForm/SubTaskForm.style";
import { ConnectedHideForRoleTask } from "../../../app/component/UserManagement/ConnectedHideForRoleTask";
import { UserRole } from "../../../app/enum/UserRole";

export interface ITaskModalCommentsForm {
  comments?: Comment[];
  user: UserDto;
  onAdd: (value: Comment) => Promise<any>;
  onDelete: (value: Comment) => Promise<any>;
}

export const TaskModalCommentsForm: FC<ITaskModalCommentsForm> = ({
  comments,
  onAdd,
  onDelete,
  user,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeComment, setActiveComment] = useState<null | Comment>(null);

  const MenuClose = () => setMenuAnchorEl(null);
  const menuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (comments) {
      const containerId = event.currentTarget.getAttribute("data-id") || null;
      const comment = comments.filter(function (com) {
        return `${com.id}` === containerId;
      });
      if (comment[0] !== undefined) {
        setActiveComment(comment[0]);
      }
    }
    setMenuAnchorEl(event.currentTarget);
  };

  const formik = useFormik<Comment>({
    enableReinitialize: true,
    initialValues: {
      text: "",
    },
    validate: (values) => {
      const errors: Partial<Record<keyof Comment, string>> = {};
      if (values.text.length > 1500) {
        errors.text = "Comment Should be less than 1500";
      }
      return errors;
    },
    onSubmit: (values: Comment) =>
      onAdd({ text: values.text }).then(() => {
        formik.resetForm();
      }),
  });

  return (
    <div className="my-2">
      <Box mb={1} display="inline-block" component="small" fontWeight="600">
        Comments
      </Box>

      <Grid container spacing={0} direction="row">
        <Grid item md={1}>
          <StyledAvatar
            className="me-2"
            src={!user.photo_url.includes("gravatar") ? user.photo_url : ""}
          >
            {user.first_name[0]}
            {user.last_name[0]}
          </StyledAvatar>
        </Grid>
        <Grid item md={10}>
          <StyledTextField
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            className="mb-1"
            fullWidth
            variant="outlined"
            placeholder="Add Comment"
            size="small"
            multiline
            rowsMax={4}
            error={formik.touched.text && !!formik.errors.text}
            helperText={formik.touched.text && formik.errors.text}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            style={{ minWidth: "auto" }}
            className="p-2 ms-2"
            disabled={formik.values.text.length < 1}
            onClick={() => formik.submitForm()}
            type="button"
            variant="contained"
            color="primary"
          >
            <Send />
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={0} direction="row">
        <Grid item md={1} />
        <Grid item md={11}>
          {comments?.map((comment) => {
            return (
              <div className="border-bottom py-3" key={comment.id}>
                <Grid
                  alignItems="center"
                  className="mb-2"
                  container
                  spacing={0}
                  direction="row"
                >
                  <Grid item md={1}>
                    <Avatar
                      className="me-2"
                      src={
                        !comment.created_by?.photo_url.includes("gravatar")
                          ? comment.created_by?.photo_url
                          : ""
                      }
                    >
                      {comment.created_by?.first_name.charAt(0)}
                      {comment.created_by?.last_name.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item md className="px-2">
                    <Box component="span" mr={1}>
                      {comment.created_by?.full_name ||
                        comment.created_by?.first_name}
                    </Box>
                    <span>
                      {comment.date ||
                        moment(comment.created_at).format("DD.MM.YY hh:mm A")}
                    </span>
                  </Grid>
                  <ConnectedHideForRoleTask
                    roles={[UserRole.Member]}
                    owner={comment}
                  >
                    <Grid item className="text-end" md={1}>
                      <div>
                        <IconButton
                          data-id={comment.id}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={menuOpen}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchorEl}
                          keepMounted
                          open={!!menuAnchorEl}
                          onClose={MenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              if (onDelete && activeComment) {
                                onDelete(activeComment);
                              }
                              MenuClose();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                  </ConnectedHideForRoleTask>
                </Grid>
                <div>{comment.text}</div>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
