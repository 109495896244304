import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { forgotPassword } from "../../store/auth.action";
import { Password } from "../../component/Password/Password";
import {
  selectIsForgotPasswordStatusPending,
  selectProfileData,
} from "../../store/auth.selector";

export const PasswordContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isForgotPasswordPending = useSelector(
    selectIsForgotPasswordStatusPending
  );
  const profileData = useSelector(selectProfileData);

  const onReset = () => dispatch(forgotPassword());

  return (
    <Password
      onReset={onReset}
      lastReset={profileData?.user.last_reset_password}
      isPending={isForgotPasswordPending}
    />
  );
};
