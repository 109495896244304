import { MaintenanceDto } from "./maintenance.api.dto";
import { authApiClient } from "../../app/api/authApi.client";

export const fetchMaintenances = async (): Promise<MaintenanceDto[]> => {
  try {
    const response = await authApiClient.get("/maintenance");
    return response.data.tasks;
  } catch (error) {
    throw error;
  }
};

export const createMaintenance = async (
  maintenance: MaintenanceDto
): Promise<MaintenanceDto> => {
  try {
    const response = await authApiClient.post("/maintenance", maintenance);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMaintenance = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.post("/maintenance/delete", {
      id,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const editMaintenance = async (
  maintenance: MaintenanceDto
): Promise<MaintenanceDto> => {
  try {
    const response = await authApiClient.put(
      `/maintenances/${maintenance.id}`,
      maintenance
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
