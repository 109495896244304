import { authApiClient } from "../../app/api/authApi.client";
import {
  TaskDto,
  TasksList,
  UpdateTaskStatusDto,
  Comment,
  TaskCommentDto,
} from "./workorders.api.dto";
import { mapTasksDtoToTasksList } from "./workorders.api.mapper";

export const fetchTasks = async (
  clientId?: number,
  assetId?: number
): Promise<{ tasks: TaskDto[]; tasksList: TasksList }> => {
  try {
    const response = await authApiClient.get(
      `/tasks?client_id=${clientId || ""}&asset_id=${assetId || ""}`
    );
    return {
      tasks: response.data.tasks,
      tasksList: mapTasksDtoToTasksList(response.data.tasks),
    };
  } catch (error) {
    throw error;
  }
};

export async function fetchTask(
  id: number
): Promise<{ success: boolean; task: TaskDto }> {
  try {
    const { data } = await authApiClient.get(`/tasks/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export const updateTaskStatus = async (
  updateTaskStatusDto: UpdateTaskStatusDto
): Promise<void> => {
  try {
    const { status, taskId } = updateTaskStatusDto;
    await authApiClient.put(`/tasks/status/${taskId}`, {
      status,
    });
  } catch (error) {
    throw error;
  }
};

export const createTask = async (formData: FormData): Promise<TaskDto> => {
  try {
    const {
      data: { task },
    } = await authApiClient.post("/tasks", formData);
    return { ...task, id: task.id.toString() };
  } catch (error) {
    throw error;
  }
};

export const editTask = async (formData: FormData): Promise<TaskDto> => {
  try {
    // Workaround to make the Laravel API handle multipart-formdata with the PUT method
    formData.append("_method", "put");
    const {
      data: { task },
    } = await authApiClient.post(`/tasks/${formData.get("id")}`, formData);
    return { ...task, id: task.id.toString() };
  } catch (error) {
    throw error;
  }
};

export const deleteTask = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/tasks/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createComment = async ({
  task,
  newComment,
}: TaskCommentDto): Promise<Comment> => {
  try {
    const {
      data: { comment },
    } = await authApiClient.post(`/tasks/comment/${task.id}`, {
      text: newComment.text,
    });
    return comment;
  } catch (error) {
    throw error;
  }
};

export const deleteComment = async (comment: Comment) => {
  try {
    await authApiClient.delete(`/tasks/comment/${comment.id}`);
  } catch (error) {
    throw error;
  }
};
