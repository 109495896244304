import styled from "styled-components";
import { Card } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

export const StyledCard = styled(Card)`
  height: 100%;
  min-height: 250px;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  font-size: 20px;
`;

export const StyledBadHealth = styled.span`
  border-radius: 4px;
  background-color: ${({ theme }) => `${theme.palette.health.bad}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
  width: 29px;
  display: inline-block;
`;

export const StyledFairHealth = styled.span`
  border-radius: 4px;
  background-color: ${({ theme }) => `${theme.palette.health.fair}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
  width: 29px;
  display: inline-block;
`;

export const StyledGoodHealth = styled.span`
  border-radius: 4px;
  background-color: ${({ theme }) => `${theme.palette.health.good}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
  width: 29px;
  display: inline-block;
`;
