import { authApiClient } from "../../app/api/authApi.client";
import {
  CreateEditDeviceDto,
  DeviceListDto,
  DeviceTypeDto,
} from "./devices.api.dto";

export const fetchDevices = async (): Promise<DeviceListDto[]> => {
  try {
    const response = await authApiClient.get("/devices");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDeviceTypes = async (): Promise<DeviceTypeDto[]> => {
  try {
    const response = await authApiClient.get("/device_type/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addDevice = async (
  deviceDto: CreateEditDeviceDto
): Promise<string> => {
  try {
    const response = await authApiClient.post("/devices", deviceDto);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editDevice = async (
  deviceDto: CreateEditDeviceDto,
  deviceId?: number
): Promise<string> => {
  try {
    const response = await authApiClient.put(`/devices/${deviceId}`, deviceDto);
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const deleteDevice = async (deviceId: number): Promise<string> => {
  try {
    const response = await authApiClient.delete(`/devices/${deviceId}`);
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const clearDevice = async (
  deviceId: number | undefined
): Promise<string> => {
  try {
    const response = await authApiClient.post(`/devices/${deviceId}/clear`);
    return response.data.success;
  } catch (error) {
    throw error;
  }
};
