import { createSlice } from "@reduxjs/toolkit";
import { LocationState, locationState } from "./locations.store";
import {
  fetchLocations,
  deleteLocation,
  editLocation,
  createLocation,
} from "./locations.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "location",
  initialState: locationState,
  reducers: {
    searchLocations: (
      state: LocationState,
      { payload }: { payload: string }
    ) => ({
      ...state,
      searchLocations: payload,
    }),
    clearLocationsState: (state: LocationState) => ({
      ...state,
      locations: locationState.locations,
    }),
    clearSearchLocationsState: (state: LocationState) => ({
      ...state,
      searchLocations: locationState.searchLocations,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.pending, (state) => ({
      ...state,
      locations: {
        ...state.locations,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchLocations.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          locations: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchLocations.rejected, (state) => ({
      ...state,
      locations: {
        ...state.locations,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(deleteLocation.pending, (state) => ({
      ...state,
      deleteLocation: {
        ...state.deleteLocation,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteLocation.fulfilled, (state) => ({
      ...state,
      deleteLocation: {
        ...state.deleteLocation,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteLocation.rejected, (state) => ({
      ...state,
      deleteLocation: {
        ...state.deleteLocation,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editLocation.pending, (state) => ({
      ...state,
      editLocation: {
        ...state.editLocation,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editLocation.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          editLocation: {
            ...state.editLocation,
            status: AsyncStatus.Fulfilled,
          },
        };
      }

      return state;
    });
    builder.addCase(editLocation.rejected, (state) => ({
      ...state,
      editLocation: {
        ...state.editLocation,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createLocation.pending, (state) => ({
      ...state,
      createLocation: {
        ...state.createLocation,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createLocation.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          createLocation: {
            ...state.createLocation,
            status: AsyncStatus.Fulfilled,
          },
        };
      }

      return state;
    });
    builder.addCase(createLocation.rejected, (state) => ({
      ...state,
      createLocation: {
        ...state.createLocation,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchLocations,
  clearLocationsState,
  clearSearchLocationsState,
} = slice.actions;
export default slice.reducer;
