import { Typography, MenuItem, Card } from "@material-ui/core";
import styled from "styled-components";

export const StyledTitle = styled(Typography)`
  font-weight: 400;
  flex-grow: 1;
`;

export const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid #e5e5e5;
`;
export const StyledCard = styled(Card)`
  border-radius: 0;
`;
