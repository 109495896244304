import React, { FC } from "react";

import { useParams } from "react-router-dom";
import { AssetTabs } from "../component/AssetTabs/AssetTabs";

export const AssetTabsContainer: FC = () => {
  const { assetId }: { assetId: string } = useParams();

  return <AssetTabs assetId={assetId} />;
};
