import React, { FC, MouseEvent, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import { Box, IconButton, Link, TextField } from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { resetPasswordFormValidationSchema } from "./ResetPasswordForm.validation";
import { ResetPasswordDataModel } from "../../model/Auth";
import {
  StyledButton,
  StyledCard,
  StyledLogo,
  StyledTypography,
} from "./ResetPasswordForm.style";
import { LOGIN } from "../../../app/const/routes";

interface IResetPasswordForm {
  onSubmit: (formValues: ResetPasswordDataModel) => void;
  pending: boolean;
}

export const ResetPasswordForm: FC<IResetPasswordForm> = ({
  onSubmit,
  pending,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleClickShowRepeatPassword = () =>
    setIsRepeatPasswordVisible(!isRepeatPasswordVisible);

  const handleMouseDownPassword = (event: MouseEvent) => event.preventDefault();

  return (
    <StyledCard>
      <Formik
        initialValues={{
          email: "",
          password: "",
          repeatPassword: "",
        }}
        validationSchema={resetPasswordFormValidationSchema}
        onSubmit={({ email, password, repeatPassword }) =>
          onSubmit({
            email,
            password,
            password_confirmation: repeatPassword,
          })
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <StyledLogo />
            <TextField
              disabled={pending}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isPasswordVisible ? "text" : "password"}
              value={values.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={pending}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.repeatPassword && errors.repeatPassword)}
              fullWidth
              helperText={touched.repeatPassword && errors.repeatPassword}
              label="Repeat password"
              margin="normal"
              name="repeatPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isRepeatPasswordVisible ? "text" : "password"}
              value={values.repeatPassword}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle repeat password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={pending}
                  >
                    {isRepeatPasswordVisible ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledTypography color="textSecondary">
                <Link component={RouterLink} to={LOGIN}>
                  BACK TO LOGIN
                </Link>
              </StyledTypography>
              <StyledButton
                pending={pending}
                disabled={pending}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                SET PASSWORD
              </StyledButton>
            </Box>
          </form>
        )}
      </Formik>
    </StyledCard>
  );
};
