import styled from "styled-components";
import { Typography, Box } from "@material-ui/core";

export const ImageWrapper = styled.div`
  justify-content: center;

  svg {
    width: 125px;
  }
`;

export const StyledLabel = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
`;
export const StyledDetailsBox = styled(Box)`
  background-color: #f1f3f4;
  width: 90%;
`;
export const StyledContainer = styled(Box)`
  background-color: #fff;
`;
