import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC, useState } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  StyledCloseIcon,
  StyledDialogTitleContainer,
  StyledIconButton,
  StyledEditIcon,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { UserModalForm } from "../UserModalForm/UserModalForm";
import { UserModel, CreateEditUserModel } from "../../model/users.model";
import { PartnerDto } from "../../../partners/api/partners.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { RoleDto } from "../../../roles/api/roles.api.dto";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";

export interface IUserModal {
  name: string;
  onClose: () => void;
  onSubmit: (user: CreateEditUserModel, id?: number) => void;
  user?: UserModel;
  partners: PartnerDto[];
  clients: ClientDto[];
  teams: TeamDto[];
  roles: RoleDto[];
  onDelete?: (id: number) => void;
  isPending: boolean;
  clientId?: number | null;
}

export const UserModal: FC<IUserModal> = ({
  name,
  isPending,
  onClose,
  onSubmit,
  user,
  partners,
  clients,
  teams,
  roles,
  onDelete,
  clientId,
}) => {
  const [editView, setEditView] = useState(false);

  const handleOnDelete = () => {
    if (onDelete && user) {
      onDelete(user.id);
    }
  };

  const getModalHeader = () => {
    if (user && editView) {
      return <Typography variant="h2">Edit User</Typography>;
    }
    if (user && !editView) {
      return <Typography variant="h2">View User</Typography>;
    }
    return <Typography variant="h2">Add User</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid container item xs={4} justify="flex-start">
        {editView && (
          <ConnectedShowForPermissions
            feature="users"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView ? (
          <>
            <Box display="inline" mr={1}>
              <Button
                color="primary"
                onClick={() => {
                  onClose();
                  setEditView(false);
                }}
                disabled={pending}
              >
                Cancel
              </Button>
            </Box>
            <Box display="inline">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={pending}
                pending={pending}
              >
                Save
              </Button>
            </Box>
          </>
        ) : (
          <Box display="inline">
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button onClick={onClose} disabled={pending} color="primary">
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add User
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (user) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        {!editView && user && (
          <ConnectedShowForPermissions
            feature="users"
            permissions={{ allOf: ["update"] }}
          >
            <StyledIconButton
              aria-label="edit"
              onClick={() => setEditView(true)}
            >
              <StyledEditIcon />
            </StyledIconButton>
          </ConnectedShowForPermissions>
        )}
        <StyledIconButton
          aria-label="close"
          onClick={() => {
            onClose();
            setEditView(false);
          }}
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = (pending: boolean) => {
    return (
      <UserModalForm
        user={user}
        partners={partners}
        clients={clients}
        teams={teams}
        roles={roles}
        onSubmit={onSubmit}
        displayActionButtons={() => displayActionButtons(pending)}
        isPending={isPending}
        editView={editView}
        clientId={clientId}
      />
    );
  };

  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
    />
  );
};
