import React, { FC } from "react";
import { Typography, CardContent, Box } from "@material-ui/core";
import moment from "moment";
import { TaskDto, TaskStatus } from "../../api/workorders.api.dto";
import { ReactComponent as TaskIcon } from "../../../../assets/icons/wo-task.svg";

import {
  StyledAvatar,
  StyledInfo,
  StyledChip,
  StyledManualIcon,
} from "./TaskCardContent.style";
import { HEADER_DETAILS } from "../TaskCardHeader/TaskCardHeader";
import { PriorityIcon } from "../PriorityIcon/PriorityIcon";
import { TASK_DATE } from "../../../app/const/date-format";

interface ITaskCardContent {
  task: TaskDto;
}

const isStartDateOverdue = (task: TaskDto) =>
  task.status === TaskStatus.Open
    ? moment(task.start_date).isBefore(moment())
    : false;

const isDueDateOverdue = (task: TaskDto) =>
  task.status === TaskStatus.Open || task.status === TaskStatus.InProgress
    ? moment(task.finish_date).isBefore(moment())
    : false;

export const TaskCardContent: FC<ITaskCardContent> = ({ task }) => {
  return (
    <CardContent>
      <Box
        display="flex"
        alignItems="space-around"
        justifyContent="space-between"
      >
        <Typography variant="h5">{task.name}</Typography>
        <StyledInfo>{task.work_order_number}</StyledInfo>
      </Box>

      <StyledInfo>
        Asset: {task.asset[0] ? task.asset[0]?.name : "None"}
      </StyledInfo>
      {task.status !== TaskStatus.Done ? (
        <StyledInfo color={isStartDateOverdue(task) ? "error" : "initial"}>
          Start Date:{" "}
          {task.start_date
            ? `${moment(task.start_date).format(TASK_DATE)}`
            : "None"}
        </StyledInfo>
      ) : null}
      {task.status !== TaskStatus.Done ? (
        <StyledInfo color={isDueDateOverdue(task) ? "error" : "initial"}>
          Due Date:{" "}
          {task.finish_date && moment(task.finish_date).isValid()
            ? `${moment(task.finish_date).format(TASK_DATE)}`
            : "None"}
        </StyledInfo>
      ) : null}
      {task.status === TaskStatus.Done ? (
        <StyledInfo>
          Closed Date:{" "}
          {task.closed_date
            ? `${moment(task.closed_date).format(TASK_DATE)}`
            : moment().format(TASK_DATE)}
        </StyledInfo>
      ) : null}
      <Box
        display="flex"
        alignItems="space-around"
        justifyContent="space-between"
      >
        <Box>
          <StyledChip icon={<StyledManualIcon />} label="Manual" />
          <StyledChip
            icon={<TaskIcon />}
            label={HEADER_DETAILS[task.status].name}
          />
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
          <PriorityIcon priority={task.priority} />
          <StyledAvatar
            src={
              !task.assigned_user?.photo_url.includes("gravatar")
                ? task.assigned_user?.photo_url
                : ""
            }
          >
            {task.assigned_user?.first_name?.charAt(0)}
            {task.assigned_user?.last_name?.charAt(0)}
          </StyledAvatar>
        </Box>
      </Box>
    </CardContent>
  );
};
