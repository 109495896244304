import styled from "styled-components";
import { Card } from "@material-ui/core";
import { CenteredCell } from "../../../app/component/Table/Table.style";

export const StyledCard = styled(Card)`
  height: 345px;
  min-height: 330px;
`;
export const StyledCenteredCell = styled(CenteredCell)`
  color: ${({ theme }) => `${theme.palette.health.bad}`}; ;
`;

export const StyledBadHealth = styled.span`
  background-color: ${({ theme }) => `${theme.palette.health.bad}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
`;

export const StyledFairHealth = styled.span`
  background-color: ${({ theme }) => `${theme.palette.health.fair}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
`;

export const StyledGoodHealth = styled.span`
  background-color: ${({ theme }) => `${theme.palette.health.good}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
`;

export const StyledTooltipWrapper = styled.span`
  vertical-align: bottom;
`;
