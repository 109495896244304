import styled from "styled-components";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { TextField } from "@material-ui/core";

export const StyledAutocompleteWrapper = styled.span`
  min-height: 45px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiInputBase-root {
    min-height: 40px;
    width: 310px;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
  .MuiInputBase-root {
    padding: 0 5px !important;
    padding-right: 40px !important;
  }
  input {
    font-size: small;
    padding: 10px;
  }
  fieldset {
    height: 40px !important;
    top: 0px !important;
  }
`;

export const StyledFilterBox = styled(TextField)`
  padding: 10px;
  font-size: 12px;
  div.MuiChip-root {
    padding: 7px;
    margin: 4px;
  }
  fieldset {
    height: auto !important;
    5px 25px 5px 5px!important;
  }
`;
export const StyledMenu = styled(Menu)`
  margin-top: 4px;
  .MuiList-padding {
    padding: 0;
  }
  * {
    margin-right: 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  :hover:active {
    background-color: transparent;
  }
  padding: 0;
`;

export const FilterButton = styled(Button)`
  padding: 7px 16px;
  img {
    margin-right: 8px;
  }
`;
