import React, { FC, useState, useEffect } from "react";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Divider } from "../../../app/component/Divider/Divider";
import {
  assetSensorModalFormValidation,
  NAME_CHAR_LIMIT,
  SENSOR_TYPE_DESCRIPTION_CHAR_LIMIT,
  DESCRIPTION_CHAR_LIMIT,
} from "./assetSensorModalForm.validation";
import {
  StyledModalContent,
  StyledSwitch,
} from "../../../app/component/Modal/Modal.style";
import { SensorDto } from "../../api/asset.api.dto";
import { CreateEditSensorModel } from "../../model/asset.model";
import { SensorTypeDto } from "../../../sensorTypes/api/sensorTypes.api.dto";
import { TooltipIcon } from "../../../app/component/TooltipIcon/TooltipIcon";

export interface IAssetSensorModalForm {
  assetId: number;
  sensor?: SensorDto;
  sensorTypes: SensorTypeDto[];
  onSubmit: (values: CreateEditSensorModel, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  selectedChannel?: number;
  selectedChannelDevice?: number;
  editView: boolean;
}

export const AssetSensorModalForm: FC<IAssetSensorModalForm> = ({
  assetId,
  sensor,
  sensorTypes,
  onSubmit,
  displayActionButtons,
  isPending,
  selectedChannel,
  selectedChannelDevice,
  editView,
}) => {
  const getSensorTypeById = (id: number) =>
    sensorTypes.find((sensorType) => sensorType.id === id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      friendly_name: sensor?.friendly_name || "",
      sensor_type_id: sensor?.sensor_type_id,
      source_voltage_ac: sensor?.source_voltage,
      source_voltage_dc: sensor?.source_voltage,
      sensor_type_description: sensor?.sensor_type_description || "",
      description: sensor?.description || "",
      calibration_value: sensor?.calibration_value || 0,
      warning_is_enabled: Boolean(sensor?.warning?.enabled) || false,
      alert_type: sensor?.warning?.alert_type,
      warning_point_min: !Number.isNaN(sensor?.warning?.min)
        ? Number(sensor?.warning?.min)
        : null,
      warning_point_max: !Number.isNaN(sensor?.warning?.max)
        ? Number(sensor?.warning?.max)
        : null,
      health_point_min: !Number.isNaN(sensor?.health_point?.min)
        ? Number(sensor?.health_point?.min)
        : null,
      health_point_max: !Number.isNaN(sensor?.health_point?.max)
        ? Number(sensor?.health_point?.max)
        : null,
      status_is_enabled: Boolean(sensor?.status?.enabled) || false,
      status_alert_type: sensor?.status?.alert_type,
      status_point_min: !Number.isNaN(sensor?.status?.min)
        ? Number(sensor?.status?.min)
        : null,
      status_point_max: !Number.isNaN(sensor?.status?.max)
        ? Number(sensor?.status?.max)
        : null,
      usage_is_enabled: Boolean(sensor?.usage_is_enabled) || false,
      usage_measurement: sensor?.usage_measurement,
      is_enabled: Boolean(sensor?.is_enabled) || false,
      equipment_id: assetId,
      device_id: sensor?.device_id || selectedChannelDevice || 0,
      channel_index: sensor?.channel_index || selectedChannel || 0,
    },
    validate: async (values) => {
      const sensorType = getSensorTypeById(Number(values.sensor_type_id));
      try {
        await validateYupSchema(
          values,
          assetSensorModalFormValidation,
          undefined,
          {
            sensor_type_name: sensorType?.name || "",
          }
        );
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
    onSubmit: (values: CreateEditSensorModel) => onSubmit(values, sensor?.id),
  });

  const selectedSensorType = getSensorTypeById(
    Number(formik.values.sensor_type_id)
  );
  const alertTypeIsRange =
    formik.values.alert_type === 3 || formik.values.alert_type === 4;
  const statusAlertTypeIsRange =
    formik.values.status_alert_type === 3 ||
    formik.values.status_alert_type === 4;
  const acVoltageFieldIsVisible = selectedSensorType?.name === "Amperage AC";
  const dcVoltageFieldIsVisible = selectedSensorType?.name === "Amperage DC";

  const getSensorTypeOptions = () => {
    return [...sensorTypes]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((sensorType) => sensorType.id);
  };
  const getSensorTypeOptionLabel = (option: number) => {
    const match = sensorTypes.find((sensorType) => {
      return sensorType.id === option;
    });
    return match
      ? `${match.name} (${match.range_min} ${match.unit_name} - ${match.range_max} ${match.unit_name})`
      : "";
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Sensor Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="friendly_name"
              name="friendly_name"
              label="Sensor Name"
              variant="filled"
              fullWidth
              value={formik.values.friendly_name}
              onChange={formik.handleChange}
              error={
                formik.touched.friendly_name &&
                Boolean(formik.errors.friendly_name)
              }
              helperText={
                formik.touched.friendly_name && formik.errors.friendly_name
              }
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && sensor !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={getSensorTypeOptions()}
              id="sensor_type_id"
              getOptionLabel={getSensorTypeOptionLabel}
              value={formik.values.sensor_type_id || null}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValue: number | null
              ) => {
                formik.setFieldValue("sensor_type_id", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="sensor_type_id"
                  label="Sensor"
                  variant="filled"
                  fullWidth
                  error={
                    formik.touched.sensor_type_id &&
                    Boolean(formik.errors.sensor_type_id)
                  }
                  helperText={
                    formik.touched.sensor_type_id &&
                    formik.errors.sensor_type_id
                  }
                  required
                />
              )}
              disabled={isPending || (!editView && sensor !== undefined)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="manufacturer"
              label="Manufacturer"
              variant="filled"
              fullWidth
              value={selectedSensorType?.manufacturer || ""}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="part_number"
              label="Part Number"
              variant="filled"
              fullWidth
              value={selectedSensorType?.part_number || ""}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="sensor_type_description"
              label="Sensor Type"
              variant="filled"
              fullWidth
              value={selectedSensorType?.name || ""}
              inputProps={{ maxLength: SENSOR_TYPE_DESCRIPTION_CHAR_LIMIT }}
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              id="range_min"
              label="Sensor Min."
              variant="filled"
              fullWidth
              value={selectedSensorType?.range_min || ""}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              id="range_max"
              label="Sensor Max."
              variant="filled"
              fullWidth
              value={selectedSensorType?.range_max || ""}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="unit_type"
              label="Unit Type"
              variant="filled"
              fullWidth
              value={selectedSensorType?.unit_name || ""}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={acVoltageFieldIsVisible || dcVoltageFieldIsVisible ? 6 : 12}
          >
            <TextField
              id="settling_time"
              label="Settling Time"
              variant="filled"
              fullWidth
              value={selectedSensorType?.settling_time || ""}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Box mt={2}>
                    <InputAdornment position="end">seconds</InputAdornment>
                  </Box>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ min: 0 }}
              disabled
            />
          </Grid>
          {acVoltageFieldIsVisible && (
            <Grid item xs={6}>
              <FormControl
                variant="filled"
                fullWidth
                error={
                  formik.touched.source_voltage_ac &&
                  Boolean(formik.errors.source_voltage_ac)
                }
                required
                disabled={isPending || (!editView && sensor !== undefined)}
              >
                <InputLabel id="source_voltage_ac-label">
                  Source Voltage (AC)
                </InputLabel>
                <Select
                  id="source_voltage_ac"
                  labelId="source_voltage_ac-label"
                  name="source_voltage_ac"
                  value={formik.values.source_voltage_ac}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string;
                      value: unknown;
                    }>
                  ) => {
                    formik.setFieldValue(
                      "source_voltage_ac",
                      event.target.value
                    );
                  }}
                  required
                  disabled={isPending || (!editView && sensor !== undefined)}
                >
                  <MenuItem value={120}>120 VAC</MenuItem>
                  <MenuItem value={208}>208 VAC</MenuItem>
                  <MenuItem value={360}>360 VAC</MenuItem>
                  <MenuItem value={480}>480 VAC</MenuItem>
                </Select>
                {formik.touched.source_voltage_ac &&
                  Boolean(formik.errors.source_voltage_ac) && (
                    <FormHelperText>
                      {formik.errors.source_voltage_ac}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          )}
          {dcVoltageFieldIsVisible && (
            <Grid item xs={6}>
              <TextField
                id="source_voltage_dc"
                name="source_voltage_dc"
                label="Source Voltage (DC)"
                type="number"
                variant="filled"
                fullWidth
                value={formik.values.source_voltage_dc}
                onChange={formik.handleChange}
                error={
                  formik.touched.source_voltage_dc &&
                  Boolean(formik.errors.source_voltage_dc)
                }
                helperText={
                  formik.touched.source_voltage_dc &&
                  formik.errors.source_voltage_dc
                }
                disabled={isPending || (!editView && sensor !== undefined)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Notes"
              multiline
              rows={4}
              rowsMax={10}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
              disabled={isPending || (!editView && sensor !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="calibration_value"
              name="calibration_value"
              label="Sensor Calibration"
              type="number"
              variant="filled"
              fullWidth
              value={formik.values.calibration_value}
              onChange={formik.handleChange}
              error={
                formik.touched.calibration_value &&
                Boolean(formik.errors.calibration_value)
              }
              helperText={
                formik.touched.calibration_value &&
                formik.errors.calibration_value
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <TooltipIcon text="Input a positive or negative percentage value to fine-tune the accuracy of your sensor as it ages." />
                ),
              }}
              disabled={isPending || (!editView && sensor !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider transparent />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={9} sm={4}>
              <Box display="flex">
                <Typography variant="h4">Alarms</Typography>
                <TooltipIcon text="Activate to add an alarm setpoint for your sensor and trigger alarm notifications when alarm value is met." />
              </Box>
            </Grid>
            <Grid item xs={3} sm={8}>
              <StyledSwitch
                id="warning_is_enabled"
                name="warning_is_enabled"
                size="small"
                value={formik.values.warning_is_enabled}
                checked={!!formik.values.warning_is_enabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    "warning_is_enabled",
                    event.target.checked
                  );
                  if (!event.target.checked) {
                    formik.setFieldValue("alert_type", null);
                    formik.setFieldValue("warning_point_min", null);
                    formik.setFieldValue("warning_point_max", null);
                    formik.setFieldValue("health_point_min", null);
                    formik.setFieldValue("health_point_max", null);
                  }
                }}
                disabled={isPending || (!editView && sensor !== undefined)}
              />
            </Grid>
            {!!formik.values.warning_is_enabled && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    variant="filled"
                    fullWidth
                    error={
                      formik.touched.alert_type &&
                      Boolean(formik.errors.alert_type)
                    }
                    required
                    disabled={isPending || (!editView && sensor !== undefined)}
                  >
                    <InputLabel id="alert_type-label">Alarm Type</InputLabel>
                    <Select
                      id="alert_type"
                      labelId="alert_type-label"
                      name="alert_type"
                      value={formik.values.alert_type}
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => {
                        formik.setFieldValue("alert_type", event.target.value);
                        if (
                          event.target.value !== 3 &&
                          event.target.value !== 4
                        ) {
                          formik.setFieldValue("warning_point_min", null);
                          formik.setFieldValue("health_point_min", null);
                        }
                      }}
                      required
                      disabled={
                        isPending || (!editView && sensor !== undefined)
                      }
                    >
                      <MenuItem value={1}>Above Value</MenuItem>
                      <MenuItem value={2}>Below Value</MenuItem>
                      <MenuItem value={3}>In Range</MenuItem>
                      <MenuItem value={4}>Out of Range</MenuItem>
                    </Select>
                    {formik.touched.alert_type &&
                      Boolean(formik.errors.alert_type) && (
                        <FormHelperText>
                          {formik.errors.alert_type}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                {!Number.isNaN(formik.values.alert_type) && (
                  <>
                    {alertTypeIsRange && (
                      <Grid item xs={6}>
                        <TextField
                          id="warning_point_min"
                          name="warning_point_min"
                          label="Min."
                          type="number"
                          variant="filled"
                          fullWidth
                          value={formik.values.warning_point_min}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.warning_point_min &&
                            Boolean(formik.errors.warning_point_min)
                          }
                          helperText={
                            formik.touched.warning_point_min &&
                            formik.errors.warning_point_min
                          }
                          required
                          disabled={
                            isPending || (!editView && sensor !== undefined)
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={alertTypeIsRange ? 6 : 12}>
                      <TextField
                        id="warning_point_max"
                        name="warning_point_max"
                        label={alertTypeIsRange ? "Max." : "Value"}
                        type="number"
                        variant="filled"
                        fullWidth
                        value={formik.values.warning_point_max}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.warning_point_max &&
                          Boolean(formik.errors.warning_point_max)
                        }
                        helperText={
                          formik.touched.warning_point_max &&
                          formik.errors.warning_point_max
                        }
                        required
                        disabled={
                          isPending || (!editView && sensor !== undefined)
                        }
                      />
                    </Grid>
                    {alertTypeIsRange && (
                      <Grid item xs={6}>
                        <TextField
                          id="health_point_min"
                          name="health_point_min"
                          label="Health Min."
                          type="number"
                          variant="filled"
                          fullWidth
                          value={formik.values.health_point_min}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.health_point_min &&
                            Boolean(formik.errors.health_point_min)
                          }
                          helperText={
                            formik.touched.health_point_min &&
                            formik.errors.health_point_min
                          }
                          required
                          disabled={
                            isPending || (!editView && sensor !== undefined)
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={alertTypeIsRange ? 6 : 12}>
                      <TextField
                        id="health_point_max"
                        name="health_point_max"
                        label={
                          alertTypeIsRange ? "Health Max." : "Health Value"
                        }
                        type="number"
                        variant="filled"
                        fullWidth
                        value={formik.values.health_point_max}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.health_point_max &&
                          Boolean(formik.errors.health_point_max)
                        }
                        helperText={
                          formik.touched.health_point_max &&
                          formik.errors.health_point_max
                        }
                        required
                        disabled={
                          isPending || (!editView && sensor !== undefined)
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Divider transparent />
                </Grid>
              </Grid>
            )}
            <Grid item xs={9} sm={4}>
              <Box display="flex">
                <Typography style={{ whiteSpace: "nowrap" }} variant="h4">
                  Asset State Detector
                </Typography>
                <TooltipIcon text="Activate to use this sensor as a way of determining if the asset is in a state of OFF or ON. Only one sensor per asset may be used to detect asset state." />
              </Box>
            </Grid>
            <Grid item xs={3} sm={8}>
              <StyledSwitch
                id="status_is_enabled"
                name="status_is_enabled"
                size="small"
                value={formik.values.status_is_enabled}
                checked={!!formik.values.status_is_enabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    "status_is_enabled",
                    event.target.checked
                  );
                  if (!event.target.checked) {
                    formik.setFieldValue("status_alert_type", null);
                    formik.setFieldValue("status_point_min", null);
                    formik.setFieldValue("status_point_max", null);
                  }
                }}
                disabled={isPending || (!editView && sensor !== undefined)}
              />
            </Grid>
            {!!formik.values.status_is_enabled && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    variant="filled"
                    fullWidth
                    error={
                      formik.touched.status_alert_type &&
                      Boolean(formik.errors.status_alert_type)
                    }
                    required
                    disabled={isPending || (!editView && sensor !== undefined)}
                  >
                    <InputLabel id="status_alert_type-label">
                      State Detector Type
                    </InputLabel>
                    <Select
                      id="status_alert_type"
                      labelId="status_alert_type-label"
                      name="status_alert_type"
                      value={formik.values.status_alert_type}
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => {
                        formik.setFieldValue(
                          "status_alert_type",
                          event.target.value
                        );
                        if (
                          event.target.value !== 3 &&
                          event.target.value !== 4
                        ) {
                          formik.setFieldValue("status_point_min", null);
                        }
                      }}
                      required
                      disabled={
                        isPending || (!editView && sensor !== undefined)
                      }
                    >
                      <MenuItem value={1}>Above Value</MenuItem>
                      <MenuItem value={2}>Below Value</MenuItem>
                      <MenuItem value={3}>In Range</MenuItem>
                      <MenuItem value={4}>Out of Range</MenuItem>
                    </Select>
                    {formik.touched.status_alert_type &&
                      Boolean(formik.errors.status_alert_type) && (
                        <FormHelperText>
                          {formik.errors.status_alert_type}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                {!Number.isNaN(formik.values.status_alert_type) && (
                  <>
                    {statusAlertTypeIsRange && (
                      <Grid item xs={6}>
                        <TextField
                          id="status_point_min"
                          name="status_point_min"
                          label="Min."
                          type="number"
                          variant="filled"
                          fullWidth
                          value={formik.values.status_point_min}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.status_point_min &&
                            Boolean(formik.errors.status_point_min)
                          }
                          helperText={
                            formik.touched.status_point_min &&
                            formik.errors.status_point_min
                          }
                          required
                          disabled={
                            isPending || (!editView && sensor !== undefined)
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={statusAlertTypeIsRange ? 6 : 12}>
                      <TextField
                        id="status_point_max"
                        name="status_point_max"
                        label={statusAlertTypeIsRange ? "Max." : "Value"}
                        type="number"
                        variant="filled"
                        fullWidth
                        value={formik.values.status_point_max}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.status_point_max &&
                          Boolean(formik.errors.status_point_max)
                        }
                        helperText={
                          formik.touched.status_point_max &&
                          formik.errors.status_point_max
                        }
                        required
                        disabled={
                          isPending || (!editView && sensor !== undefined)
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Divider transparent />
                </Grid>
              </Grid>
            )}
            {sensorTypes.filter(
              (sensorType) =>
                sensorType.id === formik.values.sensor_type_id &&
                (sensorType.name === "Flow" ||
                  sensorType.name === "Power" ||
                  sensorType.name === "Energy" ||
                  sensorType.name === "Amperage AC")
            ).length > 0 ? (
              <>
                <Grid item xs={9} sm={4}>
                  <Box display="flex">
                    <Typography variant="h4">Usage Meter</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sm={8}>
                  <StyledSwitch
                    id="usage_is_enabled"
                    name="usage_is_enabled"
                    size="small"
                    value={formik.values.usage_is_enabled}
                    checked={!!formik.values.usage_is_enabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue(
                        "usage_is_enabled",
                        event.target.checked
                      );
                      if (!event.target.checked) {
                        formik.setFieldValue("usage_measurement", null);
                      }
                    }}
                    disabled={isPending || (!editView && sensor !== undefined)}
                  />
                </Grid>
                {!!formik.values.usage_is_enabled && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        error={
                          formik.touched.usage_measurement &&
                          Boolean(formik.errors.usage_measurement)
                        }
                        required
                        disabled={
                          isPending || (!editView && sensor !== undefined)
                        }
                      >
                        <InputLabel id="usage_measurement-label">
                          Unit of Measurement
                        </InputLabel>
                        <Select
                          id="usage_measurement"
                          labelId="usage_measurement-label"
                          name="usage_measurement"
                          value={formik.values.usage_measurement || ""}
                          onChange={(
                            event: React.ChangeEvent<{
                              name?: string;
                              value: unknown;
                            }>
                          ) => {
                            formik.setFieldValue(
                              "usage_measurement",
                              event.target.value
                            );
                          }}
                          required
                          disabled={
                            isPending || (!editView && sensor !== undefined)
                          }
                        >
                          {sensorTypes.filter(
                            (sensorType) =>
                              sensorType.id === formik.values.sensor_type_id &&
                              (sensorType.name === "Power" ||
                                sensorType.name === "Amperage AC" ||
                                sensorType.name === "Energy")
                          ).length > 0 ? (
                            <MenuItem value="kwh">kiloWatt hr (kWh)</MenuItem>
                          ) : null}
                          {sensorTypes.filter(
                            (sensorType) =>
                              sensorType.id === formik.values.sensor_type_id &&
                              sensorType.name === "Flow"
                          ).length > 0
                            ? [
                                <MenuItem value="gal">Gallons (gal)</MenuItem>,
                                <MenuItem value="liters">Liters (L)</MenuItem>,
                                <MenuItem value="ac-ft">
                                  Acre Feet (ac-ft.)
                                </MenuItem>,
                              ]
                            : null}
                        </Select>
                        {formik.touched.usage_measurement &&
                          Boolean(formik.errors.usage_measurement) && (
                            <FormHelperText>
                              {formik.errors.usage_measurement}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider transparent />
                    </Grid>
                  </Grid>
                )}
              </>
            ) : null}
            <Grid item xs={9} sm={4}>
              <Box display="flex">
                <Typography variant="h4">Sensor Data</Typography>
                <TooltipIcon text="Activate this feature when you want to receive sensor data from this asset. Deactivate when you do not wish to receive data (Ex. Asset removed from operation)." />
              </Box>
            </Grid>
            <Grid item xs={3} sm={8}>
              <StyledSwitch
                id="is_enabled"
                name="is_enabled"
                size="small"
                value={formik.values.is_enabled}
                checked={!!formik.values.is_enabled}
                onChange={formik.handleChange}
                disabled={isPending || (!editView && sensor !== undefined)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
