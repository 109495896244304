import React, { FC } from "react";
import { Tooltip } from "@material-ui/core";
import { InfoIcon } from "./TooltipIcon.style";

export interface ITooltipIcon {
  text: JSX.Element | string;
}

export const TooltipIcon: FC<ITooltipIcon> = ({ text }) => {
  return (
    <Tooltip title={text} placement="top">
      <InfoIcon color="action" />
    </Tooltip>
  );
};
