import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { UsersTableContainer } from "../../container/UsersTableContainer";
import { UsersSearchContainer } from "../../container/UsersSearchContainer";
import { AddUserModalContainer } from "../../container/AddUserModalContainer";

export const UsersView: FC = () => (
  <StyledPage title="Users">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Users" />
        <ConnectedShowForPermissions
          feature="users"
          permissions={{ allOf: ["read"] }}
        >
          <PageActionsWrapper>
            <UsersSearchContainer />
            <AddUserModalContainer />
          </PageActionsWrapper>
        </ConnectedShowForPermissions>
      </PageHeaderContainer>
      <ConnectedShowForPermissions
        feature="users"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <UsersTableContainer />
        </Box>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
