import { authApiClient } from "../../app/api/authApi.client";
import { CreateEditTeamDto, TeamDto } from "./teams.api.dto";

export const fetchTeams = async (): Promise<TeamDto[]> => {
  try {
    const response = await authApiClient.get("/teams");
    return response.data.teams;
  } catch (error) {
    throw error;
  }
};

export const createTeam = async (team: CreateEditTeamDto): Promise<void> => {
  try {
    const response = await authApiClient.post("/teams", team);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTeam = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/teams/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editTeam = async (
  id: number,
  editTeamDto: CreateEditTeamDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(`/teams/${id}`, editTeamDto);
    return response.data;
  } catch (error) {
    throw error;
  }
};
