import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { setUserIdFilter } from "../store/workOrders.slice";
import { MyStuffChip } from "../component/MyStuffChip/MyStuffChip";
import {
  selectProfilePhotoUrl,
  selectProfileUserId,
} from "../../auth/store/auth.selector";
import { selectUserIdFilter } from "../store/workOrders.selector";

export const MyStuffChipContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userPhotoUrl = useSelector(selectProfilePhotoUrl);
  const userIdFilter = useSelector(selectUserIdFilter);
  const userId = useSelector(selectProfileUserId);

  const onSelect = (value: boolean) => {
    dispatch(value ? setUserIdFilter(userId) : setUserIdFilter(null));
  };

  return (
    <MyStuffChip
      onSelect={onSelect}
      userPhotoUrl={userPhotoUrl}
      selected={Boolean(userIdFilter)}
    />
  );
};
