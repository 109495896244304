import { DialogProps } from "@material-ui/core";
import React, { ReactElement, FC, useEffect } from "react";
import { StyledDialog } from "./Modal.style";

export interface IModal {
  Content: () => ReactElement | undefined;
  Header?: () => ReactElement | undefined;
  onClose: () => void;
  onEnter?: () => void; // fired 1st
  onEntering?: () => void; // fired 2nd
  onEntered?: () => void; // fired 3rd
  onExit?: () => void; // fired 1st
  onExiting?: () => void; // fired 2nd
  onExited?: () => void; // fired 3rd
  open: boolean;
  size?: DialogProps["maxWidth"];
}

export const Modal: FC<IModal> = ({
  onClose,
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  open,
  Header,
  Content,
  size,
}: IModal) => {
  const handleClose = () => onClose();

  return (
    <StyledDialog
      onClose={handleClose}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
      open={open}
      fullWidth
      maxWidth={size ?? "sm"}
    >
      {Header && Header()}
      {Content()}
    </StyledDialog>
  );
};
