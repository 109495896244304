import React, { FC, useState } from "react";
import { Column } from "react-table";
import { Typography } from "@material-ui/core";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  StyledCard,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import { DeviceListModel } from "../../model/devices.model";
import { CreateEditDeviceDto, DeviceListDto } from "../../api/devices.api.dto";
import { EDIT_DEVICE_MODAL } from "../../../app/const/modals";
import { DeviceModal } from "../DeviceModal/DeviceModal";
import { useShowForPermissions } from "../../../app/hooks/useShowForPermissions";
import { ConditionalTooltipWrapper } from "../../../app/component/Table/ConditionalTooltipWrapper/ConditionalTooltipWrapper";
import { setMaxTextLength } from "../../../app/util/max-text-length";

interface IDevicesTable {
  devices: DeviceListModel[];
  isTablePending: boolean;
  onEdit: (editDeviceDto: CreateEditDeviceDto, id?: number) => void;
  onDelete: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  deviceTypeOptions: { label: string; value: number }[];
  partnerOptions: { label: string; value: number }[];
  clientOptions: { label: string; value: number }[];
  isModalPending: boolean;
  onEnter?: () => void;
  onPartnerChange: (partnerId: number | null) => void;
  onClientChange: (partnerId: number) => void;
  assetOptions: { label: string; value: number; channels: number[] }[];
  onError: (message: string) => void;
  onModalMount: (device: DeviceListModel | DeviceListDto) => void;
}

export const DevicesTable: FC<IDevicesTable> = ({
  devices,
  isTablePending,
  onDelete,
  onEdit,
  onCloseModal,
  onOpenModal,
  deviceTypeOptions,
  partnerOptions,
  clientOptions,
  isModalPending,
  onError,
  onClientChange,
  onEnter,
  onPartnerChange,
  assetOptions,
  onModalMount,
}) => {
  const show = useShowForPermissions("devices", { oneOf: ["read", "update"] });
  const [selectedDevice, setSelectedDevice] = useState<
    DeviceListModel | undefined
  >(undefined);

  const openModal = (device: DeviceListModel) => {
    setSelectedDevice(device);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedDevice(undefined);
    onCloseModal();
  };

  const actionsColumn: Column = {
    id: "actions-column",
    disableSortBy: true,
    Cell: ({ row: { original } }: { row: { original: DeviceListModel } }) => (
      <ActionCellWrapper>
        <Button
          variant="table-action"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            openModal(original);
          }}
        >
          View
        </Button>
      </ActionCellWrapper>
    ),
  };

  const columns: Column[] = [
    {
      Header: "Device Name",
      id: "name",
      accessor: "name",
      Cell: ({ row: { original } }: { row: { original: DeviceListDto } }) => (
        <ConditionalTooltipWrapper
          condition={original.name.length > 15}
          text={original.name}
        >
          <Typography component="span" variant="body2">
            {setMaxTextLength(original.name, 15)}
          </Typography>
        </ConditionalTooltipWrapper>
      ),
    },
    {
      Header: "Measurement Plan",
      id: "measurement",
      accessor: "measurement",
      Cell: ({ row: { original } }: { row: { original: DeviceListModel } }) =>
        `${original.measurement} ${
          original.measurement === 1 ? "Second" : "Seconds"
        }`,
    },
    {
      Header: "Asset Name(s)",
      id: "equipments",
      accessor: "equipments",
      width: 100,
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <ConditionalTooltipWrapper
          condition={original.equipment_names.length > 15}
          text={original.equipment_names}
        >
          <Typography component="span" variant="body2">
            {setMaxTextLength(original.equipment_names, 15)}
          </Typography>
        </ConditionalTooltipWrapper>
      ),
    },
    { id: "device_type_id", Header: "Device ID", accessor: "device_type_id" },
    {
      Header: "Tako S/N",
      accessor: "serial_number",
    },
    {
      id: "partner",
      Header: "Partner Name",
      accessor: "partner",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <ConditionalTooltipWrapper
          condition={original.partner.length > 15}
          text={original.partner}
        >
          <Typography component="span" variant="body2">
            {setMaxTextLength(original.partner, 15)}
          </Typography>
        </ConditionalTooltipWrapper>
      ),
    },
    {
      id: "client",
      Header: "Client Name",
      accessor: "client",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <ConditionalTooltipWrapper
          condition={original.client.length > 15}
          text={original.client}
        >
          <Typography component="span" variant="body2">
            {setMaxTextLength(original.client, 15)}
          </Typography>
        </ConditionalTooltipWrapper>
      ),
    },
    {
      id: "last_reading_date",
      Header: "Last Reading",
      accessor: "last_reading_date",
    },
    { id: "status", Header: "Recording", accessor: "status" },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={show ? [...columns, actionsColumn] : columns}
          data={devices}
          hover
          sorting={{ id: "name", desc: false }}
        />
      </StyledCard>
      <DeviceModal
        name={EDIT_DEVICE_MODAL}
        onClose={closeModal}
        onSubmit={onEdit}
        device={selectedDevice}
        deviceTypeOptions={deviceTypeOptions}
        partnerOptions={partnerOptions}
        clientOptions={clientOptions}
        isPending={isModalPending}
        onEnter={onEnter}
        onPartnerChange={onPartnerChange}
        assetOptions={assetOptions}
        onClientChange={onClientChange}
        onError={onError}
        onModalMount={onModalMount}
        onDelete={onDelete}
      />
    </>
  );
};
