import { createSlice } from "@reduxjs/toolkit";
import { WorkOrdersState, workOrdersState } from "./workOrders.store";
import {
  fetchTasks,
  createTask,
  editTask,
  deleteTask,
  createComment,
  deleteComment,
} from "./workOrders.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TaskDto, TaskStatus } from "../api/workorders.api.dto";

const slice = createSlice({
  name: "workorders",
  initialState: workOrdersState,
  reducers: {
    sortTasks: (
      state: WorkOrdersState,
      { payload }: { payload: { newList: TaskDto[]; listTitle: TaskStatus } }
    ) => {
      return {
        ...state,
        tasksLists: {
          ...state.tasksLists,
          data: {
            ...state.tasksLists.data,
            [payload.listTitle]: payload.newList,
          },
        },
      };
    },
    searchTasks: (
      state: WorkOrdersState,
      { payload }: { payload: string }
    ) => ({
      ...state,
      searchTasks: payload,
    }),
    clearSearchTasksState: (state: WorkOrdersState) => ({
      ...state,
      searchTasks: workOrdersState.searchTasks,
    }),
    setUserIdFilter: (
      state: WorkOrdersState,
      { payload }: { payload: number | null }
    ) => ({
      ...state,
      userIdFilter: payload,
    }),
    setSelectedClientId: (
      state: WorkOrdersState,
      { payload }: { payload: number | null }
    ) => ({
      ...state,
      selectedClientId: payload,
    }),
    clearWorkOrderState: () => workOrdersState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.pending, (state) => ({
      ...state,
      tasksLists: {
        ...state.tasksLists,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchTasks.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          tasksLists: {
            status: AsyncStatus.Fulfilled,
            data: payload.tasksList,
          },
          tasks: {
            status: AsyncStatus.Fulfilled,
            data: payload.tasks,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchTasks.rejected, (state) => ({
      ...state,
      tasksLists: {
        ...state.tasksLists,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createTask.pending, (state) => ({
      ...state,
      tasksLists: {
        ...state.tasksLists,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createTask.fulfilled, (state, { payload }) => {
      if (payload) {
        const tasks = [...state.tasksLists.data[payload.status], payload];
        return {
          ...state,
          tasksLists: {
            ...state.tasksLists,
            data: {
              ...state.tasksLists.data,
              [payload.status]: tasks,
            },
            status: AsyncStatus.Fulfilled,
          },
        };
      }

      return state;
    });
    builder.addCase(createTask.rejected, (state) => ({
      ...state,
      tasksLists: {
        ...state.tasksLists,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(editTask.pending, (state) => ({
      ...state,
      tasksLists: {
        ...state.tasksLists,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editTask.fulfilled, (state, { payload }) => {
      if (payload) {
        const tasks = [...state.tasksLists.data[payload.status]];
        const index = tasks.findIndex(
          (task) => Number(task.id) === Number(payload.id)
        );
        tasks[index] = payload;

        return {
          ...state,
          tasksLists: {
            ...state.tasksLists,
            data: {
              ...state.tasksLists.data,
              [payload.status]: tasks,
            },
            status: AsyncStatus.Fulfilled,
          },
        };
      }

      return state;
    });
    builder.addCase(editTask.rejected, (state) => ({
      ...state,
      tasksLists: {
        ...state.tasksLists,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteTask.pending, (state) => ({
      ...state,
      deleteTask: {
        ...state.deleteTask,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteTask.fulfilled, (state) => ({
      ...state,
      deleteTask: {
        ...state.deleteTask,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteTask.rejected, (state) => ({
      ...state,
      deleteTask: {
        ...state.deleteTask,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createComment.pending, (state) => ({
      ...state,
      createComments: {
        ...state.createComments,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createComment.fulfilled, (state, { payload }) => {
      if (payload) {
        const newTasks = [...state.tasksLists.data[payload.task.status]];
        const index = newTasks.findIndex(
          (task) => Number(task.id) === Number(payload.task.id)
        );

        newTasks[index] = {
          ...newTasks[index],
          comments: [...newTasks[index].comments, payload.newComment],
        };

        return {
          ...state,
          tasksLists: {
            ...state.tasksLists,
            data: {
              ...state.tasksLists.data,
              [payload.task.status]: newTasks,
            },
            status: AsyncStatus.Fulfilled,
          },
        };
      }

      return state;
    });
    builder.addCase(createComment.rejected, (state) => ({
      ...state,
      createComments: {
        ...state.createComments,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteComment.pending, (state) => ({
      ...state,
      deleteComments: {
        ...state.deleteComments,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteComment.fulfilled, (state, { payload }) => {
      if (payload) {
        const newTasks = [...state.tasksLists.data[payload.task.status]];
        const index = newTasks.findIndex(
          (task) => Number(task.id) === Number(payload.task.id)
        );

        newTasks[index] = {
          ...newTasks[index],
          comments: newTasks[index].comments.filter(
            (e) => e.text !== payload.newComment.text
          ),
        };

        return {
          ...state,
          tasksLists: {
            ...state.tasksLists,
            data: {
              ...state.tasksLists.data,
              [payload.task.status]: newTasks,
            },
            status: AsyncStatus.Fulfilled,
          },
        };
      }

      return state;
    });
    builder.addCase(deleteComment.rejected, (state) => ({
      ...state,
      deleteComments: {
        ...state.deleteComments,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});
export const {
  sortTasks,
  searchTasks,
  clearSearchTasksState,
  setUserIdFilter,
  setSelectedClientId,
  clearWorkOrderState,
} = slice.actions;
export default slice.reducer;
