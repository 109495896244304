import { createSlice } from "@reduxjs/toolkit";
import { RolesState, rolesState } from "./roles.store";
import { fetchRoles, deleteRole, editRole, createRole } from "./roles.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "roles",
  initialState: rolesState,
  reducers: {
    searchRoles: (state: RolesState, { payload }: { payload: string }) => ({
      ...state,
      searchRoles: payload,
    }),
    clearFetchRolesState: (state: RolesState) => ({
      ...state,
      fetchRoles: rolesState.fetchRoles,
    }),
    clearSearchRolesState: (state: RolesState) => ({
      ...state,
      searchRoles: rolesState.searchRoles,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => ({
      ...state,
      fetchRoles: {
        ...state.fetchRoles,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchRoles: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchRoles.rejected, (state) => ({
      ...state,
      fetchRoles: {
        ...state.fetchRoles,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteRole.pending, (state) => ({
      ...state,
      deleteRole: {
        ...state.deleteRole,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteRole.fulfilled, (state) => ({
      ...state,
      deleteRole: {
        ...state.deleteRole,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteRole.rejected, (state) => ({
      ...state,
      deleteRole: {
        ...state.deleteRole,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editRole.pending, (state) => ({
      ...state,
      editRole: {
        ...state.editRole,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editRole.fulfilled, (state) => ({
      ...state,
      editRole: {
        ...state.editRole,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editRole.rejected, (state) => ({
      ...state,
      editRole: {
        ...state.editRole,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createRole.pending, (state) => ({
      ...state,
      createRole: {
        ...state.createRole,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createRole.fulfilled, (state) => ({
      ...state,
      createRole: {
        ...state.createRole,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createRole.rejected, (state) => ({
      ...state,
      createRole: {
        ...state.createRole,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchRoles,
  clearFetchRolesState,
  clearSearchRolesState,
} = slice.actions;
export default slice.reducer;
