import React, { FC, useState } from "react";
import {
  Typography,
  CardContent,
  Box,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { TaskStatus } from "../../api/workorders.api.dto";
import { ReactComponent as TaskIcon } from "../../../../assets/icons/wo-task.svg";
import { ReactComponent as SortIcon } from "../../../../assets/icons/wo-sort.svg";
import { ReactComponent as SwapIcon } from "../../../../assets/icons/wo-swap.svg";

import {
  StyledCard,
  StyledChip,
  StyledIconButton,
} from "./TaskCardHeader.style";
import { SortBy } from "../../enum/workorders.enum";

interface ITaskCardHeader {
  type: TaskStatus;
  amount: number;
  onSortTasks: (type: TaskStatus, sortBy: SortBy, desc: boolean) => void;
}

export const HEADER_DETAILS = {
  [TaskStatus.Open]: {
    icon: "test",
    name: "Open",
    color: "orange",
  },
  [TaskStatus.InProgress]: {
    icon: "test",
    name: "In Progress",
    color: "blue",
  },
  [TaskStatus.Done]: {
    icon: "test",
    name: "Closed",
    color: "green",
  },
};

export const TaskCardHeader: FC<ITaskCardHeader> = ({
  type,
  amount,
  onSortTasks,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortByState, setSortByState] = useState(SortBy.DueDate);
  const [isDesc, setIsDesc] = useState(true);
  const openSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSortMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    sortBy: SortBy
  ) => {
    setSortByState(sortBy);
    onSortTasks(type, sortBy, isDesc);
    setAnchorEl(null);
  };

  const handleDescClick = () => {
    onSortTasks(type, sortByState, !isDesc);
    setIsDesc(!isDesc);
  };

  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <TaskIcon />
            <Box ml={1} mr={1}>
              <Typography variant="h5">{HEADER_DETAILS[type].name}</Typography>
            </Box>
            <StyledChip size="small" label={amount} />
          </Box>
          <Box>
            <StyledIconButton
              startIcon={<SwapIcon />}
              onClick={handleDescClick}
            />
            <StyledIconButton startIcon={<SortIcon />} onClick={openSortMenu} />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeSortMenu}
            >
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, SortBy.DueDate)}
              >
                Sort By Due Date
              </MenuItem>
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, SortBy.Priority)}
              >
                Sort By Priority
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};
