import React, { FC } from "react";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "./daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { StyledInput } from "./DateTimeRangePicker.style";
import { DATE_TIME_RANGE_PICKER_FORMAT } from "../../const/AssetGraphs.const";

interface IDateTimeRangePicker {
  startEpoch: number;
  endEpoch: number;
  setStartEpoch: React.Dispatch<React.SetStateAction<number>>;
  setEndEpoch: React.Dispatch<React.SetStateAction<number>>;
}

export const DateTimeRangePicker: FC<IDateTimeRangePicker> = ({
  startEpoch,
  endEpoch,
  setStartEpoch,
  setEndEpoch,
}) => {
  return (
    <DateRangePicker
      initialSettings={{
        startDate: moment(startEpoch * 1000),
        endDate: moment(endEpoch * 1000),
        timePicker: true,
        opens: "left",
        locale: {
          format: DATE_TIME_RANGE_PICKER_FORMAT,
        },
        alwaysShowCalendars: true,
        ranges: {
          "Last hour": [moment().subtract(1, "hour"), moment()],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "Last 90 Days": [moment().subtract(89, "days"), moment()],
          "Last 180 Days": [moment().subtract(179, "days"), moment()],
        },
      }}
      onApply={(ev, picker) => {
        setStartEpoch(moment(picker.startDate).unix());
        setEndEpoch(moment(picker.endDate).unix());
      }}
    >
      <StyledInput type="text" />
    </DateRangePicker>
  );
};
