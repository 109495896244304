import { apiClient } from "../../app/api/api.client";
import { authApiClient } from "../../app/api/authApi.client";
import {
  LoginCredentialsModel,
  RecoverPasswordDataModel,
  RegisterDataModel,
} from "../model/Auth";

export const login = async (
  credentials: LoginCredentialsModel
): Promise<any> => {
  try {
    const { data } = await apiClient.post("/login", credentials);
    return data;
  } catch (error) {
    throw error;
  }
};

export const register = async (
  registerData: RegisterDataModel
): Promise<void> => {
  try {
    const { data } = await apiClient.post("/register", registerData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchProfile = async (): Promise<any> => {
  try {
    const { data } = await authApiClient.get("/user");
    return data;
  } catch (error) {
    throw error;
  }
};

export const recoverPassword = async (
  recoverPasswordData: RecoverPasswordDataModel
): Promise<void> => {
  try {
    const { data } = await apiClient.post(
      "/forgot/password",
      recoverPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (resetPasswordData: any): Promise<void> => {
  try {
    const { data } = await apiClient.post("/password/reset", resetPasswordData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (resetPasswordData: any): Promise<void> => {
  try {
    const { data } = await authApiClient.post(
      "/password/reset",
      resetPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string): Promise<void> => {
  try {
    const { data } = await apiClient.post("/forgot/password", { email });
    return data;
  } catch (error) {
    throw error;
  }
};
