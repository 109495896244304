import { createSlice } from "@reduxjs/toolkit";
import { assetState, AssetState } from "./asset.store";
import {
  fetchAssets,
  deleteAsset,
  editAsset,
  createAsset,
  fetchCategories,
  fetchTags,
  showAsset,
  fetchHealth,
  fetchSensors,
  fetchAssetHealthData,
  fetchAssetSensorReadingsData,
  fetchRuntimeData,
  fetchAssetUsageData,
  fetchDyanmoData,
  fetchAssetNotes,
  deleteAssetNote,
  pinAssetNote,
  addAssetNote,
  fetchAssetAttachments,
  downloadAssetAttachment,
  deleteAssetAttachment,
  fetchAssetSensors,
  addAssetToBookmark,
  removeAssetFromBookmark,
  fetchAssetAlertHistory,
} from "./asset.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AssetDto } from "../api/asset.api.dto";

const slice = createSlice({
  name: "asset",
  initialState: assetState,
  reducers: {
    searchAssets: (state: AssetState, { payload }: { payload: string }) => ({
      ...state,
      searchAssets: payload,
    }),
    setTagsFilters: (
      state: AssetState,
      { payload }: { payload: string[] }
    ) => ({
      ...state,
      tagsFilters: payload,
    }),
    clearSearchAssetsState: (state: AssetState) => ({
      ...state,
      searchAssets: assetState.searchAssets,
    }),
    clearAssetState: (state: AssetState) => ({
      ...state,
      assets: assetState.assets,
    }),
    clearSensors: (state: AssetState) => ({
      ...state,
      sensors: {
        ...state.sensors,
        data: [],
      },
    }),
    clearRuntime: (state: AssetState) => ({
      ...state,
      runtime: {
        ...state.sensors,
        data: [],
      },
    }),
    setAssetsState: (
      state: AssetState,
      { payload }: { payload: AssetDto[] }
    ) => ({
      ...state,
      assets: {
        ...state.assets,
        data: payload,
      },
    }),
    setFilteredAssetsState: (
      state: AssetState,
      { payload }: { payload: AssetDto[] }
    ) => ({
      ...state,
      filteredAssets: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.pending, (state) => ({
      ...state,
      assets: {
        ...state.assets,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          assets: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchAssets.rejected, (state) => ({
      ...state,
      assets: {
        ...state.assets,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchRuntimeData.pending, (state) => ({
      ...state,
      runtime: {
        ...state.runtime,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchRuntimeData.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          runtime: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchRuntimeData.rejected, (state) => ({
      ...state,
      runtime: {
        ...state.runtime,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(showAsset.pending, (state) => ({
      ...state,
      asset: {
        ...state.asset,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(showAsset.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          asset: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(showAsset.rejected, (state) => ({
      ...state,
      asset: {
        ...state.asset,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteAsset.pending, (state) => ({
      ...state,
      deleteAsset: {
        ...state.deleteAsset,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteAsset.fulfilled, (state) => ({
      ...state,
      deleteAsset: {
        ...state.deleteAsset,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteAsset.rejected, (state) => ({
      ...state,
      deleteAsset: {
        ...state.deleteAsset,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editAsset.pending, (state) => ({
      ...state,
      editAsset: {
        ...state.editAsset,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editAsset.fulfilled, (state) => ({
      ...state,
      editAsset: {
        ...state.editAsset,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editAsset.rejected, (state) => ({
      ...state,
      editAsset: {
        ...state.editAsset,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createAsset.pending, (state) => ({
      ...state,
      createAsset: {
        ...state.createAsset,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createAsset.fulfilled, (state) => ({
      ...state,
      createAsset: {
        ...state.createAsset,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createAsset.rejected, (state) => ({
      ...state,
      createAsset: {
        ...state.createAsset,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchCategories.pending, (state) => ({
      ...state,
      categories: {
        ...state.categories,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          categories: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchCategories.rejected, (state) => ({
      ...state,
      categories: {
        ...state.categories,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchTags.pending, (state) => ({
      ...state,
      tags: {
        ...state.tags,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          tags: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchTags.rejected, (state) => ({
      ...state,
      tags: {
        ...state.categories,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchHealth.pending, (state) => ({
      ...state,
      health: {
        ...state.health,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchHealth.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          health: {
            status: AsyncStatus.Fulfilled,
            data: payload.data,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchHealth.rejected, (state) => ({
      ...state,
      health: {
        ...state.health,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchSensors.pending, (state) => ({
      ...state,
      sensors: {
        ...state.sensors,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchSensors.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          sensors: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchSensors.rejected, (state) => ({
      ...state,
      sensors: {
        ...state.sensors,

        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(fetchAssetHealthData.pending, (state) => ({
      ...state,
      assetHealthData: {
        ...state.assetHealthData,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssetHealthData.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          assetHealthData: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }
      return state;
    });
    builder.addCase(fetchAssetHealthData.rejected, (state) => ({
      ...state,
      assetHealthData: {
        ...state.assetHealthData,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAssetSensorReadingsData.pending, (state) => ({
      ...state,
      assetSensorReadingsData: {
        ...state.assetSensorReadingsData,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(
      fetchAssetSensorReadingsData.fulfilled,
      (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            assetSensorReadingsData: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }

        return state;
      }
    );
    builder.addCase(fetchAssetSensorReadingsData.rejected, (state) => ({
      ...state,
      assetSensorReadingsData: {
        ...state.assetSensorReadingsData,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAssetUsageData.pending, (state) => ({
      ...state,
      usage: {
        ...state.usage,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssetUsageData.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          usage: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchAssetNotes.pending, (state) => ({
      ...state,
      notes: {
        ...state.notes,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssetNotes.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          notes: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchAssetUsageData.rejected, (state) => ({
      ...state,
      usage: {
        ...state.usage,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchDyanmoData.pending, (state) => ({
      ...state,
      dynamoHealthRollups: {
        ...state.dynamoHealthRollups,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchDyanmoData.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          dynamoHealthRollups: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchAssetNotes.rejected, (state) => ({
      ...state,
      notes: {
        ...state.notes,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteAssetNote.pending, (state) => ({
      ...state,
      deleteAssetNote: {
        ...state.deleteAssetNote,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteAssetNote.fulfilled, (state) => ({
      ...state,
      deleteAssetNote: {
        ...state.deleteAssetNote,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteAssetNote.rejected, (state) => ({
      ...state,
      deleteAssetNote: {
        ...state.deleteAssetNote,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(pinAssetNote.pending, (state) => ({
      ...state,
      pinAssetNote: {
        ...state.pinAssetNote,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(pinAssetNote.fulfilled, (state) => ({
      ...state,
      pinAssetNote: {
        ...state.pinAssetNote,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(pinAssetNote.rejected, (state) => ({
      ...state,
      pinAssetNote: {
        ...state.pinAssetNote,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(addAssetNote.pending, (state) => ({
      ...state,
      addAssetNote: {
        ...state.addAssetNote,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(addAssetNote.fulfilled, (state) => ({
      ...state,
      addAssetNote: {
        ...state.addAssetNote,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(addAssetNote.rejected, (state) => ({
      ...state,
      addAssetNote: {
        ...state.addAssetNote,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAssetAttachments.pending, (state) => ({
      ...state,
      attachments: {
        ...state.attachments,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssetAttachments.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          attachments: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchDyanmoData.rejected, (state) => ({
      ...state,
      dynamoHealthRollups: {
        ...state.dynamoHealthRollups,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAssetAttachments.rejected, (state) => ({
      ...state,
      attachments: {
        ...state.attachments,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(downloadAssetAttachment.pending, (state) => ({
      ...state,
      downloadAssetAttachment: {
        ...state.downloadAssetAttachment,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(downloadAssetAttachment.fulfilled, (state) => ({
      ...state,
      downloadAssetAttachment: {
        ...state.downloadAssetAttachment,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(downloadAssetAttachment.rejected, (state) => ({
      ...state,
      downloadAssetAttachment: {
        ...state.downloadAssetAttachment,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteAssetAttachment.pending, (state) => ({
      ...state,
      deleteAssetAttachment: {
        ...state.deleteAssetAttachment,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteAssetAttachment.fulfilled, (state) => ({
      ...state,
      deleteAssetAttachment: {
        ...state.deleteAssetAttachment,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteAssetAttachment.rejected, (state) => ({
      ...state,
      deleteAssetAttachment: {
        ...state.deleteAssetAttachment,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAssetSensors.pending, (state) => ({
      ...state,
      fetchAssetSensors: {
        ...state.fetchAssetSensors,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssetSensors.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchAssetSensors: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchAssetSensors.rejected, (state) => ({
      ...state,
      fetchAssetSensors: {
        ...state.fetchAssetSensors,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(addAssetToBookmark.pending, (state) => ({
      ...state,
      addAssetToBookmark: {
        ...state.addAssetToBookmark,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(addAssetToBookmark.fulfilled, (state) => ({
      ...state,
      addAssetToBookmark: {
        ...state.addAssetToBookmark,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(addAssetToBookmark.rejected, (state) => ({
      ...state,
      addAssetToBookmark: {
        ...state.addAssetToBookmark,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(removeAssetFromBookmark.pending, (state) => ({
      ...state,
      removeAssetFromBookmark: {
        ...state.removeAssetFromBookmark,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(removeAssetFromBookmark.fulfilled, (state) => ({
      ...state,
      removeAssetFromBookmark: {
        ...state.removeAssetFromBookmark,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(removeAssetFromBookmark.rejected, (state) => ({
      ...state,
      removeAssetFromBookmark: {
        ...state.removeAssetFromBookmark,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchAssetAlertHistory.pending, (state) => ({
      ...state,
      fetchAssetAlerts: {
        ...state.fetchAssetAlerts,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchAssetAlertHistory.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchAssetAlerts: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchAssetAlertHistory.rejected, (state) => ({
      ...state,
      fetchAssetAlerts: {
        ...state.fetchAssetAlerts,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchAssets,
  clearSearchAssetsState,
  clearSensors,
  clearRuntime,
  clearAssetState,
  setFilteredAssetsState,
  setTagsFilters,
} = slice.actions;
export default slice.reducer;
