import React, { FC, useState } from "react";
import { Column } from "react-table";
import { Typography } from "@material-ui/core";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import { UserRole } from "../../../app/enum/UserRole";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  StyledCard,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import { RoleDto, CreateEditRoleDto } from "../../api/roles.api.dto";
import { PartnerDto } from "../../../partners/api/partners.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { RoleModal } from "../RoleModal/RoleModal";
import { EDIT_ROLE_MODAL } from "../../../app/const/modals";

interface IRolesTable {
  onEditRole: (editRoleDto: CreateEditRoleDto, id?: number) => void;
  onDeleteRole: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  roles: RoleDto[];
  parentRoles: RoleDto[];
  partners: PartnerDto[];
  clients: ClientDto[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const RolesTable: FC<IRolesTable> = ({
  roles,
  parentRoles,
  partners,
  clients,
  isTablePending,
  isModalPending,
  onEditRole,
  onDeleteRole,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedRole, setSelectedRole] = useState<RoleDto | undefined>(
    undefined
  );

  const openModal = (role: RoleDto) => {
    setSelectedRole(role);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedRole(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    { Header: "Name", id: "name", accessor: "name" },
    {
      Header: "Partner",
      accessor: "partners",
      Cell: ({ row: { original } }: { row: { original: RoleDto } }) => (
        <Typography variant="inherit" noWrap>
          {original.partners.map((partner: any) => partner.name).join(", ")}
        </Typography>
      ),
    },
    {
      Header: "Client",
      accessor: "clients",
      Cell: ({ row: { original } }: { row: { original: RoleDto } }) => (
        <Typography variant="inherit" noWrap>
          {original.clients.map((client: any) => client.name).join(", ")}
        </Typography>
      ),
    },
    {
      Header: "Users",
      accessor: "user_count",
    },
    {
      Header: "Notes",
      accessor: "description",
      Cell: ({ row: { original } }: { row: { original: RoleDto } }) => (
        <Typography variant="inherit" noWrap>
          {original.description}
        </Typography>
      ),
    },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: RoleDto } }) => (
        <ActionCellWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            View
          </Button>
        </ActionCellWrapper>
      ),
    },
  ];

  return (
    <ConnectedShowForPermissions
      feature="roles"
      permissions={{ allOf: ["read"] }}
    >
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={roles}
          hover
          sorting={{ id: "name", desc: false }}
        />
      </StyledCard>
      <RoleModal
        name={EDIT_ROLE_MODAL}
        role={selectedRole}
        onClose={closeModal}
        onSubmit={onEditRole}
        onDelete={onDeleteRole}
        parentRoles={parentRoles}
        partners={partners}
        clients={clients}
        isPending={isModalPending}
      />
    </ConnectedShowForPermissions>
  );
};
