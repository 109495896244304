import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { ApiNotificationsState } from "./apiNotifications.store";
import { NotificationDto } from "../api/apiNotifications.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectApiNotificiationRootState = createSelector(
  selectSelf,
  (state: RootState): ApiNotificationsState => state.apiNotifications
);

export const selectApiNotificiationState = createSelector(
  selectApiNotificiationRootState,
  (apiNotificationsRoot): AsyncStateModel<NotificationDto[]> =>
    apiNotificationsRoot.apiNotifications
);

export const selectApiNotificiationData = createSelector(
  selectApiNotificiationState,
  (apiNotifications): NotificationDto[] => apiNotifications.data
);

export const selectApiNotificiationStatus = createSelector(
  selectApiNotificiationState,
  (apiNotifications): AsyncStatus => apiNotifications.status
);

export const selectIsApiNotificiationPending = createSelector(
  selectApiNotificiationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
