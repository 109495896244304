import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { AssetState } from "./asset.store";
import {
  AssetAttachmentDto,
  AssetDto,
  AssetNoteDto,
  AssetTagDto,
  AssetRuntimeDto,
  ChannelGraphData,
  SensorDto,
  AssetUsageDto,
  AssetDynamoRollupDto,
  SensorTableDto,
  AssetAlertsDto,
} from "../api/asset.api.dto";
import { CategoryDto } from "../../category/api/category.api.dto";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AssetStatus } from "../enum/assets.enum";
import { AssetFilterNumbers, AssetNoteModel } from "../model/asset.model";
import { filterByTags } from "../util/filter-by-tags";

export const selectSelf = (state: RootState): RootState => state;

export const selectAssetState = createSelector(
  selectSelf,
  (state: RootState): AssetState => state.asset
);

export const selectAssetsState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetDto[]> => asset.assets
);

export const selectFilteredAssets = createSelector(
  selectAssetState,
  (asset: AssetState): AssetDto[] => asset.filteredAssets
);

export const selectAssetsData = createSelector(
  selectAssetsState,
  (assets: AsyncStateModel<AssetDto[]>): AssetDto[] => assets.data
);

export const selectShowAssetState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetDto | null> => asset.asset
);

export const selectShowAssetData = createSelector(
  selectShowAssetState,
  (asset): AssetDto | null => asset.data
);

export const selectShowAssetStatus = createSelector(
  selectShowAssetState,
  (asset: AsyncStateModel<AssetDto | null>): AsyncStatus => asset.status
);

export const selectIsShowAssetStatusPending = createSelector(
  selectShowAssetStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectIsShowAssetStatusFulfilled = createSelector(
  selectShowAssetStatus,
  (status): boolean => status === AsyncStatus.Fulfilled
);

export const selectAssetsStatus = createSelector(
  selectAssetsState,
  (assets): AsyncStatus => assets.status
);

export const selectSearchAssets = createSelector(
  selectAssetState,
  (assets): string => assets.searchAssets
);

export const selectTagsFilters = createSelector(
  selectAssetState,
  (assets): string[] => assets.tagsFilters
);

export const selectAssetsTableData = createSelector(
  selectSearchAssets,
  selectAssetsData,
  (searchValue, assets): AssetDto[] => {
    const data = assets.map((asset) => ({
      ...asset,
    }));

    if (searchValue) {
      return data.filter((asset) =>
        `${asset.name}${
          asset.health_rating === null ? "" : asset.health_rating
        }${
          asset.categories
            ? asset.categories
                .map((category: CategoryDto) => category.name)
                .join(", ")
            : ""
        }${asset.manufacturer || ""}${asset.model || ""}${
          asset.location?.title || ""
        }${asset.teams ? asset.teams.map((team) => team.name).join(", ") : ""}${
          asset.last_received
        }${asset.status}`
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    }
    return data;
  }
);

export const selectAssetsFilterTabData = createSelector(
  selectSearchAssets,
  selectTagsFilters,
  selectAssetsData,
  (searchValue, tagsFilters, assets): AssetDto[] => {
    let data = assets.map((asset) => ({
      ...asset,
    }));

    if (tagsFilters.length) {
      data = filterByTags(data, tagsFilters);
    }

    if (searchValue) {
      data = data.filter((asset) =>
        `${asset.name}${
          asset.health_rating === null ? "" : asset.health_rating
        }${
          asset.categories
            ? asset.categories
                .map((category: CategoryDto) => category.name)
                .join(", ")
            : ""
        }${asset.manufacturer || ""}${asset.model || ""}${
          asset.location?.title || ""
        }${asset.teams ? asset.teams.map((team) => team.name).join(", ") : ""}${
          asset.last_received
        }${asset.cached_active_status}`
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectAssetsFilteredData = createSelector(
  selectSearchAssets,
  selectTagsFilters,
  selectFilteredAssets,
  (searchValue, tagsFilters, assets): AssetDto[] => {
    let data = assets.map((asset) => ({
      ...asset,
    }));

    if (tagsFilters.length) {
      data = filterByTags(data, tagsFilters);
    }

    if (searchValue) {
      data.filter((asset) =>
        `${asset.name}${
          asset.health_rating === null ? "" : asset.health_rating
        }${
          asset.categories
            ? asset.categories
                .map((category: CategoryDto) => category.name)
                .join(", ")
            : ""
        }${asset.manufacturer || ""}${asset.model || ""}${
          asset.location?.title || ""
        }${asset.teams ? asset.teams.map((team) => team.name).join(", ") : ""}${
          asset.last_received
        }${asset.cached_active_status}`
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    }
    return data;
  }
);

export const selectAssetsFilterNumbers = createSelector(
  selectAssetsFilterTabData,
  (assets): AssetFilterNumbers => {
    const data = assets.map((asset) => ({
      ...asset,
    }));
    const numberOfOnStatus = data.filter(
      (asset) => asset.cached_active_status === AssetStatus.On
    ).length;
    const numberOfOffStatus = data.filter(
      (asset) => asset.cached_active_status === AssetStatus.Off
    ).length;
    const numberOfNAStatus = data.filter(
      (asset) => asset.cached_active_status === AssetStatus.NA
    ).length;
    const numberOfTotal = data.length;
    const numberOfLowHealth = data.filter(
      (asset) =>
        asset.health_is_enabled &&
        asset.health_rating !== null &&
        asset.cached_active_status !== AssetStatus.Off &&
        asset.is_enabled &&
        asset.health_rating <= 2.5
    ).length;
    const numberOfAlarms = data.filter((asset) => asset.alert).length;

    return {
      numberOfOnStatus,
      numberOfOffStatus,
      numberOfNAStatus,
      numberOfTotal,
      numberOfLowHealth,
      numberOfAlarms,
    };
  }
);

export const selectDashboardAssetsFilterNumbers = createSelector(
  selectAssetsData,
  (assets): AssetFilterNumbers => {
    const data = assets.map((asset) => ({
      ...asset,
    }));
    const numberOfOnStatus = data.filter(
      (asset) => asset.cached_active_status === AssetStatus.On
    ).length;
    const numberOfOffStatus = data.filter(
      (asset) => asset.cached_active_status === AssetStatus.Off
    ).length;
    const numberOfNAStatus = data.filter(
      (asset) => asset.cached_active_status === AssetStatus.NA
    ).length;
    const numberOfTotal = data.length;
    const numberOfLowHealth = data.filter(
      (asset) =>
        asset.health_is_enabled &&
        asset.health_rating !== null &&
        asset.cached_active_status !== AssetStatus.Off &&
        asset.is_enabled &&
        asset.health_rating <= 2.5
    ).length;
    const numberOfAlarms = data.filter((asset) => asset.alert).length;

    return {
      numberOfOnStatus,
      numberOfOffStatus,
      numberOfNAStatus,
      numberOfTotal,
      numberOfLowHealth,
      numberOfAlarms,
    };
  }
);

export const selectIsAssetsStatusPending = createSelector(
  selectAssetsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectIsAssetsTableStatusPending = createSelector(
  selectAssetsStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCategoriesState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<CategoryDto[]> => asset.categories
);

export const selectCategories = createSelector(
  selectCategoriesState,
  (categoriesData: AsyncStateModel<CategoryDto[]>): CategoryDto[] =>
    categoriesData.data
);

export const selectCategoriesStatus = createSelector(
  selectCategoriesState,
  (categoriesData): AsyncStatus => categoriesData.status
);

export const selectIsCategoriesStatusPending = createSelector(
  selectCategoriesStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectTagsState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetTagDto[]> => asset.tags
);

export const selectTags = createSelector(
  selectTagsState,
  (tagsData: AsyncStateModel<AssetTagDto[]>): AssetTagDto[] => tagsData.data
);

export const selectTagsStatus = createSelector(
  selectTagsState,
  (tagsData): AsyncStatus => tagsData.status
);

export const selectIsTagsStatusPending = createSelector(
  selectTagsStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectRuntimeState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetRuntimeDto[]> => asset.runtime
);

export const selectRuntimeData = createSelector(
  selectRuntimeState,
  (runtime: AsyncStateModel<AssetRuntimeDto[]>): AssetRuntimeDto[] =>
    runtime.data
);

export const selectRuntimeDataState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetRuntimeDto[]> => asset.runtime
);

export const selectRuntimeDataStatus = createSelector(
  selectRuntimeDataState,
  (runtime): AsyncStatus => runtime.status
);

export const selectRuntimeDataStatusPending = createSelector(
  selectRuntimeDataStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectAssetHealthState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<number> => asset.health
);

export const selectAssetHealthData = createSelector(
  selectAssetHealthState,
  (healthData: AsyncStateModel<number>): number => healthData.data
);

export const selectAssetHealthStatus = createSelector(
  selectAssetHealthState,
  (health): AsyncStatus => health.status
);

export const selectIsAssetHealtStatusPending = createSelector(
  selectAssetHealthStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectIsAssetHealthEnabledData = createSelector(
  selectShowAssetState,
  (asset: AsyncStateModel<AssetDto | null>): boolean =>
    asset?.data?.health_is_enabled || false
);

export const selectAssetSensorsState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<SensorDto[]> => asset.sensors
);

export const selectAssetSensorsData = createSelector(
  selectAssetSensorsState,
  (sensors: AsyncStateModel<SensorDto[]>): SensorDto[] => sensors.data
);

export const selectAssetSensorsStatus = createSelector(
  selectAssetSensorsState,
  (sensors): AsyncStatus => sensors.status
);

export const selectIsAssetSensorsStatusPending = createSelector(
  selectAssetSensorsStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectAssetHealthGraphDataState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<ChannelGraphData[]> =>
    asset.assetHealthData
);

export const selectAssetHealthGraphDataStatus = createSelector(
  selectAssetHealthGraphDataState,
  (assetHealthData): AsyncStatus => assetHealthData.status
);

export const selectAssetHealthGraphDataStatusPending = createSelector(
  selectAssetHealthGraphDataStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectAssetHealthGraphDataData = createSelector(
  selectAssetHealthGraphDataState,
  (assetHealthData: AsyncStateModel<ChannelGraphData[]>): ChannelGraphData[] =>
    assetHealthData.data.map((channelData) => {
      const chartData = channelData.data.map((value, index) => ({
        data: value,
        xdata: channelData.xdata[index],
      }));

      return { ...channelData, chartData };
    })
);

export const selectAssetSensorGraphDataState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<ChannelGraphData[]> =>
    asset.assetSensorReadingsData
);

export const selectAssetSensorGraphDataStatus = createSelector(
  selectAssetSensorGraphDataState,
  (assetHealthData): AsyncStatus => assetHealthData.status
);

export const selectAssetSensorGraphDataStatusPending = createSelector(
  selectAssetSensorGraphDataStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectAssetSensorGraphDataData = createSelector(
  selectAssetSensorGraphDataState,
  (
    assetSensorReadingsData: AsyncStateModel<ChannelGraphData[]>
  ): ChannelGraphData[] =>
    assetSensorReadingsData.data.map((channelData) => {
      const chartData = channelData.data.map((value, index) => ({
        data: value,
        xdata: channelData.xdata[index],
        alertType: channelData.alertType,
        units: channelData.units,
        warningPointMax: channelData.warningPointMax,
        warningPointMin: channelData.warningPointMin,
      }));

      return { ...channelData, chartData };
    })
);

export const selectAssetUsageDataState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetUsageDto[]> => asset.usage
);

export const selectAssetUsageDataStatus = createSelector(
  selectAssetUsageDataState,
  (usage): AsyncStatus => usage.status
);

export const selectAssetUsageDataStatusPending = createSelector(
  selectAssetUsageDataStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectAssetUsageData = createSelector(
  selectAssetUsageDataState,
  (usage: AsyncStateModel<AssetUsageDto[]>): AssetUsageDto[] => usage.data
);

export const selectAssetDyanmoRollupDataState = createSelector(
  selectAssetState,
  (asset: AssetState): AsyncStateModel<AssetDynamoRollupDto[]> =>
    asset.dynamoHealthRollups
);

export const selectAssetDyanmoRollupDataStatus = createSelector(
  selectAssetDyanmoRollupDataState,
  (dynamoHealthRollups): AsyncStatus => dynamoHealthRollups.status
);

export const selectAssetDyanmoRollupDataStatusPending = createSelector(
  selectAssetDyanmoRollupDataStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectAssetDyanmoRollupData = createSelector(
  selectAssetDyanmoRollupDataState,
  (
    dynamoHealthRollups: AsyncStateModel<AssetDynamoRollupDto[]>
  ): AssetDynamoRollupDto[] => dynamoHealthRollups.data
);
export const selectAssetNotes = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<AssetNoteDto[]> => asset.notes
);

export const selectAssetNotesData = createSelector(
  selectAssetNotes,
  (notes): AssetNoteModel[] =>
    notes.data.map((note) => ({ ...note, pinned: !!note.pinned }))
);

export const selectAssetNotesStatus = createSelector(
  selectAssetNotes,
  (notes): AsyncStatus => notes.status
);

export const selectIsAssetNotesStatusPending = createSelector(
  selectAssetNotesStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectIsAssetNotesStatusFulfilled = createSelector(
  selectAssetNotesStatus,
  (status): boolean => status === AsyncStatus.Fulfilled
);

export const selectDeleteAssetNote = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<null> => asset.deleteAssetNote
);

export const selectAssetNoteStatus = createSelector(
  selectDeleteAssetNote,
  (notes): AsyncStatus => notes.status
);

export const selectIsDeleteAssetNoteStatusPending = createSelector(
  selectAssetNoteStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectPinAssetNote = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<null> => asset.pinAssetNote
);

export const selectPinAssetNoteStatus = createSelector(
  selectPinAssetNote,
  (pinAssetNote): AsyncStatus => pinAssetNote.status
);

export const selectIsPinAssetNoteStatusPending = createSelector(
  selectPinAssetNoteStatus,
  (status): boolean => status === AsyncStatus.Pending
);
export const selectAssetAttachments = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<AssetAttachmentDto[]> => asset.attachments
);

export const selectAssetAttachmentsData = createSelector(
  selectAssetAttachments,
  (attachments): AssetAttachmentDto[] => attachments.data
);

export const selectAssetAttachmentsStatus = createSelector(
  selectAssetAttachments,
  (attachments): AsyncStatus => attachments.status
);

export const selectIsAssetAttachmentsStatusPending = createSelector(
  selectAssetAttachmentsStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectIsAssetAttachmentsStatusFulfilled = createSelector(
  selectAssetAttachmentsStatus,
  (status): boolean => status === AsyncStatus.Fulfilled
);

export const selectDeleteAssetAttachment = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<null> => asset.deleteAssetAttachment
);

export const selectDeleteAttachmentStatus = createSelector(
  selectDeleteAssetAttachment,
  (deleteAssetAttachment): AsyncStatus => deleteAssetAttachment.status
);

export const selectIsDeleteAssetAttachmentStatusPending = createSelector(
  selectDeleteAttachmentStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectFetchAssetSensors = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<SensorTableDto[]> => asset.fetchAssetSensors
);

export const selectFetchAssetSensorsData = createSelector(
  selectFetchAssetSensors,
  (fetchAssetSensors): SensorTableDto[] => fetchAssetSensors.data
);

export const selectFetchAssetSensorsStatus = createSelector(
  selectFetchAssetSensors,
  (fetchAssetSensors): AsyncStatus => fetchAssetSensors.status
);

export const selectIsFetchAssetSensorsStatusPending = createSelector(
  selectFetchAssetSensorsStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectFetchAssetAlerts = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<AssetAlertsDto[]> => asset.fetchAssetAlerts
);

export const selectFetchAssetAlertsData = createSelector(
  selectFetchAssetAlerts,
  (fetchAssetAlerts): AssetAlertsDto[] => fetchAssetAlerts.data
);

export const selectFetchAssetAlertsStatus = createSelector(
  selectFetchAssetAlerts,
  (fetchAssetSensors): AsyncStatus => fetchAssetSensors.status
);

export const selectIsFetchAssetAlertsStatusPending = createSelector(
  selectFetchAssetAlertsStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectAddAssetToBookmark = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<null> => asset.addAssetToBookmark
);

export const selectAddAssetToBookmarkStatus = createSelector(
  selectAddAssetToBookmark,
  (addAssetToBookmark): AsyncStatus => addAssetToBookmark.status
);

export const selectIsAddAssetToBookmarkStatusPending = createSelector(
  selectAddAssetToBookmarkStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectRemoveAssetFromBookmark = createSelector(
  selectAssetState,
  (asset): AsyncStateModel<null> => asset.removeAssetFromBookmark
);

export const selectRemoveAssetFromBookmarkStatus = createSelector(
  selectRemoveAssetFromBookmark,
  (removeAssetFromBookmark): AsyncStatus => removeAssetFromBookmark.status
);

export const selectIsRemoveAssetFromBookmarkStatusPending = createSelector(
  selectRemoveAssetFromBookmarkStatus,
  (status): boolean => status === AsyncStatus.Pending
);
