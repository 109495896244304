import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";

import { AssetDto } from "../../../asset/api/asset.api.dto";
import { getAssetImage } from "../../../asset/util/get-asset-image";
import {
  ImageWrapper,
  StyledLabel,
  StyledDetailsBox,
  StyledContainer,
} from "./AssetBox.styles";
import { setMaxTextLength } from "../../../app/util/max-text-length";

interface IAssetBox {
  asset: AssetDto;
}

const MAX_CHAR_LENGTH = 18;

export const AssetBox: FC<IAssetBox> = ({ asset }) => (
  <StyledContainer
    display="flex"
    flex="1"
    alignItems="center"
    flexDirection="column"
    mb={2}
    mt={1}
  >
    <ImageWrapper>{getAssetImage(asset.live_graph_svg)}</ImageWrapper>
    <StyledDetailsBox p={2} m={2}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="subtitle1">Category</Typography>
        <StyledLabel>
          {asset.category
            ? setMaxTextLength(asset.category.name, MAX_CHAR_LENGTH)
            : ""}
        </StyledLabel>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="subtitle1">Manufacturer</Typography>
        <StyledLabel>
          {asset.manufacturer
            ? setMaxTextLength(asset.manufacturer, MAX_CHAR_LENGTH)
            : ""}
        </StyledLabel>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="subtitle1">Model</Typography>
        <StyledLabel>
          {asset.model ? setMaxTextLength(asset.model, MAX_CHAR_LENGTH) : ""}
        </StyledLabel>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="subtitle1">Serial Number</Typography>
        <StyledLabel>
          {asset.serial_number
            ? setMaxTextLength(asset.serial_number, MAX_CHAR_LENGTH)
            : ""}
        </StyledLabel>
      </Box>
    </StyledDetailsBox>
  </StyledContainer>
);
