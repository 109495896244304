import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@material-ui/core";

import {
  StyledCard,
  StyledIcon,
  StyledTypography,
} from "./ResetPasswordSuccess.style";
import { LOGIN } from "../../../app/const/routes";

export const ResetPasswordSuccess: FC = () => (
  <StyledCard>
    <Box mb={2}>
      <StyledIcon color="primary" />
      <Typography color="textPrimary" variant="h2">
        Password changed
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="body1">
        Your password has been changed
      </Typography>
    </Box>
    <Typography color="textSecondary" gutterBottom variant="body1">
      Now you can sign in to the platform with your new credentials.
    </Typography>
    <StyledTypography color="textSecondary" variant="body1">
      <Link component={RouterLink} to={LOGIN}>
        Sign in
      </Link>
    </StyledTypography>
  </StyledCard>
);
