import styled from "styled-components";

import { Typography, CardContent } from "@material-ui/core";
import { MAIN } from "../../../app/theme/colors";

export const StyledCardContent = styled(CardContent)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-bottom: 0;
`;
export const IconRow = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.span`
  display: inline-block;
  margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
`;

export const OutOfOfficeTitle = styled(Title)``;

export const Subtitle = styled(Typography)`
  font-size: 14px;
`;

export const ButtonsWrapper = styled.div`
  border-top: 1px solid #e3e3e9;
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  margin-top: ${({ theme }) => `${theme.spacing(14)}px`};
  justify-content: flex-end;
  display: flex;

  button:last-of-type {
    margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SelectsTypography = styled(Typography)`
  margin: 0 ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const SelectsDivider = styled.div`
  margin: 0 ${({ theme }) => `${theme.spacing(2)}px`};
  height: 42px;
  width: 1px;
  background-color: #aaa;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 0;
  }
`;

export const PickersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up("md")} {
    & > div:last-of-type {
      margin-left: ${({ theme }) => `${theme.spacing(3)}px`};
    }
  }
`;

export const WeekdaysWrapper = styled.div`
  margin-left: ${({ theme }) => `${theme.spacing(9)}px`};
`;

export const PickerWrapper = styled.div`
  width: 270px;

  input {
    color: #9e9e9e;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const DeleteIconWrapper = styled.span`
  display: inline-block;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;

export const WeekdayWrapper = styled.div`
  width: 100px;
  margin-right: ${({ theme }) => `${theme.spacing(4)}px`};

  p {
    text-transform: capitalize;
  }
`;

export const StyledLearnMoreText = styled.button`
  background: none;
  border: none;
  color: ${MAIN};
  padding: 0;
`;
