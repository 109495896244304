import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";
import {
  TaskCommentDto,
  TaskDto,
  TaskType,
} from "../../api/workorders.api.dto";
import { ITaskForm, TaskModelForm } from "./TaskModalForm";
import { UserDto } from "../../../users/api/users.api.dto";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { AssetDto } from "../../../asset/api/asset.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { TaskStatus } from "../../enum/workorders.enum";

interface ITaskModal {
  name: string;
  type?: TaskType;
  onSubmit: (
    task: ITaskForm,
    start?: TaskStatus,
    end?: TaskStatus,
    id?: number
  ) => void;
  onClose: () => void;
  onDelete?: () => void;
  task?: TaskDto;
  currentUser: UserDto;
  onCommentAdd: (taskCommentDto: TaskCommentDto) => Promise<any>;
  onCommentDelete: (taskCommentDto: TaskCommentDto) => Promise<any>;
  isPending: boolean;
  teams: TeamDto[];
  users: UserDto[];
  assets: AssetDto[];
  client: ClientDto[];
}

export const TaskModal: FC<ITaskModal> = ({
  name,
  type,
  onSubmit,
  onClose,
  onDelete,
  task,
  currentUser,
  onCommentAdd,
  onCommentDelete,
  isPending,
  teams,
  users,
  assets,
  client,
}) => {
  const ModalContent = (
    pending?: boolean,
    params?: { task?: TaskDto; assetId?: number }
  ) => {
    return (
      <TaskModelForm
        onCommentAdd={onCommentAdd}
        onCommentDelete={onCommentDelete}
        type={type}
        isPending={pending}
        onSubmit={onSubmit}
        onClose={onClose}
        onDelete={onDelete}
        task={params?.task ?? task}
        currentUser={currentUser}
        teams={teams}
        users={users}
        assets={assets}
        selectedAssetId={params?.assetId}
        client={client}
      />
    );
  };

  const getModalHeader = () => (
    <Typography variant="h2">Create Work Order</Typography>
  );

  return (
    <ConnectedModal
      name={name}
      size="md"
      Content={(pending, params?: { task?: TaskDto; assetId?: number }) =>
        ModalContent(pending || isPending, params)
      }
      onClose={onClose}
    />
  );
};
