import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { NotificationDto } from "../api/apiNotifications.api.dto";

export interface ApiNotificationsState {
  apiNotifications: AsyncStateModel<NotificationDto[]>;
}

export const apiNotificationState: ApiNotificationsState = {
  apiNotifications: {
    status: AsyncStatus.Void,
    data: [],
  },
};
