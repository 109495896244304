import React, { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";
import {
  StyledCard,
  StyledNAHealth,
  StyledBadHealth,
  StyledFairHealth,
  StyledGoodHealth,
  CenteredCell,
  RedText,
} from "../../../app/component/Table/Table.style";
import { AssetDto, AssetTagDto } from "../../api/asset.api.dto";
import { AssetHealthThresholds, AssetStatus } from "../../enum/assets.enum";
import { ASSETS } from "../../../app/const/routes";
import RedAlarmIcon from "../../../../assets/redAlarm.svg";
import { AssetModal } from "../AssetModal/AssetModal";
import { EDIT_ASSET_MODAL } from "../../../app/const/modals";
import { CreateEditAssetModel } from "../../model/asset.model";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { CategoryDto } from "../../../category/api/category.api.dto";
import { LocationDto } from "../../../location/api/locations.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { DeviceListDto } from "../../../devices/api/devices.api.dto";
import { ConditionalTooltipWrapper } from "../../../app/component/Table/ConditionalTooltipWrapper/ConditionalTooltipWrapper";
import { setMaxTextLength } from "../../../app/util/max-text-length";

interface IAssetsTable {
  assets: AssetDto[];
  isTablePending: boolean;
  isModalPending: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  teams: TeamDto[];
  tags: AssetTagDto[];
  categories: CategoryDto[];
  locations: LocationDto[];
  clients: ClientDto[];
  devices: DeviceListDto[];
  onEditAsset: (editClientModel: CreateEditAssetModel, id?: number) => void;
  onDeleteAsset: (id: number) => void;
  isInitialDataFetched: boolean;
}

export const AssetsTable: FC<IAssetsTable> = ({
  assets,
  isTablePending,
  isModalPending,
  onCloseModal,
  teams,
  tags,
  categories,
  locations,
  clients,
  devices,
  onEditAsset,
  onDeleteAsset,
  isInitialDataFetched,
}) => {
  const [selectedAsset, setSelectedAsset] = useState<AssetDto | undefined>(
    undefined
  );
  const renderHealthRating = (
    health: number | null,
    healthIsEnabled: boolean | null
  ) => {
    if (!healthIsEnabled) {
      return <StyledNAHealth>N/A</StyledNAHealth>;
    }
    if (health === null) {
      return <StyledNAHealth>-</StyledNAHealth>;
    }
    if (health <= AssetHealthThresholds.bad) {
      return (
        <StyledBadHealth>
          {health === 0 ? health.toFixed() : health.toFixed(1)}
        </StyledBadHealth>
      );
    }
    if (health <= AssetHealthThresholds.fair) {
      return <StyledFairHealth>{health.toFixed(1)}</StyledFairHealth>;
    }
    return (
      <StyledGoodHealth>
        {health === 10 ? health.toFixed() : health.toFixed(1)}
      </StyledGoodHealth>
    );
  };

  const closeModal = () => {
    setSelectedAsset(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    {
      id: "alert",
      Header: () => <CenteredCell>Alarm</CenteredCell>,
      accessor: "alert",
      Cell: ({ row: { original } }: { row: { original: AssetDto } }) =>
        original.alert ? (
          <CenteredCell>
            <img src={RedAlarmIcon} alt="Alarm" />
          </CenteredCell>
        ) : (
          ""
        ),
    },
    {
      id: "name",
      Header: "Asset",
      accessor: "name",
      Cell: ({ row: { original } }: { row: { original: AssetDto } }) => (
        <RouterLink to={`${ASSETS}/${original.id}/realtime`}>
          <ConditionalTooltipWrapper
            condition={original.name.length > 15}
            text={original.name}
          >
            <Typography component="span" color="primary" variant="body2">
              {setMaxTextLength(original.name, 15)}
            </Typography>
          </ConditionalTooltipWrapper>
        </RouterLink>
      ),
    },
    {
      id: "health_rating",
      Header: () => <CenteredCell>Health</CenteredCell>,
      accessor: "health",
      Cell: ({ row: { original } }: { row: { original: AssetDto } }) => (
        <CenteredCell>
          {renderHealthRating(
            original.health_rating,
            original.health_is_enabled
          )}
        </CenteredCell>
      ),
    },
    {
      Header: "Category",
      accessor: "categories",
      Cell: (
        { row: { original } }: { row: { original: any } } // TODO: quick fix. Update AssetDto model to handle mapped categories
      ) =>
        original.categories !== null ? (
          <ConditionalTooltipWrapper
            condition={original.categories.length > 15}
            text={original.categories}
          >
            <Typography component="span" variant="body2">
              {setMaxTextLength(original.categories, 15)}
            </Typography>
          </ConditionalTooltipWrapper>
        ) : (
          ""
        ),
    },
    {
      id: "manufacturer",
      Header: "Manufacturer",
      accessor: "manufacturer",
      Cell: ({ row: { original } }: { row: { original: AssetDto } }) =>
        original.manufacturer && (
          <ConditionalTooltipWrapper
            condition={original.manufacturer.length > 15}
            text={original.manufacturer}
          >
            <Typography component="span" variant="body2">
              {setMaxTextLength(original.manufacturer, 15)}
            </Typography>
          </ConditionalTooltipWrapper>
        ),
    },
    {
      Header: "Model",
      accessor: "model",
      Cell: ({ row: { original } }: { row: { original: AssetDto } }) =>
        original.model && (
          <ConditionalTooltipWrapper
            condition={original.model.length > 15}
            text={original.model}
          >
            <Typography component="span" variant="body2">
              {setMaxTextLength(original.model, 15)}
            </Typography>
          </ConditionalTooltipWrapper>
        ),
    },
    {
      Header: "Location",
      accessor: "location",
      // TODO: quick fix, update model to handle new type
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <ConditionalTooltipWrapper
          condition={original.location.length > 10}
          text={original.location}
        >
          <Typography component="span" variant="body2">
            {setMaxTextLength(original.location, 10)}
          </Typography>
        </ConditionalTooltipWrapper>
      ),
    },
    {
      Header: "Team",
      accessor: "teams",
      // TODO: quick fix, update model to handle new type
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <ConditionalTooltipWrapper
          condition={original.teams.length > 10}
          text={original.teams}
        >
          <Typography component="span" variant="body2">
            {setMaxTextLength(original.teams, 10)}
          </Typography>
        </ConditionalTooltipWrapper>
      ),
    },
    {
      id: "last_received",
      Header: "Last Reading",
      accessor: "last_received",
    },
    {
      Header: "State",
      accessor: "cached_active_status",
      Cell: ({ row: { original } }: { row: { original: AssetDto } }) => {
        if (original.cached_active_status === AssetStatus.Off) {
          return <RedText>{original.cached_active_status}</RedText>;
        }
        return original.cached_active_status;
      },
    },
  ];
  return (
    <>
      <StyledCard>
        <Table
          loading={!isInitialDataFetched && isTablePending}
          columns={columns}
          data={assets}
          hover
          sorting={{ id: "name", desc: false }}
          noActionButton
        />
      </StyledCard>
      <AssetModal
        name={EDIT_ASSET_MODAL}
        asset={selectedAsset}
        onClose={closeModal}
        onSubmit={onEditAsset}
        onDelete={onDeleteAsset}
        tags={tags}
        locations={locations}
        teams={teams}
        categories={categories}
        clients={clients}
        devices={devices}
        isPending={isModalPending}
      />
    </>
  );
};
