import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import { fetchTasks as fetchTasksAction } from "../store/workOrders.action";
import { SelectClient } from "../component/SelectClient/SelectClient";
import { fetchClients } from "../../clients/store/clients.action";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import {
  selectSelectedClientId,
  selectIsSelectedClientIdPending,
} from "../store/workOrders.selector";
import { setSelectedClientId as setStoreSelectedClientId } from "../store/workOrders.slice";

export const SelectClientContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedClientId, setSelectedClientId] = useState<
    number | null | undefined
  >(undefined);
  const clients = useSelector(selectClientsData);
  const storeSelectedClientId = useSelector(selectSelectedClientId);
  const isClientsStatusPending = useSelector(selectIsClientsStatusPending);
  const isSelectedClientIdPending = useSelector(
    selectIsSelectedClientIdPending
  );
  const { search } = useLocation();
  const history = useHistory();
  const { clientId }: { clientId?: string } = parse(search);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    if (selectedClientId) {
      dispatch(setStoreSelectedClientId(selectedClientId));
      dispatch(fetchTasksAction({ clientId: selectedClientId }));
    }
  }, [dispatch, selectedClientId]);

  if (selectedClientId === undefined && clients.length > 0) {
    if (clients.length === 1) {
      setSelectedClientId(clients[0].id);
    } else {
      const selectedClient = clients.find(
        (client) => client.id === storeSelectedClientId
      );
      setSelectedClientId(selectedClient?.id || null);
    }
  }

  useEffect(() => {
    if (clientId) {
      const queryClient = clients.find(
        (client) => Number(client.id) === Number(clientId)
      );
      if (queryClient) {
        dispatch(fetchTasksAction({ clientId: queryClient.id }));
        setSelectedClientId(queryClient.id);
        const queryParams = new URLSearchParams(search);
        if (queryParams.has("clientId")) {
          queryParams.delete("clientId");
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [dispatch, clientId, clients, search, history]);

  const handleClientIdChange = (id: number) => {
    setSelectedClientId(id);
  };

  return (
    <SelectClient
      clients={clients}
      selectedClientId={selectedClientId}
      onSelectClientId={handleClientIdChange}
      pending={isClientsStatusPending || isSelectedClientIdPending}
    />
  );
};
