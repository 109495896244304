import React from "react";
import { useSelector } from "react-redux";
import { UserRole } from "../../enum/UserRole";
import { useShowForRole } from "../../hooks/useShowForRole";
import {
  SubTaskFormModel,
  TaskDto,
} from "../../../workorders/api/workorders.api.dto";
import { selectProfileData } from "../../../auth/store/auth.selector";

export interface IConnectedHideForRoleSubTask {
  roles: Array<UserRole>;
  task?: TaskDto | undefined;
  subtask?: SubTaskFormModel | undefined;
  children: React.ReactNode;
}

export const ConnectedHideForRoleSubTask = (
  props: IConnectedHideForRoleSubTask
) => {
  const { roles, children, task, subtask } = props;
  const profileData = useSelector(selectProfileData);
  let show = true;
  const isNotRestricted = useShowForRole(roles);
  if (task?.assigned_user?.id === profileData?.user?.id) {
    show = isNotRestricted;
  } else if (isNotRestricted) {
    const subTasks = task?.sub_tasks
      .map((sub_task) => {
        return { id: sub_task.id, assignee: sub_task.assigned_user?.id };
      })
      .filter((sub_task) => sub_task.assignee === profileData?.user?.id);
    if (subTasks && subTasks.length > 0) {
      show = true;
    } else {
      show = false;
    }
  }
  if (subtask?.assigned_user?.id === profileData?.user?.id) {
    show = isNotRestricted;
  }

  return show ? <>{children}</> : null;
};
