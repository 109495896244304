import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import { IconButton, Switch, TextField } from "@material-ui/core";
import MuiDialogContent, {
  DialogContentProps,
} from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    display: initial;
    background-color: #f8f8f8;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export const StyledDialogTiny = styled(StyledDialog)`
  .MuiDialog-paperScrollPaper {
    max-width: 338px;
  }
  .MuiTypography-h6 {
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 18.75px;
  }
  .MuiTypography-body1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: #000000;
  }
  .MuiTypography-h6 {
    color: #000000;
  }
  button {
    font-weight: 500;
  }
`;

export const StyledModalContent = styled((props: DialogContentProps) => {
  const ref = useRef<HTMLElement | undefined>(undefined);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [ref]);

  return <MuiDialogContent ref={ref} {...props} />;
})<{
  noflex?: boolean;
}>`
  .MuiSvgIcon-colorAction {
    color: #5f6368;
  }
  #category,
  #live_graph_svg,
  #location {
    padding: 15px 4px 4px;
  }
  padding-left: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-right: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  ${(props) => !props.noflex && `display: flex;justify-content: space-between;`}
`;

export const StyledIconButton = styled(IconButton)<{
  nospacing?: boolean;
  transparent?: boolean;
}>`
  width: 36px;
  height: 36px;
  padding: 1px;
  border-radius: 4px;
  ${(props) => !props.nospacing && "margin-left: 16px"};
  background-color: ${({ theme, transparent }) =>
    transparent
      ? "transparent"
      : theme.overrides.MuiButton.root.backgroundColor}};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => `${theme.overrides.MuiButton.root.color}`};
`;

export const StyledEditIcon = styled(EditIcon)`
  color: ${({ theme }) => `${theme.overrides.MuiButton.root.color}`};
`;

export const StyledDialogTitleContainer = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextFieldCharCounter = styled(TextField)`
  .MuiFormHelperText-root:not(.Mui-error) {
    text-align: right;
  }
`;

export const StyledSwitch = styled(Switch)`
  float: right;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    float: none;
  }
`;
