import * as yup from "yup";

export const DESCRIPTION_CHAR_LIMIT = 500;

export const locationModalFormValidation = yup.object().shape({
  client_id: yup
    .number()
    .min(1, "Client is required")
    .required("Client is required"),
  description: yup.string().max(DESCRIPTION_CHAR_LIMIT).optional(),
  title: yup.string().required("Title is required"),
});
