import React, { FC } from "react";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import {
  FullHeightContainer,
  StyledPage,
} from "../component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../component/DashboardLayout/PageHeader/PageHeader";
import { DashboardAssetHealthGaugeContainer } from "../../dashboard/container/DashboardAssetHealthGaugeContainer";
import { DashboardAssetsFilterContainer } from "../../dashboard/container/DashboardAssetsFilterContainer";
import { DashboardFavoritesContainer } from "../../dashboard/container/DashboardFavoritesContainer";
import { RecentActivitiesTableContainer } from "../../dashboard/container/RecentActivitiesTableContainer";
import { AlertsTableContainer } from "../../dashboard/container/AlertsTableContainer";
import { selectProfileClientId } from "../../auth/store/auth.selector";
import { AppDispatch } from "../store";
import { DASHBOARD_CLIENTS } from "../const/routes";

interface RouteParams {
  clientId: string;
}

export const DashboardView: FC = () => {
  const profileClientId = useSelector(selectProfileClientId);
  const { clientId } = useParams<RouteParams>();
  const dispatch = useDispatch<AppDispatch>();

  const finalClientId = profileClientId || parseInt(clientId, 10);

  if (!finalClientId) {
    dispatch(push(DASHBOARD_CLIENTS));
  }

  return (
    <StyledPage title="Dashboard" minHeight>
      <FullHeightContainer maxWidth={false}>
        <PageHeaderContainer>
          <PageHeader title="Dashboard" />
        </PageHeaderContainer>

        {finalClientId ? (
          <Grid container direction="column">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardAssetHealthGaugeContainer clientId={finalClientId} />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <AlertsTableContainer clientId={finalClientId} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box mb={2} />
              <DashboardAssetsFilterContainer clientId={finalClientId} />
            </Grid>
            <Grid item xs={12}>
              <DashboardFavoritesContainer />
            </Grid>
            <Grid item xs={12}>
              <Box mb={2} />
              <RecentActivitiesTableContainer clientId={finalClientId} />
            </Grid>
          </Grid>
        ) : null}
      </FullHeightContainer>
    </StyledPage>
  );
};
