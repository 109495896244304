import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AssetHealthGauge } from "../component/AssetHealthGauge/AssetHealthGauge";
import { AppDispatch } from "../../app/store";
import { fetchHealth as fetchHealthAction } from "../store/asset.action";
import {
  selectAssetHealthData,
  selectIsAssetHealthEnabledData,
  selectIsAssetHealtStatusPending,
} from "../store/asset.selector";
import { useInterval } from "../../app/hooks/useInterval";

interface RouteParams {
  assetId: string;
}

export const AssetHealthGaugeContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { assetId } = useParams<RouteParams>();
  const assetHealth = useSelector(selectAssetHealthData);
  const assetIsHealthEnabled = useSelector(selectIsAssetHealthEnabledData);
  const pending = useSelector(selectIsAssetHealtStatusPending);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    dispatch(fetchHealthAction(Number(assetId)));
  }, [dispatch, assetId]);

  useInterval(() => {
    dispatch(fetchHealthAction(Number(assetId)));
    if (showLoader) {
      setShowLoader(false);
    }
  }, 117500);

  return (
    <AssetHealthGauge
      assetHealth={assetHealth}
      healthEnabled={assetIsHealthEnabled}
      pending={pending && showLoader}
      showLoader={showLoader}
    />
  );
};
