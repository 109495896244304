import { createAsyncThunk } from "@reduxjs/toolkit";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchPartners as fetchPartnersRequest,
  createPartner as createPartnerRequest,
  deletePartner as deletePartnerRequest,
  editPartner as editPartnerRequest,
} from "../api/partners.api";
import withError from "../../app/util/with-thunk-error";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_PARTNER_MODAL, EDIT_PARTNER_MODAL } from "../../app/const/modals";
import { mapCreateEditPartnerModelToDto } from "../api/partners.api.mapper";
import { PartnerDto } from "../api/partners.api.dto";
import { CreateEditPartnerModel } from "../model/partners.model";

export const fetchPartners = createAsyncThunk(
  "partners/fetchPartners",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<PartnerDto[]> => fetchPartnersRequest()
  )
);

export const createPartner = createAsyncThunk(
  "partners/createPartner",
  withError(
    async (partner: CreateEditPartnerModel, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_PARTNER_MODAL }));
      await createPartnerRequest(mapCreateEditPartnerModelToDto(partner));
      dispatch(closeModal({ name: ADD_PARTNER_MODAL }));
      dispatch(openSuccessNotification("Partner has been added"));
      dispatch(fetchPartners());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_PARTNER_MODAL }))
  )
);

export const deletePartner = createAsyncThunk(
  "partners/deletePartner",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_PARTNER_MODAL }));
      await deletePartnerRequest(id);
      dispatch(closeModal({ name: EDIT_PARTNER_MODAL }));
      dispatch(openSuccessNotification("Partner has been deleted"));
      dispatch(fetchPartners());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_PARTNER_MODAL }))
  )
);

export const editPartner = createAsyncThunk(
  "partners/editPartner",
  withError(
    async (
      {
        id,
        editPartnerModel,
      }: { id: number; editPartnerModel: CreateEditPartnerModel },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_PARTNER_MODAL }));
      await editPartnerRequest(
        id,
        mapCreateEditPartnerModelToDto(editPartnerModel)
      );
      dispatch(closeModal({ name: EDIT_PARTNER_MODAL }));
      dispatch(openSuccessNotification("Partner has been updated"));
      dispatch(fetchPartners());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_PARTNER_MODAL }))
  )
);
