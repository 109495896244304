import React, { FC } from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { TaskDto } from "../../api/workorders.api.dto";
import { StyledCard } from "./TaskCard.style";
import { TaskCardContent } from "../TaskCardContent/TaskCardContent";

interface ITaskCard {
  task: TaskDto;
  index: number;
  onCardClick: (task: TaskDto) => void;
}

export const TaskCard: FC<ITaskCard> = ({ task, index, onCardClick }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided: DraggableProvided) => (
        <StyledCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => onCardClick(task)}
        >
          <TaskCardContent task={task} />
        </StyledCard>
      )}
    </Draggable>
  );
};
