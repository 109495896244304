import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { TeamsTableContainer } from "../../container/TeamsTableContainer";
import { TeamsSearchContainer } from "../../container/TeamsSearchContainer";
import { AddTeamModalContainer } from "../../container/AddTeamModalContainer";

export const TeamsView: FC = () => (
  <StyledPage title="Teams">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Teams" />
        <ConnectedShowForPermissions
          feature="teams"
          permissions={{ allOf: ["read"] }}
        >
          <PageActionsWrapper>
            <TeamsSearchContainer />
            <AddTeamModalContainer />
          </PageActionsWrapper>
        </ConnectedShowForPermissions>
      </PageHeaderContainer>
      <ConnectedShowForPermissions
        feature="teams"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <TeamsTableContainer />
        </Box>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
