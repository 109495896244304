import * as Yup from "yup";

const emailMessages = {
  email: "Must be a valid email",
  required: "Email is required",
};

const passwordMessages = {
  required: "Password is required",
  repeatRequired: "Repeat password is required",
  min: "Password must have at least 8 characters",
  noMatch: "Password doesn't match",
};
const firstNameMessages = {
  required: "First name is required",
};
const lastNameMessages = {
  required: "Last name is required",
};

export const registerFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(emailMessages.email)
    .max(255)
    .required(emailMessages.required),
  password: Yup.string()
    .oneOf([Yup.ref("password"), null], passwordMessages.noMatch)
    .min(8, passwordMessages.min)
    .required(passwordMessages.required),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], passwordMessages.noMatch)
    .min(8, passwordMessages.min)
    .required(passwordMessages.repeatRequired),

  firstName: Yup.string().max(255).required(firstNameMessages.required),
  lastName: Yup.string().max(255).required(lastNameMessages.required),
});
