import React, { FC } from "react";
import { Tooltip } from "@material-ui/core";

interface IConditionalTooltipWrapper {
  condition: boolean;
  text: string;
  children: any;
}

export const ConditionalTooltipWrapper: FC<IConditionalTooltipWrapper> = ({
  condition,
  text,
  children,
}) =>
  condition ? (
    <Tooltip title={text} placement="top">
      {children}
    </Tooltip>
  ) : (
    children
  );
