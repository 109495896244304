import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { LocationsSearchContainer } from "../../container/LocationsSearchContainer";
import { LocationsTableContainer } from "../../container/LocationsTableContainer";
import { AddLocationModalContainer } from "../../container/AddLocationModalContainer";

export const LocationsView: FC = () => (
  <StyledPage title="Locations">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Locations" />
        <ConnectedShowForPermissions
          feature="locations"
          permissions={{ allOf: ["read"] }}
        >
          <PageActionsWrapper>
            <LocationsSearchContainer />
            <AddLocationModalContainer />
          </PageActionsWrapper>
        </ConnectedShowForPermissions>
      </PageHeaderContainer>
      <ConnectedShowForPermissions
        feature="locations"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <LocationsTableContainer />
        </Box>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
