import { createSlice } from "@reduxjs/toolkit";
import { apiNotificationState } from "./apiNotifications.store";
import {
  fetchApiNotifications,
  makeNotificationRead,
  makeNotificationsReadUntil,
} from "./apiNotifications.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "apiNotifications",
  initialState: apiNotificationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApiNotifications.pending, (state) => ({
      ...state,
      apiNotifications: {
        ...state.apiNotifications,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchApiNotifications.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          apiNotifications: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchApiNotifications.rejected, (state) => ({
      ...state,
      apiNotifications: {
        ...state.apiNotifications,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(makeNotificationRead.pending, (state) => {
      return {
        ...state,
        apiNotifications: {
          ...state.apiNotifications,
          status: AsyncStatus.Pending,
        },
      };
    });
    builder.addCase(makeNotificationRead.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          apiNotifications: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(makeNotificationRead.rejected, (state) => ({
      ...state,
      apiNotifications: {
        ...state.apiNotifications,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(makeNotificationsReadUntil.pending, (state) => ({
      ...state,
      apiNotifications: {
        ...state.apiNotifications,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(
      makeNotificationsReadUntil.fulfilled,
      (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            apiNotifications: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }

        return state;
      }
    );
    builder.addCase(makeNotificationsReadUntil.rejected, (state) => ({
      ...state,
      apiNotifications: {
        ...state.apiNotifications,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
