export enum AssetStatus {
  On = "On",
  Off = "Off",
  NA = "N/A",
}

export enum AssetHealthThresholds {
  bad = 2.5,
  fair = 5,
  good = 10,
}
