import { CreateEditLocationDto, LocationDto } from "./locations.api.dto";
import { authApiClient } from "../../app/api/authApi.client";
import { CreateEditLocationModel } from "../model/locations.model";

export const fetchLocations = async (): Promise<LocationDto[]> => {
  try {
    const response = await authApiClient.get("/locations");
    return response.data.locations;
  } catch (error) {
    throw error;
  }
};

export const createLocation = async (
  location: CreateEditLocationModel
): Promise<LocationDto> => {
  try {
    const response = await authApiClient.post("/locations", location);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteLocation = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/locations/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editLocation = async (
  id: number,
  editLocationDto: CreateEditLocationDto
): Promise<LocationDto> => {
  try {
    const response = await authApiClient.put(
      `/locations/${id}`,
      editLocationDto
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAlarmsAmount = async (id: number): Promise<number> => {
  try {
    const { data } = await authApiClient.get(`/location/${id}/alarms-amount`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchWarningsAmount = async (id: number): Promise<number> => {
  try {
    const { data } = await authApiClient.get(`/location/${id}/warnings-amount`);
    return data;
  } catch (error) {
    throw error;
  }
};
