import { AssetDto } from "../../asset/api/asset.api.dto";
import { TeamDto } from "../../teams/api/teams.api.dto";
import { UserDto } from "../../users/api/users.api.dto";
import { IUserTeamOption } from "../model/workorders.model";

export enum TaskStatus {
  Open = "open",
  InProgress = "in progress",
  Done = "closed",
}
export interface TasksList {
  [TaskStatus.Open]: TaskDto[];
  [TaskStatus.InProgress]: TaskDto[];
  [TaskStatus.Done]: TaskDto[];
}

export interface TaskDto {
  id: string;
  name: string;
  status: TaskStatus;
  priority: TaskPriority;
  asset: AssetDto[];
  start_date: string;
  finish_date: string;
  closed_date?: string;
  assigned_user: UserDto | null;
  assigned_team?: TeamDto | null;
  type: TaskType;
  comments: Comment[];
  created_by: UserDto;
  description: string;
  po_reference: string | null;
  sub_tasks: SubTaskDto[];
  watchers_users: UserDto[];
  watchers_teams: TeamDto[];
  work_order_number: string;
  file: {
    url: string;
    created_by: UserDto | null;
    created_at: string | null;
  } | null;
}

export interface Comment {
  id?: string;
  created_by?: UserDto;
  date?: string;
  text: string;
  created_at?: string;
}

export interface SubTaskDto {
  id?: number;
  name: string;
  description: string;
  assigned_user: UserDto | null;
  assigned_team: TeamDto | null;
  is_done: boolean;
}

export interface SubTaskFormModel {
  id?: number;
  formId?: string;
  name: string;
  description: string;
  assigned_user: IUserTeamOption | null;
  assigned_team: IUserTeamOption | null;
  is_done: boolean;
}

export interface WorkorderTask {
  name: string;
  type: TaskType;
  description?: string;
  asset?: AssetDto;
}

export enum TaskPriority {
  Lowest = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Highest = 4,
}

export enum TaskType {
  Manual = "manual",
}

export interface UpdateTaskStatusDto {
  status: TaskStatus;
  taskId: string;
}

export interface CreateTaskDto {
  id?: string;
  name: string;
  status: TaskStatus;
  priority: TaskPriority;
  asset: { id: number } | null;
  start_date: string;
  finish_date: string;
  closed_date?: string;
  assigned_user: { id: number } | null;
  assigned_team?: { id: number } | null;
  watchers_users: { id: number }[];
  watchers_team: { id: number }[];
  type?: TaskType;
  // comment?: Comment[];
  created_by?: UserDto;
  description: string;
  po_reference: string | null;
  sub_tasks: string[];
  work_order_number?: string;
  file?: File;
}

export interface TaskCommentDto {
  task: TaskDto;
  newComment: Comment;
  newSubTasks: SubTaskFormModel[];
}
