import { Breadcrumbs } from "@material-ui/core";
import styled from "styled-components";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 10px;

  svg {
    color: #5f6368;
    font-size: 12px;
  }

  li p {
    color: #5f6368;
    font-size: 12px;
  }
`;
