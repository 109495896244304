import React from "react";
import { ReactComponent as CarbonIcon } from "../../../assets/icons/carbon.svg";
import { ReactComponent as CurrentIcon } from "../../../assets/icons/current.svg";
import { ReactComponent as DcVoltageIcon } from "../../../assets/icons/dc_voltage.svg";
import { ReactComponent as DissolvedO2Icon } from "../../../assets/icons/dissolved_o2.svg";
import { ReactComponent as FlowIcon } from "../../../assets/icons/flow.svg";
import { ReactComponent as HumidityIcon } from "../../../assets/icons/humidity.svg";
import { ReactComponent as NoiseIcon } from "../../../assets/icons/noise.svg";
import { ReactComponent as PhIcon } from "../../../assets/icons/ph.svg";
import { ReactComponent as PressureIcon } from "../../../assets/icons/pressure.svg";
import { ReactComponent as ProximityIcon } from "../../../assets/icons/proximity.svg";
import { ReactComponent as SoilIcon } from "../../../assets/icons/soil.svg";
import { ReactComponent as TempIcon } from "../../../assets/icons/temp.svg";
import { ReactComponent as TorqueIcon } from "../../../assets/icons/torque.svg";
import { ReactComponent as VibrationIcon } from "../../../assets/icons/vibration.svg";
import { ReactComponent as WaterLevelIcon } from "../../../assets/icons/water_level.svg";
import { ReactComponent as WindSpeedIcon } from "../../../assets/icons/wind_speed.svg";
import { SensorTypeDto } from "../api/sensorTypes.api.dto";

export const getSensorTypeIcon = (sensorType: SensorTypeDto) => {
  switch (sensorType.name) {
    case "Carbon Monoxide":
      return <CarbonIcon />;
    case "Amperage AC":
    case "Amperage DC":
    case "Binary 0 or 1":
      return <CurrentIcon />;
    case "Volts DC":
      return <DcVoltageIcon />;
    case "Dissolved Oxygen":
      return <DissolvedO2Icon />;
    case "Flow":
    case "N2 Flow":
      return <FlowIcon />;
    case "Humidity":
    case "Moisture":
      return <HumidityIcon />;
    case "Noise":
      return <NoiseIcon />;
    case "PH":
      return <PhIcon />;
    case "Pressure":
    case "Back Pressure":
    case "Vacuum":
    case "Vacuum Pressure":
      return <PressureIcon />;
    case "Proximity":
    case "Position/Location Coordinates":
    case "Motion":
      return <ProximityIcon />;
    case "Soil":
      return <SoilIcon />;
    case "Temperature":
      return <TempIcon />;
    case "Torque":
      return <TorqueIcon />;
    case "Vibration":
      return <VibrationIcon />;
    case "Water Level":
    case "Liquid Level":
      return <WaterLevelIcon />;
    case "Wind Speed":
      return <WindSpeedIcon />;
    default:
      return <PressureIcon />;
  }
};
