import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createTeam as createTeamAction } from "../store/teams.action";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { TeamModal } from "../component/TeamModal/TeamModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditTeamDto } from "../api/teams.api.dto";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { ADD_TEAM_MODAL } from "../../app/const/modals";
import { selectIsTeamsTableStatusPending } from "../store/teams.selector";
import { selectProfileClientId } from "../../auth/store/auth.selector";

export const AddTeamModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const clientsData = useSelector(selectClientsData);
  const isTeamsPending = useSelector(selectIsTeamsTableStatusPending);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const profileClientId = useSelector(selectProfileClientId);

  const openModal = () => dispatch(openModalAction({ name: ADD_TEAM_MODAL }));

  const closeModal = () => dispatch(closeModalAction({ name: ADD_TEAM_MODAL }));

  const onCreateTeam = (values: CreateEditTeamDto) => {
    const clientId = values.client_id || profileClientId;
    dispatch(createTeamAction({ ...values, client_id: clientId || 0 }));
  };

  return (
    <>
      <ConnectedShowForPermissions
        feature="teams"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isTeamsPending}
        >
          Add Team
        </Button>
      </ConnectedShowForPermissions>
      <TeamModal
        name={ADD_TEAM_MODAL}
        onClose={closeModal}
        onSubmit={onCreateTeam}
        clients={clientsData}
        isPending={isFetchClientsPending}
      />
    </>
  );
};
