import * as yup from "yup";

export const DOC_MIME_TYPES = ["application/pdf"];

export const createTaskValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().optional(),
  type: yup.string().optional(),
  start_date: yup.string().optional().nullable(),
  finish_date: yup.string().optional().nullable(),
  priority: yup.string().optional(),
  asset: yup.object().required("Asset is required"),
  assigned_user: yup.object().optional().nullable(),
  assigned_team: yup.object().optional().nullable(),
  watchers: yup.object().optional().nullable(),
  created_by: yup.object().optional(),
  po_reference: yup.number().optional(),
  sub_tasks: yup.object().optional().nullable(),
  file: yup
    .mixed()
    .test(
      "file-type",
      "Declaration of Contamination can only be a PDF",
      (value: File) =>
        value === undefined ||
        value === null ||
        DOC_MIME_TYPES.includes(value.type)
    )
    .optional(),
});
