import styled from "styled-components";
import { CardActions, Card } from "@material-ui/core";

export const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledCardActions = styled(CardActions)`
  box-shadow: 0px -2px 2px #eeeeee;
  display: block;
`;

export const StyledCardTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: ${({ theme }) => theme.overrides.MuiAppBar.colorPrimary.color};
`;

export const StyledTaskCard = styled(Card)`
  min-height: 275;
  margin: ${({ theme }) => `${theme.spacing(1)}px`};
  .MuiCardContent-root {
    padding: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;
