import React, { FC } from "react";
import { makeStyles, createStyles, ListItem } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  StyledListItemIcon,
  StyledSettingsListItemIcon,
  StyledRouterLink,
} from "./NavItem.style";
import {
  DRAWER_TEXT_SELECTED,
  DRAWER_TEXT_SELECTED_SETTINGS,
  DRAWER_BACKGROUND_SELECTED,
  DRAWER_BACKGROUND_SELECTED_SETTINGS,
  SETTINGS_LIST_ITEM_COLOR,
  SETTINGS_LIST_ITEM_ICON_COLOR,
} from "../../../theme/colors";
import { DASHBOARD, DASHBOARD_CLIENTS } from "../../../const/routes";
import { ReactComponent } from "*.svg";

interface INavItem {
  href: string;
  icon: typeof SvgIcon | typeof ReactComponent;
  title: string;
  key: string;
  settingsItem?: boolean;
}

export const NavItem: FC<INavItem> = ({
  href,
  icon: IconComp,
  title,
  settingsItem,
  ...rest
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      active: {
        color: settingsItem
          ? DRAWER_TEXT_SELECTED_SETTINGS
          : DRAWER_TEXT_SELECTED,
        backgroundColor: settingsItem
          ? DRAWER_BACKGROUND_SELECTED_SETTINGS
          : DRAWER_BACKGROUND_SELECTED,
        fontWeight: "bold",
      },
      settingsListItem: {
        color: SETTINGS_LIST_ITEM_COLOR,
      },
    })
  );

  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const NavButton = (props: any) => {
    const { forwardedRef, to, ...otherProps } = props;

    return (
      <StyledRouterLink
        {...otherProps}
        ref={forwardedRef}
        to={to}
        activeClassName={classes.active}
        isActive={(match, location) => {
          if (to === DASHBOARD && location.pathname === DASHBOARD_CLIENTS)
            return true;

          return location.pathname.includes(to);
        }}
      />
    );
  };

  return (
    <ListItem
      component={NavButton}
      exact
      to={href || false}
      {...rest}
      style={{ color: settingsItem ? SETTINGS_LIST_ITEM_COLOR : "" }}
    >
      {settingsItem ? (
        <StyledSettingsListItemIcon>
          <IconComp
            style={{ color: SETTINGS_LIST_ITEM_ICON_COLOR }}
            fontSize="default"
          />
        </StyledSettingsListItemIcon>
      ) : (
        <StyledListItemIcon>
          <IconComp fontSize="default" />
        </StyledListItemIcon>
      )}
      {title}
    </ListItem>
  );
};
