import FileSaver from "file-saver";
import {
  AssetDto,
  ChannelGraphData,
  AssetTrendsDataRequest,
  AssetRuntimeDto,
  SensorDto,
  CreateEditSensorDto,
  AssetUsageDto,
  AssetDynamoRollupDto,
  AnalyticsDataRequest,
  AssetRuntimeDataRequest,
  AssetUsageDataRequest,
  AssetNoteDto,
  AssetAttachmentDto,
  SensorTableDto,
  CreateEditAssetDto,
  AssetTagDto,
  AssetAlertsDto,
} from "./asset.api.dto";
import { CategoryDto } from "../../category/api/category.api.dto";
import { authApiClient } from "../../app/api/authApi.client";

export const fetchAssets = async (clientId?: number): Promise<AssetDto[]> => {
  try {
    const url = clientId ? `/assets?client_id=${clientId}` : "/assets";
    const response = await authApiClient.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetsByClientId = async (
  clientId: number
): Promise<AssetDto[]> => {
  try {
    const response = await authApiClient.get(`/assets?client_id=${clientId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetRuntime = async (
  request: AssetRuntimeDataRequest
): Promise<AssetRuntimeDto[]> => {
  try {
    const { data } = await authApiClient.post("/analytics/asset-runtime-data", {
      asset_ids: request.assetIds,
      start_epoch: request.startEpoch,
      end_epoch: request.endEpoch,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetById = async (assetId: number): Promise<AssetDto> => {
  try {
    const response = await authApiClient.get(`/assets/${assetId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAsset = async (
  asset: CreateEditAssetDto
): Promise<AssetDto> => {
  try {
    const response = await authApiClient.post("/assets", asset);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAsset = async (id: number): Promise<string> => {
  try {
    const { data } = await authApiClient.delete(`/assets/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const editAsset = async (
  id: number,
  editAssetDto: CreateEditAssetDto
): Promise<AssetDto> => {
  try {
    const response = await authApiClient.put(`/assets/${id}`, editAssetDto);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetRuntime = async (asset: AssetDto): Promise<AssetDto> => {
  try {
    const response = await authApiClient.put(
      `/assets/${asset.id}/runtime/reset`,
      asset
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategories = async (
  clientId?: number
): Promise<CategoryDto[]> => {
  try {
    const response = await authApiClient.get(
      `/categories?client_id=${clientId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTags = async (clientId?: number): Promise<AssetTagDto[]> => {
  try {
    const response = await authApiClient.get(
      `/asset-tags/list?client_id=${clientId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchHealth = async (
  assetId: number
): Promise<{ data: number }> => {
  try {
    const response = await authApiClient.get(`assets/${assetId}/health`);
    return { data: response.data };
  } catch (error) {
    throw error;
  }
};

export const fetchSensors = async (assetId: number): Promise<SensorDto[]> => {
  try {
    const response = await authApiClient.get(`assets/${assetId}/sensors`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetHealthData = async (
  request: AssetTrendsDataRequest
): Promise<ChannelGraphData[]> => {
  try {
    const { data } = await authApiClient.post("plots/asset-health-data", {
      asset_id: request.assetId,
      start_epoch: request.startEpoch,
      end_epoch: request.endEpoch,
    });
    return data[0].datasets;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetSensorReadingsData = async (
  request: AssetTrendsDataRequest
): Promise<ChannelGraphData[]> => {
  try {
    const { data } = await authApiClient.post("plots/asset-sensor-data", {
      asset_id: request.assetId,
      start_epoch: request.startEpoch,
      end_epoch: request.endEpoch,
    });
    return data[0].datasets;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetUsageData = async (
  request: AssetUsageDataRequest
): Promise<AssetUsageDto[]> => {
  try {
    const { data } = await authApiClient.post("analytics/asset-usage-data", {
      asset_ids: request.assetIds,
      start_epoch: request.startEpoch,
      end_epoch: request.endEpoch,
      usage_unit: request.usageUnit,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchDyanmoData = async (
  request: AnalyticsDataRequest
): Promise<AssetDynamoRollupDto[]> => {
  try {
    const { data } = await authApiClient.post("analytics/asset-data", {
      asset_ids: request.assetIds,
      start_epoch: request.startEpoch,
      end_epoch: request.endEpoch,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetNotes = async (
  assetId: number
): Promise<AssetNoteDto[]> => {
  try {
    const response = await authApiClient.get(`assets/${assetId}/notes`);
    return response.data.notes;
  } catch (error) {
    throw error;
  }
};

export const deleteAssetNote = async (noteId: number): Promise<boolean> => {
  try {
    const response = await authApiClient.delete(`assets/notes/${noteId}`);
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const pinAssetNote = async (
  noteId: number,
  pinned: boolean
): Promise<boolean> => {
  try {
    const response = await authApiClient.post(`assets/notes/${noteId}/pinned`, {
      pinned,
    });
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const addAssetNote = async (
  assetId: number,
  text: string
): Promise<boolean> => {
  try {
    const response = await authApiClient.post(`assets/${assetId}/notes`, {
      text,
    });
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetAttachments = async (
  assetId: number
): Promise<AssetAttachmentDto[]> => {
  try {
    const response = await authApiClient.get(`assets/${assetId}/attachments`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addAssetAttachment = async (
  assetId: number,
  file: FormData
): Promise<boolean> => {
  try {
    const response = await authApiClient.post(
      `assets/${assetId}/attachments/store`,
      file
    );
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const downloadAssetAttachment = async (
  attachmentId: number,
  name: string,
  extension: string
): Promise<boolean> => {
  try {
    const { data } = await authApiClient.get(
      `assets/attachments/${attachmentId}`,
      {
        responseType: "blob",
      }
    );

    FileSaver.saveAs(data, `${name}.${extension}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteAssetAttachment = async (
  attachmentId: number
): Promise<boolean> => {
  try {
    const response = await authApiClient.delete(
      `assets/attachments/${attachmentId}/delete`
    );
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetSensors = async (
  assetId: number
): Promise<SensorTableDto[]> => {
  try {
    const response = await authApiClient.get(
      `/assets/${assetId}/table/sensors`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSensor = async (
  sensor: CreateEditSensorDto
): Promise<void> => {
  try {
    const response = await authApiClient.post("/sensor", sensor);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSensor = async (id: number): Promise<boolean> => {
  try {
    const { data } = await authApiClient.delete(`/sensor/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const editSensor = async (
  id: number,
  editSensorDto: CreateEditSensorDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(`/sensor/${id}`, editSensorDto);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addAssetToBookmark = async (
  assetId: number,
  tabName: string
): Promise<void> => {
  try {
    const response = await authApiClient.post(`/assets/${assetId}/bookmarks`, {
      tab_name: tabName,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeAssetFromBookmark = async (
  assetId: number,
  tabName: string
): Promise<void> => {
  try {
    const response = await authApiClient.delete(
      `/assets/${assetId}/bookmarks`,
      {
        data: {
          tab_name: tabName,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAssetAlertHistory = async (
  assetId: number
): Promise<AssetAlertsDto[]> => {
  try {
    const response = await authApiClient.get(
      `/assets/${assetId}/alert-history`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
