import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import React, { PropsWithChildren, ReactElement, useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  TableOptions,
  HeaderGroup,
  Row,
} from "react-table";
import {
  InnerTableWrapper,
  LoaderOverlay,
  RelativeWrapper,
  StyledTableContainer,
  StyledTableInnerContainer,
  StyledTablePagination,
  TableWrapper,
  StyledTableHeaderCell,
  StyledTableRow,
  StyledTableBodyCell,
  TableFooter,
} from "./Table.style";
import { TableSortLabel } from "./TableSortLabel/TableSortLabel";
import { TablePageSizeMenu } from "./TablePageSizeMenu/TablePageSizeMenu";

const DEFAULT_CELL_WIDTH = 150;

// TODO - refactor BE
// Due to `values` and `original` objects being out of sync with each other
// we need to store a reference to fields which values should be taken directly
// from `values` object when sorting
const EXCLUDED_ORIGINAL_FIELDS = ["partner", "manufacturer"];

export enum SortOrder {
  DESC = "desc",
  ASC = "asc",
}

export interface ITable<T extends Record<string, unknown>>
  extends TableOptions<T> {
  pagination?: {
    page: number;
    totalPages: number;
  };
  sorting?: {
    id: string;
    desc: boolean;
  };
  onSortChange?: (sort: { key: string; order: SortOrder } | null) => void;
  onPageChange?: (page: number) => void;
  loading?: boolean;
  // eslint-disable-next-line
  onRowClick?: (row: any) => void;
  hover?: boolean;
  formView?: boolean;
  minWidth?: number;
  noExtraHeaderPadding?: boolean;
  hideNoData?: boolean;
  noActionButton?: boolean;
}

type Order = "asc" | "desc";

function descendingComparator<T>(
  a: T & { original: any; values: any },
  b: T & { original: any; values: any },
  orderBy: keyof T
) {
  const aField = EXCLUDED_ORIGINAL_FIELDS.includes(String(orderBy))
    ? a.values[orderBy]
    : a.original[orderBy];

  const bField = EXCLUDED_ORIGINAL_FIELDS.includes(String(orderBy))
    ? b.values[orderBy]
    : b.original[orderBy];

  let firstItem = Array.isArray(aField) ? aField.join("") : aField;
  let secondItem = Array.isArray(bField) ? bField.join("") : bField;

  if (orderBy === "last_reading_date" || orderBy === "last_received") {
    firstItem = new Date(firstItem).getTime();
    secondItem = new Date(secondItem).getTime();
  }

  if (orderBy !== "channel") {
    return String(
      firstItem === null
        ? undefined
        : orderBy !== "last_reading_date" && orderBy !== "last_received"
        ? firstItem
        : secondItem
    ).localeCompare(
      String(
        secondItem === null
          ? undefined
          : orderBy !== "last_reading_date" && orderBy !== "last_received"
          ? secondItem
          : firstItem
      )
    );
  }
  return Number(firstItem) - Number(secondItem);
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string } & { original: any; values: any },
  b: { [key in Key]: number | string } & { original: any; values: any }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: any[],
  comparator: (a: any, b: any) => number,
  orderBy: string
) {
  const sortedData = array.map((el, index) => [el, index] as [any, number]);

  sortedData.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  if (orderBy === "last_reading_date" || orderBy === "last_received") {
    const firstArr = sortedData.filter((el) =>
      orderBy === "last_reading_date"
        ? el[0].original.last_reading_date !== "N/A"
        : el[0].original.last_received !== null
    );
    const secondArr = sortedData.filter((el) =>
      orderBy === "last_reading_date"
        ? el[0].original.last_reading_date === "N/A"
        : el[0].original.last_received === null
    );

    const combinedArr = firstArr.concat(secondArr);
    return combinedArr.map((el) => el[0]);
  }
  return sortedData.map((el) => el[0]);
}

export function Table<T extends Record<string, unknown>>({
  columns,
  data,
  pagination,
  sorting,
  onSortChange,
  onPageChange,
  loading,
  onRowClick,
  hover,
  formView,
  minWidth,
  noExtraHeaderPadding,
  hideNoData,
  noActionButton,
}: PropsWithChildren<ITable<T>>): ReactElement {
  const [order, setOrder] = React.useState<Order>(
    sorting ? (sorting.desc ? "desc" : "asc") : "asc"
  );
  const [orderBy, setOrderBy] = React.useState<string>(
    sorting ? sorting.id : ""
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable<T>(
    {
      columns,
      data,
      manualPagination: Boolean(onPageChange),
      manualSortBy: Boolean(onSortChange),
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        ...(sorting && { sortBy: [sorting] }),
      },
      autoResetSortBy: false,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageIndex: pagination?.page || state.pageIndex,
            hiddenColumns: ["id", "tags"],
          }),
          [state]
        );
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const isData = data && data.length > 0;

  const handlePaginationChange = (pageNumber: number): void => {
    if (onPageChange) {
      return onPageChange(pageNumber);
    }

    return gotoPage(pageNumber);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(
    page,
    getComparator(order, orderBy),
    orderBy
  ).map((row, index) => {
    prepareRow(row);
    return row;
  }) as Row<T>[];

  return (
    <RelativeWrapper>
      <TableWrapper>
        <InnerTableWrapper>
          {loading && (
            <LoaderOverlay>
              <CircularProgress size={50} />
            </LoaderOverlay>
          )}
          {!isData && !loading && !hideNoData && <LoaderOverlay />}
          <StyledTableContainer>
            <StyledTableInnerContainer
              disableAbsolutePositioning={formView}
              minWidth={minWidth}
            >
              <MuiTable {...getTableProps()} stickyHeader>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(
                        (column: HeaderGroup<T> & { accessor?: string }) => {
                          const createSortHandler = (property: string) => (
                            event: React.MouseEvent<unknown>
                          ) => {
                            handleRequestSort(event, property);
                          };

                          return (
                            <StyledTableHeaderCell
                              noExtraPadding={noExtraHeaderPadding}
                              alignToFormInputs={formView}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              onClick={
                                !column.disableSortBy
                                  ? createSortHandler(column.id)
                                  : () => {}
                              }
                              sortDirection={
                                orderBy === column.id ? order : false
                              }
                            >
                              {column.render("Header")}
                              {!column.disableSortBy &&
                              orderBy === column.id ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={
                                    orderBy === column.id ? order : "asc"
                                  }
                                />
                              ) : null}
                            </StyledTableHeaderCell>
                          );
                        }
                      )}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {sortedData.map((row) => {
                    prepareRow(row);
                    return (
                      <StyledTableRow
                        {...row.getRowProps()}
                        onClick={() =>
                          onRowClick ? onRowClick(row.original) : {}
                        }
                        hover={Boolean(onRowClick) || hover}
                      >
                        {row.cells.map((cell) => (
                          <StyledTableBodyCell
                            phoneNumber={cell.column.Header === "Phone Number"}
                            lastReading={cell.column.Header === "Last Reading"}
                            alignToFormInputs={formView}
                            noActionButton={noActionButton}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </StyledTableBodyCell>
                        ))}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </MuiTable>
            </StyledTableInnerContainer>
          </StyledTableContainer>
          {!formView && (
            <TableFooter>
              <TablePageSizeMenu
                setPageSize={setPageSize}
                pageSize={pageSize}
              />
              <StyledTablePagination
                page={pagination?.page ? pagination.page + 1 : pageIndex + 1}
                count={pagination?.totalPages || pageCount}
                color="primary"
                onChange={(event, pageNumber) =>
                  handlePaginationChange(pageNumber - 1)
                }
              />
            </TableFooter>
          )}
        </InnerTableWrapper>
      </TableWrapper>
    </RelativeWrapper>
  );
}
