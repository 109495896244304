import React, { FC, useState } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { AnalyticsSelectors } from "../component/Analytics/AnalyticsSelectors";
import {
  AssetDto,
  AssetDynamoRollupDto,
  AssetRuntimeDto,
  AssetUsageDto,
} from "../api/asset.api.dto";
import { DateTimeRangePicker } from "../component/DateTimeRangePicker/DateTimeRangePicker";
import { AnalyticsUsageContainer } from "./AnalyticsUsageContainer";
import { AssetAnalyticsRuntimeContainer } from "./AssetAnalyticsRuntimeContainer";
import { AnalyticsHealthComparisonContainer } from "./AnalyticsHealthComparisonContainer";

export const AssetAnalyticsContainer: FC = () => {
  const [startEpoch, setStartEpoch] = useState(
    moment().subtract(6, "days").unix()
  );
  const [endEpoch, setEndEpoch] = useState(moment().unix());

  const [selectedAssets, setSelectedAssets] = useState<AssetDto[]>([]);
  const [runtimeData, setRuntimeData] = useState<AssetRuntimeDto[]>([]);
  const [usageData, setUsageData] = useState<AssetUsageDto[]>([]);
  const [healthComparisonData, setHealthComparisonData] = useState<
    AssetDynamoRollupDto[]
  >([]);

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <AnalyticsSelectors
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <DateTimeRangePicker
            startEpoch={startEpoch}
            endEpoch={endEpoch}
            setStartEpoch={setStartEpoch}
            setEndEpoch={setEndEpoch}
          />
        </Grid>
        <Grid item xs={12}>
          <AnalyticsHealthComparisonContainer
            selectedAssets={selectedAssets}
            startEpoch={startEpoch}
            endEpoch={endEpoch}
            healthComparisonData={healthComparisonData}
            setHealthComparisonData={setHealthComparisonData}
          />
        </Grid>
        <Grid item xs={12}>
          <AssetAnalyticsRuntimeContainer
            selectedAssets={selectedAssets}
            startEpoch={startEpoch}
            endEpoch={endEpoch}
            runtimeData={runtimeData}
            setRuntimeData={setRuntimeData}
          />
        </Grid>
        <Grid item xs={12}>
          <AnalyticsUsageContainer
            selectedAssets={selectedAssets}
            startEpoch={startEpoch}
            endEpoch={endEpoch}
            usageData={usageData}
            setUsageData={setUsageData}
          />
        </Grid>
      </Grid>
    </>
  );
};
