import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  FullHeightContainer,
  PageActionsWrapper,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { ClientsTableContainer } from "../../container/ClientsTableContainer";
import { ClientsSearchContainer } from "../../container/ClientsSearchContainer";
import { AddClientModalContainer } from "../../container/AddClientModalContainer";

export const ClientsView: FC = () => (
  <StyledPage title="Clients">
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Clients" />
        <ConnectedShowForPermissions
          feature="clients"
          permissions={{ allOf: ["read"] }}
        >
          <PageActionsWrapper>
            <ClientsSearchContainer />
            <AddClientModalContainer />
          </PageActionsWrapper>
        </ConnectedShowForPermissions>
      </PageHeaderContainer>
      <ConnectedShowForPermissions
        feature="clients"
        permissions={{ allOf: ["read"] }}
      >
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <ClientsTableContainer />
        </Box>
      </ConnectedShowForPermissions>
    </FullHeightContainer>
  </StyledPage>
);
