import React, { FC, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { parse } from "query-string";
import { AppDispatch } from "../../app/store";
import {
  selectAssetsFilteredData,
  selectAssetsFilterNumbers,
  selectAssetsTableData,
  selectTagsFilters,
} from "../store/asset.selector";
import { AssetsFilter } from "../component/AssetsFilter/AssetsFilter";
import { setFilteredAssetsState, setTagsFilters } from "../store/asset.slice";
import { AssetStatus } from "../enum/assets.enum";
import { AssetFilterStatus } from "../../app/enum/AssetStatus";
import { filterByTags } from "../util/filter-by-tags";
import { useInterval } from "../../app/hooks/useInterval";
import { fetchAssets } from "../store/asset.action";
import { ASSET_REALTIME, ASSETS } from "../../app/const/routes";

export interface IAssetsFilterContainerProps {
  setIsInitialDataFetched: React.Dispatch<React.SetStateAction<boolean>>;
  isInitialDataFetched: boolean;
}

export const AssetsFilterContainer: FC<IAssetsFilterContainerProps> = ({
  setIsInitialDataFetched,
  isInitialDataFetched,
}) => {
  const params = useParams<{ assetId: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const assetsData = useSelector(selectAssetsTableData);
  const assetsFilterNumbers = useSelector(selectAssetsFilterNumbers);
  const tagsFilters = useSelector(selectTagsFilters);
  const [activeTab, setActiveTab] = useState(AssetFilterStatus.total);
  const { search } = useLocation();
  const { clientId }: { clientId?: string } = parse(search);
  const { active }: { active?: string } = parse(search);
  const history = useHistory<any>();

  useEffect(() => {
    dispatch(fetchAssets(Number(clientId)));
    return () => {
      dispatch(setTagsFilters([]));
    };
  }, [dispatch, clientId]);

  const filterAssets = useCallback(
    (tabName: string) => {
      let data = assetsData;
      setActiveTab(tabName);
      if (tabName === AssetFilterStatus.on) {
        data = assetsData.filter(
          (asset) => asset.cached_active_status === AssetStatus.On
        );
      }
      if (tabName === AssetFilterStatus.off) {
        data = assetsData.filter(
          (asset) => asset.cached_active_status === AssetStatus.Off
        );
      }
      if (tabName === AssetFilterStatus.na) {
        data = assetsData.filter(
          (asset) => asset.cached_active_status === AssetStatus.NA
        );
      }
      if (tabName === AssetFilterStatus.health) {
        data = assetsData.filter(
          (asset) =>
            asset.health_is_enabled &&
            asset.health_rating !== null &&
            asset.cached_active_status !== AssetStatus.Off &&
            asset.is_enabled &&
            asset.health_rating <= 2.5
        );
      }
      if (tabName === AssetFilterStatus.alarm) {
        data = assetsData.filter((asset) => asset.alert);
      }

      if (tagsFilters.length) {
        data = filterByTags(data, tagsFilters);
      }
      dispatch(setFilteredAssetsState(data));
    },
    [dispatch, tagsFilters, assetsData]
  );

  useEffect(() => {
    if (active) {
      filterAssets(active);
    } else if (!isInitialDataFetched) {
      filterAssets("all");
    }
  }, [filterAssets, active, isInitialDataFetched]);

  useInterval(() => {
    if (isInitialDataFetched) {
      dispatch(fetchAssets(Number(clientId)));
    }
    setIsInitialDataFetched(true);
    if (activeTab) {
      filterAssets(activeTab);
    }
  });

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string
  ) => {
    filterAssets(newValue);
    if (clientId) {
      history.push(`${ASSETS}?clientId=${clientId}`);
    } else {
      history.push(ASSETS);
    }
  };

  return (
    <AssetsFilter
      handleChange={handleChange}
      activeTab={activeTab}
      assetsFilterNumbers={assetsFilterNumbers}
      wrapped={false}
    />
  );
};
