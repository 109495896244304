import React, { FC } from "react";
import {
  IconButton as MuiIconButton,
  CircularProgress,
  IconButtonProps,
} from "@material-ui/core";

export interface IIconButton extends IconButtonProps {
  pending?: boolean;
  startIcon?: React.ReactNode;
}

export const IconButton: FC<IIconButton> = ({
  pending,
  startIcon,
  ...rest
}) => {
  return (
    <MuiIconButton {...rest}>
      {pending ? <CircularProgress size={16} color="inherit" /> : startIcon}
    </MuiIconButton>
  );
};
