import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { DeleteForever, Edit as EditIcon } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useFormik } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "../../../app/component/Button/Button";
import {
  AddText,
  EditText,
  LoaderWrapper,
  StyledButtonHighlight,
  StyledIconButton,
  StyledIconButtonContainer,
} from "./UserProfile.style";
import { UserDto } from "../../api/users.api.dto";
import { EditUserDetailsModel } from "../../model/users.model";
import { TeamDto } from "../../../teams/api/teams.api.dto";
import { profileFormValidation } from "./profileForm.validation";

export interface IProfileForm {
  profile: UserDto | null;
  onImageChange: (file?: File) => void;
  onSubmit: (values: EditUserDetailsModel) => void;
  isPending: boolean;
}

export const ProfileForm: FC<IProfileForm> = ({
  profile,
  onImageChange,
  onSubmit,
  isPending,
}) => {
  const [teams, setTeams] = useState(
    profile?.teams?.map((team: TeamDto) => ({
      label: team.name,
      value: team.id,
    })) || []
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: profile?.first_name || "",
      last_name: profile?.last_name || "",
      display_name: profile?.display_name || "",
      phone: profile?.phone || "",
      job_title: profile?.job_title || "",
    },
    validationSchema: profileFormValidation,
    onSubmit: (values: Omit<EditUserDetailsModel, "team_ids">) =>
      onSubmit({ ...values, team_ids: teams.map((option) => option.value) }),
  });

  const resetForm = () => {
    formik.resetForm();
    setTeams(
      profile?.teams?.map((team: TeamDto) => ({
        label: team.name,
        value: team.id,
      })) || []
    );
  };

  const handlePhoneChange = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      formik.setFieldValue("phone", onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      formik.setFieldValue("phone", number);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {isPending ? (
        <LoaderWrapper>
          <CircularProgress size={50} />
        </LoaderWrapper>
      ) : profile &&
        profile.photo_url &&
        !profile.photo_url.includes("gravatar") ? (
        <>
          <Avatar
            src={profile.photo_url}
            alt="Avatar"
            style={{
              width: "100px",
              height: "100px",
            }}
          />
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box>
              <label htmlFor="icon-button-file">
                <input
                  accept="image/*"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) =>
                    event.target.files && onImageChange(event.target.files[0])
                  }
                />
                <EditText>Edit photo</EditText>
              </label>
            </Box>
            <Box>
              <IconButton
                onClick={() => onImageChange()}
                disabled={isPending}
                style={{ padding: "none" }}
              >
                <DeleteForever color="error" />
              </IconButton>
            </Box>
          </Box>
        </>
      ) : (
        <label htmlFor="icon-button-file">
          <StyledIconButtonContainer>
            <StyledIconButton>
              <StyledButtonHighlight>
                <input
                  accept="image/*"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) =>
                    event.target.files && onImageChange(event.target.files[0])
                  }
                />
                <IconButton
                  color="inherit"
                  aria-label="upload picture"
                  component="span"
                  disabled={isPending}
                >
                  <EditIcon />
                </IconButton>
              </StyledButtonHighlight>
            </StyledIconButton>
            <AddText>Add photo</AddText>
          </StyledIconButtonContainer>
        </label>
      )}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6}>
          <TextField
            id="first_name"
            name="first_name"
            label="First Name"
            variant="filled"
            value={formik.values.first_name}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
            onChange={formik.handleChange}
            fullWidth
            disabled={isPending}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="last_name"
            name="last_name"
            label="Last Name"
            variant="filled"
            value={formik.values.last_name}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            onChange={formik.handleChange}
            fullWidth
            disabled={isPending}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="job_title"
            name="job_title"
            label="Job Title"
            variant="filled"
            value={formik.values.job_title}
            error={formik.touched.job_title && Boolean(formik.errors.job_title)}
            helperText={formik.touched.job_title && formik.errors.job_title}
            onChange={formik.handleChange}
            fullWidth
            disabled={isPending}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={[...teams].sort((a, b) => a.label.localeCompare(b.label))}
            id="team_ids"
            getOptionLabel={(option) => option.label}
            disabled
            multiple
            value={teams}
            renderInput={(params) => (
              <TextField
                {...params}
                name="team_ids"
                label="All Teams"
                variant="filled"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="phone"
            name="phone"
            label="Phone Number"
            variant="filled"
            value={formik.values.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            onChange={handlePhoneChange}
            fullWidth
            disabled={isPending}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button
              color="primary"
              onClick={resetForm}
              disabled={isPending || !formik.dirty}
            >
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isPending || !formik.dirty}
              pending={isPending}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
