import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { selectRouterPathname } from "../../store/router/router.selector";
import { AppDispatch } from "../../store";
import { StyledTabs } from "./ConnectedTabs.style";

interface IConnectedTabs {
  className?: string;
}

export const ConnectedTabs: FC<IConnectedTabs> = ({ children, className }) => {
  const dispatch = useDispatch<AppDispatch>();
  const pathname = useSelector(selectRouterPathname);

  return (
    <StyledTabs
      className={className}
      value={pathname}
      onChange={(e, value) => dispatch(push(value))}
      aria-label="tabs"
      variant="scrollable"
      scrollButtons="off"
    >
      {children}
    </StyledTabs>
  );
};
