import React, { FC } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { Container, StyledTextField } from "./SelectClient.style";

interface ISelectClient {
  clients: ClientDto[];
  selectedClientId: number | null | undefined;
  onSelectClientId: (id: number) => void;
  pending: boolean;
}

export const SelectClient: FC<ISelectClient> = ({
  clients,
  selectedClientId,
  onSelectClientId,
  pending,
}) => {
  const getClientIdOptions = () => {
    return [...clients]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client) => client.id) as number[];
  };

  const getClientIdOptionLabel = (option: number) => {
    const match = clients.find((client) => {
      return client.id === option;
    });
    return match ? match.name : "";
  };

  return (
    <Container>
      <Autocomplete
        disableClearable
        options={getClientIdOptions()}
        id="clients"
        getOptionLabel={getClientIdOptionLabel}
        disabled={pending}
        value={selectedClientId || -1}
        onChange={(event: React.ChangeEvent<unknown>, newValues: number) => {
          onSelectClientId(newValues);
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            name="clients"
            label="Clients"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      />
    </Container>
  );
};
