import { createAsyncThunk } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import {
  fetchTasks as fetchTasksRequest,
  updateTaskStatus as updateTaskStatusRequest,
  createTask as createTaskRequest,
  editTask as editTaskRequest,
  deleteTask as deleteTaskRequest,
  createComment as createCommentRequest,
  deleteComment as deleteCommentRequest,
} from "../api/workorders.api";
import withError from "../../app/util/with-thunk-error";
import {
  CreateTaskDto,
  TaskDto,
  Comment,
  TasksList,
  UpdateTaskStatusDto,
  TaskCommentDto,
} from "../api/workorders.api.dto";
import {
  closeModal,
  setModalStatusFulfilled,
  setModalStatusPending,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import { ADD_TASK_MODAL, EDIT_TASK_MODAL } from "../../app/const/modals";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";

export const fetchTasks = createAsyncThunk(
  "workorders/fetchTasks",
  withError(
    async ({
      clientId,
      assetId,
    }: {
      clientId?: number;
      assetId?: number;
    }): Promise<{ tasks: TaskDto[]; tasksList: TasksList }> =>
      fetchTasksRequest(clientId, assetId)
  )
);

export const updateTaskStatus = createAsyncThunk(
  "workorders/updateTaskStatus",
  withError(
    async (updateTaskStatusDto: UpdateTaskStatusDto): Promise<void> =>
      updateTaskStatusRequest(updateTaskStatusDto)
  )
);

export const createTask = createAsyncThunk(
  "workorders/createTask",
  withError(
    async (taskDto: CreateTaskDto, { dispatch }): Promise<TaskDto> => {
      const formData = serialize(taskDto);
      dispatch(setModalStatusPending({ name: ADD_TASK_MODAL }));
      const task = await createTaskRequest(formData);
      dispatch(closeModal({ name: ADD_TASK_MODAL }));
      dispatch(openSuccessNotification("Work order has been created"));
      return task;
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_TASK_MODAL }))
  )
);

export const editTask = createAsyncThunk(
  "workorders/editTask",
  withError(
    async (taskDto: CreateTaskDto, { dispatch }): Promise<TaskDto> => {
      const formData = serialize(taskDto);
      dispatch(setModalStatusPending({ name: EDIT_TASK_MODAL }));
      const task = await editTaskRequest(formData);
      dispatch(closeModal({ name: EDIT_TASK_MODAL }));
      dispatch(openSuccessNotification("Work order has been updated"));
      return task;
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_TASK_MODAL }))
  )
);

export const deleteTask = createAsyncThunk(
  "workorders/deleteTask",
  withError(
    async (
      {
        taskId,
        clientId,
        assetId,
      }: {
        taskId: number;
        clientId?: number;
        assetId?: number;
      },
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_TASK_MODAL }));
      await deleteTaskRequest(taskId);
      dispatch(closeModal({ name: EDIT_TASK_MODAL }));
      dispatch(openSuccessNotification("Work order has been deleted"));
      dispatch(
        fetchTasks({
          clientId,
          assetId,
        })
      );
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_TASK_MODAL }))
  )
);

export const createComment = createAsyncThunk(
  "workorder/createComment",
  withError(
    async (
      taskCommentDto: TaskCommentDto,
      { dispatch }
    ): Promise<TaskCommentDto> => {
      dispatch(
        setModalStatusPending({
          name: EDIT_TASK_MODAL,
          params: { task: taskCommentDto.task },
        })
      );
      const comment = await createCommentRequest(taskCommentDto);

      const task = {
        ...taskCommentDto.task,
        comments: [...taskCommentDto.task.comments, comment],
        sub_tasks: taskCommentDto.newSubTasks,
      };
      dispatch(
        setModalStatusFulfilled({
          name: EDIT_TASK_MODAL,
          params: { task },
        })
      );
      dispatch(openSuccessNotification("Comment has been added"));

      return { ...taskCommentDto, newComment: comment };
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_TASK_MODAL }))
  )
);

export const deleteComment = createAsyncThunk(
  "workorder/deleteComment",
  withError(
    async (
      taskCommentDto: TaskCommentDto,
      { dispatch }
    ): Promise<TaskCommentDto> => {
      dispatch(
        setModalStatusPending({
          name: EDIT_TASK_MODAL,
          params: { task: taskCommentDto.task },
        })
      );
      await deleteCommentRequest(taskCommentDto.newComment);
      const newComments = taskCommentDto.task.comments.filter(
        (e) => e.text !== taskCommentDto.newComment.text
      );
      const task = {
        ...taskCommentDto.task,
        sub_tasks: taskCommentDto.newSubTasks,
        comments: newComments,
      };
      dispatch(
        setModalStatusFulfilled({ name: EDIT_TASK_MODAL, params: { task } })
      );
      dispatch(openSuccessNotification("Comment has been removed"));
      return taskCommentDto;
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_TASK_MODAL }))
  )
);
