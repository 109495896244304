import { useRef, useEffect } from "react";
import { DEFAULT_FETCH_INTERVAL } from "../const/intervals";

export const useInterval = (
  callback: () => void,
  delay = DEFAULT_FETCH_INTERVAL
) => {
  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
