import React, { FC, useState, Fragment, useEffect } from "react";
import { useFormik } from "formik";
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import { Add } from "@material-ui/icons";
import {
  DESCRIPTION_CHAR_LIMIT,
  deviceModalFormValidation,
  ID_CHAR_LIMIT,
  SN_CHAR_LIMIT,
} from "./deviceModalForm.validation";
import {
  StyledDialogTiny,
  StyledModalContent,
  StyledSwitch,
} from "../../../app/component/Modal/Modal.style";
import { DeviceListModel } from "../../model/devices.model";
import { ChannelsSelector } from "../../../app/component/ChannelsSelector/ChannelsSelector";
import { Button } from "../../../app/component/Button/Button";
import { CreateEditDeviceDto, DeviceListDto } from "../../api/devices.api.dto";
import { TooltipIcon } from "../../../app/component/TooltipIcon/TooltipIcon";
import { useShowForPermissions } from "../../../app/hooks/useShowForPermissions";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";
import {
  OutlinedRedButton,
  OutlinedPurpleButton,
  ContainedRedButton,
  GreyButton,
} from "./DeviceModalForm.style";

export interface IClientModalForm {
  device?: DeviceListModel | DeviceListDto;
  deviceTypeOptions: { label: string; value: number }[];
  partnerOptions: { label: string; value: number }[];
  clientOptions: { label: string; value: number }[];
  assetOptions: { label: string; value: number; channels: number[] }[];
  // eslint-disable-next-line
  onSubmit: (values: CreateEditDeviceDto, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  onPartnerChange: (partnerId: number | null) => void;
  onClientChange: (partnerId: number) => void;
  onError: (message: string) => void;
  onModalMount?: (device: DeviceListModel | DeviceListDto) => void;
  isViewMode?: boolean;
  clearDevice: (deviceId: number | undefined) => void;
  setOpenedDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeviceModalForm: FC<IClientModalForm> = ({
  device,
  deviceTypeOptions,
  partnerOptions,
  onSubmit,
  displayActionButtons,
  isPending,
  clientOptions,
  onPartnerChange,
  assetOptions,
  onClientChange,
  onError,
  onModalMount,
  isViewMode,
  clearDevice,
  setOpenedDeviceModal,
}) => {
  const [type, setType] = useState<{ label: string; value: number } | null>(
    null
  );
  const [partner, setPartner] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [client, setClient] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [assetWithChannels, setAssetWithChannels] = useState<
    {
      assetId: number | null;
      channels: number[];
      disabledChannels: number[];
    }[]
  >([{ assetId: null, channels: [], disabledChannels: [] }]);

  const canEditDeviceId = useShowForPermissions("devices", {
    allOf: ["update-device-id"],
  });

  const isSuperUser = useShowForRole([UserRole.SuperUser]);
  const isSuperAdmin = useShowForRole([UserRole.SuperAdmin]);
  const [confirmClear, setConfirmClear] = useState(false);

  const [deviceChannels, setDeviceChannels] = useState<number[]>(_.range(7));

  useEffect(() => {
    const channels = [];
    let count = 0;
    if (device) {
      count = device?.channel_count;
    }
    for (let i = 0; i < count; i += 1) {
      channels.push(i);
    }
    setDeviceChannels(channels);
  }, [device]);

  useEffect(() => {
    if (onModalMount && device) {
      onModalMount(device);
    }
  }, [device, onModalMount]);

  useEffect(() => {
    const selectedOption = deviceTypeOptions.find(
      (option) => option.value === device?.device_type_id
    );

    if (selectedOption) {
      setType(selectedOption);
    }
  }, [device, deviceTypeOptions]);

  useEffect(() => {
    const selectedOption = partnerOptions.find(
      (option) => option.value === device?.partner_id
    );

    if (selectedOption) {
      setPartner(selectedOption);
    }
  }, [device, partnerOptions]);

  useEffect(() => {
    const selectedOption = clientOptions.find(
      (option) => option.value === device?.client_id
    );

    if (selectedOption) {
      setClient(selectedOption);
    }
  }, [device, clientOptions]);

  useEffect(() => {
    if (device) {
      const assignedAssetWithChannels: {
        assetId: number | null;
        channels: number[];
        disabledChannels: number[];
      }[] = device.equipments.map((asset) => ({
        assetId: asset.id,
        channels: asset.channels
          .filter((channel) => channel.device_id === device.id)
          .map((channel) => channel.channel_index),
        disabledChannels: [],
      }));
      setAssetWithChannels(assignedAssetWithChannels);
    }
  }, [device]);

  const getMeasurementPlan = () => {
    if (device && typeof device.measurement === "string") {
      const planInSeconds = Number(device.measurement.split(" ")[0]);
      const planSeconds = planInSeconds % 60;
      const planMinutes = (planInSeconds - planSeconds) / 60;

      return {
        planMinutes,
        planSeconds,
      };
    }

    if (device && typeof device.measurement === "number") {
      const planSeconds = device.measurement % 60;
      const planMinutes = (device.measurement - planSeconds) / 60;

      return {
        planMinutes,
        planSeconds,
      };
    }

    return {
      planMinutes: 0,
      planSeconds: 0,
    };
  };

  const getSchedulePlan = () => {
    if (device) {
      const scheduleSeconds = device.heartbeat % 60;
      const scheduleMinutes = (device.heartbeat - scheduleSeconds) / 60;

      return {
        scheduleMinutes,
        scheduleSeconds,
      };
    }

    return {
      scheduleMinutes: 0,
      scheduleSeconds: 0,
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: device?.name || "",
      type: device?.device_type_id || undefined,
      id: device?.avimesa_id || "",
      serialNumber: device?.serial_number || "",
      description: device?.description || "",
      measurementPlanMinutes: getMeasurementPlan().planMinutes,
      measurementPlanSeconds: getMeasurementPlan().planSeconds,
      measurementScheduleMinutes: getSchedulePlan().scheduleMinutes,
      measurementScheduleSeconds: getSchedulePlan().scheduleSeconds,
      isEnabled: Boolean(device?.is_enabled) || false,
    },
    validationSchema: deviceModalFormValidation,
    // eslint-disable-next-line
    onSubmit: (values: any) => {
      const equipment: { [key: string]: number[] } = {};
      const planInSeconds =
        values.measurementPlanMinutes * 60 + values.measurementPlanSeconds;
      const scheduleInSeconds =
        values.measurementScheduleMinutes * 60 +
        values.measurementScheduleSeconds;

      if (scheduleInSeconds < planInSeconds) {
        return onError(
          "Measurement Schedule cannot be smaller than Measurement Plan"
        );
      }

      if (
        assetWithChannels[assetWithChannels.length - 1].assetId &&
        assetWithChannels[assetWithChannels.length - 1].channels.length === 0
      ) {
        return onError("At least one channel should be assigned to the Asset");
      }

      assetWithChannels.forEach((asset) => {
        if (asset.assetId) {
          equipment[String(asset.assetId)] = asset.channels;
        }
      });

      onSubmit(
        {
          name: values.name,
          device_type_id: values.type,
          avimesa_id: values.id,
          serial_number: values.serialNumber,
          description: values.description,
          plan_minutes: values.measurementPlanMinutes,
          plan_seconds: values.measurementPlanSeconds,
          heartbeat_minutes: values.measurementScheduleMinutes,
          heartbeat_seconds: values.measurementScheduleSeconds,
          partner_id: partner?.value,
          client_id: client?.value,
          equipment: Object.keys(equipment).length > 0 ? equipment : undefined,
          is_enabled: values.isEnabled,
        },
        device?.id
      );
    },
  });

  const checkIfIsAddAssetButtonDisabled = () => {
    const isAtLeastOneChannelSelected = Boolean(
      assetWithChannels.find(
        (asset) => !asset.channels || asset.channels.length === 0
      )
    );
    let count = 0;
    if (device) {
      count = device?.channel_count;
    }
    const allChannelsSelected =
      assetWithChannels.reduce(
        (acc: number[], curr) =>
          curr.channels ? [...acc, ...curr.channels] : [...acc],
        []
      ).length >= count;

    const allOptionsSelected = assetOptions.length === assetWithChannels.length;

    return (
      isAtLeastOneChannelSelected || allChannelsSelected || allOptionsSelected
    );
  };

  const handleDeviceTypeChange = (value: number | undefined) => {
    if (value === 1) {
      setDeviceChannels(_.range(7));
    } else if (value === 2) {
      setDeviceChannels(_.range(23));
    }
  };

  const getConfirmationModal = () => {
    return (
      <StyledDialogTiny
        open={confirmClear}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <DialogTitle id="confirmation-dialog-title">
          Clear Device Data?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is permanent and cannot be undone. Do you wish to
            continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton
            autoFocus
            onClick={() => {
              setConfirmClear(false);
              setOpenedDeviceModal(true);
            }}
            color="primary"
          >
            Cancel
          </GreyButton>
          <ContainedRedButton
            onClick={() => {
              setConfirmClear(false);
              clearDevice(device?.id);
            }}
            variant="contained"
          >
            Continue
          </ContainedRedButton>
        </DialogActions>
      </StyledDialogTiny>
    );
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          {getConfirmationModal()}
          <Grid item xs={12}>
            <Typography variant="h4">Device Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Device Name"
              required
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled={isPending || isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={isPending}
            >
              <Autocomplete
                options={deviceTypeOptions.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                id="type"
                disabled={isPending || isViewMode}
                getOptionLabel={(option) => option.label}
                // eslint-disable-next-line
                value={type as any}
                disableClearable
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  option: { label: string; value: number } | null
                ) => {
                  setType(option);
                  formik.setFieldValue("type", option?.value);
                  handleDeviceTypeChange(option?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="type"
                    label="Device Type"
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    variant="filled"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              id="id"
              name="id"
              label="Device ID"
              variant="filled"
              fullWidth
              value={formik.values.id}
              onChange={formik.handleChange}
              error={formik.touched.id && Boolean(formik.errors.id)}
              helperText={formik.touched.id && formik.errors.id}
              inputProps={{ maxLength: ID_CHAR_LIMIT }}
              required
              disabled={
                isPending || isViewMode || !canEditDeviceId || !isSuperUser
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="serialNumber"
              name="serialNumber"
              label="Device S/N"
              variant="filled"
              fullWidth
              value={formik.values.serialNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.serialNumber &&
                Boolean(formik.errors.serialNumber)
              }
              helperText={
                formik.touched.serialNumber && formik.errors.serialNumber
              }
              inputProps={{ maxLength: SN_CHAR_LIMIT }}
              required
              disabled={isPending || isViewMode || !isSuperUser}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={3}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
              disabled={isPending || isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="h4">Measurements</Typography>
              <TooltipIcon
                text={
                  <div>
                    <div>
                      Measurement Plan - The fastest possible interval of time
                      that this device is permitted to measure at. Contact your
                      account manager to alter your devices’ plans.
                    </div>
                    <br />
                    <div>
                      Measurement Schedule - The interval that is set for this
                      device to measure at. Set this at the same speed as or
                      slower than the set measurement plan for this device.
                    </div>
                  </div>
                }
              />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="measurementPlanMinutes"
              name="measurementPlanMinutes"
              label="Measurement Plan Minutes"
              variant="filled"
              fullWidth
              value={formik.values.measurementPlanMinutes}
              onChange={formik.handleChange}
              error={
                formik.touched.measurementPlanMinutes &&
                Boolean(formik.errors.measurementPlanMinutes)
              }
              helperText={
                formik.touched.measurementPlanMinutes &&
                formik.errors.measurementPlanMinutes
              }
              type="number"
              disabled={
                isPending || isViewMode || (!isSuperUser && !isSuperAdmin)
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="measurementPlanSeconds"
              name="measurementPlanSeconds"
              label="Measurement Plan Seconds"
              variant="filled"
              fullWidth
              value={formik.values.measurementPlanSeconds}
              onChange={formik.handleChange}
              error={
                formik.touched.measurementPlanSeconds &&
                Boolean(formik.errors.measurementPlanSeconds)
              }
              helperText={
                formik.touched.measurementPlanSeconds &&
                formik.errors.measurementPlanSeconds
              }
              type="number"
              disabled={
                isPending || isViewMode || (!isSuperUser && !isSuperAdmin)
              }
              inputProps={{ min: 0, max: 59 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="measurementScheduleMinutes"
              name="measurementScheduleMinutes"
              label="Measurement Schedule Minutes"
              variant="filled"
              fullWidth
              value={formik.values.measurementScheduleMinutes}
              onChange={formik.handleChange}
              error={
                formik.touched.measurementScheduleMinutes &&
                Boolean(formik.errors.measurementScheduleMinutes)
              }
              helperText={
                formik.touched.measurementScheduleMinutes &&
                formik.errors.measurementScheduleMinutes
              }
              type="number"
              disabled={isPending || isViewMode}
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="measurementScheduleSeconds"
              name="measurementScheduleSeconds"
              label="Measurement Schedule Seconds"
              variant="filled"
              fullWidth
              value={formik.values.measurementScheduleSeconds}
              onChange={formik.handleChange}
              error={
                formik.touched.measurementScheduleSeconds &&
                Boolean(formik.errors.measurementScheduleSeconds)
              }
              helperText={
                formik.touched.measurementScheduleSeconds &&
                formik.errors.measurementScheduleSeconds
              }
              type="number"
              disabled={isPending || isViewMode}
              inputProps={{ min: 0, max: 59 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Assign Device</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={isPending || isViewMode}
            >
              <Autocomplete
                options={partnerOptions.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                id="partner"
                disabled={
                  isPending || isViewMode || (Boolean(device) && !isSuperUser)
                }
                getOptionLabel={(option) => option.label}
                // eslint-disable-next-line
                value={partner as any}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  option: { label: string; value: number } | null
                ) => {
                  setPartner(option);
                  onPartnerChange(option ? option.value : null);
                  setClient(null);
                  setAssetWithChannels([
                    { assetId: null, channels: [], disabledChannels: [] },
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partner"
                    label="Select Partner"
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={isPending || isViewMode}
            >
              <Autocomplete
                options={clientOptions.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                id="client"
                disabled={
                  isPending ||
                  isViewMode ||
                  (Boolean(device) && !isSuperUser && !isSuperAdmin)
                }
                getOptionLabel={(option) => option.label}
                // eslint-disable-next-line
                value={client as any}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  option: { label: string; value: number } | null
                ) => {
                  setClient(option);

                  if (option) {
                    onClientChange(option.value);
                  }

                  setAssetWithChannels([
                    { assetId: null, channels: [], disabledChannels: [] },
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="client"
                    label="Select Client"
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          {assetWithChannels.map((assetWithChannel, index) => (
            <Fragment key={index}>
              <Grid item xs={12}>
                <FormControl
                  variant="filled"
                  fullWidth
                  required
                  disabled={isPending || isViewMode}
                >
                  <Autocomplete
                    options={assetOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    // eslint-disable-next-line
                    value={
                      assetOptions.find(
                        (assetOption) =>
                          assetOption.value === assetWithChannel.assetId
                      ) || null
                    }
                    getOptionLabel={(option) => option.label}
                    getOptionDisabled={(option) => {
                      const selectedAsset = assetWithChannels.find(
                        (asset) => asset.assetId === option.value
                      );

                      return Boolean(selectedAsset);
                    }}
                    disabled={
                      isPending ||
                      index < assetWithChannels.length - 1 ||
                      !client ||
                      isViewMode
                    }
                    onChange={(
                      event: React.ChangeEvent<unknown>,
                      option: {
                        label: string;
                        value: number;
                        channels: number[];
                      } | null
                    ) => {
                      const filteredAssetsWithChannels = assetWithChannels.filter(
                        (asset) => asset.assetId
                      );

                      if (option?.value) {
                        const currentAssetWithChannel =
                          filteredAssetsWithChannels[index];

                        if (currentAssetWithChannel) {
                          const edited = filteredAssetsWithChannels.map(
                            (localAssetWithChannel, assetIndex) => {
                              if (assetIndex === index) {
                                return {
                                  assetId: option.value,
                                  disabledChannels: option.channels,
                                  channels: [],
                                };
                              }
                              return localAssetWithChannel;
                            }
                          );

                          return setAssetWithChannels(edited);
                        }

                        return setAssetWithChannels([
                          ...filteredAssetsWithChannels,
                          {
                            assetId: option.value,
                            disabledChannels: [],
                            channels: [],
                          },
                        ]);
                      }

                      const data =
                        assetWithChannels.length === 1
                          ? [
                              {
                                assetId: null,
                                channels: [],
                                disabledChannels: [],
                              },
                            ]
                          : [
                              ...assetWithChannels.slice(
                                0,
                                assetWithChannels.length - 1
                              ),
                            ];

                      setAssetWithChannels(data);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Asset"
                        variant="filled"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {assetWithChannel.assetId && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Assign Channel</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ChannelsSelector
                      onChange={(channels) => {
                        const filteredAssetWithChannels = assetWithChannels.filter(
                          (asset) => asset.assetId !== assetWithChannel.assetId
                        );
                        setAssetWithChannels([
                          ...filteredAssetWithChannels,
                          {
                            assetId: assetWithChannel.assetId,
                            channels,
                            disabledChannels: assetWithChannel.disabledChannels,
                          },
                        ]);
                      }}
                      value={assetWithChannel.channels}
                      channels={deviceChannels}
                      disabled={
                        isPending ||
                        index < assetWithChannels.length - 1 ||
                        isViewMode === true
                      }
                      disabledChannels={[
                        ...assetWithChannel.disabledChannels,
                        ...assetWithChannels
                          .filter(
                            (asset) =>
                              asset.assetId !==
                              assetWithChannels[assetWithChannels.length - 1]
                                .assetId
                          )
                          .reduce(
                            // eslint-disable-next-line
                            (acc: number[], curr: any) => [
                              ...acc,
                              ...curr.channels,
                            ],
                            []
                          ),
                      ]}
                    />
                  </Grid>
                </>
              )}
            </Fragment>
          ))}
          {!(isViewMode === true) && (
            <>
              <Grid item xs={12}>
                <OutlinedPurpleButton
                  color="primary"
                  variant="outlined"
                  startIcon={<Add />}
                  disabled={
                    checkIfIsAddAssetButtonDisabled() || isPending || isViewMode
                  }
                  onClick={() =>
                    setAssetWithChannels([
                      ...assetWithChannels,
                      { assetId: null, channels: [], disabledChannels: [] },
                    ])
                  }
                >
                  SELECT ADDITIONAL ASSET
                </OutlinedPurpleButton>
              </Grid>
              {device ? (
                <Grid item xs={12}>
                  <OutlinedRedButton
                    variant="outlined"
                    disabled={
                      isPending ||
                      isViewMode ||
                      Boolean(device?.clear_in_progress)
                    }
                    onClick={() => {
                      setConfirmClear(true);
                      setOpenedDeviceModal(false);
                    }}
                  >
                    CLEAR DEVICE DATA
                  </OutlinedRedButton>
                  <TooltipIcon text="Select to delete all sensor reading history from this device. If selected, a notification will be sent to you when data is finished clearing." />
                </Grid>
              ) : null}
            </>
          )}
          <Grid item xs={9} sm={4}>
            <Box display="flex">
              <Typography variant="h4">Activate Device</Typography>
              <TooltipIcon text="Activate when you want to receive data from this device and display on its associated assets. Disable when you do not wish to receive data." />
            </Box>
          </Grid>
          <Grid item xs={3} sm={8}>
            <StyledSwitch
              id="isEnabled"
              name="isEnabled"
              value={formik.values.isEnabled}
              checked={formik.values.isEnabled}
              onChange={formik.handleChange}
              disabled={isPending || isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
