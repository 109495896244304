import styled from "styled-components";
import { Fab } from "@material-ui/core";

export const FloatingButton = styled(Fab)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const Form = styled.form`
  flex: 1;
`;
