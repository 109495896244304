import React from "react";
import { useShowForPermissions } from "../../hooks/useShowForPermissions";
import {
  RolePermissions,
  RolePermissionValues,
  RoleDevicePermissionValues,
} from "../../../roles/api/roles.api.dto";

export interface IConnectedShowForPermissions {
  feature: keyof RolePermissions;
  permissions: {
    allOf?: (RolePermissionValues | RoleDevicePermissionValues)[];
    oneOf?: (RolePermissionValues | RoleDevicePermissionValues)[];
  };
  children: React.ReactNode;
}

export const ConnectedShowForPermissions = (
  props: IConnectedShowForPermissions
) => {
  const { feature, permissions, children } = props;
  const show = useShowForPermissions(feature, permissions);
  return show ? <>{children}</> : null;
};
