import React from "react";
import styled from "styled-components";
import {
  TableContainer,
  TableContainerProps,
  TableCell,
  TableRow,
  Card,
  Link,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBox, Check } from "@material-ui/icons";
import { Pagination, PaginationProps } from "@material-ui/lab";
import forms from "../../theme/forms";
import { TEXT_PRIMARY, TEXT_SECONDARY } from "../../theme/colors";

export const UncheckedBoxIcon = CheckBoxOutlineBlank;
export const CheckedBoxIcon = CheckBox;
export const CheckIcon = Check;

export const BlankIcon = styled(UncheckedBoxIcon)`
  opacity: 0;
  visibility: hidden;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const InnerTableWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  position: relative;
`;

export const TableFooter = styled.div`
  border-top: 1px solid #e3e3e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledTablePagination = styled(Pagination)<PaginationProps>`
  display: flex;
`;

export const StyledTableContainer = styled(TableContainer)<TableContainerProps>`
  flex: 1;
  height: 100%;
  position: relative;
`;

export const StyledTableInnerContainer = styled.div<{
  disableAbsolutePositioning?: boolean;
  minWidth?: number;
}>`
  ${({ disableAbsolutePositioning }) =>
    !disableAbsolutePositioning &&
    `
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "auto")};
`;

export const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const StyledTableHeaderCell = styled(TableCell)<{
  noExtraPadding?: boolean;
  alignToFormInputs?: boolean;
  width?: string | number;
}>`
  width: ${({ width }) => `${width || 80}`}px !important;
  font-weight: bold;
  padding: 6px 17px;

  ${({ noExtraPadding }) =>
    !noExtraPadding &&
    `
    padding: 17px;
  `};

  &:first-child {
    padding-left: ${({ theme, alignToFormInputs }) =>
      `${
        alignToFormInputs ? forms.input.paddingLeft : `${theme.spacing(3)}px`
      }`};
  }

  &:last-child {
    padding-right: ${({ theme, alignToFormInputs }) =>
      `${
        alignToFormInputs ? forms.input.paddingRight : `${theme.spacing(3)}px`
      }`};
  }
`;

export const StyledTableBodyCell = styled(
  ({
    alignToFormInputs,
    phoneNumber,
    lastReading,
    width,
    noActionButton,
    ...props
  }) => <TableCell {...props} />
)<{
  alignToFormInputs?: boolean;
  phoneNumber?: boolean;
  lastReading?: boolean;
  width?: string | number;
  noActionButton?: boolean;
}>`
  width: ${({ width }) => `${width || 80}`}px !important;
  padding: ${({ noActionButton }) =>
    noActionButton ? "19px 17px" : "11px 17px"};
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${TEXT_SECONDARY};

  &:first-child {
    padding-left: ${({ theme, alignToFormInputs }) =>
      `${
        alignToFormInputs ? forms.input.paddingLeft : `${theme.spacing(3)}px`
      }`};
  }

  &:last-child {
    padding-right: ${({ theme, alignToFormInputs }) =>
      `${
        alignToFormInputs ? forms.input.paddingRight : `${theme.spacing(3)}px`
      }`};
  }

  // white-space: ${({ phoneNumber }) => `${phoneNumber ? "nowrap" : null}`};
  white-space: nowrap;
`;

export const StyledTableRow = styled(TableRow)`
  td:last-child {
    button {
      visibility: hidden;
    }
  }

  &.MuiTableRow-hover:hover {
    button {
      visibility: visible;
    }
  }

  &:last-child td {
    border-bottom: none;
  }
`;

export const StyledCard = styled(Card)`
  flex: 1;
  height: 100%;
`;

export const ActionCellWrapper = styled.span`
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const TrimActionCellWrapper = styled.span``;

export const StyledNAHealth = styled(Typography)`
  font-weight: 500;
  color: ${TEXT_PRIMARY};
  font-size: 14px;
`;

export const StyledBadHealth = styled.span`
  border-radius: 4px;
  background-color: ${({ theme }) => `${theme.palette.health.bad}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 7px 3px;
`;

export const StyledFairHealth = styled.span`
  border-radius: 4px;
  background-color: ${({ theme }) => `${theme.palette.health.fair}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
`;

export const StyledGoodHealth = styled.span`
  border-radius: 4px;
  background-color: ${({ theme }) => `${theme.palette.health.good}`};
  color: ${({ theme }) => `${theme.palette.text.white}`};
  padding: 2px 4px 3px;
`;

export const CenteredCell = styled.span`
  text-align: center;
  display: inline-block;
  width: 100%;
`;

export const RightAlignedCell = styled.span`
  text-align: right;
  display: inline-block;
  width: 100%;
`;

export const RedText = styled.span`
  color: ${({ theme }) => `${theme.palette.button.error.color}`};
`;

export const StyledLink = styled(Link)<{ component: React.ElementType }>`
  font-weight: ${({ theme }) => `${theme.typography.fontWeightBold}`};
`;

export const StyledUserIcon = styled.div`
  min-width: 32px;
  height: 32px;
  padding: 8px 5px;
  margin-right: 10px;
  background-color: #e3e3e9; 
  border-radius: 50px;
  display: flex;
  flex-direction row;
  justify-content: center;
  align-items: center;
`;

export const StyledUserNameWrapper = styled.span`
  display: flex;
`;

export const StyledUserNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledUserTypography = styled(Typography)`
  font-size: 14px;
  color: ${TEXT_PRIMARY};
`;

export const StyledPartnerTypography = styled(Typography)`
  font-size: 14px;
  color: ${TEXT_SECONDARY};
`;

export const StyledUserEmailTypography = styled(Typography)`
  font-size: 12px;
  color: #aaa;
`;

export const StyledUserIconTypography = styled(Typography)`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1f1e4d;
`;

export const StyledInviteDot = styled.div`
  width: 6px;
  height: 6px;
  margin: 6px 4px 4px 0px;
  background-color: #eaca19;
  border-radius: 50px;
`;

export const TrimmedTableCell = styled(Typography)<
  TypographyProps & { width?: number; component?: string }
>`
  display: inline-block;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
