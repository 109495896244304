import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  StyledCloseIcon,
  StyledDialogTitleContainer,
  StyledEditIcon,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { DeviceModalForm } from "../DeviceModalForm/DeviceModalForm";
import { DeviceListModel } from "../../model/devices.model";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";
import { CreateEditDeviceDto, DeviceListDto } from "../../api/devices.api.dto";
import { AppDispatch } from "../../../app/store";
import { clearDevice } from "../../store/devices.action";

export interface IDeviceModal {
  name: string;
  onClose: () => void;
  // eslint-disable-next-line
  onSubmit: (device: CreateEditDeviceDto, id?: number) => void; // TODO: Add properer CreateEditDeviceModel here
  device?: DeviceListModel | DeviceListDto;
  deviceTypeOptions: { label: string; value: number }[];
  partnerOptions: { label: string; value: number }[];
  clientOptions: { label: string; value: number }[];
  onDelete?: (id: number) => void;
  onEnter?: () => void;
  isPending: boolean;
  onPartnerChange: (partnerId: number | null) => void;
  onClientChange: (partnerId: number) => void;
  assetOptions: { label: string; value: number; channels: number[] }[];
  onError: (message: string) => void;
  onModalMount?: (device: DeviceListModel | DeviceListDto) => void;
  viewOnly?: boolean;
}

export const DeviceModal: FC<IDeviceModal> = ({
  name,
  isPending,
  onClose,
  onSubmit,
  device,
  deviceTypeOptions,
  partnerOptions,
  onDelete,
  onEnter,
  clientOptions,
  onPartnerChange,
  onClientChange,
  assetOptions,
  onError,
  onModalMount,
  viewOnly,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [editView, setEditView] = useState(false);

  const [openedDeviceModal, setOpenedDeviceModal] = useState(true);

  const clearDeviceData = (deviceId: number | undefined) => {
    dispatch(clearDevice(deviceId));
  };

  useEffect(() => {
    if (device?.clear_in_progress) {
      setOpenedDeviceModal(true);
    }
  }, [device]);

  const handleOnDelete = () => {
    if (onDelete && device) {
      onDelete(device.id);
    }
  };

  const getModalHeader = () => {
    if (device && editView) {
      return <Typography variant="h2">Edit Device</Typography>;
    }
    if ((device && !editView) || viewOnly) {
      return <Typography variant="h2">View Device</Typography>;
    }
    return <Typography variant="h2">Add Device</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid container item xs={4} justify="flex-start">
        {editView && !viewOnly && (
          <ConnectedShowForPermissions
            feature="devices"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView && (
          <Box display="inline" mr={1}>
            <Button
              color="primary"
              onClick={() => {
                onClose();
              }}
              disabled={pending}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box display="inline">
          {editView && !viewOnly ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
              }}
              disabled={pending}
              pending={pending}
            >
              Close
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button onClick={onClose} disabled={pending} color="primary">
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add Device
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (device) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalHeader = () => {
    const displayHeaderStyle = {
      display: openedDeviceModal ? "block" : "none",
    };
    return (
      <div style={displayHeaderStyle}>
        <StyledDialogTitleContainer disableTypography>
          <Box>{getModalHeader()}</Box>
          <Box>
            {!editView && device && !viewOnly && (
              <ConnectedShowForPermissions
                feature="devices"
                permissions={{ allOf: ["update"] }}
              >
                <StyledIconButton
                  aria-label="edit"
                  onClick={() => setEditView(true)}
                  disabled={isPending}
                >
                  <StyledEditIcon />
                </StyledIconButton>
              </ConnectedShowForPermissions>
            )}
            <StyledIconButton
              aria-label="close"
              onClick={() => {
                onClose();
              }}
            >
              <StyledCloseIcon />
            </StyledIconButton>
          </Box>
        </StyledDialogTitleContainer>
      </div>
    );
  };

  const ModalContent = (pending: boolean) => {
    const displayContentStyle = {
      display: openedDeviceModal ? "block" : "none",
    };
    return (
      <div style={displayContentStyle}>
        <DeviceModalForm
          device={device}
          deviceTypeOptions={deviceTypeOptions}
          partnerOptions={partnerOptions}
          clientOptions={clientOptions}
          onSubmit={onSubmit}
          displayActionButtons={() => displayActionButtons(pending)}
          isPending={pending}
          onPartnerChange={onPartnerChange}
          onClientChange={onClientChange}
          assetOptions={assetOptions}
          onError={onError}
          onModalMount={onModalMount}
          isViewMode={(device && !editView) || viewOnly}
          clearDevice={clearDeviceData}
          setOpenedDeviceModal={setOpenedDeviceModal}
        />
      </div>
    );
  };
  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
      onEnter={onEnter}
      onExited={() => setEditView(false)}
    />
  );
};
