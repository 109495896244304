import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { RolesTable } from "../component/RolesTable/RolesTable";
import {
  editRole as editRoleAction,
  deleteRole as deleteRoleAction,
  fetchRoles as fetchRolesAction,
} from "../store/roles.action";
import {
  selectRolesTableData,
  selectIsRolesTableStatusPending,
  selectParentRolesData,
  selectIsParentRolesStatusPending,
} from "../store/roles.selector";
import { clearFetchRolesState as clearFetchRolesStateAction } from "../store/roles.slice";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditRoleDto } from "../api/roles.api.dto";
import { fetchPartners as fetchPartnersAction } from "../../partners/store/partners.action";
import { fetchClients as fetchClientsAction } from "../../clients/store/clients.action";
import {
  selectPartnersData,
  selectIsPartnersStatusPending,
} from "../../partners/store/partners.selector";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import { EDIT_ROLE_MODAL } from "../../app/const/modals";

export const RolesTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const rolesTableData = useSelector(selectRolesTableData);
  const isRolesPending = useSelector(selectIsRolesTableStatusPending);
  const parentRolesData = useSelector(selectParentRolesData);
  const isParentRolesPending = useSelector(selectIsParentRolesStatusPending);
  const partnersData = useSelector(selectPartnersData);
  const isFetchPartnersPending = useSelector(selectIsPartnersStatusPending);
  const clientsData = useSelector(selectClientsData);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_ROLE_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_ROLE_MODAL }));

  const onEditRole = (editRoleDto: CreateEditRoleDto, id?: number) =>
    id && dispatch(editRoleAction({ id, editRoleDto }));

  const onDeleteRole = (id: number) => dispatch(deleteRoleAction(id));

  useEffect(() => {
    dispatch(fetchPartnersAction());
    dispatch(fetchClientsAction());
    dispatch(fetchRolesAction());

    return () => {
      dispatch(clearFetchRolesStateAction());
    };
  }, [dispatch]);

  return (
    <RolesTable
      // TODO: quick fix, update model to handle new type
      roles={rolesTableData}
      parentRoles={parentRolesData}
      partners={partnersData}
      clients={clientsData}
      isTablePending={isRolesPending}
      isModalPending={
        isParentRolesPending || isFetchPartnersPending || isFetchClientsPending
      }
      onEditRole={onEditRole}
      onDeleteRole={onDeleteRole}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
