import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { SensorTypesTable } from "../component/SensorTypesTable/SensorTypesTable";
import {
  editSensorType as editSensorTypeAction,
  deleteSensorType as deleteSensorTypeAction,
  fetchSensorTypes as fetchSensorTypesAction,
  fetchHardcodedSensorTypes as fetchHardcodedSensorTypesAction,
  fetchHardcodedSensorUnits as fetchHardcodedSensorUnitsAction,
} from "../store/sensorTypes.action";
import {
  selectSensorTypesTableData,
  selectIsSensorTypesTableStatusPending,
  selectHardcodedSensorTypesData,
  selectIsHardcodedSensorTypesStatusPending,
  selectHardcodedSensorUnitsData,
  selectIsHardcodedSensorUnitsStatusPending,
} from "../store/sensorTypes.selector";
import {
  clearFetchSensorTypesState as clearFetchSensorTypesStateAction,
  clearFetchHardcodedSensorTypesState as clearFetchHardcodedSensorTypesStateAction,
  clearFetchHardcodedSensorUnitsState as clearFetchHardcodedSensorUnitsStateAction,
} from "../store/sensorTypes.slice";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditSensorTypeDto } from "../api/sensorTypes.api.dto";
import { EDIT_SENSOR_TYPE_MODAL } from "../../app/const/modals";

export const SensorTypesTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const sensorTypesTableData = useSelector(selectSensorTypesTableData);
  const hardcodedSensorTypesData = useSelector(selectHardcodedSensorTypesData);
  const hardcodedSensorUnitsData = useSelector(selectHardcodedSensorUnitsData);
  const isSensorTypesPending = useSelector(
    selectIsSensorTypesTableStatusPending
  );
  const isHardcodedSensorTypesPending = useSelector(
    selectIsHardcodedSensorTypesStatusPending
  );
  const isHardcodedSensorUnitsPending = useSelector(
    selectIsHardcodedSensorUnitsStatusPending
  );

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_SENSOR_TYPE_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_SENSOR_TYPE_MODAL }));

  const onEditSensorType = (
    editSensorTypeDto: CreateEditSensorTypeDto,
    id?: number
  ) => id && dispatch(editSensorTypeAction({ id, editSensorTypeDto }));

  const onDeleteSensorType = (id: number) =>
    dispatch(deleteSensorTypeAction(id));

  useEffect(() => {
    dispatch(fetchSensorTypesAction());
    dispatch(fetchHardcodedSensorTypesAction());
    dispatch(fetchHardcodedSensorUnitsAction());

    return () => {
      dispatch(clearFetchSensorTypesStateAction());
      dispatch(clearFetchHardcodedSensorTypesStateAction());
      dispatch(clearFetchHardcodedSensorUnitsStateAction());
    };
  }, [dispatch]);

  return (
    <SensorTypesTable
      // TODO: quick fix, update model to handle new type
      sensorTypes={sensorTypesTableData.map((sensorType: any) => ({
        ...sensorType,
        manufacturer: sensorType.manufacturer || "",
        part_number: sensorType.part_number || "",
      }))}
      hardcodedSensorTypes={hardcodedSensorTypesData}
      hardcodedSensorUnits={hardcodedSensorUnitsData}
      isTablePending={isSensorTypesPending}
      isModalPending={
        isHardcodedSensorTypesPending || isHardcodedSensorUnitsPending
      }
      onEditSensorType={onEditSensorType}
      onDeleteSensorType={onDeleteSensorType}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
