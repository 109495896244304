import React from "react";
import { UserRole } from "../../enum/UserRole";
import { useShowForRole } from "../../hooks/useShowForRole";

export interface IConnectedShowForRole {
  roles: Array<UserRole>;
  children: React.ReactNode;
}

export const ConnectedShowForRole = (props: IConnectedShowForRole) => {
  const { roles, children } = props;
  const show = useShowForRole(roles);
  return show ? <>{children}</> : null;
};
