import React, { FC } from "react";
import {
  InnerWrapper,
  OuterWrapper,
  StyledArrowDropDown,
  StyledArrowDropUp,
} from "./TableSortLabel.style";

export interface ITableSortLabel {
  direction: "asc" | "desc";
  active: boolean;
}

export const TableSortLabel: FC<ITableSortLabel> = ({ direction, active }) => (
  <OuterWrapper>
    <InnerWrapper>
      <StyledArrowDropUp
        color={active && direction === "asc" ? "primary" : "disabled"}
      />
      <StyledArrowDropDown
        color={active && direction === "desc" ? "primary" : "disabled"}
      />
    </InnerWrapper>
  </OuterWrapper>
);
