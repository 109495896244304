import React, { FC, useState } from "react";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import {
  Settings as SettingsIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import {
  Popover,
  Box,
  Link,
  IconButton,
  Badge,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  ApiNotificationType,
  NotificationDto,
} from "../../api/apiNotifications.api.dto";
import { ApiNotificationRow } from "../ApiNotificationRow/ApiNotificationRow";
import {
  StyledTitle,
  StyledMenuItem,
  StyledCard,
} from "./ApiNotifications.style";

import {
  NOTIFICATIONS,
  WORKORDERS,
  DEVICES,
  ASSET_REALTIME,
} from "../../../app/const/routes";

import {
  makeNotificationRead,
  makeNotificationsReadUntil,
} from "../../store/apiNotifications.action";
import { EDIT_TASK_MODAL } from "../../../app/const/modals";
import { openModal } from "../../../app/store/connectedModal/connectedModal.slice";
import { fetchTask } from "../../../workorders/api/workorders.api";

interface IApiNotifications {
  apiNotifications: NotificationDto[];
}

export const ApiNotifications: FC<IApiNotifications> = ({
  apiNotifications,
}) => {
  const history = useHistory<any>();
  const dispatch = useDispatch<any>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  const handleRedirect = async (notification: NotificationDto) => {
    if (!notification.is_read) dispatch(makeNotificationRead(notification));

    switch (notification.type) {
      case ApiNotificationType.Workorder: {
        const { task } = await fetchTask(notification.workorder_id as number);
        history.push(WORKORDERS);
        dispatch(openModal({ name: EDIT_TASK_MODAL, params: { task } }));
        break;
      }
      case ApiNotificationType.WorkorderDone: {
        const { task } = await fetchTask(notification.workorder_id as number);
        history.push(WORKORDERS);
        dispatch(openModal({ name: EDIT_TASK_MODAL, params: { task } }));
        break;
      }

      case ApiNotificationType.Info:
        history.push(DEVICES);
        break;

      case ApiNotificationType.Alarm:
        history.push(
          ASSET_REALTIME.replace(
            ":assetId",
            (notification.asset_id ?? "").toString()
          )
        );
        break;

      case ApiNotificationType.LowHealth:
        history.push(
          ASSET_REALTIME.replace(
            ":assetId",
            (notification.asset_id ?? "").toString()
          )
        );
        break;

      case ApiNotificationType.LostConnection:
        history.push(DEVICES);
        break;

      default:
        throw new Error("No notification type found");
    }
    handleClose();
  };

  const handleMarkAllAsRead = () => {
    dispatch(makeNotificationsReadUntil(apiNotifications[0].timestamp));
    handleMoreClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <NotificationsNoneOutlinedIcon style={{ color: "#1f1e4d" }} />
        <Badge
          style={{ marginTop: "-1rem" }}
          badgeContent={apiNotifications.filter((e) => !e.is_read).length}
          max={9}
          color="error"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </IconButton>

      <Popover
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Box minWidth={480}>
          <StyledCard>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={1}
              pb={1}
              pl={3}
              pr={2}
            >
              <StyledTitle variant="h4">Notifications</StyledTitle>
              <Link component={RouterLink} to={NOTIFICATIONS}>
                <IconButton onClick={handleClose}>
                  <SettingsIcon />
                </IconButton>
              </Link>
              <IconButton onClick={handleMoreClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleMoreClose}
                PaperProps={{
                  style: {
                    minWidth: "200px",
                  },
                }}
              >
                <MenuItem key="mark-all-as-read" onClick={handleMarkAllAsRead}>
                  Mark all as read
                </MenuItem>
              </Menu>
            </Box>
          </StyledCard>
        </Box>
        {!apiNotifications.length && (
          <Box textAlign="center" py={2} px={4}>
            No New Notification
          </Box>
        )}
        {apiNotifications.map((apiNotification) => (
          <StyledMenuItem key={apiNotification.id}>
            <ApiNotificationRow
              onClick={handleRedirect}
              apiNotification={apiNotification}
            />
          </StyledMenuItem>
        ))}
      </Popover>
    </>
  );
};
