import { createSlice } from "@reduxjs/toolkit";
import { maintenanceState } from "./maintenance.store";
import {
  fetchMaintenances,
  deleteMaintenance,
  editMaintenance,
  createMaintenance,
} from "./maintenance.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "maintenance",
  initialState: maintenanceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMaintenances.pending, (state, { meta: { arg } }) => ({
      ...state,
      maintenances: {
        ...state.maintenances,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchMaintenances.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          maintenances: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }
      return state;
    });
    builder.addCase(fetchMaintenances.rejected, (state) => ({
      ...state,
      maintenances: {
        ...state.maintenances,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteMaintenance.pending, (state) => ({
      ...state,
      deleteMaintenance: {
        ...state.deleteMaintenance,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteMaintenance.fulfilled, (state) => ({
      ...state,
      deleteMaintenance: {
        ...state.deleteMaintenance,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteMaintenance.rejected, (state) => ({
      ...state,
      deleteMaintenance: {
        ...state.deleteMaintenance,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editMaintenance.pending, (state) => ({
      ...state,
      editMaintenance: {
        ...state.editMaintenance,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editMaintenance.fulfilled, (state, { payload }) => ({
      ...state,
      editMaintenance: {
        ...state.editMaintenance,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editMaintenance.rejected, (state) => ({
      ...state,
      editMaintenance: {
        ...state.editMaintenance,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createMaintenance.pending, (state) => ({
      ...state,
      createMaintenance: {
        ...state.createMaintenance,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createMaintenance.fulfilled, (state) => ({
      ...state,
      createMaintenance: {
        ...state.createMaintenance,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createMaintenance.rejected, (state) => ({
      ...state,
      createMaintenance: {
        ...state.createMaintenance,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
