import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";
import { Card, Typography } from "@material-ui/core";

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  text-align: center;
`;

export const StyledTypography = styled(Typography)`
  margin-top: 26px;
`;

export const StyledIcon = styled(ErrorIcon)`
  margin-bottom: 16px;
  font-size: 60px;
`;
