import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { DeviceListDto } from "../api/devices.api.dto";

export interface DevicesState {
  fetchDevices: AsyncStateModel<DeviceListDto[]>;
  searchDevices: string;
}

export const devicesState: DevicesState = {
  fetchDevices: {
    status: AsyncStatus.Void,
    data: [],
  },
  searchDevices: "",
};
