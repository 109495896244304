import React, { FC, useState } from "react";
import { Column } from "react-table";
import { Table } from "../../../app/component/Table/Table";
import {
  ActionCellWrapper,
  StyledCard,
  RightAlignedCell,
} from "../../../app/component/Table/Table.style";
import { Button } from "../../../app/component/Button/Button";
import {
  SensorTypeDto,
  CreateEditSensorTypeDto,
  HardcodedSensorType,
  HardcodedSensorUnit,
} from "../../api/sensorTypes.api.dto";
import { SensorTypeModal } from "../SensorTypeModal/SensorTypeModal";
import { EDIT_SENSOR_TYPE_MODAL } from "../../../app/const/modals";

interface ISensorTypesTable {
  onEditSensorType: (
    editSensorTypeDto: CreateEditSensorTypeDto,
    id?: number
  ) => void;
  onDeleteSensorType: (id: number) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  sensorTypes: SensorTypeDto[];
  hardcodedSensorTypes: HardcodedSensorType[];
  hardcodedSensorUnits: HardcodedSensorUnit[];
  isTablePending: boolean;
  isModalPending: boolean;
}

export const SensorTypesTable: FC<ISensorTypesTable> = ({
  sensorTypes,
  hardcodedSensorTypes,
  hardcodedSensorUnits,
  isTablePending,
  isModalPending,
  onEditSensorType,
  onDeleteSensorType,
  onOpenModal,
  onCloseModal,
}) => {
  const [selectedSensorType, setSelectedSensorType] = useState<
    SensorTypeDto | undefined
  >(undefined);

  const openModal = (sensorType: SensorTypeDto) => {
    setSelectedSensorType(sensorType);
    onOpenModal();
  };

  const closeModal = () => {
    setSelectedSensorType(undefined);
    onCloseModal();
  };

  const columns: Column[] = [
    {
      Header: "Sensor Type",
      id: "sensor_type",
      accessor: "sensor_type",
    },
    {
      Header: () => <RightAlignedCell>Range</RightAlignedCell>,
      accessor: "range_min",
      id: "range_min",
      Cell: ({ row: { original } }: { row: { original: SensorTypeDto } }) => (
        <RightAlignedCell>
          {`${original.range_min} - ${original.range_max} ${original.unit_name}`}
        </RightAlignedCell>
      ),
    },
    {
      Header: () => <RightAlignedCell>In Use</RightAlignedCell>,
      id: "in_use_count",
      accessor: "in_use_count",
      Cell: ({ row: { original } }: { row: { original: SensorTypeDto } }) => (
        <RightAlignedCell>{original.in_use_count}</RightAlignedCell>
      ),
    },
    {
      Header: () => <RightAlignedCell>Manufacturer</RightAlignedCell>,
      id: "manufacturer",
      accessor: "manufacturer",
      Cell: ({ row: { original } }: { row: { original: SensorTypeDto } }) => (
        <RightAlignedCell>{original.manufacturer}</RightAlignedCell>
      ),
    },
    {
      Header: () => <RightAlignedCell>OEM Part Number</RightAlignedCell>,
      id: "oem_part_number",
      accessor: "part_number",
      Cell: ({ row: { original } }: { row: { original: SensorTypeDto } }) => (
        <RightAlignedCell>{original.oem_part_number}</RightAlignedCell>
      ),
    },
    {
      Header: () => <RightAlignedCell>Tako Part Number</RightAlignedCell>,
      id: "part_number",
      accessor: "part_number",
      Cell: ({ row: { original } }: { row: { original: SensorTypeDto } }) => (
        <RightAlignedCell>{original.part_number}</RightAlignedCell>
      ),
    },
    {
      id: "actions-column",
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: { original: SensorTypeDto } }) => (
        <ActionCellWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openModal(original);
            }}
          >
            View
          </Button>
        </ActionCellWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledCard>
        <Table
          loading={isTablePending}
          columns={columns}
          data={sensorTypes}
          hover
          sorting={{ id: "sensor_type", desc: false }}
        />
      </StyledCard>
      <SensorTypeModal
        name={EDIT_SENSOR_TYPE_MODAL}
        sensorType={selectedSensorType}
        hardcodedSensorTypes={hardcodedSensorTypes}
        hardcodedSensorUnits={hardcodedSensorUnits}
        onClose={closeModal}
        onSubmit={onEditSensorType}
        onDelete={onDeleteSensorType}
        isPending={isModalPending}
      />
    </>
  );
};
