import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { ClientState } from "./clients.store";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  ClientAverageHealthDto,
  ClientDto,
  RecentActivityDto,
} from "../api/clients.api.dto";
import { ClientModel } from "../model/clients.model";
import { ClientsStatus } from "../enum/clients.enum";
import { AssetDto, AssetTodosDto } from "../../asset/api/asset.api.dto";
import { AssetStatus } from "../../asset/enum/assets.enum";

export const selectSelf = (state: RootState): RootState => state;

export const selectClientsRootState = createSelector(
  selectSelf,
  (state: RootState): ClientState => state.clients
);

export const selectClientsState = createSelector(
  selectClientsRootState,
  (clients: ClientState): AsyncStateModel<ClientDto[]> => clients.clients
);

export const selectClientsData = createSelector(
  selectClientsState,
  (clients: AsyncStateModel<ClientDto[]>): ClientDto[] => clients.data
);

export const selectClientsStatus = createSelector(
  selectClientsState,
  (clients: AsyncStateModel<ClientDto[]>): AsyncStatus => clients.status
);

export const selectIsClientsStatusPending = createSelector(
  selectClientsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchClients = createSelector(
  selectClientsRootState,
  (clients): string => clients.searchClients
);

export const selectClientsTableData = createSelector(
  selectSearchClients,
  selectClientsData,
  (searchValue, clients): ClientModel[] => {
    const data = clients.map((client) => ({
      ...client,
      phone: client.phone || "",
      status: client.is_enabled ? ClientsStatus.Active : ClientsStatus.Inactive,
    }));

    if (searchValue) {
      return data.filter(
        (client) =>
          client.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          client.partner?.name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          client.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          client.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
          client.status.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectIsClientsTableStatusPending = createSelector(
  selectClientsStatus,
  (status: AsyncStatus): boolean =>
    status === AsyncStatus.Pending || status === AsyncStatus.Void
);

export const selectCreateClientState = createSelector(
  selectClientsRootState,
  (clients): AsyncStateModel<null> => clients.createClient
);

export const selectCreateClientStatus = createSelector(
  selectCreateClientState,
  (createClient): AsyncStatus => createClient.status
);

export const selectIsCreateClientStatusPending = createSelector(
  selectCreateClientStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditClientState = createSelector(
  selectClientsRootState,
  (clients): AsyncStateModel<null> => clients.editClient
);

export const selectEditClientStatus = createSelector(
  selectEditClientState,
  (editClient): AsyncStatus => editClient.status
);

export const selectIsEditClientStatusPending = createSelector(
  selectEditClientStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteClientState = createSelector(
  selectClientsRootState,
  (clients): AsyncStateModel<null> => clients.deleteClient
);

export const selectDeleteClientStatus = createSelector(
  selectDeleteClientState,
  (deleteClient): AsyncStatus => deleteClient.status
);

export const selectIsDeleteClientStatusPending = createSelector(
  selectDeleteClientStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectAverageHealthState = createSelector(
  selectClientsRootState,
  (clients): AsyncStateModel<ClientAverageHealthDto | null> =>
    clients.averageHealth
);

export const selectAverageHealthStateData = createSelector(
  selectAverageHealthState,
  (averageHealth): ClientAverageHealthDto | null => averageHealth.data
);

export const selectAverageHealthStatus = createSelector(
  selectAverageHealthState,
  (averageHealth): AsyncStatus => averageHealth.status
);

export const selectIsAverageHealthStatusPending = createSelector(
  selectAverageHealthStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectRecentActivityState = createSelector(
  selectClientsRootState,
  (clients): AsyncStateModel<RecentActivityDto[]> => clients.recentActivities
);

export const selectRecentActivityStateData = createSelector(
  selectRecentActivityState,
  (recentActivities): RecentActivityDto[] => recentActivities.data
);

export const selectIsRecentActivityStatusPending = createSelector(
  selectRecentActivityState,
  (recentActivities): boolean => recentActivities.status === AsyncStatus.Pending
);

export const selectTodosState = createSelector(
  selectClientsRootState,
  (clients): AsyncStateModel<AssetTodosDto[]> => clients.todos
);

export const selectTodosStateData = createSelector(
  selectTodosState,
  (todos): AssetTodosDto[] =>
    todos.data.filter((sensor) => {
      const assetStatus = sensor.equipment?.cached_active_status;
      return sensor.equipment && assetStatus !== AssetStatus.Off;
    })
);

export const selectIsTodosStatusPending = createSelector(
  selectTodosState,
  (todos): boolean => todos.status === AsyncStatus.Pending
);
