import { Box } from "@material-ui/core";
import React, { FC } from "react";
import {
  FullHeightContainer,
  StyledPage,
  StyledFormContainer,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { UserProfileContainer } from "../../container/UserProfileContainer";

export const ProfileView: FC = () => (
  <StyledPage title="Profile" minHeight>
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Profile" />
      </PageHeaderContainer>
      <Box
        flexDirection="column"
        height="100%"
        justifyContent="center"
        flex={1}
      >
        <StyledFormContainer>
          <UserProfileContainer />
        </StyledFormContainer>
      </Box>
    </FullHeightContainer>
  </StyledPage>
);
