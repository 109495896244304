import { createAsyncThunk } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  fetchSensorTypes as fetchSensorTypesRequest,
  fetchHardcodedSensorTypes as fetchHardcodedSensorTypesRequest,
  fetchHardcodedSensorUnits as fetchHardcodedSensorUnitsRequest,
  createSensorType as createSensorTypeRequest,
  deleteSensorType as deleteSensorTypeRequest,
  editSensorType as editSensorTypeRequest,
} from "../api/sensorTypes.api";
import withError from "../../app/util/with-thunk-error";
import {
  setModalStatusPending,
  closeModal,
  setModalStatusRejected,
} from "../../app/store/connectedModal/connectedModal.slice";
import {
  ADD_SENSOR_TYPE_MODAL,
  EDIT_SENSOR_TYPE_MODAL,
} from "../../app/const/modals";
import {
  SensorTypeDto,
  CreateEditSensorTypeDto,
  HardcodedSensorType,
  HardcodedSensorUnit,
} from "../api/sensorTypes.api.dto";

export const fetchSensorTypes = createAsyncThunk(
  "sensorTypes/fetchSensorTypes",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<SensorTypeDto[]> => fetchSensorTypesRequest()
  )
);

export const fetchHardcodedSensorTypes = createAsyncThunk(
  "sensorTypes/fetchHardcodedSensorTypes",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<HardcodedSensorType[]> => fetchHardcodedSensorTypesRequest()
  )
);

export const fetchHardcodedSensorUnits = createAsyncThunk(
  "sensorTypes/fetchHardcodedSensorUnits",
  withError(
    // eslint-disable-next-line
    async (arg: undefined): Promise<HardcodedSensorUnit[]> => fetchHardcodedSensorUnitsRequest()
  )
);

export const createSensorType = createAsyncThunk(
  "sensorTypes/createSensorType",
  withError(
    async (
      sensorType: CreateEditSensorTypeDto,
      { dispatch }
    ): Promise<void> => {
      dispatch(setModalStatusPending({ name: ADD_SENSOR_TYPE_MODAL }));
      await createSensorTypeRequest(sensorType);
      dispatch(closeModal({ name: ADD_SENSOR_TYPE_MODAL }));
      dispatch(openSuccessNotification("Sensor has been added"));
      dispatch(fetchSensorTypes());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: ADD_SENSOR_TYPE_MODAL }))
  )
);

export const deleteSensorType = createAsyncThunk(
  "sensorTypes/deleteSensorType",
  withError(
    async (id: number, { dispatch }): Promise<void> => {
      dispatch(setModalStatusPending({ name: EDIT_SENSOR_TYPE_MODAL }));
      await deleteSensorTypeRequest(id);
      dispatch(closeModal({ name: EDIT_SENSOR_TYPE_MODAL }));
      dispatch(openSuccessNotification("Sensor has been deleted"));
      dispatch(fetchSensorTypes());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_SENSOR_TYPE_MODAL }))
  )
);

export const editSensorType = createAsyncThunk(
  "sensorTypes/editSensorType",
  withError(
    async (
      {
        id,
        editSensorTypeDto,
      }: {
        id: number;
        editSensorTypeDto: CreateEditSensorTypeDto;
      },
      { dispatch }
    ): Promise<void> => {
      const formData = serialize(editSensorTypeDto);
      dispatch(setModalStatusPending({ name: EDIT_SENSOR_TYPE_MODAL }));
      await editSensorTypeRequest(id, formData);
      dispatch(closeModal({ name: EDIT_SENSOR_TYPE_MODAL }));
      dispatch(openSuccessNotification("Sensor has been updated"));
      dispatch(fetchSensorTypes());
    },
    (args, { dispatch }) =>
      dispatch(setModalStatusRejected({ name: EDIT_SENSOR_TYPE_MODAL }))
  )
);
