import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { UsersTable } from "../component/UsersTable/UsersTable";
import {
  editUser as editUserAction,
  deleteUser as deleteUserAction,
  fetchUsers as fetchUsersAction,
} from "../store/users.action";
import {
  selectUsersTableData,
  selectIsUsersTableStatusPending,
} from "../store/users.selector";
import { clearFetchUsersState as clearFetchUsersStateAction } from "../store/users.slice";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditUserModel } from "../model/users.model";
import { fetchPartners as fetchPartnersAction } from "../../partners/store/partners.action";
import { fetchClients as fetchClientsAction } from "../../clients/store/clients.action";
import { fetchTeams as fetchTeamsAction } from "../../teams/store/teams.action";
import { fetchRoles as fetchRolesAction } from "../../roles/store/roles.action";
import {
  selectPartnersData,
  selectIsPartnersStatusPending,
} from "../../partners/store/partners.selector";
import {
  selectClientsData,
  selectIsClientsStatusPending,
} from "../../clients/store/clients.selector";
import {
  selectTeamsData,
  selectIsTeamsStatusPending,
} from "../../teams/store/teams.selector";
import {
  selectRolesData,
  selectIsRolesStatusPending,
} from "../../roles/store/roles.selector";
import { EDIT_USER_MODAL } from "../../app/const/modals";

export const UsersTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const usersTableData = useSelector(selectUsersTableData);
  const partnersData = useSelector(selectPartnersData);
  const clientsData = useSelector(selectClientsData);
  const teamsData = useSelector(selectTeamsData);
  const rolesData = useSelector(selectRolesData);
  const isUsersPending = useSelector(selectIsUsersTableStatusPending);
  const isFetchPartnersPending = useSelector(selectIsPartnersStatusPending);
  const isFetchClientsPending = useSelector(selectIsClientsStatusPending);
  const isFetchTeamsPending = useSelector(selectIsTeamsStatusPending);
  const isFetchRolesPending = useSelector(selectIsRolesStatusPending);

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_USER_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_USER_MODAL }));

  const onEditUser = (editUserModel: CreateEditUserModel, id?: number) =>
    id && dispatch(editUserAction({ id, editUserModel }));

  const onDeleteUser = (id: number) => dispatch(deleteUserAction(id));

  useEffect(() => {
    dispatch(fetchPartnersAction());
    dispatch(fetchClientsAction());
    dispatch(fetchTeamsAction());
    dispatch(fetchRolesAction());
    dispatch(fetchUsersAction());

    return () => {
      dispatch(clearFetchUsersStateAction());
    };
  }, [dispatch]);

  return (
    <UsersTable
      users={usersTableData.map((user: any) => ({
        ...user,
        team_names:
          user.teams && user.teams.length > 0
            ? user.teams.map((team: any) => team.name).join(", ")
            : "All",
        role_name: user.role_name ?? "None",
        phone: user.phone ?? "",
      }))}
      partners={partnersData}
      clients={clientsData}
      teams={teamsData}
      roles={rolesData}
      isTablePending={isUsersPending}
      isModalPending={
        isFetchPartnersPending ||
        isFetchClientsPending ||
        isFetchTeamsPending ||
        isFetchRolesPending
      }
      onEditUser={onEditUser}
      onDeleteUser={onDeleteUser}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
