import React, { FC } from "react";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import FilterIcon from "../../../../assets/filter-icon.png";
import {
  StyledAutocompleteWrapper,
  FilterButton,
  StyledMenuItem,
  StyledMenu,
  StyledFilterBox,
} from "./AssetTagsFilter.style";

export interface IAssetTagsFilter {
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleChange: (event: React.ChangeEvent<unknown>, newValue: string[]) => void;
  tags: string[];
  anchorEl: null | HTMLElement;
  selectedFilters: string[];
}
export const AssetTagsFilter: FC<IAssetTagsFilter> = ({
  handleClose,
  handleClick,
  handleChange,
  tags,
  anchorEl,
  selectedFilters,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      endAdornment: {
        "& .MuiAutocomplete-endAdornment": {
          top: "6px",
        },
      },
    })
  );
  const classes = useStyles();

  return (
    <div>
      <FilterButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="Filter icon" />
        <span>{`Tags (${selectedFilters.length})`}</span>
      </FilterButton>
      <StyledMenu
        elevation={5}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <StyledAutocompleteWrapper>
            <Autocomplete
              className={selectedFilters.length > 0 ? classes.endAdornment : ""}
              multiple
              id="tags-filter"
              options={tags.sort()}
              value={selectedFilters}
              getOptionLabel={(option) => option}
              onChange={(event, value) => {
                handleChange(event, value);
              }}
              renderOption={(option) => (
                <Typography style={{ fontSize: "small" }}>{option}</Typography>
              )}
              renderInput={(params) => (
                <StyledFilterBox
                  {...params}
                  variant="outlined"
                  style={{ padding: "10px" }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </StyledAutocompleteWrapper>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};
