import { Shadows } from "@material-ui/core/styles/shadows";
import createMuiTheme, {
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
} from "@material-ui/core/styles/createMuiTheme";

import shadows from "./shadows";
import typography from "./typography";
import forms from "./forms";
import {
  BACKGROUND_DARK,
  BACKGROUND_DEFAULT,
  BACKGROUND_PAPER,
  BORDER_LIGHT,
  BORDER_DARK,
  BUTTON_PRIMARY,
  BUTTON_PRIMARY_HOVER,
  BUTTON_PRIMARY_TEXT,
  BUTTON_PRIMARY_HOVER_TEXT,
  BUTTON_SECONDARY,
  BUTTON_SECONDARY_HOVER,
  BUTTON_SECONDARY_HOVER_TEXT,
  BUTTON_SECONDARY_TEXT,
  BUTTON_TERTIARY,
  BUTTON_TERTIARY_HOVER,
  BUTTON_TERTIARY_HOVER_TEXT,
  BUTTON_TERTIARY_TEXT,
  BUTTON_ERROR,
  BUTTON_ERROR_HOVER,
  BUTTON_ERROR_HOVER_TEXT,
  BUTTON_ERROR_TEXT,
  BUTTON_CANCEL,
  BUTTON_CANCEL_TEXT,
  MAIN,
  TEXT_LINK,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_DISABLED,
  TEXT_WHITE,
  APP_BAR_BACKGROUND,
  APP_BAR_TEXT,
  DRAWER_BACKGROUND,
  DRAWER_TEXT,
  AVATAR_BACKGROUND,
  AVATAR_TEXT,
  TAB_PRIMARY_BACKGROUND,
  TAB_PRIMARY_COLOR,
  TAB_DIVIDER_COLOR,
  ICON_ERROR_COLOR,
  ICON_ERROR_OUTLINE,
  CARD_SECONDARY_BACKGROUND,
  ASSET_RUNTIME_LABEL,
  ASSET_RUNTIME_DATA,
  ICON_GREEN_OUTLINE,
  ICON_GREEN_COLOR,
  ICON_BLUE_OUTLINE,
  ICON_BLUE_COLOR,
  HEALTH_GAUGE_BAD,
  HEALTH_GAUGE_FAIR,
  HEALTH_GAUGE_GOOD,
  BELL_COLOR,
  HIGHLIGHT_BACKGROUND,
  PROFILE_BACKGROUND,
  INPUT_BACKGROUND,
  BUTTON_PRIMARY_INVERSE_HOVER,
} from "./colors";

type ThemeOptions = MuiThemeOptions;

export type Theme = MuiTheme;
const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    background: {
      dark: BACKGROUND_DARK,
      default: BACKGROUND_DEFAULT,
      paper: BACKGROUND_PAPER,
    },
    border: {
      light: BORDER_LIGHT,
      dark: BORDER_DARK,
    },
    primary: {
      main: MAIN,
    },
    secondary: {
      main: MAIN,
    },
    text: {
      primary: TEXT_PRIMARY,
      secondary: TEXT_SECONDARY,
      disabled: TEXT_DISABLED,
      link: TEXT_LINK,
      white: TEXT_WHITE,
    },
    health: {
      bad: HEALTH_GAUGE_BAD,
      fair: HEALTH_GAUGE_FAIR,
      good: HEALTH_GAUGE_GOOD,
    },
    tab: {
      primary: {
        backgroundColor: TAB_PRIMARY_BACKGROUND,
        color: TAB_PRIMARY_COLOR,
      },
      divider: {
        color: TAB_DIVIDER_COLOR,
      },
    },
    button: {
      error: {
        backgroundColor: BUTTON_ERROR,
        color: BUTTON_ERROR_TEXT,
        hover: {
          backgroundColor: BUTTON_ERROR_HOVER,
          color: BUTTON_ERROR_HOVER_TEXT,
        },
      },
      highlight: {
        primary: {
          backgroundColor: HIGHLIGHT_BACKGROUND,
        },
        secondary: {
          backgroundColor: PROFILE_BACKGROUND,
        },
      },
    },
    block: {
      color: ASSET_RUNTIME_LABEL,
      dataColor: ASSET_RUNTIME_DATA,
    },
    icon: {
      error: {
        outline: {
          backgroundColor: ICON_ERROR_OUTLINE,
        },
        color: ICON_ERROR_COLOR,
      },
      green: {
        outline: {
          backgroundColor: ICON_GREEN_OUTLINE,
        },
        color: ICON_GREEN_COLOR,
      },
      blue: {
        outline: {
          backgroundColor: ICON_BLUE_OUTLINE,
        },
        color: ICON_BLUE_COLOR,
      },
      bell: {
        color: BELL_COLOR,
      },
    },
    card: {
      secondary: CARD_SECONDARY_BACKGROUND,
    },
    avatar: {
      text: AVATAR_TEXT,
      background: AVATAR_BACKGROUND,
    },
  },
  shadows: shadows as Shadows,
  typography,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: APP_BAR_BACKGROUND,
        color: APP_BAR_TEXT,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: DRAWER_BACKGROUND,
        color: DRAWER_TEXT,
      },
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
      body: {
        color: TEXT_SECONDARY,
      },
      stickyHeader: {
        backgroundColor: "#fff",
        whiteSpace: "nowrap",
      },
    },
    MuiButton: {
      textPrimary: {
        backgroundColor: BUTTON_CANCEL,
        color: BUTTON_CANCEL_TEXT,
      },
      containedPrimary: {
        backgroundColor: BUTTON_PRIMARY,
        color: BUTTON_PRIMARY_TEXT,
        hover: {
          background: BUTTON_PRIMARY_INVERSE_HOVER,
          text: BUTTON_PRIMARY_HOVER,
        },
        "&:hover": {
          backgroundColor: BUTTON_PRIMARY_HOVER,
          color: BUTTON_PRIMARY_HOVER_TEXT,
        },
      },
      containedSecondary: {
        backgroundColor: BUTTON_SECONDARY,
        color: BUTTON_SECONDARY_TEXT,
        "&:hover": {
          backgroundColor: BUTTON_SECONDARY_HOVER,
          color: BUTTON_SECONDARY_HOVER_TEXT,
        },
      },
      outlinedPrimary: {
        backgroundColor: "transparent",
        borderColor: MAIN,
        "&:hover": {
          color: MAIN,
        },
      },
      root: {
        backgroundColor: BUTTON_TERTIARY,
        color: BUTTON_TERTIARY_TEXT,
        "&:hover": {
          backgroundColor: BUTTON_TERTIARY_HOVER,
          color: BUTTON_TERTIARY_HOVER_TEXT,
        },
      },
      containedSizeLarge: {
        fontSize: "14px",
      },
      label: {
        whiteSpace: "nowrap",
      },
    },
    MuiInputBase: {
      input: {
        paddingTop: forms.input.paddingTop,
        paddingRight: forms.input.paddingRight,
        paddingBottom: forms.input.paddingBottom,
        paddingLeft: forms.input.paddingLeft,
        color: TEXT_SECONDARY,
      },
      inputMultiline: { color: "#000" },
    },
    MuiBadge: { colorError: { backgroundColor: "#FF0033" } },
    MuiOutlinedInput: {
      input: {
        color: "#000",
      },
    },
    MuiFilledInput: {
      root: {
        color: TEXT_SECONDARY,
        backgroundColor: INPUT_BACKGROUND,
        fontSize: "14px",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
        "&$disabled": {
          backgroundColor: INPUT_BACKGROUND,
        },
        "&$focused": {
          backgroundColor: INPUT_BACKGROUND,
        },
      },
      input: {
        paddingTop: forms.input.paddingTop,
        paddingRight: forms.input.paddingRight,
        paddingBottom: forms.input.paddingBottom,
        paddingLeft: forms.input.paddingLeft,
        color: TEXT_SECONDARY,
        fontSize: "14px",
      },
      inputAdornedStart: { marginTop: 16 },
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        fontFamily: "Roboto",
        "&$disabled": {
          color: TEXT_SECONDARY,
        },
      },
    },
    MuiDivider: {
      root: {
        height: 2,
        backgroundColor: "#777777",
      },
    },
    MuiIconButton: {
      root: {
        paddingTop: forms.iconButton.paddingTop,
        paddingRight: forms.iconButton.paddingRight,
        paddingBottom: forms.iconButton.paddingBottom,
        paddingLeft: forms.iconButton.paddingLeft,
      },
    },
    MuiLink: {
      root: {
        fontWeight: typography.link.fontWeight,
        fontSize: typography.link.fontSize,
        color: typography.link.color,
        "&:hover": {
          color: typography.link.hover.color,
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: "10px !important",
        paddingBottom: "8px !important",
      },
      endAdornment: { position: "absolute", right: "3px", top: "12.5px" },
      tag: { margin: 0, marginTop: "18px", marginRight: "6px" },
    },
    MuiChip: {
      label: {
        color: TEXT_SECONDARY,
        fontSize: "14px",
        fontFamily: "Roboto",
      },
      labelSmall: {
        color: "black",
      },
    },
    MuiTypography: {
      colorPrimary: { fontWeight: 500 },
    },
    MuiSwitch: {
      switchBase: {
        "&.Mui-disabled": {
          "& .MuiSwitch-thumb": {
            boxShadow: "unset",
          },
        },
        // thumb when unchecked
        color: "#F1F1F1",
        opacity: 1,
        "&$checked": {
          // thumb when checked
          color: MAIN,
          opacity: 1,
          // track when checked
          "& + $track": {
            backgroundColor: "#DDDDFA",
            opacity: 0.3,
          },
          "&$disabled": {
            // thumb when unchecked and disabled
            color: "#BDBDBD",
            opacity: 0.8,
            "&$checked": {
              // thumb when checked and disabled
              color: "#A3A4F3",
              opacity: 1,
              // track when checked and disabled
              "& + $track": {
                backgroundColor: "#DDDDFA",
                opacity: 0.7,
              },
            },
          },
        },
      },
      thumb: {
        boxShadow:
          "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
      },
      checked: {},
      track: { backgroundColor: "#000", opacity: 0.24 },
      disabled: {},
    },
    MuiCheckbox: { root: { color: MAIN } },
    MuiTooltip: { tooltip: { fontSize: 12 } },
  },
  breakpoints: {
    brakpoints: { ...defaultTheme.breakpoints },
  },
} as ThemeOptions) as Theme;

export default theme;
