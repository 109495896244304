import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@material-ui/core";

import {
  StyledCard,
  StyledIcon,
  StyledTypography,
} from "./ResetPasswordError.style";
import { FORGOT_PASSWORD } from "../../../app/const/routes";

export const ResetPasswordError: FC = () => (
  <StyledCard>
    <Box mb={2}>
      <StyledIcon color="primary" />
      <Typography color="textPrimary" variant="h2">
        Link expired
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="body1">
        Reset password link has been expired
      </Typography>
    </Box>
    <Typography color="textSecondary" gutterBottom variant="body1">
      Unfortunately, the reset password link has been expired. Use forgot
      password page to generate new link.
    </Typography>
    <StyledTypography color="textSecondary" variant="body1">
      <Link component={RouterLink} to={FORGOT_PASSWORD}>
        Forgot password
      </Link>
    </StyledTypography>
  </StyledCard>
);
