import { authApiClient } from "../../app/api/authApi.client";
import { AlertModel } from "../model/alerts.model";
import { AlertDto } from "./alerts.api.dto";
import { mapAlertDtoToAlertModel } from "./alerts.api.mapper";

export const fetchAlerts = async (clientId: number): Promise<AlertModel[]> => {
  try {
    const response = await authApiClient.get(
      `active-alerts-json-data?client_id=${clientId}`
    );
    const alertModel: AlertModel[] = response.data.map((alertDto: AlertDto) =>
      mapAlertDtoToAlertModel(alertDto)
    );

    return alertModel;
  } catch (error) {
    throw error;
  }
};
