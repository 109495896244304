import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import {
  clearSearchRolesState as clearSearchRolesStateAction,
  searchRoles as searchRolesAction,
} from "../store/roles.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";
import { selectIsRolesTableStatusPending } from "../store/roles.selector";

export const RolesSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isRolesPending = useSelector(selectIsRolesTableStatusPending);

  useEffect(() => {
    return () => {
      dispatch(clearSearchRolesStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchRolesAction(value));

  return (
    <TableSearchInput
      label="Search Roles"
      onSearch={debounce(onSearch, 200)}
      isPending={isRolesPending}
    />
  );
};
