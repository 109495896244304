import { authApiClient } from "../../app/api/authApi.client";
import { BookmarkModel } from "../model/bookmarks.model";
import { BookmarkDto } from "./bookmarks.api.dto";
import { mapBookmarkDtoToModel } from "./bookmarks.api.mapper";

export const fetchBookmarks = async (): Promise<BookmarkModel[]> => {
  try {
    const response = await authApiClient.get("bookmarks");

    return response.data.map((bookmarkDto: BookmarkDto) =>
      mapBookmarkDtoToModel(bookmarkDto)
    );
  } catch (error) {
    throw error;
  }
};

export const repositionBookmarks = async (
  bookmarksIds: number[]
): Promise<void> => {
  try {
    await authApiClient.post("bookmarks/reposition", {
      bookmarks: bookmarksIds,
    });
  } catch (error) {
    throw error;
  }
};
