import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { AppDispatch } from "../../app/store";
import {
  fetchAssetSensors as fetchAssetSensorsAction,
  fetchSensors as fetchModalSensorsAction,
  editSensor as editSensorAction,
  deleteSensor as deleteSensorAction,
  createSensor as createSensorAction,
} from "../store/asset.action";
import { fetchSensorTypes as fetchSensorTypesAction } from "../../sensorTypes/store/sensorTypes.action";
import { AssetSensorsTable } from "../component/AssetSensorsTable/AssetSensorsTable";
import {
  selectFetchAssetSensorsData,
  selectIsFetchAssetSensorsStatusPending,
  selectAssetSensorsData,
  selectIsAssetSensorsStatusPending,
  selectShowAssetData,
  selectIsShowAssetStatusPending,
} from "../store/asset.selector";
import {
  selectSensorTypesData,
  selectIsSensorTypesStatusPending,
} from "../../sensorTypes/store/sensorTypes.selector";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditSensorModel } from "../model/asset.model";
import { useInterval } from "../../app/hooks/useInterval";

export const AssetsSensorsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ assetId: string }>();
  const assetData = useSelector(selectShowAssetData);
  const assetSensorsTableData = useSelector(selectFetchAssetSensorsData);
  const assetSensorsData = useSelector(selectAssetSensorsData);
  const sensorTypesData = useSelector(selectSensorTypesData);
  const isAssetDataPending = useSelector(selectIsShowAssetStatusPending);
  const isAssetSensorsDataPending = useSelector(
    selectIsFetchAssetSensorsStatusPending
  );
  const isSensorsPending = useSelector(selectIsAssetSensorsStatusPending);
  const isSensorTypesPending = useSelector(selectIsSensorTypesStatusPending);
  const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);

  const onOpenModal = (name: string) => dispatch(openModalAction({ name }));

  const onCloseModal = (name: string) => dispatch(closeModalAction({ name }));

  const onSubmit = (values: CreateEditSensorModel, id?: number) => {
    if (id) {
      dispatch(
        editSensorAction({
          id,
          values,
          assetId: Number(params.assetId),
        })
      );
    } else {
      dispatch(
        createSensorAction({
          values,
          assetId: Number(params.assetId),
        })
      );
    }
  };

  const onDeleteSensor = (id: number) =>
    dispatch(deleteSensorAction({ id, assetId: Number(params.assetId) }));

  useEffect(() => {
    dispatch(fetchAssetSensorsAction(Number(params.assetId)));
    dispatch(fetchModalSensorsAction(Number(params.assetId)));
    dispatch(fetchSensorTypesAction());
    setIsInitialDataFetched(false);
  }, [dispatch, params]);

  useInterval(() => {
    dispatch(fetchAssetSensorsAction(Number(params.assetId)));
    setIsInitialDataFetched(true);
  });

  return (
    <AssetSensorsTable
      rows={[...assetSensorsTableData].sort(
        (a, b) => Number(b.channel) - Number(a.channel)
      )}
      assetId={Number(params.assetId)}
      sensors={assetSensorsData}
      sensorTypes={sensorTypesData}
      isTablePending={!isInitialDataFetched && isAssetSensorsDataPending}
      isModalPending={
        isSensorsPending || isSensorTypesPending || isAssetDataPending
      }
      onSubmit={onSubmit}
      onDeleteSensor={onDeleteSensor}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
      asset={assetData}
    />
  );
};
