import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppDispatch } from "../../app/store";
import {
  selectAssetDyanmoRollupData,
  selectAssetDyanmoRollupDataStatusPending,
} from "../store/asset.selector";
import { AssetDto, AssetDynamoRollupDto } from "../api/asset.api.dto";
import { fetchDyanmoData as fetchDyanmoDataAction } from "../store/asset.action";
import { AssetHealthComparisonGraph } from "../component/Analytics/AssetHealthComparisonGraph";
import { GRAPHS_DATE_FORMAT } from "../const/AssetGraphs.const";

interface IAnalyticsHealthComparisonContainer {
  selectedAssets: AssetDto[];
  startEpoch: number;
  endEpoch: number;
  healthComparisonData: AssetDynamoRollupDto[];
  setHealthComparisonData: (health: AssetDynamoRollupDto[]) => void;
}

export const AnalyticsHealthComparisonContainer: FC<IAnalyticsHealthComparisonContainer> = ({
  selectedAssets,
  startEpoch,
  endEpoch,
  setHealthComparisonData,
  healthComparisonData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const assetHealthData = useSelector(selectAssetDyanmoRollupData);
  const assetHealthDataIsPending = useSelector(
    selectAssetDyanmoRollupDataStatusPending
  );

  useEffect(() => {
    dispatch(
      fetchDyanmoDataAction({
        assetIds: [],
        startEpoch: 0,
        endEpoch: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setHealthComparisonData(assetHealthData);
  }, [setHealthComparisonData, assetHealthData]);

  useEffect(() => {
    const assetIds = selectedAssets.map((asset: AssetDto) => {
      return asset.id;
    });
    if (assetIds.length > 0) {
      dispatch(fetchDyanmoDataAction({ assetIds, startEpoch, endEpoch }));
    }
    setHealthComparisonData([]);
  }, [dispatch, setHealthComparisonData, selectedAssets, startEpoch, endEpoch]);

  const formatDate = (tickItem: number | string) => {
    return moment(tickItem).format(GRAPHS_DATE_FORMAT);
  };
  return (
    <AssetHealthComparisonGraph
      isGraphsPending={assetHealthDataIsPending}
      formatDate={formatDate}
      healthComparisonData={healthComparisonData}
    />
  );
};
