import React, { FC } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { DeleteOutline, GetApp } from "@material-ui/icons";
import {
  Preview,
  StyledIconButton,
  StyledIFrame,
  StyledImage,
  StyledTypography,
  Title,
  Wrapper,
} from "./AssetAttachment.style";
import { AssetAttachmentDto } from "../../api/asset.api.dto";
import { useShowForRole } from "../../../app/hooks/useShowForRole";
import { UserRole } from "../../../app/enum/UserRole";

interface IAssetAttachment {
  attachment: AssetAttachmentDto;
  onDownload: (attachmentId: number, name: string, extension: string) => void;
  onDelete: (attachmentId: number) => void;
}

export const AssetAttachment: FC<IAssetAttachment> = ({
  attachment,
  onDelete,
}) => {
  const isMember = useShowForRole([UserRole.Member]);

  return (
    <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
      <Wrapper>
        <Preview>
          {attachment.extension === "pdf" && (
            <StyledIFrame
              src={attachment.file}
              title="pdf-review"
              scrolling="no"
            />
          )}
          {(attachment.extension === "png" ||
            attachment.extension === "jpg" ||
            attachment.extension === "jpeg" ||
            attachment.extension === "svg") && (
            <StyledImage src={attachment.file} alt="preview" />
          )}
          {attachment.extension === "txt" && null}
        </Preview>
        <Title>
          <StyledTypography color="textSecondary">
            {`${attachment.name}.${attachment.extension}`}
          </StyledTypography>
          <div>
            <StyledIconButton
              component="a"
              href={attachment.file}
              target="_blank"
            >
              <GetApp />
            </StyledIconButton>
            {!isMember ? (
              <IconButton onClick={() => onDelete(attachment.id)}>
                <DeleteOutline />
              </IconButton>
            ) : null}
          </div>
        </Title>
      </Wrapper>
    </Grid>
  );
};
