import React, { FC } from "react";
import { Avatar } from "@material-ui/core";
import { StyledChip } from "./MyStuffChip.styles";

interface IMyStuffChip {
  userPhotoUrl: string | null;
  selected: boolean;
  onSelect: (value: boolean) => void;
}

export const MyStuffChip: FC<IMyStuffChip> = ({
  userPhotoUrl,
  selected,
  onSelect,
}) => (
  <StyledChip
    avatar={
      <Avatar src={userPhotoUrl || undefined}>{userPhotoUrl?.charAt(0)}</Avatar>
    }
    label="Only My Stuff"
    color="primary"
    onClick={() => onSelect(!selected)}
    selected={selected}
    clickable={false}
  />
);
