import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  ClientAverageHealthDto,
  ClientDto,
  RecentActivityDto,
} from "../api/clients.api.dto";
import { AssetTodosDto } from "../../asset/api/asset.api.dto";

export interface ClientState {
  clients: AsyncStateModel<ClientDto[]>;
  deleteClient: AsyncStateModel<null>;
  editClient: AsyncStateModel<null>;
  createClient: AsyncStateModel<null>;
  searchClients: string;
  averageHealth: AsyncStateModel<ClientAverageHealthDto | null>;
  recentActivities: AsyncStateModel<RecentActivityDto[]>;
  todos: AsyncStateModel<AssetTodosDto[]>;
}

export const clientState: ClientState = {
  clients: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteClient: {
    status: AsyncStatus.Void,
    data: null,
  },
  editClient: {
    status: AsyncStatus.Void,
    data: null,
  },
  createClient: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchClients: "",
  averageHealth: {
    status: AsyncStatus.Void,
    data: null,
  },
  recentActivities: {
    status: AsyncStatus.Void,
    data: [],
  },
  todos: {
    status: AsyncStatus.Void,
    data: [],
  },
};
