import { createSlice } from "@reduxjs/toolkit";
import { TeamsState, teamsState } from "./teams.store";
import { fetchTeams, deleteTeam, editTeam, createTeam } from "./teams.action";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

const slice = createSlice({
  name: "teams",
  initialState: teamsState,
  reducers: {
    searchTeams: (state: TeamsState, { payload }: { payload: string }) => ({
      ...state,
      searchTeams: payload,
    }),
    clearFetchTeamsState: (state: TeamsState) => ({
      ...state,
      fetchTeams: teamsState.fetchTeams,
    }),
    clearSearchTeamsState: (state: TeamsState) => ({
      ...state,
      searchTeams: teamsState.searchTeams,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.pending, (state) => ({
      ...state,
      fetchTeams: {
        ...state.fetchTeams,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          fetchTeams: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchTeams.rejected, (state) => ({
      ...state,
      fetchTeams: {
        ...state.fetchTeams,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteTeam.pending, (state) => ({
      ...state,
      deleteTeam: {
        ...state.deleteTeam,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteTeam.fulfilled, (state) => ({
      ...state,
      deleteTeam: {
        ...state.deleteTeam,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteTeam.rejected, (state) => ({
      ...state,
      deleteTeam: {
        ...state.deleteTeam,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editTeam.pending, (state) => ({
      ...state,
      editTeam: {
        ...state.editTeam,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editTeam.fulfilled, (state) => ({
      ...state,
      editTeam: {
        ...state.editTeam,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editTeam.rejected, (state) => ({
      ...state,
      editTeam: {
        ...state.editTeam,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(createTeam.pending, (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(createTeam.fulfilled, (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(createTeam.rejected, (state) => ({
      ...state,
      createTeam: {
        ...state.createTeam,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const {
  searchTeams,
  clearFetchTeamsState,
  clearSearchTeamsState,
} = slice.actions;
export default slice.reducer;
