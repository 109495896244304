import React, { FC } from "react";
import { Box } from "@material-ui/core";
import {
  FullHeightContainer,
  StyledPage,
} from "../../../app/component/DashboardLayout/DashboardLayout.style";
import {
  PageHeaderContainer,
  PageHeader,
} from "../../../app/component/DashboardLayout/PageHeader/PageHeader";
import { UserNotificationsContainer } from "../../container/UserNotificationsContainer";

export const UserNotificationsView: FC = () => (
  <StyledPage title="Notifications" minHeight>
    <FullHeightContainer maxWidth={false}>
      <PageHeaderContainer>
        <PageHeader title="Notifications" />
      </PageHeaderContainer>
      <Box
        flexDirection="column"
        height="100%"
        justifyContent="center"
        flex={1}
      >
        <UserNotificationsContainer />
      </Box>
    </FullHeightContainer>
  </StyledPage>
);
