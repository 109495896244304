import React, { FC } from "react";
import { Card, Typography, Box } from "@material-ui/core";
import moment from "moment";
import {
  ButtonsWrapper,
  StyledCardContent,
  Subtitle,
  Title,
} from "./Password.style";
import { Button } from "../../../app/component/Button/Button";
import Lock from "../../../../assets/Lock.svg";

interface IPassword {
  onReset: () => void;
  lastReset?: Date | null;
  isPending: boolean;
}

export const Password: FC<IPassword> = ({ onReset, lastReset, isPending }) => (
  <Card>
    <StyledCardContent>
      <Box>
        <Title>Need to reset your password?</Title>
        <Subtitle color="textSecondary">
          You’re in the right place! The security of your info is a top priority
          at Tako. <br />
          Let’s get started!
        </Subtitle>
        {lastReset && (
          <Typography color="textSecondary">
            Last reset on {moment(lastReset).format("MM/DD/YYYY")}
          </Typography>
        )}
      </Box>
      <Box>
        <img alt="lock" src={Lock} />
      </Box>
    </StyledCardContent>
    <ButtonsWrapper>
      <Button
        variant="contained"
        color="primary"
        onClick={onReset}
        disabled={isPending}
        pending={isPending}
      >
        Reset Password
      </Button>
    </ButtonsWrapper>
  </Card>
);
