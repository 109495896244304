import { AssetDto, AssetTagDto } from "../api/asset.api.dto";

export const filterByTags = (data: AssetDto[], tagsFilters: string[]) => {
  return data.filter((asset) => {
    let matchingTag = false;
    Object.values(tagsFilters).forEach((cat) => {
      const cats = asset.tags.map((tag: AssetTagDto) => tag.name);
      if (cats.join(",").includes(cat)) {
        matchingTag = true;
      }
    });
    return matchingTag;
  });
};
