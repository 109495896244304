import React, { FC, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import moment from "moment";
import { useInterval } from "../../app/hooks/useInterval";
import { AppDispatch } from "../../app/store";
import {
  selectAssetHealthGraphDataData,
  selectAssetHealthGraphDataStatusPending,
  selectAssetSensorGraphDataData,
  selectAssetSensorGraphDataStatusPending,
  selectShowAssetData,
} from "../store/asset.selector";
import {
  fetchAssetHealthData as fetchAssetHealthDataAction,
  fetchAssetSensorReadingsData as fetchAssetSensorReadingsDataAction,
  showAsset as showAssetAction,
} from "../store/asset.action";
import { AssetHealthGraph } from "../component/AssetTrends/AssetHealthGraph";
import { AssetSensorReadingsGraphs } from "../component/AssetTrends/AssetSensorReadingsGraphs";
import { DateTimeRangePicker } from "../component/DateTimeRangePicker/DateTimeRangePicker";
import { GRAPHS_DATE_FORMAT } from "../const/AssetGraphs.const";
import { AssetTrendsSensorSelector } from "../component/AssetTrends/AssetTrendsSensorSelector";

interface RouteParams {
  assetId: string;
}

export const AssetTrendsGraphsContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { assetId } = useParams<RouteParams>();
  const asset = useSelector(selectShowAssetData);
  const assetHealthData = useSelector(selectAssetHealthGraphDataData);
  const sortedSensors = assetHealthData
    .slice()
    .sort((a, b) => a.index - b.index);
  const assetHealthDataIsPending = useSelector(
    selectAssetHealthGraphDataStatusPending
  );
  const assetSensorReadingsData = useSelector(selectAssetSensorGraphDataData);
  const assetSensorReadingsDataIsPending = useSelector(
    selectAssetSensorGraphDataStatusPending
  );

  const [startEpoch, setStartEpoch] = useState(
    moment().subtract(6, "days").unix()
  );
  const [endEpoch, setEndEpoch] = useState(moment().unix());

  const [selectedSensors, setSelectedSensors] = useState<number[] | undefined>(
    undefined
  );

  const assetIdRef = useRef(assetId);
  assetIdRef.current = assetId;
  const startEpochRef = useRef(startEpoch);
  startEpochRef.current = startEpoch;
  const endEpochRef = useRef(endEpoch);
  endEpochRef.current = endEpoch;

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const assetTrendsRequest = {
      assetId,
      startEpoch,
      endEpoch,
    };
    dispatch(showAssetAction(Number(assetId)));
    dispatch(fetchAssetHealthDataAction(assetTrendsRequest));
    dispatch(fetchAssetSensorReadingsDataAction(assetTrendsRequest));
  }, [dispatch, assetId, startEpoch, endEpoch]);

  useInterval(() => {
    const assetTrendsRequest = {
      assetId,
      startEpoch,
      endEpoch,
    };
    dispatch(fetchAssetHealthDataAction(assetTrendsRequest));
    dispatch(fetchAssetSensorReadingsDataAction(assetTrendsRequest));
    if (showLoader) {
      setShowLoader(false);
    }
  });

  const formatDate = (tickItem: number | string) => {
    return moment(tickItem).format(GRAPHS_DATE_FORMAT);
  };

  const formatYAxis = (tickItem: number): string => {
    if (tickItem < 1000) {
      return tickItem % 1 !== 0
        ? tickItem.toFixed(1).toString()
        : tickItem.toString();
    }
    return `${(tickItem / 1000).toFixed(1)}k`;
  };

  const sortedSelectedSensors = selectedSensors?.slice().sort((a, b) => a - b);

  return (
    <>
      <Box mb={2}>
        <AssetTrendsSensorSelector
          graphData={sortedSensors}
          selectedSensors={sortedSelectedSensors}
          setSelectedSensors={setSelectedSensors}
        />
      </Box>
      <Box mb={2}>
        <AssetHealthGraph
          isGraphsPending={assetHealthDataIsPending && showLoader}
          graphData={assetHealthData}
          formatDate={formatDate}
          assetName={asset?.name || ""}
          assetHealthEnabled={asset?.health_is_enabled || false}
          dateTimeRangePicker={
            <DateTimeRangePicker
              startEpoch={startEpoch}
              endEpoch={endEpoch}
              setStartEpoch={setStartEpoch}
              setEndEpoch={setEndEpoch}
            />
          }
          selectedSensors={sortedSelectedSensors}
        />
      </Box>
      <Box mb={2}>
        <AssetSensorReadingsGraphs
          isGraphsPending={assetSensorReadingsDataIsPending && showLoader}
          graphData={assetSensorReadingsData}
          formatDate={formatDate}
          formatYAxis={formatYAxis}
          assetName={asset?.name || ""}
          startEpoch={startEpoch}
          endEpoch={endEpoch}
          selectedSensors={sortedSelectedSensors}
        />
      </Box>
    </>
  );
};
