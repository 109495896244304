import * as Yup from "yup";

const emailMessages = {
  email: "Must be a valid email",
  required: "Email is required",
};

export const forgotPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(emailMessages.email)
    .max(255)
    .required(emailMessages.required),
});
