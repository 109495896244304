import styled, { css } from "styled-components";

export const ChannelsWrapper = styled.div`
  display: block;
  max-width: 350px;

  div:last-child {
    margin: 0;
  }
`;

export const Channel = styled.div<{ disabled: boolean; selected: boolean }>`
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: solid 1px rgba(31, 30, 77, 0.1);
  border-radius: 50%;
  margin-right: 12px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        color: #fff;
        border-color: ${({ theme }) => theme.palette.primary.main};
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      border-color: #dddddd;
      background-color: #dddddd;
      color: #5f6368;
    `}

  ${({ selected, theme }) =>
    selected &&
    css`
      color: #fff;
      cursor: pointer;
      border-color: ${theme.palette.primary.main};
      background-color: ${theme.palette.primary.main};
    `}
`;
