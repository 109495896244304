import React, { FC } from "react";
import { useFormik } from "formik";
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  partnerModalFormValidation,
  DESCRIPTION_CHAR_LIMIT,
  NAME_CHAR_LIMIT,
} from "./partnerModalForm.validation";
import {
  StyledModalContent,
  StyledSwitch,
} from "../../../app/component/Modal/Modal.style";
import {
  PartnerModel,
  CreateEditPartnerModel,
} from "../../model/partners.model";

export interface IClientModalForm {
  partner?: PartnerModel;
  industries: string[];
  onSubmit: (values: CreateEditPartnerModel, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  editView: boolean;
}

export const PartnerModalForm: FC<IClientModalForm> = ({
  partner,
  industries,
  onSubmit,
  displayActionButtons,
  isPending,
  editView,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: partner?.name || "",
      email: partner?.email || "",
      description: partner?.description || "",
      first_name: partner?.first_name || "",
      last_name: partner?.last_name || "",
      phone: partner?.phone || "",
      address: partner?.address || "",
      industry: partner?.industry || "",
      is_enabled: partner?.is_enabled !== 0,
    },
    validationSchema: partnerModalFormValidation,
    onSubmit: (values: CreateEditPartnerModel) => onSubmit(values, partner?.id),
  });

  const handlePhoneChange = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      formik.setFieldValue("phone", onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      formik.setFieldValue("phone", number);
    }
  };

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Partner Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Partner Name"
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: NAME_CHAR_LIMIT }}
              required
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="filled"
              fullWidth
              required
              disabled={isPending}
            >
              <Autocomplete
                options={industries.sort()}
                id="industry"
                disabled={isPending || (!editView && partner !== undefined)}
                value={formik.values.industry}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: string | null
                ) => {
                  formik.setFieldValue("industry", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="industry"
                    label="Partner Industry"
                    error={
                      formik.touched.industry && Boolean(formik.errors.industry)
                    }
                    helperText={
                      formik.touched.industry && formik.errors.industry
                    }
                    variant="filled"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Partner Description"
              multiline
              rows={3}
              variant="filled"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              inputProps={{
                maxLength: DESCRIPTION_CHAR_LIMIT,
              }}
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Partner Contact</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="first_name"
              name="first_name"
              label="First Name"
              variant="filled"
              fullWidth
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              required
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="last_name"
              name="last_name"
              label="Last Name"
              variant="filled"
              fullWidth
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              required
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              variant="filled"
              fullWidth
              value={formik.values.phone}
              onChange={handlePhoneChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="filled"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              name="address"
              label="Address"
              variant="filled"
              fullWidth
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={9} sm={4}>
            <Typography variant="h4">Activate Partner</Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            <StyledSwitch
              id="is_enabled"
              name="is_enabled"
              value={formik.values.is_enabled}
              checked={!!formik.values.is_enabled}
              onChange={formik.handleChange}
              disabled={isPending || (!editView && partner !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
