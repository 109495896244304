import React, { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AssetAttachments } from "../component/AssetAttachments/AssetAttchaments";
import { AppDispatch } from "../../app/store";
import {
  deleteAssetAttachment,
  downloadAssetAttachment,
  fetchAssetAttachments,
} from "../store/asset.action";
import {
  selectAssetAttachmentsData,
  selectIsAssetAttachmentsStatusFulfilled,
  selectIsAssetAttachmentsStatusPending,
  selectIsDeleteAssetAttachmentStatusPending,
} from "../store/asset.selector";

export const AssetAttachmentsContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ assetId: string }>();
  const assetAttachmentsData = useSelector(selectAssetAttachmentsData);
  const isAssetAttachmentsFulfilled = useSelector(
    selectIsAssetAttachmentsStatusFulfilled
  );
  const isAssetAttachmentsPending = useSelector(
    selectIsAssetAttachmentsStatusPending
  );
  const isDeleteAttachmentPending = useSelector(
    selectIsDeleteAssetAttachmentStatusPending
  );

  useEffect(() => {
    dispatch(fetchAssetAttachments(Number(params.assetId)));
  }, [dispatch, params.assetId]);

  const handleOnDownload = (
    attachmentId: number,
    name: string,
    extension: string
  ) => dispatch(downloadAssetAttachment({ attachmentId, name, extension }));

  const handleOnDelete = async (attachmentId: number): Promise<void> => {
    await dispatch(deleteAssetAttachment(attachmentId));
    await dispatch(fetchAssetAttachments(Number(params.assetId)));
  };

  return (
    <AssetAttachments
      attachments={assetAttachmentsData}
      isPending={isAssetAttachmentsPending || isDeleteAttachmentPending}
      isFulfilled={isAssetAttachmentsFulfilled}
      onDownload={handleOnDownload}
      onDelete={handleOnDelete}
    />
  );
};
