import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { UserNotifications } from "../component/UserNotifications/UserNotifications";
import { AppDispatch } from "../../app/store";
import {
  editUserNotifications,
  fetchUserNotifications,
  unsubscribeUserEmailNotifications,
} from "../store/users.action";
import { selectProfileData } from "../../auth/store/auth.selector";
import {
  selectFetchUserNotificationsData,
  selectIsEditUserNotificationsStatusPending,
  selectIsFetchUserNotificationsStatusPending,
} from "../store/users.selector";
import { UserNotificationsDto } from "../api/users.api.dto";
import { openErrorNotification } from "../../app/store/notifications/notifications.slice";

export const UserNotificationsContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector(selectProfileData);
  const userNotificationsData = useSelector(selectFetchUserNotificationsData);
  const isUserNotificationsPending = useSelector(
    selectIsFetchUserNotificationsStatusPending
  );
  const isEditUserNotificationsPending = useSelector(
    selectIsEditUserNotificationsStatusPending
  );
  const { search } = useLocation();
  const history = useHistory();
  const { unsubscribe }: { unsubscribe?: string } = parse(search);

  useEffect(() => {
    if (profile?.user.id) {
      dispatch(fetchUserNotifications(profile.user.id));
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (unsubscribe) {
      dispatch(unsubscribeUserEmailNotifications());
      const queryParams = new URLSearchParams(search);
      if (queryParams.has("unsubscribe")) {
        queryParams.delete("unsubscribe");
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (profile?.user.id) {
        dispatch(fetchUserNotifications(profile?.user.id));
      }
    }
  }, [dispatch, unsubscribe, search, history, profile]);

  const handleSubmit = async (values: UserNotificationsDto) => {
    if (profile?.user.id) {
      await dispatch(
        editUserNotifications({ id: profile.user.id, notificationsDto: values })
      );
      await dispatch(fetchUserNotifications(profile.user.id));
    }
  };

  const showErrorMessage = (message: string) =>
    dispatch(openErrorNotification(message));

  return (
    <UserNotifications
      isPending={isUserNotificationsPending || isEditUserNotificationsPending}
      notifications={userNotificationsData}
      onSubmit={handleSubmit}
      showErrorMessage={showErrorMessage}
    />
  );
};
