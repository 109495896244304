import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  AssetDto,
  AssetTagDto,
  SensorDto,
  AssetRuntimeDto,
  ChannelGraphData,
  AssetUsageDto,
  AssetDynamoRollupDto,
  AssetNoteDto,
  AssetAttachmentDto,
  SensorTableDto,
  AssetAlertsDto,
} from "../api/asset.api.dto";
import { CategoryDto } from "../../category/api/category.api.dto";

export interface AssetState {
  assets: AsyncStateModel<AssetDto[]>;
  asset: AsyncStateModel<AssetDto | null>;
  createAsset: AsyncStateModel<null>;
  editAsset: AsyncStateModel<null>;
  deleteAsset: AsyncStateModel<null>;
  categories: AsyncStateModel<CategoryDto[]>;
  tags: AsyncStateModel<AssetTagDto[]>;
  health: AsyncStateModel<number>;
  searchAssets: string;
  tagsFilters: string[];
  assetHealthData: AsyncStateModel<ChannelGraphData[]>;
  assetSensorReadingsData: AsyncStateModel<ChannelGraphData[]>;
  filteredAssets: AssetDto[];
  sensors: AsyncStateModel<SensorDto[]>;
  runtime: AsyncStateModel<AssetRuntimeDto[]>;
  usage: AsyncStateModel<AssetUsageDto[]>;
  dynamoHealthRollups: AsyncStateModel<AssetDynamoRollupDto[]>;
  notes: AsyncStateModel<AssetNoteDto[]>;
  deleteAssetNote: AsyncStateModel<null>;
  pinAssetNote: AsyncStateModel<null>;
  addAssetNote: AsyncStateModel<null>;
  attachments: AsyncStateModel<AssetAttachmentDto[]>;
  deleteAssetAttachment: AsyncStateModel<null>;
  downloadAssetAttachment: AsyncStateModel<null>;
  fetchAssetSensors: AsyncStateModel<SensorTableDto[]>;
  addAssetToBookmark: AsyncStateModel<null>;
  removeAssetFromBookmark: AsyncStateModel<null>;
  fetchAssetAlerts: AsyncStateModel<AssetAlertsDto[]>;
}

export const assetState: AssetState = {
  assets: {
    status: AsyncStatus.Void,
    data: [],
  },
  asset: {
    status: AsyncStatus.Void,
    data: null,
  },
  createAsset: {
    status: AsyncStatus.Void,
    data: null,
  },
  editAsset: {
    status: AsyncStatus.Void,
    data: null,
  },
  deleteAsset: {
    status: AsyncStatus.Void,
    data: null,
  },
  categories: {
    status: AsyncStatus.Void,
    data: [],
  },
  tags: {
    status: AsyncStatus.Void,
    data: [],
  },
  health: {
    status: AsyncStatus.Void,
    data: 0,
  },
  assetHealthData: {
    status: AsyncStatus.Void,
    data: [],
  },
  assetSensorReadingsData: {
    status: AsyncStatus.Void,
    data: [],
  },
  searchAssets: "",
  tagsFilters: [],
  filteredAssets: [],
  sensors: {
    status: AsyncStatus.Void,
    data: [],
  },
  runtime: {
    status: AsyncStatus.Void,
    data: [],
  },
  usage: {
    status: AsyncStatus.Void,
    data: [],
  },
  dynamoHealthRollups: {
    status: AsyncStatus.Void,
    data: [],
  },
  notes: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteAssetNote: {
    status: AsyncStatus.Void,
    data: null,
  },
  pinAssetNote: {
    status: AsyncStatus.Void,
    data: null,
  },
  addAssetNote: {
    status: AsyncStatus.Void,
    data: null,
  },
  attachments: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteAssetAttachment: {
    status: AsyncStatus.Void,
    data: null,
  },
  downloadAssetAttachment: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchAssetSensors: {
    status: AsyncStatus.Void,
    data: [],
  },
  addAssetToBookmark: {
    status: AsyncStatus.Void,
    data: null,
  },
  removeAssetFromBookmark: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchAssetAlerts: {
    status: AsyncStatus.Void,
    data: [],
  },
};
