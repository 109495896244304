import React, { FC } from "react";
import { useFormik } from "formik";
import { Divider, FormControl, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ConnectedShowForRole } from "../../../app/component/UserManagement/ConnectedShowForRole";
import { UserRole } from "../../../app/enum/UserRole";
import { teamModalFormValidation } from "./teamModalForm.validation";
import { StyledModalContent } from "../../../app/component/Modal/Modal.style";
import { TeamDto, CreateEditTeamDto } from "../../api/teams.api.dto";
import { ClientDto } from "../../../clients/api/clients.api.dto";
import { useClientId } from "../../../app/hooks/useClientId";

export interface ITeamModalForm {
  team?: TeamDto;
  clients: ClientDto[];
  onSubmit: (values: CreateEditTeamDto, id?: number) => void;
  displayActionButtons: () => void;
  isPending: boolean;
  editView: boolean;
}

export const TeamModalForm: FC<ITeamModalForm> = ({
  team,
  clients,
  onSubmit,
  displayActionButtons,
  isPending,
  editView,
}) => {
  const userClientId = useClientId();
  const getClientIdOptions = () => {
    return [...clients]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client) => client.id) as number[];
  };
  const getClientIdOptionLabel = (option: number) => {
    const match = clients.find((client) => {
      return client.id === option;
    });
    return match ? match.name : "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: team?.name || "",
      client_id: team?.client_id || userClientId || 0,
      description: team?.description || "",
    },
    validationSchema: teamModalFormValidation,
    onSubmit: (values: CreateEditTeamDto) => onSubmit(values, team?.id),
  });

  return (
    <StyledModalContent>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <ConnectedShowForRole
            roles={[UserRole.SuperUser, UserRole.SuperAdmin]}
          >
            <Grid item xs={12}>
              <FormControl
                variant="filled"
                fullWidth
                required
                disabled={isPending}
              >
                <Autocomplete
                  options={getClientIdOptions()}
                  id="client_id"
                  getOptionLabel={getClientIdOptionLabel}
                  disabled={isPending || (!editView && team !== undefined)}
                  value={formik.values.client_id}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: number | null
                  ) => {
                    formik.setFieldValue("client_id", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="client_id"
                      label="Client"
                      error={
                        formik.touched.client_id &&
                        Boolean(formik.errors.client_id)
                      }
                      helperText={
                        formik.touched.client_id && formik.errors.client_id
                      }
                      variant="filled"
                      fullWidth
                      required
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </ConnectedShowForRole>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Team Name"
              variant="filled"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required
              disabled={isPending || (!editView && team !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Description"
              variant="filled"
              fullWidth
              multiline
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              disabled={isPending || (!editView && team !== undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {displayActionButtons()}
        </Grid>
      </form>
    </StyledModalContent>
  );
};
