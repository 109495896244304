import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { ClientsTable } from "../component/ClientsTable/ClientsTable";
import {
  editClient as editClientAction,
  deleteClient as deleteClientAction,
  fetchClients as fetchClientsAction,
} from "../store/clients.action";
import {
  selectClientsTableData,
  selectIsClientsTableStatusPending,
} from "../store/clients.selector";
import { clearClientsState as clearClientsStateAction } from "../store/clients.slice";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { CreateEditClientModel } from "../model/clients.model";
import { fetchIndustries as fetchIndustriesAction } from "../../industry/store/industry.action";
import {
  selectFetchIndustriesData,
  selectIsFetchIndustriesStatusPending,
} from "../../industry/store/industry.selector";
import { EDIT_CLIENT_MODAL } from "../../app/const/modals";
import { selectPartnersData } from "../../partners/store/partners.selector";
import { fetchPartners as fetchPartnersAction } from "../../partners/store/partners.action";

export const ClientsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const clientsTableData = useSelector(selectClientsTableData);
  const isClientsPending = useSelector(selectIsClientsTableStatusPending);
  const industriesData = useSelector(selectFetchIndustriesData);
  const partnersData = useSelector(selectPartnersData);
  const isFetchIndustriesPending = useSelector(
    selectIsFetchIndustriesStatusPending
  );

  const onOpenModal = () =>
    dispatch(openModalAction({ name: EDIT_CLIENT_MODAL }));

  const onCloseModal = () =>
    dispatch(closeModalAction({ name: EDIT_CLIENT_MODAL }));

  const onEditClient = (editClientModel: CreateEditClientModel, id?: number) =>
    id && dispatch(editClientAction({ id, editClientModel }));

  const onDeleteClient = (id: number) => dispatch(deleteClientAction(id));

  useEffect(() => {
    dispatch(fetchIndustriesAction());
    dispatch(fetchClientsAction());
    dispatch(fetchPartnersAction());

    return () => {
      dispatch(clearClientsStateAction());
    };
  }, [dispatch]);

  return (
    <ClientsTable
      clients={clientsTableData}
      industries={industriesData}
      partners={partnersData}
      isTablePending={isClientsPending}
      isModalPending={isFetchIndustriesPending}
      onEditClient={onEditClient}
      onDeleteClient={onDeleteClient}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};
