import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  SensorTypeDto,
  HardcodedSensorType,
  HardcodedSensorUnit,
} from "../api/sensorTypes.api.dto";

export interface SensorTypesState {
  fetchSensorTypes: AsyncStateModel<SensorTypeDto[]>;
  fetchHardcodedSensorTypes: AsyncStateModel<HardcodedSensorType[]>;
  fetchHardcodedSensorUnits: AsyncStateModel<HardcodedSensorUnit[]>;
  deleteSensorType: AsyncStateModel<null>;
  editSensorType: AsyncStateModel<null>;
  createSensorType: AsyncStateModel<null>;
  searchSensorTypes: string;
}

export const sensorTypesState: SensorTypesState = {
  fetchSensorTypes: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchHardcodedSensorTypes: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchHardcodedSensorUnits: {
    status: AsyncStatus.Void,
    data: [],
  },
  deleteSensorType: {
    status: AsyncStatus.Void,
    data: null,
  },
  editSensorType: {
    status: AsyncStatus.Void,
    data: null,
  },
  createSensorType: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchSensorTypes: "",
};
