import React, { FC } from "react";

import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { NoNotesWrapper, NotesNumber, NotesWrapper } from "./AssetNotes.style";
import { AssetNote } from "../AssetNote/AssetNote";
import { LoaderOverlay } from "../../../app/component/DashboardLayout/DashboardLayout.style";
import { AssetNoteModel } from "../../model/asset.model";

interface IAssetNotes {
  notes: AssetNoteModel[];
  isPending: boolean;
  isFulfilled: boolean;
  onDelete: (noteId: number) => void;
  onPin: (noteId: number, isPinned: boolean) => void;
}

export const AssetNotes: FC<IAssetNotes> = ({
  notes,
  isPending,
  onDelete,
  onPin,
  isFulfilled,
}) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h4">
        Notes <NotesNumber>{notes.length}</NotesNumber>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <NotesWrapper>
        {isPending && (
          <LoaderOverlay>
            <CircularProgress size={50} />
          </LoaderOverlay>
        )}
        {notes.length === 0 && isFulfilled && (
          <NoNotesWrapper>
            <Typography>No notes found</Typography>
          </NoNotesWrapper>
        )}
        {notes.map((note) => (
          <AssetNote note={note} onDelete={onDelete} onPin={onPin} />
        ))}
      </NotesWrapper>
    </Grid>
  </Grid>
);
