import { CreateEditAssetModel } from "../model/asset.model";
import { CreateEditAssetDto } from "./asset.api.dto";

export const mapCreateEditAssetModelToDto = (
  assetModel: CreateEditAssetModel
): CreateEditAssetDto => ({
  ...assetModel,
  is_enabled: assetModel.is_enabled ? 1 : 0,
  health_is_enabled: assetModel.health_is_enabled ? 1 : 0,
  snoozed: assetModel.snoozed ? 1 : 0,
});
