import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { AppDispatch } from "../../app/store";
import { clearSearchClientsState, searchClients } from "../store/clients.slice";
import TableSearchInput from "../../app/component/Table/TableSearchInput/TableSearchInput";
import { selectIsClientsTableStatusPending } from "../store/clients.selector";

export const ClientsSearchContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isClientsPending = useSelector(selectIsClientsTableStatusPending);

  useEffect(() => {
    return () => {
      dispatch(clearSearchClientsState());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchClients(value));

  return (
    <TableSearchInput
      label="Search Clients"
      onSearch={debounce(onSearch, 200)}
      isPending={isClientsPending}
    />
  );
};
