import React from "react";
import { ReactComponent as Chiller } from "../../../assets/chiller.svg";
import { ReactComponent as DryPump1800 } from "../../../assets/dry-pump-1800.svg";
import { ReactComponent as DryPump1800Box } from "../../../assets/dry-pump-1800-box.svg";
import { ReactComponent as DryVacuumPump } from "../../../assets/dry-vacuum-pump.svg";
import { ReactComponent as DryVacuumPumpBlower } from "../../../assets/dry-vacuum-pump-blower.svg";
import { ReactComponent as GasBottle } from "../../../assets/gas-bottle.svg";
import { ReactComponent as Otto } from "../../../assets/otto.svg";
import { ReactComponent as ScrollPump } from "../../../assets/scroll-pump.svg";
import { ReactComponent as WineCentrifuge } from "../../../assets/wine-centrifuge.svg";

export const getAssetImage = (liveGraphSvg: number) => {
  switch (liveGraphSvg) {
    case 0:
      return <Chiller />;
    case 1:
      return <DryPump1800 />;
    case 2:
      return <DryPump1800Box />;
    case 3:
      return <DryVacuumPump />;
    case 4:
      return <DryVacuumPumpBlower />;
    case 5:
      return <GasBottle />;
    case 6:
      return <Otto />;
    case 7:
      return <ScrollPump style={{ marginLeft: "25px" }} />;
    case 8:
      return <WineCentrifuge />;
    default:
      return <Otto />;
  }
};
