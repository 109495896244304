import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@material-ui/icons";
import { ConnectedShowForPermissions } from "../../app/component/UserManagement/ConnectedShowForPermissions";
import { AppDispatch } from "../../app/store";
import { createPartner as createPartnerAction } from "../store/partners.action";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from "../../app/store/connectedModal/connectedModal.slice";
import { PartnerModal } from "../component/PartnerModal/PartnerModal";
import { Button } from "../../app/component/Button/Button";
import { CreateEditPartnerModel } from "../model/partners.model";
import {
  selectFetchIndustriesData,
  selectIsFetchIndustriesStatusPending,
} from "../../industry/store/industry.selector";
import { ADD_PARTNER_MODAL } from "../../app/const/modals";
import { selectIsPartnersTableStatusPending } from "../store/partners.selector";

export const AddPartnerModalContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const industriesData = useSelector(selectFetchIndustriesData);
  const isPartnersPending = useSelector(selectIsPartnersTableStatusPending);
  const isFetchIndustriesPending = useSelector(
    selectIsFetchIndustriesStatusPending
  );

  const openModal = () =>
    dispatch(openModalAction({ name: ADD_PARTNER_MODAL }));

  const closeModal = () =>
    dispatch(closeModalAction({ name: ADD_PARTNER_MODAL }));

  const onCreatePartner = (values: CreateEditPartnerModel) =>
    dispatch(createPartnerAction(values));

  return (
    <>
      <ConnectedShowForPermissions
        feature="partners"
        permissions={{ allOf: ["create"] }}
      >
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModal}
          disabled={isPartnersPending}
        >
          Add Partner
        </Button>
      </ConnectedShowForPermissions>
      <PartnerModal
        name={ADD_PARTNER_MODAL}
        onClose={closeModal}
        onSubmit={onCreatePartner}
        industries={industriesData}
        isPending={isFetchIndustriesPending}
      />
    </>
  );
};
