import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncStateModel } from "../../app/model/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TaskDto, TasksList, TaskStatus } from "../api/workorders.api.dto";
import { WorkOrdersState } from "./workOrders.store";

export const selectSelf = (state: RootState): RootState => state;

export const selectWorkOrdersRootState = createSelector(
  selectSelf,
  (state: RootState): WorkOrdersState => state.workOrders
);

export const selectTasksListState = createSelector(
  selectWorkOrdersRootState,
  (tasks): AsyncStateModel<TasksList> => tasks.tasksLists
);

export const selectTasksState = createSelector(
  selectWorkOrdersRootState,
  (tasks): AsyncStateModel<TaskDto[]> => tasks.tasks
);

export const selectSearchTasks = createSelector(
  selectWorkOrdersRootState,
  (tasks): string => tasks.searchTasks
);

export const selectUserIdFilter = createSelector(
  selectWorkOrdersRootState,
  (tasks): number | null => tasks.userIdFilter
);

export const selectTasksListData = createSelector(
  selectTasksListState,
  (tasksLists): TasksList => tasksLists.data
);

export const selectTasksData = createSelector(
  selectTasksState,
  (tasks): TaskDto[] => tasks.data
);

export const selectFilteredTasksListData = createSelector(
  selectTasksListState,
  selectSearchTasks,
  selectUserIdFilter,
  (tasksLists, searchValue, userIdFilter): TasksList =>
    Object.keys(tasksLists.data).reduce(
      (acc: TasksList, curr) => {
        const taskStatusKey = curr as TaskStatus;
        acc[taskStatusKey] = tasksLists.data[taskStatusKey]
          .filter(
            (task) =>
              task.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              task.asset[0]?.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())
          )
          .filter((task) =>
            userIdFilter ? task.assigned_user?.id === userIdFilter : true
          );

        return acc;
      },
      {
        [TaskStatus.Open]: [],
        [TaskStatus.InProgress]: [],
        [TaskStatus.Done]: [],
      }
    )
);

export const selectTasksListStatus = createSelector(
  selectTasksListState,
  (tasksLists): AsyncStatus => tasksLists.status
);

export const selectIsTasksListStatusPending = createSelector(
  selectTasksListStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSelectedClientId = createSelector(
  selectWorkOrdersRootState,
  (tasks): number | null => tasks.selectedClientId
);

export const selectSelectedClientIdStatus = createSelector(
  selectWorkOrdersRootState,
  (selectedClientId): AsyncStatus => selectedClientId.tasks.status
);

export const selectIsSelectedClientIdPending = createSelector(
  selectSelectedClientIdStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
