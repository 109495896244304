import styled from "styled-components";

import { Typography } from "@material-ui/core";
import { MAIN } from "../../../app/theme/colors";

export const StyledDot = styled.div<{ show: boolean }>`
  height: 8px;
  width: 8px;
  background-color: ${({ show }) => (show ? MAIN : "transparent")};
  border-radius: 50px;
`;

export const StyledLabel = styled(Typography)`
  letter-spacing: 1.25px;
  line-height: 18px;
`;
