import styled from "styled-components";
import { Fab } from "@material-ui/core";

export const FloatingButton = styled(Fab)`
  position: absolute;
  right: 0;
  bottom: -56px;
`;

export const Form = styled.form`
  flex: 1;
`;

export const DropzoneContainer = styled.div`
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p {
    text-align: center;
  }
`;
