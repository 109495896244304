import React, { FC, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ConnectedShowForPermissions } from "../../../app/component/UserManagement/ConnectedShowForPermissions";
import {
  StyledEditIcon,
  StyledCloseIcon,
  StyledDialogTitleContainer,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { AssetSensorModalForm } from "./AssetSensorModalForm";
import { SensorDto } from "../../api/asset.api.dto";
import { CreateEditSensorModel } from "../../model/asset.model";
import { SensorTypeDto } from "../../../sensorTypes/api/sensorTypes.api.dto";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";

export interface IAssetSensorModal {
  name: string;
  assetId: number;
  sensorTypes: SensorTypeDto[];
  onClose: () => void;
  onSubmit: (values: CreateEditSensorModel, id?: number) => void;
  sensor?: SensorDto;
  onDelete?: (id: number) => void;
  isPending: boolean;
  selectedChannel?: number;
  selectedChannelDevice?: number;
}

export const AssetSensorModal: FC<IAssetSensorModal> = ({
  name,
  assetId,
  sensorTypes,
  isPending,
  onClose,
  onSubmit,
  sensor,
  onDelete,
  selectedChannel,
  selectedChannelDevice,
}) => {
  const [editView, setEditView] = useState(false);

  const handleOnDelete = () => {
    if (onDelete && sensor) {
      onDelete(sensor.id);
    }
  };

  const getModalHeader = () => {
    if (sensor && editView) {
      return <Typography variant="h2">Edit Sensor</Typography>;
    }
    if (sensor && !editView) {
      return <Typography variant="h2">View Sensor</Typography>;
    }
    return <Typography variant="h2">Add Sensor</Typography>;
  };

  const getEditActionButtons = (pending: boolean) => (
    <>
      <Grid container item xs={4} justify="flex-start">
        {editView && (
          <ConnectedShowForPermissions
            feature="sensors"
            permissions={{ allOf: ["delete"] }}
          >
            <Box display="inline" mr={1}>
              <Button
                variant="delete"
                onClick={handleOnDelete}
                disabled={pending}
              >
                Delete
              </Button>
            </Box>
          </ConnectedShowForPermissions>
        )}
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        {editView && (
          <Box display="inline" mr={1}>
            <Button
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
              disabled={pending}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box display="inline">
          {editView ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
                setEditView(false);
              }}
            >
              Close
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );

  const getCreateActionButtons = (pending: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button
              onClick={() => {
                onClose();
                setEditView(false);
              }}
              disabled={pending}
              color="primary"
            >
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              pending={pending}
            >
              <AddIcon /> Add Sensor
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const displayActionButtons = (pending: boolean) => {
    if (sensor) {
      return getEditActionButtons(pending);
    }
    return getCreateActionButtons(pending);
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        {!editView && sensor && (
          <ConnectedShowForPermissions
            feature="assets"
            permissions={{ allOf: ["update"] }}
          >
            <StyledIconButton
              aria-label="edit"
              onClick={() => setEditView(true)}
            >
              <StyledEditIcon />
            </StyledIconButton>
          </ConnectedShowForPermissions>
        )}
        <StyledIconButton aria-label="close" onClick={onClose}>
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = (pending: boolean) => {
    return (
      <AssetSensorModalForm
        assetId={assetId}
        sensor={sensor}
        sensorTypes={sensorTypes}
        onSubmit={onSubmit}
        displayActionButtons={() => displayActionButtons(pending)}
        isPending={isPending}
        selectedChannel={selectedChannel}
        selectedChannelDevice={selectedChannelDevice}
        editView={editView}
      />
    );
  };

  return (
    <ConnectedModal
      name={name}
      Content={(pending) => ModalContent(pending || isPending)}
      Header={ModalHeader}
      onClose={onClose}
    />
  );
};
