import styled from "styled-components";
import { Box, Card, Link, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { Button } from "../../../app/component/Button/Button";

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  width: 470px;
`;

// eslint-disable-next-line
export const StyledLink = styled<any>(Link)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  text-align: right;
  color: #1f1e4d;
  display: inline-block;
`;

export const StyledBox = styled(Box)`
  position: relative;
`;

export const StyledButton = styled(Button)`
  height: 35px;
  width: 255px;
`;

export const StyledNotification = styled.div`
  display: flex;
  margin: ${({ theme }) => `${theme.spacing(1)}px`} 0;
  height: 51px;
  background-color: rgba(87, 176, 242, 0.2);
  border-radius: 5px;
  align-items: center;
  padding-left: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const StyledNotificationText = styled(Typography)`
  font-size: 14px;
  color: #3680b7;
  font-weight: 500;
`;

export const StyledNotificationIcon = styled(InfoOutlinedIcon)`
  color: #3680b7;
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  font-weight: 500;
`;
