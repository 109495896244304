import React, { FC } from "react";
import { Divider, Grid } from "@material-ui/core";
import { AssetHealthGaugeContainer } from "../../container/AssetHealthGaugeContainer";
import { AssetInfoContainer } from "../../container/AssetInfoContainer";
import { AssetsSensorsTableContainer } from "../../container/AssetSensorsTableContainer";

export const AssetSensorsView: FC = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={4} lg={3} xl={2}>
      <AssetHealthGaugeContainer />
    </Grid>
    <Grid item xs={12} md={8} lg={9} xl={10}>
      <AssetInfoContainer />
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12}>
      <AssetsSensorsTableContainer />
    </Grid>
  </Grid>
);
