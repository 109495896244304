import { unwrapResult } from "@reduxjs/toolkit";

// eslint-disable-next-line
export const unwrap = async (fn: Promise<any>) => {
  try {
    const result = await fn;
    return unwrapResult(result);
  } catch (error) {
    throw error;
  }
};
