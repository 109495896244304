import { Box, Grid, Typography } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import React, { FC } from "react";

import { Add } from "@material-ui/icons";
import {
  StyledDialogTitleContainer,
  StyledCloseIcon,
  StyledIconButton,
} from "../../../app/component/Modal/Modal.style";
import { Button } from "../../../app/component/Button/Button";
import { ConnectedModal } from "../../../app/component/ConnectedModal/ConnectedModal";
import { AddAssetNoteModalForm } from "./AddAssetNoteModalForm";
import { FloatingButton } from "./AddAssetNoteModal.style";

export interface IAssetModal {
  onClose: () => void;
  onOpen: () => void;
  onSubmit: (text: string) => void;
  name: string;
}

export const AddAssetNoteModal: FC<IAssetModal> = ({
  onClose,
  onOpen,
  onSubmit,
  name,
}) => {
  const getModalHeader = () => <Typography variant="h2">Add Note</Typography>;

  const getCreateActionButtons = (pending: boolean, disabled: boolean) => {
    return (
      <>
        <Grid container item xs={12} justify="flex-end">
          <Box display="inline" mr={1}>
            <Button onClick={onClose} disabled={pending} color="primary">
              Cancel
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending || disabled}
              pending={pending}
            >
              <AddIcon /> Add Note
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>{getModalHeader()}</Box>
      <Box>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <StyledCloseIcon />
        </StyledIconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const ModalContent = (pending: boolean) => {
    return (
      <AddAssetNoteModalForm
        onSubmit={onSubmit}
        displayActionButtons={(disabled) =>
          getCreateActionButtons(pending, disabled)
        }
        isPending={pending}
      />
    );
  };

  return (
    <>
      <FloatingButton color="primary" aria-label="add" onClick={onOpen}>
        <Add />
      </FloatingButton>
      <ConnectedModal
        name={name}
        Content={(pending) => ModalContent(!!pending)}
        Header={ModalHeader}
        onClose={onClose}
      />
    </>
  );
};
