import styled from "styled-components";

export const ListContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  border-radius: 2px;
  background-color: #e5e8ea;

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 100%;
  }

  .MuiCard-root:first-child {
    margin-top: 0;
  }
  .MuiCard-root:last-child {
    margin-bottom: 0;
  }
`;
