import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { AppDispatch } from "../../app/store";

import { DashboardFavorites } from "../component/DashboardFavorites/DashboardFavorites";
import { ASSETS } from "../../app/const/routes";
import {
  fetchBookmarks,
  repositionBookmarks,
} from "../../bookmarks/store/bookmarks.action";
import {
  selectFetchBookmarksData,
  selectIsFetchBookmarksStatusPending,
} from "../../bookmarks/store/bookmarks.selector";
import { BookmarkModel } from "../../bookmarks/model/bookmarks.model";

export const DashboardFavoritesContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const bookmarks = useSelector(selectFetchBookmarksData);
  const pending = useSelector(selectIsFetchBookmarksStatusPending);

  const onFavoriteClick = (favorite: BookmarkModel) => {
    dispatch(push(`${ASSETS}/${favorite.asset_id}/${favorite.tab_name}`));
  };

  const onReposition = (bookmarksIds: number[]) => {
    dispatch(repositionBookmarks(bookmarksIds));
  };

  useEffect(() => {
    dispatch(fetchBookmarks());
  }, [dispatch]);

  return (
    <DashboardFavorites
      favorites={bookmarks}
      onFavoriteClick={onFavoriteClick}
      pending={pending}
      onReposition={onReposition}
    />
  );
};
